// Reopen Ember Checkbox to add support for 'indeterminate'
Ember.Checkbox.reopen({
	attributeBindings: ['type', 'checked', 'disabled', 'tabindex', 'name', 'indeterminate'],
	indeterminate: false,
	didInsertElement: function() {
		this._super();
		this.get('element').indeterminate = !!this.get('indeterminate');
	},
});

Ember.LinkView.reopen({
	classNameBindings: ['isLoading:loading'],
	click: function(event) {
		this.set('isLoading', true);
		return this._super(event);
	}
});

zen._EligibilityUploadMixin = Ember.Mixin.create({
	documentUrl: '',
	documentType: '',
	name: '',
	deletedList: [],
	errorText: '',
	showUploader: true,
	I9_LIST_A: [
		{ "id": "US_PASSPORT", "name": "U.S. Passport" },
		{ "id": "GREEN_CARD", "name": "Permanent Resident Card or Receipt (I-551)" },
		{ "id": "I551_STAMP", "name": "Foreign passport with temporary I-551 stamp" },
		{ "id": "EAD", "name": "Employment Authorization Document " },
		{ "id": "FOREIGN_PASSPORT", "name": "Foreign passport and Form I-94/ I-94A" },
	],

	I9_LIST_B: [
		{ "id": "STATE_ID", "name": "Driver's license or State ID" },
		{ "id": "AGENCY_ID", "name": "Government Agency ID card" },
		{ "id": "SCHOOL_ID", "name": "School ID (with photograph)" },
		{ "id": "VOTER_ID", "name": "Voter's registration card" },
		{ "id": "MILITARY_ID", "name": "U.S. Military Card" },
		{ "id": "MILITARY_DEP_ID", "name": "Military dependent's ID Card" },
		{ "id": "CANADIAN_ID", "name": "Canadian Driver's license" }
	],

	I9_LIST_C: [
		{ "id": "SSN_CARD", "name": "Social Security card" },
		{ "id": "BIRTH_CERT", "name": "U.S. Birth Certificate" },
		{ "id": "FORM_FS_545", "name": "Certification of Report of Birth (Forms DS-1350, FS-545, FS-240)" },
		{ "id": "US_CITIZEN_ID", "name": "U.S. Citizen ID Card (Form I-197)" },
		{ "id": "NATIVE_AMERICAN_TRIBAL", "name": "Native American Tribal Document" },
		{ "id": "FORM_I_179", "name": "ID Card for Use of Resident Citizen in U.S. (Form I-179)" },
		{ "id": "EMPLOYMENT_AUTHORIZATION", "name": "Employment Authorization Document Issued by DHS" },
		{ "id": "OTHER", "name": "Other"},
	],

	documentsTypeChoices: function() {
		var I9_LIST_A = this.get('I9_LIST_A');
		var I9_LIST_B = this.get('I9_LIST_B');
		var I9_LIST_C = this.get('I9_LIST_C');
		return I9_LIST_A.concat(I9_LIST_B, I9_LIST_C);
	}.property('I9_LIST_A', 'I9_LIST_B', 'I9_LIST_C'),

	hasDocuments: function() {
		return Boolean(this.get('fileList.length') > 0);
	}.property('fileList.length'),
	queueFiles: function(name) {
		return function () {
			this.set('errorText', '');
			if (!this.get('documentUrl')) {
				this.set('errorText', 'Please upload a document');
				return;
			}
			if (App.switches.isActive('i9_document_upload_new')) {
				console.log(this.get('documentType'));
				var doc = App.EmployeeEligibilityDocument.createRecord({
					'eligibility': this.get('model'),
					'documentType': this.get('documentType'),
					'documentName': name,
					'uploadUrl': this.get('documentUrl')
				});
			}
			else {
				var doc = App.EmployeeEligibilityDocument.createRecord({
					'eligibility': this.get('model'),
					'documentName': name,
					'uploadUrl': this.get('documentUrl')
				});
			}
			var fileList = this.get('fileList');
			fileList.pushObject(doc);
			this.set('fileList', fileList);
			this.set('name', '');
			this.set('documentType', '');
			this.set('documentUrl', '');
		};
	},

	deleteDocument: function(obj) {
		var fileList = this.get('fileList');
		var deletedList = this.get('deletedList');
		fileList.removeObject(obj);
		obj.deleteRecord();
		deletedList.pushObject(obj);
		this.set('fileList', fileList);
		this.set('deletedList', deletedList);
	},

	saveFilesOneByOne: function(listOfFiles) {
		return listOfFiles.reduce(function (soFar, file) {
			return soFar.then(function () {
				if (file.get('isDirty')) {
					return file.save();
				}
				return Ember.RSVP.resolve();
			});
		}, Ember.RSVP.resolve());
	},
	reloadObjects: function() {
		var model = this.get('model.newHire');
		model.reload().then(function() {
			thenpath(this.get('model'), 'eligibilityDocuments').then(function() {
				this.transitionToRoute('employeei9', this.get('model.id'));
			}.bind(this));
		}.bind(this));
	},
	resetUploader: function() {
		this.set('showUploader', false);
		Ember.run.later(function() {
			this.set('showUploader', true);
		}.bind(this));
	},
	actions: {
		deleteDocumentAction: function(obj) {
			this.deleteDocument(obj);
		},

		onFileUploadSuccess: function(file, data) {
			this.set('errorText', '');

			if (App.switches.isActive('i9_document_upload_new')) {
				if (!this.get('documentType')) {
					this.set('errorText', 'Please specify the document type.');
					this.resetUploader();
					return;
				}
			}
			if (!this.get('name')) {
				this.set('errorText', 'Please specify the document name.');
				this.resetUploader();
				return;
			}

			this.set('documentUrl', data.url);
			this.queueFiles(this.get('name')).bind(this)();
			this.resetUploader();
		},
		onFileUploadError: function(file, error) {
			this.eventLogger.log('eligibility-proof-upload-error', {
				message: "Error uploading file: " + error.toString(),
			});
		},
		uploadDocument: function() {
			this.set('errorText', '');
			if (App.switches.isActive('i9_document_upload_new')) {
				if (!this.get('documentType')) {
					this.set('errorText', 'Please specify the document type.');
					return Ember.RSVP.resolve();
				}
			}
			if (!this.get('name')) {
				this.set('errorText', 'Please specify the document name.');
				return Ember.RSVP.resolve();
			}

			this.pickAndStore('documentUrl',
							  { "extension": ['.pdf','.png','.jpg','jpeg','.PDF','.PNG','.JPG','JPEG'] },
							  this.queueFiles(this.get('name'), this.get('documentType')).bind(this));
		},
		save: function() {
			if (this.get('fileList').length === 0) {
				this.set('errorText', 'Please upload a document');
				return;
			}
			//Ensures newhire transition to 'complete' happens just once
			return this.saveFilesOneByOne(this.get('fileList')).then(function() {
				//Deletes can be done in bulk
				return wrapArrayPromise(
					this.get('deletedList').filter(function (deletedFile) {
						return deletedFile.get('isDirty');
					}).map(function (deletedFile) {
						return deletedFile.save();
					})
				);
			}.bind(this)).then(function() {
				this.reloadObjects();
			}.bind(this));
		},
	},
});

zen._AuthContractorAdminMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
		return dashboard('canAccessContractors').then(function (canAccessContractors) {
			if (!canAccessContractors) { throw new Error('Not Authorized'); }
		});
	}
});

zen._AuthSuperAdminMixin = Ember.Mixin.create({
	permissions: Ember.inject.service(),
	beforeModel: function(transition) {
		if (App.switches.isActive('roles_permissions_add_admin')) {
			if (!this.get('permissions').currentEmployeeHas('can_add_remove_admins')) {
				throw new Error('Not Authorized');
			}
		} else {
			return dashboard('canAddRemoveAdmins').then(function (canAddRemoveAdmins) {
				if (!canAddRemoveAdmins) { throw new Error('Not Authorized'); }
			});
		}
	}
});

zen._AuthManagerMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
		return dashboard('isAdminOrManager').then(function (isAdminOrManager) {
			if (!isAdminOrManager) { throw new Error('Not Authorized'); }
		});
	}
});

zen._AuthFullManagerAdminMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
	    this._super();
	    var _this = this;
        return dashboard('isFullAdminOrManager').then(function (isFullAdminOrManager) {
			if (!isFullAdminOrManager){
			        _this.transitionTo('/');
			}
		});
	}
});

zen._AuthContractorAdminWithWriteAccessMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
	    this._super();
	    var _this = this;
		return dashboard('canAccessContractorsWithWriteAccess').then(function (canAccessContractorsWithWriteAccess) {
			if (!canAccessContractorsWithWriteAccess) {
			        _this.transitionTo('/');
			}
		});
	}
});

zen._AuthEmployeeMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
		return dashboard('employee').then(function (employee) {
			if (!employee) { throw new Error('Not Authorized'); }
		});
	}
});

zen._AuthAnyMixin = Ember.Mixin.create({
	beforeModel: function() {}
});


zen._HideAdminMixin= Ember.Mixin.create({
	checkAdminStatus: function(model) {
		if (model.get("type") === "AD"){
			return this.transitionTo("index");
		}
	}
});

zen._HiringRefreshMixin = Ember.Mixin.create(zen._FlowCurrentSectionRouteMixin, {
	model: function() {
		return this.modelFor('hiring');
	},
});

if (App.Router) {
	App.Router.map(window.routingFn);
}

App.ValidationsControllerMixin = Ember.Mixin.create({
	validations: Ember.computed(function() {
		return Ember.Set.create();
	}),
	anyErrors: Ember.computed.gt('validations.length', 0),
	validationErrorText: function() {
		if (this.get('anyErrors')) {
			return this.get('validations')[0].reason;
		} else {
			return null;
		}
	}.property('anyErrors', 'validations.@each.reason'),
});

// TODO(JoCo): Kill this, this is a ridiculously stupid code pattern
zen._CompanyRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function() { return dashboard('company'); }
});


App.FirstRoute = Ember.Route.extend({
	beforeModel: function(transition) {
		$('body').append('<iframe width="0" height="0" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://learn.zenefits.com/dashboard-first-visit-iframe.html" style="display:none"></iframe>');
		this.transitionTo('index');
	}
});

App.DateRange = Ember.Object.extend({
	'start': '',
	'end': '',
});

App.DateRangeFilter = App.DateRange.extend({
	selectedListSetter: function() {
		var startDate = zen.parseDate(this.get('dateRange.start'));
		var endDate = zen.parseDate(this.get('dateRange.end'));
		var previousStart = this.get('selectedList.start');
		var previousEnd = this.get('selectedList.end');
		if ((startDate != previousStart) || (endDate != previousEnd)) {
			this.set('selectedList', App.DateRange.create({
				'start' : startDate,
				'end' : endDate,
			}));
		}
	}.observes('dateRange.start', 'dateRange.end'),
	reset: function() {
		this.set('dateRange.start', '');
		this.set('dateRange.end', '');
	},
});

zen._NewStylesPatchMixin = Ember.Mixin.create({
	newStylesService: Ember.inject.service('new-styles'),
	_routeActivated: false,

	addV2Classes: function() {
		// NOTE: this block needed to prevent Ember bug where route is activated TWICE before any deactivations
		// Happens when this mixin is applied to a top level wizard flow route who's controller uses 'transitionToRoute'
		// to a subroute in that wizard
		var routeActivated = this.get('_routeActivated');
		if (routeActivated) {
			return;
		}

		this.set('_routeActivated', true);
		var newStylesService = this.get('newStylesService');
		newStylesService.activateV2Route();
	}.on('activate'),

	addV1Classes: function(){
		// NOTE: this block needed to prevent Ember bug where route is deactivated TWICE before any activations
		// Happens when this mixin is applied to a top level wizard flow route who's controller uses 'transitionToRoute'
		// to a subroute in that wizard
		var routeActivated = this.get('_routeActivated');
		if (!routeActivated) {
			return;
		}

		this.set('_routeActivated', false);
		var newStylesService = this.get('newStylesService');
		newStylesService.deactivateV2Route();
	}.on('deactivate'),
});

// FIXME - Remove and move logic to react
var RemoveDjangoNavBottomMarginMixin = Ember.Mixin.create({
	removeDjangoNavBottomMargin: function() {
		Ember.$('.z-navigation-container-django').addClass('u-bottomFlush');
		Ember.$('.z-top-nav-bar').addClass('u-bottomFlush');
	}.on('activate'),

	restoreDjangoNavBottomMargin: function(){
		Ember.$('.z-navigation-container-django').removeClass('u-bottomFlush');
		Ember.$('.z-top-nav-bar').removeClass('u-bottomFlush');
	}.on('deactivate'),
});

App.EmployeeorgchartRoute = Ember.Route.extend({
	renderTemplate: function() {
		return;
	},
	model: function() {
		return Ember.RSVP.resolve();
	}
});

App.TeamorgchartRoute = Ember.Route.extend({
	beforeModel: function() {
		return this.transitionTo('employeeorgchart');
	}
});

// this exists because the sidebar needs to access canAccessSensitive
App.ProvisionManageRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAnyMixin, {
	model: function() {
		return this.all({
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"canAccessITProvisioning" : dashboard('canAccessITProvisioning'),
			"companyItServices" : dashboard('company.companyItServices'),
			"isAdmin": dashboard('isAdmin'),
			"_itServiceUsers": dashboard('employee').then(function(employee){
				return employee.get('itServiceUsers');
			}),
		});
	},
});


zen._ProvisionitDisplayController = zen._TransactionSavingController.extend({
	needs: ['application'],
	ordering: function() {
		// to modify ordering of the grid, you can change this column
		var ordering = [
			0, // first element goes up top
			1,
			2,
			3,
			4,
			5,
			6,
		];
		var itServiceOrdered = [];
		var setupApps = this.get('company.installedITServices').split(",");
		var itServices = this.get('itServices.content');
		var itService;
		var itServiceName;
		ordering.forEach(function(number) {
			itService = itServices[number];
			if (itService) {
				itServiceName = itService.get('name');
				itServiceOrdered.push(
					{
						'name': itServiceName,
						'id': itService.get('id'),
						'model': itService,
						'logoUrl': itService.get('logoURL'),
						'setUp': setupApps.contains(itServiceName),
					}
				);
			}
		});
		console.log(itServiceOrdered);
		return itServiceOrdered;
	}.property('itServices'),
	topLinks: function() {
		return this.get('ordering').slice(0, 1);
	}.property('ordering'),
	bottomLinks: function() {
		return this.get('ordering').slice(1);
	}.property('ordering'),
	linksGrid: function() {
		var bottomLinks = this.get('bottomLinks');
		var rowSize = this.get('rowSize');
		var linkGrid = [];
		bottomLinks.forEach(function(element, index, array) {
			if (index % rowSize === 0) {
				linkGrid.push(bottomLinks.slice(index, index + rowSize));
			}
		});
		return linkGrid;
	}.property('bottomLinks'),
	rowSize: 3,
	allGrid: function() {
		var activeServiceOrdered = [];
		var i = 0;
		var rowSize = this.get('rowSize');
		allSetup = this.get('ordering').filter(function(object) {
			return object['setUp'];
		}).forEach(function(service) {
			if (i % rowSize === 0) {
				activeServiceOrdered.push([service]);
			}
			else {
				activeServiceOrdered[activeServiceOrdered.length-1].push(service);
			}
			i++;
		});
		console.log(activeServiceOrdered);
		return activeServiceOrdered;
	}.property('ordering'),
	clickIcon: function(itService) {
		console.log(this);
		$.oauthpopup = function(options) {
			options.windowName = options.windowName || 'ConnectWithOAuth'; // should not include space for IE
			options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=400';
			options.callback = options.callback || function() { window.location.reload(); };
			var that = this;
			console.log(options.path);
			console.log(this);
			console.log(this.get('gmail'));
			that._oauthWindow = window.open(options.path, options.windowName, options.windowOptions);

			window.transitionToSyncing = function() {
				this.set('creatingCompanyService', "creating");
			}.bind(this);
		}.bind(this);
		console.log(itService);
		$.oauthpopup({
			path: '/it_provisioning/bf06169a403ec44e556ce079367a3af1/',
			callback: function() {
				 //parent.close()
				console.log('callback');
			}

		});
	},
	gmailCacher: function() {
		if (this.get('gmail.isLoaded') && !this.get('gmail.isUpdating')) {
			this.set('displayGmail', this.get('gmail'));
		}
	}.observes('gmail.isLoaded', 'gmail.isUpdating'),
	save: function() {
		this.saveAndContinue().then(function() {
			this.transitionToRoute('provision.match.multiple');
		}.bind(this));
	},
	thisPage: null // needs to be defined
});

App.ProvisionMatchRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return this.all({
			"canAccessSensitive": dashboard('canAccessSensitive'),
		});
	},
});


zen._ProvisionFlowController = zen._TransactionSavingController.extend({
	needs: ['application'],
	tableColumns: [
		{ name : "User Info", style: "width: 180px" },
		{ name : "Employee Match", style: "width: 320px" },
		{ name : "Edit", style: "width: 40px" }
	],
	showEmployeeSelector: Ember.computed.alias('canEdit'),
	canEdit: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Edit'; }).length > 0;
	}.property(),
	canRemove: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Remove'; }).length > 0;
	}.property(),
	canDelete: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Delete'; }).length > 0;
	}.property(),
	showDeactivationDate: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Deactivated'; }).length > 0;
	}.property(),
	showDeletionDate: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Deleted'; }).length > 0;
	}.property(),
	canReactivate: function() {
		return this.get('tableColumns').filter(function(column) { return column.name == 'Reactivate'; }).length > 0;
	}.property(),
	toggleEmp: function(user) {
		var currentValue = user.get('isEditing');
		// saves for all types of users right now
		if (currentValue) {
			user.save();
		}
		user.set('showSavedIcon', currentValue);
		user.set('isEditing', !currentValue);
	},
	allEmployees : Ember.computed.filterByProperty('employees', 'isAllActive'),
	//We can't do mapByProperty as it returns null if transferRequests is null and ZenMultiSelectDLE expect a Em.A, blame DD
	selectedEmployees: Em.A(),
	addedEmployees: Em.A(),
	removedEmployees: Em.A(),
	//should not be needed but ZenMultiSelectDLE does not work without it, again DD.
	allDepartments: Em.A(),
	selectedDepartments: Em.A(),
	addedDepartments: Em.A(),
	removedDepartments: Em.A(),
	allLocations: Em.A(),
	selectedLocations: Em.A(),
	addedLocations: Em.A(),
	removedLocations: Em.A(),
	employeeSelect: true,
	reportToEmployees: function() {
		return this.get('employees').filterProperty('status', 'Act');
	}.property('employees'),

});

zen._ProvisionRollbackRouteMixin = Ember.Mixin.create({
	actions: {
		willTransition: function() {
			console.log('checking for transition');
			var itServiceUsers = this.get('controller.model.serviceUsers') || [];
			console.log(itServiceUsers);
			var doRollback = function (o) {
				if (o && o.get && (o.get('isDirty') || o.get('isNew'))) {
					o.get('transaction').rollback();
				}
			};
			itServiceUsers.forEach(doRollback);
		}
	}
});

App.ProvisionManageAllRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, zen._ProvisionRollbackRouteMixin, {
	model: function(params) {
		return this.all({
			"company": dashboard('company'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"employees": dashboard('employees'),
			"companyItServices": dashboard('company.companyItServices'),
			"itServices": App.ItService.find(),
			"canAccessITProvisioning" : dashboard('canAccessITProvisioning'),
		});
	},
	afterModel: function(model) {
		var companyItServices = model['companyItServices'];
		companyItServices.forEach(function(service) {
			console.log(service);
			service.get('itService');
		});
	}
});

zen._LoadAllEmployeesMixin = Ember.Mixin.create({
	triggerLoadingProperty: null,
	allEmployees: Ember.computed.filterByProperty('employees', 'isEmployee', true),
	loadItServiceUsers: function() {
		var selectedCompanyService = this.get('selectedITService');
		if (selectedCompanyService) {
			console.log('getting....');
			console.log(selectedCompanyService);
			wrapArrayPromise(selectedCompanyService.get('itServiceUsers')).then(function() {
				console.log('finished loading');
				this.set('employeesLoaded', true);
			}.bind(this));
		}
	}.observes('selectedITService'),
	setFlippingProperty: null,
});

zen._PollForCompanyServicesMixin = Ember.Mixin.create({
	pollForCompanyService: function() {
		if (this.get('currentPage') == this.get('thisPage') &&
				(!this.get('gmailService') ||
				!this.get('gmailService.hasSufficientCredentials') ||
				this.get('gmailService.status') != 'complete')) {
			setTimeout(function() {
				this.set('flippingProperty', !this.get('flippingProperty'));
				if (this.get('gmailService')){
					App.CompanyItService.find({'itService__name':'gmail'});
				} else {
					this.set('gmailServices', App.CompanyItService.find({'itService__name':'gmail'}));
				}

				this.set('waitTime', 4000);
			}.bind(this), this.get('waitTime'));
		}
		return this.get('syncedYet');
	}.observes('flippingProperty'),
	setFlippingProperty: function(){
		this.set('flippingProperty', !this.get('flippingProperty'));
	}.property(),
	gmailService: Ember.computed.alias('gmailServices.firstObject'),
	thisPage: 'provision.marketing.initial',
	gmailServices: Ember.computed.oneWay('companyItServices'),
	currentPage: Ember.computed.alias('controllers.application.currentPath'),
	needs: ['application'],
});

App.ProvisionManageInitialController = zen._ProvisionitDisplayController.extend(zen._LoadAllEmployeesMixin, zen._PollForCompanyServicesMixin, {
	canAccessITProvisioningOrSensitive: Ember.computed.or('canAccessSensitive', 'canAccessITProvisioning'),
	rowSize: 2,
	waitTime: 1, // initial wait time = 1ms.
	serviceUsers:  Ember.computed.alias('selectedITService.itServiceUsers'),
	currentPage: Ember.computed.alias('controllers.application.currentPath'),
	bothNumbers: Ember.computed.and('unsuccessfulMatchesNumber', 'successfulMatchesNumber'),
	selectedITService: Ember.computed.alias('companyItServices.firstObject'),
	triggerLoadingProperty: Ember.computed.equal('gmailService.status', 'complete'),
	successfulMatchesNumber: function() {
		return (this.get('number1') || 0) + (this.get('number2') || 0);
	}.property('number1', 'number2'),
	unsuccessfulMatchesNumber: function() {
		return (this.get('number3') || 0) + (this.get('number4') || 0);
	}.property('number3', 'number4'),
});

App.ProvisionAdminhelpController = zen._TransactionSavingController.extend({
	back: function() {
		this.send('hideModal');
	},
});

zen._EmployeeServiceController = Ember.Controller.extend({
	needs: ['provision/manage/all'],
	canAccessITProvisioning: Ember.computed.alias('controllers.provision/manage/all.canAccessITProvisioning'),
	unmatchedAvailUsers: Ember.computed.alias('controllers.provision/manage/all.unmatchedUsers'),
	addedUsers: Ember.A(),
	removedUsers: Ember.A(),
	removeFromList: false,
	actionBucket: function() {
		var status = this.get('model.status');
		var serviceUsers = this.get('model.itServiceUsers');
		var activeServiceUsers = serviceUsers.filter(function(user) {
			return ['Act'].indexOf(user.get('status')) > -1;
		});
		var deactivatedServiceUsers = serviceUsers.filter(function(user) {
			return ['Deac', 'Marked-Deac', 'Del', 'Ina'].indexOf(user.get('status')) > -1;
		});

		var allSelectedUsers = activeServiceUsers.every(function(user) {
				return user.get('matchAccuracy') == 100;
			});
		var activeAndSettingUpServiceUsers = serviceUsers.filter(function(user) {
			return ['Act', 'Set'].indexOf(user.get('status')) > -1;
		});

		var oneGoodMatch = activeAndSettingUpServiceUsers.length == 1 && activeAndSettingUpServiceUsers[0].get('matchAccuracy') >= 90;
		if (activeAndSettingUpServiceUsers.length) {
			if (allSelectedUsers || oneGoodMatch) {
				if (status == 'Act') {
					return 'active';
				}
				if (status == 'Ter') {
					return 'terminated';
				}
			} else if (status != 'Ter') {
				return 'multiple';
			}
		} else if (!activeAndSettingUpServiceUsers.length && !deactivatedServiceUsers.length && status == 'Act') {
			return 'unmatched';
		}
	}.property('model.itServiceUsers.@each.matchAccuracy', 'model.itServiceUsers.@each.status', 'model.itServiceUsers.length'),
	userQualified: function(user) {
		return true;
	},
	toggleIsEditing: function(object) {
		object.set('isEditing', !object.get('isEditing'));
	},
	confirmMatch: function(user) {
		user.set('matchAccuracy', 100);
		user.save();
	},
	removeEmpFromList: function(employee) {
		var listEmployees = this.get('parentController.displayEmployees');
		var myIndex = -1;
		listEmployees.some(function(subController, index){
			var matches = subController.get('model') == employee;
			if (matches){
				myIndex = index;
				return true;
			}
			return false;
		});
		if (myIndex != -1) {
			listEmployees.removeAt(myIndex);
		}
	},
	cancelMatch: function(user) {
		user.set('employee', null);
		user.set('matchAccuracy', 50);
		user.save();

		var allUsers = this.get('model.itServiceUsers');
		allUsers.removeObject(user);
	},
	toggleIsEditingSave: function(object) {
		this._toggleIsEditingSave(object);
	},
	_toggleIsEditingSave: function(object) {
		var emp = this.get('model');
		var allUsers = this.get('model.itServiceUsers');
		var relevantUsers = this.get('relevantItServiceUsers');

		relevantUsers.forEach(function(user) {
			this.confirmMatch(user);
		}.bind(this));

		this.get('addedUsers').forEach(function(user) {
			allUsers.pushObject(user);
			user.set('employee', emp);
			this.confirmMatch(user);
		}.bind(this));

		this.get('removedUsers').forEach(function(user) {
			allUsers.removeObject(user);
			this.cancelMatch(user);
		}.bind(this));

		object.set('isEditing', !object.get('isEditing'));
		this.set('addedUsers',Ember.A());
		this.set('removedUsers',Ember.A());

	},
	toggleIsEditingCancel: function(object) {
		object.set('isEditing', !object.get('isEditing'));
		this.set('addedUsers',Ember.A());
		this.set('removedUsers',Ember.A());

	},
	relevantItServiceUsers: function() {
		var serviceUsers = this.get('model.itServiceUsers') || [];
		return serviceUsers.filter(function(user) {
			return this.userQualified(user);
		}.bind(this)).sort(function (a, b) {
			return a.get('matchAccuracy') < b.get('matchAccuracy');
		});
	}.property('model.itServiceUsers.@each'),
	killMargins: Ember.computed.alias('notSelectingNonSwappedServiceUser'),
 });

App.ActiveEmployeeController = zen._EmployeeServiceController.extend({
	needs: ['provision/manage/all', 'provision/match/actives'],
	listEmployees: Ember.computed.alias('controllers.provision/match/actives.model1'),
	userQualified: function(user) {
		return ['Act', 'Imp', 'Mat', 'Set'].indexOf(user.get('status')) > -1 && user.get('matchAccuracy') > 89;
	},

	showEmployee: Ember.computed.equal('actionBucket', 'active'),

});

App.TerminatedEmployeeController = zen._EmployeeServiceController.extend({
	needs: ['provision/manage/all', 'provision/match/terminateds'],
	listEmployees: Ember.computed.alias('controllers.provision/match/terminateds.model1'),
	testVariable: true,
	userQualified: function(user) {
		return ['Act', 'Imp', 'Mat', 'Set'].indexOf(user.get('status')) > -1 && user.get('matchAccuracy') > 89;
	},
	showEmployee: Ember.computed.equal('actionBucket', 'terminated'),

});
App.MultipleEmployeeController = zen._EmployeeServiceController.extend({
	needs: ['provision/manage/all', 'provision/match/multiple'],
	listEmployees: Ember.computed.alias('controllers.provision/manage/all.array3'),
	userQualified: function(user) {
		return user.get('matchAccuracy') > 72;
	},
	disableDeactivate: true,
	confirmMatch: function(user) {
		var serviceUsers = this.get('relevantItServiceUsers');
		var allUsers = this.get('model.itServiceUsers');
		var promises = serviceUsers.map(function(eachUser) {
			if (eachUser && eachUser != user) {
				allUsers.removeObject(eachUser);
				eachUser.set('employee', null);
				eachUser.set('matchAccuracy', 50);
				return eachUser.save();
			} else if (eachUser == user) {
				user.set('matchAccuracy', 100);
				return user.save();
			}
		});
		wrapArrayPromise(promises);
	},
	multipleTable: true,
	showEmployee: Ember.computed.equal('actionBucket', 'multiple'),
});

App.DeactivatedEmployeeController = zen._EmployeeServiceController.extend({
	needs: ['provision/manage/all', 'provision/match/deactivateds'],
	listEmployees: Ember.computed.alias('controllers.provision/match/deactivateds.model1'),
	userQualified: function(user) {
		return ["Deac", "Del"].indexOf(user.get('status')) > -1;
	},
	deactivatedTable: true,
	toggleIsEditingSave: function(object) {
		this._toggleIsEditingSave(object);
	},
	showEmployee: function() {
		var serviceUsers = this.get('model.itServiceUsers') || [];
		var deactivatedServiceUsers = serviceUsers.filter(function(user) {
			return ['Deac', 'Marked-Deac', 'Del'].indexOf(user.get('status')) > -1;
		});
		return deactivatedServiceUsers.length > 0;
	}.property('model.itServiceUsers.@each.status'),
});

App.UnmatchedEmployeeController = zen._EmployeeServiceController.extend({
	needs: ['provision/manage/all', 'provision/match/unmatched'],
	listEmployees: Ember.computed.alias('controllers.provision/manage/all.array4'),
	disableDeactivate: true,
	userQualified: function(user) {
		return ['Act', 'Imp', 'Mat', 'Set'].indexOf(user.get('status')) > -1 &&
				user.get('matchAccuracy') > 72;
	},
	noServiceUsers: Ember.computed.equal('model.relevantItServiceUsers.length', 0),
	killMargins: Ember.computed.and('notSelectingNonSwappedServiceUser', 'noServiceUsers'),
	toggleIsEditingSave: function(object) {
		this._toggleIsEditingSave(object);
		//this.set('hideThis', true)
		this.send('removeEmpFromList', this.get('model'));
	},
	showEmployee: Ember.computed.equal('actionBucket', 'unmatched')
});


App.ServiceUserController = Ember.ObjectController.extend({
	needs: ['provision/manage/all'],
	unmatchedAvailUsers: Ember.computed.alias('controllers.provision/manage/all.unmatchedUsers'),
	canAccessITProvisioning: Ember.computed.alias('controllers.provision/manage/all.canAccessITProvisioning'),
	removeUser: function(serviceUser) {
		serviceUser.set('swapLocation', "resetting");
		this.send('toggleGroup', serviceUser);
		this.send('toggleIsEditing', serviceUser);
	},

	toggleGroupChild: function(serviceUser) {
		this.send('toggleGroup', serviceUser);
		this.send('toggleIsEditing', serviceUser);
	}
});

App.ProvisionManageIndividualemployeeController = zen._ProvisionFlowController.extend({
});

zen._EmployeeListMixin = Ember.Mixin.create({
	numSubControllers: Ember.computed.alias('displayEmployees.length'),
	displayEmployees: function() {
		return this.filterBy('showEmployee', true);
	}.property('@each.showEmployee'),
});

App.ProvisionMatchMultipleController = Ember.ArrayController.extend(zen._EmployeeListMixin, {
	needs: ['provision/manage/all'],
	canAccessITProvisioning: Ember.computed.alias('controllers.provision/manage/all.canAccessITProvisioning'),
	multipleList: true,
	showEmployees: false,
	showX: true,
	itemController: 'multipleEmployee',
	model1: Ember.computed.alias('array3'),
	toggleIsEditing: function(serviceUser) {
			serviceUser.set('isEditing', !serviceUser.get('isEditing'));
		},
	saveServiceUser: function(employee) {
		console.log(employee);
		employee.saveServiceUser(employee);
		employee.selectServiceUser();
	},
		toggleGroup: function(user) {
			// current bugs:
			// won't let you delete
			var userToSwapIn = user.get('swapLocation');
			var oldEmp = user.get('employee');

			if (!userToSwapIn || userToSwapIn.get('id') != user.get('id')) {

				if (userToSwapIn) {
					userToSwapIn.set('employee', oldEmp);
					userToSwapIn.save();
				}

				user.set('employee', null);
				user.save();
			}
		},
		title1: "Employees with Possible Matches",
		displayNumber1: Ember.computed.alias('number3'),
});

App.ProvisionMatchUnmatchedController = Ember.ArrayController.extend(zen._EmployeeListMixin, {
	needs: ['provision/manage/all'],
	showX: true,
	unmatchedList: true,
	itemController: 'unmatchedEmployee',
	showEmployees: false,
	title1: "Unmatched Employees",
	displayNumber1: function() {
		return (this.get('allEmployees') || []).filter(function(employee) {
			return this.get('showEmployee');
		}).length;
	}.property('allEmployees.@each.showEmployee'),
	selectServiceUser: function(employee) {
		employee.selectServiceUser();
	},
	saveServiceUser: function(employee) {
		console.log(employee);
		employee.saveServiceUser(employee);
		employee.selectServiceUser();
	},
	toggleIsEditing: function(serviceUser) {
			serviceUser.set('isEditing', !serviceUser.get('isEditing'));
		},
});

App.ProvisionMatchTerminatedsController = Ember.ArrayController.extend(zen._EmployeeListMixin, {
	needs: ['provision/manage/all'],
	terminatedList: true,
	itemController: 'terminatedEmployee',
	showCheck: true,
	showEmployees: false,
	displayNumber1: function() {
		return (this.get('allEmployees') || []).filter(function(employee) {
			return this.get('showEmployee');
		}).length;
	}.property('allEmployees.@each.showEmployee'),
	title1: "Terminated Employees With Active Accounts",

});

App.ProvisionMatchActivesController = Ember.ArrayController.extend(zen._EmployeeListMixin, {
	needs: ['provision/manage/all'],
	activeList: true,
	itemController: "activeEmployee",
	displayNumber1: function() {
		return (this.get('allEmployees') || []).filter(function(employee) {
			return this.get('showEmployee');
		}).length;
	}.property('allEmployees.@each.showEmployee'),
	showEmployees: false,
	showCheck: true,
	title1: "Active Employees",
});

App.ProvisionMatchDeactivatedsController = Ember.ArrayController.extend(zen._EmployeeListMixin, {
	deactivatedList: true,
	itemController: 'deactivatedEmployee',
	model1: Ember.computed.alias('array5'),
	showTrash: true,
	showEmployees: false,
	deactivatedTable: true,
	title1: "Deactivated Employees",
  // TODO: This function will return 0 or length of an array this.get('allEmployees')
	// depending on the boolean value of this.get('showEmployee'). Looks like there is a
	// mistake somewhere here, probabaly this.get('showEmployee') should be employee.get('showEmployee').
	// Please REVIEW.
	displayNumber1: function() {
		return (this.get('allEmployees') || []).filter(function(employee) {
			return this.get('showEmployee');
		}).length;
	}.property('allEmployees.@each.showEmployee'),
});

App.GroupDisplayerController = zen._TransactionSavingController.extend({
	needs: ['provision/manage/groups'],
	numMembersReal: function(){
		return this.get('model.activeMembersCount') + this.get('delta');
	}.property('model.activeMembersCount', 'delta'),
	canAccessITProvisioning: Ember.computed.alias('parentController.canAccessITProvisioning'),
	allUnremovedMembersUnfiltered: Ember.computed.filterByProperty('controllers.provision/manage/groups.allUsersUnloaded', 'isRemoved', false),
	allUnremovedMembers: function(){
		return (this.get('allUnremovedMembersUnfiltered') || []).filter(function(member){
			return !member.get('isSyncedObject');
		});
	}.property('allUnremovedMembersUnfiltered.@each.displayGroupName'),
	allSyncedObjects: function(){
		return (this.get('allUnremovedMembersUnfiltered') || []).filter(function(member){
			return member.get('isSyncedObject');
		});
	}.property('allUnremovedMembersUnfiltered.@each.displayGroupName'),
	subserviceUnremovedPeople: Ember.computed.alias('model.itSubserviceUsers'),
	subserviceMembers: function(){
		var a = Em.A();
		this.get('subserviceUnremovedPeople').forEach(function(arrObj) {
			if(!arrObj.get('isAdmin') && arrObj.get('isActiveNonShadow')) {
				a.pushObject(arrObj);
			}
		});
		return a;
	}.property('subserviceUnremovedPeople.@each.isAdmin', 'subserviceUnremovedPeople.@each.isActiveNonShadow', 'subserviceUnremovedPeople.@each.isLoaded'),
	subserviceAdmins: function(){
		var a = Em.A();
		this.get('subserviceUnremovedPeople').forEach(function(arrObj) {
			if(arrObj.get('isAdmin') && arrObj.get('isActiveNonShadow')) {
				a.pushObject(arrObj);
			}
		});
		return a;
	}.property('subserviceUnremovedPeople.@each.isAdmin', 'subserviceUnremovedPeople.@each.isActiveNonShadow', 'subserviceUnremovedPeople.@each.isLoaded'),
	membersInGroup: function(){
		var a = Em.A();
		this.get('subserviceMembers').forEach(function(arrObj) {
			if(arrObj.get('serviceUser') && !arrObj.get('serviceUser.isSyncedObject') && !arrObj.get('serviceUser.isRemoved')) {
				a.pushObject(arrObj.get('serviceUser'));
			}
		});
		return a;
	}.property('subserviceMembers.@each.serviceUser', 'subserviceMembers.@each.serviceUser.name'),
	syncedObjectsInGroup: function(){
		var a = Em.A();
		this.get('subserviceMembers').forEach(function(arrObj) {
			if(arrObj.get('serviceUser') && arrObj.get('serviceUser.isSyncedObject') && !arrObj.get('serviceUser.isRemoved')) {
				a.pushObject(arrObj.get('serviceUser'));
			}
		});
		return a;
	}.property('subserviceMembers.@each.serviceUser', 'subserviceMembers.@each.serviceUser.name'),
	adminsInGroup: function(){
		var a = Em.A();
		this.get('subserviceAdmins').forEach(function(arrObj) {
			if(arrObj.get('serviceUser') && !arrObj.get('serviceUser.isRemoved')) {
				a.pushObject(arrObj.get('serviceUser'));
			}
		});
		return a;
	}.property('subserviceAdmins.@each.serviceUser', 'subserviceAdmins.@each.serviceUser.name'),
	membersInGroupEditable: Ember.computed.oneWay('membersInGroup'),
	adminsInGroupEditable: Ember.computed.oneWay('adminsInGroup'),
	membersInGroupOriginal: Ember.computed.oneWay('membersInGroup'),
	adminsInGroupOriginal: Ember.computed.oneWay('adminsInGroup'),
	syncedObjectsInGroupEditable: Ember.computed.oneWay('syncedObjectsInGroup'),
	syncedObjectsInGroupOriginal: Ember.computed.oneWay('syncedObjectsInGroup'),
	allInGroup: Ember.computed.mapByProperty('subserviceUnremovedPeople', 'serviceUser'),
	addedSyncedObjects: Ember.computed.setDifference('syncedObjectsInGroupEditable', 'syncedObjectsInGroupOriginal'),
	removedSyncedObjects: Ember.computed.setDifference('syncedObjectsInGroupOriginal', 'syncedObjectsInGroupEditable'),
	addedMembers: Ember.computed.setDifference('membersInGroupEditable', 'membersInGroupOriginal'),
	removedMembers: Ember.computed.setDifference('membersInGroupOriginal', 'membersInGroupEditable'),
	addedAdmins: Ember.computed.setDifference('adminsInGroupEditable', 'adminsInGroupOriginal'),
	removedAdmins: Ember.computed.setDifference('adminsInGroupOriginal', 'adminsInGroupEditable'),
	membersEqual: Ember.computed.arraysEqual('membersInGroupEditable', 'membersInGroupOriginal'),
	adminsEqual: Ember.computed.arraysEqual('adminsInGroupOriginal', 'adminsInGroupEditable'),
	syncedObjectsEqual: Ember.computed.arraysEqual('syncedObjectsInGroupEditable', 'syncedObjectsInGroupOriginal'),
	actionsToSave: function(){
		return !this.get('membersEqual') || !this.get('adminsEqual') || !this.get('syncedObjectsEqual');
	}.property('membersEqual', 'adminsEqual', 'syncedObjectsEqual'),
	emptyArray: Em.A(),
	delta: 0,
	toggleGroupDisplayingProperty: function() {
		var group = this.get('model');
		group.set('isDisplaying', !this.get('isDisplaying'));
	},
	cancel: function() {
		this.set('membersInGroupEditable', this.get('membersInGroupOriginal'));
		this.set('adminsInGroupEditable', this.get('adminsInGroupOriginal'));
		this.set('syncedObjectsInGroupEditable', this.get('syncedObjectsInGroupOriginal'));
	},
	addAndRemoveUsers: function(usersToAdd, usersToRemove, isAdmin) {
		var removedSubserviceUsers = [];
		var subserviceUsers = this.get('model.itSubserviceUsers');
		subserviceUsers.forEach(function(user) {
			var relevantServiceUser = user.get('serviceUser');
			if (usersToRemove.indexOf(relevantServiceUser) > -1) {
				removedSubserviceUsers.push(user);
			}
		});
		removedSubserviceUsers.forEach(function(user) {
			if (user.get('id')){
				user.set('lastRemovalDate', user.get('dateToRemove'));
				user.save();
			}
		});
		usersToAdd.forEach(function(serviceUser) {
			console.log('creating subuser');
			var newRecord = App.ItSubserviceUser.createRecord({
				status: "Set", // add it in, admin privileges
				isApproved: true,
				serviceUser: serviceUser,
				isAdmin: isAdmin,
				isLeadAdmin: isAdmin,
				companySubservice: this.get('model'),
			});
			newRecord.save();
		}.bind(this));
	},
	changeUserStatus: function(usersToChange, newAdminStatus) {
		var subserviceUsers = this.get('model.itSubserviceUsers');
		var changeUsers = subserviceUsers.filter(function(subUser) {
			var users = usersToChange.some(function(servUser) {
				return subUser.get('serviceUser') == servUser;
			});
			return users;
		});
		var promises = changeUsers.map(function(subUser) {
			console.log();
			subUser.set('isAdmin', newAdminStatus);
			subUser.set('isLeadAdmin', newAdminStatus);
			return subUser.save();
		});
		wrapArrayPromise(promises);
	},
	save: function() {
		// need to do some set intersection stuff to separate stuff out.
		var serviceMembersToAdd = this.get('addedMembers');
		var serviceMembersToRemove = this.get('removedMembers');
		var serviceAdminsToAdd = this.get('addedAdmins');
		var serviceAdminsToRemove = this.get('removedAdmins');
		var serviceSyncedObjectsToAdd = this.get('addedSyncedObjects');
		var serviceSyncedObjectsToRemove = this.get('removedSyncedObjects');


		var delta = ((serviceMembersToAdd.length + serviceAdminsToAdd.length + serviceSyncedObjectsToAdd.length) -
					 (serviceMembersToRemove.length + serviceAdminsToRemove.length  + serviceSyncedObjectsToRemove.length));

		this.set('delta', this.get('delta') + delta);

		// compute differences
		var serviceMembersToAddOnly = serviceMembersToAdd.filter(function(x) {
			return serviceAdminsToRemove.indexOf(x) < 0;
		});
		var serviceMembersToRemoveOnly = serviceMembersToRemove.filter(function(x) {
			return serviceAdminsToAdd.indexOf(x) < 0;
		});
		var serviceAdminsToAddOnly = serviceAdminsToAdd.filter(function(x) {
			return serviceMembersToRemove.indexOf(x) < 0;
		});
		var serviceAdminsToRemoveOnly = serviceAdminsToRemove.filter(function(x) {
			return serviceMembersToAdd.indexOf(x) < 0;
		});

		// compute intersections
		var serviceAdminsToUpgrade = serviceAdminsToAdd.filter(function(x) {
			return serviceMembersToRemove.indexOf(x) > -1;
		});
		var serviceAdminsToDowngrade = serviceAdminsToRemove.filter(function(x) {
			return serviceMembersToAdd.indexOf(x) > -1;
		});

		//differences are adds or removes
		this.addAndRemoveUsers(serviceMembersToAddOnly, serviceMembersToRemoveOnly, false);
		this.addAndRemoveUsers(serviceSyncedObjectsToAdd, serviceSyncedObjectsToRemove, false);
		this.addAndRemoveUsers(serviceAdminsToAddOnly, serviceAdminsToRemoveOnly, true);

		// intersections are change status
		this.changeUserStatus(serviceAdminsToUpgrade, true);
		this.changeUserStatus(serviceAdminsToDowngrade, false);

		this.set('membersInGroupOriginal', this.get('membersInGroupEditable'));
		this.set('adminsInGroupOriginal', this.get('adminsInGroupEditable'));
		this.set('syncedObjectsInGroupOriginal', this.get('syncedObjectsInGroupEditable'));
	}
});

App.ProvisionManageAllController = zen._ProvisionFlowController.extend(zen._LoadAllEmployeesMixin, {
	needs: ['provision/match/multiple', 'provision/match/terminateds', 'provision/match/deactivateds', 'provision/match/actives'],
	canAccessITProvisioningOrSensitive: Ember.computed.or('canAccessSensitive', 'canAccessITProvisioning'),
	triggerLoadingProperty: true,
	selectedGroupUsers: Ember.computed.alias('selectedITService.itServiceUsers'),
	selectedITService: Ember.computed.alias('companyItServices.firstObject'),
	unmatchedUsers: function() {
		var selectedGroupUsers = this.get('selectedGroupUsers') || [];
		return selectedGroupUsers.filter(function(user) {
			return user.get('insideOrganization') === true && !user.get('companySubservice');
		});
	}.property('selectedGroupUsers.@each.insideOrganization', 'selectedGroupUsers.@each.companySubservice'),
	firstService: function() {
		var itServices = this.get('itServices') || [];
		return itServices.firstObject;
	}.property('itServices'),
	toggleIsEditing: function(object) {
		object.set('isEditing', !object.get('isEditing'));
	},
	resetChildControllers: function() {
		console.log('controllers');
		console.log(this.get('selectedITService.id'));
		this.set('itServiceID', this.get('selectedITService.id'));
	}.observes('selectedITService.id'),
});

App.ProvisionUserDeactivateController = zen._ProvisionFlowController.extend({
	valueSelected: function() {
		var deactivateEmail = this.get('deactivateEmail');
		return deactivateEmail === true || deactivateEmail === false;
	}.property('deactivateEmail'),
	deactivate: function() {
		var employee = this.get('model');
		if (!this.get('valueSelected')) {
			this.set('errorText', 'Please select whether to transfer or deactivate this employee');
		}
		else {
			var serviceUsers = employee.get('itServiceUsers') || [];
			var inactivationType = this.get('deactivateEmail') ? "suspend" : "transfer";
			var today = moment.tz("America/Los_Angeles").format('MM/DD/YYYY');
			serviceUsers.filter(function(user) {
				return user.get('matchAccuracy') >= 80;
			}).forEach(function(user) {
				user.set('inactivationType', inactivationType);
				user.set('inactivationDate', today);
				user.save();
			});
			employee.set('removeFromList', true);
			this.send('hideModal');
		}
	},
	resetErrorText: function() {
		if (this.get('valueSelected')) {
			this.set('errorText', '');
		}
	}.observes('valueSelected', 'errorText'),
	cancel: function() {
		this.send('hideModal');
	},
});

App.ProvisionManageGroupsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAnyMixin, {
	model: function(params) {
		return this.all({
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"company": dashboard('company'),
			"itServices" : App.ItService.find(),
			"companyItServices": dashboard('company.companyItServices'),
			"managedGroups" : App.ManagedGroups.find(),
			"membershipsToApprove": App.MembershipsToApprove.find(),
			"serviceUsersGetter" : dashboard('company').then(function(company){
				return company.get('companyItServices'); }).then(function(services) {
					return services.map(function(service) {
						return service.get('itServiceUsers').map(function(serviceUser) {
							return serviceUser.get('username');
						});
					});
				}),
			"isAdmin": dashboard('isAdmin'),
			"locations" : dashboard('company.locations'),
			"departments" : dashboard('company.departments'),
			"canAccessITProvisioning" : dashboard('canAccessITProvisioning'),
		});
	},
});

App.ProvisionManageMembershipsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAnyMixin, {
	model: function(params) {
		return this.all({
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"company": dashboard('company'),
			"itServices" : App.ItService.find(),
			"companyItServices": dashboard('company.companyItServices'),
			"isAdmin": dashboard('isAdmin'),
			"_employee": dashboard('employee'),
			"_itServiceUsers": dashboard('employee').then(function(employee){
				return employee.get('itServiceUsers');
			}),
			"canAccessITProvisioning" : dashboard('canAccessITProvisioning'),
		});
	},
});

App.ProvisionManageGroupsController = zen._TransactionSavingController.extend(zen._DebouncedPropertiesMixin, {
	pageTitle: "Groups You Manage",
	groupsUrl: function(){
		var serviceURLs = this.get('companyItServices.firstObject.serviceURLs');
		var commaPos = serviceURLs.indexOf(',');
		if (commaPos > -1){
			return serviceURLs.substr(0,commaPos);
		}
		return "";
	}.property('companyItServices.firstObject.serviceURLs'),
	isOneMembership: Ember.computed.equal('membershipsToApprove.length', 1),
	membershipsStillToApprove: Ember.computed.filterByProperty('membershipsToApprove', 'isRequested', true),
	googleGroupsUrl: function(){
		return "https://groups.google.com/a/" + this.get('groupsUrl') + "/forum/#!creategroup";
	}.property('groupsUrl'),
	groupsPage: true,
	// TODO: add in the ZenMultiSelect from DD.
	canAccessITProvisioningOrSensitive: Ember.computed.or('canAccessSensitive', 'canAccessITProvisioning'),
	debouncedProperties: [
		['undebouncedFilterText', { debouncedPropertyName: "filterText", delay: 1000 }],
	],
	selectedITService: Ember.computed.alias('companyItServices.firstObject'),
	loadItServiceUsers: function() {
		var selectedCompanyService = this.get('selectedITService');
		if (selectedCompanyService) {
			selectedCompanyService.get('itServiceUsers');
		}
	}.observes('selectedITService'),
	setPageNum: function(number) {
		this.set('pageNum', number);
	},
	// normal JS range function
	range: function(start, stop, step) {
		if (typeof stop=='undefined') {
			// one param defined
			stop = start;
			start = 0;
		}
		if (typeof step=='undefined') {
			step = 1;
		}
		if ((step>0 && start>=stop) || (step<0 && start<=stop)) {
			return [];
		}
		var result = [];
		for (var i=start; step>0 ? i<stop : i>stop; i+=step) {
			result.push(i);
		}
		return result;
	},
	pageNums: function() {
		var pageLength = this.get('pageLength');
		if (!pageLength) {
			return [];
		}
		var endNum = Math.floor((this.get('filteredGroups.content.length') || 0) / pageLength);
		return this.range(0, endNum, 1);
	}.property('filteredGroups.@each', 'pageLength'),
	selectedPageNum: function() {
		if (this.get('pageNum') != null) {
			return this.get('pageNum');
		}
		if (!this.get('pageLength')) {
			return 0;
		}
		return 1;
	}.property('pageNum', 'pageLength'),
	pageLength: 0,
	adminPrivileges: function(){
		return (this.get('canAccessITProvisioning') || this.get('canAccessSensitive')) && this.get('isAdmin');
	}.property('canAccessITProvisioning', 'canAccessSensitive', 'isAdmin'),
	filteredPaginatedGroups: function() {
		var filteredGroups = this.get('filteredGroups');
		var pageLength = this.get('pageLength');
		var pageNum = this.get('selectedPageNum');
		if (pageNum === 0 || !filteredGroups) {
			return filteredGroups;
		}
		return filteredGroups.slice((pageNum -1) * pageLength, pageNum * pageLength);
	}.property('filteredGroups.@each', 'selectedPageNum', 'pageLength'),
	filteredPaginatedGroupsAdmin: Ember.computed.alias('filteredPaginatedGroups'),
	filterSetter: function(){
		var filterText = this.get('filterText') || '';
		var filterType = this.get('filterType');

		if (filterText && (filterType == 'Employee Email' || filterType == 'Employee Name')) {
			var param = (filterType == 'Employee Email') ? 'username' : 'name';
			Ember.$.ajax({
				url: '/groupsSearch?' + param + '=' + filterText,
				type: "get",
				success: function(response) {
					if (response['tag']) {
						this.set('ids', response);
					}
				}.bind(this),
				error: function(jqXHR, textStatus, errorThrown) {
				}
			});
		}
	}.observes('filterText', 'filterType'),
	ids: {
		tag: 'original'
	},
	filteredGroups: function() {
		var filterText = this.get('filterText') || '';
		var filterType = this.get('filterType');
		var allGroups = this.get('validatedGroups');

		if (!filterText) {
			return allGroups;
		}
		// for group name and email we can do the filtering on the frontend
		if (filterType == 'Group Name') {
			return allGroups.filter(function(group) {
				var group_name = (group.get('name') || '').toLowerCase();
				return group_name.indexOf(filterText.toLowerCase()) > -1;
			});
		}
		if (filterType == 'Group Email') {
			return allGroups.filter(function(group) {
				var group_username = (group.get('username') || '').toLowerCase();
				return group_username.indexOf(filterText.toLowerCase()) > -1;
			});
		}

		// for employee name or email we need to do the filtering on the backend
		if (filterType == 'Employee Email' || filterType == 'Employee Name') {
			var ids = this.get('ids');
			return allGroups.filter(function(group) {
				return ids[group.get('id')] === "";
			});
		}
	}.property('validatedGroups', 'filterText', 'filterType', 'selectedPageNum', 'ids.tag'),
	managedGroupsAlias: function(){
		return (this.get('managedGroups') || []).filter(function(group) {
			return true;
		});
	}.property('managedGroups.@each.id'),
	companyItServices: Ember.computed.alias('company.companyItServices'),
	allUsersUnloaded: Ember.computed.alias('selectedITService.itServiceUsers'),
	triggerLoadingProperty: true,
	// validation for ManagedGroups done on the backed
	validatedGroups: Ember.computed.filterByProperty('groups', 'isActive', true),
	groups: Ember.computed.alias('managedGroups'),
	itServicesAlt: Ember.computed.mapProperty('companyItServices', 'name'),
	toggleGroupDisplay: function(group) {
		var currentValue = group.get('isDisplaying');
		group.set('isDisplaying', !currentValue);
	},
	save: function() {
		this.saveAndContinue().then(function() {
			this.transitionToRoute('provision.review.active');
		}.bind(this));
	},
	singleItem: function(){
		return [0];
	}.property()
});

App.ProvisionManageMembershipsController = App.ProvisionManageGroupsController.extend({
	groupsPage: false,
	pageTitle: "Your Group Memberships",
	myMemberships: [],
	hideOwnMemberships: false,
	showOwnMemberships: Ember.computed.not('hideOwnMemberships'),
	thisServiceUser: function(){
		var activeUsers = this.get('_itServiceUsers').filter(function(serviceUser){
			return serviceUser.get('matchAccuracy') >= 80 && ['Act', 'Imp', 'Mat'].indexOf(serviceUser.get('status')) > -1;
		});
		return activeUsers.length ? activeUsers[0] : null;
	}.property('_itServiceUsers.@each.status', '_itServiceUsers.@each.matchAccuracy'),
	addMembership: function(group){
		group.set('isAddingMember', true);
		var membership = App.ItSubserviceUser.createRecord({
			status: "Set", // requested
			isApproved: group.get('isJoinableByAll') ? true : false, // requested
			serviceUser: this.get('thisServiceUser'),
			isAdmin: false,
			isLeadAdmin: false,
			companySubservice: group,
		});
		membership.save();
	},
	removeMembership: function(membership){
		membership.set('lastRemovalDate', membership.get('dateToRemove'));
		membership.save();
	},
	setMemberships: function(){
		var emp_id = this.get('_employee.id');
		var memberships = App.ItSubserviceUser.find({"serviceUser_id__employee_id": emp_id,
													"serviceUser_id__matchAccuracy__gte": 80 });
		this.set('myMemberships', memberships);
	}.property(),
	activeMemberships: function(){
		return this.get('myMemberships').filter(function(membership) {
			return !membership.get('isInactive');
		});
	}.property('myMemberships.@each.isInactive'),
	filteredMemberships: function() {
		var filterText = this.get('filterText') || '';
		var filterType = this.get('filterType');
		var activeMemberships = this.get('activeMemberships');

		if (!filterText) {
			return activeMemberships;
		}
		if (filterType == 'Group Name') {
			return activeMemberships.filter(function(group) {
				var group_name = (group.get('companySubservice.name') || '').toLowerCase();
				return group_name.indexOf(filterText.toLowerCase()) > -1;
			});
		}
		if (filterType == 'Group Email') {
			return activeMemberships.filter(function(group) {
				var group_username = (group.get('companySubservice.username') || '').toLowerCase();
				return group_username.indexOf(filterText.toLowerCase()) > -1;
			});
		}
		return activeMemberships;
	}.property('validatedGroups', 'filterText', 'filterType', 'selectedPageNum', 'myMemberships.@each.isInactive'),
	filteredPaginatedGroupsNonMember: function(){
		var activeMemberships = this.get('activeMemberships');
		return this.get('filteredPaginatedGroups').filter(function(group){
			return !activeMemberships.some(function(membership){
				return membership.get('companySubservice.id') == group.get('id');
			});
		}).filter(function(group){
			return group.get('isVisibleByAll');
		});
	}.property('activeMemberships.@each.id', 'filteredPaginatedGroups.@each.isVisibleByAll'),
	// need to validate these b/c we are just grabbing vanilla companySubservice objects
	validatedGroups: function() {
		return (this.get('groups') || []).filter(function(group) {
			return !group.get('associatedLocation') && !group.get('associatedDepartment') && group.get('isActive');
		});
	}.property('groups.@each.associatedLocation', 'groups.@each.associatedDepartment', 'groups.@each.isActive'),
	groups: Ember.computed.alias('selectedITService.companyItSubservices'),

});

App.ProvisionManageServicesettingsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	afterModel: function(model) {
		return this.all(model, {
			"companyServiceList": App.CompanyItService.find({'itService__id': model.get('id')}),
		});
	},
});

App.ProvisionManageServicesettingsController = zen._ProvisionitDisplayController.extend({
	companyService: Ember.computed.alias('companyServiceList.firstObject'),
	save: function() {
		this.saveAndContinue().then(function() {
			this.transitionToRoute('provision.review.active');
		}.bind(this));
	}
});

App.BackgroundsetupRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return dashboard('company');
	},
});

App.BackgroundsetupController = zen._TransactionSavingController.extend({
	isBackgroundCheckAgreementComplete: Ember.computed.and('onboardingSettings.isBackgroundCheckAgreementComplete'),
});

App.BackgroundsetupAboutRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		App.ProductStage.createBackgroundCheckEvent('marketing_page_view', 0.0);
		App.ProductStage.createBackgroundCheckEvent('progress', 0.33);
	}
});

App.BackgroundsetupAboutController = zen._AbstractSavingController.extend({
	showAboutBackgroundChecks: function() {
		this.eventLogger.log('background-check-about-modal');
		this.send('showModal', 'backgroundcheckabout');
	}
});

App.BackgroundsetupReviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return this.all({
			"employees": dashboard('employees'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
		});
	}
});

App.BackgroundsetupReviewController = zen._AbstractSavingController.extend({
	relevantEmployees: function() {
		return this.get('employees').filterProperty('isDoingBackgroundCheck', true).filterProperty('statusDeleted', false);
	}.property('employees.@each.isDoingBackgroundCheck'),
	save: function() {
		if (!this.get('isBackgroundCheckInfoComplete')) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}
		this.saveAndContinue().then(function() {
			this.transitionToRoute('backgroundsetup.agreement');
		}.bind(this));
	}
});

App.BackgroundsetupCompanyinfoRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		var banksPromise = dashboard('company.banks');
		var companyPromise = dashboard('company');
		var onboardingSettingsPromise = dashboard('company.onboardingSettings');
		var bgcEmployerAgreementPromise = onboardingSettingsPromise.then(function(onboardingSettings) {
			var bgcEmployerAgreementID = onboardingSettings.get('bgcEmployerAgreementID');
			return App.BgcEmployerAgreement.find(bgcEmployerAgreementID);
		});

		return Ember.RSVP.hash({
			banks: banksPromise,
			bgcEmployerAgreement: bgcEmployerAgreementPromise,
			company: companyPromise,
		});
	},
});

App.BackgroundsetupCompanyinfoController = zen._AbstractSavingController.extend({
	backgroundCheckMotorVehicleReportsAllowedOrRequired: function(key, value) {
		if (arguments.length > 1) {
			this.set('bgcEmployerAgreement.motorVehicleReportAllowed', value[0] == 'y');
			this.set('bgcEmployerAgreement.motorVehicleReportRequired', value[1] == 'y');
		}
		var motorVehicleReportAllowed = (
			this.get('bgcEmployerAgreement.motorVehicleReportAllowed') ? "y" : "n"
		);
		var motorVehicleReportRequired = (
			this.get('bgcEmployerAgreement.motorVehicleReportRequired') ? "y" : "n"
		);
		return motorVehicleReportAllowed + motorVehicleReportRequired;
	}.property(
		'bgcEmployerAgreement.motorVehicleReportAllowed',
		'bgcEmployerAgreement.motorVehicleReportRequired'
	),
	backgroundCheckRequiredGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('bgcEmployerAgreement.backgroundCheckRequired', value == 'yes');
		}

		if (this.get('bgcEmployerAgreement.backgroundCheckRequired')) {
			return 'yes';
		} else {
			return 'no';
		}
	}.property('bgcEmployerAgreement.backgroundCheckRequired'),
	banks: Ember.computed.alias('model.banks'),
	bgcEmployerAgreement: Ember.computed.alias('model.bgcEmployerAgreement'),
	company: Ember.computed.alias('model.company'),
	save: function() {
		if (!this.get('company.isBackgroundCheckInfoComplete')) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}

		var banksSavePromise = this.get('banks').save();
		var bgcEmployerAgreementSavePromise = this.get('bgcEmployerAgreement').save();
		var companySavePromise = this.get('company').save();
		var promises = [banksSavePromise, bgcEmployerAgreementSavePromise, companySavePromise];
		wrapArrayPromise(promises).then(function() {
			this.transitionToRoute('backgroundsetup.agreement');
		}.bind(this));

		App.ProductStage.createBackgroundCheckEvent('progress', 0.66);
	},
	sendBGCheckEmailsToManagerGroup: function(key, value) {
		if (arguments.length > 1) {
			this.get('bgcEmployerAgreement').set('sendBGCheckEmailsToManager', value == 'yes');
		}

		var sendBGCheckEmailsToManager = this.get(
			'bgcEmployerAgreement.sendBGCheckEmailsToManager'
		);
		if (sendBGCheckEmailsToManager) {
			return 'yes';
		} else {
			return 'no';
		}
	}.property('bgcEmployerAgreement.sendBGCheckEmailsToManager'),
	setBackgroundCheckTypeOnRequiredChange: function() {
		if (this.get('bgcEmployerAgreement.backgroundCheckRequired') &&
			this.get('bgcEmployerAgreement.backgroundCheckTypesAllowed') === "all") {
			this.set('bgcEmployerAgreement.backgroundCheckTypesAllowed', 'premium');
		}
	}.observes('backgroundCheckRequiredGroup'),
	showAboutBackgroundChecks: function() {
		this.eventLogger.log('background-check-about-modal');
		this.send('showModal', 'backgroundcheckabout');
	},
});

App.BackgroundsetupAgreementRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return dashboard('company');
	},
});

App.BackgroundsetupAgreementController = zen._AbstractSavingController.extend({
	showAboutBackgroundChecks: function() {
		this.eventLogger.log('background-check-about-modal');
		this.send('showModal', 'backgroundcheckabout');
	},
	save: function() {
		if (!this.get('onboardingSettings.isBackgroundCheckAgreementComplete')) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}
		this.saveAndContinue().then(function() {
			this.transitionToRoute('onboarding.settings');
		}.bind(this));

		App.ProductStage.createBackgroundCheckEvent('complete', 1.0);
	}
});


App.OnboardingCustomfieldRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	billing: Ember.inject.service(),
	zPromos: Ember.inject.service(),
	model: function() {
		var hasUnlimitedCustomFieldsFeature = true;
		var promotion = null;
		if(App.switches.isActive('hr1_global'))  {
			hasUnlimitedCustomFieldsFeature = Ember.ajaxGet('/custom_api/all_active_features').then(function(features) {
				return features.includes('unlimitedCustomFields');
      });
			if (App.switches.isActive('show_promo_widget')) {
				promotion = dashboard('isAdmin').then(function(isAdmin) {
					return isAdmin ? this.get('zPromos').getOrCreatePromo('promo_upgrade_to_silver_interstitial') : null;
				}.bind(this));
			}
		}

		return this.all({
			"customFieldSections" : App.CustomFieldSection.find(),
			"customFields" : App.CustomField.find(),
			"isReadOnly": dashboard('isReadOnly'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"company": dashboard('company'),
			"onboardingSettings": dashboard('company.onboardingSettings'),
			"hasUnlimitedCustomFieldsFeature": hasUnlimitedCustomFieldsFeature,
			promo: promotion,
		});

	},
	afterModel: function(model) {
		var customFields = model['customFields'];
		var redirect = true;
		customFields.forEach(function(customField) {
			if (customField.get('id') != null && !customField.get('isSystemField')) {
				redirect = false;
			}
		});
		if (redirect) {
			this.transitionTo("onboarding.addcustomfield", App.CustomField.createRecord({ onboardingSettings: this.get('onboardingSettings') }));
		}
	},
	actions: {
		switchToSilverPlan: function() {
			var returnRoute = this.get('routeName');
			this.get('billing').goToCheckout('SILVER', returnRoute, returnRoute).then(function() {
				this.eventLogger.log('switch_to_silver_interstitial_click_through');
			}).catch(function(error) {
				this.eventLogger.log('error_custom_field_to_checkout', { message: error });
			});
		},
	}
});

zen._DocumentRequestRouteMixin = Ember.Mixin.create({
	afterModel: function(model) {
		var onboardingSettingsPromise = dashboard('company.onboardingSettings');
		var bgcEmployerAgreementPromise = onboardingSettingsPromise.then(
			function(onboardingSettings) {
				return App.BgcEmployerAgreement.find(
					onboardingSettings.get('bgcEmployerAgreementID')
				);
			}
		);
		var options = {
			"isReadOnly": dashboard('isReadOnly'),
			"onboardingSettings": onboardingSettingsPromise,
			"ipAssignments": dashboard('company.onboardingSettings.ipAssignments'),
			"employeeHandbook": dashboard('company.onboardingSettings.employeeHandbook'),
			"company": dashboard('company'),
			"bgcEmployerAgreement": bgcEmployerAgreementPromise,
			"customFields": dashboard('company.onboardingSettings.customFields'),
			"customFieldSections": dashboard('company.onboardingSettings.customFieldSections'),
			"_employeEeoObjects": App.EmployeeEeo.find()
		};

		if (this.get('isSingleEmployeeRequest')) {
			options.selectEmployees = [model];
		}
		else {
			options.allEmployees = dashboard('employees');
			options.allDepartments = dashboard('company.departments');
			options.allLocations = dashboard('company.locations');
		}
		return this.all(model, options);
	},
	loadObjectsPromise: function() {
		var promises = [];
		promises.push(App.SignedIpAssignment.find());
		promises.push(App.NewHire.find());
		promises.push(App.CustomFieldValue.find());
		return Ember.RSVP.all(promises);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('selectAll', true);
		controller.get('onboardingSettings.ipTemplates').setEach('isSelected', false);
		controller.set('loadObjectsPromise', this.loadObjectsPromise());
		controller.set('isDoingEligibilityProofUpload', !controller.get('onboardingSettings.isSkipEligibilityProofUpload'));
		controller.set('errorText', '');
		if (controller.get('requesttype')) {
			//Switch instead of directly mapping query parameter for security reasons
			switch(controller.get('requesttype')) {
				case 'agreements':
					controller.set('selectedOption', 'isDoingAgreements');
					break;
				case 'customfields':
					controller.set('selectedOption', 'isDoingCustomFields');
					break;
				case 'handbooks':
					controller.set('selectedOption', 'isDoingEmployeeHandbook');
					break;
				case 'eligibility':
					controller.set('selectedOption', 'isDoingEligibility');
					break;
				case 'tax':
					controller.set('selectedOption', 'isDoingTax');
					break;
				case 'backgroundcheck':
					if (controller.get('company.onboardingSettings.isBackgroundCheckAgreementComplete')) {
						controller.set('selectedOption', 'isDoingBackgroundCheck');
					} else {
						controller.set('selectedOption', null);
					}
					break;
				case 'registration':
					controller.set('selectedOption', 'resendRegistration');
					break;
			}
		} else {
			controller.set('selectedOption', null);
		}
		controller.set('selectedEHTemplate', null);
	},
});

App.OnboardingBulkemployeerequestRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, zen._DocumentRequestRouteMixin, {
	model: function() {
		return dashboard('employees');
	},
});

App.OnboardingEmployeerequestRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, zen._DocumentRequestRouteMixin, {
	isSingleEmployeeRequest: true,
});

zen._FtPtValidatorMixin = Ember.Mixin.create({
	validate: function(employee, startDate) {
		if (!startDate) {
			this.set("errorText", "All fields are required. Please fill in the missing fields.");
			return false;
		}

		var dateRegEx = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2})*$");
		if (!dateRegEx.test(startDate)) {
			this.set('errorText', "Please enter a valid date in the MM/DD/YYYY format");
			return false;
		}

		var ftDateObj = zen.americanDateAsDate(startDate);
		var hireDateObj = zen.americanDateAsDate(employee.get('currentEmployment.hireDate'));

		if (ftDateObj < hireDateObj) {
			this.set("errorText", this.get('errorMessage'));
			return false;
		}

		var compType = employee.get('currentEmployment.compType');
		if ((compType == 'H' && !employee.get('currentEmployment.payRate')) || (compType == 'S' && !employee.get('currentEmployment.annualSalary'))) {
			this.set("errorText", "All fields are required. Please fill in the missing fields.");
			return false;
		}

		this.set('errorText', "");
		return true;
	},
});


App.BackgroundcheckRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	afterModel: function(model) {
		return this.all(model, {
			"canAccessSensitive": dashboard('canAccessSensitive'),
		});
	}
});

App.ItserviceusergmailtableController = Ember.ObjectController.extend({
	employee: Ember.computed.alias('model'),
	editingIT: Ember.computed.alias('parentController.editingIT'),
	editingITAndUserEditable: Ember.computed.and('editingIT','model.isSettingUpOrActive'),
	displayEditFunctionality: Ember.computed.alias('parentController.displayEditFunctionality'),
	displayPwAndCodes: Ember.computed.and('model.isActive', 'editingIT'),
	suspendAccount: function() {
		var model = this.get('model');
		var today = moment.tz("America/Los_Angeles").format('MM/DD/YYYY');
		model.set('inactivationDate', today);
		model.set('inactivationType', 'suspend');
		model.save();
		this.set('statusMessage', 'Suspending account. Please refresh the page in one minute for a success confirmation.');
	},
	transferAccount: function() {
		var model = this.get('model');
		var today = moment.tz("America/Los_Angeles").format('MM/DD/YYYY');
		model.set('inactivationDate', today);
		model.set('inactivationType', 'transfer');
		model.save();
		this.set('statusMessage', 'Transferring account to manager. Please refresh the page in one minute for a success confirmation.');
	},
	resetPassword: function() {
		var model = this.get('model');
		model.set('userChoiceSet', 'Reset');
		model.save();
		this.set('pwMessage', "We've sent the new password to the employee and their manager.");
	},
	generateCodes: function() {
		var model = this.get('model');
		model.set('userChoiceSet', 'Codes');
		model.save();
		this.set('codeMessage', "We've sent the authorization codes to the employee and their manager.");
	},
	confirmMatch: function() {
		var model = this.get('model');
		model.set('matchAccuracy', 100);
		model.save();
	},
	cancelMatch: function() {
		var model = this.get('model');
		model.set('matchAccuracy', 50);
		model.set('employee', null);
		model.save();
	},
	createImmediately: function() {
		var model = this.get('model');
		var today = moment.tz("America/Los_Angeles").format('MM/DD/YYYY');
		model.set('status', 'Set');
		model.set('dateToCreate', today);
		model.save();
		this.set('statusMessage', "Thanks! We're creating " + model.get('firstName') + "'s account now" );
	},
	usernameObserver: function() {
		var username = this.get('username');
		var displayUsername = this.get('model.displayUsername');
		var model = this.get('model');
		if (username == displayUsername){
			model.set('blockSave', false);
			this.set('errorText', '');
			return;
		}
		var companyService_id = this.get('companyService.id');
		var employee_id = this.get('employee.id');

		if(typeof companyService_id === 'undefined') {
			// shivam.gupta@: the companyService_id is undefined for a scenario which I am not able to repro
			// hence setting the following text on UI to ensure there is no incorrect API call at the backend
			this.set('errorText', 'Internal error. Please try again later.');
			return;
		}

		model.set('blockSave', true);
		this.set('errorText', 'Checking validity...');
		Ember.$.ajax({
			url: '/usernameCheck?username=' + displayUsername + '&employee_id=' + employee_id + "&companyService_id=" + companyService_id,
			type: "get",
			success: function(response) {
				if (response['canCreate']) {
					var model = this.get('model');
					if (!model) {
						return;
					}
					model.set('blockSave', false);
					if (this.get('originalUsername') == this.get('username')) {
						this.set('errorText', '');
					} else {
						this.set('errorText', 'Username available');
					}
				} else {
					this.set('errorText', response['errorMessage']);
				}
			}.bind(this),
			error: function(jqXHR, textStatus, errorThrown) {
			}
		});
	// can't listen on model.displayUsername due to subtle Ember bug --
	// it doesn't update correctly b/c it is a computed property that we overwrite on
	// the frontend -- the dependency graph looks on the dependents.
	}.observes('model.displayHandle'),
});

zen._ItServiceListMixin = Ember.Mixin.create({
	startEditingIT: function() {
		this.set("editingIT", true);
	},
	saveEditingIT: function(section, saveAndTransition) {
		this.set("editingIT", false);
		var itServiceUsers = this.get('itServiceUsers') || [];
		itServiceUsers.filter(function(itServiceUser) {
			return !itServiceUser.get('blockSave');
		}).forEach(function(itServiceUser) {
			itServiceUser.set('username', itServiceUser.get('displayUsername'));
			itServiceUser.save();
		});
		// rollback invalid saves
		itServiceUsers.filter(function(itServiceUser) {
			return itServiceUser.get('blockSave');
		}).forEach(function(itServiceUser) {
			itServiceUser.set('displayHandle', itServiceUser.get('displayHandleOriginal'));
			itServiceUser.rollbackWithChildren();
		});
	},
	cancelEditingIT: function(section) {
		//this.get('model').rollbackWithChildren().then(function() {
		this.set("editingIT", false);
		//}.bind(this));
		var itServiceUsers = this.get('itServiceUsers') || [];
		itServiceUsers.forEach(function(itServiceUser) {
			itServiceUser.set('displayHandle', itServiceUser.get('displayHandleOriginal'));
			itServiceUser.rollbackWithChildren();
		});

	},
	createTeamGroup: function() {
		this.set('creatingTeamGroup', true);
		var employee_name = this.get('model.fullName');
		var new_group = App.CompanyItSubservice.createRecord({
			username: "zenefits_team_" + this.get('model.id') + "@", // will be hashed & appended to in the post_change hook
			companyService: this.get('_gmailService'),
			name: employee_name + "'s Team",
			description: "This group is managed by Zenefits and includes all subordinates of " + employee_name,
			associatedTeamOwner: this.get('model')
		});
		new_group.save();
	},
	teamGroup: Ember.computed.alias('_teamGroups.firstObject'),
	_gmailService: Ember.computed.alias('company.gmailService'),
	displayEditFunctionality: function() {
		return this.get('requester') || this.get('canAccessITProvisioning') || (this.get('canAccessSensitive') && !this.get('isReadOnly'));
	}.property('canAccessSensitive', 'isReadOnly', 'requester', 'canAccessITProvisioning'),
});


zen._RedoRegistrationMixin = Ember.Mixin.create({
	resendNewHireRegistrationEmail: function(newhire, doNotShowSuccessModal) {
		this.set('displayRedoRegistration',false);
		newhire.get('resendRegistrationEmailPromise').then(function () {
			var promise = $.ajax({ url: '/custom_api/resend_newhire_registration/'+newhire.get('id'), type: 'POST' });
			newhire.set('resendRegistrationEmailPromise',promise);
			if(!doNotShowSuccessModal) {
				promise.then(function() {
					this.send('showModal', 'redo.newhire.registration', newhire);
				}.bind(this));
			}
		}.bind(this));
	},
	reshowNewHireRegistrationLink: function(newhire) {
		this.send('showModal', 'redo.newhire.registration', newhire);
	},
	displayRedoRegistration: true,
});

zen._OnboardingActionsRequiredModalMixin = Ember.Mixin.create({
	employerOnboardingTasksWithIconClasses: function() {
		var tasks = this.get('employee.employerOnboardingTasks');
		tasks.forEach(function(task) {
			if (task['status'] == 'done') {
				task['iconClass'] = 'fa-check';
			} else {
				task['iconClass'] = 'fa-times';
			}
		});
		return tasks;
	}.property('employee.employerOnboardingTasks'),

	employeeOnboardingTasksWithIconClasses: function() {
		var tasks = this.get('employee.employeeOnboardingTasks');
		if (tasks.length === 0) {
			return tasks;
		}
		tasks.forEach(function(task) {
			if (task['status'] == 'done') {
				task['iconClass'] = 'fa-check';
			} else {
				task['iconClass'] = 'fa-times';
			}
		});
		return [tasks.slice(0, tasks.length/2 + 1), tasks.slice(tasks.length/2 + 1)];
	}.property('employee.employeeOnboardingTasks'),
});

zen._attributeHistoryMixin = Ember.Mixin.create({
	showAttributeHistory: function(attributeType, displayName) {
		App.EmployeeAttributeHistory.find({employee_id: this.get('model.id'), attribute_type: attributeType})
		.then(function(changes) {
			var data = Ember.Object.create({changes: changes, attributeDisplayName: displayName });
			this.send('showModal', 'attributehistory', data);
		}.bind(this), function(error) {
			this.eventLogger.log('view_attribute_history', {'message': error});
		}.bind(this));
	},
	hasSalaryHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasSalaryHistory'),
	hasTitleHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasTitleHistory'),
	hasLocationHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasLocationHistory'),
	hasDepartmentHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasDepartmentHistory'),
	hasManagerHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasManagerHistory'),
	hasHireDateHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasHireDateHistory'),
	hasEmploymentTypeHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasEmploymentTypeHistory'),
	hasPayScheduleHistory: Ember.computed.bool('employeeAttributeHistory.firstObject.hasPayScheduleHistory'),
	actions: {
		showSalaryHistory: function() {
			this.showAttributeHistory('comp', 'Salary');
		},
		showTitleHistory: function() {
			this.showAttributeHistory('title', 'Title');
		},
		showLocationHistory: function() {
			this.showAttributeHistory('location', 'Location');
		},
		showDepartmentHistory: function() {
			this.showAttributeHistory('department', 'Department');
		},
		showManagerHistory: function() {
			this.showAttributeHistory('manager', 'Manager');
		},
		showHireDateHistory: function() {
			this.showAttributeHistory('hireDate', 'HireDate');
		},
		showEmploymentTypeHistory: function() {
			this.showAttributeHistory('employment', 'Employment Type');
		},
		showPayScheduleHistory: function() {
			this.showAttributeHistory('paySchedule', 'PaySchedule');
		},
	},
});

zen._PayrollSettingsMixin = Ember.Mixin.create({
	isUnderWFCustomProtocolBetaTest: Ember.computed.equal('companyPayroll.isUnderWFCustomProtocolBetaTest', true),
	isPayrollProviderPaychex: Ember.computed.equal('employee.company.payrollProvider', 'PX'),
	isPayrollProviderPaylocity: Ember.computed.equal('employee.company.payrollProvider', 'PL'),
	isPayrollProviderParagon: Ember.computed.equal('employee.company.payrollProvider', 'PG'),
	isPayrollProviderWorkforce: Ember.computed.equal('employee.company.payrollProvider', 'WF'),
	isPayrollProviderYP: Ember.computed.equal('employee.company.payrollProvider', 'YP'),
	isPayrollProviderIntuit: function() {
		var intuitArray = ['IN', 'MP', 'QB'];
		return (intuitArray.indexOf(this.get('employee.company.payrollProvider')) >= 0);
	}.property('employee.company.payrollProvider'),
	isPayrollProviderADP: function() {
		var adpArray = ['AR', 'WF'];
		return (adpArray.indexOf(this.get('employee.company.payrollProvider')) >= 0);
	}.property('employee.company.payrollProvider'),

	showPLPayrollSettings: function() {
		var provider = this.get('employee.company.payrollProvider');
		var fields = this.get('payrollEnumFieldsChoices');
		return provider == 'PL' && Object.keys(fields).length>0;
	}.property('payrollEnumFieldsChoices', 'employee.company.payrollProvider'),

	showPGPayrollSettings: function() {
		var provider = this.get('employee.company.payrollProvider');
		return provider == 'PG';
	}.property('employee.company.payrollProvider'),

	showCostCenterSettings: function() {
		var provider = this.get('employee.company.payrollProvider');
		var fields = this.get('payrollEnumFieldsChoices');
		return (provider == 'PL' && Object.keys(fields).length>0) || provider == 'PG';
	}.property('payrollEnumFieldsChoices', 'employee.company.payrollProvider'),

	isIntuitComplete: Ember.computed.equal('employee.company.isIntuitComplete', true),

	showWFPayrollSettings: function() {
		return this.get('isUnderWFCustomProtocolBetaTest');
	}.property('isUnderWFCustomProtocolBetaTest'),

	showPXPayrollSettings: function() {
		return this.get('isPayrollProviderPaychex') && (this.get('isPXComplete') || this.get('isPXCompClassValid'));
	}.property('isPayrollProviderPaychex', 'isPXComplete', 'isPXCompClassValid'),

	isPXComplete: Ember.computed.equal('employee.company.isPXComplete', true),
	isPXCompClassValid: function() {
		var state = this.get('employee.location.state');
		var compClasses = this.get('employee.company.compClasses').filterProperty('isActive', true).filterProperty('state', state);
		if (compClasses && compClasses.length > 0) {
			return true;
		}

		return false;

	}.property('employee.location.state', 'employee.company.compClasses', 'employee.company.compClasses.@each.isActive', 'employee.company.compClasses.@each.state'),

	showINPayrollSettings: function() {
		return this.get('isPayrollProviderIntuit') && this.get('isIntuitComplete');
	}.property('isIntuitComplete', 'isPayrollProviderIntuit'),

	intuitPayScheduleChoices: function() {
		var schedules = this.get('employee.company.intuitPaySchedules');
		if (!schedules.get('length')) {
			return null;
		}
		var options = [];
		schedules.forEach(function(entry) {
			var freq = entry.get('payFrequency');
			var fullFreq = {'We': 'weekly', 'BW': 'biweekly', 'SM': 'semimonthly', 'Mo': 'monthly'};
			var str = entry.get('description') + " -- on a " + fullFreq[freq] + " frequency";
			options.push({description: str, id: entry.get('code')});
		});
		return options;
	}.property(),

	prettyScheduleDescription: function() {
		var str = 'N/A';
		var intuitPayScheduleId = this.get('employee.payroll.intuitPayScheduleId');
		var schedules = this.get('employee.company.intuitPaySchedules');
		if (!schedules.get('length')) {
			return null;
		}
		schedules.forEach(function(entry) {
			if (entry.get('code') == intuitPayScheduleId) {
				var fullFreq = {'We': 'weekly', 'BW': 'biweekly', 'SM': 'semimonthly', 'Mo': 'monthly'};
				str = entry.get('description') + " -- on a " + fullFreq[entry.get('payFrequency')] + " frequency";
			}
		});
		return str;
	}.property('employee.payroll.intuitPayScheduleId'),

	showPayrollDepartments: function() {
		if (this.get('isPayrollProviderPaychex')) {
			var payrollDepartments = this.get('companyPayrollDepartments').filterProperty('isActive', true);
			if (!(payrollDepartments && payrollDepartments.length)) {
				return false;
			}
			return true;
		}

		if (this.get('showWFPayrollSettings') && this.get('companyPayroll.syncDepartment')) {
			return true;
		}

		return false;
	}.property('isPayrollProviderPaychex', 'companyPayrollDepartments', 'companyPayroll.syncDepartment', 'showWFPayrollSettings'),

	// Show workers comp class if syncWorkersComp is true or the company has Paychex as the payroll provider
	showWorkersCompCode: function() {
		if (this.get('isPayrollProviderPaychex')) {
			var state = this.get('location.state');
			if (!state) {
				return false;
			}
			var compClasses = this.get('workersCompClasses').filterProperty('isActive', true).filterProperty('state', state);
			if (!(compClasses && compClasses.length)) {
				return false;
			}
			return true;
		}
		return this.get('companyPayroll.syncWorkersComp');
	}.property('companyPayroll.syncWorkersComp', 'isPayrollProviderPaychex', 'workersCompClasses', 'location.state'),

	workersCompChoices: function() {
		if (!this.get('showWorkersCompCode')) {
			return null;
		}


		var compClasses = this.get('workersCompClasses').filterProperty('isActive', true);
		if (this.get('isPayrollProviderPaychex')) {
			var state = this.get('location.state');
			compClasses = compClasses.filterProperty('state', state);
		}


		if (!(compClasses && compClasses.length)) {
			this.set('showWorkersCompCode', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < compClasses.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': compClasses[i].get('code') + " - " + (compClasses[i].get('description') || ""),
				 'id': compClasses[i].get('code')}
			));
		}
		return xchoices;
	}.property('showWorkersCompCode', 'location.state', 'workersCompClasses.@each.isActive'),

	showPxLaborAssignments: function() {
		if (!this.get('isPayrollProviderPaychex')) {
			return false;
		}

		var pxLaborAssignments = this.get('pxLaborAssignments').filterProperty('isActive', true);
		if (!(pxLaborAssignments && pxLaborAssignments.length)) {
			return false;
		}
		return true;
	}.property('pxLaborAssignments'),

	pxLaborAssignmentChoices: function() {
		if (!this.get('showPxLaborAssignments')) {
			return null;
		}

		var pxLaborAssignments = this.get('pxLaborAssignments').filterProperty('isActive', true);
		if (!(pxLaborAssignments && pxLaborAssignments.length)) {
			this.set('showPxLaborAssignments', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < pxLaborAssignments.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': pxLaborAssignments[i].get('description'),
				 'id': pxLaborAssignments[i].get('code')}
			));
		}
		return xchoices;
	}.property('showPxLaborAssignments', 'pxLaborAssignments', 'pxLaborAssignments.@each.isActive'),

	payrollDepartmentChoices: function() {
		if (!this.get('showPayrollDepartments')) {
			return null;
		}

		var payrollDepartments = this.get('companyPayrollDepartments').filterProperty('isActive', true).sortBy('code');
		if (!(payrollDepartments && payrollDepartments.length)) {
			this.set('showPayrollDepartments', false);
			return null;
		}

		var xchoices = Ember.A();
		for (var i = 0; i < payrollDepartments.length; ++i) {
			if (this.get('isPayrollProviderPaychex')) {
				xchoices.pushObject(Ember.Object.create(
				{'description': (payrollDepartments[i].get('description') || ""),
				 'id': payrollDepartments[i].get('code')}
				));
			} else {
				xchoices.pushObject(Ember.Object.create(
				{'description': payrollDepartments[i].get('code') + " - " + (payrollDepartments[i].get('description') || ""),
				 'id': payrollDepartments[i].get('code')}
				));
			}
		}
		return xchoices;
	}.property('companyPayrollDepartments.@each.isActive'),

	showPxBusinessLocations: function() {
		if (!this.get('isPayrollProviderPaychex')) {
			return false;
		}

		var pxBusinessLocations = this.get('pxBusinessLocations').filterProperty('isActive', true);
		if (!(pxBusinessLocations && pxBusinessLocations.length)) {
			return false;
		}
		return true;
	}.property('pxBusinessLocations'),
	pxBusinessLocationChoices: function() {
		if (!this.get('showPxBusinessLocations')) {
			return null;
		}

		var pxBusinessLocations = this.get('pxBusinessLocations').filterProperty('isActive', true);
		if (!(pxBusinessLocations && pxBusinessLocations.length)) {
			this.set('showPxBusinessLocations', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < pxBusinessLocations.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': pxBusinessLocations[i].get('description'),
				 'id': pxBusinessLocations[i].get('code')}
			));
		}
		return xchoices;
	}.property('showPxBusinessLocations', 'pxBusinessLocations', 'pxBusinessLocations.@each.isActive'),

	showWfPayClasses: true,

	wfPayClassChoices: function() {
		var wfPayClasses = this.get('companyWfPayClasses').filterProperty('isActive', true);
		if (!(wfPayClasses && wfPayClasses.length)) {
			this.set('showWfPayClasses', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < wfPayClasses.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': wfPayClasses[i].get('code') + " - " + (wfPayClasses[i].get('description') || ""),
				 'id': wfPayClasses[i].get('code')
				}
			));
		}
		return xchoices;
	}.property('companyWfPayClasses.@each.isActive'),

	showWfJobTitle: Ember.computed.oneWay('companyPayroll.syncWfJobTitle'),
	wfJobTitleChoices: function() {
		if (!this.get('showWfJobTitle')) {
			return null;
		}

		var wfJobTitles = this.get('wfJobTitles').filterProperty('isActive', true);
		if (!(wfJobTitles && wfJobTitles.length)) {
			this.set('showWfJobTitle', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < wfJobTitles.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': wfJobTitles[i].get('code') + " - " + (wfJobTitles[i].get('description') || ""),
				 'id': wfJobTitles[i].get('code')}
			));
		}
		return xchoices;
	}.property('showWfJobTitle', 'wfJobTitles.@each.isActive'),

	showWfLocation: Ember.computed.oneWay('companyPayroll.syncWfLocation'),
	wfLocationChoices: function() {
		if (!this.get('showWfLocation')) {
			return null;
		}

		var wfLocations = this.get('wfLocations').filterProperty('isActive', true);
		if (!(wfLocations && wfLocations.length)) {
			this.set('showWfLocation', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < wfLocations.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': wfLocations[i].get('code') + " - " + (wfLocations[i].get('description') || ""),
				 'id': wfLocations[i].get('code')}
			));
		}
		return xchoices;
	}.property('showWfLocation', 'wfLocations.@each.isActive'),

	wfApiIntegrationActive: App.switches.isActive('workforce_api_integration_enabled'),
	showWfWorkerCategory: true,
	showWfAcaBenefitsEligibility: Ember.computed.oneWay('wfApiIntegrationActive'),
	wfWorkerCategoryChoices: function() {
		var wfWorkerCategories = this.get('wfWorkerCategories').filterProperty('isActive', true);
		if (!(wfWorkerCategories && wfWorkerCategories.length)) {
			this.set('showWfWorkerCategory', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < wfWorkerCategories.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': wfWorkerCategories[i].get('code') + " - " + (wfWorkerCategories[i].get('description') || ""),
				 'id': wfWorkerCategories[i].get('code')}
			));
		}
		return xchoices;
	}.property('showWfWorkerCategory', 'wfWorkerCategories.@each.isActive'),

	showWfBusinessUnit: Ember.computed.oneWay('companyPayroll.syncWfBusinessUnit'),
	wfBusinessUnitChoices: function() {
		if (!this.get('showWfBusinessUnit')) {
			return null;
		}

		var wfBusinessUnits = this.get('wfBusinessUnits').filterProperty('isActive', true);
		if (!(wfBusinessUnits && wfBusinessUnits.length)) {
			this.set('showWfBusinessUnit', false);
			return null;
		}
		var xchoices = Ember.A();
		for (var i = 0; i < wfBusinessUnits.length; ++i) {
			xchoices.pushObject(Ember.Object.create(
				{'description': wfBusinessUnits[i].get('code') + " - " + (wfBusinessUnits[i].get('description') || ""),
				 'id': wfBusinessUnits[i].get('code')}
			));
		}
		return xchoices;
	}.property('showWfBusinessUnit', 'wfBusinessUnits.@each.isActive'),

	wfCompanyCodeChoices:  function() {
		if (!this.get('showWfCompanyCode')) {
			return null;
		}

		var scrapedWfCoCodes = this.get('companyScrapedWfCoCodes').filterProperty('isActive', true);
		if (!(scrapedWfCoCodes && scrapedWfCoCodes.length)) {
			this.set('showWfCompanyCode', false);
			return null;
		}
		var xchoices = Ember.A();
		var hasIncludeWfCoCodes = !!this.get('companyPayroll.includeWfCoCodes');
		for (var i = 0; i < scrapedWfCoCodes.length; ++i) {
			if (hasIncludeWfCoCodes && !this.get('companyPayroll.includeWfCoCodes').includes(scrapedWfCoCodes[i].get('code'))) {
				continue;
			}
			xchoices.pushObject(Ember.Object.create(
				{
					'description': (scrapedWfCoCodes[i].get('code') + " - " + scrapedWfCoCodes[i].get('description')),
					'id': scrapedWfCoCodes[i].get('code')
				}
			));

		}
		return xchoices;
	}.property('showWfCompanyCode', 'companyScrapedWfCoCodes', 'companyScrapedWfCoCodes.@each.isActive'),

	payrollEnumFieldsChoices: function () {
		var ret = {};
		var fields = App.Utils.EmployeePayrollEnumFields;
		var provider = this.get('employee.company.payrollProvider');
		for (var i=0; i<fields.length; i++) {
			var field = fields[i];
			if (field.supported_providers.indexOf(provider)<0) {
				continue;
			}
			var all = this.get(field.company_related_name).filterProperty('isActive', true);
			if (!(all && all.length)) {
				continue;
			}
			var xchoices = Ember.A();
			for(var j=0; j<all.length; j++) {
				xchoices.pushObject(Ember.Object.create({
					'description': all[j].get('code') + " - " + (all[j].get('description') || ""),
					'id': all[j].get('code')
				}));
			}
			ret[field.related_name+'Choices'] = xchoices;
		}
		return ret;
	}.property('employee.company.payrollProvider', 'costCenters1', 'costCenters1.@each.isActive', 'costCenters2', 'costCenters2.@each.isActive', 'costCenter3', 'costCenters3.@each.isActive', 'positionCodes', 'positionCodes.@each.isActive'),

	wfSupervisorChoices: function() {
		var employees = this.get('employees').filterProperty('isWfSupervisor', true).filterProperty('status', 'Act');
		if (!employees) { return null; }
		var choices = Ember.A();
		for (var i = 0; i < employees.length; i++) {
			if (this.get('employee.id') != employees[i].get('id') && employees[i].get('payrollId') != null && employees[i].get('payrollId').length > 0) {
				choices.pushObject(Ember.Object.create({
					'payrollId': employees[i].get('payrollId'),
					'name': employees[i].get('first_name') + " " + employees[i].get('last_name'),
				}));
			}
		}
		return choices;
	}.property('employees.@each.payrollId', 'employees.@each.isWfSupervisor', 'employees.@each.status',
		'employees.@each.first_name' , 'employees.@each.last_name', 'employee.id'),

	wfSupervisorName: function() {
		var wfSupervisorId = this.get('employee.payroll.wfSupervisorPayrollId');
		if (wfSupervisorId == null) {
			return null;
		}
		var choices = this.get('wfSupervisorChoices');
		for (var x = 0; x < choices.length; x++) {
			if (choices[x].get('payrollId') == wfSupervisorId) {
				return choices[x].get('name');
			}
		}
		return null;
	}.property('employee.payroll.wfSupervisorPayrollId', 'wfSupervisorChoices'),
});

zen._TaxExemptionMixin = Ember.Mixin.create({
	isPayrollProviderYP: Ember.computed.equal('employee.company.payrollProvider', 'YP'),
	hasActiveExemptions: Ember.computed.notEmpty('activeExemptions'),
	hasRequestedExemptions: Ember.computed.notEmpty('requestedExemptions'),
	hasExemptions: Ember.computed.or('hasActiveExemptions', 'hasRequestedExemptions'),

	actions: {
		showExemptions: function() {
			var employee = this.get('model');
			this.transitionToRoute('payroll.exemptions', employee.get('id'));
		},
	}
});

var SMS_DEVICE_CONSTANT = 'SMS Device';

App.DepartmentautofillController = zen._ModalObjectController.extend({
	needs: 'employeetearsheet',
	baseController: Ember.computed.alias('controllers.employeetearsheet'),
	payrollDepartmentChoices: Ember.computed.alias('baseController.payrollDepartmentChoices'),
	employee: Ember.computed.alias('baseController.employee'),

	save: function() {
		this.get('baseController').saveEditing('payrollsettings', false);
		this.send('hideModal');
	},
	cancel: function() {
		this.send('hideModal');
	},
});



App.Employeei9Route = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	renderTemplate: function() {
		return;
	},
	model: function(params) {
		return Ember.RSVP.resolve();
	},
});

App.Reverifyi9Route = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	renderTemplate: function() {
		return;
	},
	afterModel: function(model) {
		return;
	},
});

App.OfferletterrevokeRoute = Ember.Route.extend(zen._AuthManagerMixin);
App.OfferletterrevokeController = zen._ModalObjectController.extend({
	save: function() {
		var newhire = this.get('model');
		var employee = newhire.get('employee');
		var old = App.EmployeeCustomDocument.createRecord({
			"employee": employee,
			"name": "Prior Offer Letter",
			"url": this.get('offerLetterPdfUrl')
		});
		old.save().then(function() {
			newhire.set('offerLetterSig', null);
			newhire.set('status', 'setting_up');
			newhire.set('isOfferLetterSealed', false);
			return newhire.save();
		}.bind(this)).then(function() {
			this.transitionToRoute('hiring.basicinfo', newhire);
		}.bind(this));
	}
});

App.EmployeedocumentuploadRoute = Ember.Route.extend(zen._AuthManagerMixin);
App.EmployeedocumentuploadController = zen._ModalObjectController.extend(zen._PublicFilePickerMixin, {
	errorText: '',
	documentName: null,
	uploadUrl: null,
	uploadDocument: function() {
		this.pickAndStore('uploadUrl', { "extension": ['.pdf','.doc','.docx','.rtf','txt'] });
	},
	save: function() {
		var employee = this.get('model');
		this.set('errorText', '');
		var documentName = this.get('documentName');
		var uploadUrl = this.get('uploadUrl');
		if (!documentName || !uploadUrl) {
			this.set('errorText', 'All fields are required.');
			return;
		}
		var transaction = App.store.transaction();
		transaction.createRecord(App.EmployeeCustomDocument, {
			"employee": employee,
			"name": documentName,
			"url": uploadUrl
		});
		transaction.commit().then(function() {
			this.set('documentName', null);
			this.set('uploadUrl', null);
			this.set('errorText', '');
			employee.reload().then(function() {
				this.send('hideModal');
			}.bind(this));
		}.bind(this));
	}
});

Ember.computed.isNewHireCurrentlyDoing = function(lookFor) {
	return Ember.computed(function() {
		return this.get('newHires').find(function(h) {
			console.log(lookFor, !h.get('isComplete'), !h.get('setting_up'), h.get(lookFor));
			return !h.get('isComplete') && !h.get('setting_up') && h.get(lookFor);
		});
	}).property('newHires.@each.' + lookFor);
};

zen._AbstractConnectController = zen._AbstractSavingController.extend({
	isWorkForce: Ember.computed.equal('payrollProvider', 'WF'),
	isAdpRun: Ember.computed.equal('payrollProvider', 'AD'),
	isPaychex: Ember.computed.equal('payrollProvider', 'PX'),
	doesPayrollRequirePIN: Ember.computed.equal('payrollProvider', 'PX'),
	filteredName: function() {
		var name = this.get('name');
		return name.replace(/\w\S*/g, function(text) {return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();}).replace(/[\W_]+/g, "").substr(0, 10);
	}.property('name'),
	payrollFormComplete: function() {
		if (!this.get('payrollProvider') || !this.get('payrollUsername') || !this.get('payrollPassword')) {
			return false;
		}
		if (this.get('doesPayrollRequirePIN') && !this.get('payrollPin')) {
			return false;
		}
		return true;
	}.property('payrollProvider', 'payrollUsername', 'payrollPassword', 'doesPayrollRequirePIN', 'payrollPin'),
	pxFormComplete: function() {
		if(!this.get('payrollProvider') || !this.get('payrollClientId')) {
			return false;
		}
		return true;
	}.property('payrollProvider', 'payrollClientId')
});

App.ConnectforquotesRoute = zen._CompanyRoute.extend(zen._MultiModelMixin, {
	model: function() {
		return this.all(this._super.apply(this, arguments), {
			healthTodo: App.SetupTodo.findAll().then(function(todos) {
				todos.find(function(todo) {
					return todo.get('todoKey') === 'HEALTH_AND_BENEFITS';
				});
			})
		});
	},

	redirect: function (model, transition) {
		this._super.apply(this, arguments);

		if(!model.get('isSupported')) {
			this.transitionTo('getquotes');
			return;
		}
	}
});
App.ConnectforquotesController = zen._AbstractConnectController.extend({
	save: function() {
		if(this.get('model.healthTodo')) {
			this.get('model.healthTodo').complete();
		}
		this.saveAndContinue('syncingquotes');
	}
});

App.OnboardingtobenefitsRoute = zen._CompanyRoute.extend();

App.ManualRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('employees'), {
			'company': dashboard('company'),
			'healthTodo': App.SetupTodo.findAll().then(function(todos) {
				todos.find(function(todo) {
					return todo.get('todoKey') === 'HEALTH_AND_BENEFITS';
				});
			})
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('employees', model);
		if (!model.filterProperty('isEmployee').get('length')) {
			controller.createEmployee();
			controller.createEmployee();
		}
	}
});

App.ManualController = Ember.Controller.extend(App.ValidationsControllerMixin, {
	isEmployeeCountLessThan2: Ember.computed.lt('filteredEmployees.length', 2),

	filteredEmployees: Ember.computed(function() {
		return this.get('employees').filter(function(employee) {
			return employee.get('isActive') && employee.get('isFulltime');
		});
	}).property('employees.@each.isActive', 'employees.@each.isFulltime'),

	createEmployee: function() {
		var employee = App.AllEmployee.createRecord({
			'status': 'Act',
			'employmentType': 'FT',
			'creationMethod': 'MA',
		});

		this.get('employees').pushObject(employee);
	},
	deleteEmployee: function(employee) {
		employee.set('status', 'Del');
	},

	isSaving: false,
	actions: {
		save: function() {
			if (this.get('isSaving')) {
				return Ember.RSVP.resolve();
			}
			this.set('isSaving', true);

			if (this.get('isEmployeeCountLessThan2')) {
				this.set('errorText', '');
				return Ember.RSVP.resolve();
			}
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return Ember.RSVP.resolve();
			}

			var hasMissingFields = this.get('filteredEmployees').find(function(employee) {
				return !employee.get('first_name') ||
					!employee.get('last_name') ||
					!employee.get('ageRange') ||
					!employee.get('zip');
			});

			if (hasMissingFields) {
				this.set('errorText', "Some fields are missing, all fields are required");
				return Ember.RSVP.resolve();
			}

			this.set('errorText', "");
			var controller = this;

			if(this.get('healthTodo')) {
				this.get('healthTodo').complete();
			}

			return Ember.RSVP.resolve().then(function() {
				return wrapArrayPromise(controller.get('employees').invoke('save'));
			}).then(function() {
				return controller.get('company').reload();
			}).then(function() {
				controller.set('isSaving', false);
				return controller.transitionToRoute('initialEnrollment');
			});
		}
	}
});

App.SyncingpaychexRoute = zen._CompanyRoute.extend();

App.SyncingquotesRoute = App.SyncingonboardingRoute = App.SyncingptouploadRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function() {
		return dashboard();
	},
	afterModel: function(model) {
		return model.get('company');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var route = this;
		route.set('active', true);
		var company = model.get('company');
		var lastSyncRun = company.get('lastSyncRun');
		controller.set('isSyncCompleted', false);
		var checkSync = function() {
			if (route.get('active') === false) {
				return;
			}
			company.reload().then(function() {
				var finished = company.get('lastSyncRun') != lastSyncRun;
				if (finished) {
					model.reload().then(function() {
						controller.set('isSyncCompleted', true);
					});
				}
				else {
					setTimeout(checkSync, 2000);
				}
			});
		};
		checkSync();
	},
	renderTemplate: function(controller, model) {
		this.render('syncing');
	},
	activate: function() {
		Ember.$.ajax('/api/dosync');
	},
	deactivate: function() {
		this.set('active', false);
	},
});
zen._SyncingController = Ember.ObjectController.extend({
	showManualEEInfoEntryMsg: true,
	isSyncFailure: function() {
		return this.get('isSyncCompleted') && this.get('company.syncStatus') != 'success';
	}.property('company.syncStatus', 'isSyncCompleted'),
	saveAndContinue: function() {
		var dirty = [];
		if (this.get('company.isDirty')) {
			dirty.push(this.get('company'));
		}
		if (this.get('company.primaryBank.isDirty')) {
			dirty.push(this.get('company.primaryBank'));
		}
		var promises = dirty.concat(this.get('employees').filterProperty('isDirty')).map(function(o) { return o.save(); });
		if (promises.length) {
			Ember.RSVP.all(promises).then(this.goToNext.bind(this));
		}
		else {
			this.goToNext();
		}
	},
});
App.SyncingquotesController = zen._SyncingController.extend({
	goToNext: function() {
		this.transitionToRoute('initialEnrollment');
	}
});
App.SyncingonboardingController = zen._SyncingController.extend({
	goToNext: function() {
		this.transitionToRoute('index');
	}
});
App.SyncingptouploadController = zen._SyncingController.extend({
	goToNext: function() {
		this.transitionToRoute('ptosetup.basicinfo');
	}
});


zen._MedicalsettingsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all({
			'isReadOnly': dashboard('isReadOnly'),
			'company': dashboard('company'),
			'settings': dashboard('company.settings'),
			'companyMedicalEnrollments': zen.companyEnrollmentSettingsApi('companyMedicalEnrollments'),
			'companyDentalEnrollments': zen.companyEnrollmentSettingsApi('companyDentalEnrollments'),
			'companyVisionEnrollments': zen.companyEnrollmentSettingsApi('companyVisionEnrollments'),
			'medicalPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'medical');
				}),
			'dentalPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'dental');
				}),
			'visionPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'vision');
				})
		});
	}
});


App.MedicalsettingsSpdsRoute = zen._MedicalsettingsRoute.extend({
	model: function() {
		return this.all({
				'isReadOnly': dashboard('isReadOnly'),
				'company': dashboard('company'),
				"companyMedicalPlans": zen.companyEnrollmentApi('companyMedicalPlans'),
				'companyDentalEnrollments': zen.companyEnrollmentSettingsApi('companyDentalEnrollments'),
				'companyVisionEnrollments': zen.companyEnrollmentSettingsApi('companyVisionEnrollments'),
				'medicalPromises': dashboard('company').then(function(company) {
						return Ember.RSVP.all([
							wrapArrayPromise(company.get('healthEnrollments')),
							wrapArrayPromise(company.get('healthCarriers'))
						]);
					}).then(function(result) {
						return zen.getAdminPromise(result,'medical');
				}),
				'dentalPromises': dashboard('company').then(function(company) {
						return Ember.RSVP.all([
							wrapArrayPromise(company.get('healthEnrollments')),
							wrapArrayPromise(company.get('healthCarriers'))
						]);
					}).then(function(result) {
						return zen.getAdminPromise(result,'dental');
					}),
				'visionPromises': dashboard('company').then(function(company) {
						return Ember.RSVP.all([
							wrapArrayPromise(company.get('healthEnrollments')),
							wrapArrayPromise(company.get('healthCarriers'))
						]);
					}).then(function(result) {
						return zen.getAdminPromise(result,'vision');
					})
				});
	}

});


App.MedicalsettingsRoute = zen._MedicalsettingsRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'isReadOnly': dashboard('isReadOnly'),
			'settings': dashboard('company.settings'),
			'section125': App.AgreementSection125.find({'isActive': true}),
			'medicalPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'medical');
				}),
			'dentalPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'dental');
				}),
			'visionPromises': dashboard('company').then(function(company) {
					return Ember.RSVP.all([
						wrapArrayPromise(company.get('healthEnrollments')),
						wrapArrayPromise(company.get('healthCarriers'))
					]);
				}).then(function(result) {
					return zen.getAdminPromise(result,'vision');
				})
		});
	},
	actions: {
		refresh: function(){
			this.refresh();
		}
	}
});

App._MedicalsettingsOverviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var employeesPromise = dashboard('employees');
		var employeeBenefitsOfferingPropertiesPromise = employeesPromise.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeBenefitsOfferingProperties'));
		});
		return this.all({
			employee: dashboard('employee'),
			employeeHealthStatusProperties: dashboard('employee.employeeHealthStatusProperties'),
			isReadOnly: dashboard('isReadOnly'),
			company: dashboard('company'),
			settings: dashboard('company.settings'),
			employees: employeesPromise,
			employeeBenefitsOfferingProperties: employeeBenefitsOfferingPropertiesPromise,
			companyMedicalCarriers: zen.companyEnrollmentSettingsApi('companyMedicalCarriers'),
			companyDentalCarriers: zen.companyEnrollmentSettingsApi('companyDentalCarriers'),
			companyVisionCarriers: zen.companyEnrollmentSettingsApi('companyVisionCarriers'),
			companyMedicalEnrollments: zen.companyEnrollmentSettingsApi('companyMedicalEnrollments'),
			companyDentalEnrollments: zen.companyEnrollmentSettingsApi('companyDentalEnrollments'),
			companyVisionEnrollments: zen.companyEnrollmentSettingsApi('companyVisionEnrollments'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('eligibleEmployees', controller.get('employees').filterBy('isEligibleForHealth').rejectBy('isInternational'));
	}
});
// App.MedicalsettingsOpenenrollmentRoute = App._MedicalsettingsOverviewRoute.extend();

App.EmployeeBreakdownController = Ember.ObjectController.extend();

App.MedicalsettingsEstimateRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	}
});

App.NewHireDependent = Ember.Object.extend({
	age: null,
	gender: null,
	zipCode: null,
	type: null
});

App.MedicalsettingsEstimateController = Ember.ObjectController.extend(App.ValidationsControllerMixin, {
	age: "",
	zipCode: "",
	gender: "",
	dependents: [],
	medicalPlans: [],
	dentalPlans: [],
	zipCodeErrorReason: "Invalid zip code",
	addDependent: function() {
		var dependents = this.get('dependents');
		var newQD = App.NewHireDependent.create();
		dependents.pushObject(newQD);
	},
	deleteDependent: function(dependent) {
		var dependents = this.get('dependents');
		dependents.removeObject(dependent);
		dependent.destroy();
	},
	calculate: function() {
		var hireAge = this.get('age');
		var hireZip = this.get('zipCode');
		var hireGender = this.get('gender');
		if (!hireAge || !hireZip || !hireGender) {
			this.set('errorText', 'Please fill in the missing fields. All fields are required.');
			return;
		}
		if (this.get('anyErrors')) {
			this.set('errorText', 'Please give valid informations.');
			return;
		}
		var ageParam = hireAge;
		var zipParam = hireZip;
		var typeParam = "";
		var genderParam = hireGender;
		var dependents = this.get('dependents');
		var isValid = true;
		if (dependents) {
			dependents.forEach(function(item) {
				var age = item.get('age');
				var zip = item.get('zipCode');
				var type = item.get('type');
				var gender = item.get('gender');
				if (!age || !zip || !type || !gender) {
					isValid = false;
					return;
				}
				ageParam += "," + age;
				zipParam += "," + zip;
				typeParam += "," + type;
				genderParam += "," + gender;
			});
		}
		if (!isValid) {
			this.set('errorText', 'Please fill in the missing fields. All fields are required.');
			return;
		}
		this.set('errorText', '');
		var self = this;
		$.ajax({
			url: '/getHirePremiums',
			type: "post",
			data: {
				"ages": ageParam,
				"zips": zipParam,
				"types": typeParam,
				"genders": genderParam,
			},
			success: function(response) {
				var medicalPlans = response.medical;
				self.set('medicalPlans', medicalPlans);
				self.set('employeeContribution', response.employeeContribution);
				self.set('dependentContribution', response.dependentContribution);
				self.set('dependentAdditionalContribution', response.dependentAdditionalContribution);
				var dentalPlans = response.dental;
				self.set('dentalPlans', dentalPlans);
				self.set('dentalEmployeeContribution', response.dentalEmployeeContribution);
				self.set('dentalDependentContribution', response.dentalDependentContribution);
				self.set('dentalDependentAdditionalContribution', response.dentalDependentAdditionalContribution);
				var visionPlans = response.vision;
				self.set('visionPlans', visionPlans);
				self.set('visionEmployeeContribution', response.visionEmployeeContribution);
				self.set('visionDependentContribution', response.visionDependentContribution);
				self.set('visionDependentAdditionalContribution', response.visionDependentAdditionalContribution);
				$('html, body').animate({
					scrollTop: $("#plans").offset().top - 100
				}, 1000);
			}
		});
	},
	dependentCount: function() {
		var dependents = this.get('dependents');
		return dependents.get('length');
	}.property('dependents.@each.age')
});


App.MedicalsettingsSwitchcarrierRoute = zen._MedicalsettingsRoute.extend();
App.MedicalsettingsCancelRoute = zen._MedicalsettingsRoute.extend();

zen._InviteEmailsMixin = Ember.Mixin.create({
	_updateEmails: function() {
		var format = this.get('format');
		var domain = this.get('domain');
		if (!format || !domain) { return; }
		var models = this.get('employees') || this.get('content');
		for (var i = 0; i < models.get('length'); i++) {
			var employee = models.objectAt(i);
			if (employee.get('statusDeleted')) {
				continue;
			}
			var email = employee.get('email');
			if (!email) {
				var firstName = employee.get('first_name');
				if (format == 'first') {
					email = firstName + "@" + domain;
				}
				else if (format == 'flast') {
					var f = firstName.substring(0, 1);
					var last = employee.get('last_name');
					email = f + last + "@" + domain;
				}
				else if (format == 'first.last') {
					var last = employee.get('last_name');
					email = firstName + '.' + last + "@" + domain;
				}
				else if (format == 'firstlast') {
					var last = employee.get('last_name');
					email = firstName + last + "@" + domain;
				}
			}
			employee.set('emailGuessed', email.toLowerCase());
		}
	}.observes('format', 'domain'),
	_validate: function(type) {
		var models = this.get('employees') || this.get('model');
		var isValid = true;
		for (var i = 0; i < models.get('length'); i++) {
			var employee = models.objectAt(i);

			if (!employee.get('isActive')) {
				continue;
			}

			if (type == 'fsa' || type == 'employeeemails') {
				if (employee.get('isTerminated') || !employee.get('isFulltime')) {
					continue;
				}
			}

			if (type == 'hra') {
				if (employee.get('isTerminated') || !employee.get('isFulltime') || !employee.get('isEnrollmentCompleteOrApproved')) {
					continue;
				}
			}

			if (type == 'hsa') {
				if (!employee.get('isApproved')) {
					continue;
				}
			}

			if (type == '401k') {
				// TODO: Change the check below to include part-time employees for 401(k) but not temps.
				if (employee.get('isTerminated') || !employee.get('isFulltime')) {
					continue;
				}
			}

			if (type == 'pto') {
				if (employee.get('isTerminated')) {
					continue;
				}
			}

			if (type == 'ucommuter') {
				if (employee.get('isTemp') || !employee.get("isEligibleForCommuter")) {
					continue;
				}
			}

			if (!employee.get('first_name')) {
				this.set('errorText', 'Employee names are mandatory. Please fill in the names for all employees.');
				isValid = false;
				break;
			}
			var emailGuessed = employee.get('emailGuessed');
			emailGuessed = emailGuessed ? emailGuessed.trim() : emailGuessed;
			if (!emailGuessed) {
				var fullName = employee.get('first_name') + " " + employee.get('last_name');
				this.set('errorText', 'Missing email for ' + fullName + '. Email address is required for all employees.');
				isValid = false;
				break;
			}

			var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!re.test(emailGuessed)) {
				var fullName = employee.get('first_name') + " " + employee.get('last_name');
				this.set('errorText', 'Email for ' + fullName + ' - (' + emailGuessed + ') is not a valid email address. Please enter a valid email address.');
				isValid = false;
				break;
			}
		}

		return isValid;
	}
});

zen._MissingEmployeeEmailsMixin = Ember.Mixin.create({
	eligibleEmployees: undefined,
	modelType: undefined,

	employeesWithMissingEmails: function() {
		var employees = this.getWithDefault('eligibleEmployees', []);
		return employees.filter(function(employee) {
			return Ember.isEmpty(employee.get('email'));
		});
	}.property('eligibleEmployees.@each.email'),

	hasMissingEmails: function() {
		return this.get('employeesWithMissingEmails.length') > 0;
	}.property('employeesWithMissingEmails.length'),

	submit: function() {
		var modelType = this.get('modelType');
		if (!this._validate(modelType)) {
			return;
		}
		this.set('buttonClass', 'button grey');
		this.set('buttonText', 'Updating Emails...');
		zen.setEmailToEmailGuessed(this.get('model'), this.get('modelType'));
		this.saveAndContinue(this.get('emailsPreviewRoute'));
	}
});

App.OnboardingRoute = Ember.Route.extend(zen._AuthFullManagerAdminMixin, zen._MultiModelMixin, {
	model: function() {
		return this.all(dashboard('company.onboardingSettings'), {
			managerAndNotAdminWithHirePermissions: dashboard('managerAndNotAdminWithHirePermissions'),
			isReadOnlyOrRestrictSensitiveAdmin: dashboard('isReadOnlyOrRestrictSensitiveAdmin'),
			isAdmin: dashboard('isAdmin'),
			isAdminAll: dashboard('isAdminAll'),
			companySettings: dashboard('company.settings'),
			isAdminDepartment: dashboard('isAdminDepartment'),
			isAdminLocation: dashboard('isAdminLocation'),
			showExperimental: dashboard('company.showExperimental') });
	}
});

App.OnboardingAddofferletterRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function() {
		return App.OfferLetter.createRecord();
	},
	redirect: function() {
		// don't allow the companies for whom the switch is active to access old pages
		if (App.switches.isActive('documents_template_editor')) {
			this.transitionTo('onboarding.offerLetter.create');
		}
	},
	afterModel: function(model) {
		return dashboard('company.onboardingSettings').then(function(onboardingSettings) {
			model.set('onboardingSettings', onboardingSettings);
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('defaultOrCustom', model.get('uploadUrl') ? 'custom' : model.get('template') ? 'default' : null);
		controller.set('errorText', "");
	}
});

App.OnboardingAddBaseCustomfieldRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {

	billing: Ember.inject.service(),

	afterModel: function(model) {

		if(App.switches.isActive('hr1_global') && model.isNew) {

    		Ember.ajaxGet('/custom_api/all_active_features').then(function(features) {
	            if(!features.includes('unlimitedCustomFields')) {
	            	App.CustomField.find().then(function(customFields) {
	            		var customFieldCount = 0;
	            		customFields.forEach(function(customField) {
							if (customField.get('id') != null && !customField.get('isSystemField')) {
								customFieldCount++;
							}
						});
	            		if (customFieldCount >= 3) {
	            			// transition to setup, overview, ilp, or billing.
	                		this.get('billing').guardFeatureWithBilling('unlimitedCustomFields', 'onboarding.customfield', 'onboarding.overview');
	            		}
	            	}.bind(this));
	            }
	        }.bind(this));
    	}

		return this.all(model, {
			'company': dashboard('company'),
			'departments': dashboard('company.departments'),
			'locations': dashboard('company.locations'),
			'approverSettings': dashboard('company.approverSettings'),
			'onboardingSettings': dashboard('company.onboardingSettings'),
			'customFieldSections': App.CustomFieldSection.find(),
			'_waitForCustomFieldConstraints': wrapArrayPromise(model.get(this.constraintsName())),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('_rules', Ember.Object.create({count: 0, rules: Em.A()}));
		// Start off with one rule if there are no rules.
		if (!controller.get(this.constraintsName()).get('length')) {
			controller.addRule();
		}
		controller.set('_promises', []);
	}
});

App.OnboardingAddcustomfieldRoute = App.OnboardingAddBaseCustomfieldRoute.extend({
	constraintsName: function() {
		return 'customFieldConstraints';
	},
});

App.OnboardingAddcustomfieldsectionRoute = App.OnboardingAddBaseCustomfieldRoute.extend({
	constraintsName: function() {
		return 'customFieldSectionConstraints';
	},
});

App.OnboardingAddipassignmentRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function() {
		return App.IpAssignment.createRecord({'type': 'A'});
	},
	redirect: function() {
		// don't allow the companies for whom the switch is active to access old pages
		if (App.switches.isActive('documents_template_editor')) {
			this.transitionTo('onboarding.ipAssignment.create');
		}
	},
	afterModel: function(model) {
		return dashboard('company.onboardingSettings').then(function(onboardingSettings) {
			model.set('onboardingSettings', onboardingSettings);
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('defaultOrCustom', model.get('ipAssignment.uploadUrl') ? 'custom' : model.get('ipAssignment.template') ? 'default' : null);
		controller.set('errorText', "");
	}
});

App.OnboardingAddemployeehandbookRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, {
	model: function() {
		return Ember.RSVP.hash({
			"handbook": App.IpAssignment.createRecord({'type': 'E'}),
			"onboardingSettings": dashboard('company.onboardingSettings'),
		});
	},
	redirect: function() {
		// don't allow the companies for whom the switch is active to access old pages
		if (App.switches.isActive('documents_template_editor')) {
			this.transitionTo('onboarding.employeeHandbook.create');
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', "");
	}
});

App.OnboardingCompanysignatureRoute = App.OnboardingRoute.extend();

App.HiringLoadingRoute = Ember.Route.extend({
	renderTemplate: function(controller, model) {
		this.render('loading', {controller: controller});
	},
});

// TODO HR - Move this to CLI as well
App.HiringRoute = Ember.Route.extend(zen._AuthManagerMixin, {
	model: function(params) {
		var id = params['new_hire_id'];
		if (id == 'null' || id == 'undefined') {
			this.transitionTo('onboarding.hireoradd');
			return;
		}
		return App.NewHire.find(id);
	},
	afterModel: function(model) {
		return thenpath(model, 'employerFlow.sections');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('flow', model.get('employerFlow'));
	},
	redirect: function(model, transition) {
		return this.transitionTo('hiring.v2.basicinfo', model.get('id'));
	},
});

App.ContractorsRoute = Ember.Route.extend( zen._AuthContractorAdminWithWriteAccessMixin, zen._MultiModelMixin, {
	model: function() {
		var zAppSubscriptionsPromise = App.ZAppInstallSubscription.findAll();
		var contractorsZappPromise = zAppSubscriptionsPromise.then(function(zAppSubscriptions) {
			return zAppSubscriptions.find(function(subscription) {
				return subscription.get('appInstall.app.uniqueId') == '1.com.zenefits.ContractorsAdmin';
			});
		});
		return Ember.RSVP.hash({
			"contractorSettings": dashboard('company.contractorSettings'),
			"contractors": App.Contractor.find(),
			"consultantContracts": App.ConsultantContract.find(),
			"payments": App.ContractorPayment.find(),
			"contractorsZapp": contractorsZappPromise,
		}).then(function(o) {
			return Ember.Object.create(o);
		});
	},

	redirect: function(model, transition) {
		if (model.contractorsZapp.get('appInstall.status')!= App.ZAppInstall.OK) {
			this.transitionTo('index');
			return;
		}
	},
});


zen._ContractorsPayMixin = Ember.Mixin.create({
	needs: 'contractors/pay',
	isOnPayDate: Ember.computed.alias('controllers.contractors/pay.showMultipleMode'),
	selectedDate: Ember.computed.alias('controllers.contractors/pay.selectedDate')
});

zen._TransitionMixin = Ember.Mixin.create({
	transition: function(route) {
		this.transitionToRoute(route);
	}
});

var CONTRACTOR_PATH_MAPPINGS = {
	'contractors.payments': "Payment History",
	'contractors.file1099': "1099 Filing",
	'contractors.agreements': "Agreements",
	'contractors.templates': "Templates",
	'contractors.contract': "New Contract",
	'contractors.bankinfo': "Bank Account Information",
};
App.ContractorsController = Ember.ObjectController.extend(zen._ContractorsPayMixin, zen._TransitionMixin, {
	needs: 'application',

	productNavElements: function() {
		//Add all your nav elements
		var listOfNavElements = [
			{
				label: 'View Contractors',
				routeName: 'contractors.list',
			}];

		if (!this.get('contractorSettings.hireContractorOnly')) {
			listOfNavElements.push({
				label: 'Payment History',
				routeName: 'contractors.payments',
			});
		}

		listOfNavElements.push({
			label: 'Agreements',
			routeName: 'contractors.agreements',
		});
		listOfNavElements.push({
			label: 'Templates',
			routeName: 'contractors.templates',
		});

		if (!this.get('contractorSettings.hireContractorOnly')) {
			listOfNavElements.push({
				label: 'Bank Information',
				routeName: 'contractors.bankinfo.summary',
			});
		}
		if (App.switches.isActive('show_contractor_1099_form_filing_summary_view')) {
			listOfNavElements.push({
				label: '1099 Form',
				routeName: 'contractors.1099-form-filing',
				linkedObject: { year: Math.max(moment().year() - 1, 2019) }
			});
		}

		return listOfNavElements;
	}.property('contractorSettings.hireContractorOnly'),
	isNotOnMapping: Ember.computed.none('currentModule'),
	currentModule: function() {
		var currentPath = this.get('controllers.application.currentPath');
		return CONTRACTOR_PATH_MAPPINGS[currentPath];
	}.property('controllers.application.currentPath')
});

App.ContractorsNewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.all(Ember.RSVP.hash({"contractor": App.Contractor.createRecord()}), {
							"consultantContracts": App.ConsultantContract.find(),
							"contract": App.InstantiatedConsultantContract.createRecord(),
		});
	},
	redirect: function(model, transition) {
		this.transitionTo('contractors.list');
	}
});

zen._ContractorsNewController = Ember.Controller.extend(App.ValidationsControllerMixin, {
	selectedContract: null,
	showContractInfo: false,

	contractsSelection: function() {
		var array = [
			Ember.Object.create({ name: "Select Agreement", id: null }),
			Ember.Object.create({ name: "No Agreement", id: 0 })
		];
		var consultantContracts = this.get('consultantContracts');
		if (consultantContracts) {
			return array.concat(consultantContracts.filterProperty('isActive').filterProperty('isTemplatized'));
		}
		return array;
	}.property('consultantContracts.length'),
	activeConsultantContracts: function() {
		var array = [];
		var consultantContracts = this.get('consultantContracts').filterProperty('isActive').filterProperty('isTemplatized');
		if (!Ember.isEmpty(consultantContracts)) {
			array.push(Ember.Object.create({name: "Select Agreement", id: null}));
			return array.concat(consultantContracts);
		} else {
			array.push(Ember.Object.create({ name: "No Contractor Agreements", id: null }));
		}
		return array;
	}.property('consultantContracts.length'),
	onSelectedContract: function() {
		var consultant_contract = this.get('selectedContract');
		if (consultant_contract && !consultant_contract.get('isActive')) {
			consultant_contract = null;
		}

		if (consultant_contract) {
			this.set('contract.consultant_contract', consultant_contract);
			Ember.TEMPLATES['_contractintro'] = Ember.Handlebars.compile(consultant_contract.get('intro'));
			this.set('showContractInfo', true);
		}
		else {
			this.set('showContractInfo', false);
		}
	}.observes('selectedContract'),
	hasSelectedContract: Ember.computed.notEmpty('selectedContract.id'),

	actions : {
		save: function(button) {
			this.set('errorText', '');
			if (!this.get('name') || !this.get('email') || this.get('anyErrors')) {
				this.set("errorText", "All fields are required. Please fill in the missing fields.");
				button.stop();
				return;
			}

			if (this.get('showContractInfo')) {
				if (!this.get('contract.effectiveDate')) {
					this.set("errorText", "All fields are required. Please fill in the missing fields.");
					button.stop();
					return;
				}
				if (Ember.$('.edit-field.virgin').length) {
					this.set("errorText", "There are some fields in the contract which haven't been edited (highlighted in yellow)");
					button.stop();
					return;
				}
				Ember.$('.edit-area').trigger('blur');
			}
			this.set('contractor.name', this.get('name'));
			this.set('contractor.email', this.get('email'));
			this.set('contractor.isActive', true);

			this.get('contractor').save()
				.then(function() {
					if (this.get('showContractInfo')) {
						var contract = this.get('contract');
						contract.set('contractor', this.get('contractor'));
						return contract.save();
					} else {
						return Ember.RSVP.resolve([]);
					}
				}.bind(this))
				.finally(function() {
					button.stop();
					this.send('showModal', 'contractors.new.added', this.get('contractor'));
					this.controllerFor('contractors.new.added').set('nextPageRoute', this.get('nextPageRoute'));
					this.controllerFor('contractors.new.added').set('nextPageVerbose', this.get('nextPageVerbose'));
					this.resetStates();
				}.bind(this));
		}
	},
});

App.ContractorsNewController = zen._ContractorsNewController.extend({
	name: null,
	email: null,
	nextPageRoute: 'contractors.list',
	nextPageVerbose: 'Return to Hire Contractor',
	contractor: Ember.computed.alias('model.contractor'),

	resetStates: function() {
		this.setProperties({'showContractInfo': false, 'selectedContract': null});
		this.set('contractor', App.Contractor.createRecord());
		this.set('contract', App.InstantiatedConsultantContract.createRecord());
		this.setProperties({'name': null, 'email': null});
	},
});

App.ContractorsContractRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthContractorAdminMixin, {
	afterModel: function(model) {
		return this.all(model, {
			"contractor": model,
			"consultantContracts": App.ConsultantContract.find(),
			"contract": App.InstantiatedConsultantContract.createRecord(),
		});
	},
});

App.ContractorsNewAddedController = Ember.ObjectController.extend({
	actions : {
		toNextPage : function() {
			this.send('hideModal');
			this.transitionToRoute(this.get('nextPageRoute'));
		},
	},
});

App.ContractorsContractController = zen._ContractorsNewController.extend({
	name: Ember.computed.alias('contractor.name'),
	email: Ember.computed.alias('contractor.email'),
	nextPageRoute: 'index',
	nextPageVerbose: 'Return to Dashboard',

	resetStates: function() {
		this.setProperties({'showContractInfo': false, 'selectedContract': null});
		this.set('contract', App.InstantiatedConsultantContract.createRecord());
	},
});

App.PaystubRoute = Ember.Route.extend({});

App.PaystubController = Ember.ObjectController.extend({});

App.ContractorsListRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.modelFor('contractors');
	}
});

App.ContractorsListController = Ember.ObjectController.extend({
	showInactive: false,
	doShowInactive: function() {
		this.set('showInactive', true);
	},
	activeContractors: Ember.computed.filterByProperty('contractors', 'isActive', true),
	nonDeletedContractors: Ember.computed.filterByProperty('contractors', 'softDeleted', false),
	inactiveContractors: Ember.computed.filterByProperty('nonDeletedContractors', 'isActive', false),
});

App.ContractortearsheetRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.all(this.modelFor('contractors'), {
				"softDeleted": false,
		});
	},
	afterModel: function(model) {
		if (Ember.isEmpty(model)) {
			this.transitionTo('contractors.list');
			return;
		}
		var all = {
			"displayTwoFactorOption": App.CompanyTwofactorSettings.find().then(function(twoFactorSetting) {
			    return twoFactorSetting.get('firstObject.settingType') === 'HS';
			}),
			"twoFactorDeviceType": Ember.ajax({ url: '/api/two_factor/get_contractor_two_factor_device/'+model.get('id'), type: 'GET' }),
		};
		return this.all(model, all);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var contractors = controller.get('contractors');
		controller.set('contractors', contractors.filterBy('softDeleted', false));
		if (Ember.isEmpty(contractors)) {
			wrapArrayPromise(App.Contractor.find({"softDeleted":false})).then(function(contractors) {
				controller.set('contractors', contractors);
			}).catch(function(reason) {
				this.transitionTo('contractors.list');
			});
		}
	}
});

App.ContractortearsheetController = Ember.ObjectController.extend(zen._TransitionMixin, {
	contractors: [],
	showLLCClassification: Ember.computed.equal('entityType', 'LC'),
	showSoleProprietorship: Ember.computed.equal('entityType', 'SP'),
	showIdentification: Ember.computed.or('showLLCClassification', 'showSoleProprietorship'),
	twoFactorDeviceType: Ember.computed.alias('model.twoFactorDeviceType'),
	isSmstwoFactorDevice: Ember.computed.equal('twoFactorDeviceType.name', SMS_DEVICE_CONSTANT),
	deleteContract: function(contract) {
		contract.deleteRecord();
		return contract.save();
	},
	editing: Ember.Object.create(),
	startEditing: function(section) {
		this.set('isIdentificationSSN', (!this.get('isBusiness') && this.get('taxId')));
		this.set('isIdentificationEIN', (this.get('isBusiness') && this.get('taxId')));
		this.set("editing." + section, true);
	},
	saveEditing: function(section) {
		this.set("editing." + section, false);
		if(this.get('newTaxIdTearSheet')){
			if(this.get('isBusiness')){
				this.set('identification', 'EIN');
			}
			else{
				this.set('identification', 'SSN');
			}
			this.set('taxId', this.get('newTaxIdTearSheet'));
		}
		return this.get('model').save();
	},
	cancelEditing: function(section) {
		this.set("editing." + section, false);

		if (this.get('model.isDirty')) {
			return this.get('model').rollback();
		}
	},
	selectedContractor: function(key, value) {
		if (value) {
			this.transitionToRoute("contractortearsheet", value);
		}
		return value || this.get('model');
	}.property('model'),
	scrollTo: function(section) {
		setTimeout(function () {
			Ember.$('html, body').animate({
				scrollTop: $('.employee-tearsheet.' + section).offset().top
			}, 200);
		}, 10);
	},
	markInactive: function(contractor) {
		contractor.set('isActive', false);
		contractor.set('softDeleted', false);
		return contractor.save();
	},
	markActive: function(contractor) {
		contractor.set('isActive', true);
		contractor.set('softDeleted', false);
		return contractor.save();
	},
	showDeleteConfirm: function(selectedContractor) {
		var modal = 'contractor.delete.confirm';
		this.controllerFor(modal).set('selectedContractor', selectedContractor);
		this.send('showModal', modal);
	},
	reset2FactorDevice: function(employee) {
		var data = {
			employee: employee,
			parentModel: this.get('model'),
		};

		this.send('showModal', '2fa.modals.resetdevice', data);
	},
});

App.ContractorShowRoutingNumberController = Ember.ObjectController.extend({
	actions: {
		close : function() {
			this.send('hideModal');
		}
	},
});

App.ContractorShowAccountNumberController = Ember.ObjectController.extend({
	actions: {
		close : function() {
			this.send('hideModal');
		}
	},
});

App.ContractorShowTaxIdController = Ember.ObjectController.extend({
	actions: {
		close : function() {
			this.send('hideModal');
		}
	},
});

App.ContractorDeleteConfirmController = Ember.Controller.extend({
	deleteContractor: function(contractor) {
		contractor.set('softDeleted', true);
		contractor.set('isActive', false);
		var self = this;
		contractor.save().then(function() {
			self.transitionToRoute('contractors.list');
		}, function() {
			self.set('errorText', 'There was an error deleting the contractor');
			return;
		});
	}
});

App.ContractorsPayConfirmController = Ember.Controller.extend({
	promise: Ember.RSVP.resolve(),
	contractorsWithPayments: Ember.computed.filterByProperty('model.activeContractors', 'payAmount'),
	confirmPayments: function() {
		this.get('promise').then(function() {
			var paymentPromises = this.get('contractorsWithPayments').map(function(contractor) {
				var amount = contractor.get('payAmount').replace(/[^\d.]/g,'');
				return App.ContractorPayment.createRecord({
					'contractor': contractor,
					'amount': amount
				}).save();
			});
			var promises = Ember.RSVP.all(paymentPromises).then(function() {
				return this.get('model.contractorSettings').reload();
			}.bind(this)).then(function() {
				this.send('hideModal');
				this.get('contractorsWithPayments').setEach('payAmount', '');
				return this.transitionToRoute("contractors.payments");
			}.bind(this));
			this.set('promise', promises);
		}.bind(this));
	}
});

App.ContractorsPayBlockController = Ember.Controller.extend();

App.ContractorsPayView = Ember.View.extend({
	didInsertElement: function() {
		$('[data-toggle="tooltip"]').each(function() {
			$(this).tooltip();
		});
	}
});

App.ContractorsPayRoute = Ember.Route.extend(zen._CompanyLegalInfoVerificationRouteMixin, zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.modelFor('contractors');
	},
	afterModel: function(model) {
		if (model.get('contractorSettings.hireContractorOnly')) {
			return this.transitionTo('contractors.list');
		}

		if (!model.get('contractorSettings.hasAuthorizedACH') && model.get('contractorSettings.authSignature')) {
			model.setProperties({'contractorSettings.authSignature': null, 'contractorSettings.authSignatureName': null});
		}
		if (model.get('contractorSettings.didTestTransactionFail') || model.get('contractorSettings.isMissingBank')) {
			if (model.get('contractorSettings.company.bankAccountNumber')) {
				model.set('contractorSettings.company.bankAccountNumber', null);
			}
			if (model.get('contractorSettings.company.bankRoutingNumber')) {
				model.set('contractorSettings.company.bankRoutingNumber', null);
			}
		}

		var needsBankVerification = model.get('contractorSettings.needsBankAccountVerification');
		var hasBankAccounts = model.get('contractorSettings.hasBankAccounts');
		var canSendPayments = model.get('contractorSettings.canSendPayments');
		if (!canSendPayments || needsBankVerification || !hasBankAccounts) {
			return this.transitionTo('contractors.bankinfo.summary');
		}
		var needsAchAuthorization = !model.get('contractorSettings.hasAuthorizedACH');
		if (needsAchAuthorization) {
			return this.transitionTo('contractors.bankinfo.summary');
		}
	},
	needsVerification: function(model) {
		return !model.get('contractorSettings.isCompanyVerified') && !model.get('contractorSettings.hasCompanyVerificationRequest');
	},
	actions: {
		/* OLD */
		addContractor: function() {
			this.send('showModal', 'contractors.pay.add', App.Contractor.createRecord());
		},
		/* NEW */
		showRegisterLink: function(contractor) {
			this.send('showModal', 'contractors.pay.copylink', contractor);
		},
		resendRegistrationEmail: function(contractor) {
			contractor.get('resendRegistrationEmailPromise').then(function () {
				var promise = $.ajax('/actions/resend_contractor_registration/' + contractor.get('id'), { type: 'POST' });
				contractor.set('resendRegistrationEmailPromise', promise);
				return promise;
			}.bind(this)).then(function() {
				contractor.set('hasResentRegistrationEmail', true);
			}.bind(this));
		},
		showPayHelp: function() {
			var name = 'contractors.pay.help';
			this.controllerFor(name).set('pageNumber', 0);
			this.send('showModal', name);
		},
		showFastTrack: function() {
			this.send('showModal', 'contractors.pay.fast_track');
		},
		showScheduleHoliday: function(dates) {
			this.send('showModal', 'contractors.pay.schedule_holiday', dates);
		},
		showCalendarDialog: function(name, model, entry) {
			if (this.get('controller.hasSelectedDate')) {
				return;
			}

			this.controllerFor(name).setProperties({
				'model': model,
				'position': entry.get('dialogPosition')
			});
			this.render(name, {
				outlet: 'contractor_dialog',
				into: 'contractors/pay'
			});

			this.set('controller.selectedDate', entry.get('content'));
		},
		hideCalendarDialog: function() {
			this.disconnectOutlet({
				outlet: 'contractor_dialog',
				parentView: 'contractors/pay'
			});
			this.set('controller.selectedDate', null);
		},
		showScheduler: function(entry) {
			if (entry.get('hasNoCalendarMonth')) {
				return;
			}

			var controller = this.get('controller');
			if (!controller.get('isScheduling')) {
				controller.toggleScheduling();
			}

			this.send('showCalendarDialog', 'contractors.pay.scheduler', controller.get('eligibleContractors'), entry);
		},
		viewScheduled: function(entry) {
			if (entry.get('hasNoCalendarMonth')) {
				return;
			}

			this.get('controller').setProperties({'selectedDate': entry.get('content'), 'isMultipleMode': true});
		},
		willTransition: function() {
			if (this.get('controller.hasSelectedDate')) {
				this.send('hideCalendarDialog');
			}
		},
	}
});

App.BankVerificationMFAField = Ember.View.extend({
	templateName: 'bankaccount/verificationmfafield',
	value: '',
	field: null,
	isSelection: Ember.computed.equal('field.metadata.type', 'selections'),
	isQuestion: Ember.computed.equal('field.metadata.type', 'questions'),
	isCodeMethodSelect: Ember.computed.equal('field.metadata.type', 'list'),
	isCode: Ember.computed.equal('field.metadata.type', 'message'),
});


App.DateEntry = Ember.Object.extend({
	value: null,
	formatShort: Ember.computed.prettyDate('value', 'MMM D'),
	formatLong: Ember.computed.prettyDate('value', 'MMMM D, YYYY'),
	dateOnly: Ember.computed.prettyDate('value', 'D')
});

App.UpcomingPayments = Ember.Object.extend({
	payments: null,
	_showPayments: false,
	total: Ember.computed.sumByProperty('payments', 'amount'),
	onlyOnePayment: Ember.computed.equal('payments.length', 1),
	showPayments: Ember.computed.or('_showPayments', 'onlyOnePayment'),
	setShowPayments: function(value) {
		this.set('_showPayments', value);
	}
});

zen._CalendarMixin = Ember.Mixin.create({
	excludeWeekends: false,
	selectedMonth: function() {
		var months = this.get('months');
		var today = months.find(function(month) {
			return moment().isSame(month.get('value'), 'month');
		});

		return today || months.get('firstObject');
	}.property('months'),
	months: function() {
		var months = Ember.A();
		var current = moment().months(0);
		var inTwoYears = moment(current).add(2, 'years');
		while (inTwoYears.isAfter(current, 'month')) {
			var formattedDate = current.format("MMMM YYYY");
			months.pushObject(Ember.Object.create({"name": formattedDate, "value": moment(current)}));
			current.add(1, 'months');
		}

		return months;
	}.property(),
	monthIndex: function() {
		return this.get('months').indexOf(this.get('selectedMonth'));
	}.property('months', 'selectedMonth'),
	isFirstMonth: Ember.computed.equal('monthIndex', 0),
	isLastMonth: function() {
		return this.get('monthIndex') == this.get('months.length') - 1;
	}.property('monthIndex', 'months.length'),
	decrementMonth: function() {
		if (!this.get('isFirstMonth')) {
			var prevMonth = this.get('months').objectAt(this.get('monthIndex') - 1);
			this.set('selectedMonth', prevMonth);
		}
	},
	incrementMonth: function() {
		if (!this.get('isLastMonth')) {
			var nextMonth = this.get('months').objectAt(this.get('monthIndex') + 1);
			this.set('selectedMonth', nextMonth);
		}
	},
	createDateEntry: function(date) {
		return App.DateEntry.create({"value": date});
	},
	validateWeek: function(week) {
		var max = this.get('excludeWeekends') ? 5 : 7;
		var extra = max - week.length;
		if (extra === 0) {
			return week;
		}

		var extraDays = Ember.A();
		var first = week.get('firstObject.value');

		if (first.date() < 10) {
			var current = moment(first).subtract(extra, 'days');
			while (first.isAfter(current, 'day')) {
				extraDays.pushObject(this.createDateEntry(current));
				current = moment(current).add(1, 'days');
			}

			$.merge(extraDays, week);
			return extraDays;
		}

		var current = week.get('lastObject.value');
		var end = moment(current).add(extra, 'days');
		while (end.isAfter(current, 'day')) {
			current = moment(current).add(1, 'days');
			extraDays.pushObject(this.createDateEntry(current));
		}

		$.merge(week, extraDays);
		return week;
	},
	weeksOfMonth: function() {
		var selected = this.get('selectedMonth.value');
		var daysInMonth = selected.daysInMonth();
		var year = selected.year();
		var month = selected.month();

		var excludeWeekends = this.get('excludeWeekends');
		var initDay = excludeWeekends ? 1 : 0;

		var weeks = [];
		var currentWeek = Ember.A();

		for (var i = 1; i <= daysInMonth; i++) {
			var date = moment(new Date(year, month, i));
			if (excludeWeekends && (date.weekday() === 0 || date.weekday() == 6)) {
				continue;
			}

			if (date.weekday() == initDay && currentWeek.length > 0) {
				weeks.push(this.validateWeek(currentWeek));
				currentWeek = Ember.A();
			}

			currentWeek.pushObject(this.createDateEntry(date));
		}

		if (weeks.indexOf(currentWeek) == -1) {
			weeks.push(this.validateWeek(currentWeek));
		}

		return weeks;
	}.property('selectedMonth.value', 'excludeWeekends'),
	goToCurrentMonth: function() {
		var currentMonth = this.get('months').find(function(month) {
			return month.get('value').isSame(moment(), 'month');
		});

		if (currentMonth) {
			this.set('selectedMonth', currentMonth);
		}
	},
	isCurrentMonth: function() {
		return this.get('selectedMonth.value').isSame(moment(), 'month');
	}.property('selectedMonth.value')
});

zen._SchedulingPaymentsMixin = Ember.Mixin.create({
	SCHEDULING_BUTTON_ID: 'contractor-scheduling-button',
	isScheduling: false,
	showUpcoming: false,
	selectedDate: null,
	hasSelectedDate: Ember.computed.bool('selectedDate'),
	isSchedulingOnDate: Ember.computed.and('isScheduling', 'selectedDate.isDateInRange'),
	toggleScheduling: function() {
		this.toggleProperty('isScheduling');
	},
	setShowUpcoming: function(value) {
		this.set('showUpcoming', value);
	},
	resetContractorSelection: function() {
		if (!this.get('selectedDate')) {
			this.clearProperties();
		}
	}.observes('selectedDate'),
	bindCancel: function() {
		if (!this.get('isScheduling')) {
			$('body').off('click.contractor-scheduling');
			return;
		}

		$('body').on('click.contractor-scheduling', function(event) {
			if (this.get('selectedDate')) {
				return;
			}

			var target = $(event.target);
			if (target.attr('id') == this.get('SCHEDULING_BUTTON_ID')) {
				return;
			}

			if (!this.get('preventCancel')) {
				this.set('isScheduling', false);
				$('body').off('click.contractor-scheduling');
			}
			else {
				this.set('preventCancel', false);
			}
		}.bind(this));
	}.observes('isScheduling')
});

zen._SchedulingMultiplePaymentsMixin = Ember.Mixin.create(zen._SchedulingPaymentsMixin, {
	isMultipleMode: false,
	isDisplayInitiated: false,
	showMultipleMode: Ember.computed.and('selectedDate', 'isMultipleMode'),
	setMultipleMode: function(value) {
		this.set('isMultipleMode', value);
	},
	setDisplayInitiated: function(value) {
		this.set('isDisplayInitiated', value);
	},
	adjustScroll: function() {
		setTimeout(function() {
			window.scrollTo(0, 0);
		}, 100);
	}.observes('isMultipleMode'),
	daysOnCalendar: function() {
		return Ember.A().concat.apply(Ember.A(), this.get('weeksOfMonth'));
	}.property('weeksOfMonth'),
	daysOnMonth: function() {
		var selectedMonth = this.get('selectedMonth.value');
		if (selectedMonth) {
			return this.get('daysOnCalendar').filter(function(day) {
				return day.get('value').month() == selectedMonth.month();
			});
		}
	}.property('selectedMonth.value', 'daysOnCalendar.@each.value'),
	updateSelectedOnMonthChange: function() {
		if (this.get('isMultipleMode')) {
			var current = this.get('selectedDate.value').date();
			var daysOnMonth = this.get('daysOnMonth');
			var date = daysOnMonth.find(function(day) {
				return current == day.get('value').date();
			}) || daysOnMonth.get('firstObject');

			this.set('selectedDate', date);
		}
	}.observes('daysOnMonth'),
	resetMultipleMode: function() {
		if (!this.get('selectedDate') && this.get('isMultipleMode')) {
			this.setMultipleMode(false);
		}
	}.observes('selectedDate'),
	onSetToCalendar: function() {
		if (!this.get('isMultipleMode') && this.get('selectedDate')) {
			Ember.run.next(function() {
				this.set('selectedDate', null);
			}.bind(this));
		}
	}.observes('isMultipleMode'),
	resetSchedulingOnMultiple: function() {
		if (this.get('isMultipleMode') && this.get('isScheduling')) {
			this.toggleScheduling();
		}
	}.observes('isMultipleMode')
});

zen._FastTrackMixin = Ember.Mixin.create({
	isFastTrackEnabled: false,
	hasFastTrack: Ember.computed.and('canFastTrack', 'isFastTrackEnabled')
});

zen._ContractorsBankinfoMixin = Ember.Mixin.create({
	afterModel: function(model) {
		return model.get('contractorSettings.bankAccount');
	},
});

App.ContractorsBankinfoVerifyRoute = Ember.Route.extend(zen._AuthContractorAdminMixin, {
	model: function(params) {
		return Ember.RSVP.hash({
			'contractorSettings': dashboard('company.contractorSettings')
		}).then(function(o) {
			return Ember.Object.create(o);
		});
	},
	redirect: function(model, transition) {
		if (model.get('contractorSettings.isPypOnboarded')) {
			this.transitionTo('contractors.bankinfo.summary');
			return;
		}
	},
});

App.ContractorsBankinfoVerifyTestRoute = Ember.Route.extend(zen._AuthContractorAdminMixin, {
	model: function(params) {
		return Ember.RSVP.hash({
			'selectedAccount': App.ContractorSettingsBankAccount.find(params.account_id),
			'contractorSettings': dashboard('company.contractorSettings')
		}).then(function(o) {
			return Ember.Object.create(o);
		});
	},
	afterModel: function(model) {
		return model.get('contractorSettings.bankAccount');
	},
	redirect: function(model, transition) {
		if (model.get('selectedAccount.isVerified')) {
			this.transitionTo('contractors.bankinfo.summary');
			return;
		}
		var needsAchAuthorization = !model.get('contractorSettings.hasAuthorizedACH');
		if (needsAchAuthorization) {
			this.transitionTo('contractors.bankinfo.summary');
			return;
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorMessage', '');
		controller.set('testTransactionAmount1', '');
		controller.set('testTransactionAmount2', '');
	}
});

App.ContractorsBankinfoVerifyTestController = Ember.Controller.extend({
	errorMessage: '',
	defaultErrorMessage: 'An error occurred while attempting to verify the test amounts. Please try again later.',
	testTransactionAmount1: '',
	testTransactionAmount2: '',
	requestPromise: Ember.RSVP.resolve(),
	actions: {
		testTransactions: function() {
			this.set('errorMessage', '');
			var json = {
				testDetails: {
					'verificationId': this.get('model.selectedAccount.bankAccountVerification_id'),
					'testAmount1': this.get('testTransactionAmount1'),
					'testAmount2': this.get('testTransactionAmount2')
				}
			};
			var response = Ember.ajax({
				url: "/custom_api/bank_account_verification/test_amounts/",
				type: "POST",
				data: JSON.stringify(json)
			}).then(function(response) {
				if (response.errorOccurred) {
					this.set('errorMessage', response.errorMessage || this.get('defaultErrorMessage'));
					return;
				}
				return this.get('model.selectedAccount').reload().then(function() {
					if (this.get('model.selectedAccount.isVerified')) {
						return this.get('model.contractorSettings').reload().then(function() {
							return this.transitionToRoute('contractors.bankinfo.summary');
						}.bind(this));
					} else {
						this.set('errorMessage', 'The test amounts you have entered did not match our records. Please try again.');
					}
				}.bind(this));
			}.bind(this), function(response) {
				this.set('errorMessage', this.get('defaultErrorMessage'));
			}.bind(this));
			this.set('requestPromise', Ember.PromiseObject.create({promise: response}));
		}
	}
});

App.ContractorsBankinfoVerifyManualRoute = Ember.Route.extend(zen._ContractorsBankinfoMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.modelFor('contractors');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorMessage', '');
		controller.set('accountHolderName', null);
		controller.set('accountNumber', null);
		controller.set('routingNumber', null);
		controller.set('accountType', null);
	}
});

App.ContractorsBankinfoVerifyManualController = Ember.Controller.extend({
	errorMessage: '',
	defaultErrorMessage: 'An error occurred while attempting to authenticate your account. Please try again later.',
	accountType: null,
	accountHolderName: null,
	accountNumber: null,
	routingNumber: null,
	actions: {
		submitAccountSelection: function() {
			this.set('errorMessage', '');

			if (Ember.isEmpty(this.get('accountType'))) {
				this.set('errorMessage', 'An account type must be selected to continue.');
				return;
			}

			if (Ember.isEmpty(this.get('accountNumber')) || Ember.isEmpty(this.get('routingNumber'))) {
				this.set('errorMessage', 'An account and routing number is required to continue.');
				return;
			}

			if (Ember.isEmpty(this.get('accountHolderName'))) {
				this.set('errorMessage', 'An account holder name is required to continue.');
				return;
			}

			var json = {
				bankDetails: {
					accountType: this.get('accountType'),
					accountHolderName: this.get('accountHolderName'),
					accountNumber: this.get('accountNumber'),
					routingNumber: this.get('routingNumber'),
					verificationType: 'manual',
				}
			};
			var response = Ember.ajax({
				url: "/custom_api/bank_account_verification/step_select_account/",
				type: "POST",
				data: JSON.stringify(json)
			}).then(function(response) {
				if (response.errorOccurred) {
					this.set('errorMessage', response.errorMessage || this.get('defaultErrorMessage'));
				} else {
					if (!response.routingNumber || !response.accountNumber || !response.verificationId) {
						this.set('errorMessage', this.get('defaultErrorMessage'));
						return;
					}
					return App.ContractorSettingsBankAccount.createRecord({
						'company': this.get('model.contractorSettings.company'),
						'bankRoutingNumber': response.routingNumber,
						'bankAccountNumber': response.accountNumber,
						'bankAccountVerification_id': response.verificationId
					}).save().then(function(account) {
						var currentAccount = this.get('model.contractorSettings.bankAccount');
						if (Ember.isNone(currentAccount)) {
							this.set('model.contractorSettings.bankAccount', account);
						}
						return this.get('model.contractorSettings').save().then(function() {
							return this.transitionToRoute('contractors.bankinfo.summary');
						}.bind(this));
					}.bind(this));
				}
			}.bind(this), function(response) {
				return this.set('errorMessage', this.get('defaultErrorMessage'));
			}.bind(this));
			this.set('requestPromise', Ember.PromiseObject.create({promise: response}));
		},

	}
});

App.ContractorsBankinfoSummaryRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.all(this.modelFor('contractors'),
			{'availableBankAccounts': App.ContractorSettingsBankAccount.find()});
	},
	afterModel: function(model) {
		return model.get('contractorSettings.bankAccount');
	},
});

App.ContractorsBankinfoSummaryController = Ember.ObjectController.extend({
	errorText: '',
	errorTextForPypOnboardedCompanies: '',
	availableBankAccountsWithStatus: function() {
		var primaryAccount = this.get('model.contractorSettings.bankAccount');
		return this.get('model.availableBankAccounts').map(function(account) {
			var isPrimary = !Ember.isNone(primaryAccount) && primaryAccount.get('id') == account.get('id');
			account.set('isPrimary', isPrimary);
			return account;
		});
	}.property('model.contractorSettings.bankAccount', 'model.availableBankAccounts.length'),
	hasBankAccounts: function() {
		return this.get('model.availableBankAccounts.length') > 0;
	}.property('model.availableBankAccounts.length'),
	actions: {
		save: function() {
			this.set('errorText', '');
			if (!this.get('model.contractorSettings.bankAccount')) {
				this.set('errorText', 'Please enter your company bank account information.');
				return;
			}
			if (!this.get('model.contractorSettings.authSignature') || !this.get('model.contractorSettings.authSignatureName')) {
				this.set('errorText', 'Please sign the ACH Authorization to continue.');
				return;
			}
			var transaction = this.get('model.contractorSettings.transaction') || App.store.get('defaultTransaction');
			transaction.commit().then(function() {
				return this.get('model.contractorSettings').reload();
			}.bind(this));
		},
		selectAccount: function(account) {
			if (this.get('model.contractorSettings.isPypOnboarded')) {
				this.set('errorTextForPypOnboardedCompanies', 'Once your payroll account has been verified, it will become your primary account.');
				return;
			}
			if (!Ember.isNone(account) && account.get('isVerified')) {
				var contractorSettings = this.get('model.contractorSettings');
				contractorSettings.set('bankAccount', account);
				contractorSettings.save();
			}
		},
		deleteAccountConfirm: function(account) {
			var content = {};
			content['contractorSettings'] = this.get('model.contractorSettings');
			content['selectedAccount'] = account;
			if (!content['contractorSettings'].get('isPypOnboarded')) {
				this.send('showModal', 'contractors.bankinfo.remove.confirm', content);
			} else {
				this.set('errorTextForPypOnboardedCompanies', 'Please manage your account through Payroll.');
				return;
			}
		},
		addBankAccount: function(button) {
			var bank = App.ContractorSettingsBankAccount.createRecord({
								company: this.get('model.contractorSettings.company'),
							});
			this.transitionToRoute('plaidVerification', {
				queryParams: {
					returnRoute: 'contractors.bankinfo.summary',
					applyModel: bank,
					bankType: 'CONTRACTOR',
				},
			});
		},
	}
});

App.ContractorsBankinfoRemoveConfirmController = Ember.Controller.extend({
	actions: {
		deleteAccount: function(account) {
			if (this.get('model.contractorSettings.isPypOnboarded')) {
				return;
			}
			if (!Ember.isNone(account)) {
				account.deleteRecord();
				return account.save().then(function() {
					this.send('hideModal');
					return this.get('model.contractorSettings').reload();
				}.bind(this));
			}
		},
	}
});

App.ContractorsPayController = Ember.ObjectController.extend(App.ValidationsControllerMixin, {
	errorText: '',
	quoteErrorText: '',
	activeContractors: function() {
		return this.get('contractors').filterProperty('isActive');
	}.property('contractors.@each.isActive'),
	isValid: function() {
		var numErrors = this.get('validations.length');
		var numPayments = this.get('contractorsWithPayments.length');
		var totalAmount = this.get('totalAmount');
		var creditLimit = this.get('contractorSettings.remainingDistribution');
		if (numPayments > 0) {
			if (totalAmount > creditLimit) {
				this.set('errorText', 'The payments you\'ve entered exceed your current limit.');
				this.set('quoteErrorText', 'Exceeds current limit');
				return false;
			}

			if (numErrors > 0) {
				this.set('errorText', 'You\'ve entered an invalid payment.');
				return false;
			}
		}

		this.set('errorText', '');
		this.set('quoteErrorText', '');
		return true;
	}.property('contractorsWithPayments.length', 'totalAmount', 'contractorSettings.remainingDistribution'),
	totalAmount: function() {
		var contractorsWithPayments = this.get('contractorsWithPayments');
		var total = 0;
		if (contractorsWithPayments.get('length') > 0) {
			total = contractorsWithPayments.reduce(function(prev, contractor) {
				var amount = Ember.isNone(contractor.get('payAmount')) ? '0.00' : contractor.get('payAmount');
				amount = amount.replace(/[^\d.]/g, '');
				return prev + Number(amount);
			}, 0);
		}
		return total;
	}.property('contractorsWithPayments.@each.payAmount'),
	quoteRemaining: function() {
		var totalAmount = this.get('totalAmount');
		var remainingDistribution = this.get('contractorSettings.remainingDistribution');
		return remainingDistribution - totalAmount;
	}.property('totalAmount', 'contractorSettings.remainingDistribution'),
	hasExceededTheLimit: Ember.computed.lte('contractorSettings.remainingDistribution', 0),
	hasNoInvalidPayments: function() {
		var contractorsWithPayments = this.get('contractorsWithPayments').filterProperty('payAmount');
		var hasRealPayments = contractorsWithPayments.get('length') > 0;
		return this.get('isValid') && hasRealPayments;
	}.property('contractorsWithPayments.@each.payAmount', 'isValid'),
	contractorsWithPayments: Ember.A([]),
	selectedContractor: null,
	unselectedContractors: function() {
		var selected = this.get('contractorsWithPayments');
		var unselected = this.get('activeContractors').slice();
		unselected.removeObjects(selected);
		return unselected;
	}.property('activeContractors', 'contractorsWithPayments.@each'),
	hasSelectedContractor: Ember.computed.notEmpty('selectedContractor'),
	actions: {
		markInactive: function(contractor) {
			contractor.set('isActive', false);
			contractor.save();
			this.get('contractorsWithPayments').removeObject(contractor);
		},
		addContractorPayment: function() {
			var contractor = this.get('selectedContractor');
			if (Ember.isNone(contractor)) {
				return;
			}
			var current = this.get('contractorsWithPayments');
			var exists = current.filter(function(c) {
				return c.get('id') === contractor.get('id');
			});
			if (!Ember.isEmpty(exists)) {
				return;
			}

			var previousPaymentFailureReason = contractor.get('previousPaymentFailureReason');
			if (previousPaymentFailureReason == 'A' || previousPaymentFailureReason == 'R') {
				this.send('showModal', 'contractors.pay.block', {'contractorName': contractor.get('name')});
			} else {
				this.get('contractorsWithPayments').pushObject(contractor);
			}
			this.set('selectedContractor', '');
		},
		removeContractorPayment: function(contractor) {
			contractor.set('payAmount', '');
			this.get('contractorsWithPayments').removeObject(contractor);
		},
		showPayConfirm: function(button) {
			var content = {};
			content['contractorSettings'] = this.get('model.contractorSettings');
			content['activeContractors'] = this.get('contractorsWithPayments');
			this.send('showModal', 'contractors.pay.confirm', content);
			button.stop();
		},
	}
});

zen._CalendarDialogMixin = Ember.Mixin.create({
	position: null,
	id: null,
	updatePos: function() {
		var position = this.get('position');
		if (position) {
			Ember.run.next(function() {
				$('#' + this.get('id')).css('top', position);
			}.bind(this));
		}
	}.observes('position')
});

zen._ContractorCalendarDialogMixin = Ember.Mixin.create(zen._CalendarDialogMixin, {
	ARROW_POSITIONS: {
		1: "day-one",
		2: "day-two",
		3: "day-three",
		4: "day-four",
		5: "day-five"
	},
	setTabIndex: function() {
		if (this.get('position')) {
			Ember.run.next(function() {
				$('#' + this.get('id')).find('input').each(function(index) {
					$(this).attr('tabindex', index + 1);
				});
			}.bind(this));
		}
	}.observes('position')
});

App.ContractorsPayCopylinkController = zen._ModalObjectController.extend();

App.ContractorsPaymentsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {

		var payments = App.ContractorPayment.find().then(function(payments) {
			return payments.sortBy("created").reverse();
		});
		return this.all(this.modelFor('contractors'), {
				"payments": payments,
		});
	}
});
App.ContractorsPaymentsController = Ember.ObjectController.extend({
	search: "",
	selectedDate: null,
	createdDates: function() {
		return this.get('payments').map(function(payment) {
			return moment.utc(payment.get('created')).local().format('M/D/YYYY');
		}).filter(function(payment, index, array) {
			return array.indexOf(payment) == index;
		}).sort(function(a, b) {
			return new Date(a) - new Date(b);
		});
	}.property('payments.@each.created'),
	filteredPaymentsWithDate: function() {
		var payments = this.get('payments');
		var selected = this.get('selectedDate');
		var search = this.get('search');
		if (search) {
			search = search.toLowerCase();
		}

		if (selected || (search && search.length > 0)) {
			payments = payments.filter(function(p) {
				if (search && search.length > 0 && (p.get('contractor.name').toLowerCase().indexOf(search) == -1)) {
					return false;
				}
				if (selected && !(moment.utc(p.get('created')).local().isSame(selected, 'day'))) {
					return false;
				}
				return true;
			});
		}

		return payments;
	}.property('payments.@each.created', 'payments.@each.contractor', 'search', 'selectedDate'),
});

App.ContractorsFile1099Route = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		var that = this;
		var date = new Date();
		var isEditingDisabled = true;
		// The 1099 filings are only editable between January 2nd to January 22th
		if (date.getMonth() == 0 && date.getDate() > 1 && date.getDate() < 23) {
			isEditingDisabled = false;
		}

		return this.all(App.Contractor.find(), { "contractorSettings": dashboard('company.contractorSettings'), "isEditingDisabled": isEditingDisabled });
	}
});

App.ContractorsFile1099Controller = zen._TransactionArraySavingController.extend({
	isAnyContractorFiling: null,
	actions: {
		save: function() {
			var contractors = this.get('model');
			var isAnyContractorFiling = contractors.any(function(contractor) {
				return contractor.get("total2018PayAmount") > 0 && contractor.get("willFile1099ThroughZenefits2018") == true;
			});
			this.set('isAnyContractorFiling', isAnyContractorFiling);
			return this.saveAndContinue().then(function() {
				this.send('showModal', 'contractors.file1099.confirmation', this);
			}.bind(this));
		},
		generateExcel: function() {
			window.location = "/custom_api/reports/tenninenine/run";
		}
	},
});

App.ContractorsFile1099ConfirmationController = Ember.ObjectController.extend({
	actions : {
			toDashboard : function() {
				this.send('hideModal');
				this.transitionToRoute("index");
		},
	},
});

App.ContractorsAgreementsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.all(this.modelFor('contractors'), {
			'agreements': App.InstantiatedConsultantContract.find(),
			'ipAgreements': App.InstantiatedIpAgreement.find(),
		});
	},
	actions: {
		uploadContractDialog: function() {
			this.send('showModal', 'contractors.agreements.upload', App.ConsultantContract.createRecord());
		}
	}
});

App.ContractorsAgreementsController = Ember.ObjectController.extend({
	deleteContract: function(contract) {
		contract.deleteRecord();
		return contract.save();
	}
});

App.ContractorsTemplatesRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthContractorAdminMixin, {
	model: function() {
		return this.all(dashboard('company.contractorSettings'), {
			'templates': App.ConsultantContract.find(),
			'ipTemplates': App.IpAgreement.find()
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		if (model.get('usingDefaultTemplates')) {
			controller.set('consultingAgreement', 'default');
		}
		else if (model.get('hasSetupTemplates')) {
			controller.set('consultingAgreement', 'custom');
		}
	},
});
App.ContractorsTemplatesController = Ember.ObjectController.extend({
	uploadContractDialog: function() {
		this.send('showModal', 'contractors.templates.upload');
	},
	addDefault: function() {
		var model = this.get('model');
		model.set('usingDefaultTemplates', true);
		model.save().then(function() {
			App.ConsultantContract.find();
			App.IpAgreement.find();
		});
	},
	hasActiveTemplates: Ember.computed.gt('activeTemplates.length', 0),
	activeTemplates: function() {
		var templates = this.get('templates');
		var ipTemplates = this.get('ipTemplates');
		return templates && ipTemplates && templates.filterProperty('isActive').concat(ipTemplates.filterProperty('isActive'));
	}.property('templates.@each.isActive', 'ipTemplates.@each.isActive'),
	removeTemplate: function(template) {
		template.set('isActive', false);
		template.save();
	},
	errorText: '',
	save: function() {
		this.set('errorText','');
		if (!this.get('fromSignature') || !this.get('fromSignatureName') || !this.get('fromSignatureTitle')) {
			this.set('errorText', 'Please complete the signature fields');
			return;
		}
		this.get('model.transaction').commit().then(function() {
			var isProcessing = this.get('activeTemplates').filterProperty('isTemplatized', false).get('length') > 0;
			this.transitionToRoute(isProcessing ? 'index' : 'contractors.list');
		}.bind(this));
	}
});

App.ContractorsTemplatesUploadController = Ember.ObjectController.extend({
	errorText: '',
	name: null,
	agreementType: null,
	uploadUrl: null,
	acceptedFileTypes: ['doc', 'pdf', 'txt'],
	fileCategory: "consultant_contract_template",
	actions: {
		onFileUploadSuccess: function(file, data) {
			this.set('uploadUrl', data.url);
		},

		onFileUploadError: function(file, error) {
			this.eventLogger.log('contractor-template-upload-error', {
				message: "Error uploading file: " + error.toString(),
			});
		},
	},
	canScrapePdfPromiseObject: function() {
		var url = this.get('uploadUrl');
		if (!url) {
			return Ember.PromiseObject.create({
				promise: Ember.RSVP.resolve(),
				scrapable: true,
			});
		}

		return Ember.PromiseObject.create({
			promise: Ember.getJSON(zen.urlForCanScrapePdf(url)),
		});
	}.property('uploadUrl'),
	save: function() {
		this.set('errorText', '');
		var name = this.get('name');
		var agreementType = this.get('agreementType');
		var uploadUrl = this.get('uploadUrl');
		if (!name || !agreementType || !uploadUrl) {
			this.set('errorText', 'All fields are required.');
			return;
		}
		var canScrapePdfPromiseObject = this.get('canScrapePdfPromiseObject');
		canScrapePdfPromiseObject.then(function() {
			if (!canScrapePdfPromiseObject.get('scrapable')) {
				var templateName;
				if (agreementType == 'ConsultantContract') {
					templateName = 'Consultant Agreement';
				} else {
					templateName = 'IP/NDA Agreement';
				}
				this.set('errorText', "We're unable to automatically detect the text in your custom " + templateName +  ". Please make sure you have not uploaded a scanned document.");
				return;
			}

			this.set('errorText', "");
			var transaction = App.store.transaction();
			transaction.createRecord(App[agreementType], {
				"name": name,
				"uploadUrl": uploadUrl,
				"isActive": true
			});
			transaction.commit().then(function() {
				this.setProperties({'name': null, 'agreementType': null, 'uploadUrl': null});
				this.send('hideModal');
			}.bind(this));
		}.bind(this));
	}
});

zen._AbstractEeoNextPathSavingController = zen._AbstractSavingController.extend({
	needs: 'eeo',
	hasMissingValues: false,
	goNext: function() {
		var current = window.location.hash.substr(1);
		var routes = this.get('controllers.eeo.routes');

		if (routes.hasOwnProperty(current)) {
			var next = routes[current].next;
			var goToRoute = routes[next].transition;
			goToRoute.call();
		}
	},
	getNextRoute: function() {
		var current = window.location.hash.substr(1);
		var routes = this.get('controllers.eeo.routes');

		if (routes.hasOwnProperty(current)) {
			var next = routes[current].next;
			var nextRoute = routes[next].transition;
			return nextRoute;
		}
	},

	checkMissingValues: function() {
		this.set('hasMissingValues', this.getWithDefault('errorText.length', 0) > 0);
	}.observes('errorText.length')
});

App.EeoRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return this.all(dashboard('company'), {"_locations": dashboard('company.locations'), "employees": dashboard('employees'), "all_pit_locations": Ember.RSVP.resolve(Ember.getJSON('/custom_api/get_past_eeo_locations'))});
	}
});
var EEO_REPORT_NAV_STATES = Ember.A(["eeo.starting", "eeo.requirements", "eeo.setup", "eeo.summary"]);
App.EeoController = Ember.ObjectController.extend({
	needs: ['application', 'eeoStarting', 'eeoSetup'],
	EMPLOYEE_THRESHOLD: 50,
	generate: null,
	isNotSetupComplete: Ember.computed.not('isSetupComplete'),
	isNotReportsComplete: Ember.computed.not('isReportsComplete'),
	showNav: function() {
		var currentPath = this.get('controllers.application.currentPath');
		return EEO_REPORT_NAV_STATES.contains(currentPath);
	}.property('controllers.application.currentPath'),
	isSetupComplete: Ember.computed.and('isStartingComplete', 'eeoReport.isReqQuestionsComplete'),
	pit_locations: null,
	set_pit_location: function() {
		this.set('pit_locations', this.get('all_pit_locations'));
	}.observes('all_pit_locations.@each.id', 'all_pit_locations', 'all_pit_locations.[].id', 'all_pit_locations.[]'),
	showHierarchicalFlow: App.switches.isActive('eeo_hierarchical_show'),
	current_locations: Ember.computed('showHierarchicalFlow', function(){
		if(this.get('eeoPastMetricsEnabled') && this.get('ReportType') === 2 && !Ember.isEmpty(this.get('eeoYear'))){
			var year = this.get('eeoYear');
			return this.get('pit_locations').filter(function(x){return x && x.year===year;});
		}
		else if (this.get('showHierarchicalFlow')){
			return this.get('_locations').filter(function(loc){return loc.get('isVirtual') === false;});
		}
		else{
			return this.get('_locations');
		}
	}).property('_locations', 'ReportType', 'eeoYear', 'pit_locations.@each.id', 'pit_locations.[].id', 'pit_locations.[]', 'pit_locations', 'eeoPastMetricsEnabled', 'headquarters'),
	locationsWithHQs: Ember.computed.filterByProperty('locations', 'isHeadquarters'),
	isStartingComplete: function() {
		return !Ember.isEmpty(this.get('eeoReport.includeOptionals')) && this.get('locationsWithHQs.length');
	}.property('eeoReport.includeOptionals', 'locationsWithHQs.length'),
	finishedReports: Ember.computed.filterByProperty('locations', 'isEeoReportComplete'),
	isReportsComplete: function() {
		var totalReports = this.get('locations.length');
		var finishedReports = this.get('finishedReports.length');
		return totalReports && totalReports == finishedReports;
	}.property('locations.length', 'finishedReports.length'),
	isSetupOpen: function() {
		var currentPath = this.get('controllers.application.currentPath');
		return currentPath == "eeo.starting" || currentPath == "eeo.requirements";
	}.property('controllers.application.currentPath'),
	isReportsOpen: function() {
		var currentPath = this.get('controllers.application.currentPath');
		return currentPath == "eeo.setup" || currentPath == "eeo.summary";
	}.property('controllers.application.currentPath'),
	headquarters: Ember.computed.alias('locationsWithHQs.firstObject'),
	ReportType: Ember.computed.alias('eeoReport.ReportType'),
	eeoYear: Ember.computed.alias('eeoReport.year'),
	eeoPastMetricsEnabled: Ember.computed(function(){
		return true;
	}),
	locations: function(key, value) {
		var self = this;
		if(this.get('eeoPastMetricsEnabled') && this.get('eeoReport.ReportType') === 2 && !Ember.isEmpty(this.get('eeoYear'))){
			return this.get('current_locations').sort(function(a, b) {
				return b.isHeadquarters - a.isHeadquarters;
			});
		}
		return this.get('current_locations').filter(function(loc) {
			var threshold = self.get('eeoReport.includeOptionals') ? 1 : self.get('EMPLOYEE_THRESHOLD');
			return self.get('employees').filter(function(emp) {return emp.get('type') == 'RE' && emp.get('location') == loc;}).length >= threshold;}).sort(function(a, b) {
			return b.get('isHeadquarters') - a.get('isHeadquarters');
		});
	}.property('eeoReport.includeOptionals', 'current_locations.@each.isHeadquarters', 'ReportType', 'eeoYear', 'current_locations'),
	routes: function(key, value) {

		var routes = {};
		var self = this;

		routes["/eeo/starting"] = {};
		routes["/eeo/starting"].transition = function() {
			self.transitionToRoute("eeo.starting");
		};
		routes["/eeo/starting"].next = "/eeo/requirements";

		routes["/eeo/requirements"] = {};
		routes["/eeo/requirements"].transition = function() {
			self.transitionToRoute("eeo.requirements");
		};

		var previous = null;
		var id = null;
		this.get('locations').forEach(function(loc) {
			var loc_id;
			try {
			  loc_id = loc.get('id');
			}
			catch(err) {
			  loc_id = loc.id;
			}
			id = '/eeo/setup/' + loc_id;
			if (!previous) {
				routes["/eeo/requirements"].next = id;
			}
			else {
				routes[previous].next = id;
			}

			routes[id] = {};
			routes[id].transition = function() {
				self.transitionToRoute("eeo.setup", loc);
				setTimeout(function() {
					window.scrollTo(0, 0);
				}, 100);
			};
			previous = id;
		});

		if (!previous) {
			routes["/eeo/requirements"].next = "/census/sheets/eeo";
		}
		else if (id) {
			routes[id].next = "/census/sheets/eeo";
		}

		routes["/census/sheets/eeo"] = {};
		routes["/census/sheets/eeo"].transition = function() {
			self.transitionToRoute('census.sheets', {
				queryParams: { flow: 'EEO' }
			});
		};

		routes["/eeo/summary"] = {};
		routes["/eeo/summary"].transition = function() {
			self.transitionToRoute("eeo.summary");
		};

		return routes;
	}.property('locations')
});

App.EeoStartingRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return this.all(dashboard('company'), {"employees": dashboard('employees')});
	}
});
App.EeoStartingController = zen._AbstractEeoNextPathSavingController.extend({
	currentYears: Ember.computed('show2020Year', function(){
		var startYear = 2017, endYear, years = [];
		if (this.get('show2020Year')){
			startYear = 2020;
			endYear = new Date().getFullYear();
		}
		else {
			startYear = 2017;
			endYear = 2019;
		}
		for (var year = startYear; year < endYear; year++){
			years.push(year);
		}
		return years;
	}),
	pit_locations : Ember.computed.alias('controllers.eeo.pit_locations'),
	_locations : Ember.computed.alias('controllers.eeo._locations'),
	current_locations: function(){
		if(this.get('eeoPastMetricsEnabled') && this.get('ReportType') === 2 && !Ember.isEmpty(this.get('year'))){
			var year = this.get('year');
			return this.get('pit_locations').filter(function(x){return x && x.year===year;});
		}
		return this.get('_locations');
	}.property('_locations', 'ReportType', 'year', 'pit_locations.@each.id', 'pit_locations.[].id', 'pit_locations.[]', 'pit_locations', 'eeoPastMetricsEnabled', 'headquarters'),
	generateEnabled: Ember.computed.equal('reportOptions', 'yes'),
	headquarters: function(key, value) {
		if (value) {
			var boolValue = !Ember.isEmpty(value);
			if (this.get('controllers.eeo.headquarters')){
				this.set('controllers.eeo.headquarters.isHeadquarters', !boolValue);
			}
			Ember.set(value, 'isHeadquarters', boolValue);
		}
		return this.get('controllers.eeo.headquarters');
	}.property('controllers.eeo.headquarters'),
	startDate: Ember.computed.alias('model.eeoReport.startDate'),
	endDate: Ember.computed.alias('model.eeoReport.endDate'),
	locations:Ember.computed.alias('controllers.eeo.locations'),
	ReportType: Ember.computed.alias('controllers.eeo.ReportType'),
	year: Ember.computed.alias('controllers.eeo.eeoYear'),
	eeoPastMetricsEnabled: Ember.computed.alias('controllers.eeo.eeoPastMetricsEnabled'),
	ReportTypeExcel: 1,
	defaultYear: Ember.computed('show2020Year', function(){
		if (this.get('show2020Year')) {
			return new Date().getFullYear()-1;
		}
		return 2018;
	}),
	ExcelYear: Ember.computed('defaultYear', function(){
		return this.get('defaultYear');
	}),
	showMissingEeoEmployees: false,
	setShowMissingEeoEmployees: function() {
		this.set('showMissingEeoEmployees', true);
	},
	show2020Year: App.switches.isActive('eeo_report_show_2020_year'),
	downloadingReport: false,
	notEeoPastMetricsNotComponent2: Ember.computed('eeoPastMetricsEnabled', 'component2Selected', function(){
		return !this.get('eeoPastMetricsEnabled') || !this.get('component2Selected');
	}),

	reportOptions: function(key, value) {
		if (value) {
			var boolValue = value == 'yes';
			this.set('controllers.eeo.generate', boolValue);
		}

		var has = this.get('controllers.eeo.generate');
		if (has === true) {
			return 'yes';
		}
		if (has === false) {
			return 'no';
		}

		return null;
	}.property('controllers.eeo.generate'),
	ReportTypeQuestion: function(key, value) {
		if (value) {
			this.set('ReportType', value);
		}
		return this.get('ReportType');
	}.property('ReportType'),
	yearQuestion: function(key, value) {
		if (value) {
			this.set('year', value);
		}
		if (this.get('year')) {
			return this.get('year');
		}
		return this.get('defaultYear');
	}.property('year', 'defaultYear'),
	component2Selected: Ember.computed('eeoPastMetricsEnabled', 'ReportTypeQuestion', 'show2020Year', function(){
		if (this.get('show2020Year')) {
//		Govt is not providing info for component 2 for year 2019,2020 for now
//		If govt provide this data, we can remove this check
			return false;
		}
		return this.get('eeoPastMetricsEnabled') && (this.get('ReportTypeQuestion') === 2);
	}),

	includeOptionalsQuestion: function(key, value) {
		if (value) {
			var boolValue = value == 'yes';
			this.set('eeoReport.includeOptionals', boolValue);
		}

		var has = this.get('eeoReport.includeOptionals');
		if (has === true) {
			return 'yes';
		}
		if (has === false) {
			return 'no';
		}

		return null;
	}.property('eeoReport.includeOptionals'),

	ReportTypeExcelQuestion: function(key, value) {

		if (value){
			this.set('ReportTypeExcel', value);
		}
		return this.get('ReportTypeExcel');
	}.property('ReportTypeExcel'),

	ExcelYearQuestion: function(key, value) {

		if (value){
			this.set('ExcelYear', value);
		}
		return this.get('ExcelYear');
	}.property('ExcelYear'),
	xlscomponent2Selected:  Ember.computed('eeoPastMetricsEnabled', 'ReportTypeExcelQuestion', 'show2020Year', function(){
		if (this.get('show2020Year')) {
//		Govt is not providing info for component 2 for year 2019,2020 for now
//		If govt provide this data, we can remove this check
			return false;
		}
		return this.get('eeoPastMetricsEnabled') && (this.get('ReportTypeExcelQuestion') === 2);
	}),


	// if today is before Nov, the calendar only allows user to select dates in between
	// 10/01/last year - 12/31/last year as the first day of pay period,

	// if today is after (including) Nov, it only allows dates in between
	// 10/01/this year - 12/31/this year

	minStartDate: function() {
		var retDate = moment();
		if (retDate.month() < 10) {
			retDate = retDate.subtract(1, 'year');
		}
		if (this.get('year')) {
			retDate.year(this.get('year')).month(9).date(1);
		} else {
			retDate.year(this.get('defaultYear')).month(9).date(1);
		}
		return retDate.format('MM/DD/YYYY');
	}.property('defaultYear', 'year'),
	setStartDate: function(key, value) {
		if (value) {
			this.set('startDate', value);
			this.set('endDate', null);
		}
		return this.get('startDate');
	}.property('startDate'),
	maxStartDate: function() {
		var retDate = moment();
		if (retDate.month() < 10) {
			retDate = retDate.subtract(1, 'year');
		}
		if (this.get('year')) {
			retDate.year(this.get('year')).month(11).date(31);
		} else {
			retDate.year(this.get('defaultYear')).month(11).date(31);
		}
		return retDate.format('MM/DD/YYYY');
	}.property('year', 'defaultYear'),
	disableEndDate: function() {
		return !this.get('startDate');
	}.property('startDate'),

	minEndDate: function() {
		var startDate = this.get('startDate');
		if (moment(startDate, 'MM/DD/YYYY').isValid()) {
			return startDate;
		}
		return this.get('minStartDate');
	}.property('startDate', 'minStartDate'),

	maxEndDate: function() {
		return this.get('maxStartDate');
	}.property('maxStartDate'),

	clearErrorText: function() {
		this.setProperties({
			errorText: '',
			startDateErrorText: '',
			endDateErrorText: '',
		});
	},
	download_excel: function (){
		this.clearErrorText();
		this.set('downloadingReport', true);
		var ReportTypeExcel = this.get('ReportTypeExcel');
		var ExcelYear = this.get('ExcelYear');
		var downloadUrl = '/custom_api/reports/eeo_data/run';

		var requestJson = {};

		requestJson.url = downloadUrl;
		requestJson.dataType = 'binary';

		if (ReportTypeExcel == 2){
			requestJson.type = 'POST';
			requestJson.data = JSON.stringify({
				ReportTypeExcel: ReportTypeExcel,
				ExcelYear: ExcelYear,
			});
		} else {
			requestJson.type = 'GET';
			if (ExcelYear) {
				requestJson.url = downloadUrl + '?ExcelYear=' + ExcelYear;
			}
		}
		var self = this;
		Ember.ajax(requestJson).then(function(data) {
			window.saveAs(data, 'file' + '.xlsx');
		}).catch(function(error) {
			self.set('errorText', 'some error occured please try again');
		}).finally(function() {
			self.set('downloadingReport', false);
		});

	},

	next: function() {
		this.clearErrorText();
		var startDate = moment(this.get('startDate'), 'MM/DD/YYYY');
		var endDate = moment(this.get('endDate'), 'MM/DD/YYYY');
		var companyId = this.get('eeoReport.companyId');
		var userIdNORC = this.get('eeoReport.userIdNORC');
		var component2Selected = this.get('component2Selected');

//		if (!this.get('includeOptionalsQuestion')) {
//			this.set("errorText", "Please select Yes/No regarding optional reports.");
//			return;
//		}

		if (!this.get('headquarters')) {
			this.set("errorText", "Please select a headquarters.");
			return;
		}

		if (!(component2Selected)){
			if (companyId.length > 7) {
				this.set('errorText', "The company ID you entered is more than 7 numerical characters. Please enter a company ID that is 7 numerical characters or less.");
				return;
			}
			if (!this.get('startDate')) {
				this.set("startDateErrorText", "Please enter a valid start date.");
				return;
			}

			if (!this.get('endDate')) {
				this.set("endDateErrorText", "Please enter a valid end date.");
				return;
			}
			if (startDate.isBefore(this.get('minStartDate')) || startDate.isAfter( this.get('maxStartDate'))) {
				this.set('startDateErrorText', 'The start date must be between ' + this.get('minStartDate') + ' and ' + this.get('maxStartDate') + '.');
				return;
			}
			if (startDate.isAfter(endDate)) {
				this.set('startDateErrorText', 'The start date must be before the end date.');
				return;
			}
			if (endDate.isBefore(this.get('minEndDate')) || endDate.isAfter(this.get('maxEndDate'))) {
				this.set('endDateErrorText', 'The end date must be between ' + this.get('minEndDate') + ' and ' + this.get('maxEndDate') + '.');
				return;
			}
		}
		else{
			if (!(userIdNORC) || !(userIdNORC.length === 8)) {
				this.set('errorText', "Please enter a User ID which is equal to 8 characters");
				return;
			}
			if (!this.get('year')){
				this.set("errorText", "Please select a year.");
				return;
			}

		}

		this.set('downloadingReport', true);
		this.set('headquarters.isHeadquarters', true);

		// update eeo location (backend) headquarters
		var	locationId = this.get('headquarters.id');

		var downloadUrl = '/custom_api/update_company_headquarters/';

		var requestJson = {};
		var year = this.get('year');
		var ReportType = this.get('ReportType');

		requestJson.url = downloadUrl;
		requestJson.dataType = 'binary';

		requestJson.type = 'POST';
		requestJson.data = JSON.stringify({
			locationId: locationId,
			year: year,
			ReportType: ReportType,
		});
		var self = this;
		return Ember.ajax(requestJson).then(function(data) {
			self.saveAndContinue().catch(function(error){
						 console.log('');
					}).then(function() {
				// updating frontend values for pit_locations
				var curr_locs = self.get('controllers.eeo.locations');

				var x = [];
				for(var i=0;i<curr_locs.length;i++){
					x.push(JSON.parse(JSON.stringify(curr_locs[i])));
				}
				for(var i=0;i<x.length;i++){
					if(x[i]  && x[i].name && x[i].id!=locationId){
						if(x[i].isHeadquarters){
							x[i].isHeadquarters = false;
						}
					}else{
						if(x[i] && x[i].name & !x[i].isHeadquarters){
							x[i].isHeadquarters = true;
						}
					}
				}
				self.set('controllers.eeo.locations', x);
				self.goNext();
			}.bind(this)).finally(function() {
							self.set('downloadingReport', false);
						});
		}).catch(function(error) {
			self.set('errorText', error);
		});
	}
});

App.EeoRequirementsRoute = Ember.Route.extend(zen._AuthManagerMixin, {
	model: function() {
		return dashboard('company');
	}
});
App.EeoRequirementsController = zen._AbstractEeoNextPathSavingController.extend({
	ReportType: Ember.computed.alias('controllers.eeo.ReportType'),
	eeoPastMetricsEnabled: Ember.computed.alias('controllers.eeo.eeoPastMetricsEnabled'),
	showBanner: App.switches.isActive('eeo_hierarchical_show'),
	isComponent2:Ember.computed('eeoPastMetricsEnabled', 'ReportType', function() {
		return this.get('eeoPastMetricsEnabled') && (this.get('ReportType') === 2);
		}),
	eeoC3Question: function(key, value) {
		if (value) {
			var boolValue = value == 'yes';
			this.set('eeoReport.questionC3', boolValue);
		}

		var has = this.get('eeoReport.questionC3');
		if (has === true) {
			return 'yes';
		}
		if (has === false) {
			return 'no';
		}
		return null;
	}.property('eeoReport.questionC3'),
	next: function() {
		this.set("errorText", "");
		if (!this.get('eeoC3Question')) {
			this.set("errorText", "Please answer all questions.");
			return;
		}

		this.saveAndContinue().then(function() {
			this.goNext();
		}.bind(this));
	}
});

App.EeoSetupRoute = Ember.Route.extend(zen._AuthManagerMixin, {
	model: function() {
		return dashboard('company');
	}
});

App.EeoSetupController = zen._AbstractEeoNextPathSavingController.extend({
	eeoPastMetricsEnabled: Ember.computed.alias('controllers.eeo.eeoPastMetricsEnabled'),
	ReportType: Ember.computed.alias('controllers.eeo.ReportType'),
	isComponent2:Ember.computed('eeoPastMetricsEnabled', 'ReportType', function() {
		return this.get('eeoPastMetricsEnabled') && (this.get('ReportType') === 2);
		}),
	year: Ember.computed.alias('controllers.eeo.eeoYear'),
	thisLocation: function(){
		var loc_id = window.location.hash.split('/')[3]; // [#, eeo , setup, <id>]
		var location = this.get('controllers.eeo.current_locations').filter(function(loc) {return loc.id==loc_id? true:false;});
		return location[0];
	}.property('controllers.eeo.locations.@each.id', 'window.location.hash'),
	singlePersonLocation: function(){
		if (this.get('isComponent2')){
			var loc_id = window.location.hash.split('/')[3]; // [#, eeo , setup, <id>]
			var location = this.get('controllers.eeo.current_locations').filter(function(loc) {return loc.id==loc_id? true:false;});

			return location && location[0].employeeCount==1 && !location[0].isHeadquarters? true: false;
		}
		return false;
	}.property('controllers.eeo.locations.@each.id', 'window.location.hash'),
	remainingLocations: function(){
		var loc_id = window.location.hash.split('/')[3]; // [#, eeo , setup, <id>]
		var locations = this.get('controllers.eeo.locations').filter(function(loc) {return loc.id!=loc_id && !loc.includedInOtherLocation? true:false;});
		return locations;
	}.property('controllers.eeo.locations.@each.id', 'window.location.hash', 'controllers.eeo.locations'),
	alternateLocation: function(key, value){

		if (this.get('singlePersonLocation')){
			if (value){
				this.set('thisLocation.alternateLocationId', value.id);
			}
			if (value===null){
				this.set('thisLocation.alternateLocationId', value);
			}
			var alternateLocationId = this.get('thisLocation').alternateLocationId;

			if (!(alternateLocationId)){
				return null;
			}
			return this.get('controllers.eeo.locations').filter(function(loc) {
				 if (loc.id==alternateLocationId){
					return loc;
					}
				 })[0];
		}
		return ;
	}.property('controllers.eeo.locations.@each.id', 'window.location.hash'),
	alternateLocationName: function(){
		return this.get('alternateLocation').name;
	}.property('alternateLocation'),
	disableEditing: function(){
		if(this.get('alternateLocation')) return true;
	}.property('alternateLocation'),
	showUnitIdInput: function(){
//		unitId is enabled for all the locations in component 2, it is enabled only for non headquarters location in component 1
		if (this.get('isComponent2') || !(this.get('isHeadquarters'))){
			return true;
		}
		return false;
	}.property('isComponent2', 'isHeadquarters'),
	updating: false,
	eeoB2CQuestion: function(key, value) {
		if (value) {
			var boolValue = value == 'yes';
			this.set('eeoFiledLastYear', boolValue);
		}

		var has = this.get('eeoFiledLastYear');
		if (has === true) {
			return 'yes';
		}
		if (has === false) {
			return 'no';
		}
		return null;
	}.property('eeoFiledLastYear'),
	next: function() {
		this.set('errorText', "");

		if(!this.get('disableEditing')){


			if (!this.get('isHeadquarters') && this.get('eeoFiledLastYear') && !this.get('eeoId')) {
				this.set("errorText", "You specified that the report was filed last year, please provide the Unit ID given.");
				return;
			}

			if (!this.get('naicsCode')) {
				this.set("errorText", "Please enter a 6-digit NAICS code.");
				return;
			}

			if (!this.get('street1') || !this.get('city') || !this.get('state') || !this.get('zip')) {
				this.set('errorText', "Please fill in the address for the office.");
				return;
			}

			if ((!this.get('officialName') || !this.get('officialTitle') || !this.get('officialPhone') || !this.get('officialEmail')) && !this.get('isComponent2')) {
				this.set('errorText', "Please provide a certifying official.");
				return;
			}

			this.set('updating', true);
			var reportType = this.get('ReportType');
			var eeoId = this.get('eeoId');
			var street1 = this.get('street1');
			var street2 = this.get('street2');
			var city = this.get('city');
			var state = this.get('state');
			var zip = this.get('zip');
			var naicsCode = this.get('naicsCode');
			var eeoFiledLastYear = this.get('eeoFiledLastYear');
			var officialName = this.get('officialName');
			var officialTitle = this.get('officialTitle');
			var officialPhone = this.get('officialPhone');
			var officialEmail = this.get('officialEmail');
			var locationId = window.location.hash.split('/')[3];
			var downloadUrl = '/custom_api/update_location_info/';
			var year = this.get('year');

			var alternateLocationId = null;
				if (this.get('alternateLocation')){
					alternateLocationId = this.get('alternateLocation').id;
				}
			var update_location_url = '/custom_api/update_eeo_location/';

			var requestJsonToUpdateAlternateLocation = {};

			requestJsonToUpdateAlternateLocation.url = update_location_url;
			requestJsonToUpdateAlternateLocation.dataType = 'binary';

			requestJsonToUpdateAlternateLocation.type = 'POST';
			requestJsonToUpdateAlternateLocation.data = JSON.stringify({
				year: year,
				locationId: locationId,
				alternateLocationId: alternateLocationId,
			});

			var requestJson = {};

			requestJson.url = downloadUrl;

			requestJson.type = 'POST';
			requestJson.data = JSON.stringify({
				reportType: reportType,
				year: year,
				eeoId: eeoId,
				street1: street1,
				street2: street2,
				city: city,
				state: state,
				zip: zip,
				naicsCode: naicsCode,
				eeoFiledLastYear: eeoFiledLastYear,
				officialName: officialName,
				officialTitle: officialTitle,
				officialPhone: officialPhone,
				officialEmail: officialEmail,
				locationId: locationId,
			});
			var self = this;
			Ember.ajax(requestJsonToUpdateAlternateLocation).then(function() {
				Ember.ajax(requestJson).then(function(data) {
					var curr_locs = self.get('controllers.eeo.locations');

					var x = [];
					for(var i=0;i<curr_locs.length;i++){
						x.push(curr_locs[i]);
					}

					for(var i=0;i<x.length;i++){
						if(x[i] && x[i].name && x[i].id==locationId){
							Ember.set(x[i], 'eeoId', eeoId);
							Ember.set(x[i], 'street1', street1);
							Ember.set(x[i], 'street2', street2);
							Ember.set(x[i], 'city', city);
							Ember.set(x[i], 'zip', zip);
							Ember.set(x[i], 'naicsCode', naicsCode);
							Ember.set(x[i], 'eeoFiledLastYear', eeoFiledLastYear);
							Ember.set(x[i], 'officialName', officialName);
							Ember.set(x[i], 'officialTitle', officialTitle);
							Ember.set(x[i], 'officialPhone', officialPhone);
							Ember.set(x[i], 'officialEmail', officialEmail);
							Ember.set(x[i], 'alternateLocationId', alternateLocationId);
							Ember.set(x[i], 'isEeoReportComplete', data['isEeoReportComplete']);
						}
					}
					self.set('controllers.eeo.locations', x);

					self.saveAndContinue().catch(function(error){
						 console.log('');
					}).then(function() {
						self.goNext();
					}).finally(function(){
						self.set('updating', false);
					});
				});
			}).catch(function(error){
						 self.set('errorText', error);
					});

		}else{
			var self = this;
			self.set('updating', true);
			var year = self.get('year');
			var locationId = window.location.hash.split('/')[3];
			var alternateLocationId = null;
			if (self.get('alternateLocation')){
				alternateLocationId = self.get('alternateLocation').id;
			}
			var downloadUrl = '/custom_api/update_eeo_location/';

			var requestJson = {};

			requestJson.url = downloadUrl;
			requestJson.dataType = 'binary';

			requestJson.type = 'POST';
			requestJson.data = JSON.stringify({
				year: year,
				locationId: locationId,
				alternateLocationId: alternateLocationId,
			});
			Ember.ajax(requestJson).then(function(data) {

				var curr_locs = self.get('controllers.eeo.locations');

				var x = [];
				for(var i=0;i<curr_locs.length;i++){
					x.push(curr_locs[i]);
				}

				for(var i=0;i<x.length;i++){
					if(x[i] && x[i].name && x[i].id==locationId){
						Ember.set(x[i], 'alternateLocationId', alternateLocationId);
						Ember.set(x[i], 'isEeoReportComplete', true);
						Ember.set(x[i], 'includedInOtherLocation', true);
						Ember.set(x[i], 'alternateLocationName', self.get('alternateLocationName'));
					}else{
						if(x[i] && x[i].name && x[i].id==alternateLocationId){
							x[i].employeeCount = self.get('y', 'employeeCount') + 1;
						}
					}
				}
				var nextRoute = self.getNextRoute();

					self.set('controllers.eeo.locations', x);

				self.saveAndContinue().catch(function(error){
						 console.log('');
					}).then(function() {
					self.set('updating', false);
					nextRoute.call();
				}).finally(function(){
						self.set('updating', false);
					});
			});
		}

	}
});

App.EeoSummaryRoute = Ember.Route.extend(zen._AuthManagerMixin, {
	model: function() {
		return dashboard('company');
	}
});

App.EeoSummaryController = zen._AbstractEeoNextPathSavingController.extend({
	headquarters: Ember.computed.alias('controllers.eeo.headquarters'),
	reports: Ember.computed.alias('controllers.eeo.locations'),
	eeoPastMetricsEnabled: Ember.computed.alias('controllers.eeo.eeoPastMetricsEnabled'),
	ReportType: Ember.computed.alias('controllers.eeo.ReportType'),
	isComponent2:Ember.computed('eeoPastMetricsEnabled', 'ReportType', function() {
		return (this.get('eeoPastMetricsEnabled') && (this.get('ReportType') === 2));
		}),
	hasNoReports: Ember.computed.equal('reports.length', 0),
	isSetupComplete: Ember.computed.alias('controllers.eeo.isSetupComplete'),
	isReportsComplete: Ember.computed.and('controllers.eeo.isReportsComplete', 'headquarters.isEeoReportComplete'),
	eeoLink:Ember.computed('isComponent2', function(){
		if (this.get('isComponent2')){
			return 'https://eeoccomp2.norc.org/assets/documents/Comp2EEO1UploadFileSpecification.pdf';
		}
	return 'https://www.eeoc.gov/employers/eeo1survey/eeo1_cvs_specifications.cfm';
	}),
	eeoC3Answered: function() {
		return !Ember.isEmpty(this.get('eeoReport.questionC3'));
	}.property('eeoReport.questionC3')
});

App.ReportsCustomSelectedfieldsView = Ember.View.extend({
	templateName: 'reports/custom/selectedfields',
	selected: null,
	sectionName: null
});

App.CustomFieldSelectChoicesTextArea = Ember.TextArea.extend({
	classNames: ['customFieldSelectChoicesTextArea'],
	init: function() {
		this._super();
		var attributeBindings = this.get('attributeBindings');
		attributeBindings.pushObject('style');
		this.set('attributeBindings', attributeBindings);
	},
});

App.ReportsCustomSectionView = Ember.View.extend({
	templateName: 'reports/custom/section',
	isExpanded: false,
	isExpandedProperty: null,
	metadata: null,
	sectionName: null,
});

App.ReportsCustomFieldCheckbox = Ember.Checkbox.extend({
	fields: null,
	field: null,
	includeInternationalEmployees: false,
	shouldSelectCurrency: false,
	init: function() {
		this._super();
			var fields = this.get('fields');
			var field = this.get('field');
			var includeInternationalEmployees = this.get('includeInternationalEmployees');
			if(field._data.id == "currency"){
				if (includeInternationalEmployees === true)
				{
					this.set('disabled', false);
				}
				else{
					this.set('disabled', true);
				}
			}
			if (!fields) { return; }
			this.set('checked', fields.contains(this.get('field')));
	},
	onIncludeInternationalEmployeesChange: function() {
		var includeInternationalEmployees = this.get('includeInternationalEmployees');
		var field = this.get('field');
		if (field._data.id == "currency"){
			if (includeInternationalEmployees === true)
			{
				this.set('disabled', false);
			}
			else{
				this.set('disabled', true);
				this.set('checked',false);
			}
		}
	}.observes('includeInternationalEmployees'),
	onSelectedFieldsChange: function() {
		var fields = this.get('fields');
		if (!fields) { return; }
		var field = this.get('field');
		if (field._data.id == "currency" && (this.get('disabled') === false)){
			var intersection = Ember.EnumerableUtils.intersection;
			var currencyFields = ['compHourlyOrAnnual', 'salary', 'annualSalary', 'priorSalaryAnnual', 'payRate'];
			var selectedFieldsContainCurrencyFields = !!intersection(fields.mapProperty('id'), currencyFields).length;
			if (selectedFieldsContainCurrencyFields === true){
				this.set('shouldSelectCurrency', true);
			}
			else{
				this.set('shouldSelectCurrency', false);
			}
			if (this.get('shouldSelectCurrency') === true){
				this.set('checked',true);
			}
			else{
				this.set('checked',false);
				}
		}
		var isSelected = fields.contains(field);
		if (this.get('checked') != isSelected) {
			this.set('checked', isSelected);
		}
	}.observes('fields.length'),
	onChange: function() {
		var fields = this.get('fields');
		if (!fields) { return; }
		var field = this.get('field');
		if (this.get('checked')) {
			fields.add(field);
		}
		else {
			fields.remove(field);
		}
	}.observes('checked')
});

App.ReportsRoute = Ember.Route.extend({
	beforeModel: function() {
		this.transitionTo('bi.overview');
	}
});

App.FocusInputComponent = Ember.TextField.extend({
	becomeFocused: function() {
		this.$().focus();
	}.on('didInsertElement')
});

App.DepartmentsController = zen._AbstractArraySavingController.extend({
	init: function() {
		this._super();
		this.set('employees', dashboard('employees'));
		this.set('departments', App.Department.find());
		this.set('company', dashboard('company'));
		this.set('payrollProvider', dashboard('company.payrollProvider'));
		this.set('wfPayrollCodeType', dashboard('company.payroll.wfPayrollCodeType'));
		this.get('wfPayrollCodeType').then(function(wfPayrollCodeType) {
			this.get('payrollProvider').then(function(payrollProvider){
				if ((payrollProvider === 'WF') && (wfPayrollCodeType === 4)) {
					this.set('requiresCoCode', true);
				}
			}.bind(this));
		}.bind(this));
		this.get('departments').forEach(function(department) {
			department.set('isEditing', false);
		}.bind(this));
	},
	isEmployeeSetupNeeded: false,
	errorMessage: null,
	sortedDepartments: Ember.computed.sortByProperty('model', 'name'),
	addDepartment: function() {
		this.get('wfPayrollCodeType').then(function(wfPayrollCodeType) {
			this.get('payrollProvider').then(function(payrollProvider){
				var wfCoCode = this.get('wfCoCode'),
				departmentName = this.get('newDepartmentName');
				wfCoCode = wfCoCode ? wfCoCode.trim() : wfCoCode;

				if (!departmentName) {
					// error message? why would they try and do this?
					this.set('errorMessage', 'Please enter department name.');
					return;
				}
				if ((payrollProvider === 'WF') && (wfPayrollCodeType === 4) && !App.Utils.isValidWfCode(wfCoCode)) {
						// error, bad co-code
						this.set('errorMessage', 'Please enter valid Workforce Comapany Code.');
						return;
				}
				var departmentRecord = null;
				if (departmentName) {
					departmentRecord = App.Department.createRecord({"name": departmentName, "wfCoCode": wfCoCode}).save();
				// if they click on the button
				}

				this.set('newDepartmentName', null);
				this.set('wfCoCode', null);
				this.set('errorMessage', null);
				return departmentRecord;
			}.bind(this));
		}.bind(this));
	},
	requiresCoCode: false,
	removeDepartment: function(department) {
		// NB the django model has on_delete=models.SET_NULL so we don't need to clear the employee's department manually
		this.get('employees').forEach(function(employee) {
			if (department == employee.get('department')) {
				this.set('isEmployeeSetupNeeded', true);
			}
		}.bind(this));
		department.deleteRecord();
	},
	saveAndSetupEmployees: function() {
		this.set('isEmployeeSetupNeeded', true);
		this.save();
	},
	startEditing: function(department) {
		department.set('isEditing', true);
		this.get('model').forEach(function(d) {
			if (d != department){
				d.set('isEditing', false);
			}
		});
	},
	stopEditing: function(department) {
		department.set('isEditing', false);
	},
	save: function() {
		this.saveAndContinue().then(function() {
			this.send('hideModal');
			if (this.get('isEmployeeSetupNeeded')) {
				this.transitionToRoute('departments_onetimesetup');
			}
		}.bind(this));
	},
});


App.DepartmentsOnetimesetupRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function() {
		return Ember.RSVP.hash({
			"departments": wrapArrayPromise(App.Department.find()),
			"employees": wrapArrayPromise(dashboard('employees'))
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('employeesTransaction', App.store.transaction());
		controller.set('departmentsTransaction', App.store.transaction());
	},
	deactivate: function() {
		this._super();
		this.get('controller.employeesTransaction').rollback();
		this.get('controller.departmentsTransaction').rollback();
	}
});
App.DepartmentsOnetimesetupController = zen._AbstractSavingController.extend({
	activeEmployees: function() {
		return this.get('employees').filterProperty('isActive').filterProperty('isEmployee');
	}.property('employees.@each.isActive'),
	sortedDepartments: Ember.computed.sortByProperty('departments', 'name'),
	onDepartmentChange: function() {
		// loop through employees checking departmentHack against department.name
		this.get('activeEmployees').forEach(function(employee) {
			var name = employee.get('department.name');
			var hack = employee.get('departmentHack');
			if (employee.get('isDirty')) {
				this.get('employeesTransaction').add(employee);
			}
			if (hack && name != hack) {
				var newDepartment = this.get('departmentsTransaction').createRecord(App.Department, {"name": hack});
				employee.set('department', newDepartment);
			}
		}.bind(this));
		// loop through departments removing any unused new departments
		this.get('departments').toArray().forEach(function(department) {
			if (department.get('isNew') && !this.get('employees').filterProperty('department', department).get('length')) {
				department.deleteRecord();
			}
		}.bind(this));
	}.observes('employees.@each.departmentHack', 'employees.@each.department'),
	removeDepartment: function(department) {
		// TODO clear department on
		this.get('departmentsTransaction').add(department);
		department.deleteRecord();
	},
	actions: {
		save: function() {
			return this.get('departmentsTransaction').commit().then(function() {
				return this.get('employeesTransaction').commit().then(function() {
					history.back();
				}.bind(this));
			}.bind(this));
		}
	},
});

App.HighlyCompensatedAndKeyEmployeesMixin = Ember.Mixin.create({
	highlyCompensatedAndKeyEmployees: Ember.computed.filterBy('employees', 'shouldShowHighlyCompensatedAndKeyEmployees'),
});

App.CompanyBankVerificationConfirmationMixin = Ember.Mixin.create({
	postSaveCallback: function() {
		this.send('showModal', 'companyBankVerificationConfirmation', {'isAgreementHSA': !!this.get('isAgreementHSA'), 'nextRoute': this.get('nextRoute'),  'wcsSwitchForNewEEAndER': this.get('wcsSwitchForNewEEAndER')});
	},
});

App.CheckAndShowErrorOrSaveAndContinueMixin = Ember.Mixin.create({
	truthy: true,
	checkComplete: 'truthy',
	checkCompleteErrorMsg : '',
	checkCompleteAfterSave: 'truthy',
	nextRoute: 'index',
	validations: Ember.computed(function() {
		return Ember.Set.create();
	}),
	save: function(objectsToReload) {
		this.set('errorText', '');

		// Client-Side validation
		if (!this.get(this.get('checkComplete'))) {
			var defaultMsg = 'Certain fields are missing information. Please complete all fields';
			var msg = this.get('checkCompleteErrorMsg') || defaultMsg;
			this.set('errorText', msg);
			return;
		}

		// Client-side validation - this needs to be used with App.ValidationsControllerMixin
		if (this.get('validations.length') >= 1) {
			this.set('errorText', 'Certain fields contain invalid data. Please correct the invalid fields');
			return;
		}

		// This can be thought of as pre-setter that is required for the 'check'.
		var setBeforeCheck = this.get('setBeforeCheck');
		if (typeof setBeforeCheck == 'function') {
			setBeforeCheck.call(this);
		}

		// This has filed level validations, that might involve interacting with multiple properties.
		var fieldValidations = this.get('fieldValidations');
		if (typeof fieldValidations == 'function') {
			if (!fieldValidations.call(this)) {
				return;
			}
		}

		// This is more of a post-check and post-validations setter.
		// For simple setters like setting a flag true use the object syntax.
		// Only works for pre-save validation
		var setAfterCheckAndValidationsPass = this.get('setAfterCheckAndValidationsPass');
		if (typeof setAfterCheckAndValidationsPass == 'function') {
				setAfterCheckAndValidationsPass.call(this);
		} else if (typeof setAfterCheckAndValidationsPass == 'object') {
			var fieldNames = Object.keys(setAfterCheckAndValidationsPass);
			for (var i = 0; i < fieldNames.length; i++) {
				var fieldName = fieldNames[i];
				var fieldValue = setAfterCheckAndValidationsPass[fieldName];
				this.set(fieldName, fieldValue);
			}
		}

		return this.saveAndContinue().then(function() {
			var promises = [];
			if (objectsToReload) {
				objectsToReload.forEach(function(obj) {
					promises.push(obj.reload());
				});
			}
			return Ember.RSVP.all(promises);
		}.bind(this)).then(function() {
			// Backend validation
			if (!this.get(this.get('checkCompleteAfterSave'))) {
				this.set('errorText', 'Certain fields are missing information. Please complete all fields');
				return Ember.RSVP.resolve();
			}

			var postSaveCallback = this.get('postSaveCallback');
			if (postSaveCallback && typeof postSaveCallback == 'function') {
				return postSaveCallback.call(this);
			} else {
				return this.transitionToRoute(this.get('nextRoute'));
			}

		}.bind(this));
	}
});

App.FlexBenefitsPayrollInfoMixin = Ember.Mixin.create({
	hasPayFrequency: Ember.computed.or('payFrequency', 'newPayroll.newPayFrequency'),
	isPayrollFrequencyReadOnly: Ember.computed.and('hasActiveSyncedPayroll', 'hasPayFrequency'),
	isPayrollInfoReadOnly: function() {
		return this.get('firstPayrollChoices') && !this.get('isRenewal');
	}.property('firstPayrollChoices', 'isRenewal'),
	computedFirstPayrollAfterStartDate: function() {
		if (this.get('firstPayrollChoices')) {
			var firstPayroll = this.get('firstPayrollChoices')[this.get('flexBenefitEnrollmentStartDate')];
			this.set('destinationFirstPayrollAfterStartDate', firstPayroll);
			return firstPayroll;
		}
	}.property('firstPayrollChoices', 'flexBenefitEnrollmentStartDate'),
	ajaxFirstPayrollAfterStartDate: function()  {
		if (this.get('hasActiveSyncedPayroll') && this.get('effectiveDateChoices')) {
			// We need to slice(1) out "Select" as part of effectiveDateChoices
			var effectiveChoices = this.get('effectiveDateChoices').slice(1).map(function(d) {
				return d.id;
			});

			Ember.ajax('/custom_api/getFirstPayrollsAfterStartDates?startDates=' + JSON.stringify(effectiveChoices)).then(function(data) {
				this.set('firstPayrollChoices', data);
			}.bind(this));
		}
	}.observes('effectiveDateChoices'),
});


App._FsaContributionControllerMixin = Ember.Mixin.create({
	truthy: true,
	falsy: false,
	fieldValidations: function() {
			var medicalCareContribution = this.get('currFsa.medicalCareContribution');
			var dependentCareContribution = this.get('currFsa.dependentCareContribution');
			if(Ember.isEmpty(medicalCareContribution) || Ember.isEmpty(dependentCareContribution)) {
				this.set('errorText',"Please specify a valid medical care and dependent care contribution (enter $0 if you don't wish to contribute anything).");
				return false;
			}
			var maxDependentCareContribution = this.get('currFsa.maxDependentCareContribution');
			var maxMedicalCareContribution = this.get('currFsa.maxMedicalCareContribution');
			if (parseFloat(medicalCareContribution) > maxMedicalCareContribution) {
				this.set('errorText', 'The matching contribution for medical FSA cannot exceed $' + maxMedicalCareContribution + ' this year.');
				return false;
			}
			if (parseFloat(dependentCareContribution) > maxDependentCareContribution) {
				this.set('errorText', 'The dependent care contribution cannot be more than $' + maxDependentCareContribution + ' this year.');
				return false;
			}
			var isRollover = this.get('currFsa.isRollover');

			if (isRollover) {
				var rolloverAmount = this.get('currFsa.rolloverAmount');
				var maxFsaRollOverLimit = this.get('currFsa.maxFsaRollOverLimit');
				if (parseFloat(rolloverAmount) > parseFloat(maxFsaRollOverLimit)) {
					this.set('errorText', 'The roll over amount cannot be more than $'+ maxFsaRollOverLimit +' in a year.');
					return false;
				}
			} else {
				this.set('currFsa.rolloverAmount', null);
			}

			return true;
		}
});

// Legacy HRA v1 style routes

App.HrasetupRoute = Ember.Route.extend({
	redirect: function(model, transition) {
		this.transitionTo('hraAdminSetup.planDetails.planYear');
	}
});

// End Legacy FSA v1 style routes

// Legacy FSA v1 style routes

App.FsaIntroRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var isBasicsPackageCompany = Ember.ajaxGet('/custom_api/is-basics-package-company/').then(function(data) {
			return data['isBasicsPackageCompany'];
		});
		var isIOMCompany = Ember.ajaxGet('/custom_api/is-iom-company/').then(function(data) {
			return data['isIOMCompany'];
		}).catch(function(data) {
			//;
		});
		return this.all(dashboard('company'), {
			"isReadOnly": dashboard('isReadOnly'),
			'newPayroll': dashboard('company.newPayroll'),
			'currFsa': dashboard('company.fsa'),
			'isTrialCompany': dashboard('isTrialCompany'),
			'isBasicsPackageCompany': isBasicsPackageCompany,
			'isIOMCompany': isIOMCompany,
		});
	},
	afterModel: function(model) {
	    return Ember.RSVP.all([
	    	thenpath(model, 'isBasicsPackageCompany'),
	    ]);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		controller.set('isRenewal', false);
		App.ProductStage.createFSAEvent('marketing_page_view', 0.0);
	}
});

App.FsaIntroController = zen._TransactionSavingController.extend({
	buttonText: function() {
		if (this.get('model.fsa.isFillingOut')) {
			return 'Continue Set Up';
		}
		else {
			return 'Get Started Now';
		}
	}.property('model.fsa'),
	 getStartedRoute: function() {
    	if (this.get('model.isBasicsPackageCompany')) {
    		return "billing.contactSupport";
    	}
        return 'fsaAdminSetup.planDetails.planYear';
    }.property('model.isBasicsPackageCompany'),
	actions: {
		showTrialGatingModal: function() {
			this.eventLogger.log('v1_trial_gating_modal_open', {
				productName: 'FSA',
			});
			this.send('showModal', 'flexBenTrialGating', {
				'productName': 'FSA',
				'customBodyText': "Once you set up your real Zenefits account, you can offer your employees flexible savings accounts (FSAs).",

			});
		}
	},
});


App.FsasetupRoute = Ember.Route.extend({
	redirect: function(model, transition) {
		this.transitionTo('fsaAdminSetup.planDetails.planYear');
	}
});

App.FsarenewalgetstartedRoute = Ember.Route.extend({
	model: function() {
		return Ember.RSVP.hash({
			'currFsa': dashboard('company.fsa.renewal'),
		});
	}
});

App.FsarenewalRoute = Ember.Route.extend({
	redirect: function(model, transition) {
		this.transitionTo('fsaAdminSetup.planDetails.planYear');
	},
});

// End Legacy FSA v1 style routes

App.HsaController = zen._TransactionSavingController.extend({
	isPolicyInfoComplete: function() {
		return zen.checkIfStartDateInEffectiveDateChoices.bind(this)('hsa.startDate') && this.get('hsa.startDate') && this.get('hsa.firstPreTaxWithholdingDate') && (this.get('payFrequency') || this.get('newPayroll.newPayFrequency'));
	}.property('hsa.startDate', 'hsa.firstPreTaxWithholdingDate', 'hasActiveSyncedPayroll', 'payFrequency', 'newPayroll.newPayFrequency'),
	isContributionLevelComplete: Ember.computed.and('hsa.employerContributionForEmployee', 'hsa.employerContributionForDependents'),
});

App.HsaRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var isIOMCompany = Ember.ajaxGet('/custom_api/is-iom-company/').then(function(data) {
			return data['isIOMCompany'];
		}).catch(function(data) {
			//;
		});

		return this.all(dashboard('company'), {
			"isReadOnly": dashboard('isReadOnly'),
			"banks": dashboard('company.banks'),
			"isIOMCompany": isIOMCompany,
		});
	},
	redirect: function(model, transition) {
		if (model.get('hsa.status') == 'complete') {
			this.transitionTo('hsaSettings.overview');
		} else if (transition.targetName == 'hsa.index') {
			this.transitionTo('hsa.info');
		}
	},
	afterModel: function(model) {
		var promises = [];
		promises = promises.concat([
			thenpath(model, 'hsa'),
			thenpath(model, 'alegeusSettings'),
		]);
		return Ember.RSVP.all(promises);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		if (model.get('hsa.status') == null) {
			model.set('hsa.status', 'filling-out');
			App.store.commit();
		}
	}
});

App.HsaCompanyRoute = App.HsaRoute.extend();
App.HsaCompanyController = App.HsaController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	nextRoute: 'hsa.policy',
	checkCompleteAfterSave: 'hsa.isCompanyInfoComplete',
	continue: function() {
		App.ProductStage.createHSAEvent('progress', 0.20);
		this.save([this.get('hsa')]);
	}
});

App.HsaPolicyRoute = App.HsaRoute.extend(zen._CommuterEffectiveDateMixin, zen._UnicardSetupStartDateMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);
		var minimumValidStartDate = zen.americanDateAsDate(model.get('hsa.computedEffectiveDate'));
		controller.set('effectiveDateChoices', this.effectiveDateChoices(minimumValidStartDate));
		if (model.get('hasActiveSyncedPayroll')) {
			if (model.get('payFrequency')) {
				controller.set('displayPayrollFrequency', getFullPayFrequency(model.get('payFrequency')));
			} else if (model.get('newPayroll') && model.get('newPayroll.newPayFrequency')) {
				controller.set('displayPayrollFrequency', getFullPayFrequency(model.get('newPayroll.newPayFrequency')));
			}
		}
	}
});
App.HsaPolicyController = App.HsaController.extend(App.FlexBenefitsPayrollInfoMixin, {
	flexBenefitEnrollmentStartDate: Ember.computed.alias('hsa.startDate'),
	destinationFirstPayrollAfterStartDate: Ember.computed.alias('hsa.firstPreTaxWithholdingDate'),
	minimumValidStartDate: Ember.computed.alias('hsa.computedEffectiveDate'),
	fifteenthOfMonthBeforeStartDate: function() {
		var startDate = zen.americanDateAsDate(this.get('hsa.startDate'));
		return startDate && moment(startDate).subtract(1, 'month').date(15);
	}.property('hsa.startDate'),
	monthAfterStartDateMonth: function() {
		var startDate = zen.americanDateAsDate(this.get('hsa.startDate'));
		return startDate && moment(startDate).add(1, 'months');
	}.property('hsa.startDate'),
	fifteenthOfMonthBeforeMinimumValidStartDate :function() {
		var minimumValidStartDate = zen.americanDateAsDate(this.get('minimumValidStartDate'));
		return minimumValidStartDate && moment(minimumValidStartDate).subtract(1, 'month').date(15);
	}.property('minimumValidStartDate'),
	monthAfterMinimumValidStartDateMonth :function() {
		var minimumValidStartDate = zen.americanDateAsDate(this.get('minimumValidStartDate'));
		return minimumValidStartDate && moment(minimumValidStartDate).add(1, 'months');
	}.property('minimumValidStartDate'),
	save: function() {
		if (this.get('computedFirstPayrollAfterStartDate')) {
			this.set('hsa.firstPreTaxWithholdingDate', this.get('computedFirstPayrollAfterStartDate'));
		}

		if (!this.get('isPolicyInfoComplete')) {
			this.set('errorText', 'Some fields are missing, all fields are required.');
			return;
		}

		var model = this.get('content'),
			startDateYear = zen.parseAmericanDate(model.get('hsa.startDate')).year,
			firstPretaxWithholdingYear = zen.parseAmericanDate(model.get('hsa.firstPreTaxWithholdingDate')).year,
			startDateMonth = zen.parseAmericanDate(model.get('hsa.startDate')).month,
			firstPretaxWithholdingMonth = zen.parseAmericanDate(model.get('hsa.firstPreTaxWithholdingDate')).month,
			startDateValue = +zen.americanDateAsDate(model.get('hsa.startDate')),
			firstPretaxWithholdingStartValue = +zen.americanDateAsDate(model.get('hsa.firstPreTaxWithholdingDate')),
			firstPretaxWithholdingStartDate = zen.parseAmericanDate(model.get('hsa.firstPreTaxWithholdingDate'));

		if (firstPretaxWithholdingStartValue < startDateValue) {
			this.set('errorText', 'The first pre-tax withholding date should be after the plan start date.');
			return false;
		}

		if (startDateMonth != firstPretaxWithholdingMonth || startDateYear != firstPretaxWithholdingYear) {
			this.set('errorText', 'The first pre-tax withholding date and start date have to be in the same month.');
			return false;
		}

		if (this.get('payFrequency') == 'SM' && firstPretaxWithholdingStartDate.day > 15) {
			this.set('errorText', 'First pre-tax withholding date must be on the 15th day of the month or earlier.');
			return false;
		}

		this.set('errorText', '');

		App.ProductStage.createHSAEvent('progress', 0.40);

		this.saveAndContinue('hsa.contribution');
	}
});

App.HsaContributionRoute = App.HsaRoute.extend();
App.HsaContributionController = zen._TransactionSavingController.extend({
	isPolicyInfoComplete: function() {
		return zen.checkIfStartDateInEffectiveDateChoices.bind(this)('hsa.startDate') && this.get('hsa.startDate') && this.get('hsa.firstPreTaxWithholdingDate') && (this.get('payFrequency') || this.get('newPayroll.newPayFrequency'));
	}.property('hsa.startDate', 'hsa.firstPreTaxWithholdingDate', 'hasActiveSyncedPayroll', 'payFrequency', 'newPayroll.newPayFrequency'),
	isContributionLevelComplete: Ember.computed.and('hsa.employerContributionForEmployee', 'hsa.employerContributionForDependents'),
	truthy: true,
	falsy: false,
	save: function() {
		if (!this.get('isContributionLevelComplete')) {
			this.set('errorText', 'Please specify the contribution levels. Enter $0 if you do not wish to contribute.');
			return;
		}
		var contributionForEmployee = this.get('hsa.employerContributionForEmployee');
		var contributionForDependents = this.get('hsa.employerContributionForDependents');
		var currentMaxMonthlyEmployeeContribution = this.get('hsa.currentMaxMonthlyEmployeeContribution');
		var currentMaxMonthlyEmployeeWithDependentsContribution = this.get('hsa.currentMaxMonthlyEmployeeWithDependentsContribution');
		if (contributionForEmployee == null || contributionForDependents == null) {
			this.set('errorText', 'Please specify a valid employer contribution. Enter $0 if you do not wish to contribute.');
			return;
		}
		contributionForEmployee = parseFloat(contributionForEmployee);
		var totalContribution = parseFloat(contributionForEmployee) + parseFloat(contributionForDependents);
		if (contributionForEmployee > currentMaxMonthlyEmployeeContribution) {
			this.set('errorText', "Contribution cannot be greater than $" + currentMaxMonthlyEmployeeContribution +
							" per employee per month.");
			return;
		}
		if (totalContribution > currentMaxMonthlyEmployeeWithDependentsContribution) {
			this.set('errorText', "Total contribution cannot be greater than $" + currentMaxMonthlyEmployeeWithDependentsContribution +
							 " for employees (per month).");
			return;
		}
		this.set('errorText', null);
		App.ProductStage.createHSAEvent('progress', 0.60);

		this.saveAndContinue('hsa.bank');
	}
});

App.HsaBankRoute = App.HsaRoute.extend({
	afterModel: function(model) {
		var promises = [];
		promises = promises.concat([
			thenpath(model, 'hsa'),
		]);
		return Ember.RSVP.all(promises);
	},
});
App.HsaBankController = zen._TransactionSavingController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, App.CompanyBankVerificationConfirmationMixin, {
	isAgreementHSA: true,
	prevRoute: 'hsa.contribution',
	wcsSwitchForNewEEAndER: Ember.computed.alias('switches.Enable_WCS_New_ERandEE'),
	isPolicyInfoComplete: function() {
		return zen.checkIfStartDateInEffectiveDateChoices.bind(this)('hsa.startDate') && this.get('hsa.startDate') && this.get('hsa.firstPreTaxWithholdingDate') && (this.get('hsa.company.payFrequency') || this.get('hsa.company.newPayroll.newPayFrequency'));
	}.property('hsa.startDate', 'hsa.firstPreTaxWithholdingDate', 'hsa.company.hasActiveSyncedPayroll', 'hsa.company.payFrequency', 'hsa.company.newPayroll.newPayFrequency'),
	checkCompleteAfterSave: 'hsa.isBankInfoComplete',
	isContributionInfoComplete: function() {
		return this.get('hsa.employerContributionForEmployee') && this.get('hsa.employerContributionForDependents');
	}.property('hsa.employerContributionForEmployee', 'hsa.employerContributionForDependents'),

	nextRoute: function() {
		if (!this.get('hsa.isCompanyInfoComplete')) {
			return 'hsa.company';
		} else if (!this.get('isPolicyInfoComplete')) {
			return 'hsa.policy';
		} else if (!this.get('isContributionInfoComplete')) {
			return 'hsa.contribution';
		} else if (this.get('model.isIOMCompany')) {
    		return 'hsasetupfinish';
    	} else {
			return 'hsa.billing';
		}
	}.property('hsa.isCompanyInfoComplete', 'isPolicyInfoComplete', 'isContributionInfoComplete'),
	continue: function() {
		this.save([this.get('hsa')]);
		App.ProductStage.createHSAEvent('progress', 0.8);
	}
});

App.HsaBillingRoute = App.HsaRoute.extend();
App.HsaBillingController = zen._TransactionSavingController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, App.CompanyBankVerificationConfirmationMixin, {
	isAgreementHSA: true,
	prevRoute: 'hsa.bank',
	wcsSwitchForNewEEAndER: Ember.computed.alias('switches.Enable_WCS_New_ERandEE'),
	isPolicyInfoComplete: function() {
		return zen.checkIfStartDateInEffectiveDateChoices.bind(this)('hsa.startDate') && this.get('hsa.startDate') && this.get('hsa.firstPreTaxWithholdingDate') && (this.get('hsa.company.payFrequency') || this.get('hsa.company.newPayroll.newPayFrequency'));
	}.property('hsa.startDate', 'hsa.firstPreTaxWithholdingDate', 'hsa.company.hasActiveSyncedPayroll', 'hsa.company.payFrequency', 'hsa.company.newPayroll.newPayFrequency'),
	isContributionInfoComplete: function() {
		return this.get('hsa.employerContributionForEmployee') && this.get('hsa.employerContributionForDependents');
	}.property('hsa.employerContributionForEmployee', 'hsa.employerContributionForDependents'),

	errorText: '',
	showErrorText: Ember.computed.alias('errorText'),
	costPerEmployeePerMonthForMonthlyBilling: Ember.computed.alias('hsa.costPerEmployeePerMonthForMonthlyBilling'),
	estimatedEmployees: Ember.computed.alias('hsa.minEmployeesForBilling'),
	estimatedCost: Ember.computed.alias('hsa.estimatedMonthlyCost'),
	paymentInfo: Ember.computed('company.primaryBank', function() {
            var bankAccountType = "";
            bankAccountType = this.get('primaryBank.bankAccountType') == "C" ? "Checking" : "Savings";
			return {
                accountType: bankAccountType,
            };
	}),

	nextRoute: function() {
		if (!this.get('hsa.isCompanyInfoComplete')) {
			return 'hsa.company';
		} else if (!this.get('isPolicyInfoComplete')) {
			return 'hsa.policy';
		} else if (!this.get('isContributionInfoComplete')) {
			return 'hsa.contribution';
		} else if (!this.get('hsa.isBankInfoComplete')) {
			return 'hsa.bank';
		} else {
			return 'hsasetupfinish';
		}
	}.property('hsa.isCompanyInfoComplete', 'isPolicyInfoComplete', 'isContributionInfoComplete', 'hsa.isBankInfoComplete'),
	continue: function () {
		App.ProductStage.createHSAEvent('progress', 0.9);
		this.saveAndContinue(this.get('nextRoute'));
	}
});

App.HsaNextStepsRoute = App.HsaRoute.extend();
App.HsaNextStepsController = App.HsaController.extend({
	needs: 'index',
	isCompanyUnapproved: Ember.computed(
		'controllers.index.company.isApproved',
		'alegeusSettings.isBorElsewhere',
		function() {
			return !this.get('alegeusSettings.isBorElsewhere') && this.get('controllers.index.company.isApproved');
		}),
	isNoEmployeeApproved: Ember.computed.not('controllers.index.company.hasApprovedEmployees'),
	isInBorImplementation: Ember.computed.alias('controllers.index.company.isInBorImplementation')
});


App.HsaInfoRoute = App.HsaRoute.extend();
App.HsaInfoController = App.HsaController.extend();

App.HsasetupfinishRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"alegeusSettings": dashboard('company.alegeusSettings'),
			"isReadOnly": dashboard('isReadOnly'),
			"agreement": Ember.ajaxGet('/custom_api/saas_agreement/'),
		});
	},
});
App.HsasetupfinishController = zen._TransactionSavingController.extend({
	agreementPath: Ember.computed.alias('model.agreement.path'),
	isAgreementHSA: true,
	hsaEffectiveDate: function() {
		return this.get('hsa.startDate');
	}.property('hsa.startDate'),
	planStartDate: Ember.computed.alias('hsa.startDate'),
	save: function() {
		if (this.get('hsa.name') && this.get('hsa.title') && this.get('hsa.signature')) {
			this.set('errorText', "");

			var hsaCompanyEnrollment = this.get('hsa');

			Ember.ajaxPost('/custom_api/accept_msa/', JSON.stringify({source: "hsa"})).then(function() {
				this.saveAndContinue().then(function() {
					this.get('hsa').reload().then(function() {
						// After the agreement is saved, we need to determine if hsa-eligible enrollment
						// is completed before letting the employer send out invite emails to employees.
						if (this.get('isEnrollmentCompleteOrApproved') &&
							this.get('hasApprovedEmployees') &&
							!this.get('isInBorImplementation') &&
							!this.get('alegeusSettings.isBorElsewhere')) {
							if (hsaCompanyEnrollment.get('status') == 'filling-out') {
								hsaCompanyEnrollment.set('status', 'emails');
								App.ProductStage.createHSAEvent('progress', 0.95);
							}
							this.saveAndContinue('hsaemailspreview');
						} else {
							if (this.get('hsa.status') == 'filling-out') {
								this.set('hsa.status', 'complete');
								App.ProductStage.createHSAEvent('complete', 1.0, this.get('hsa.startDate'));
							}
							this.saveAndContinue().then(function() {
								if (this.get('alegeusSettings.isBorElsewhere')) {
									this.eventLogger.log("hsa_company_setup_complete");
									this.send('showModal', 'hsa.eligibilityredirect');
								} else {
									var o = Ember.Object.create({
										effectiveDate: this.get('hsa.startDate'),
										emailsSent: false,
									});
									this.eventLogger.log("hsa_company_setup_complete");
									this.send('showModal', 'hsa.nextSteps', o);
								}
							}.bind(this));
						}
					}.bind(this));
				}.bind(this));
			}.bind(this));
		} else {
			this.set('errorText', 'Some fields are missing, all fields are required.');
		}
	}
});

App.HsaemailspreviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('employees'), { "company": dashboard('company')});
	},
	afterModel: function(model) {
		return thenpath(this.otherModels.company, 'hsa');
	},
});

App.HsaemailspreviewController = zen._TransactionArraySavingController.extend({
	buttonText: "Email Employees Now",
	buttonClass: "button",
	eligibleEmployees: Ember.computed.filterByProperty('content', 'isEligibleForHSA'),

	hasEligibleEmployees: function() {
		return this.get('eligibleEmployees.length') > 0;
	}.property('eligibleEmployees'),

	emailPreviewUrl: function() {
		var id = this.get('eligibleEmployees.firstObject.id');
		var url = '/getEmployeesHsaInvitesPreview/' + id;
		return id ? url : undefined;
	}.property('eligibleEmployees.firstObject.id'),

	submit: function() {
		var hsaCompanyEnrollment = this.get('company.hsa');

		this.set('errorText', '');
		this.set('buttonClass', 'button grey');
		this.set('buttonText', 'Sending Emails...');

		if (hsaCompanyEnrollment.get('status') == 'emails') {
			hsaCompanyEnrollment.set('status', 'complete');
			App.ProductStage.createHSAEvent('complete', 1.0, hsaCompanyEnrollment.get('startDate'));
		}


		this.saveAndContinue().then(function() {
			return this.get('company').reload();
		}.bind(this)).then(function() {
			return Ember.ajaxPost('/sendHsaEmployeesRegisterLinks');
		}.bind(this)).then(function() {
			this.set('buttonClass', 'button');
			this.set('buttonText', 'Emails Sent');
			var o = Ember.Object.create({
				effectiveDate: hsaCompanyEnrollment.get('startDate'),
				productName: 'HSA',
				emailSentToEmployees: true,
				otherProductNames: 'FSA, HRA or Commuter Benefits',
			});
			this.send('showModal', 'hsasetup.nextsteps', o);
		}.bind(this)).catch(function(error) {
			this.set('buttonClass', 'button');
			this.set('buttonText', 'Try Again?');
			this.set('errorText', 'Operation unsuccessful');
		}.bind(this));
	}
});

App.HsasetupNextstepsController = Ember.ObjectController.extend();

App.HsaEmailsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	setupController: function(controller, model)
	{
		this._super(controller, model);
		emailGuessedSetups(controller, model, 'isEligibleForHSA');
		if (!controller.get('hasMissingEmails')) {
			this.transitionTo(controller.get('emailsPreviewRoute'));
		}
	},
	model: function() {
		var employees = dashboard('employees');
		var employeeHealthStatusProperties = employees.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeHealthStatusProperties'));
		});
		return this.all(employees, {
			'companyEnrollment': dashboard('company.hsa'),
			'employeeHealthStatusProperties': employeeHealthStatusProperties,
		});
	}
});

App.HsaEmailsController = zen._TransactionArraySavingController.extend(zen._InviteEmailsMixin, zen._MissingEmployeeEmailsMixin, {
	buttonText: "Update Missing Emails",
	buttonClass: "button",
	emailsPreviewRoute: 'hsa.emailspreview',
	modelType: 'hsa',

	eligibleEmployees: function() {
		var content = this.get('content');
		return content.filterProperty('isEligibleForHSA').filterProperty('isMedicalCompleteOrApproved');
	}.property('@each')
});

App.HsaEmailspreviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var employees = dashboard('employees');
		var employeeHealthStatusProperties = employees.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeHealthStatusProperties'));
		});
		return this.all(employees, {
			'companyEnrollment': dashboard('company.hsa'),
			'employeeHealthStatusProperties': employeeHealthStatusProperties,
		});
	}
});

App.HsaEmailspreviewController = zen._TransactionArraySavingController.extend({
	buttonText: "Email Employees Now",
	buttonClass: "button",

	eligibleEmployees: function() {
		var content = this.get('content');
		return content.filterProperty('isEligibleForHSA').filterProperty('isMedicalCompleteOrApproved');
	}.property('@each'),

	hasEligibleEmployees: function() {
		return this.get('eligibleEmployees.length') > 0;
	}.property('eligibleEmployees'),

	emailPreviewUrl: function() {
		var id = this.get('eligibleEmployees.firstObject.id');
		var url = '/getEmployeesHsaInvitesPreview/' + id;
		return id ? url : undefined;
	}.property('eligibleEmployees.firstObject.id'),

	submit: function() {
		var self = this;
		var model = this.get('companyEnrollment');
		self.set('errorText', null);
		this.set('buttonClass', 'button grey');
		this.set('buttonText', 'Saving...');

		if (model.get('status') == 'confirm-send-to-employees') {
			model.set('status', 'complete');
		}
		this.saveAndContinue().then(function() {
			$.ajax({
				url: '/sendHsaEmployeesRegisterLinks',
				type: "get",
				success: function(response) {
					// Hack to show the HSA card correctly in the index page.
					// Otherwise, at times the dashboard still shows the "Set Up" link
					// after the HSA has been set up.
					var company = self.get('companyEnrollment.company');
					company.reload();

					self.set('buttonClass', 'button');
					self.set('buttonText', 'Email Employees Now');
					var o = Ember.Object.create({
						effectiveDate: model.get('startDate'),
						emailsSent: true,
					});
					self.send('showModal', 'hsa.nextSteps', o);
				},
				error: function(jqXHR, textStatus, errorThrown) {
					self.set('buttonClass', 'button');
					self.set('buttonText', 'Try Again?');
					self.set('errorText', 'Operation unsuccessful');
				}
			});
		});
	}
});

App.NextEffectiveDateCalculationMixin = Ember.Mixin.create({
	nextCalculatedEffectiveDate: function(){
		return moment(this.get('calculatedEffectiveDate')).add(1, 'months').calendar();
	}.property('calculatedEffectiveDate'),
	deadline: function(){
		return moment(this.get('calculatedEffectiveDate')).subtract(1, 'months').set('date', this.get('deadlineCutoffDay'));
	}.property('calculatedEffectiveDate', 'deadlineCutoffDay'),
});

App.ProductStage = {
	createFSAEvent : function(action, percentageComplete, createddate){
		this.__createEvent(action, percentageComplete, 'FSA', createddate);
	},
	createHSAEvent : function(action, percentageComplete, createddate){
		this.__createEvent(action, percentageComplete, 'HSA', createddate);
	},
	createTAEvent : function(action, percentageComplete) {
		this.__createEvent(action, percentageComplete, 'TA', null);
	},
	createPMEvent : function(action, percentageComplete) {
		this.__createEvent(action, percentageComplete, 'PM', null);
	},
	createPTOEvent : function(action, percentageComplete) {
		this.__createEvent(action, percentageComplete, 'PTO', null);
	},
	createCommuterEvent : function(action, percentageComplete, createddate) {
		this.__createEvent(action, percentageComplete, 'COM_BEN', createddate);
	},
	createHRAEvent : function(action, percentageComplete, createddate){
		this.__createEvent(action, percentageComplete, 'HRA', createddate);
	},
	createAllInsuranceEvent : function(action, percentageComplete, ctx){
		if (ctx) {
			if (ctx.get('borMedicalEnrollment')) {
				App.ProductStage.createMedicalEvent(action, percentageComplete);
			}
			if (ctx.get('borAdditionalMedicalEnrollment')) {
				App.ProductStage.createMedical2Event(action, percentageComplete);
			}
			if (ctx.get('borDentalEnrollment')) {
				App.ProductStage.createDentalEvent(action, percentageComplete);
			}
			if (ctx.get('borVisionEnrollment')) {
				App.ProductStage.createVisionEvent(action, percentageComplete);
			}
			if (ctx.get('borLifeEnrollment')) {
				App.ProductStage.createLifeEvent(action, percentageComplete);
			}
			if (ctx.get('borStDisabilityEnrollment')) {
				App.ProductStage.createSTDEvent(action, percentageComplete);
			}
			if (ctx.get('borLtDisabilityEnrollment')) {
				App.ProductStage.createLTDEvent(action, percentageComplete);
			}
		}
	},
	createHealthInsuranceEvent : function(action, percentageComplete, ctx){
		if (ctx) {
			if (ctx.get('companyMedicalEnrollment')) {
				App.ProductStage.createMedicalEvent(action, percentageComplete);
			}
			if (ctx.get('companyDentalEnrollment')) {
				App.ProductStage.createDentalEvent(action, percentageComplete);
			}
			if (ctx.get('companyVisionEnrollment')) {
				App.ProductStage.createVisionEvent(action, percentageComplete);
			}
		// if enrollments haven't been fetched before
		} else {
			var medicalEnrollment = zen.companyEnrollmentApi('companyMedicalEnrollment');
			var dentalEnrollment = zen.companyEnrollmentApi('companyDentalEnrollment');
			var visionEnrollment = zen.companyEnrollmentApi('companyVisionEnrollment');

			if (medicalEnrollment) {
				App.ProductStage.createMedicalEvent(action, percentageComplete);
			}
			if (dentalEnrollment) {
				App.ProductStage.createDentalEvent(action, percentageComplete);
			}
			if (visionEnrollment) {
				App.ProductStage.createVisionEvent(action, percentageComplete);
			}
		}
	},
	createLifeEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'LD', null);
	},
	createLTDEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'LTD', null);
	},
	createSTDEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'STD', null);
	},
	createMedicalEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'MEDICAL', null);
	},
	createMedical2Event : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'MEDICAL_2', null);
	},
	createDentalEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'DENTAL', null);
	},
	createVisionEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'VISION', null);
	},
	create401KEvent : function(action, percentageComplete, createddate){
		this.__createEvent(action, percentageComplete, '401k', createddate);
	},
	createBackgroundCheckEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'OB_BC', null);
	},
	createPYPEvent : function(action, percentageComplete){
		this.__createEvent(action, percentageComplete, 'PYP', null);
	},
	__createEvent : function(action, percentageComplete, productType, createddate) {
		//TODO - functionality removed, clean all the caller if needed
		return;
	}
};

App.SignatureFetchOrCreateMixin = Ember.Mixin.create({
	afterModel: function(model) {
		var superResult = this._super.apply(this, arguments);
		var baseModelPath = this.get('baseModelPath');
		var signatureName = this.get('signatureName');
		if (Ember.isEmpty(baseModelPath) || Ember.isEmpty(signatureName) || Ember.isEmpty(model[baseModelPath])
				|| !Ember.isEmpty(model[baseModelPath].get(signatureName))) {
			return superResult;
		}

		dashboard('employee').then(function(value) {
			model[baseModelPath].set(signatureName, App.Signature.createRecord({ employee: value}));
		});

		return superResult;
	},
});

function getFullPayFrequency(payFrequency) {
	if (payFrequency == 'Mo') {
		return 'Monthly';
	}
	else if (payFrequency == 'SM') {
		return 'Semi-monthly';
	}
	else if (payFrequency == 'We') {
		return 'Weekly';
	}
	else if (payFrequency == 'BW') {
		return 'Bi-weekly';
	}

	return payFrequency;
}

zen._CalculatedPlanYearEndDateMixin = Ember.Mixin.create({
	calculatedCurrFsaStartDate: function(key, value, previousValue) {
		if (!value) {
			// renewal comes with set startDate so fetch that if nothing selected
			value = this.get('currFsa.startDate');
		}
		if ((arguments.length > 1 && previousValue !== value) || !this.get('currFsa.planYearEndDate')) {
			this.set('currFsa.startDate', value);
			var planYearEndDate = null;
			if (this.get('currFsa.shortPlanYearEndDate') == this.get('currFsa.fullPlanYearEndDate')) {
				planYearEndDate = this.get('currFsa.shortPlanYearEndDate');
			}
			this.set('currFsa.planYearEndDate', planYearEndDate);
		}
		return this.get('currFsa.startDate');
	}.property('currFsa.startDate')
});

App.FsaAndHraComplianceControllersMixin = {
	buttonText: "Update",
	buttonClass: "button",

	saveCompliance: function() {
		this.set('buttonText', 'Updating ...');
		this.set('buttonClass', 'button grey');
		this.saveAndContinue().then(function () {
			this.set('buttonText', 'Updated');
			this.set('buttonClass', 'button light');
			setTimeout(function () {
				this.set('buttonText', 'Update');
				this.set('buttonClass', 'button');
			}.bind(this), 500);
		}.bind(this));
	}
};


App.HrasetupNextstepsController = Ember.ObjectController.extend();

zen._UcommuterRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"isReadOnly": dashboard('isReadOnly'),
			'newPayroll': dashboard('company.newPayroll'),
			'banks': dashboard('company.banks'),
			'alegeusSettings': dashboard('company.alegeusSettings'),
		});
	},
	afterModel: function(model) {
		return model.get('unicardCommuter').then(function(unicardCommuter) {
			if (unicardCommuter.get('status') == null) {
				unicardCommuter.set('status', 'filling-out');
				return unicardCommuter.save();
			}
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
	}
});

App.UcommuterRoute = zen._UcommuterRoute.extend({
	redirect: function(model, transition) {
		var unicardCommuter = model.get('unicardCommuter');
		if (unicardCommuter.get('status') == 'complete') {
			this.transitionTo('ucommutersettings.overview');
		} else {
			if (transition.targetName == 'ucommuter.index') {
				this.transitionTo('ucommuter.info');
			}
		}
	}
});

App.CloseModalWithNextRouteMixin = Ember.Mixin.create({
	close: function() {
		this.transitionToRoute(this.get('nextRoute'));
		this.send('hideModal');
	}
});

App.CompanyBankVerificationConfirmationController = Ember.ObjectController.extend(App.CloseModalWithNextRouteMixin);

App.UcommutersettingsRoute = zen._UcommuterRoute.extend({
	model: function() {
		return this.all(dashboard('company'), {
			'isReadOnly': dashboard('isReadOnly'),
			'unicardCommuter': dashboard('company.unicardCommuter'),
			'alegeusCompanySettings': dashboard('company.alegeusSettings'),
		});
	}
});

window.getAdminLtdPromise = zen.getAdminLtdPromise = function (result, lineOfCoverage) {
	var enrollments = result[0];
	var carriers = result[1];
	var promises = [];
	var activeIds = [];
	if (enrollments == null || carriers ==null) { return; }
	enrollments.forEach(function (enrollment) {
		if (enrollment.get('isActive')) {
			activeIds.push(enrollment.get('companyHealthCarrier.id'));
		}
	});

	carriers.forEach(function (carrier) {
		if ($.inArray(carrier.id, activeIds) != -1) {
			if (carrier.get('lineOfCoverage') == lineOfCoverage) {
				promises.pushObject(wrapArrayPromise(carrier.get('ltdPlans')));
			}
		}
	});
	return Ember.RSVP.all(promises);
};

window.getAdminStdPromise = zen.getAdminStdPromise = function (result, lineOfCoverage) {
	var enrollments = result[0];
	var carriers = result[1];
	var promises = [];
	var activeIds = [];
	if (enrollments == null || carriers ==null) { return; }
	enrollments.forEach(function (enrollment) {
		if (enrollment.get('isActive')) {
			activeIds.push(enrollment.get('companyHealthCarrier.id'));
		}
	});

	carriers.forEach(function (carrier) {
		if ($.inArray(carrier.id, activeIds) != -1) {
			if (carrier.get('lineOfCoverage') == lineOfCoverage) {
				promises.pushObject(wrapArrayPromise(carrier.get('stdPlans')));
			}
		}
	});
	return Ember.RSVP.all(promises);
};

App.LifeRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'company': dashboard('company'),
			'isReadOnly': dashboard('isReadOnly'),
			'companyLifeEnrollments': zen.companyEnrollmentSettingsApi('companyLifeEnrollments'),
			'companyAdndEnrollments': zen.companyEnrollmentSettingsApi('companyAdndEnrollments'),
			'companyLifeCarriers': zen.companyEnrollmentSettingsApi('companyLifeCarriers'),
			'companyAdndCarriers': zen.companyEnrollmentSettingsApi('companyAdndCarriers'),
			'companyStdEnrollments': zen.companyEnrollmentSettingsApi('companyStdEnrollments'),
			'companyLtdEnrollments': zen.companyEnrollmentSettingsApi('companyLtdEnrollments'),
			'companyStdCarriers': zen.companyEnrollmentSettingsApi('companyStdCarriers'),
			'companyLtdCarriers': zen.companyEnrollmentSettingsApi('companyLtdCarriers'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('lifeChe', controller.get('companyLifeEnrollments').findProperty('isActive', true));
		controller.set('adndChe', controller.get('companyAdndEnrollments').findProperty('isActive', true));
		controller.set('stdChe', controller.get('companyStdEnrollments').findProperty('isActive', true));
		controller.set('ltdChe', controller.get('companyLtdEnrollments').findProperty('isActive', true));

		if (controller.get('lifeChe')) {
			controller.set('lifeEnrolls', controller.get('lifeChe.employeeLifeDisabilityEnrollments').then(function(lifeEnrolls) {
				return lifeEnrolls;
			}));
		}
		if (controller.get('adndChe')) {
			controller.set('adndEnrolls', controller.get('adndChe.employeeLifeDisabilityEnrollments').then(function(adndEnrolls) {
				return adndEnrolls;
			}));
		}
		if (controller.get('stdChe')) {
			controller.set('stdEnrolls', controller.get('stdChe.employeeLifeDisabilityEnrollments').then(function(stdEnrolls) {
				return stdEnrolls;
			}));
		}
		if (controller.get('ltdChe')) {
			controller.set('ltdEnrolls', controller.get('ltdChe.employeeLifeDisabilityEnrollments').then(function(ltdEnrolls) {
				return ltdEnrolls;
			}));
		}
	}
});

App.LifeController = Ember.ObjectController.extend({
	productNavElements: function() {
		var listOfNavElements = [];
		if (this.get('lifeChe') || this.get('adndChe')) {
			listOfNavElements.push({
				label: 'Life and AD&D Details',
				routeName: 'life.overview',
			});
		}
		if (this.get('stdChe') || this.get('ltdChe')) {
			listOfNavElements.push({
				label: 'Disability Overview',
				routeName: 'disability.overview',
			});
		}
		if (this.get('hasLtdPlans')) {
			listOfNavElements.push({
				label: 'LTD Plan Details',
				routeName: 'life.ltddetails',
			});
		}
		if (this.get('hasStdPlans')) {
			listOfNavElements.push({
				label: 'STD Plan Details',
				routeName: 'life.stddetails',
			});
		}
		if (!this.get('isReadOnly')) {
			listOfNavElements.push({
				label: 'Change Policy',
				routeName: 'life.change',
			});
			listOfNavElements.push({
				label: 'Cancel Policy',
				routeName: 'life.cancel',
			});
		}
		if (this.get('hasAnySpd')) {
			listOfNavElements.push({
				label: 'Summary Plan Description',
				routeName: 'life.spds',
			});
		}
		return listOfNavElements;
	}.property('hasLtdPlans', 'hasStdPlans', 'isReadOnly', 'hasAnySpd'),
	stdSpds: function(){
		if (this.get('stdPromises') === undefined) { return; }
		var stdPlans = this.get('stdPromises');
		if (stdPlans === undefined) {
			return;
		}
		var spds = [];
		for (var i = 0; i < stdPlans.length; i++) {
			if (stdPlans[i].get('summaryPlanDescriptionDocument')!==null) {
				spds.push(stdPlans[i]);
			}
		}
		if (spds.length > 0) {
			return spds;
		}
	}.property('stdPromises'),
	ltdSpds: function(){
		if (this.get('ltdPromises') === undefined) { return; }
		var ltdPlans = this.get('ltdPromises');
		if (ltdPlans === undefined) {
			return;
		}
		var spds = [];
		for (var i = 0; i < ltdPlans.length; i++) {
			if (ltdPlans[i].get('summaryPlanDescriptionDocument')!==null) {
				spds.push(ltdPlans[i]);
			}
		}
		if (spds.length > 0) {
			return spds;
		}
	}.property('ltdPromises'),

	hasStdSpd : Ember.computed.gt('stdSpds.length', 0),
	hasLtdSpd : Ember.computed.gt('ltdSpds.length', 0),
	hasAnySpd: function(){
		return this.get('hasStdSpd')||this.get('hasLtdSpd');
	}.property('hasStdSpd','hasLtdSpd'),
});

App.DisabilityRoute = App.LifeRoute.extend();

App.DisabilityController = App.LifeController.extend();

App.LifeSpdsRoute = App.LifeRoute.extend();

App.LifeSpdsController = App.LifeController.extend();

App.FriendlyLifeDisabilityStatusHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var value = params[0];
	if (value == _LDEAbstractModel.prototype.COMPLETE) {
		return new Ember.Handlebars.SafeString('Pending Approval');
	}
	else if (value == _LDEAbstractModel.prototype.APPROVED) {
		return value.capitalize();
	}
	else if (value == _LDEAbstractModel.prototype.BEGIN) {
		return new Ember.Handlebars.SafeString('In Progress');
	}
	else if (value == _LDEAbstractModel.prototype.DECLINE) {
		return new Ember.Handlebars.SafeString('Declined');
	}

	else if (!value) {
		return new Ember.Handlebars.SafeString('Yet To Begin Enrollment');
	}
	return value;
});

App.SumPropertyHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var objs = params[0],
		key = params[1];
	var sum = 0.0;
	objs.forEach(function(obj) {
		sum += Number(obj[key]);
	});
	return sum;
});

App.GetChildPlansHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var delps = params[0];
	var childPlans = [];
	delps.forEach(function(delp) {
		if(delp.get('enrollment') && delp.get('enrollment').get('dependent.type') === 'Child') {
			childPlans.pushObject(delp);
		}
	});
	return childPlans;
});

App.GetNotChildPlansHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var delps = params[0];
	var childPlans = [];
	delps.forEach(function(delp) {
		if(delp.get('enrollment') && delp.get('enrollment').get('dependent.type') != 'Child') {
			childPlans.pushObject(delp);
		}
	});
	return childPlans;
});

App.HasChildTieredHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var delps = params[0];
	var hasChildTiered = false;
	delps.forEach(function(delp) {
		if(delp.get('restriction.rateType') === 'child_tiered') {
			hasChildTiered = true;
		}

		if(delp.get('plan') && delp.get('plan').get('chRestriction.rateType') === 'child_tiered') {
			hasChildTiered = true;
		}
	});
	return hasChildTiered;
});

// For enrollment finish page
App.HasChildTieredFromPlanHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	// Plan, not Dependent plan
	var plans = params[0];
	var hasChildTiered = false;
	plans.forEach(function(plan) {
		if(plan.get('chRestriction.rateType') === 'child_tiered') {
			hasChildTiered = true;
		}
	});
	return hasChildTiered;
});

App.SumEnrolleePremiumHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var enrollees = params[0];
	var premium = 0.0;
	enrollees.forEach(function(enrollee) {
		premium += Number(enrollee.premium);
	});
	return premium;
});

App.GetChildEnrolleesHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var enrollees = params[0];
	var children = [];
	enrollees.forEach(function(enrollee) {
		if(enrollee.dependentType === 'Child') {
			children.pushObject(enrollee);
		}
	});
	return children;
});

App.GetNotChildEnrolleesHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var enrollees = params[0];
	var children = [];
	enrollees.forEach(function(enrollee) {
		if(enrollee.dependentType != 'Child') {
			children.pushObject(enrollee);
		}
	});
	return children;
});

App._LifeDisabilityController = Ember.ObjectController.extend({
	viewLifeTotal: false,
	lifeStats: {},
	adndStats: {},
	stdStats: {},
	ltdStats: {},
	seeLifeEstimate: function() {
		this.set('viewLifeTotal', true);
	},
	lifeEmployeeLdes: function() {
		return this.get('lifeChe.employeeLifeDisabilityEnrollments');
	}.property('lifeChe'),
	adndEmployeeLdes: function() {
		return this.get('adndChe.employeeLifeDisabilityEnrollments');
	}.property('adndChe'),
	stdEmployeeLdes: function() {
		return this.get('stdChe.employeeLifeDisabilityEnrollments');
	}.property('stdChe'),
	ltdEmployeeLdes: function() {
		return this.get('ltdChe.employeeLifeDisabilityEnrollments');
	}.property('ltdChe'),
	shouldShowPreviousLifeSummary: function() {
		return this.get('lifeChe.previousEnrollment') && this.get('lifeChe.enrollmentType') == 'SW' && this.get('lifeChe.companyHealthCarrier.status') != 'approved';
	}.property('lifeChe'),
	shouldShowPreviousAdndSummary: function() {
		return this.get('adndChe.previousEnrollment') && this.get('adndChe.enrollmentType') == 'SW' && this.get('adndChe.companyHealthCarrier.status') != 'approved';
	}.property('adndChe'),
	shouldShowPreviousStdSummary: function() {
		return this.get('stdChe.previousEnrollment') && this.get('stdChe.enrollmentType') == 'SW' && this.get('stdChe.companyHealthCarrier.status') != 'approved';
	}.property('stdChe'),
	shouldShowPreviousLtdSummary: function() {
		return this.get('ltdChe.previousEnrollment') && this.get('ltdChe.enrollmentType') == 'SW' && this.get('ltdChe.companyHealthCarrier.status') != 'approved';
	}.property('ltdChe'),
	lifeStatsUpdate: function() {
		if (this.get('lifeChe') && this.get('lifeEnrolls')) {
			var _this = this;
			this.get('lifeEnrolls').then(function(lifeEnrolls) {
				var lifeTotal = 0, lifeCompleted = 0;
				lifeEnrolls.forEach(function(lifeEnroll) {
					if (lifeEnroll.get('employee.isEligibleForLifeDisability')) {
						lifeTotal++;
						if (lifeEnroll.get('status') == _LDEAbstractModel.prototype.COMPLETE || lifeEnroll.get('status') == _LDEAbstractModel.prototype.APPROVED || lifeEnroll.get('status') == _LDEAbstractModel.prototype.DECLINE) {
							lifeCompleted++;
						}
					}
				});
				_this.set('lifeStats', {
					'total': lifeTotal,
					'completed': lifeCompleted,
				});
			});
		}
	}.observes('lifeChe', 'lifeEnrolls'),

	adndStatsUpdate: function() {
		if (this.get('adndChe') && this.get('adndEnrolls')) {
			var _this = this;
			this.get('adndEnrolls').then(function(adndEnrolls) {
				var adndTotal = 0, adndCompleted = 0;
				adndEnrolls.forEach(function(adndEnroll) {
					if (adndEnroll.get('employee.isEligibleForLifeDisability')) {
						adndTotal++;
						if (adndEnroll.get('status') == _LDEAbstractModel.prototype.COMPLETE || adndEnroll.get('status') == _LDEAbstractModel.prototype.APPROVED || adndEnroll.get('status') == _LDEAbstractModel.prototype.DECLINE) {
							adndCompleted++;
						}
					}
				});
				_this.set('adndStats', {
					'total': adndTotal,
					'completed': adndCompleted,
				});
			});
		}
	}.observes('adndChe', 'adndEnrolls'),

	stdStatsUpdate: function() {
		if (this.get('stdChe') && this.get('stdEnrolls')) {
			var _this = this;
			this.get('stdEnrolls').then(function(stdEnrolls) {
				var stdTotal = 0, stdCompleted = 0;
				stdEnrolls.forEach(function(stdEnroll) {
					if (stdEnroll.get('employee.isEligibleForLifeDisability')) {
						stdTotal++;
						if (stdEnroll.get('status') == _LDEAbstractModel.prototype.COMPLETE || stdEnroll.get('status') == _LDEAbstractModel.prototype.APPROVED || stdEnroll.get('status') == _LDEAbstractModel.prototype.DECLINE) {
							stdCompleted++;
						}
					}
				});
				_this.set('stdStats', {
					'total': stdTotal,
					'completed': stdCompleted,
				});
			});
		}
	}.observes('stdChe', 'stdEnrolls'),

	ltdStatsUpdate: function() {
		if (this.get('ltdChe') && this.get('ltdEnrolls')) {
			var _this = this;
			this.get('ltdEnrolls').then(function(ltdEnrolls) {
				var ltdTotal = 0, ltdCompleted = 0;
				ltdEnrolls.forEach(function(ltdEnroll) {
					if (ltdEnroll.get('employee.isEligibleForLifeDisability')) {
						ltdTotal++;
						if (ltdEnroll.get('status') == _LDEAbstractModel.prototype.COMPLETE || ltdEnroll.get('status') == _LDEAbstractModel.prototype.APPROVED || ltdEnroll.get('status') == _LDEAbstractModel.prototype.DECLINE) {
							ltdCompleted++;
						}
					}
				});
				_this.set('ltdStats', {
					'total': ltdTotal,
					'completed': ltdCompleted,
				});
			});
		}
	}.observes('ltdChe', 'ltdEnrolls'),

});

App.LifeLifedetailsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function(params) {
		return this.all(dashboard('company'), {
			'employees': dashboard('employees'),
			'isReadOnly': dashboard('isReadOnly')
		});
	},
	afterModel: function(model) {
		thenpath(model, 'lifePlan');
		thenpath(model, 'stdPlan');
		thenpath(model, 'ltdPlan');
	}
});
App.LifeLifedetailsController = Ember.ObjectController.extend();

App.LifeLtddetailsRoute = App.LifeLifedetailsRoute.extend();
App.LifeLtddetailsController = Ember.ObjectController.extend({
	viewLtdGlossary: function() {
		var ltdPlan = this.get('ltdPlan.plan');
		this.send("showModal", "life.ltddetailspop", ltdPlan);
	},
	viewLTDTotal: false,
	seeLTDEstimate: function() {
		this.set('viewLTDTotal', true);
	},
});
App.LifeLtddetailspopController = Ember.ObjectController.extend();

App.LifeStddetailsRoute = App.LifeLifedetailsRoute.extend();
App.LifeStddetailsController = Ember.ObjectController.extend({
	viewSTDTotal: false,
	seeSTDEstimate: function() {
		this.set('viewSTDTotal', true);
	},
});

App.VisionsettingsRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function(params) {
		// TODO
	}
});

App.DentalsettingsRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function(params) {
		// TODO
	}
});

App.PlansreviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var companyPlansPromise = zen.companyEnrollmentReviewApi('companyMedicalPlans');
		var medicalPlansPromise = companyPlansPromise.then(function(plans) {
			return Ember.RSVP.all(plans.map(function(plan) {
				return Ember.RSVP.hash({
					plan: plan.get('plan'),
				});
			}));
		});

		return this.all(App.CPlan.find(), {
			'company': dashboard('company'),
			'medicalPlans': medicalPlansPromise,
			'companyEnrollmentReviewApi': zen.companyEnrollmentReviewApi(''),
			'companyMedicalEnrollment': zen.companyEnrollmentReviewApi('companyMedicalEnrollment'),
			'companyMedicalCarrier': zen.companyEnrollmentReviewApi('companyMedicalCarrier'),
			'companyMedicalHealthPlans': zen.companyEnrollmentReviewApi('companyMedicalHealthPlans'),
			'companyMedicalPlans' : companyPlansPromise,
			'companyDentalEnrollment': zen.companyEnrollmentReviewApi('companyDentalEnrollment'),
			'companyVisionEnrollment': zen.companyEnrollmentReviewApi('companyVisionEnrollment')
			});
	},

	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('selectedPriceLevel', 'avgYouPremium');
		controller.set('priceLevel', 'avgYouPremium');
		controller.set('displayRecommendedPlans', true);

		controller.set('prevMedicalCarrier', controller.get('companyMedicalCarrier.carrier'));
		var prevMedicalPlans = Ember.A();
		controller.get('companyMedicalPlans').forEach(function(medicalPlan) {
			prevMedicalPlans.pushObject(medicalPlan);
		});
		controller.set('prevMedicalPlans', prevMedicalPlans);

		/* Set up transition specific variables */
		var transitionToDental = (controller.get('companyDentalEnrollment') &&
			(controller.get('companyDentalEnrollment.enrollmentStatus') == controller.get('companyMedicalEnrollment.enrollmentStatus')) &&
			(controller.get('company.medicalReviewing') == controller.get('company.dentalReviewing')));

		var transitionToVision = (controller.get('companyVisionEnrollment') &&
			(controller.get('companyVisionEnrollment.enrollmentStatus') == controller.get('companyMedicalEnrollment.enrollmentStatus')) &&
			(controller.get('company.medicalReviewing') == controller.get('company.visionReviewing')));

		controller.set('transitionToDental', transitionToDental);
		controller.set('transitionToVision', transitionToVision);
	},

	renderTemplate: function(controller, model) {
		this.controllerFor('plan/overview').set('selectorController', controller); // TODO(ed): planselectorcontroller -> plansreviewcontroller
		this.controllerFor('plan/added').set('selectorController', controller); // TODO(ed): planselectorcontroller -> plansreviewcontroller
		this.controllerFor('plan/addconfirm').set('selectorController', controller); // TODO(ed): planselectorcontroller -> plansreviewcontroller
		this.controllerFor('plan/seechange').set('selectorController', controller); // TODO(ed): planselectorcontroller -> plansreviewcontroller
		this.controllerFor('review/dialog').set('reviewController', controller); // TODO(ed): set needs with plansreviewcontroller
		this.render('planselector', {controller: 'plansreview'});
	},
});


App.DentalplansreviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var companyPlansPromise = zen.companyEnrollmentReviewApi('companyDentalPlans');
		var dentalPlansPromise = companyPlansPromise.then(function(plans) {
			return Ember.RSVP.all(plans.map(function(plan) {
				return Ember.RSVP.hash({
					plan: plan.get('plan'),
				});
			}));
		});
		return this.all(App.CDentalPlan.find(), {
			'company': dashboard('company'),
			'dentalPlans': dentalPlansPromise,
			'companyEnrollmentReviewApi': zen.companyEnrollmentReviewApi(''),
			'companyDentalEnrollment': zen.companyEnrollmentReviewApi('companyDentalEnrollment'),
			'companyDentalCarrier': zen.companyEnrollmentReviewApi('companyDentalCarrier'),
			'companyDentalHealthPlans': zen.companyEnrollmentReviewApi('companyDentalHealthPlans'),
			'companyDentalPlans' : companyPlansPromise,
			'companyMedicalEnrollment': zen.companyEnrollmentReviewApi('companyMedicalEnrollment'),
			'companyVisionEnrollment': zen.companyEnrollmentReviewApi('companyVisionEnrollment')
			});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('prevDentalCarrier', controller.get('companyDentalCarrier.carrier'));
		var prevDentalPlans = Ember.A();
		controller.get('companyDentalPlans').forEach(function(dentalPlan) {
			prevDentalPlans.pushObject(dentalPlan);
		});
		controller.set('prevDentalPlans', prevDentalPlans);

		/* Set up transition specific variables */
		var transitionToMedical = (controller.get('companyMedicalEnrollment') &&
			(controller.get('companyMedicalEnrollment.enrollmentStatus') == controller.get('companyDentalEnrollment.enrollmentStatus')) &&
			(controller.get('company.dentalReviewing') == controller.get('company.medicalReviewing')));

		var transitionToVision = (controller.get('companyVisionEnrollment') &&
			(controller.get('companyVisionEnrollment.enrollmentStatus') == controller.get('companyDentalEnrollment.enrollmentStatus')) &&
			(controller.get('company.dentalReviewing') == controller.get('company.visionReviewing')));

		controller.set('transitionToMedical', transitionToMedical);
		controller.set('transitionToVision', transitionToVision);
	},

	renderTemplate: function(controller, model) {
		this.controllerFor('dentalplanoverview').set('selectorController', controller); // TODO(ed): dentalplanselectorcontroller -> dentalplansreviewcontroller
		this.controllerFor('dentalplanselector/addconfirm').set('selectorController', controller); // TODO(ed): dentalplanselectorcontroller -> dentalplansreviewcontroller
		this.controllerFor('review/dialog').set('reviewController', controller); // TODO(ed): set needs with dentalplansreviewcontroller CONFLICT. so just leave as a property
		this.render('dentalplanselector', {controller: 'dentalplansreview'});
	},
});


App.VisionplansreviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var companyPlansPromise = zen.companyEnrollmentReviewApi('companyVisionPlans');
		var visionPlansPromise = companyPlansPromise.then(function(plans) {
			return Ember.RSVP.all(plans.map(function(plan) {
				return Ember.RSVP.hash({
					plan: plan.get('plan'),
				});
			}));
		});

		return this.all(App.CVisionPlan.find(), {
			'company': dashboard('company'),
			'visionPlans': visionPlansPromise,
			'companyEnrollmentReviewApi': zen.companyEnrollmentReviewApi(''),
			'companyVisionEnrollment': zen.companyEnrollmentReviewApi('companyVisionEnrollment'),
			'companyVisionCarrier': zen.companyEnrollmentReviewApi('companyVisionCarrier'),
			'companyVisionHealthPlans': zen.companyEnrollmentReviewApi('companyVisionHealthPlans'),
			'companyVisionPlans' : companyPlansPromise,
			'companyMedicalEnrollment': zen.companyEnrollmentReviewApi('companyMedicalEnrollment'),
			'companyDentalEnrollment': zen.companyEnrollmentReviewApi('companyDentalEnrollment'),
			});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('prevVisionCarrier', controller.get('companyVisionCarrier.carrier'));
		var prevVisionPlans = Ember.A();
		controller.get('companyVisionPlans').forEach(function(visionPlan) {
			prevVisionPlans.pushObject(visionPlan);
		});
		controller.set('prevVisionPlans', prevVisionPlans);

		/* Set up transition specific variables */
		var transitionToMedical = (controller.get('companyMedicalEnrollment') &&
			(controller.get('companyMedicalEnrollment.enrollmentStatus') == controller.get('companyVisionEnrollment.enrollmentStatus')) &&
			(controller.get('company.visionReviewing') == controller.get('company.medicalReviewing')));

		var transitionToDental = (controller.get('companyDentalEnrollment') &&
			(controller.get('companyDentalEnrollment.enrollmentStatus') == controller.get('companyVisionEnrollment.enrollmentStatus')) &&
			(controller.get('company.visionReviewing') == controller.get('company.dentalReviewing')));

		controller.set('transitionToMedical', transitionToMedical);
		controller.set('transitionToDental', transitionToDental);
	},

	renderTemplate: function(controller, model) {
		this.controllerFor('visionplanoverview').set('selectorController', controller); // TODO(ed): visionplanselectorcontroller -> visionplansreviewcontroller
		this.controllerFor('visionplanselector/addconfirm').set('selectorController', controller); // TODO(ed): visionplanselectorcontroller -> visionplansreviewcontroller
		this.controllerFor('review/dialog').set('reviewController', controller); // TODO(ed): set needs with visionplansreviewcontroller. CONFLICT, just leave as a property
		this.render('visionplanselector', {controller: controller});
	},
});

App.QuotedependentsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	// TODO(yfang): remove this route after turn switch on 100%
	beforeModel: function() {
		if (App.switches.isActive('new_nge_census')) {
			this.transitionTo('quoteDependentsV2');
		}
	},

	model: function() {
		return this.all(dashboard('employees'), {
			company: dashboard('company'),
			companyMedicalPlans: zen.companyEnrollmentApi('companyMedicalPlans')
		});
	},
	afterModel: function(allEmployees) {
		return this.all(allEmployees, {
			"quoteDependents": App.QuoteDependent.find(),
		});
	}
});

App.QuotedependentsController = zen._TransactionArraySavingController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	// TODO(yfang): remove this controller after turn switch on 100%

	zipCodeErrorReason: "Invalid zip code",
	nextRoute: 'planselector',

	eligibleEmployees: Ember.computed.filterByProperty('model', 'isEligibleForHealth'),
	addQuoteDependent: function(employee) {
		var quoteDependents = employee.get('quoteDependents');
		var newQD = App.QuoteDependent.createRecord();
		newQD.set('employee', employee);
		quoteDependents.pushObject(newQD);
	},
	deleteQuoteDependent: function(qDependent) {
		var employee = qDependent.get('employee');
		var quoteDependents = employee.get('quoteDependents');
		quoteDependents.removeObject(qDependent);
		qDependent.deleteRecord();
	},
	fieldValidations: function () {
		var errorText = this.some(function(employee) {
			if (!employee.get('isActive')) {
				return false;
			}

			if (employee.get('isEligibleForHealth') && (!employee.get('ageRange') || !employee.get('zip'))) {
				return 'All fields are required. Please fill in all the missing fields.';
			}

			var qDependents = employee.get('quoteDependents');
			var hasSpouseOrDP = false;
			return qDependents.some(function(item) {
				var type = item.get('type');
				var age = item.get('age');
				var zipCode = item.get('zipCode');
				if (!type || !age || !zipCode) {
					return 'All fields are required. Please fill in all the missing fields.';
				}

				if (zipCode.length != 5) {
					return 'Zip code must be 5 digits.';
				}

				// Check more than one Spouse and Domestic Partner
				if (type === 'Spouse' || type === 'Domestic Partner') {
					if (hasSpouseOrDP) {
						return 'An employee can only have one spouse or domestic partner.';
					}
					hasSpouseOrDP = true;
				}

				return false;
			});
		});

		this.set('errorText', errorText || '');
		return !errorText;
	},

	actions: {
		continue: function() {
			this.set('nextRoute', 'initialEnrollment');
			var company = this.get('company');
			var companyMedicalPlans = this.get('companyMedicalPlans');
			var objectsToReload = [company];
			companyMedicalPlans.forEach(function(companyMedicalPlan) {
				objectsToReload.push(companyMedicalPlan);
			});
			return this.save(objectsToReload);
		},
	},

	cancel: function () {
		// Rollback any unsaved changes, then go back
		this.forEach(function (employee) {
			employee.get('transaction').rollback();
		});
		return this.transitionToRoute('initialEnrollment');
	},
});


/*
 * Add existing / make zenefits your broker
 */

zen._AddexistingRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function(params) {
		return this.all(zen.companyEnrollmentBorApi(''), {
				'company': dashboard('company'),
				'medicalCarriers' : zen.stateCarrierApi('medicalCarriers'),
				'dentalCarriers' : zen.stateCarrierApi('dentalCarriers'),
				'visionCarriers' : zen.stateCarrierApi('visionCarriers'),
				'lifeCarriers' : zen.stateCarrierApi('lifeCarriers'),
				'disabilityCarriers' : zen.stateCarrierApi('disabilityCarriers'),
				'borMedicalEnrollment': zen.companyEnrollmentBorApi('borMedicalEnrollment'),
				'borDentalEnrollment': zen.companyEnrollmentBorApi('borDentalEnrollment'),
				'borVisionEnrollment': zen.companyEnrollmentBorApi('borVisionEnrollment'),
				'borAdditionalMedicalEnrollment': zen.companyEnrollmentBorApi('borAdditionalMedicalEnrollment'),
				'borLifeEnrollment': zen.companyEnrollmentBorApi('borLifeEnrollment'),
				'borStDisabilityEnrollment': zen.companyEnrollmentBorApi('borStDisabilityEnrollment'),
				'borLtDisabilityEnrollment': zen.companyEnrollmentBorApi('borLtDisabilityEnrollment'),
				'borMedicalHealthCarrier': zen.companyEnrollmentBorApi('borMedicalHealthCarrier'),
				'borDentalHealthCarrier': zen.companyEnrollmentBorApi('borDentalHealthCarrier'),
				'borVisionHealthCarrier': zen.companyEnrollmentBorApi('borVisionHealthCarrier'),
				'borAdditionalMedicalHealthCarrier': zen.companyEnrollmentBorApi('borAdditionalMedicalHealthCarrier'),
				'borLifeHealthCarrier': zen.companyEnrollmentBorApi('borLifeHealthCarrier'),
				'borStDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borStDisabilityHealthCarrier'),
				'borLtDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borLtDisabilityHealthCarrier'),
				'companyMedicalEnrollments': zen.companyEnrollmentSettingsApi('companyMedicalEnrollments'),
				'companyDentalEnrollments': zen.companyEnrollmentSettingsApi('companyDentalEnrollments'),
				'companyVisionEnrollments': zen.companyEnrollmentSettingsApi('companyVisionEnrollments'),
				'companyBusinessCarriersByLineOfCoverages' : App.CompanyBusinessCarriersByLineOfCoverage.find(),
				'borBusinessInsuranceEnrollments': zen.businessInsuranceEnrollmentBorApi('borBusinessInsuranceEnrollments'),
				'borBusinessInsuranceCarriers': zen.businessInsuranceEnrollmentBorApi('borBusinessInsuranceCarriers'),
				'agents': Ember.ajaxGet('/custom_api/get_bor_agents').then(function(agents) {
						try {
							return JSON.parse(agents);
						} catch(e) {
							return agents;
						}
					}),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('errorText', "");
		controller.set('showBICopy', false);
		var company = controller.get('company');
		var partner = company.get('partner');
		if (partner) {
			partner.then(function(partner) {
				controller.set('partner', partner);
			});
		}
	}
});



zen._BoRPostSignModelMixin = Ember.Mixin.create({
	model: function(params) {
		return this.all(dashboard('company'), {
			'companyEnrollmentBorApi': zen.companyEnrollmentBorApi(''),
			'medicalCarriers' : zen.stateCarrierApi('medicalCarriers'),
			'dentalCarriers' : zen.stateCarrierApi('dentalCarriers'),
			'visionCarriers' : zen.stateCarrierApi('visionCarriers'),
			'lifeCarriers' : zen.stateCarrierApi('lifeCarriers'),
			'disabilityCarriers' : zen.stateCarrierApi('disabilityCarriers'),
			'borMedicalEnrollment': zen.companyEnrollmentBorApi('borMedicalEnrollment'),
			'borPreviousMedicalEnrollment': zen.companyEnrollmentBorApi('borPreviousMedicalEnrollment'),
			'borDentalEnrollment': zen.companyEnrollmentBorApi('borDentalEnrollment'),
			'borPreviousDentalEnrollment': zen.companyEnrollmentBorApi('borPreviousDentalEnrollment'),
			'borVisionEnrollment': zen.companyEnrollmentBorApi('borVisionEnrollment'),
			'borPreviousVisionEnrollment': zen.companyEnrollmentBorApi('borPreviousVisionEnrollment'),
			'borAdditionalMedicalEnrollment': zen.companyEnrollmentBorApi('borAdditionalMedicalEnrollment'),
			'borPreviousAdditionalMedicalEnrollment': zen.companyEnrollmentBorApi('borPreviousAdditionalMedicalEnrollment'),
			'borLifeEnrollment': zen.companyEnrollmentBorApi('borLifeEnrollment'),
			'borStDisabilityEnrollment': zen.companyEnrollmentBorApi('borStDisabilityEnrollment'),
			'borLtDisabilityEnrollment': zen.companyEnrollmentBorApi('borLtDisabilityEnrollment'),
			'borMedicalHealthCarrier': zen.companyEnrollmentBorApi('borMedicalHealthCarrier'),
			'borPreviousMedicalHealthCarrier': zen.companyEnrollmentBorApi('borPreviousMedicalHealthCarrier'),
			'borDentalHealthCarrier': zen.companyEnrollmentBorApi('borDentalHealthCarrier'),
			'borPreviousDentalHealthCarrier': zen.companyEnrollmentBorApi('borPreviousDentalHealthCarrier'),
			'borVisionHealthCarrier': zen.companyEnrollmentBorApi('borVisionHealthCarrier'),
			'borPreviousVisionHealthCarrier': zen.companyEnrollmentBorApi('borPreviousVisionHealthCarrier'),
			'borAdditionalMedicalHealthCarrier': zen.companyEnrollmentBorApi('borAdditionalMedicalHealthCarrier'),
			'borPreviousAdditionalMedicalHealthCarrier': zen.companyEnrollmentBorApi('borPreviousAdditionalMedicalHealthCarrier'),
			'borLifeHealthCarrier': zen.companyEnrollmentBorApi('borLifeHealthCarrier'),
			'borStDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borStDisabilityHealthCarrier'),
			'borLtDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borLtDisabilityHealthCarrier'),
			'companyMedicalEnrollments': zen.companyEnrollmentSettingsApi('companyMedicalEnrollments'),
			'companyDentalEnrollments': zen.companyEnrollmentSettingsApi('companyDentalEnrollments'),
			'companyVisionEnrollments': zen.companyEnrollmentSettingsApi('companyVisionEnrollments'),
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'borCompanyNav': App.BorCompanyNav.find('me'),
			'scoeAdminSignOff': App.ScoeAdminSignOff.find('me'),
			'policy' : App.CompanyPolicy.find('me'),
			'user': dashboard('user'),
			// CHC and CHE properties.
			'borHealthCarrierProperties': ['borMedicalHealthCarrier', 'borAdditionalMedicalHealthCarrier', 'borDentalHealthCarrier', 'borVisionHealthCarrier', 'borLifeHealthCarrier', 'borStDisabilityHealthCarrier', 'borLtDisabilityHealthCarrier'],
			'borPreviousHealthCarrierProperties': ['borPreviousMedicalHealthCarrier', 'borPreviousAdditionalMedicalHealthCarrier', 'borPreviousDentalHealthCarrier', 'borPreviousVisionHealthCarrier'],
			'borHealthEnrollmentProperties': ['borMedicalEnrollment', 'borAdditionalMedicalEnrollment', 'borDentalEnrollment', 'borVisionEnrollment', 'borLifeEnrollment', 'borStDisabilityEnrollment', 'borLtDisabilityEnrollment'],
			'borPreviousHealthEnrollmentProperties': ['borPreviousMedicalEnrollment', 'borPreviousAdditionalMedicalEnrollment', 'borPreviousDentalEnrollment', 'borPreviousVisionEnrollment'],
			'stateCarrierProperties': ['medicalCarriers', 'dentalCarriers', 'visionCarriers', 'lifeCarriers', 'disabilityCarriers'],
        });
    },
});


// Its controllers should use the zen._BoRGamificationControllerUtils
// Children should set the 'rawRouteName' in controller before calling
// setupController's super method. The 1.0 version of ember doesn't support
// routeName in setupController, so for now, we have to live with that.
// TODO: For Ember 1.7 and higher, please change the code to use this.routeName
// and remove the 'rawRouteName' steps altogether.
zen._BoRPostSignChildRoute = Ember.Route.extend(zen._BoRPostSignModelMixin, zen._MultiModelMixin, zen._AuthAdminMixin, {
	parentController: "setup",
	setupController: function(controller, model) {
		this._super(controller, model);
		// rawRouteName will be passed by child routes before initializing
		// the parent controller.
		controller.set('thisRouteName', controller.get('rawRouteName'));
		this.setAppropriateRouteVariables(controller);

		var scoeAdminSignOff = controller.get('scoeAdminSignOff');
		controller.get('borHealthCarrierProperties').forEach(function(healthCarrier) {
			var healthCarrier = controller.get(healthCarrier);
			if (healthCarrier) {
				healthCarrier.set('freezeScoeSignOff', healthCarrier.get('companyHealthEnrollment.enrollmentType') == 'OE' && scoeAdminSignOff.get('freezeSignOff'));
				healthCarrier.set('invalidateSCOEAdminSignOnChange', healthCarrier.get('companyHealthEnrollment.enrollmentType') == 'OE' && scoeAdminSignOff.get('isComplete'));
			}
		});
	},
	setAppropriateRouteVariables: function(controller, dotSeparatedEmberRouteName) {
		// A brave attempt to calculate route name in a failure. Hence the below line commented.
		// controller.calcNsetThisRouteName(dotSeparatedEmberRouteName, controller);

		// Parent(this case--setup route will use currentChildRouteName to dynamically generate the checklist.
		this.controllerFor(this.get('parentController')).set('currentChildRouteName', controller.get('thisRouteName'));
		this.controllerFor(this.get('parentController')).set('thisCardName', controller.get('cardForCurrentRoute'));
	},
});

// Common for the bor dashboard as well as bor routes.
// Any route which uses this should extend zen._BoRPostSignChildRoute.
// This will supply utility function. Its upto the callee to use observer or property.
zen._BoRGamificationUtils = Ember.Mixin.create({
	// As the name indicates, this will return the next route given the current route.
	// If the current route is the last route in the current BoR Gamification card, then it
	// will return BoR dashboard as next route. If there is no route of the given name, then it
	// will return dashboard. The borCardsToRouts should be the json object.

	isMandatoryStepsComplete: function(mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, enableMandatoryRoutes) {
		if(!enableMandatoryRoutes) {
			return true;
		}
		var mandatoryRoutes = Object.keys(mandatoryRoutesToMinStepsNeededObj);
		for (var i=0; i < mandatoryRoutes.length; i++) {
			var mandatoryRoute = mandatoryRoutes[i];
			var minStepsNeededInMandatoryRoute = parseInt(mandatoryRoutesToMinStepsNeededObj[mandatoryRoute]);
			var numStepsCompletedForTheRoute = this.numStepsCompletedForTheRoute(mandatoryRoute, borRoutesToStepsCompleted);
			if (numStepsCompletedForTheRoute < minStepsNeededInMandatoryRoute) {
				return false;
			}
		}
		return true;
	},

	nextOrPrevRoute: function(thisRouteName, borCardsToRoutes, mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, isNextRoute, enableMandatoryRoutes) {
		if (!thisRouteName) {
			return "";
		}
		var incrFactor = isNextRoute ? 1 : -1;

		var isMandatoryRoutesComplete = this.isMandatoryStepsComplete(mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, enableMandatoryRoutes);
		// If there are mandatory routes and the current route is not part of mandatory route, we need
		// to direct them to first unfinished mandatory route
		if(!mandatoryRoutesToMinStepsNeededObj[thisRouteName] && !isMandatoryRoutesComplete && thisRouteName == 'bordashboard') {
			var mandatoryRoutes = Object.keys(mandatoryRoutesToMinStepsNeededObj);
			for (var i=0; i < mandatoryRoutes.length; i++) {
				var minStepsNeededInMandatoryRoute = parseInt(mandatoryRoutesToMinStepsNeededObj[mandatoryRoutes[i]]);
				var numStepsCompletedForTheRoute = this.numStepsCompletedForTheRoute(mandatoryRoutes[i], borRoutesToStepsCompleted);
				if (numStepsCompletedForTheRoute < minStepsNeededInMandatoryRoute) {
					return mandatoryRoutes[i];
				}
			}
		}
		// If this route is part of mandatory route, then go to next/previous mandatory route in the path of mandatory routes.
		// Stay in the current route if
		if (mandatoryRoutesToMinStepsNeededObj[thisRouteName] && !isMandatoryRoutesComplete) {
			var mandatoryRoutes = Object.keys(mandatoryRoutesToMinStepsNeededObj);
			for (var i=0; i < mandatoryRoutes.length; i++) {
				if (mandatoryRoutes[i] == thisRouteName) {
					i += incrFactor;
					// If we are done with manadatory routes, then go to bor dashboard.
					if (i > mandatoryRoutes.length - 1 || i < 0) {
						return 'bordashboard';
					}
					return mandatoryRoutes[i];
				}
			}
		}
		var borCards = Object.keys(borCardsToRoutes);
		for (var i=0; i < borCards.length; i++) {
			var borCard = borCards[i];
			var routeNames = Object.keys(borCardsToRoutes[borCard]);
			for(var j=0; j < routeNames.length; j++) {
				if (routeNames[j] == thisRouteName) {
					j += incrFactor;
					// if the route we want to go is beyond reach, go to dashboard.
					if (j > routeNames.length - 1 || j < 0) {
						return 'bordashboard';
					}
					return routeNames[j];
				}
			}
		}
		return 'bordashboard';
	},
	nextEmberRouteForTheRoute: function(theRoute, borCardsToRoutes, borRouteNameToEmberRouteObj, mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, enableMandatoryRoutes) {
		var nextRouteName = this.nextOrPrevRoute(theRoute, borCardsToRoutes, mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, true, enableMandatoryRoutes);
		if (nextRouteName) {
			return borRouteNameToEmberRouteObj[nextRouteName];
		}
		return borRouteNameToEmberRouteObj['bordashboard'];
	},
	// same as nextEmberRouteForTheRoute but it will fetch the previous one. If the current
	// route is the first route of the card, then it will return dashboard as previous route.
	prevEmberRouteForTheRoute: function(theRoute, borCardsToRoutes, borRouteNameToEmberRouteObj, mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, enableMandatoryRoutes) {
		var prevRouteName = this.nextOrPrevRoute(theRoute, borCardsToRoutes, mandatoryRoutesToMinStepsNeededObj, borRoutesToStepsCompleted, false, enableMandatoryRoutes);
		if (prevRouteName) {
			return borRouteNameToEmberRouteObj[prevRouteName];
		}
		return borRouteNameToEmberRouteObj['bordashboard'];
	},
	// If the route is not present, it will return 0.
	numStepsCompletedForTheRoute: function(theRoute, borRoutesToStepsCompleted) {
		if (borRoutesToStepsCompleted[theRoute]) {
			return parseInt(borRoutesToStepsCompleted[theRoute]);
		}
		return 0;
	},
	maxStepsInTheRoute: function(theRoute, borCardsToRoutes) {
		var thisRouteName = theRoute;
		if (!thisRouteName) {
			return 0;
		}
		var borCards = Object.keys(borCardsToRoutes);
		for (var i=0; i < borCards.length; i++) {
			var routeNames = Object.keys(borCardsToRoutes[borCards[i]]);
			var routeArray = borCardsToRoutes[borCards[i]];
			for(var j=0; j < routeNames.length; j++) {
				if (routeNames[j] == thisRouteName) {
					return parseInt(routeArray[thisRouteName]);
				}
			}
		}
		return 0;
	},
	firstRouteNameInCard: function(cardName, borCardsToRoutes) {
		var routesInCard = borCardsToRoutes[cardName];
		if (!routesInCard || !Object.keys(routesInCard)) {
			return "bordashboard";
		}
		return Object.keys(routesInCard)[0];
	},
	dynamicRouteParams: function(routeName, routeNameToDynamicParamsObj) {
		var dynamicRouteParams = routeNameToDynamicParamsObj[routeName];
		return (dynamicRouteParams ? dynamicRouteParams : null);
	},
	getEmberRouteForRouteName: function(routeName, borRouteNameToEmberRouteObj) {
		var toRet = borRouteNameToEmberRouteObj[routeName];
		if (!toRet) {
			return borRouteNameToEmberRouteObj["bordashboard"];
		}
		return toRet;
	},
	// Given a card, this will make sure there are routes in the card. If there are none, this will return false.
	isValidCard: function(cardName, borCardsToRoutes) {
		if (!borCardsToRoutes[cardName] || !Object.keys(borCardsToRoutes[cardName]).length) {
			return false;
		}
		return true;
	},
	getCardForRoute: function(routeName, cardsToRoutes) {
		if (!cardsToRoutes) {
			return "";
		}
		var cards = Object.keys(cardsToRoutes);
		for(var i=0; i<cards.length; i++) {
			var card = cards[i];
			if (routeName in cardsToRoutes[card]) {
				return card;
			}
		}
		return "";
	},
	// Takes hypenized rotues and convert it into Title case string.
	verboseRouteOrCardName: function(routeOrCardName) {
		return routeOrCardName.split('-').map(function(word) {
			return word[0].toUpperCase() + word.substring(1);
		}).join(' ');
	},
	// Given something like census, this will give '#/setup/census'. First fetch the
	// census->setup.census from routeNameToEmberRouteObj then convert it back to the #/setup/census form.
	// This will also take into account the dynamic params if any.
	getHrefLink: function(routeName, routeNameToEmberRouteObj, routeNameToDynamicParamsObj) {
		var dynamicParams = routeNameToDynamicParamsObj[routeName];
		var toRet = '#/' + routeNameToEmberRouteObj[routeName].split(".").join("/");
		if (dynamicParams) {
			toRet += ('/' + dynamicParams);
		}
		return toRet;
	},
});

// These are the following constraint for using this mixin.
// This mixin is safe to use in/after the aferModel.
// 1) Every route should set the property "thisRouteName" and should be directly accessible by controller(like this.get('thisRouteName'), this=controller)
// 2) Every route should have the borCompanyNav model loaded in the model itself with exactly the same variable name.
zen._BoRGamificationControllerUtils = Ember.Mixin.create(zen._BoRGamificationUtils, {
	nextRouteName: function() {
		return this.nextOrPrevRoute(this.get('thisRouteName'), this.get('borCompanyNav.borCardsToRoutesObj'), this.get('borCompanyNav.mandatoryRoutesToMinStepsNeededObj'), this.get('borCompanyNav.borRoutesStatusesObj'), true, this.get('borCompanyNav.enableMandatoryRoutes'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.mandatoryRoutesToMinStepsNeededObj', 'borCompanyNav.borRoutesStatusesObj', 'borCompanyNav.enableMandatoryRoutes'),
	nextEmberRouteName: function() {
		return this.nextEmberRouteForTheRoute(this.get('thisRouteName'), this.get('borCompanyNav.borCardsToRoutesObj'), this.get('borCompanyNav.routeNameToEmberRouteObj'), this.get('borCompanyNav.mandatoryRoutesToMinStepsNeededObj'), this.get('borCompanyNav.borRoutesStatusesObj'), this.get('borCompanyNav.enableMandatoryRoutes'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.routeNameToEmberRouteObj', 'borCompanyNav.mandatoryRoutesToMinStepsNeededObj', 'borCompanyNav.borRoutesStatusesObj', 'borCompanyNav.enableMandatoryRoutes'),
	prevRouteName: function() {
		return this.nextOrPrevRoute(this.get('thisRouteName'), this.get('borCompanyNav.borCardsToRoutesObj'), this.get('borCompanyNav.mandatoryRoutesToMinStepsNeededObj'), this.get('borCompanyNav.borRoutesStatusesObj'), false, this.get('borCompanyNav.enableMandatoryRoutes'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.mandatoryRoutesToMinStepsNeededObj', 'borCompanyNav.borRoutesStatusesObj', 'borCompanyNav.enableMandatoryRoutes'),
	prevEmberRouteName: function() {
		return this.prevEmberRouteForTheRoute(this.get('thisRouteName'), this.get('borCompanyNav.borCardsToRoutesObj'), this.get('borCompanyNav.routeNameToEmberRouteObj'), this.get('borCompanyNav.mandatoryRoutesToMinStepsNeededObj'), this.get('borCompanyNav.borRoutesStatusesObj'), this.get('borCompanyNav.enableMandatoryRoutes'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.routeNameToEmberRouteObj', 'borCompanyNav.mandatoryRoutesToMinStepsNeededObj', 'borCompanyNav.borRoutesStatusesObj', 'borCompanyNav.enableMandatoryRoutes'),
	maxStepsInCurrentRoute: function() {
		var currentRoute = this.get('thisRouteName');
		return this.maxStepsInTheRoute(currentRoute, this.get('borCompanyNav.borCardsToRoutesObj'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj'),
	thisRouteComplete: function() {
		return this.get('numStepsCompleted') == this.get('maxStepsInCurrentRoute');
	}.property('thisRouteName', 'maxStepsInCurrentRoute', 'borCompanyNav.borRoutesStatusesObj'),
	// This will add/subtract the number of steps to the current route. If the thus added/subtracted
	// steps falls below 0 or goes above max steps the route is assigned to 0 and maxSteps will be
	// assigned respectively.
	stepsToAddInCurrentRoute: function(numStepsToAdd, shouldSubtract) {
		if (!numStepsToAdd) {
			numStepsToAdd = 1;
		}
		if (shouldSubtract) {
			numStepsToAdd = -numStepsToAdd;
		}
		var currentBoRRoutes = this.get('borCompanyNav.borRoutesStatusesObj');
		var curSteps = 0;
		if (currentBoRRoutes[this.get('thisRouteName')]) {
			curSteps = parseInt(currentBoRRoutes[this.get('thisRouteName')]);
		}
		curSteps += numStepsToAdd;
		if (curSteps < 0) {
			curSteps = 0;
		}
		else if (curSteps > this.get('maxStepsInCurrentRoute')) {
			curSteps = this.get('maxStepsInCurrentRoute');
		}
		currentBoRRoutes[this.get('thisRouteName')] = curSteps.toString();
		this.get('borCompanyNav').setCurrentBoRRouteStatus(currentBoRRoutes);
	},
	stepsCompletedInCurrentRoute: function(numStepsToAdd) {
		this.stepsToAddInCurrentRoute(numStepsToAdd);
	},
	stepsRevertedInCurrentRoute: function(numStepsToSubtract) {
		this.stepsToAddInCurrentRoute(numStepsToSubtract, true);
	},
	oneStepCompletedInCurrentRoute: function() {
		this.stepsCompletedInCurrentRoute(1);
	},
	oneStepRevertedInCurrentRoute: function() {
		this.stepsRevertedInCurrentRoute(1);
	},
	numStepsCompleted: function() {
		return this.numStepsCompletedForTheRoute(this.get('thisRouteName'), this.get('borCompanyNav.borRoutesStatusesObj'));
	}.property('thisRouteName', 'borCompanyNav.borRoutesStatusesObj'),
	cardForCurrentRoute: function() {
		return this.getCardForRoute(this.get('thisRouteName'), this.get('borCompanyNav.borCardsToRoutesObj'));
	}.property('thisRouteName', 'borCompanyNav.borCardsToRoutesObj'),
	emberRouteToBoRRouteName: function() {
		var toRet = {};
		var borRouteNameToEmberRoute = this.get('borCompanyNav.routeNameToEmberRouteObj');
		var keys = Object.keys(borRouteNameToEmberRoute);
		for(var i=0;i<keys.length;i++) {
			toRet[borRouteNameToEmberRoute[keys[i]]] = keys[i];
		}
		return toRet;
	}.property('borCompanyNav.routeNameToEmberRouteObj'),
	// This was written at the aim of dynamically calculating the route name. Lots.
	// As of now, child sets route name themselves, so this function is a no-op as of now.
	calcNsetThisRouteName: function(dotSeparatedEmberRouteName, controller) {
		var routeName = controller.get('emberRouteToBoRRouteName')[dotSeparatedEmberRouteName];
		if (!routeName) {
			routeName = "bordashboard";
		}
		controller.set('thisRouteName', routeName);
	},
	setNumStepsCompletedForThisRoute: function(numStepsCompleted) {
		if (numStepsCompleted < 0) {
			numStepsCompleted = 0;
		}
		if (numStepsCompleted > this.get('maxStepsInCurrentRoute')) {
			numStepsCompleted = this.get('maxStepsInCurrentRoute');
		}
		var currentBoRRoutes = this.get('borCompanyNav.borRoutesStatusesObj');
		currentBoRRoutes[this.get('thisRouteName')] = numStepsCompleted.toString();
		var borCompanyNav = this.get('borCompanyNav');
		borCompanyNav.setCurrentBoRRouteStatus(currentBoRRoutes);
		return borCompanyNav;
	},
	goBack: function() {
		var dynamicParams = this.dynamicRouteParams(this.get('prevRouteName'), this.get('borCompanyNav.routeNameToDynamicParamsObj'));
		if (dynamicParams) {
			// HACK HACK. We cannot force the route to reload model and after model for dynamic
			// parameters. So, we have to explicitly do via window.location. For users,
			// window.location will still not invoke the setupController if the models we use
			// are the same. So, you have to do another hack hack probably.
			var prevEmberRouteName = this.get('prevEmberRouteName');
			//window.location = '#/' + prevEmberRouteName.split(".").join("/") + '/' + dynamicParams;
			this.transitionTo(prevEmberRouteName, dynamicParams);
		}
		else {
			this.transitionToRoute(this.get('prevEmberRouteName'));
		}
	},
	goNext: function() {
		var dynamicParams = this.dynamicRouteParams(this.get('nextRouteName'), this.get('borCompanyNav.routeNameToDynamicParamsObj'));
		if (dynamicParams) {
			// HACK HACK. We cannot force the route to reload model and after model for dynamic
			// parameters. So, we have to explicitly do via window.location. For users,
			// window.location will still not invoke the setupController if the models we use
			// are the same. So, you have to do another hack hack probably.
			var nextEmberRouteName = this.get('nextEmberRouteName');
			//window.location = '#/' + nextEmberRouteName.split(".").join("/") + '/' + dynamicParams;
			this.transitionTo(nextEmberRouteName, dynamicParams);
		}
		else {
			this.transitionToRoute(this.get('nextEmberRouteName'));
		}
	},
	goToBoRDashboard: function() {
		if (App.switches.isActive('bor.beta')) {
			this.eventLogger.log('bor_beta_flow', {action: 'finishlater', current_route: 'Unknown'});
		}
		this.set('borCompanyNav.enableMandatoryRoutes', false);
		this.transitionTo('bordashboard');
	},
	setNumStepsCompletedForRoute: function(routeName, numStepsCompleted) {
		if (numStepsCompleted < 0) {
			numStepsCompleted = 0;
		}
		var maxStepsInTheRoute = this.maxStepsInTheRoute(routeName, this.get('borCompanyNav.borCardsToRoutesObj'));
		if (numStepsCompleted > maxStepsInTheRoute) {
			numStepsCompleted = maxStepsInTheRoute;
		}
		var currentBoRRoutes = this.get('borCompanyNav.borRoutesStatusesObj');
		currentBoRRoutes[routeName] = numStepsCompleted.toString();
		var borCompanyNav = this.get('borCompanyNav');
		borCompanyNav.setCurrentBoRRouteStatus(currentBoRRoutes);
		return borCompanyNav;
	},
	saveNContinueWithNumStepsCompleted: function(numStepsCompleted, additionalPromiseArrayIfAny) {
		this.setNumStepsCompletedForThisRoute(numStepsCompleted);
		var nextRouteName = this.get('nextRouteName');
		var nextEmberRouteName = this.get('nextEmberRouteName');
		var dynamicParams = this.dynamicRouteParams(nextRouteName, this.get('borCompanyNav.routeNameToDynamicParamsObj'));
		var self = this;
		if (dynamicParams) {
			// Same hack hack as the previous one.
			this.saveAndContinue().then(function() {
				if (additionalPromiseArrayIfAny) {
					Ember.RSVP.all(additionalPromiseArrayIfAny).then(function() {
						self.transitionTo(nextEmberRouteName, dynamicParams);
					});
				}
				else {
					this.transitionTo(nextEmberRouteName, dynamicParams);
				}
			}.bind(this));
		}
		else {
			if (additionalPromiseArrayIfAny) {
				this.saveAndContinue().then(function() {
					Ember.RSVP.all(additionalPromiseArrayIfAny).then(function() {
						self.transitionTo(nextEmberRouteName);
					});
				});
			}
			else {
				return this.saveAndContinue(nextEmberRouteName);
			}
		}
	},
	saveNContinueToRouteWithNumStepsCompleted: function(numStepsCompleted, nextRouteName, shouldInferDynamicParams, dynamicParams) {
		this.setNumStepsCompletedForThisRoute(numStepsCompleted);
		if (shouldInferDynamicParams) {
			dynamicParams = dynamicParams || this.dynamicRouteParams(nextRouteName, this.get('borCompanyNav.routeNameToDynamicParamsObj'));
		}
		if (dynamicParams) {
			this.saveAndContinue().then(function() {
				this.transitionTo(nextEmberRouteName, dynamicParams);
			}.bind(this));
		}
		else {
			return this.saveAndContinue('setup.boremployeeemails');
		}
	},
});

zen._LoadingScreenMixin = Ember.Mixin.create({
	needs: ['loadingscreen'],
	// Main content can be any html text. Feel free to give any html content.
	showLoadingScreen: function(header, mainContent) {
		var loadingScreenContent = Ember.Object.create({
			header:header,
			maintext:mainContent
		});
		this.send('showModal', 'loadingscreen', loadingScreenContent);
	},
	closeLoadingScreen: function(nextRoute) {
		// This is to ensure smooth transition. Otherwise when the gap between
		// showLoadingScreen and closeLoadingScreen is small, use will see a blip.
		// 600 ms will ensure the user gets a glance of the loading screen while not
		// delaying the transition further.
		var _this = this;
		setTimeout(function() {
			_this.send('hideModal', 'loadingscreen');
			if (nextRoute) {
				_this.transitionTo(nextRoute);
			}
		}, 600);
	},
});

// This will work only for the routes extending the zen._BoRPostSignChildRoute
zen._SetupUtilMixin = Ember.Mixin.create({
	// This will just set the BoR status in all the BoR health carrier. It will not save the thus set
	// carrier.
	setBoRStatus: function(borStatusName) {
		var chcsChanged = [];
		this.get('borHealthCarrierProperties').forEach(function(borCarrierPropertyStr) {
			var borCarrierProperty = this.get(borCarrierPropertyStr);
			if (borCarrierProperty) {
				var status = borCarrierProperty.get('borStatus');
				status = (status) ? status : "";
				if (status.indexOf(borStatusName) == -1) {
					status += "+" + borStatusName;
					chcsChanged.push(borCarrierProperty);
				}
				borCarrierProperty.set('borStatus', status);
			}
		}.bind(this));
		return chcsChanged;
	},
	// This will return a Ember.RSVP.promise.
	setAndSaveBoRStatus: function(borStatusName) {
		var chcsChanged = this.setBoRStatus(borStatusName);
		var promiseArray = [];
		chcsChanged.forEach(function(chcChanged) {
			promiseArray.push(chcChanged.save());
		});
		return Ember.RSVP.all(promiseArray);
	},
});

// This should be used only with AddExistingRoute.
zen._AddExistingUniqueCarrierMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		var borHealthCarriers = getBoRHealthCarriers(model);
		var borBiCarriers = Ember.A();
		if(controller.get('borBusinessInsuranceCarriers')) {
			var borBiCarriers = controller.get('borBusinessInsuranceCarriers');
		}

		var uniqueCarriers = Ember.A();
		var uniqueBiCarriers = Ember.A();
		var uniqueCarrierIds = {};
		var uniqueBiCarrierIds = {};
		var uniqueCarrierEnrollments = Ember.A();

		borHealthCarriers.forEach(function(healthCarrier) {
			if (!uniqueCarrierIds[healthCarrier.get('carrier.id')]) {
				uniqueCarriers.addObject(healthCarrier);
				uniqueCarrierIds[healthCarrier.get('carrier.id')] = 1;
			}
		});
		uniqueCarriers.forEach(function(uniqueHealthCarrier) {
			var carrierHealthEnrollments = Ember.Object.create();
			var healthEnrollments = Ember.A();
			borHealthCarriers.forEach(function(healthCarrier) {
				if (healthCarrier.get('carrier.id') == uniqueHealthCarrier.get('carrier.id')) {
					healthEnrollments.addObject(healthCarrier.get('companyHealthEnrollment'));
				}
			});
			carrierHealthEnrollments.set('healthCarrier', uniqueHealthCarrier);
			carrierHealthEnrollments.set('healthEnrollments', healthEnrollments);
			uniqueCarrierEnrollments.addObject(carrierHealthEnrollments);
		});
		borBiCarriers.forEach(function(biCarrier) {
			if (!uniqueBiCarrierIds[biCarrier.get('carrier.id')]) {
				uniqueBiCarriers.addObject(biCarrier);
				uniqueBiCarrierIds[biCarrier.get('carrier.id')] = 1;
			}
		});
		uniqueBiCarriers.forEach(function(uniqueBiCarrier) {
			var carrierBiEnrollments = Ember.Object.create();
			var biEnrollments = Ember.A();
			borBiCarriers.forEach(function(biCarrier) {
				if (biCarrier.get('carrier.id') == uniqueBiCarrier.get('carrier.id')) {
					biEnrollments.addObject(biCarrier.get('businessInsuranceEnrollment'));
				}
			});
			carrierBiEnrollments.set('biCarrier', uniqueBiCarrier);
			carrierBiEnrollments.set('biEnrollments',biEnrollments);
			uniqueCarrierEnrollments.addObject(carrierBiEnrollments);
		});

		controller.set('uniqueCarriers', uniqueCarriers);
		controller.set('uniqueBiCarriers', uniqueBiCarriers);
		controller.set('borHealthCarriers', borHealthCarriers);
		controller.set('uniqueCarrierEnrollments', uniqueCarrierEnrollments);
	},
});

App.AddexistingQuestionsRoute = zen._AddexistingRoute.extend({
	afterModel: function(model, controller) {
		this._super(controller, model);
		return App.SetupTodo.findAll().then(function(todos) {
			model.set('setupTodos', todos);
			return model;
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('carrier_id', controller.get('borMedicalHealthCarrier.carrier'));
		controller.set('dentalcarrier_id', controller.get('borDentalHealthCarrier.carrier'));
		controller.set('visioncarrier_id', controller.get('borVisionHealthCarrier.carrier'));
		controller.set('additionalcarrier_id', controller.get('borAdditionalMedicalHealthCarrier.carrier'));

		model.set('healthTodo', model.get('setupTodos').find(function(todo) {
			return todo.get('todoKey') === 'HEALTH_AND_BENEFITS';
		}));

		if (controller.get('additionalcarrier_id')) {
			controller.set('hasAdditionalCarrier', true);
		}
		else {
			controller.set('hasAdditionalCarrier', false);
		}
		var medicalCarrier = controller.get('carrier_id');
		for (var i = 0; i < controller.get('companyMedicalEnrollments.length'); i++) {
			if (controller.get('companyMedicalEnrollments').objectAt(i).get('enrollmentType') != 'BoR' ||
				// NERD-12186: If the carrier is not in the list anymore, it probably means that it's in another state. make selection readonly.
				(medicalCarrier && !controller.get('medicalCarriers').contains(medicalCarrier))
			) {
				controller.set('showPrimaryMedicalCarrier', false);
				controller.set('companyMedicalEnrollment', controller.get('companyMedicalEnrollments').objectAt(i));
				break;
			}
		}
		for (var i = 0; i < controller.get('companyDentalEnrollments.length'); i++) {
			if (controller.get('companyDentalEnrollments').objectAt(i).get('enrollmentType') != 'BoR') {
				controller.set('showDentalCarrier', false);
				controller.set('companyDentalEnrollment', controller.get('companyDentalEnrollments').objectAt(i));
				break;
			}
		}
		for (var i = 0; i < controller.get('companyVisionEnrollments.length'); i++) {
			if (controller.get('companyVisionEnrollments').objectAt(i).get('enrollmentType') != 'BoR') {
				controller.set('showVisionCarrier', false);
				controller.set('companyVisionEnrollment', controller.get('companyVisionEnrollments').objectAt(i));
				break;
			}
		}
	}
});


App.AddexistingQuestionsController = zen._TransactionSavingController.extend({
	carrier_id : "",
	showPrimaryMedicalCarrier : true,
	showDentalCarrier: true,
	showVisionCarrier: true,
	buttonText: "Next",
	buttonClass: "button first",
	getPolicyDetails: Ember.computed.or('showPrimaryMedicalCarrier', 'additionalcarrier_id', 'dentalcarrier_id', 'visioncarrier_id'),
	addAdditionalCarrier: function() {
		this.set('hasAdditionalCarrier', true);
	},
	deleteAdditionalCarrier: function() {
		this.set('hasAdditionalCarrier', false);
		this.set('additionalcarrier_id', null);
		this.set('errorText', "");

		if (this.get('borAdditionalMedicalHealthCarrier')) {
			this.get('borAdditionalMedicalHealthCarrier').deleteRecord();
			this.get('borAdditionalMedicalHealthCarrier').save().then(function() {
				this.get('borAdditionalMedicalEnrollment').deleteRecord();
				this.get('borAdditionalMedicalEnrollment').save();
			}.bind(this));
		}
	},
	next: function() {
		var company = this.get('company');
		var promises = [];
		this.set('errorText', '');

		if(this.get('healthTodo')) {
			this.get('healthTodo').complete();
		}

		if (this.get('buttonText') != 'Next') {
			return;
		}

		if (!this.get('carrier_id') && this.get('showPrimaryMedicalCarrier')) {
			this.set('errorText', 'Please select a medical carrier.');
			return;
		}

		if (this.get('hasAdditionalCarrier') && !this.get('additionalcarrier_id')) {
			this.set('errorText', 'Please select the additional medical carrier, or delete additional carrier.');
			return;
		}

		this.set('buttonClass', 'button first unclickable');
		this.set("buttonText", "Saving...");

		if (this.get('carrier_id')) {
			App.ProductStage.createMedicalEvent('marketing_page_view', 0.0);
			App.ProductStage.createMedicalEvent('progress', 0.1);
		}

		if (this.get('additionalcarrier_id')) {
			App.ProductStage.createMedical2Event('marketing_page_view', 0.0);
			App.ProductStage.createMedical2Event('progress', 0.1);
		}

		if (this.get('dentalcarrier_id')) {
			App.ProductStage.createDentalEvent('marketing_page_view', 0.0);
			App.ProductStage.createDentalEvent('progress', 0.1);
		}

		if (this.get('visioncarrier_id')) {
			App.ProductStage.createVisionEvent('marketing_page_view', 0.0);
			App.ProductStage.createVisionEvent('progress', 0.1);
		}

		var medicalPromise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (!this.get('showPrimaryMedicalCarrier')) {
				resolve();
				return;
			}
			if (this.get('borMedicalEnrollment')) {
				if (this.get('borMedicalHealthCarrier.carrier') != this.get('carrier_id')) {
					this.get('borMedicalHealthCarrier').set('carrier', this.get('carrier_id'));
					this.get('borMedicalHealthCarrier').save().then(function() {
						resolve();
					}.bind(this));
				}
				else {
					resolve();
				}
			}
			else {
				var borMedicalEnrollment = App.CompanyHealthEnrollment.createRecord();
				borMedicalEnrollment.set('company', company);
				borMedicalEnrollment.set('lineOfCoverage', 'medical');
				borMedicalEnrollment.set('enrollmentType', 'BoR');
				borMedicalEnrollment.set('startDate', formatDate(new Date()));
				borMedicalEnrollment.set('isEnrollmentComplete', false);
				borMedicalEnrollment.set('isActive', true);

				borMedicalEnrollment.save().then(function() {
					var borMedicalCarrier = App.CompanyHealthCarrier.createRecord();
					borMedicalCarrier.set('company', company);
					borMedicalCarrier.set('companyHealthEnrollment', borMedicalEnrollment);
					borMedicalCarrier.set('carrier', this.get('carrier_id'));
					borMedicalCarrier.set('isPrimaryCarrier', true);
					borMedicalCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
		}.bind(this));

		var additionalCarrierPromise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (!this.get('hasAdditionalCarrier')) {
				resolve();
			}
			else if (this.get('borAdditionalMedicalEnrollment')) {
				if (this.get('borAdditionalMedicalHealthCarrier.carrier') != this.get('additionalcarrier_id')) {
					this.get('borAdditionalMedicalHealthCarrier').set('carrier', this.get('additionalcarrier_id'));
					this.get('borAdditionalMedicalHealthCarrier').save().then(function() {
						resolve();
					}.bind(this));
				}
				else {
					resolve();
				}
			}
			else {
				var borAdditionalMedicalEnrollment = App.CompanyHealthEnrollment.createRecord();
				borAdditionalMedicalEnrollment.set('company', company);
				borAdditionalMedicalEnrollment.set('lineOfCoverage', 'medical');
				borAdditionalMedicalEnrollment.set('enrollmentType', 'BoR');
				borAdditionalMedicalEnrollment.set('startDate', formatDate(new Date()));
				borAdditionalMedicalEnrollment.set('isEnrollmentComplete', false);
				borAdditionalMedicalEnrollment.set('isActive', true);

				borAdditionalMedicalEnrollment.save().then(function() {
					var additionalMedicalHealthCarrier = App.CompanyHealthCarrier.createRecord();
					additionalMedicalHealthCarrier.set('company', company);
					additionalMedicalHealthCarrier.set('companyHealthEnrollment', borAdditionalMedicalEnrollment);
					additionalMedicalHealthCarrier.set('carrier', this.get('additionalcarrier_id'));
					additionalMedicalHealthCarrier.set('isPrimaryCarrier', false);
					additionalMedicalHealthCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
		}.bind(this));

		var dentalPromise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (this.get('borDentalEnrollment') && this.get('dentalcarrier_id')) {
				if (this.get('borDentalHealthCarrier.carrier') != this.get('dentalcarrier_id')) {
					this.get('borDentalHealthCarrier').set('carrier', this.get('dentalcarrier_id'));
					this.get('borDentalHealthCarrier').save().then(function() {
						resolve();
					});
				}
				else {
					resolve();
				}
			}
			else if (this.get('borDentalEnrollment') && !this.get('dentalcarrier_id')) {
				this.get('borDentalHealthCarrier').deleteRecord();
				this.get('borDentalHealthCarrier').save().then(function() {
					this.get('borDentalEnrollment').deleteRecord();
					this.get('borDentalEnrollment').save().then(function() {
						resolve();
					});
				}.bind(this));
			}
			else if (!this.get('borDentalEnrollment') && this.get('dentalcarrier_id')) {
				var borDentalEnrollment = App.CompanyHealthEnrollment.createRecord();
				borDentalEnrollment.set('company', company);
				borDentalEnrollment.set('lineOfCoverage', 'dental');
				borDentalEnrollment.set('enrollmentType', 'BoR');
				borDentalEnrollment.set('startDate', formatDate(new Date()));
				borDentalEnrollment.set('isEnrollmentComplete', false);
				borDentalEnrollment.set('isActive', true);

				borDentalEnrollment.save().then(function() {
					var borDentalCarrier = App.CompanyHealthCarrier.createRecord();
					borDentalCarrier.set('company', company);
					borDentalCarrier.set('companyHealthEnrollment', borDentalEnrollment);
					borDentalCarrier.set('carrier', this.get('dentalcarrier_id'));
					borDentalCarrier.set('isPrimaryCarrier', false);
					borDentalCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
			else {
				resolve();
			}
		}.bind(this));

		var visionPromise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (this.get('borVisionEnrollment') && this.get('visioncarrier_id')) {
				if (this.get('borVisionHealthCarrier.carrier') != this.get('visioncarrier_id')) {
					this.get('borVisionHealthCarrier').set('carrier', this.get('visioncarrier_id'));
					this.get('borVisionHealthCarrier').save().then(function() {
						resolve();
					});
				}
				else {
					resolve();
				}
			}
			else if (this.get('borVisionEnrollment') && !this.get('visioncarrier_id')) {
				this.get('borVisionHealthCarrier').deleteRecord();
				this.get('borVisionHealthCarrier').save().then(function() {
					this.get('borVisionEnrollment').deleteRecord();
					this.get('borVisionEnrollment').save().then(function() {
						resolve();
					});
				}.bind(this));
			}
			else if (!this.get('borVisionEnrollment') && this.get('visioncarrier_id')) {
				var borVisionEnrollment = App.CompanyHealthEnrollment.createRecord();
				borVisionEnrollment.set('company', company);
				borVisionEnrollment.set('lineOfCoverage', 'vision');
				borVisionEnrollment.set('enrollmentType', 'BoR');
				borVisionEnrollment.set('startDate', formatDate(new Date()));
				borVisionEnrollment.set('isEnrollmentComplete', false);
				borVisionEnrollment.set('isActive', true);

				borVisionEnrollment.save().then(function() {
					var borVisionCarrier = App.CompanyHealthCarrier.createRecord();
					borVisionCarrier.set('company', company);
					borVisionCarrier.set('companyHealthEnrollment', borVisionEnrollment);
					borVisionCarrier.set('carrier', this.get('visioncarrier_id'));
					borVisionCarrier.set('isPrimaryCarrier', false);
					borVisionCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
			else {
				resolve();
			}
		}.bind(this));

		promises.push(medicalPromise);
		promises.push(dentalPromise);
		promises.push(visionPromise);
		promises.push(additionalCarrierPromise);
		Ember.RSVP.all(promises).then(function() {
			this.get('model').reload().then(function() {
				this.set('buttonText', "Next");
				this.set('buttonClass', 'button first');
				if (this.get('getPolicyDetails')) {
					this.transitionToRoute('addexisting.policy');
				}
				else {
					this.transitionToRoute('addexisting.life');
				}
			}.bind(this));
		}.bind(this));
	},
	dentalSameAsMedicalDisabled: function() {
		var medicalCarrier = this.get('carrier_id');
		if (!medicalCarrier) {
			return true;
		}
		return !this.get('dentalCarriers').contains(medicalCarrier);
	}.property('dentalCarriers', 'carrier_id'),
	visionSameAsMedicalDisabled: function() {
		var medicalCarrier = this.get('carrier_id');
		if (!medicalCarrier) {
			return true;
		}
		return !this.get('visionCarriers').contains(medicalCarrier);
	}.property('visionCarriers', 'carrier_id'),
	dentalSameAsMedicalObserver: function() {
		if (this.get('dentalSameAsMedical')) {
			this.set('dentalcarrier_id', this.get('carrier_id'));
		}
	}.observes('dentalSameAsMedical'),
	visionSameAsMedicalObserver: function() {
		if (this.get('visionSameAsMedical')) {
			this.set('visioncarrier_id', this.get('carrier_id'));
		}
	}.observes('visionSameAsMedical'),
	carrierIdObserver: function() {
		var medicalCarrier = this.get('carrier_id');
		if (this.get('dentalSameAsMedical')) {
			if (this.get('dentalCarriers').contains(medicalCarrier)) {
				this.set('dentalcarrier_id', medicalCarrier);
			} else {
				this.set('dentalSameAsMedical', false);
			}
		}

		if (this.get('visionSameAsMedical')) {
			if (this.get('visionCarriers').contains(medicalCarrier)) {
				this.set('visioncarrier_id', medicalCarrier);
			} else {
				this.set('visionSameAsMedical', false);
			}
		}

	}.observes('carrier_id')
});


App.AddexistingPolicyRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function(params) {
		return this.all(zen.companyEnrollmentBorApi(''), {
			"borMedicalHealthCarrier" : zen.companyEnrollmentBorApi('borMedicalHealthCarrier'),
			"borAdditionalMedicalHealthCarrier" : zen.companyEnrollmentBorApi('borAdditionalMedicalHealthCarrier'),
			"borDentalHealthCarrier" : zen.companyEnrollmentBorApi('borDentalHealthCarrier'),
			"borVisionHealthCarrier" : zen.companyEnrollmentBorApi('borVisionHealthCarrier'),
		});
	},
	afterModel: function(model) {
		var promiseArray = [];
		var healthCarriers = ['borMedicalHealthCarrier', 'borAdditionalMedicalHealthCarrier', 'borDentalHealthCarrier', 'borVisionHealthCarrier'];
		healthCarriers.forEach(function(healthCarrier) {
			if (model.get(healthCarrier)) {
				promiseArray.push(model.get(healthCarrier + '.carrier').then(function(carrier) {
					return carrier.get('policyRelationship').then(function(metadatas) {
					}.bind(this));
				}.bind(this)));
			}
		});

		return Ember.RSVP.all(promiseArray);
	},
});


App.AddexistingPolicyController = zen._TransactionSavingController.extend({
	showMedicalHint : Ember.computed.or('borMedicalHealthCarrier', 'borAdditionalMedicalHealthCarrier'),
	buttonText: "Next",
	buttonClass: "button first",
	buttonClassBack: "button grey",
	back: function() {
		this.get('model').reload().then(function() {
			this.saveAndContinue('addexisting.questions');
		}.bind(this));
	},
	next: function() {
		var missingGroupIds = "";

		if (this.get('borMedicalHealthCarrier') && !this.get('borMedicalHealthCarrier.groupID')) {
			missingGroupIds += "Medical";
		}
		var dentalHealthCarrier = this.get('borDentalHealthCarrier');
		if (dentalHealthCarrier) {
			if (!dentalHealthCarrier.get('groupID')) {
				missingGroupIds += missingGroupIds ? ", Dental" : "Dental";
			}
		}
		var visionHealthCarrier = this.get('borVisionHealthCarrier');
		if (visionHealthCarrier) {
			if (!visionHealthCarrier.get('groupID')) {
				missingGroupIds += missingGroupIds ? ", Vision" : "Vision";
			}
		}
		if (missingGroupIds) {
			this.set('errorText', "Missing Policy/Group # for (" + missingGroupIds + "); please fill them.");
			return;
		}
		else {
			this.set('errorText', "");
		}

		if (this.get('borMedicalHealthCarrier')) {
			App.ProductStage.createMedicalEvent('progress', 0.2);
		}

		if (this.get('borAdditionalMedicalHealthCarrier')) {
			App.ProductStage.createMedical2Event('progress', 0.2);
		}

		if (dentalHealthCarrier) {
			App.ProductStage.createDentalEvent('progress', 0.2);
		}

		if (visionHealthCarrier) {
			App.ProductStage.createVisionEvent('progress', 0.2);
		}

		this.set('buttonClass', 'button first unclickable');
		this.set('buttonClassBack', "button grey unclickable");
		this.set("buttonText", "Saving...");

		var self = this;
		this.saveAndContinue('addexisting.life').then(function() {
			self.set('buttonClass', 'button first');
			self.set('buttonClassBack', 'button grey');
			self.set("buttonText", "Next");
		});
	},
	dentalSameAsMedicalPolicyObserver: function() {
		if (this.get('dentalSameAsMedicalPolicy')) {
			this.get('borDentalHealthCarrier').set('groupID', this.get('borMedicalHealthCarrier.groupID'));
			this.get('borDentalHealthCarrier').set('approvedDate', this.get('borMedicalHealthCarrier.approvedDate'));
		}
	}.observes('dentalSameAsMedicalPolicy', 'borMedicalHealthCarrier.groupID', 'borMedicalHealthCarrier.approvedDate'),
	visionSameAsMedicalPolicyObserver: function() {
		if (this.get('visionSameAsMedicalPolicy')) {
			this.get('borVisionHealthCarrier').set('groupID', this.get('borMedicalHealthCarrier.groupID'));
			this.get('borVisionHealthCarrier').set('approvedDate', this.get('borMedicalHealthCarrier.approvedDate'));
		}
	}.observes('visionSameAsMedicalPolicy', 'borMedicalHealthCarrier.groupID', 'borMedicalHealthCarrier.approvedDate'),
	showRenewalDateHelp: function() {
		this.send('showModal', 'addexisting.renewaldatehelper');
	},
	showInvoiceHelp: function(healthCarrier) {
		this.send('showModal', 'addexisting.policynumberinvoicehelper', {'invoiceUrls' : healthCarrier.get('carrier.policyInvoiceUrls')});
	},
	showUploadDialog: function(healthCarrier) {
		this.send('showModal', 'addexisting.policyuploadinvoice', healthCarrier);
	},
	medicalConfusedCarrierObserverMedical: function () {
		var borMedicalHealthCarrier = this.get('borMedicalHealthCarrier');
		if (!borMedicalHealthCarrier) {
			return;
		}

		var matchingCarrierList = borMedicalHealthCarrier.get('carrier.commonlyConfusedCarrierList');
		var matchingCarrier = null;
		var groupId = borMedicalHealthCarrier.get('groupID');
		if (matchingCarrierList != null) {
			matchingCarrierList.any(function (carrier) {
				var matchingPolicyPatterns = carrier.get('policyNumberPatterns');
				var isMatching = matchingPolicyPatterns.any(function (policyNumberPattern) {
					var regExp = new RegExp(policyNumberPattern);
					if (regExp.test(groupId)) {
						return true;
					}
				});

				if (isMatching) {
					matchingCarrier = carrier;
					return true;
				}

				matchingCarrier = null;
				return false;
			});
		}

		borMedicalHealthCarrier.set('confusedCarrier', matchingCarrier);
	}.observes('borMedicalHealthCarrier.groupID'),

	confusedCarrierObserverAdditionalMedical: function () {
		var borAdditionalMedicalHealthCarrier = this.get('borAdditionalMedicalHealthCarrier');
		if (!borAdditionalMedicalHealthCarrier) {
			return;
		}

		var matchingCarrierList = borAdditionalMedicalHealthCarrier.get('carrier.commonlyConfusedCarrierList');
		var matchingCarrier = null;
		var groupId = borAdditionalMedicalHealthCarrier.get('groupID');
		if (matchingCarrierList != null) {
			matchingCarrierList.any(function (carrier) {
				var matchingPolicyPatterns = carrier.get('policyNumberPatterns');
				var isMatching = matchingPolicyPatterns.any(function (policyNumberPattern) {
					var regExp = new RegExp(policyNumberPattern);
					if (regExp.test(groupId)) {
						return true;
					}
				});

				if (isMatching) {
					matchingCarrier = carrier;
					return true;
				}

				matchingCarrier = null;
				return false;
			});
		}

		borAdditionalMedicalHealthCarrier.set('confusedCarrier', matchingCarrier);
	}.observes('borAdditionalMedicalHealthCarrier.groupID'),

	dentalConfusedCarrierObserverMedical: function () {
		var borDentalHealthCarrier = this.get('borDentalHealthCarrier');
		if (!borDentalHealthCarrier) {
			return;
		}

		var matchingCarrierList = borDentalHealthCarrier.get('carrier.commonlyConfusedCarrierList');
		var groupId = borDentalHealthCarrier.get('groupID');
		var matchingCarrier = null;
		if (matchingCarrierList != null) {
			matchingCarrierList.any(function (carrier) {
				var matchingPolicyPatterns = carrier.get('policyNumberPatterns');
				var isMatching = matchingPolicyPatterns.any(function (policyNumberPattern) {
					var regExp = new RegExp(policyNumberPattern);
					if (regExp.test(groupId)) {
						return true;
					}
				});

				if (isMatching) {
					matchingCarrier = carrier;
					return true;
				}

				matchingCarrier = null;
				return false;
			});
		}

		borDentalHealthCarrier.set('confusedCarrier', matchingCarrier);
	}.observes('borDentalHealthCarrier.groupID'),
	visionConfusedCarrierObserverMedical: function () {
		var borVisionHealthCarrier = this.get('borVisionHealthCarrier');
		if (!borVisionHealthCarrier) {
			return;
		}

		var matchingCarrierList = borVisionHealthCarrier.get('carrier.commonlyConfusedCarrierList');
		var groupId = borVisionHealthCarrier.get('groupID');
		var matchingCarrier = null;
		if (matchingCarrierList != null) {
			matchingCarrierList.any(function (carrier) {
				var matchingPolicyPatterns = carrier.get('policyNumberPatterns');
				var isMatching = matchingPolicyPatterns.any(function (policyNumberPattern) {
					var regExp = new RegExp(policyNumberPattern);
					if (regExp.test(groupId)) {
						return true;
					}
				});

				if (isMatching) {
					matchingCarrier = carrier;
					return true;
				}

				matchingCarrier = null;
				return false;
			});
		}

		borVisionHealthCarrier.set('confusedCarrier', matchingCarrier);
	}.observes('borVisionHealthCarrier.groupID'),
});

App.AddexistingPolicynumberinvoicehelperController = Ember.ObjectController.extend({
	urlIndex: 0,
	shouldShowSlideButtons: Ember.computed.gt('invoiceUrls.length', 1),
	currentUrl: function() {
		return this.get('invoiceUrls')[this.get('urlIndex')];
	}.property('urlIndex', '@each.invoiceUrls'),
	numInvoices: function() {
		return this.get('invoiceUrls').length;
	}.property('invoiceUrls.length'),
	currentInvoiceNumber: function() {
		return this.get('urlIndex') + 1;
	}.property('urlIndex'),
	previous: function() {
		this.set('urlIndex', Math.abs((this.get('urlIndex') - 1) % this.get('numInvoices')));
	},
	next: function() {
		this.set('urlIndex', Math.abs((this.get('urlIndex') + 1) % this.get('numInvoices')));
	},
	close: function() {
		this.set('urlIndex', 0);
		this.send('hideModal');
	}
});

App.AddexistingRenewaldatehelperController = Ember.Controller.extend({
	close: function() {
		this.send('hideModal');
	},
});

App.AddexistingPolicyuploadinvoiceController = zen._TransactionSavingController.extend(zen._PublicFilePickerMixin, {
	uploadInvoice: function() {
		var model = this.get('model');

		filepicker.setKey(FILEPICKER_KEY);
		filepicker.pickAndStore({
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
				services:['COMPUTER', 'GMAIL', 'DROPBOX', 'GOOGLE_DRIVE']
			},
			{location: "S3"},
			function(FPFile) {
				model.set('invoiceUrl', FPFile[0].url);
			}.bind(this),
			function(FPError) {
				//console.log(FPError.toString());
			}
		);
	},
	saveNContinue: function() {
		this.get('model').save();
		this.send('hideModal');
	}
});

App.AddexistingLifeRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function(params) {
		return this.all(zen.companyEnrollmentBorApi(''), {
				"company": dashboard('company'),
				'lifeCarriers' : zen.stateCarrierApi('lifeCarriers'),
				'disabilityCarriers' : zen.stateCarrierApi('disabilityCarriers'),
				'borLifeEnrollment': zen.companyEnrollmentBorApi('borLifeEnrollment'),
				'borStDisabilityEnrollment': zen.companyEnrollmentBorApi('borStDisabilityEnrollment'),
				'borLtDisabilityEnrollment': zen.companyEnrollmentBorApi('borLtDisabilityEnrollment'),
				'borLifeHealthCarrier': zen.companyEnrollmentBorApi('borLifeHealthCarrier'),
				'borStDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borStDisabilityHealthCarrier'),
				'borLtDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borLtDisabilityHealthCarrier'),
			}
		);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('lifecarrier_id', controller.get('borLifeHealthCarrier.carrier'));
		controller.set('st_disability_carrier_id', controller.get('borStDisabilityHealthCarrier.carrier'));
		controller.set('lt_disability_carrier_id', controller.get('borLtDisabilityHealthCarrier.carrier'));
		controller.set('lifeGroupID', controller.get('borLifeHealthCarrier.groupID'));
		controller.set('st_disability_GroupID', controller.get('borStDisabilityHealthCarrier.groupID'));
		controller.set('lt_disability_GroupID', controller.get('borLtDisabilityHealthCarrier.groupID'));
	}
});



App.AddexistingLifeController = zen._TransactionSavingController.extend({
	buttonText: "Next",
	buttonClass: "button first",
	buttonClassBack: "button grey",
	showNext: Ember.computed.or(
		'borMedicalEnrollment', 'borAdditionalMedicalEnrollment','borDentalEnrollment', 'borVisionEnrollment',
		'borLifeEnrollment', 'borStDisabilityEnrollment', 'borLtDisabilityEnrollment',
		'lifecarrier_id', 'st_disability_carrier_id', 'lt_disability_carrier_id'
	),
	back: function() {
		if (this.get('borMedicalEnrollment') || this.get('borAdditionalMedicalEnrollment') || this.get('borDentalEnrollment') || this.get('borVisionEnrollment')) {
			this.transitionToRoute('addexisting.policy');
		}
		else {
			this.transitionToRoute('addexisting.questions');
		}
	},
	next: function() {
		var company = this.get('company');
		var promises = [];
		this.set('errorText', "");

		if (this.get('buttonText') != 'Next') {
			return;
		}

		if (this.get('lifecarrier_id') && !this.get('lifeGroupID')) {
			this.set('errorText', 'Please specify Life Policy/Group # for ' + this.get('lifecarrier_id.displayName') + ".");
			return;
		}

		if (this.get('st_disability_carrier_id') && !this.get('st_disability_GroupID')) {
			this.set('errorText', 'Please specify Short Term Disability Policy/Group # for ' + this.get('st_disability_carrier_id.displayName') + ".");
			return;
		}

		if (this.get('lt_disability_carrier_id') && !this.get('lt_disability_GroupID')) {
			this.set('errorText', 'Please specify Long Term Disability Policy/Group # for ' + this.get('lt_disability_carrier_id.displayName') + ".");
			return;
		}

		this.set('buttonClass', 'button first unclickable');
		this.set('buttonClassBack', 'button grey unclickable');
		this.set("buttonText", "Saving...");

		if (this.get('lifecarrier_id')) {
			App.ProductStage.createLifeEvent('marketing_page_view', 0.0);
			App.ProductStage.createLifeEvent('progress', 0.1);
		}

		if (this.get('st_disability_carrier_id')) {
			App.ProductStage.createSTDEvent('marketing_page_view', 0.0);
			App.ProductStage.createSTDEvent('progress', 0.1);
		}

		if (this.get('lt_disability_carrier_id')) {
			App.ProductStage.createLTDEvent('marketing_page_view', 0.0);
			App.ProductStage.createLTDEvent('progress', 0.1);
		}

		var lifePromise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (this.get('borLifeEnrollment') && this.get('lifecarrier_id')) {
				if (this.get('borLifeHealthCarrier.carrier') != this.get('lifecarrier_id') || this.get('borLifeHealthCarrier.groupID') != this.get('lifeGroupID')) {
					this.get('borLifeHealthCarrier').set('carrier', this.get('lifecarrier_id'));
					this.get('borLifeHealthCarrier').set('groupID', this.get('lifeGroupID'));
					this.get('borLifeHealthCarrier').save().then(function() {
						resolve();
					});
				}
				else {
					resolve();
				}
			}
			else if (this.get('borLifeEnrollment') && !this.get('lifecarrier_id')) {
				this.get('borLifeHealthCarrier').deleteRecord();
				this.get('borLifeHealthCarrier').save().then(function() {
					this.get('borLifeEnrollment').deleteRecord();
					this.get('borLifeEnrollment').save().then(function() {
						resolve();
					});
				}.bind(this));
			}
			else if (!this.get('borLifeEnrollment') && this.get('lifecarrier_id')) {
				var borLifeEnrollment = App.CompanyHealthEnrollment.createRecord();
				borLifeEnrollment.set('company', company);
				borLifeEnrollment.set('lineOfCoverage', "lifenew");
				borLifeEnrollment.set('enrollmentType', 'BoR');
				borLifeEnrollment.set('startDate', formatDate(new Date()));
				borLifeEnrollment.set('isEnrollmentComplete', false);
				borLifeEnrollment.set('isActive', true);

				borLifeEnrollment.save().then(function() {
					var lifeHealthCarrier = App.CompanyHealthCarrier.createRecord();
					lifeHealthCarrier.set('company', company);
					lifeHealthCarrier.set('companyHealthEnrollment', borLifeEnrollment);
					lifeHealthCarrier.set('carrier', this.get('lifecarrier_id'));
					lifeHealthCarrier.set('isPrimaryCarrier', false);
					lifeHealthCarrier.set('groupID', this.get('lifeGroupID'));
					lifeHealthCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
			else {
				resolve();
			}
		}.bind(this));

		var st_disability_Promise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (this.get('borStDisabilityEnrollment') && this.get('st_disability_carrier_id')) {
				if (this.get('borStDisabilityHealthCarrier.carrier') != this.get('st_disability_carrier_id') || this.get('borStDisabilityHealthCarrier.groupID') != this.get('st_disability_GroupID')) {
					this.get('borStDisabilityHealthCarrier').set('carrier', this.get('st_disability_carrier_id'));
					this.get('borStDisabilityHealthCarrier').set('groupID', this.get('st_disability_GroupID'));
					this.get('borStDisabilityHealthCarrier').save().then(function() {
						resolve();
					});
				}
				else {
					resolve();
				}
			}
			else if (this.get('borStDisabilityEnrollment') && !this.get('st_disability_carrier_id')) {
				this.get('borStDisabilityHealthCarrier').deleteRecord();
				this.get('borStDisabilityHealthCarrier').save().then(function() {
					this.get('borStDisabilityEnrollment').deleteRecord();
					this.get('borStDisabilityEnrollment').save().then(function() {
						resolve();
					});
				}.bind(this));
			}
			else if (!this.get('borStDisabilityEnrollment') && this.get('st_disability_carrier_id')) {
				var borStDisabilityEnrollment = App.CompanyHealthEnrollment.createRecord();
				borStDisabilityEnrollment.set('company', company);
				borStDisabilityEnrollment.set('lineOfCoverage', 'std');
				borStDisabilityEnrollment.set('enrollmentType', 'BoR');
				borStDisabilityEnrollment.set('startDate', formatDate(new Date()));
				borStDisabilityEnrollment.set('isEnrollmentComplete', false);
				borStDisabilityEnrollment.set('isActive', true);

				borStDisabilityEnrollment.save().then(function() {
					var borStDisabilityHealthCarrier = App.CompanyHealthCarrier.createRecord();
					borStDisabilityHealthCarrier.set('company', company);
					borStDisabilityHealthCarrier.set('companyHealthEnrollment', borStDisabilityEnrollment);
					borStDisabilityHealthCarrier.set('carrier', this.get('st_disability_carrier_id'));
					borStDisabilityHealthCarrier.set('isPrimaryCarrier', false);
					borStDisabilityHealthCarrier.set('groupID', this.get('st_disability_GroupID'));
					borStDisabilityHealthCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
			else {
				resolve();
			}
		}.bind(this));

		var lt_disability_Promise = new Ember.RSVP.Promise(function(resolve, reject) {
			if (this.get('borLtDisabilityEnrollment') && this.get('lt_disability_carrier_id')) {
				if (this.get('borLtDisabilityHealthCarrier.carrier') != this.get('lt_disability_carrier_id') || this.get('borLtDisabilityHealthCarrier.groupID') != this.get('lt_disability_GroupID')) {
					this.get('borLtDisabilityHealthCarrier').set('carrier', this.get('lt_disability_carrier_id'));
					this.get('borLtDisabilityHealthCarrier').set('groupID', this.get('lt_disability_GroupID'));
					this.get('borLtDisabilityHealthCarrier').save().then(function() {
						resolve();
					});
				}
				else {
					resolve();
				}
			}
			else if (this.get('borLtDisabilityEnrollment') && !this.get('lt_disability_carrier_id')) {
				this.get('borLtDisabilityHealthCarrier').deleteRecord();
				this.get('borLtDisabilityHealthCarrier').save().then(function() {
					this.get('borLtDisabilityEnrollment').deleteRecord();
					this.get('borLtDisabilityEnrollment').save().then(function() {
						resolve();
					});
				}.bind(this));
			}
			else if (!this.get('borLtDisabilityEnrollment') && this.get('lt_disability_carrier_id')) {
				var borLtDisabilityEnrollment = App.CompanyHealthEnrollment.createRecord();
				borLtDisabilityEnrollment.set('company', company);
				borLtDisabilityEnrollment.set('lineOfCoverage', 'ltd');
				borLtDisabilityEnrollment.set('enrollmentType', 'BoR');
				borLtDisabilityEnrollment.set('startDate', formatDate(new Date()));
				borLtDisabilityEnrollment.set('isEnrollmentComplete', false);
				borLtDisabilityEnrollment.set('isActive', true);

				borLtDisabilityEnrollment.save().then(function() {
					var borLtDisabilityHealthCarrier = App.CompanyHealthCarrier.createRecord();
					borLtDisabilityHealthCarrier.set('company', company);
					borLtDisabilityHealthCarrier.set('companyHealthEnrollment', borLtDisabilityEnrollment);
					borLtDisabilityHealthCarrier.set('carrier', this.get('lt_disability_carrier_id'));
					borLtDisabilityHealthCarrier.set('isPrimaryCarrier', false);
					borLtDisabilityHealthCarrier.set('groupID', this.get('lt_disability_GroupID'));
					borLtDisabilityHealthCarrier.save().then(function() {
						resolve();
					}.bind(this));
				}.bind(this));
			}
			else {
				resolve();
			}
		}.bind(this));


		promises.push(lifePromise);
		promises.push(st_disability_Promise);
		promises.push(lt_disability_Promise);
		Ember.RSVP.all(promises).then(function() {
			this.get('model').reload().then(function() {
				this.set("errorText", "");
				this.set('buttonClass', 'button first');
				this.set('buttonClassBack', 'button grey');
				this.set("buttonText", "Next");
				if(this.get('switches.business_insurance_bor')) {
					this.transitionToRoute('addexisting.businessinsurance');
				} else {
					this.transitionToRoute('addexistingfinish');
				}
			}.bind(this));
		}.bind(this));
	}
});



zen._BusinessinsuranceAddexistingRoute = zen._AddexistingRoute.extend({
	model: function(params) {
		return this.all(zen.businessInsuranceEnrollmentBorApi(''), {
				'company': dashboard('company'),
				'companyBusinessCarriersByLineOfCoverages' : App.CompanyBusinessCarriersByLineOfCoverage.find(),
				'borBusinessInsuranceEnrollments': zen.businessInsuranceEnrollmentBorApi('borBusinessInsuranceEnrollments'),
				'borBusinessInsuranceCarriers': zen.businessInsuranceEnrollmentBorApi('borBusinessInsuranceCarriers'),
		}).then(function(model){
			var promiseArray = [];
			this.otherModels['companyBusinessCarriersByLineOfCoverages'].forEach(function(biLoC){
				promiseArray.push(thenpath(biLoC,'carriers'));
			});

			//TODO HACK HACK to return the actual model
			return Ember.RSVP.all(promiseArray).then(function() {
				return this;
			}.bind(model));
		}.bind(this));

	},
});


App.BusinessInsuranceEnrollmentWrapper = Ember.Object.extend({
	businessInsuranceEnrollment: null,
	businessInsuranceCarrier: null,
	lineOfCoverage: null,
	renewalDate: null,
	carrier: null,
	groupID: null,
	company:null,
	companyBusinessCarriersByLineOfCoveragesDict: null,
	lineOfCoverageToDispalyDict: null,
	effectiveDate: null,

	carrierChoices: function() {
		if(this.get('lineOfCoverage')){
			var choices = this.get("companyBusinessCarriersByLineOfCoveragesDict")[this.get("lineOfCoverage")];
			return choices;
		}
		else{
			return null;
		}

	}.property('lineOfCoverage'),

	locChange: function() {
		this.set('carrier', null);
		this.set('groupID', null);
		this.set('renewalDate', null);
		this.set('effectiveDate', null);
	}.observes('lineOfCoverage'),

	carrierChange:function() {
		this.set('groupID', null);
		this.set('renewalDate', null);
		this.set('effectiveDate', null);
	}.observes('carrier'),
});


App.AddexistingBusinessinsuranceRoute = App.BusinessinsuranceAddexistingQuestionsRoute  = zen._BusinessinsuranceAddexistingRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);
		//set up companyBusinessCarriersByLineOfCoveragesDict since companyBusinessCarriersByLineOfCoverages is an array
		var sorted_locs = controller.get('companyBusinessCarriersByLineOfCoverages').sortBy('displayName');
		controller.set('companyBusinessCarriersByLineOfCoverages', sorted_locs);
		var companyBiCarriersByLoc = controller.get('companyBusinessCarriersByLineOfCoverages');
		var companyBiCarriersByLocDict = controller.get('companyBusinessCarriersByLineOfCoveragesDict');

		companyBiCarriersByLoc.forEach(function(carriersByLoC) {
			companyBiCarriersByLocDict[carriersByLoC.get("lineOfCoverage")] = carriersByLoC.get("carriers");
			controller.get('lineOfCoverageToDispalyDict')[carriersByLoC.get("lineOfCoverage")] = carriersByLoC.get("displayName");
		});

		if(this.controllerFor('addexisting')) {
			this.controllerFor('addexisting').set('showBICopy', true);
		}
		controller.set('borBusinessInsuranceEnrollmentWrappers', []);
		//set up wrappers
		if (!controller.get("borBusinessInsuranceEnrollments.length")) {
			controller.get("borBusinessInsuranceEnrollmentWrappers").pushObject(App.BusinessInsuranceEnrollmentWrapper.create({
				company: controller.get('company'),
				companyBusinessCarriersByLineOfCoveragesDict: companyBiCarriersByLocDict,
				lineOfCoverageToDispalyDict: controller.get('lineOfCoverageToDispalyDict'),
			}));
		}
		else{
			for(var i=0; i < controller.get("borBusinessInsuranceEnrollments.length"); i++){
				var carrier_record = controller.get("borBusinessInsuranceCarriers").objectAt(i);
				controller.get("borBusinessInsuranceEnrollmentWrappers").pushObject(App.BusinessInsuranceEnrollmentWrapper.create({
					company: controller.get('company'),
					businessInsuranceCarrier: carrier_record,
					businessInsuranceEnrollment: carrier_record.get("businessInsuranceEnrollment"),
					lineOfCoverage: carrier_record.get("businessInsuranceEnrollment.lineOfCoverage"),
					effectiveDate: carrier_record.get('businessInsuranceEnrollment.effectiveDate'),
					carrier: carrier_record.get('carrier'),
					groupID: carrier_record.get('groupID'),
					companyBusinessCarriersByLineOfCoveragesDict: companyBiCarriersByLocDict,
					lineOfCoverageToDispalyDict: controller.get('lineOfCoverageToDispalyDict'),
				}));
			}
		}
	},

	deactivate: function() {
		this._super();
		if(this.controllerFor('addexisting')) {
			this.controllerFor('addexisting').set('showBICopy', false);
		}
	}
});

App.AddexistingBusinessinsuranceController = App.BusinessinsuranceAddexistingQuestionsController = zen._TransactionSavingController.extend({
	buttonText: "Next",
	buttonClass: "button first js-glue-bi-next",
	buttonClassBack: "button grey",
	errorText: null,
	borBusinessInsuranceEnrollmentWrappers:null,
	companyBusinessCarriersByLineOfCoveragesDict:{},
	lineOfCoverageToDispalyDict:{},
	hasExtraBusinessInsuranceEnrollmentWrappers: function() {
		return this.get('borBusinessInsuranceEnrollmentWrappers.length') > 1;
	}.property('borBusinessInsuranceEnrollmentWrappers.@each'),

	addAdditionalCarrier: function() {
		this.get("borBusinessInsuranceEnrollmentWrappers").pushObject(App.BusinessInsuranceEnrollmentWrapper.create({
			company: this.get('company'),
			companyBusinessCarriersByLineOfCoveragesDict: this.get('companyBusinessCarriersByLineOfCoveragesDict'),
			lineOfCoverageToDispalyDict: this.get('lineOfCoverageToDispalyDict'),
		}));
	},
	deleteAdditionalCarrier: function(bie) {
		var index = this.get('borBusinessInsuranceEnrollmentWrappers').indexOf(bie);
		if (index > -1) {
			this.get('borBusinessInsuranceEnrollmentWrappers').removeAt(index);
			if (bie.get('businessInsuranceEnrollment')) {
				var bieToDelete = bie.get('businessInsuranceEnrollment');
				bieToDelete.set('isEnrollmentActive', false);
				bieToDelete.save();
			}
		}
		if(this.get('borBusinessInsuranceEnrollmentWrappers.length') === 0) {
			this.get("borBusinessInsuranceEnrollmentWrappers").pushObject(App.BusinessInsuranceEnrollmentWrapper.create({
				company: this.get('company'),
				companyBusinessCarriersByLineOfCoveragesDict: this.get('companyBusinessCarriersByLineOfCoveragesDict'),
				lineOfCoverageToDispalyDict: this.get('lineOfCoverageToDispalyDict'),
			}));
		}
	},
	back: function() {
		this.transitionToRoute('addexisting.life');
	},
	nextCommon: function() {
		this.set('errorText', "");
		if (this.get('buttonText') != 'Next') {
			return;
		}
		var isLineOfCoverageAdded = false;
		// validate input if user entered lineOfCoverage
		for(var i=0; i < this.get('borBusinessInsuranceEnrollmentWrappers.length'); i++) {
			var biEnrollmentWrapper = this.get('borBusinessInsuranceEnrollmentWrappers').objectAt(i);
			if(biEnrollmentWrapper.get('lineOfCoverage') && biEnrollmentWrapper.get('carrier')) {
				isLineOfCoverageAdded = true;
			}
		}
		if(isLineOfCoverageAdded) {
			this.next(true);
		}
		else {
			this.get('model').reload().then(function() {
				this.transitionToRoute('addexistingfinish');
			}.bind(this));
		}
	},
	next: function(isBorFlow) {
		var promises = [];
		this.set('errorText', "");
		if (this.get('buttonText') != 'Next') {
			return;
		}
		//validate input
		for(var i=0; i < this.get('borBusinessInsuranceEnrollmentWrappers.length'); i++){
			var biEnrollmentWrapper = this.get('borBusinessInsuranceEnrollmentWrappers').objectAt(i);
			if(!biEnrollmentWrapper.get('lineOfCoverage')){
				this.set('errorText', 'Please specify line of coverage.');
				return;
			}
			if(!biEnrollmentWrapper.get('carrier')){
				this.set('errorText', 'Please specify a carrier.');
				return;
			}
			if(!biEnrollmentWrapper.get('groupID')){
				this.set('errorText', 'Please specify a policy number/group ID.');
				return;
			}
			if(!biEnrollmentWrapper.get('effectiveDate')){
				this.set('errorText', 'Please specify an effective date.');
				return;
			}


		}
		//validate no duplicate enrollments with the same carrier and the same loc
		for(var i = 0; i <  this.get('borBusinessInsuranceEnrollmentWrappers.length'); i++){
			var biEnrollmentWrapper = this.get('borBusinessInsuranceEnrollmentWrappers').objectAt(i);
			for(var j = i + 1; j <  this.get('borBusinessInsuranceEnrollmentWrappers.length'); j++){
				var biEnrollmentWrapperCmp = this.get('borBusinessInsuranceEnrollmentWrappers').objectAt(j);
				if(biEnrollmentWrapper.get('carrier') == biEnrollmentWrapperCmp.get('carrier') &&
					biEnrollmentWrapper.get('lineOfCoverage') == biEnrollmentWrapperCmp.get('lineOfCoverage')){
					this.set('errorText', "Please avoid duplicate BI type for " + biEnrollmentWrapper.get('carrier.displayName'));
					return;
				}
			}
		}

		this.set('buttonClass', 'button grey');
		this.set('buttonText', 'Saving...');
		for(var i=0; i < this.get('borBusinessInsuranceEnrollmentWrappers.length'); i++){
			var biEnrollmentWrapper = this.get('borBusinessInsuranceEnrollmentWrappers').objectAt(i);
			if(!biEnrollmentWrapper.get('businessInsuranceEnrollment')){
				biEnrollmentWrapper.set('businessInsuranceEnrollment', App.BusinessInsuranceEnrollment.createRecord({company: biEnrollmentWrapper.get('company')}));
			}
			if(!biEnrollmentWrapper.get('businessInsuranceCarrier')){
				biEnrollmentWrapper.set('businessInsuranceCarrier', App.BusinessInsuranceCarrier.createRecord({company: biEnrollmentWrapper.get('company')}));
			}
			var biEnrollment = biEnrollmentWrapper.get('businessInsuranceEnrollment');
			biEnrollment.set('lineOfCoverage', biEnrollmentWrapper.get('lineOfCoverage'));
			biEnrollment.set('lineOfCoverageDisplayName', biEnrollmentWrapper.get('lineOfCoverageToDispalyDict')[biEnrollmentWrapper.get('lineOfCoverage')]);
			biEnrollment.set('effectiveDate', biEnrollmentWrapper.get('effectiveDate'));
			biEnrollment.set('company', biEnrollmentWrapper.get('company'));
			biEnrollment.set('policyStatus', 'Ongoing');
			biEnrollment.set('enrollmentType', 'BoR');
			biEnrollment.set('isEnrollmentActive', true);
			biEnrollment.set('businessInsuranceCarrier', biEnrollmentWrapper.get('businessInsuranceCarrier'));

			var biCarrier = biEnrollmentWrapper.get('businessInsuranceCarrier');
			biCarrier.set('company', biEnrollmentWrapper.get('company'));
			biCarrier.set('carrier', biEnrollmentWrapper.get('carrier'));
			biCarrier.set('groupID', biEnrollmentWrapper.get('groupID'));

			promises.push(
				biEnrollment.save().then(function(savedBiEnrollment){
					var biCarrierToSave = this.get('businessInsuranceCarrier');
					biCarrierToSave.set('businessInsuranceEnrollment', savedBiEnrollment);
					return biCarrierToSave.save().then(function(savedBiCarrier){
						return savedBiCarrier;
					});
				}.bind(biEnrollmentWrapper))
			);
		}

		Ember.RSVP.all(promises).then(function() {
			this.get('model').reload().then(function() {
				this.set("errorText", "");
				this.set('buttonClass', 'button first');
				this.set("buttonText", "Next");
				if(isBorFlow) {
					this.transitionToRoute('addexistingfinish');
				}
				else {
					this.transitionToRoute('businessinsurance.addexistingfinish');
				}
			}.bind(this));
		}.bind(this));
	},
});


App.SetupShortcircuitoeintroRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		// We reload it everytime. The things here are computed and cound have been updated just after addexisting/finish route.
		return this.all(App.ShortCircuitOpenEnrollmentStats.find('me').then(function(resolvedModel) { return resolvedModel.reload();}), {'medicalCarrier': zen.companyEnrollmentBorApi('borMedicalHealthCarrier')});
	},
	afterModel: function(model) {
		if (!model.get('medicalShortCircuitOEEligible') &&
			!model.get('additionalMedicalShortCircuitOEEligible') &&
			!model.get('dentalShortCircuitOEEligible') &&
			!model.get('visionShortCircuitOEEligible')) {
			this.transitionTo('setup.totalemployees');
			return;
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);
	}

});

App.SetupShortcircuitoeintroController = Ember.Controller.extend({
	next: function() {
		var borStatus = this.get('medicalCarrier.borStatus');
		borStatus = (borStatus) ? borStatus : "";
		if (borStatus.indexOf("intro") == -1) {
			borStatus += "+intro";
			this.set('medicalCarrier.borStatus', borStatus);
			// It is fine for this to be asynchronous.
			this.get('medicalCarrier').save();
		}
		this.transitionToRoute('setup.totalemployees');
	}
});

App.SetupBorcompletecongratsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(App.ShortCircuitOpenEnrollmentStats.find('me'), {'medicalCarrier': zen.companyEnrollmentBorApi('borMedicalHealthCarrier')});
	},
	afterModel: function(model) {
		// Short circuit OE stats is a stats resource and needs to be reloaded to get the fresh stats.
		return model.reload();
	},

	redirect: function(model) {
		if (!model.get('shortCircuitOECreated')) {
			// No short circuit OE created, go to index route directly.
			this.transitionTo('index');
			return;
		}
	},

	setupController: function(controller, model) {
		this._super(controller, model);
	},
});

App.SetupBorcompletecongratsController = Ember.Controller.extend({

	next: function() {
		var borStatus = this.get('medicalCarrier.borStatus');
		borStatus = (borStatus) ? borStatus : "";
		if (borStatus.indexOf("welcome") == -1) {
			borStatus += "+welcome";
			this.set('medicalCarrier.borStatus', borStatus);
			// It is fine for this to be asynchronous.
			this.get('medicalCarrier').save();
		}
		window.location.href = '/dashboard';
	}
});

App.AddexistingfinishRoute = zen._AddexistingRoute.extend(zen._AddExistingUniqueCarrierMixin, {
	beforeModel: function() {
		// Company might go to switched state and hence they might be eligible for IM fees.
		return dashboard('company').then(function(company) {
			return company.reload();
		}.bind(this));
	},
	afterModel: function(model) {
		var promiseArray = [];
		if (model.get('borMedicalHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borMedicalHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borAdditionalMedicalHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borAdditionalMedicalHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borDentalHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borDentalHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borVisionHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borVisionHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borLifeHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borLifeHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borStDisabilityHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borStDisabilityHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borLtDisabilityHealthCarrier')) {
			promiseArray.push(App.CarrierCopy.find(model.get('borLtDisabilityHealthCarrier.carrier.copy.id')));
		}
		if (model.get('borBusinessInsuranceCarriers')) {
			model.get('borBusinessInsuranceCarriers').forEach(function(borBiCarrier) {
				promiseArray.push(App.BiCarrierCopy.find(borBiCarrier.get('carrier.biCopy.id')));
			});
		}
		return Ember.RSVP.all(promiseArray);
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		var borLetterCarriers = Ember.A();
		var carrierCounter = 0;
		var agents = model.agents;
		controller.get('uniqueCarrierEnrollments').forEach(function(uniqueCarrier) {
			var carrierDetails;
			if(uniqueCarrier.get('healthCarrier')) {
				var healthCarrier = uniqueCarrier.get('healthCarrier');
				carrierDetails = {
					'carrier': healthCarrier.get('carrier'),
					'groupID': healthCarrier.get('groupID'),
					'carrierIndex': "carrier" + carrierCounter.toString(),
					'enrollments': uniqueCarrier.get('healthEnrollments'),
				};
			}
			else {
				var biCarrier = uniqueCarrier.get('biCarrier');
				carrierDetails = {
					'carrier': biCarrier.get('carrier'),
					'carrierIndex': "carrier" + carrierCounter.toString(),
					'enrollments': uniqueCarrier.get('biEnrollments'),
				};
			}
			carrierDetails.agent = agents[carrierDetails.carrier.get('displayName')];
			borLetterCarriers.addObject(carrierDetails);
			carrierCounter++;
		});
		controller.set('borLetterCarriers', borLetterCarriers);

		/* Set the enrollment date to TODAY */
		controller.set('enrolledDate', formatDate(new Date()));

		var enrollment = null;
		if (controller.get('borMedicalEnrollment') && controller.get('borMedicalEnrollment.authName')) {
			enrollment = controller.get('borMedicalEnrollment');
		}
		else if (controller.get('borAdditionalMedicalEnrollment') && controller.get('borAdditionalMedicalEnrollment.authName')) {
			enrollment = controller.get('borAdditionalMedicalEnrollment');
		}
		else if (controller.get('borDentalEnrollment') && controller.get('borDentalEnrollment.authName')) {
			enrollment = controller.get('borDentalEnrollment');
		}
		else if (controller.get('borVisionEnrollment') && controller.get('borVisionEnrollment.authName')) {
			enrollment = controller.get('borVisionEnrollment');
		}
		else if (controller.get('borLifeEnrollment') && controller.get('borLifeEnrollment.authName')) {
			enrollment = controller.get('borLifeEnrollment');
		}
		else if (controller.get('borStDisabilitylEnrollment') && controller.get('borStDisabilitylEnrollment.authName')) {
			enrollment = controller.get('borStDisabilityEnrollment');
		}
		else if (controller.get('borLtDisabilitylEnrollment') && controller.get('borLtDisabilitylEnrollment.authName')) {
			enrollment = controller.get('borLtDisabilityEnrollment');
		}

		if (enrollment) {
			controller.set('authName', enrollment.get('authName'));
			controller.set('authTitle', enrollment.get('authTitle'));
			controller.set('authWebsite', enrollment.get('authWebsite'));
			controller.set('authPhone', enrollment.get('authPhone'));
			controller.set('authSignature', enrollment.get('authSignature'));
		}

		var company = controller.get('company');
		controller.set('companyAddress', company.get('address'));
		controller.set('companyAddress2', company.get('address2'));
		controller.set('companyCity', company.get('city'));
		controller.set('companyState', company.get('state'));
		controller.set('companyZip', company.get('zip'));
	}
});


App.AddexistingfinishController =zen._TransactionSavingController.extend({
	authentication: Ember.inject.service(),
	errorText: '',
	buttonText: "Authorize and Send",
	buttonClass: 'button first',
	company: null,
	showPrimaryMedicalCarrier: true,
	authName: null,
	authTitle: null,
	authWebsite: null,
	authPhone: null,
	authSignature: null,
	isOnlineAuthorizationChecked: null,
	isIMFeesChecked: null,
	companyAddress: null,
	companyAddress2: null,
	companyCity: null,
	companyState: null,
	companyZip: null,
	mainAgency: function() {
		return this.get('borLetterCarriers.firstObject.agent.agency');
	}.property('borLetterCarriers.[]'),
	currentDate: moment(),
	signingDate: function() {
		return moment();
	},
	isAnyFieldMissing: function(){
		var anyFieldMissing =
			(!this.get('authName') ||
			!this.get('authTitle') ||
			!this.get('authWebsite') ||
			!this.get('authPhone') ||
			!this.get('authSignature') ||
			!this.get('companyAddress') ||
			!this.get('companyCity') ||
			!this.get('companyState') ||
			!this.get('companyZip'));
		return anyFieldMissing;
	},
	isAllCarriersAuthorized: function(){
		var allCarriersAuthorized=true;
		this.get('borLetterCarriers').forEach(function(borLetterCarrier) {
			if (!borLetterCarrier.isSelected) {
				allCarriersAuthorized = false;
			}
		});
		return allCarriersAuthorized;
	},
	saveExisting: function() {
		if (this.isAnyFieldMissing())
		{
			this.set('errorText', 'All fields are required. Please fill out the missing fields.');
			return;
		}

		if (!this.isAllCarriersAuthorized()) {
			this.set('errorText', 'Please authorize letter for all the carriers.');
			return;
		}

		if (!this.get('isIMFeesChecked')) {
			this.set('errorText', "Please check Disclosure & Acknowledgement of Broker Compensation");
			return;
		}
		this.set('errorText', '');
		this.set('buttonText', "Sending...");
		this.set('buttonClass', 'button first unclickable');

		try {
			var today = formatDate(new Date());
			var now = new Date();
			var promiseArray = [];
			var borEnrollments = Ember.A();
			var medical = false;
			var dental = false;
			var vision = false;
			var isBIbor = false;
			var agreements = [];
			var carrierCopy = null;
			var carrier = null;

			if (this.get('borMedicalEnrollment')) {
				borEnrollments.pushObject(this.get('borMedicalEnrollment'));
				medical = true;
			}
			if (this.get('borAdditionalMedicalEnrollment')) {
				borEnrollments.pushObject(this.get('borAdditionalMedicalEnrollment'));
			}
			if (this.get('borDentalEnrollment')) {
				borEnrollments.pushObject(this.get('borDentalEnrollment'));
				dental = true;
			}
			if (this.get('borVisionEnrollment')) {
				borEnrollments.pushObject(this.get('borVisionEnrollment'));
				vision = true;
			}
			if (this.get('borLifeEnrollment')) {
				borEnrollments.pushObject(this.get('borLifeEnrollment'));
			}
			if (this.get('borStDisabilityEnrollment')) {
				borEnrollments.pushObject(this.get('borStDisabilityEnrollment'));
			}
			if (this.get('borLtDisabilityEnrollment')) {
				borEnrollments.pushObject(this.get('borLtDisabilityEnrollment'));
			}
			if (this.get('borBusinessInsuranceEnrollments')) {
				this.get('borBusinessInsuranceEnrollments').forEach(function (borBIEnrollment) {
					borBIEnrollment.set('isBIbor', true);
					borEnrollments.pushObject(borBIEnrollment);
					isBIbor = true;
				});
			}

			App.ProductStage.createAllInsuranceEvent('progress', 0.3, this);

			borEnrollments.forEach(function(borEnrollment) {
				// Don't make the enrollment status back to switched again.
				if (!borEnrollment.get('enrollmentStatus')) {
					borEnrollment.set('enrollmentStatus', 'switched');
				}
				borEnrollment.set('endDate', today);
				borEnrollment.set('isEnrollmentComplete', true);
				borEnrollment.set('authName', this.get('authName'));
				borEnrollment.set('authTitle', this.get('authTitle'));
				borEnrollment.set('authWebsite', this.get('authWebsite'));
				borEnrollment.set('authPhone', this.get('authPhone'));
				borEnrollment.set('authSignature', this.get('authSignature'));
				if (!borEnrollment.get('enrollmentCompleteTimestamp')) {
					borEnrollment.set('enrollmentCompleteTimestamp', now);
				}
				borEnrollment.set('addExistingTimestamp', now);
				borEnrollment.set('authDate', today);

				promiseArray.push(borEnrollment.save());

				//Save BoR agreement
				if (borEnrollment.get("isBIbor")) {
					carrier = borEnrollment.get('businessInsuranceCarrier.carrier');
				} else {
					carrier = borEnrollment.get('companyHealthCarrier.carrier');
				}
				var agreementData = {
					isBIbor: !!borEnrollment.get("isBIbor"),
					id: borEnrollment.get('id'),
					name: carrier.get('displayName') ? carrier.get('displayName') : "",
					letters: [],
				};

				var letterHtml = "";
				carrierCopy = carrier.get('copy');
				if (carrierCopy.get('borUsesCarrierSpecificLetterHead')) {
					letterHtml += carrierCopy.get('borCarrierLetterHeadContent');
				} else {
					letterHtml += carrierCopy.get('borCompanyLetterHeadContent');
				}
				letterHtml += '<hr style="margin-top: 0px">';
				letterHtml += carrierCopy.get('borLetterBodyContent');
				letterHtml += '<p>Sincerely,</p>';

				agreementData.letters.push(letterHtml);

				if (carrierCopy.get('borUsesCarrierSpecificTemplate') && !borEnrollment.get('companyHealthCarrier.carrier.usesLocalBroker')) {
					var authLetterHtml = "";
					authLetterHtml += carrierCopy.get('borCompanyLetterHeadContent');
					authLetterHtml += '<hr style="margin-top: 0px">';
					authLetterHtml += carrierCopy.get('borAuthLetterBodyContent');
					authLetterHtml += '<p>Sincerely,</p>';

					agreementData.letters.push(authLetterHtml);
				}
				agreements.push(agreementData);
			}.bind(this));

			var company = this.get('company');
			company.set('selectedCarrier', this.get('borMedicalEnrollment.companyHealthCarrier.carrier.name'));
			company.set('selectedStateCarrier', this.get('borMedicalEnrollment.companyHealthCarrier.carrier'));

			// Set the company address related information.
			company.set('address', this.get('companyAddress'));
			company.set('address2', this.get('companyAddress2'));
			company.set('city', this.get('companyCity'));
			company.set('state', this.get('companyState'));
			company.set('zip', this.get('companyZip'));

			promiseArray.push(company.save());

			Ember.RSVP.all(promiseArray).then(function() {
				this.send('showModal', 'addexisting.success', company);
				this.set('buttonText', 'Authorize and Send');
				this.set('buttonClass', 'button first');
				$.ajax({
					url: '/custom_api/employerNotification',
					type: "post",
					data: {
						"csrfmiddlewaretoken": this.get('authentication.csrfToken'),
						"action": "switchClient",
						"medical": medical,
						"dental": dental,
						"vision": vision,
						"isBIbor": isBIbor,
					},
				});
				if (isBIbor) {
					company.set('showBusinessInsurance', true);
					$.ajax({
						url: '/custom_api/employerNotification',
						type: "post",
						data: {
							"csrfmiddlewaretoken": this.get('authentication.csrfToken'),
							"action": "biSwitchClient",
							"isBIbor": isBIbor,
						},
					});
				}
			}.bind(this));
		} catch(e) {
			this.set('buttonText', 'Authorize and Send');
			this.set('buttonClass', 'button first');
			this.set('errorText', 'Unexpected error occurred, could you please refresh and try again.');
			return;
		}
	},
	scrollTo: function(carrierId) {
		setTimeout(function () {
			Ember.$('html, body').animate({
				scrollTop: $('#'+carrierId).offset().top - 20
			}, 200);
		}, 10);
	},
	scrollToTop: function() {
		setTimeout(function () {
			Ember.$('html, body').animate({
				scrollTop: $('#sign').offset().top - $(window).height() + $('#sign').height() + 10
			}, 200);
		}, 10);
	},
	companyAddressChange: function() {
		Ember.$('.company-address-letter-head').text(this.get('companyAddress'));
	}.observes('companyAddress'),
	companyCityChange: function() {
		Ember.$('.company-city-letter-head').text(this.get('companyCity'));
	}.observes('companyCity'),
	companyStateChange: function() {
		Ember.$('.company-state-letter-head').text(this.get('companyState'));
	}.observes('companyState'),
	companyZipChange: function() {
		Ember.$('.company-zip-letter-head').text(this.get('companyZip'));
	}.observes('companyZip')
});
App.AddexistingSuccessController = Ember.ObjectController.extend();

zen._BusinessInsuranceAddExistingUniqueCarrierMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		var borBiCarriers = controller.get('borBusinessInsuranceCarriers');
		var uniqueCarriers = Ember.A();
		var uniqueCarrierIds = {};
		var uniqueCarrierEnrollments = Ember.A();

		borBiCarriers.forEach(function(biCarrier) {
			if (!uniqueCarrierIds[biCarrier.get('carrier.id')]) {
				uniqueCarriers.addObject(biCarrier);
				uniqueCarrierIds[biCarrier.get('carrier.id')] = 1;
			}
		});
		uniqueCarriers.forEach(function(uniqueBiCarrier) {
			var carrierBiEnrollments = Ember.Object.create();
			var biEnrollments = Ember.A();
			borBiCarriers.forEach(function(biCarrier) {
				if (biCarrier.get('carrier.id') == uniqueBiCarrier.get('carrier.id')) {
					biEnrollments.addObject(biCarrier.get('businessInsuranceEnrollment'));
				}
			});
			carrierBiEnrollments.set('biCarrier', uniqueBiCarrier);
			carrierBiEnrollments.set('biEnrollments',biEnrollments);
			uniqueCarrierEnrollments.addObject(carrierBiEnrollments);
		});

		controller.set('uniqueCarriers', uniqueCarriers);
		controller.set('uniqueCarrierEnrollments', uniqueCarrierEnrollments);
	},
});


App.BusinessinsuranceAddexistingfinishRoute  = zen._BusinessinsuranceAddexistingRoute.extend(zen._BusinessInsuranceAddExistingUniqueCarrierMixin,{
	afterModel: function(model){
		var promiseArray = [];
		model.get('borBusinessInsuranceCarriers').forEach(function(borBiCarrier){
			promiseArray.push(App.BiCarrierCopy.find(borBiCarrier.get('carrier.biCopy.id')));
		});
		return Ember.RSVP.all(promiseArray);
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		var borLetterCarriers = Ember.A();
		var carrierCounter = 0;
		controller.get('uniqueCarrierEnrollments').forEach(function(uniqueCarrier) {
			var biCarrier = uniqueCarrier.get('biCarrier');
			var carrierCopy = biCarrier.get('carrier.biCopy');

			var carrierDetails = {'carrier': biCarrier.get('carrier'), 'carrierIndex': "carrier"+carrierCounter.toString(),
					'enrollments': uniqueCarrier.get('biEnrollments'), 'carrierCopy': carrierCopy};
			borLetterCarriers.addObject(carrierDetails);
			carrierCounter++;
		});
		controller.set('borLetterCarriers', borLetterCarriers);

		// populate available company data
		var company = controller.get('company');
		controller.set('companyAddress', company.get('address'));
		controller.set('companyAddress2', company.get('address2'));
		controller.set('companyCity', company.get('city'));
		controller.set('companyState', company.get('state'));
		controller.set('companyZip', company.get('zip'));
	},
});

App.BusinessinsuranceAddexistingfinishController = App.AddexistingfinishController.extend({
	saveExisting: function() {
		if (this.isAnyFieldMissing())
		{
			this.set('errorText', 'All fields are required. Please fill out the missing fields.');
			return;
		}

		if (!this.isAllCarriersAuthorized()) {
			this.set('errorText', 'Please authorize letter for all the carriers.');
			return;
		}

		this.set('errorText', '');
		this.set('buttonText', "Sending...");
		this.set('buttonClass', 'button first grey');

		try {
			var today = formatDate(new Date());
			var now = new Date();
			var promiseArray = [];
			var borEnrollments = this.get('borBusinessInsuranceEnrollments');


			borEnrollments.forEach(function(borEnrollment) {
				if (!borEnrollment.get('enrollmentStatus')) {
					borEnrollment.set('enrollmentStatus', 'switched');
				}

				borEnrollment.set('authName', this.get('authName'));
				borEnrollment.set('authTitle', this.get('authTitle'));
				borEnrollment.set('authWebsite', this.get('authWebsite'));
				borEnrollment.set('authPhone', this.get('authPhone'));
				borEnrollment.set('authSignature', this.get('authSignature'));
				if (!borEnrollment.get('enrollmentCompleteTimestamp')) {
					borEnrollment.set('enrollmentCompleteTimestamp', now);
				}
				borEnrollment.set('addExistingTimestamp', now);
				borEnrollment.set('authDate', today);

				promiseArray.push(borEnrollment.save());
			}.bind(this));

			var company = this.get('company');

			// Set the company address related information.
			company.set('address', this.get('companyAddress'));
			company.set('address2', this.get('companyAddress2'));
			company.set('city', this.get('companyCity'));
			company.set('state', this.get('companyState'));
			company.set('zip', this.get('companyZip'));

			promiseArray.push(company.save());

			Ember.RSVP.all(promiseArray).then(function() {
				this.get('company').set('showBusinessInsurance', true);
				this.send('showModal', 'businessinsurance.addexisting.success', company);
				this.set('buttonText', 'Authorize and Send');
				this.set('buttonClass', 'button first');
				$.ajax({
					url: '/custom_api/employerNotification',
					type: "post",
					data: {
						"csrfmiddlewaretoken": this.get('authentication.csrfToken'),
						"action": "biSwitchClient",
					},
				});
			}.bind(this));
		}
		catch(e) {
			this.set('buttonText', 'Authorize and Send');
			this.set('buttonClass', 'button first');
			this.set('errorText', 'Unexpected error occurred, could you please refresh and try again.');
			return;
		}
	}
});


App.BusinessinsuranceAddexistingSuccessController = Ember.ObjectController.extend();

App.LoadingscreenController = Ember.ObjectController.extend();

App.BordashboardRoute = Ember.Route.extend(zen._BoRPostSignModelMixin, zen._MultiModelMixin, zen._AuthAdminMixin, zen._BoRGamificationUtils, {
	beforeModel: function() {
		this._super();
		return App.ShortCircuitOpenEnrollmentStats.find('me').then(function(resolvedModel) { return resolvedModel.reload();});
	},
	afterModel: function() {
		var hasAtleastOneSwitchedCarrier = false;
		this.otherModels.borHealthEnrollmentProperties.forEach(function(borHealthEnrollmentPropertyStr) {
			var borHealthEnrollment = this.otherModels[borHealthEnrollmentPropertyStr]; // medical|dental|vision|blah carriers
			if (borHealthEnrollment &&
				borHealthEnrollment.get('enrollmentStatus') == 'switched' &&
				borHealthEnrollment.get('isActive') === true &&
				borHealthEnrollment.get('isEnrollmentComplete') === true)
			{
				hasAtleastOneSwitchedCarrier = true;
				return hasAtleastOneSwitchedCarrier;
			}
		}.bind(this));
		if (!hasAtleastOneSwitchedCarrier) {
			this.transitionTo('index');
		}
	},
	redirect: function(model) {
		var borCompanyNav = this.otherModels['borCompanyNav'];
		var scoeStats = this.otherModels['shortCircuitOEStats'];
		if (!scoeStats.get('shortCircuitOEEligible')) {
			// only directs to the new flow when no scOE eligible lines
			// NOTE: if all steps in borSetupWizard are completed, another re-direct will be triggered to direct admin to the persistence page
			// TODO: check borPersistenceNav and then direct to persistence page from here rather than relies on the wizard to re-direct
			// meh for now as when we hit here we already loaded pretty much everything thus no obvious performance benefits
			// long term solution is to re-direct in beforeModel
			this.transitionTo('borSetupWizard');
		}
		else {
			if (!this.isMandatoryStepsComplete(borCompanyNav.get('mandatoryRoutesToMinStepsNeededObj'), borCompanyNav.get('borRoutesStatusesObj'), borCompanyNav.get('enableMandatoryRoutes'))) {
				var nextEmberRouteName = this.nextEmberRouteForTheRoute('bordashboard', borCompanyNav.get('borCardsToRoutesObj'), borCompanyNav.get('routeNameToEmberRouteObj'), borCompanyNav.get('mandatoryRoutesToMinStepsNeededObj'), borCompanyNav.get('borRoutesStatusesObj'), borCompanyNav.get('enableMandatoryRoutes'));
				var nextRoute = this.nextOrPrevRoute('bordashboard', borCompanyNav.get('borCardsToRoutesObj'), borCompanyNav.get('mandatoryRoutesToMinStepsNeededObj'), borCompanyNav.get('borRoutesStatusesObj'), true, borCompanyNav.get('enableMandatoryRoutes'));
				var dynamicParams = this.dynamicRouteParams(nextRoute, borCompanyNav.get('routeNameToDynamicParamsObj'));
				if (dynamicParams) {
					this.transitionTo(nextEmberRouteName, dynamicParams);
				}
				else {
					this.transitionTo(nextEmberRouteName);
				}
			}
		}
	}
});

App.BordashboardController = Ember.Controller.extend(
);

App.SetupRoute = Ember.Route.extend(zen._BoRPostSignModelMixin, zen._BoRGamificationUtils, zen._MultiModelMixin, zen._AuthAdminMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);
	},
	deactivate: function() {
		this._super();
		var navigationCard = this.get('controller.borNavigationCard');
		if (navigationCard) {
			navigationCard.set('isValid', false);
			// We reset the card name since this card is no longer applicable and when we reenter the cards are computed afresh.
			this.set('controller.thisCardName', null);
		}
	}
});

App.SetupLoadingRoute = Ember.Route.extend({
	renderTemplate: function(controller, model) {
		this.render('loading', {controller: controller});
	},
});

App.SetupController = Ember.Controller.extend(zen._BoRGamificationUtils, {
	borNavigationCard: function() {
		var thisCardName = this.get('thisCardName');
		if (!thisCardName) {
			return;
		}

		// 100% sure that this card object exists unless something is broken elsewhere.
		return this.get('borCompanyNav.borCardNamesToNavigationObjects')[this.get('thisCardName')];
	}.property('thisCardName', 'borCompanyNav.borCardNamesToNavigationObjects'),
	computeRoutesAndUpdateChecklist: function(cardName, routeNames) {
		var routes = Ember.A();
		for(var i = 0; i < routeNames.length; i++) {
			var routeName = routeNames[i];
			var verboseRouteName = this.verboseRouteOrCardName(routeName);
			var emberLink = '<a href="' + this.getHrefLink(routeName, this.get('borCompanyNav.routeNameToEmberRouteObj'), this.get('borCompanyNav.routeNameToDynamicParamsObj')) + '">' + verboseRouteName + '</a>';
			var isComplete = this.numStepsCompletedForTheRoute(routeName, this.get('borCompanyNav.borRoutesStatusesObj')) == this.maxStepsInTheRoute(routeName, this.get('borCompanyNav.borCardsToRoutesObj'));
			var isCurrent = this.get('currentChildRouteName') == routeName;
			var route = Ember.Object.create({
				verboseRouteName: verboseRouteName,
				routeName:routeName,
				link: emberLink,
				isComplete: isComplete,
				isCurrent: isCurrent,
			});
			routes.pushObject(route);
		}

		var borNavigationCard = this.get('borCompanyNav.borCardNamesToNavigationObjects')[cardName];
		borNavigationCard.set('routesAndLinks', routes);
		borNavigationCard.set('verboseCardName', this.verboseRouteOrCardName(cardName));
		borNavigationCard.set('isValid', true);
 		this.computeChecklistClass(routes);
		return borNavigationCard;
	},
	refreshChecklist: function() {
		var thisCardName = this.get('thisCardName');
		if (!thisCardName) {
			return;
		}
		var borCardsToRoutes = this.get('borCompanyNav.borCardsToRoutesObj');
		var routesInThisCard = borCardsToRoutes[thisCardName];
		this.computeRoutesAndUpdateChecklist(thisCardName, Object.keys(routesInThisCard));

	}.observes('thisCardName', 'borCompanyNav.borCardsToRoutesObj', 'borCompanyNav.routeNameToEmberRouteObj', 'borCompanyNav.routeNameToDynamicParamsObj', 'borCompanyNav.borCardNamesToNavigationObjects'),
	markCompletedRoute: function() {
		var routes = this.get('borNavigationCard.routesAndLinks');
		// Observerrs can be called before setupController and this will guard it.
		if (!routes) {
			return;
		}
		for(var i=0; i<routes.get('length'); i++) {
			if (this.numStepsCompletedForTheRoute(routes[i].get('routeName'), this.get('borCompanyNav.borRoutesStatusesObj')) == this.maxStepsInTheRoute(routes[i].routeName, this.get('borCompanyNav.borCardsToRoutesObj'))) {
				routes[i].set('isComplete', true);
			}
		}
		this.computeChecklistClass(this.get('borNavigationCard.routesAndLinks'));
	}.observes('borCompanyNav.borRoutesStatusesObj'),
	markCurrentRoute: function() {
		var routes = this.get('borNavigationCard.routesAndLinks');
		// Observerrs can be called before setupController and this will guard it.
		if (!routes) {
			return;
		}
		for(var i=0; i<routes.get('length'); i++) {
			if (this.get('currentChildRouteName') == routes[i].get('routeName')) {
				routes[i].set('isCurrent', true);
			}
			else {
				routes[i].set('isCurrent', false);
			}
		}
		this.computeChecklistClass(this.get('borNavigationCard.routesAndLinks'));
	}.observes('currentChildRouteName'),
	computeChecklistClass: function(routesAndLinks) {
		routesAndLinks.forEach(function(routeAndLink) {
			routeAndLink.set('checklistClass', "");
			if (routeAndLink.get('isCurrent')) {
				routeAndLink.set('checklistClass', "current");
			}
			else if (routeAndLink.get('isComplete')) {
				routeAndLink.set('checklistClass', "complete");
			}
		});
	},

	showMandatoryCards: function() {
		var borCompanyNav = this.get('borCompanyNav');
		return !this.isMandatoryStepsComplete(borCompanyNav.get('mandatoryRoutesToMinStepsNeededObj'), borCompanyNav.get('borRoutesStatusesObj'), borCompanyNav.get('enableMandatoryRoutes')) && borCompanyNav.get('mandatoryRoutesToMinStepsNeededObj')[this.get('currentChildRouteName')];
	}.property('borCompanyNav.mandatoryRoutesToMinStepsNeededObj', 'borCompanyNav.borRoutesStatusesObj', 'borCompanyNav.enableMandatoryRoutes', 'borCompanyNav.mandatoryRoutesToMinStepsNeeded', 'currentChildRouteName'),
	navigationCards: function() {
		if (!this.get('showMandatoryCards')) {
			return [this.get('borNavigationCard')];
		}

		var mandatoryCardsToRoutes = this.get('borCompanyNav.mandatoryCardsToRoutes');
		return this.get('borCompanyNav.mandatoryCardsOrder').filter(function(cardName) {
			return mandatoryCardsToRoutes[cardName] != null;
		}.bind(this)).map(function(cardName) {
			return this.computeRoutesAndUpdateChecklist(cardName, Object.keys(mandatoryCardsToRoutes[cardName]));
		}.bind(this));
	}.property('borCompanyNav.mandatoryCardsToRoutes', 'borCompanyNav.mandatoryCardsOrder', 'borCompanyNav.borCardNamesToNavigationObjects', 'currentChildRouteName', 'borNavigationCard', 'showMandatoryCards'),
});

App.SetupPlansummaryRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	// Short circuit plans api is a read only model and can change in any of hte short circuit route.
	// So, reload.
	beforeModel: function() {
		return App.ShortCircuitPlansApi.find('me').then(function(resolvedPromise) { return resolvedPromise.reload(); });
	},

	model: function() {

		return this._super().then(function() {
			return this.all(zen.companyEnrollmentBorApi(''), {
				// BoR Short circuit plans.
				'borMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borMedicalShortCircuitPlans'),
				'borAdditionalMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borAdditionalMedicalShortCircuitPlans'),
				'borDentalShortCircuitPlans': zen.shortCircuitPlansApi('borDentalShortCircuitPlans'),
				'borVisionShortCircuitPlans': zen.shortCircuitPlansApi('borVisionShortCircuitPlans'),
			});
		}.bind(this));
	},

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'plan-summary';
		this._super(controller, model);
		// Add all the carriers to array
		var borCarriers = Ember.A();
		if (controller.get('borMedicalHealthCarrier')) {
			borCarriers.addObject(controller.get('borMedicalHealthCarrier'));
		}
		if (controller.get('borAdditionalMedicalHealthCarrier')) {
			borCarriers.addObject(controller.get('borAdditionalMedicalHealthCarrier'));
		}
		if (controller.get('borDentalHealthCarrier')) {
			borCarriers.addObject(controller.get('borDentalHealthCarrier'));
		}
		if (controller.get('borVisionHealthCarrier')) {
			borCarriers.addObject(controller.get('borVisionHealthCarrier'));
		}
		controller.set('borCarriers', borCarriers);
	},
});

// This route as such will not save anythng exept the BoRCompanyNav stating that the user has visited this route.
App.SetupPlansummaryController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, {
	hasAtleastOneMedicalPlan: Ember.computed.or('borMedicalShortCircuitPlans.length', 'borAdditionalMedicalShortCircuitPlans.length'),
	saveNContinue: function() {
		if (!this.get('hasAtleastOnePlans')) {
			this.transitionTo(this.get('nextEmberRouteName'));
			return;
		}
		this.get('borCarriers').forEach(function(borCarrier) {
			var status = borCarrier.get('borStatus');
			status = (status) ? status : "";
			if (status.indexOf("plan-summary") == -1) {
				status += "+plan-summary";
			}
			borCarrier.set('borStatus', status);
		}.bind(this));

		this.saveNContinueWithNumStepsCompleted(1);
	},

	hasAtleastOnePlans: function() {
		return this.get('borMedicalShortCircuitPlans.length') || this.get('borAdditionalMedicalShortCircuitPlans.length') || this.get('borDentalShortCircuitPlans.length') || this.get('borVisionShortCircuitPlans.length');
	}.property('borMedicalShortCircuitPlans', 'borAdditionalMedicalShortCircuitPlans', 'borDentalShortCircuitPlans', 'borVisionShortCircuitPlans'),
});


App.SetupConfigureschRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	// Short circuit plans api is a read only model and can change in any of hte short circuit route.
	// So, reload.
	beforeModel: function() {
		return App.ShortCircuitPlansApi.find('me').then(function(resolvedPromise) { return resolvedPromise.reload(); });
	},

	model: function(params) {
		return this._super(params).then(function() {
			return this.all(dashboard('company'), {
				// Short circuit plans. This is the only extra step from AddExisting route. But for clarity,
				// having this separatedout.
				'borMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borMedicalShortCircuitPlans'),
				'borAdditionalMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borAdditionalMedicalShortCircuitPlans'),
				'borDentalShortCircuitPlans': zen.shortCircuitPlansApi('borDentalShortCircuitPlans'),
				'borVisionShortCircuitPlans': zen.shortCircuitPlansApi('borVisionShortCircuitPlans'),
				'borLifeShortCircuitPlans': zen.shortCircuitPlansApi('borLifenewShortCircuitPlans'),
				'borStDisabilityShortCircuitPlans': zen.shortCircuitPlansApi('borStdShortCircuitPlans'),
				'borLtDisabilityShortCircuitPlans': zen.shortCircuitPlansApi('borLtdShortCircuitPlans'),

				'oeMedicalShortCircuitPlans': zen.shortCircuitPlansApi('oeMedicalShortCircuitPlans'),
				'oeAdditionalMedicalShortCircuitPlans': zen.shortCircuitPlansApi('oeAdditionalMedicalShortCircuitPlans'),
				'oeDentalShortCircuitPlans': zen.shortCircuitPlansApi('oeDentalShortCircuitPlans'),
				'oeVisionShortCircuitPlans': zen.shortCircuitPlansApi('oeVisionShortCircuitPlans'),
				'oeLifeShortCircuitPlans': zen.shortCircuitPlansApi('oeLifenewShortCircuitPlans'),
				'oeStDisabilityShortCircuitPlans': zen.shortCircuitPlansApi('oeStdShortCircuitPlans'),
				'oeLtDisabilityShortCircuitPlans': zen.shortCircuitPlansApi('oeLtdShortCircuitPlans'),
			});
		}.bind(this));
	},

	setupController: function(controller, model) {

		this._super(controller, model);

		// They correspond to existing short circuit plan arrays.
		var existingMedicalPlans, existingAdditionalMedicalPlans, existingDentalPlans, existingVisionPlans;
		var existingLifePlans, existingStdPlans, existingLtdPlans;
		if (this.getEnrollmentType() == 'OE') {
			existingMedicalPlans = controller.get('oeMedicalShortCircuitPlans');
			existingAdditionalMedicalPlans = controller.get('oeAdditionalMedicalShortCircuitPlans');
			existingDentalPlans = controller.get('oeDentalShortCircuitPlans');
			existingVisionPlans = controller.get('oeVisionShortCircuitPlans');
			existingLifePlans = controller.get('oeLifeShortCircuitPlans');
			existingStdPlans = controller.get('oeStDisabilityShortCircuitPlans');
			existingLtdPlans = controller.get('oeLtDisabilityShortCircuitPlans');
		}
		else {
			existingMedicalPlans = controller.get('borMedicalShortCircuitPlans');
			existingAdditionalMedicalPlans = controller.get('borAdditionalMedicalShortCircuitPlans');
			existingDentalPlans = controller.get('borDentalShortCircuitPlans');
			existingVisionPlans = controller.get('borVisionShortCircuitPlans');
			existingLifePlans = controller.get('borLifeShortCircuitPlans');
			existingStdPlans = controller.get('borStDisabilityShortCircuitPlans');
			existingLtdPlans = controller.get('borLtDisabilityShortCircuitPlans');
		}

		// Create the array of objects for medical, dental, vision, life, std, and ltd plans.
		var medicalPlans = Ember.A();
		var additionalMedicalPlans = Ember.A();
		var dentalPlans = Ember.A();
		var visionPlans = Ember.A();
		var lifePlans = Ember.A();
		var stdPlans = Ember.A();
		var ltdPlans = Ember.A();

		controller.set('medicalPlans', medicalPlans);
		controller.set('additionalMedicalPlans', additionalMedicalPlans);
		controller.set('dentalPlans', dentalPlans);
		controller.set('visionPlans', visionPlans);
		controller.set('lifePlans', lifePlans);
		controller.set('stdPlans', stdPlans);
		controller.set('ltdPlans', ltdPlans);

		if (controller.get('medicalCarrierName')) {
			existingMedicalPlans.forEach(function(shortCircuitPlan) {
				medicalPlans.addObject(shortCircuitPlan);
			}.bind(this));
			if (!medicalPlans.length) {
				controller.addPlan('medical');
			}
		}

		if (controller.get('additionalMedicalCarrierName')) {
			existingAdditionalMedicalPlans.forEach(function(shortCircuitPlan) {
				additionalMedicalPlans.addObject(shortCircuitPlan);
			}.bind(this));
			if (!additionalMedicalPlans.length) {
				controller.addPlan('additionalMedical');
			}
		}

		if (controller.get('dentalCarrierName')) {
			existingDentalPlans.forEach(function(shortCircuitPlan) {
				dentalPlans.addObject(shortCircuitPlan);
			}.bind(this));
			if (!dentalPlans.length) {
				controller.addPlan('dental');
			}
		}

		if (controller.get('visionCarrierName')) {
			existingVisionPlans.forEach(function(shortCircuitPlan) {
				visionPlans.addObject(shortCircuitPlan);
			}.bind(this));
			if (!visionPlans.length) {
				controller.addPlan('vision');
			}
		}

		if (controller.get('lifeCarrierName')) {
			existingLifePlans.forEach(function (shortCircuitPlan) {
				lifePlans.addObject(shortCircuitPlan);
			});
			if (!lifePlans.length) {
				controller.addPlan('lifenew');
			}
		}

		if (controller.get('stdCarrierName')) {
			existingStdPlans.forEach(function (shortCircuitPlan) {
				stdPlans.addObject(shortCircuitPlan);
			});
			if (!stdPlans.length) {
				controller.addPlan('std');
			}
		}

		if (controller.get('ltdCarrierName')) {
			existingLtdPlans.forEach(function (shortCircuitPlan) {
				ltdPlans.addObject(shortCircuitPlan);
			});
			if (!ltdPlans.length) {
				controller.addPlan('ltd');
			}
		}
	},

	// We cannot use the rollback route for this since our data is not exactly in model.
	// We need to rollback all the dirty array objects so we are implementing our own
	// deactivate function.
	deactivate: function() {
		this._super();

		var controller = this.get('controller');
		controller.rollbackUncommittedPlans('medical');
		controller.rollbackUncommittedPlans('additionalMedical');
		controller.rollbackUncommittedPlans('dental');
		controller.rollbackUncommittedPlans('vision');
		controller.rollbackUncommittedPlans('lifenew');
		controller.rollbackUncommittedPlans('std');
		controller.rollbackUncommittedPlans('ltd');
	},
});

App.SetupConfigureschController = Ember.ObjectController.extend(zen._BoRGamificationControllerUtils, zen._SetupUtilMixin, {

	displayLineOfCoverage: function(lineOfCoverage) {
		// unfortunately we have to deal with L&D lines of coverage
		switch(lineOfCoverage) {
			case 'lifenew':
				return 'life';

			case 'std':
				return 'STD';

			case 'ltd':
				return 'LTD';

			default:
				return lineOfCoverage;
		}
	},

	isMDV: function(lineOfCoverage) {
		return lineOfCoverage == 'medical' ||
			lineOfCoverage == 'additionalMedical' ||
			lineOfCoverage == 'dental' ||
			lineOfCoverage == 'vision';
	},

	isLnD: function(lineOfCoverage) {
		return lineOfCoverage == 'lifenew' ||
			lineOfCoverage == 'std' ||
			lineOfCoverage == 'ltd';
	},

	addPlan: function(lineOfCoverage) {
		this.clearErrorText();
		var plans = this.getAppropriatePlan(lineOfCoverage);
		plans.addObject(App.ShortCircuitPlan.createRecord());
	},

	//
	// Given the line of coverage arg (medical, additionalMedical, dental, vision)
	// this will return the appropriate plan object array.
	//
	getAppropriatePlan : function(lineOfCoverage) {
		switch(lineOfCoverage) {
			case 'medical':
				return this.get('medicalPlans');
			case 'additionalMedical':
				return this.get('additionalMedicalPlans');
			case 'dental':
				return this.get('dentalPlans');
			case 'vision':
				return this.get('visionPlans');
			case 'lifenew':
				return this.get('lifePlans');
			case 'std':
				return this.get('stdPlans');
			case 'ltd':
				return this.get('ltdPlans');
		}
	},

	// Deletes the last plan in the line of coverage. It performs bunch of checks and makes sure
	// the last plan is eligible for deletion. The order would be XXXXYYYY where X would be the plans
	// not eligible for deletion and Ys are plans whihc are eligible. So it is fine to just check
	// the last plan and delete.
	deletePlan: function(lineOfCoverage) {
		this.clearErrorText();
		var plans = this.getAppropriatePlan(lineOfCoverage);
		var deletePlanErrorMessage = this.deletePlanErrorMsg(plans, lineOfCoverage);
		if (deletePlanErrorMessage) {
			this.set('errorText', deletePlanErrorMessage);
			return;
		}
		var toDel = plans[plans.length - 1];
		plans.removeAt(plans.length - 1);
		toDel.deleteRecord();
	},

	// This will perform validations and makes sure the last plan in the line of coverage is
	// eligible for deletion. This will return error message if the plan is not eligible for
	// deletion. So, if the error message(return value) is empty, then it means that the last plan
	// in the line of coverage is eligible for deletion.
	deletePlanErrorMsg: function(plans, lineOfCoverage) {
		if (plans.length == 1) {
			return "Cannot delete the only " + this.displayLineOfCoverage(lineOfCoverage) + " plan";
		}
		if (plans[plans.length - 1].get('id')) {
			//XXX: Small copy required.
			return 'Sorry! Cannot delete existing plan.';
		}
	},

	rollbackUncommittedPlans: function(lineOfCoverage) {
		this.clearErrorText();
		var plans = this.getAppropriatePlan(lineOfCoverage);
		// Delete till we find a plan id. If the plan has plan id, then it is in database.
		// Don't delete them, but rollback (which is exactly what we are doing in the next for loop)
		while(plans.length) {
			var toDel = plans[plans.length - 1];
			if (toDel.get('id')) {
				break;
			}
			plans.removeAt(plans.length - 1);
			toDel.deleteRecord(); // Uncommitted records will definitely be dirty. So isDirty is not needed.
		}
		// This can happen when the user is skipping the step after editing some plans.
		for(var i = 0; i < plans.length; i++) {
			if (plans[i].get('isDirty')) {
				plans[i].rollback();
			}
		}
	},


	//
	// The corresponding enrollment should be in switched state and those enrollment
	// should not have any plans to it. Then only, we return the corresponding carrier
	// name. Once the SCH plan is set, the user cannot add/delete/edit those plans.
	// So, in that case, all the below functions will return empty string which will then
	// be interpreted by other ember components as if there are no corresponding LoC carriers
	// present.
	//
	medicalCarrierName: function() {
		// If the company doesn't have OE enrollment and we are in the OE route. The borMedicalEnrollment
		// would have been actually for BoR and not for short circuit OE.
		// this.getEnrollmentType will say if we are in the OE route.
		if (this.get('borMedicalEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borMedicalEnrollment.enrollmentStatus') == "switched" &&
			this.get('borMedicalHealthCarrier.status') != 'approved')
		{
				return this.get('borMedicalHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borMedicalEnrollment.enrollmentStatus',
				 'borMedicalHealthCarrier.status',
				 'borMedicalEnrollment.enrollmentType'),

	additionalMedicalCarrierName: function() {
		if (this.get('borAdditionalMedicalEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borAdditionalMedicalEnrollment.enrollmentStatus') == "switched" &&
			this.get('borAdditionalMedicalHealthCarrier.status') != 'approved')
		{
			return this.get('borAdditionalMedicalHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borAdditionalMedicalEnrollment.enrollmentStatus',
				 'borAdditionalMedicalHealthCarrier.status',
				 'borAdditionalMedicalEnrollment.enrollmentType'),

	dentalCarrierName: function() {
		if (this.get('borDentalEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borDentalEnrollment.enrollmentStatus') == "switched" &&
			this.get('borDentalHealthCarrier.status') != 'approved')
		{
			return this.get('borDentalHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borDentalEnrollment.enrollmentStatus',
				 'borDentalEnrollment.enrollmentType',
				 'borDentalHealthCarrier.status'),

	visionCarrierName: function() {
		if (this.get('borVisionEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borVisionEnrollment.enrollmentStatus') == "switched" &&
			this.get('borVisionHealthCarrier.status') != 'approved')
		{
			return this.get('borVisionHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borVisionEnrollment.enrollmentStatus',
				 'borVisionEnrollment.enrollmentType',
				 'borVisionHealthCarrier.status'),

	lifeCarrierName: function() {
		if (this.get('borLifeEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borLifeEnrollment.enrollmentStatus') == "switched" &&
			this.get('borLifeHealthCarrier.status') != 'approved')
		{
			return this.get('borLifeHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borLifeEnrollment.enrollmentStatus',
				 'borLifeEnrollment.enrollmentType',
				 'borLifeHealthCarrier.status'),

	stdCarrierName: function() {
		if (this.get('borStDisabilityEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borStDisabilityEnrollment.enrollmentStatus') == "switched" &&
			this.get('borStDisabilityHealthCarrier.status') != 'approved')
		{
			return this.get('borStDisabilityHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borStDisabilityEnrollment.enrollmentStatus',
				 'borStDisabilityEnrollment.enrollmentType',
				 'borStDisabilityHealthCarrier.status'),

	ltdCarrierName: function() {
		if (this.get('borLtDisabilityEnrollment.enrollmentType') == 'BoR' &&
			this.getEnrollmentType() != 'BoR') {
			return "";
		}
		if (this.get('borLtDisabilityEnrollment.enrollmentStatus') == "switched" &&
			this.get('borLtDisabilityHealthCarrier.status') != 'approved')
		{
			return this.get('borLtDisabilityHealthCarrier.carrier.displayName');
		}
		return "";
	}.property('borLtDisabilityEnrollment.enrollmentStatus',
				 'borLtDisabilityEnrollment.enrollmentType',
				 'borLtDisabilityHealthCarrier.status'),

	 // Checks if any one medical plan is present
	isMedicalPlanPresent: Ember.computed.or('additionalMedicalCarrierName', 'medicalCarrierName'),

	schDone: function() {
		this.clearErrorText();
		var allLocValidations =
			this.validateAndPrepare('medical') &&
			this.validateAndPrepare('additionalMedical') &&
			this.validateAndPrepare('dental') &&
			this.validateAndPrepare('vision') &&
			this.validateAndPrepare('lifenew') &&
			this.validateAndPrepare('std') &&
			this.validateAndPrepare('ltd');

		if (allLocValidations) {
			this.clearErrorText();
			this.saveAndTransitionTo();
		}
	},

	getHealthCarrier: function(lineOfCoverage) {
		switch (lineOfCoverage) {
			case 'medical':
				return this.get('borMedicalHealthCarrier');
			case 'additionalMedical':
				return this.get('borAdditionalMedicalHealthCarrier');
			case 'dental':
				return this.get('borDentalHealthCarrier');
			case 'vision':
				return this.get('borVisionHealthCarrier');
			case 'lifenew':
				return this.get('borLifeHealthCarrier');
			case 'std':
				return this.get('borStDisabilityHealthCarrier');
			case 'ltd':
				return this.get('borLtDisabilityHealthCarrier');
		}
	},

	// Since our model object is not the actual model we operate in, we have do explicitly save.
	// Call this only after all the validations are done. This will save all the plan(medical,addnMdcl,dental,vision)
	// array objects and will RSVP and will transition to the next route.

	// This is responsible for generating the promise array after saving the array objects. Please note that though
	// the controller is object controller, we use a synthetic array of type ShortCircuitPlan. This shortCircuitPlan
	// exists only in api and npt a real object. Since we have to deal with plan,dentalPlan,visionPlan(hybrid object array),
	// we chose this approach.

	// This will invoke the show loading screen. So whoever is on the other end should close it
	// appropriately.
	saveAndTransitionTo: function() {
		var promiseArray = [];
		var linesOfCoverage = ['medical', 'additionalMedical', 'dental', 'vision', 'lifenew', 'std', 'ltd'];
		var numStepsCompleted = 0;
		var scoeAdminSignOff = this.get('scoeAdminSignOff');
		var pureBoRLinesWithFirstPlans = [];
		var scoeStats = this.get('shortCircuitOEStats');
		for (var i = 0; i < linesOfCoverage.length; i++) {
			var plans = this.getAppropriatePlan(linesOfCoverage[i]);
			var borHealthCarrier = this.getHealthCarrier(linesOfCoverage[i]);
			if (plans.length) {
				numStepsCompleted++;

				if (borHealthCarrier.get('companyHealthEnrollment.enrollmentType') == 'BoR' &&
					Ember.isEmpty(borHealthCarrier.get('companyHealthPlans')) &&
					!scoeStats.get(linesOfCoverage[i] + 'ShortCircuitOEEligible'))
				{
					pureBoRLinesWithFirstPlans.pushObject(borHealthCarrier.get('lineOfCoverage'));
				}
			}
			for (var j = 0; j < plans.length; j++) {
				if (plans[j].get('isDirty')) {
					promiseArray.push(plans[j].save());
					// Mark the SCOE admin sign as inactive, so that we will force admin to review and sign it again.
					if (borHealthCarrier && borHealthCarrier.get('invalidateSCOEAdminSignOnChange')) {
						scoeAdminSignOff.set('isActive', false);
					}
				}
			}
		}

		if (scoeAdminSignOff.get('isDirty')) {
			promiseArray.push(scoeAdminSignOff.save());
			this.get('borCompanyNav').mandateSCOEAdminSignOffRoute();
		}

		if (numStepsCompleted) {
			this.setNumStepsCompletedForThisRoute(numStepsCompleted);
			if (this.get('borCompanyNav.isDirty')) {
				promiseArray.push(this.get('borCompanyNav').save());
			}
		}

		if (App.switches.isActive('bor.beta')) {
			this.eventLogger.log('bor_beta_flow', {action: 'save', current_route: 'current-plans'});
		}

		// Attempt to sign off lines.
		if (!Ember.isEmpty(pureBoRLinesWithFirstPlans)) {
			promiseArray.push(Ember.ajaxPost('/custom_api/bor_admin_sign_off_post_action/', {linesOfCoverage: pureBoRLinesWithFirstPlans.join(',')}));
		}

		promiseArray.push(App.CompanyEnrollmentBorApi.find('me').reload());
		this.showLoadingScreen("Adding health plans", "Please wait while we add health plans. <br><br> This might take up to 10 seconds.");
		Ember.RSVP.all(promiseArray).then(function() {
			return this._nextRoute();
		}.bind(this));
	},

	validateAndPrepare: function(lineOfCoverage) {
		var plans;
		var carrierName; // Corresponding medical/dental/vision carrier name.
		var displayName;
		switch (lineOfCoverage) {
			case 'medical':
				plans = this.get('medicalPlans');
				carrierName = this.get('borMedicalHealthCarrier.carrier.name');
				displayName = this.get('borMedicalHealthCarrier.carrier.displayName');
				break;
			case 'additionalMedical':
				plans = this.get('additionalMedicalPlans');
				carrierName = this.get('borAdditionalMedicalHealthCarrier.carrier.name');
				displayName = this.get('borAdditionalMedicalHealthCarrier.carrier.displayName');
				break;
			case 'dental':
				plans = this.get('dentalPlans');
				carrierName = this.get('borDentalHealthCarrier.carrier.name');
				displayName = this.get('borDentalHealthCarrier.carrier.displayName');
				break;
			case 'vision':
				plans = this.get('visionPlans');
				carrierName = this.get('borVisionHealthCarrier.carrier.name');
				displayName = this.get('borVisionHealthCarrier.carrier.displayName');
				break;
			case 'lifenew':
				plans = this.get('lifePlans');
				carrierName = this.get('borLifeHealthCarrier.carrier.name');
				displayName = this.get('borLifeHealthCarrier.carrier.displayName');
				break;
			case 'std':
				plans = this.get('stdPlans');
				carrierName = this.get('borStDisabilityHealthCarrier.carrier.name');
				displayName = this.get('borStDisabilityHealthCarrier.carrier.displayName');
				break;
			case 'ltd':
				plans = this.get('ltdPlans');
				carrierName = this.get('borLtDisabilityHealthCarrier.carrier.name');
				displayName = this.get('borLtDisabilityHealthCarrier.carrier.displayName');
				break;
		}
		for (var i = 0; i < plans.length; i++) {
			var plan = plans[i];

			// General Validations
			if (!plan.get('displayName')) {
				this.set('hasMissingValues', true);
				this.set('errorText', "Please specify name of the plan");
				return false;
			}

			// Loc Validation
			if (this.isMDV(lineOfCoverage)) {
				if (!plan.get('HMOPPO')) {
					this.set('errorText', "Please specify the HMO/PPO type for the " + this.displayLineOfCoverage(lineOfCoverage) + " plan");
					return false;
				}
			} else if (this.isLnD(lineOfCoverage)) {
				if (!plan.get('planType')) {
					this.set('errorText', "Please select a plan type for the " + this.displayLineOfCoverage(lineOfCoverage) + " plan");
					return false;
				}
			}

			// General Preparation
			plan.set('carrier', displayName);
			plan.set('lineOfCoverage', lineOfCoverage);
			plan.set('enrollmentType', this.getEnrollmentType());

			// LoC Preparation
			if (this.isMDV(lineOfCoverage)) {
				// Prepend plan name for clarity
				if (!plan.get('name')) {
					// plan name - 100 chars. id can be 7, two underscores, display name can be 50, carrier name can be 35, still won't exceed 100 characters.
					// Even when it exceeds, it will be truncated by our mysql.
					plan.set('name', this.get('id') + "_" + carrierName + "_" + plan.get('displayName'));
				}

				// HMO Plans will not have HSA
				if (plan.get('HMOPPO') == "HMO") {
					plan.set('HSA', false);
				}

				// Vision plans have no HMO.
				if (plan.get('lineOfCoverage') == 'vision') {
					plan.set('HMOPPO', 'PPO');
				}
			} else if (this.isLnD(lineOfCoverage)) {
				// Sync name and display name (LnD only depends on name field)
				plan.set('name', plan.get('displayName'));
			}
		}
		return true;
	},

	showWarningForHmoPlan: function() {
		this.send('showModal', 'setup.configuresch.hmoplanwarning');
	},

	clearErrorText : function() { this.set('hasMissingValues', false); this.set('errorText', ''); }
});

App.SetupConfigureschHmoplanwarningController = Ember.Controller.extend({
	actions: {
		okay: function() {
			this.send('hideModal');
		},
		cancel: function() {
			this.send('hideModal');
		},
	},
});

zen._SetupConfigureschborUtilMixin = Ember.Mixin.create({

	getEnrollmentType: function() { return 'BoR'; },
	// This can be invoked anywhere after the 'model' hook. So, this is bascically for 'afterModel' and 'setupController' and anywhere in the controller.
	_nextRoute: function() {
		Ember.ajaxPost('/custom_api/schEmployerNotify', {enrollmentType: 'BoR'});
		var promise = this.setAndSaveBoRStatus("current-plan");
		promise.then(function(resolvedPromise) {
			this.closeLoadingScreen(this.get('nextEmberRouteName'));
			return;
		}.bind(this));
		return true;
	},
});

zen._SetupConfigureschoeUtilMixin = Ember.Mixin.create({
	getEnrollmentType: function() { return 'OE'; },

	_nextRoute: function() {
		Ember.ajaxPost('/custom_api/schEmployerNotify', {enrollmentType: 'BoR'});
		var promise = this.setAndSaveBoRStatus("new-plans");
		promise.then(function(resolvedPromise) {
			this.closeLoadingScreen(this.get('nextEmberRouteName'));
		}.bind(this));
		return true;
	}
});

App.SetupConfigureschborRoute = App.SetupConfigureschRoute.extend(zen._SetupConfigureschborUtilMixin, {

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'current-plans';
		this._super(controller, model);
	},

});

App.SetupConfigureschoeRoute = App.SetupConfigureschRoute.extend(zen._SetupConfigureschoeUtilMixin, {

	getEnrollmentType: function() { return 'OE'; },

	beforeModel: function() {
		// Some carriers are short circuit eligible. Now, invoke the short circuit oe employer url
		// which creates OE as required. Then we can transform into the short circuit oe page.
		var promise = new Ember.RSVP.Promise(function(resolve, reject) {
			Ember.ajaxPost('/custom_api/short_circuit_oe_create/').then(function(response) {
				resolve(1);
			});
		}.bind(this));

		return promise.then(function(resolvedP) {
			return App.CompanyEnrollmentBorApi.find('me').then(function(resolvedPromise) {
				return resolvedPromise.reload();
			}.bind(this));
		});
	},

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'new-plans';
		this._super(controller, model);
	},
});

// Things are inherited rom borUtilMixin...
App.SetupConfigureschborController = App.SetupConfigureschController.extend(zen._SetupConfigureschborUtilMixin, zen._LoadingScreenMixin, {

	shouldShowOECopy: function() {
		if (this.get('shortCircuitOEStats.shortCircuitOEEligible')) {
			// Short circuit BoR page runs for specific lines of coverage, ones which are not updated yet! So, check if those lines of
			// coverage are eligible for short circuit OE.
			var updatingMedicalCarrierWithShortcircuitOE = this.get('medicalCarrierName') && this.get('shortCircuitOEStats.medicalShortCircuitOEEligible');
			var updatingAdditionalMedicalCarrierWithShortcircuitOE = this.get('additionalMedicalCarrierName') && this.get('shortCircuitOEStats.additionalMedicalShortCircuitOEEligible');
			var updatingDentalCarrierWithShortcircuitOE = this.get('dentalCarrierName') && this.get('shortCircuitOEStats.dentalShortCircuitOEEligible');
			var updatingVisionCarrierWithShortcircuitOE = this.get('visionCarrierName') && this.get('shortCircuitOEStats.visionShortCircuitOEEligible');
			// don't exist yet, but should return false properly
			var updatingLifeCarrierWithShortcircuitOE = this.get('lifeCarrierName') && this.get('shortCircuitOEStats.lifeShortCircuitOEEligible');
			var updatingStdCarrierWithShortcircuitOE = this.get('stdCarrierName') && this.get('shortCircuitOEStats.stdShortCircuitOEEligible');
			var updatingLtdCarrierWithShortcircuitOE = this.get('ltdCarrierName') && this.get('shortCircuitOEStats.ltdShortCircuitOEEligible');

			if (updatingMedicalCarrierWithShortcircuitOE ||
				updatingAdditionalMedicalCarrierWithShortcircuitOE ||
				updatingDentalCarrierWithShortcircuitOE ||
				updatingVisionCarrierWithShortcircuitOE ||
				updatingLifeCarrierWithShortcircuitOE ||
				updatingStdCarrierWithShortcircuitOE ||
				updatingLtdCarrierWithShortcircuitOE)
			{
				return true;
			}
		}
		return false;
	}.property('shortCircuitOEStats', 'medicalCarrierName', 'additionalMedicalCarrierName', 'dentalCarrierName', 'visionCarrierName', 'lifeCarrierName', 'stdCarrierName', 'ltdCarrierName'),

	heading: function() {
		if (App.switches.isActive('bor.beta')) {
			return "Enabling hiring today";
		}
		if (this.get('shouldShowOECopy')) {
			return "Want Zenefits to handle your Open Enrollment ? ";
		}
		return "Want to offer your employees online health insurance enrollment right away?";
	}.property('shouldShowOECopy'),

	descriptionCopy: function() {
		if (App.switches.isActive('bor.beta')) {
			return '<p class="subhead"> Enter in your plans, and we\'ll take care of the rest. Using this information, your new hires will be able to onboard and enroll in these insurance plans through Zenefits.</p>';
		}
		if (this.get('shouldShowOECopy')) {
			// Short circuit BoR page runs for specific lines of coverage, ones which are not updated yet! So, check if those lines of
			// coverage are eligible for short circuit OE.
			var updatingMedicalCarrierWithShortcircuitOE = this.get('medicalCarrierName') && this.get('shortCircuitOEStats.medicalShortCircuitOEEligible');
			var updatingAdditionalMedicalCarrierWithShortcircuitOE = this.get('additionalMedicalCarrierName') && this.get('shortCircuitOEStats.additionalMedicalShortCircuitOEEligible');
			var updatingDentalCarrierWithShortcircuitOE = this.get('dentalCarrierName') && this.get('shortCircuitOEStats.dentalShortCircuitOEEligible');
			var updatingVisionCarrierWithShortcircuitOE = this.get('visionCarrierName') && this.get('shortCircuitOEStats.visionShortCircuitOEEligible');

			if (updatingMedicalCarrierWithShortcircuitOE || updatingAdditionalMedicalCarrierWithShortcircuitOE ||
				updatingDentalCarrierWithShortcircuitOE || updatingVisionCarrierWithShortcircuitOE) {
				var renewalDate = this.get('shortCircuitOEStats.medicalRenewalDate') || this.get('shortCircuitOEStats.additionalMedicalRenewalDate') || this.get('shortCircuitOEStats.dentalRenewalDate') || this.get('shortCircuitOEStats.visionRenewalDate');
				renewalDate = moment(renewalDate);
				renewalDate = renewalDate.format('MMMM Do');
				return '<p class="subhead"> Your open enrollment is less than 8 weeks away &mdash; ' + renewalDate + ' to be exact. That said, it normally takes health insurance carriers 4-8 weeks to import everyone\'s health insurance information into Zenefits, which means the carriers may not give us all the information we need in time for your upcoming open enrollment. Not to worry though! With your help, we can bypass this 4-8 week waiting period and help manage your upcoming open enrollment, despite the carriers slow turn around. </p> <p class="subhead"> Simply enter the current carriers and plans your company offers, and we\'ll have everything we need to help administer your open enrollment on ' + renewalDate + '!</p>';
			}
		}
		return '<p class="subhead"> It normally takes health insurance carriers 4-8 weeks to import all your employees\' health insurance data into Zenefits, which means your employees (including new hires) won\'t be able to enroll in health insurance through our online enrollment system for up to 8 weeks &mdash; until the insurance carriers send us all the data we need. You can bypass this 4-8 week waiting period and offer your employees online health insurance enrollment right away, by entering the current carriers and plans your company offers below.</p> <p class="subhead"> Once you\'ve given us this information, your employees (including new hires) will be able to view and enroll in health insurance online through Zenefits. After the insurance carriers finish importing all your data into Zenefits, the plans below will automatically be updated with the associated plan PDFs and pricing. In the meantime, any hires you make during this 4-8 week waiting period will be instructed to contact their HR administrator (you) for plan PDFs and pricing (if needed).';
	}.property('shouldShowOECopy'),

	setupController: function(controller, model) {
		this._super(controller, model);
	},
});

App.SetupConfigureschoeController = App.SetupConfigureschController.extend( zen._SetupConfigureschoeUtilMixin, zen._LoadingScreenMixin, {
	nextYear: function() {
		var today = moment();
		var nextYear = today.year() + 1;
		// If month is < 2, (it is Jan), then use the current year. Else use next year.
		if (today.month() < 2) {
			nextYear--;
		}
		return nextYear;
	},
	heading: function() {
		var nextYear = this.nextYear();
		return 'Tell us your new insurance plan offerings in ' + nextYear;
	}.property(),

	descriptionCopy: function() {
		var nextYear = this.nextYear();
		return '<p class="subhead"> Please tell us about your new plans in ' + nextYear + ', either in addition or as a replacement to your current plans.</p><p class="subhead"> Moving forward, your employees will be able to view and enroll in these health insurance plans through zenefits in their open enrollment period. Once the insurance carriers finish importing all your data into Zenefits, the plans you entered will be automatically updated with the associated plan PDFs and pricing. In the meantime, your employees will be instructed to contact their HR administrator (you) for plan PDFs and pricing (if needed). </p>';
	}.property(),
});

App.SetupConfigureschoeView = Ember.View.extend({
	templateName: 'setup/configuresch'
});

App.SetupConfigureschborView = Ember.View.extend({
	templateName: 'setup/configuresch'
});


App.SetupCarrierdataRoute = zen._BoRPostSignChildRoute.extend(zen._AddExistingUniqueCarrierMixin, {
	model: function(params) {
		return this._super().then(function(resolvedModel) {
			//resolvedModel.reload();
			this.otherModels['operatingCarrierId'] = params.carrierid;
			var parentPortalStatPromise = App.CarrierAndAdminCredentialStat.find({"isGroupCredential":false, "carriers":params.carrierid, "portalType":"Single", "subType":"Admin", "isActive":true});
			var groupPortalStatPromise = App.CarrierAndAdminCredentialStat.find({"isGroupCredential":true, "carriers":params.carrierid, "portalType":"Single", "subType":"Admin", "companyHealthCarriers__company_id":resolvedModel.get('id'), "isActive":true});
			var parentPortalPromise = parentPortalStatPromise.then(function(portalIds){
				var portalId = portalIds.mapBy('id');
				return App.CarrierAndAdminCredential.find(portalId);
			});

			var parentCredentialPromise = parentPortalPromise.then(function(portal){
				var portalId = portal.get('firstObject').get('id');
				return App.PortalCredential.find({
					"portal_id": portalId,
					"isActive": true
				});
			});
			var groupPortalPromise = groupPortalStatPromise.then(function(portalIds){
				var portalId = portalIds.mapBy('id');
				return App.CarrierAndAdminCredential.find(portalId);
			});

			var adminCredentialPromise = groupPortalPromise.then(function(portal){
				var firstObject = portal.get('firstObject');
				if (firstObject) {
					return App.PortalCredential.find({
						"portal_id": firstObject.get('id'),
						"isActive": true
					}).then(function(credential) {
						return credential.get('firstObject');
					});
				} else {
					return null;
				}
			});
			var securityQuestionsPromise = groupPortalPromise.then(function(portal){
				var firstObject = portal.get('firstObject');
				if (firstObject) {
					return App.AdditionalSecurityQuestion.find({"portal_id":firstObject.get('id'), "isActive":true});
				}
				else {
					return Ember.A();
				}
			});
			// HACK HACK KICKASSHACK. No way to force reloading the route in our version of
			// ember. Even when we do window.location, it will not call setupController since
			// the model is unchanged. So, do this so that everytime ember thinks it is a different
			// model.
			//resolvedModel.set('parentPortal', parentPortalPromise);
			return Ember.RSVP.hash({
				model: resolvedModel,
				parentPortal: parentPortalPromise.then(function(portal) {return portal.get('firstObject');}),
				parentCredential: parentCredentialPromise.then(function(credential) { return credential.get('firstObject');}),
				groupPortal: groupPortalPromise.then(function(portal) {return portal.get('firstObject');}),
				adminCredential: adminCredentialPromise,
				securityQuestions: securityQuestionsPromise,
				operatingCarrier: App.Carrier.find(params.carrierid),

			});
		}.bind(this));
	},
	afterModel: function(model) {
		this.otherModels['operatingCarrier'] = model.operatingCarrier;
		// populates all company health carriers which use the current operating carrier id.
		var consideredCompanyHealthCarriers = [];

		this.otherModels.borHealthCarrierProperties.forEach(function (borHealthCarrierPropertyStr) {
			var borHealthCarrier = this.otherModels[borHealthCarrierPropertyStr];
			if (borHealthCarrier) {
				// Non-approved switched company health carriers are considered.
				if (borHealthCarrier.get('carrier.id') == this.otherModels.operatingCarrierId && borHealthCarrier.get('status') != 'approved') {
					consideredCompanyHealthCarriers.push(borHealthCarrier);
				}
			}
		}.bind(this));
		this.otherModels['consideredCompanyHealthCarriers'] = consideredCompanyHealthCarriers;
	},

	setupController: function(controller, model) {
		// HACK HACK KICKASSHACK Undo the hack in the model.
		var parentPortal = model.parentPortal;
		var groupPortal = model.groupPortal;
		var adminCredential = model.adminCredential;
		var securityQuestions = model.securityQuestions;
		var parentCredential = model.parentCredential;
		model = model.model;
		var operatingCarrier = this.otherModels['operatingCarrier'];
		// Example route name: Anthem-Blue-Cross-CA
		var routeName = ((operatingCarrier.get('displayName') + " " + operatingCarrier.get('state')).split(" ")).join("-");
		controller['rawRouteName'] = routeName;
		this._super(controller, model);
		controller.set('showFinishLaterModal', false);

		controller.set('securityQuestions', securityQuestions);

		if (!groupPortal) {
			groupPortal = App.CarrierAndAdminCredential.createRecord({
				carrier: operatingCarrier,
				portalType: parentPortal.get('portalType'),
				subType: parentPortal.get('subType'),
				linesOfCoverage: parentPortal.get('linesOfCoverage'),
				functionality: parentPortal.get('functionality'),
				requireCommissionsCredentials: parentPortal.get('requireCommissionsCredentials'),
				requireSecurityQuestions: parentPortal.get('requireSecurityQuestions'),
				requireMultiplePassword: parentPortal.get('requireMultiplePassword'),
				isGroupCredential: true,
				verificationStatus: 'Unverified',
				additionalSecurityQuestionsCount: parentPortal.get('additionalSecurityQuestionsCount'),
				carrierId: operatingCarrier.get('id'),
				statesApplicable: [operatingCarrier.get('state'), ],
				parentPortalId: parentPortal.get('id'),
				isActive: true,
			});
		}

		securityQuestions.forEach(function(question){
			question.set('securityAnswer', null);
			question.set('isNewQuestion', false);
			question.set('placeholder', 'We have your answer for this question.');
		});
		controller.set('portalRecord', groupPortal);

		if (!adminCredential) {
			adminCredential = App.PortalCredential.createRecord({
				isActive: true,
				url: parentCredential.get('url'),
			});
		}

		controller.set('portalCredential', adminCredential);

		var addedGroup = Ember.Object.create({
			id: model.get('id'),
			name: model.get('name'),
		});
		controller.set('addedGroup', addedGroup);

		controller.set('hasOnlineAccessCredentials', false);
		if (adminCredential.get('primaryUsername') && adminCredential.get('primaryPassword') && groupPortal.get('verificationStatus') != 'Broken') {
			controller.set('hasOnlineAccessCredentials', true);
			if (groupPortal.get('requireMultiplePassword') && !adminCredential.get('additionalPassword')) {
				controller.set('hasOnlineAccessCredentials', false);
			}
		}
		adminCredential.set('primaryUsername', null);
		adminCredential.set('primaryPassword', null);
		adminCredential.set('additionalPassword', null);

		controller.set('buttonText', 'Save And Continue');
		controller.set('buttonClass', 'button');
		controller.set('buttonClassBack', 'button grey');
	},

	actions: {
		willTransition: function() {
			var portalCredential = this.controller.get('portalCredential');
			var portalRecord = this.controller.get('portalRecord');
			var securityQuestions = this.controller.get('securityQuestions');
			securityQuestions.forEach(function(question){
				if (question.get('isModified') || question.get('isNew')) {
					question.rollback();
				}
			});
			if (portalCredential.get('isModified') || portalCredential.get('isNew')) {
				portalCredential.rollback();
			}

			if (portalRecord.get('isModified') || portalRecord.get('isNew')) {
				portalRecord.rollback();
			}
			this.controller.set('errorText', '');

		}
	},
});


App.SetupCarrierdataController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._PublicFilePickerMixin, {
	operatingCarrier: null,
	operatingCarrierId: null,
	errorText: null,
	showOnlineOrInvoice: true,
	showFinishLaterModal: false,
	invoiceUrl: null,

	deletedQuestions: function() {
		return Ember.A();
	}.property(),
	reset: function() {
		this.set('userName', null);
		this.set('password', null);
		this.set('invoiceUrl', this.get('consideredCompanyHealthCarriers').get('firstObject').get('invoiceUrl'));
		this.set('showOnlineOrInvoice', true);
	}.observes('operatingCarrier'),
	// Some copy depends on this property. For second BoR online access route, we omit some redundant infos.
	carrierName: function() {
		return this.get('operatingCarrier.displayName');
	}.property('operatingCarrier.displayName'),
	usernamePlaceholder: function() {
		if (this.get('hasOnlineAccessCredentials')) {
			return 'We have your ' + this.get('carrierName') + ' username.';
		}
		return 'Please enter your ' + this.get('carrierName') + ' username.';
	}.property('hasOnlineAccessCredentials', 'carrierName'),

	passwordPlaceholder: function() {
		if (this.get('hasOnlineAccessCredentials')) {
			return 'We have your ' + this.get('carrierName') + ' password.';
		}
		return 'Please enter your ' + this.get('carrierName') + ' password.';
	}.property('hasOnlineAccessCredentials', 'carrierName'),

	additionalPasswordPlaceholder: function() {
		if (this.get('hasOnlineAccessCredentials')) {
			return 'We have your ' + this.get('carrierName') + ' secondary password.';
		}
		return 'Please enter your ' + this.get('carrierName') + ' secondary password.';
	}.property('hasOnlineAccessCredentials', 'carrierName'),

	securityQuestionsPresent: function() {
		var requireSecurityQuestions = this.get('portalRecord.requireSecurityQuestions');
		if (requireSecurityQuestions) {
				if (this.get('securityQuestions') && this.get('securityQuestions').get('length') === 0) {
					this.get('securityQuestions').pushObject(App.AdditionalSecurityQuestion.createRecord({
						securityQuestion: '',
						securityAnswer: '',
						isActive: true,
						isNewQuestion: true,
						placeholder: 'Please Enter your answer.',
					}));
				}
		}
		else {
			if (this.get('securityQuestions')) {
				for(var itr=0; itr<this.get('securityQuestions').length; itr++){
					this.send('deleteQuestion');
				}
			}
		}
	}.observes('portalRecord.requireSecurityQuestions'),

	isValid: function() {
		var portalCredential = this.get('portalCredential');
		var portalRecord = this.get('portalRecord');
		if (portalCredential.get('primaryUsername') && portalCredential.get('primaryPassword')) {
			if (portalRecord.get('requireMultiplePassword') && !portalCredential.get('additionalPassword')) {
				return false;
			}
			return true;
		}
		else if (!portalCredential.get('primaryUsername') && !portalCredential.get('primaryPassword')) {
			if (this.get('hasOnlineAccessCredentials')) {
				if (portalRecord.get('requireMultiplePassword') && !portalCredential.get('additionalPassword')) {
					return true;
				}
				else if (portalRecord.get('requireMultiplePassword') && portalCredential.get('additionalPassword')) {
					return false;
				}
				return true;
			}

		}
		return false;
	}.property('portalCredential.primaryUsername', 'portalCredential.primaryPassword', 'portalCredential.additionalPassword', 'portalRecord.requireMultiplePassword'),

	isAllQuestionsComplete: function() {
		var isAllQuestionsComplete = true;
		var portalRecord = this.get('portalRecord');
		if (portalRecord.get('requireSecurityQuestions')) {
			isAllQuestionsComplete = this.get('securityQuestions').every(function(question) {
				if(question.get('isNewQuestion') && !question.get('securityAnswer')){
					return false;
				}
				return true;
			});
		}
		return isAllQuestionsComplete;
	}.property('portalRecord.requireSecurityQuestions', 'securityQuestions.@each.securityQuestion', 'securityQuestions.@each.securityAnswer'),

	canBeSaved: function(){
		return this.get('isAllQuestionsComplete') && this.get('isValid');
	}.property('isAllQuestionsComplete', 'isValid'),

	actions: {
		toggleOnlineOrInvoice: function() {
			this.toggleProperty('showOnlineOrInvoice');
			this.set('errorText', '');
		},
		uploadInvoice: function(currentHealthCarrier) {
			this.pickAndStore('invoiceUrl', {
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
			});
		},
		finishLater: function() {
			if (!this.get('showFinishLaterModal')) {
				this.toggleProperty('showFinishLaterModal');
				var self = this;
				this.send('showModal', 'setup.carrierdata.finishlater', {
					target: self,
				});
			} else {
				this.goToBoRDashboard();
			}
		},
		cancel: function() {
			if (this.get('showFinishLaterModal')) {
				this.toggleProperty('showFinishLaterModal');
				this.send('hideModal');
			}
		},

		addQuestion: function() {
			this.get('securityQuestions').pushObject(this.store.createRecord('additionalSecurityQuestion', {
				securityQuestion: '',
				securityAnswer: '',
				isActive: true,
				isNewQuestion: true,
				placeholder: 'Please Enter your answer.',
			}));

		},

		deleteQuestion: function() {
			var deletedQuestion = this.get('securityQuestions').popObject();
			deletedQuestion.set('isActive', false);
			this.get('deletedQuestions').pushObject(deletedQuestion);
			if (this.get('securityQuestions').length === 0) {
				this.set('portalRecord.requireSecurityQuestions', false);
			}
		},
		save: function() {
			var nextRouteName = this.get('nextRouteName');
			if (this.get('showOnlineOrInvoice')) {
				var portalRecord = this.get('portalRecord');
				portalRecord.set('verificationStatus', 'Unverified');
				var superthis = this;
				var addedGroup = this.get('addedGroup');
				var promiseArray = [];
				if (this.get('canBeSaved')) {
					if (App.switches.isActive('bor.beta')) {
						this.eventLogger.log('bor_beta_flow', {action: 'save', current_route: "carrierDetails-" + this.get('carrierName')});
					}
					this.set('buttonText', 'Saving...');
					this.set('buttonClass',  'button unclickable');
					this.set('buttonClassBack', 'button grey unclickable');
					portalRecord.get('addedGroups').pushObject(addedGroup);
					promiseArray.push(portalRecord.save().then(function(portal) {
						var portalCredential = superthis.get('portalCredential');
						portalCredential.set('parentPortal_id', portal.get('id'));
						portalCredential.save();
						var securityQuestions = superthis.get('securityQuestions');
						var deletedQuestions = superthis.get('deletedQuestions');

						securityQuestions.forEach(function(question) {
							question.set('parentPortal_id', portal.get('id'));
							question.save();
						});

						deletedQuestions.forEach(function(question) {
							question.set('parentPortal_id', portal.get('id'));
							question.save();
						});
						superthis.set('errorText', null);
					}));
				}
				else {
					this.set('errorText', 'Please enter all fields');
					return;
				}
				this.set('errorText', '');
				Ember.RSVP.all(promiseArray).then(function(resolvedPromise) {
					if (nextRouteName=='bordashboard') {
						App.ProductStage.createAllInsuranceEvent('progress', 0.4, this);
					}
					this.saveNContinueWithNumStepsCompleted(1, [this.get('borCompanyNav').reload()]);
				}.bind(this));
			}
			else {
				var operatingCarrier = this.get('operatingCarrier');
				operatingCarrier.set('invoiceUrl', this.get('invoiceUrl'));
				var invoiceUrl = this.get('invoiceUrl');
				var currentBorRoutes = this.get('borCompanyNav.borRoutesStatusesObj');
				var invoiceStepsComplete = parseInt(currentBorRoutes['invoices']);

				this.get('borHealthCarriers').forEach(function(healthCarrier) {
					if (healthCarrier.get('carrier.id') == operatingCarrier.get('carrierID')) {
						healthCarrier.set('invoiceUrl', invoiceUrl);
						var status = healthCarrier.get('borStatus') || "";
						if (status.indexOf("invoice-uploaded") == -1) {
							invoiceStepsComplete++;
							status += "+invoice-uploaded";
							healthCarrier.set('borStatus', status);
						}
					}
				});
				currentBorRoutes['invoices'] = invoiceStepsComplete.toString();
				this.get('borCompanyNav').setCurrentBoRRouteStatus(currentBorRoutes);
				if (nextRouteName=='bordashboard') {
					App.ProductStage.createAllInsuranceEvent('progress', 0.4, this);
				}
				this.saveNContinueWithNumStepsCompleted(0, [this.get('borCompanyNav').reload()]);
			}
		},
	},
});
//
App.SetupCarrierdataFinishlaterController = Ember.Controller.extend({
	actions: {
		finishLater: function() {
			this.controllerFor('setup.carrierdata').send('finishLater');
		},
		cancel: function() {
			this.controllerFor('setup.carrierdata').send('cancel');
		},
	},
});

App.SetupSendinvitesRoute = zen._BoRPostSignChildRoute.extend({
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'send-invites';
		this._super(controller, model);
		// controller.set('emailText', this.get('companyName') + ' will be switching their HR system to Zenefits real soon!!');
	},
});

App.SetupSendinvitesController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, zen._SetupUtilMixin, {
	emailText: 'Hi Employee1,',
	testDriveStepSrc: '/static/img/slide-02.jpg',
	originalButtonText: 'Looks good! Invite my employees',
	userInput: '',
	newSubject: function(model){
		return '[Important] ' + this.get('model.name') + ' has a new HR system';
	}.property('model.name'),

	buttonText: function(key, value, previousValue) {
		if (arguments.length > 1) {
			return value;
		}
		return this.get('originalButtonText');
	}.property('originalButtonText'),
	toggleEditMode: function() {
		this.toggleProperty('enableEdit');
		if (!this.get('enableEdit')) {
			this.set('errorText', "");
		}
	},
	actions: {
		showTestDriveModal: function() {
			this.send('showModal', 'setup.eetestdrivemodal');
		},
		invite: function() {
			if (this.get('enableEdit')) {
				this.set('errorText', "Please preview the template first.");
				return;
			}
			this.set('errorText', "");
			this.set('buttonText', 'Inviting...');
			var self = this; // 'this' will be used in multi level deep, prefer this over the bind(this).
			// Lets first save the changes here and then call the ajax to send emails. Upon successful AJAX, lets call the
			// gamification's save and transition to function. The ajax will just trigger a celery task and come back.
			self.saveAndContinue().then(function() {
				Ember.ajaxPost('/send_employee_early_emails/', {
					"userInputText": self.get("userInput"),
					"userInputSubject": self.get("newSubject")
				}).then(function (response) {
					self.set('buttonText', self.get('originalButtonText'));
					self.setNumStepsCompletedForRoute('meeting-your-employees', 1);
					self.saveNContinueWithNumStepsCompleted(1).then(function() {
						if (App.switches.isActive('bor.beta') && self.get('borCompanyNav.enableMandatoryRoutes')) {
							self.eventLogger.log('bor_beta_flow', {action: 'save', current_route: 'send-invites'});
							self.send('showModal', 'setup.betaflowsuccess');
							self.transitionTo('bordashboard');
						}
					});
				}).catch(function() {
					console.warn("Error happened while sending emails.");
				});
			});
		},
	},
});

App.SetupBetaflowsuccessController = zen._ModalObjectController.extend(zen._BoRGamificationControllerUtils, {
	actions: {
		cancel: function() {
			this.send('hideModal');
		},
	},
});

App.SetupCompanyinformationRoute = zen._BoRPostSignChildRoute.extend({
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'company-information';
		this._super(controller, model);
	}

});

App.SetupCompanyinformationController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, {
	sicErrorText: '',
	errorText: '',
	buttonText: "Save & Continue",
	buttonClass: "button primary",
	buttonClassBack: "button grey",

	saveNContinue: function() {
		var sicCode = this.get('sicCode');
		var ein = this.get('ein');
		var socialRegex = new RegExp("^\\d{2}-?\\d{7}?$");
		var isValid = ein && sicCode && !this.get('sicErrorText') && socialRegex.test(ein);
		if (isValid) {
			App.ProductStage.createAllInsuranceEvent('progress', 0.95, this);
			this.set('errorText', '');
			this.set('buttonClass', 'button primary unclickable');
			this.set('buttonClassBack', "button grey unclickable");
			this.set("buttonText", "Saving...");
			this.saveNContinueWithNumStepsCompleted(1).then(function() {
				this.set('buttonClass', 'button primary');
				this.set('buttonClassBack', "button grey");
				this.set("buttonText", "Save & Continue");
			}.bind(this));
		} else {
			this.set('errorText', 'Invalid or missing details. Please correct them and continue');
		}
	},

	showEINHelp: function() {
		this.send('showModal', 'setup.feinhelper');
	},

	showSICHelp: function() {
		this.send('showModal', 'setup.sichelper');
	},
});


App.SetupCobratakeoverRoute = zen._BoRPostSignChildRoute.extend({
	afterModel: function(model) {
		return Ember.RSVP.all([model.get('companyCobra')]);
	},

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'cobra-takeover';
		this._super(controller, model);

		var companyCobra = model.get('companyCobra');
		if (companyCobra) {
			var status = companyCobra.get('status');
			if (status === "filling-out") {
				this.transitionTo("addcobra.tpa");
			} else if (status === "requested" || status === "setup") {
				this.transitionTo("addcobra2.redirect");
			}
		}
	},
});


App.SetupCobratakeoverController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, {
	errorText: '',
	buttonText: "Save & Continue",
	buttonClass: "button primary",
	buttonClassBack: "button grey",
	isConfirmed: Ember.computed.equal("companyCobra.status", "confirmed"),
	isSkipped: Ember.computed.equal("companyCobra.status", "third-party"),
	skipStep: function() {
		var companyCobra = this.get('companyCobra');
		companyCobra.set('status', 'third-party');
		companyCobra.save().then(function() {
			this.saveNContinueWithNumStepsCompleted(3);
		}.bind(this));
		App.ProductStage.createAllInsuranceEvent('progress', 0.9, this);
	},
	saveNContinue: function() {
		this.set('errorText', '');
		if (this.get('isConfirmed') || this.get('isSkipped')) {
			this.set('buttonClass', 'button primary unclickable');
			this.set('buttonClassBack', "button grey unclickable");
			this.set("buttonText", "Saving...");
			this.saveNContinueWithNumStepsCompleted(3).then(function() {
				this.set('buttonClass', 'button primary');
				this.set('buttonClassBack', "button grey");
				this.set("buttonText", "Save & Continue");
			}.bind(this));
		} else {
			this.transitionToRoute("addcobra.questionnaire");
		}
		App.ProductStage.createAllInsuranceEvent('progress', 0.9, this);
	},
	cobraParticipants: function() {
		return this.transitionToRoute('cobra.participants');
	},
});


App.SetupFeinhelperController = Ember.Controller.extend({
	close: function() {
		this.send('hideModal');
	},
});

App.SetupSichelperController = Ember.Controller.extend({
	close: function() {
		this.send('hideModal');
	},
});

App.SetupContributionRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	beforeModel: function() {
		return App.ShortCircuitPlansApi.find('me').then(function(resolvedPromise) { return resolvedPromise.reload(); });
	},

	model: function(params) {
		return this._super().then(function(parentModel) {
			return this.all(parentModel, {
				'borMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borMedicalShortCircuitPlans'),
				'borAdditionalMedicalShortCircuitPlans': zen.shortCircuitPlansApi('borAdditionalMedicalShortCircuitPlans'),
				'borDentalShortCircuitPlans': zen.shortCircuitPlansApi('borDentalShortCircuitPlans'),

				'oeMedicalShortCircuitPlans': zen.shortCircuitPlansApi('oeMedicalShortCircuitPlans'),
				'oeAdditionalMedicalShortCircuitPlans': zen.shortCircuitPlansApi('oeAdditionalMedicalShortCircuitPlans'),
				'oeDentalShortCircuitPlans': zen.shortCircuitPlansApi('oeDentalShortCircuitPlans'),
			});
		}.bind(this));
	},

	afterModel: function(model) {
		this._super(model);

		var promises = [];
		var otherModels = this.otherModels;
		['Medical', 'AdditionalMedical', 'Dental', 'Vision'].forEach(function(loc) {
			var healthCarrier = otherModels['bor' + loc + 'HealthCarrier'];
			if (healthCarrier) {

				if (!healthCarrier.get('companyHealthEnrollment.isEnrollmentComplete')) {
					// Unset the health carrier if the carrier isn't switched.
					otherModels['bor' + loc + 'HealthCarrier'] = null;
					return;
				}

				var plansKey = healthCarrier.get('companyHealthEnrollment.enrollmentType').toLowerCase() + loc + 'ShortCircuitPlans';
				var shortCircuitPlans = otherModels[plansKey];
				var plansSelectOptions;
				if (shortCircuitPlans) {
					plansSelectOptions = shortCircuitPlans.map(function(plan) {
						return {
							id: plan.get('id'),
							name: plan.get('displayName'),
						};
					});

					healthCarrier.set('plansSelectOptions', plansSelectOptions);
					if (healthCarrier.get('basePlanName')) {
						var basePlan = plansSelectOptions.findBy('name', healthCarrier.get('basePlanName'));
						healthCarrier.set('selectedBasePlan', basePlan && basePlan.id);
					}
				}

				promises.push(Ember.ajaxPost('/custom_api/contribution_scheme/read', {
					lineOfCoverage: healthCarrier.get('lineOfCoverage'),
					carrierId: healthCarrier.get('carrier.id'),
				}).then(function(response) {
					// If the contributions schemes are available, choose the appropirate one and set it on the health carrier.
					if (response.isContributionAvailable) {
						var contribution = response.contributions[0];
						contribution.employeeContribution = Number(contribution.employeeContribution);
						contribution.dependentsContribution = Number(contribution.dependentsContribution);
						healthCarrier.setProperties({
							contributionType: contribution.contributionType,
							contributionEmployee: contribution.employeeContribution,
							contributionDependents: contribution.dependentsContribution,
						});

						if (plansSelectOptions && contribution.basePlanCHPId) {
							var basePlan = plansSelectOptions.findBy('id', contribution.basePlanCHPId);
							healthCarrier.set('selectedBasePlan', basePlan && basePlan.id);
						}
						healthCarrier.set('contribution', contribution);
					}
				}));
			}
		});

		return Ember.RSVP.all(promises);
	},

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'contributions';
		this._super(controller, model);
	},

	deactivate: function() {
		this._super();
		var controller = this.get('controller');
		if (controller) {
			if (controller.get('borMedicalHealthCarrier.isDirty')) {
				controller.get('borMedicalHealthCarrier').rollback();
			}
			if (controller.get('borAdditionalMedicalHealthCarrier.isDirty')) {
				controller.get('borAdditionalMedicalHealthCarrier').rollback();
			}
			if (controller.get('borDentalHealthCarrier.isDirty')) {
				controller.get('borDentalHealthCarrier').rollback();
			}
			if (controller.get('borVisionHealthCarrier.isDirty')) {
				controller.get('borVisionHealthCarrier').rollback();
			}
		}
	}
});

App.SetupContributionController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, {
	buttonText: "Save & Continue",
	buttonClass: "button primary js-glue-save-continue",
	buttonClassBack: "button grey",
	hasSeenContributionWarning: false,
	save: function() {
		var totalStepsComplete = 0;

		if (this.get('borMedicalHealthCarrier')) {
			if (this.atleastOneContributionFieldFilled('borMedicalHealthCarrier') && !this.get('borMedicalHealthCarrier.contributionType')) {
				this.set('errorText', 'Please select a valid contribution type for Medical plans.');
				return;
			}
			if (this.get('isPercentOfPlan')) {
				if (!this.get('borMedicalHealthCarrier.selectedBasePlan')) {
					this.set('errorText', 'Please enter the base plan for percent of plan policy.');
					return;
				}
			}
			if (this.atleastOneContributionFieldFilled('borMedicalHealthCarrier') && (!this.get('borMedicalHealthCarrier.contributionEmployee') || !this.get('borMedicalHealthCarrier.contributionDependents'))) {
				if (!this.get('borMedicalHealthCarrier.contributionDependents')) {
					this.set('errorText', 'Please enter a valid non-zero dependents contribution for Medical plans.');
					return;
				}
				else {
					this.set('errorText', 'Please enter a valid non-zero employee contribution for Medical plans.');
					return;
				}
			}
			// We came this far. If contribution EE is set, then admin has filled in the contributions
			// for this line of coverage. In that case,
			if (this.get('borMedicalHealthCarrier.contributionEmployee')) {
				var status = this.get('borMedicalHealthCarrier.borStatus');
				status = (status) ? status : "";
				if (status.indexOf("contributions") == -1) {
					status += "+contributions";
				}
				this.get('borMedicalHealthCarrier').set('borStatus', status);
				totalStepsComplete++;
				this.invalidateScoeAdminSign(this.get('borMedicalHealthCarrier'));
			}
		}

		if (this.get('borAdditionalMedicalHealthCarrier')) {
			if (this.atleastOneContributionFieldFilled('borAdditionalMedicalHealthCarrier') && !this.get('borAdditionalMedicalHealthCarrier.contributionType')) {
				this.set('errorText', 'Please select a valid contribution type for Additional Medical plans.');
				return;
			}
			if (this.get('additionalCarrierIsPercentOfPlan')) {
				if (!this.get('borAdditionalMedicalHealthCarrier.selectedBasePlan')) {
					this.set('errorText', 'Please enter the base plan for percent of plan policy.');
					return;
				}
			}
			if (this.atleastOneContributionFieldFilled('borAdditionalMedicalHealthCarrier') && (!this.get('borAdditionalMedicalHealthCarrier.contributionEmployee') || !this.get('borAdditionalMedicalHealthCarrier.contributionDependents'))) {
				if (!this.get('borAdditionalMedicalHealthCarrier.contributionDependents')) {
					this.set('errorText', 'Please enter a valid non-zero dependents contribution for Additional Medical plans.');
					return;
				}
				else {
					this.set('errorText', 'Please enter a valid non-zero employee contribution for Additional Medical plans.');
					return;
				}
			}
			if (this.get('borAdditionalMedicalHealthCarrier.contributionType')) {
				var status = this.get('borAdditionalMedicalHealthCarrier.borStatus');
				status = (status) ? status : "";
				if (status.indexOf("contributions") == -1) {
					status += "+contributions";
				}
				this.get('borAdditionalMedicalHealthCarrier').set('borStatus', status);
				totalStepsComplete++;
				this.invalidateScoeAdminSign(this.get('borAdditionalMedicalHealthCarrier'));
			}
		}

		if (this.get('borDentalHealthCarrier')) {
			if (this.atleastOneContributionFieldFilled('borDentalHealthCarrier') && !this.get('borDentalHealthCarrier.contributionType')) {
				this.set('errorText', 'Please select a valid contribution type for Dental plan.');
				return;
			}
			if (this.atleastOneContributionFieldFilled('borDentalHealthCarrier') && (!this.get('borDentalHealthCarrier.contributionEmployee') || !this.get('borDentalHealthCarrier.contributionDependents'))) {
				if (!this.get('borDentalHealthCarrier.contributionDependents')) {
					this.set('errorText', 'Please enter a valid non-zero dependents contribution for Dental plans.');
					return;
				}
				else {
					this.set('errorText', 'Please enter a valid non-zero employee contribution for Dental plans.');
					return;
				}
			}
			if (this.get('borDentalHealthCarrier.contributionType')) {
				var status = this.get('borDentalHealthCarrier.borStatus');
				status = (status) ? status : "";
				if (status.indexOf("contributions") == -1) {
					status += "+contributions";
				}
				this.get('borDentalHealthCarrier').set('borStatus', status);
				totalStepsComplete++;
				this.invalidateScoeAdminSign(this.get('borDentalHealthCarrier'));
			}
		}

		if (this.get('borVisionHealthCarrier')) {
			if (this.atleastOneContributionFieldFilled('borVisionHealthCarrier') && !this.get('borVisionHealthCarrier.contributionType')) {
				this.set('errorText', 'Please select a valid contribution type for Vision plan.');
				return;
			}
			if (this.atleastOneContributionFieldFilled('borVisionHealthCarrier') && (!this.get('borVisionHealthCarrier.contributionEmployee') || !this.get('borVisionHealthCarrier.contributionDependents'))) {
				if (!this.get('borVisionHealthCarrier.contributionDependents')) {
					this.set('errorText', 'Please enter a valid non-zero dependents contribution for Vision plans.');
					return;
				}
				else {
					this.set('errorText', 'Please enter a valid non-zero employee contribution for Vision plans.');
					return;
				}
			}
			if (this.get('borVisionHealthCarrier.contributionType')) {
				var status = this.get('borVisionHealthCarrier.borStatus');
				status = (status) ? status : "";
				if (status.indexOf("contributions") == -1) {
					status += "+contributions";
				}
				this.get('borVisionHealthCarrier').set('borStatus', status);
				totalStepsComplete++;
				this.invalidateScoeAdminSign(this.get('borVisionHealthCarrier'));
			}
		}

		this.set('errorText', '');
		this.set('buttonClass', 'button primary unclickable');
		this.set('buttonClassBack', "button grey unclickable");
		this.set("buttonText", "Saving...");

		if (this.having100PercentContributionInSCOE() && !this.get('hasSeenContributionWarning')) {
			var data = {
				scoeEnrollments: this.getSCOEEnrollmentsWith100PercentCoverage(),
				controller: this,
				totalStepsComplete: totalStepsComplete,
			};
			this.set('hasSeenContributionWarning', true);
			this.send('showModal', 'setup.contributionWarning', data);
		} else {
			this._save(totalStepsComplete);
		}
	},

	_clearContributionProperties: function(healthCarrier) {
		var loc = healthCarrier.get('lineOfCoverage');
		if (loc == 'medical') {
			healthCarrier.set('basePlan', null);
			healthCarrier.set('basePlanName', null);
		}
		else if (loc == 'dental') {
			healthCarrier.set('dentalBasePlan', null);
			healthCarrier.set('basePlanName', null);
		}

		healthCarrier.setProperties({
			contributionType: '',
			contributionEmployee: null,
			contributionDependents: null,
		});
	},

	_saveContributionSchemePromise: function(healthCarrier) {
		var effectiveDate = moment(healthCarrier.get('companyHealthEnrollment.enrollmentCompleteTimestamp'));
		// Let the effective date be start of the month.
		effectiveDate.date(1);
		return Ember.ajaxPost('/custom_api/contribution_scheme/create', {
			contributionType: healthCarrier.get('contributionType'),
			lineOfCoverage: healthCarrier.get('lineOfCoverage'),
			carrierId: healthCarrier.get('carrier.id'),
			employeeContribution: healthCarrier.get('contributionEmployee'),
			dependentsContribution: healthCarrier.get('contributionDependents'),
			basePlanCHPId: healthCarrier.get('selectedBasePlan'),
			effectiveDate: effectiveDate.format('MM/DD/YYYY'),
		});
	},

	_save: function(totalStepsComplete) {
		// Ready to save the contributions!
		var self = this;
		var healthCarriersToSave = [];
		['Medical', 'AdditionalMedical', 'Dental', 'Vision'].forEach(function(loc) {
			var healthCarrier = self.get('bor' + loc + 'HealthCarrier');
			if (healthCarrier) {
				var contribution = healthCarrier.get('contribution');
				var saveContributionScheme =
					!contribution ||
					contribution.contributionType !== healthCarrier.get('contributionType') ||
					contribution.employeeContribution !== healthCarrier.get('contributionEmployee') ||
					contribution.dependentsContribution !== healthCarrier.get('contributionDependents') ||
					(healthCarrier.get('selectedBasePlan') && contribution.basePlanCHPId !== healthCarrier.get('selectedBasePlan'));

				if (saveContributionScheme) {
					healthCarriersToSave.push(healthCarrier);
				}
			}
		});

		// Create the contributions in sequential order to avoid integrity errors.
		healthCarriersToSave.reduce(function(cur, next) {
			return cur.then(function() {
				return self._saveContributionSchemePromise(next);
			});
		}, Ember.RSVP.resolve()).then(function() {
			healthCarriersToSave.forEach(function(healthCarrier) {
				self._clearContributionProperties(healthCarrier);
				// If the health carrier isn't dirty after clearing out the contribution stuff, force
				// reload to load the generic contribution descriptions.
				if (!healthCarrier.get('isDirty')) {
					healthCarrier.reload();
				}
			});

			self.saveNContinueWithNumStepsCompleted(totalStepsComplete).then(function() {
				self.set('buttonClass', 'button primary js-glue-save-continue');
				self.set('buttonClassBack', "button grey");
				self.set("buttonText", "Save & Continue");
			});
		});
	},

	having100PercentContributionInSCOE: function() {
		var scoeEnrollmentsWith100PercentCoverage = this.getSCOEEnrollmentsWith100PercentCoverage();
		return this.getSCOEEnrollmentsWith100PercentCoverage().length > 0;
	},

	getSCOEEnrollmentsWith100PercentCoverage: function() {
		var locSpecificEnrollmentsAndCHCs = [
			['borMedicalHealthCarrier', 'borMedicalEnrollment'],
			['borAdditionalMedicalHealthCarrier', 'borAdditionalMedicalEnrollment'],
			['borDentalHealthCarrier', 'borDentalEnrollment'],
			['borVisionHealthCarrier', 'borVisionEnrollment']
		];
		var enrollmentsWith100PercentCoverage = locSpecificEnrollmentsAndCHCs.filter(function(element){
			var chc = element[0];
			var che = element[1];
			if (!this.get(chc)) {
				return;
			}
			var isContributionTypePercentage = this.get(chc).get('contributionType') != 'F';
			var isEnrollmentTypeOE = this.get(che).get('enrollmentType') == 'OE';
			var isEmployeeHaving100Percent = this.get(chc).get('contributionEmployee') == 100;
			var isDependentHaving100Percent = this.get(chc).get('contributionDependents') == 100;
			var isEnrollmentWith100PercentCoverage = isContributionTypePercentage && isEnrollmentTypeOE && (isEmployeeHaving100Percent || isDependentHaving100Percent);
			return isEnrollmentWith100PercentCoverage;
		}.bind(this));
		return enrollmentsWith100PercentCoverage;
	},
	// arg passed is string. We have to extract the ember object out of it.
	atleastOneContributionFieldFilled: function(healthCarrier) {
		healthCarrier = this.get(healthCarrier);
		return healthCarrier.get('contributionType') || healthCarrier.get('contributionEmployee') || healthCarrier.get('contributionDependents');
	},
	invalidateScoeAdminSign: function(healthCarrier) {
		if (healthCarrier && healthCarrier.get('invalidateSCOEAdminSignOnChange') && healthCarrier.get('isDirty') && this.set('scoeAdminSignOff.isActive')) {
			this.set('scoeAdminSignOff.isActive', false);
			this.get('borCompanyNav').mandateSCOEAdminSignOffRoute();
		}
	},
	isFixed: Ember.computed.equal('borMedicalHealthCarrier.contributionType', 'F'),
	isPercentOfPlan: Ember.computed.equal('borMedicalHealthCarrier.contributionType', 'B'),
	additionalCarrierIsFixed: Ember.computed.equal('borAdditionalMedicalHealthCarrier.contributionType', 'F'),
	additionalCarrierIsPercentOfPlan: Ember.computed.equal('borAdditionalMedicalHealthCarrier.contributionType', 'B'),
	isDentalFixed: Ember.computed.equal('borDentalHealthCarrier.contributionType', 'F'),
	isVisionFixed: Ember.computed.equal('borVisionHealthCarrier.contributionType', 'F'),
});

function getBoRHealthCarriers(ctx) {
	var borHealthCarriers = [];
	if (ctx.get('borMedicalHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borMedicalHealthCarrier'));
	}
	if (ctx.get('borAdditionalMedicalHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borAdditionalMedicalHealthCarrier'));
	}
	if (ctx.get('borDentalHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borDentalHealthCarrier'));
	}
	if (ctx.get('borVisionHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borVisionHealthCarrier'));
	}
	if (ctx.get('borLifeHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borLifeHealthCarrier'));
	}
	if (ctx.get('borStDisabilityHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borStDisabilityHealthCarrier'));
	}
	if (ctx.get('borLtDisabilityHealthCarrier')) {
		borHealthCarriers.push(ctx.get('borLtDisabilityHealthCarrier'));
	}
	return borHealthCarriers;
}

App.SetupContributionWarningController = zen._AbstractSavingController.extend({
	actions: {
		contribute99: function() {
			var scoeEnrollments = this.get('model.scoeEnrollments');
			scoeEnrollments.forEach(function(enrollment) {
				this.set99If100(enrollment);
			}.bind(this));
			this.get('model.controller').send('_save', this.get('model.totalStepsComplete'));
			this.send('hideModal');
		},
		contribute100: function() {
			this.get('model.controller').send('_save', this.get('model.totalStepsComplete'));
			this.send('hideModal');
		},
		close: function() {
			this.get('model.controller').send('_save', this.get('model.totalStepsComplete'));
			this.send('hideModal');
		},
	},

	set99If100: function(enrollment) {
		controller = this.get('model.controller');
		var chc = controller.get(enrollment[0]);
		var che = controller.get(enrollment[1]);
		if (chc) {
			var contributionEmployee = chc.get('contributionEmployee');
			var contributionDependents = chc.get('contributionDependents');
			chc.setProperties({
				contributionEmployee: contributionEmployee == 100 ? 99 : contributionEmployee,
				contributionDependents: contributionDependents == 100 ? 99 : contributionDependents,
			});
		}

	}

});

App.SetupTotalemployeesRoute = zen._BoRPostSignChildRoute.extend({

	setupController: function(controller, model) {
		controller['rawRouteName'] = 'total-employees';
		this._super(controller, model);
		var borCarriers = Ember.A();
		if (controller.get('borMedicalHealthCarrier')) {
			borCarriers.addObject(controller.get('borMedicalHealthCarrier'));
		}
		if (controller.get('borAdditionalMedicalHealthCarrier')) {
			borCarriers.addObject(controller.get('borAdditionalMedicalHealthCarrier'));
		}
		if (controller.get('borDentalHealthCarrier')) {
			borCarriers.addObject(controller.get('borDentalHealthCarrier'));
		}
		if (controller.get('borVisionHealthCarrier')) {
			borCarriers.addObject(controller.get('borVisionHealthCarrier'));
		}
		controller.set('borCarriers', borCarriers);
	},
});

App.SetupTotalemployeesController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, zen._SetupUtilMixin, {
	buttonText: "Save & Continue",
	buttonClass: "button primary",
	buttonClassBack: "button grey",

	saveNContinue: function() {
		if (!this.get('shortCircuitEmployeeCount')) {
			this.set('errorText', "Please enter the number of full time employees.");
			this.set('hasMissingValues', true);
			return;
		}
		this.setBoRStatus('total-employees');
		this.set('errorText', "");
		this.set('hasMissingValues', false);
		this.set('buttonClass', 'button primary unclickable');
		this.set('buttonClassBack', "button grey unclickable");
		this.set("buttonText", "Saving...");
		this.saveNContinueWithNumStepsCompleted(1).then(function() {
			this.set('buttonClass', 'button primary');
			this.set('buttonClassBack', "button grey");
			this.set("buttonText", "Save & Continue");
		}.bind(this));
	},
});

App.SetupInvoicesRoute = zen._BoRPostSignChildRoute.extend(zen._AddExistingUniqueCarrierMixin, {
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'invoices';
		this._super(controller, model);
	},
});

App.SetupInvoicesController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._RollbackOnDeactivateMixin, zen._PublicFilePickerMixin, {
	buttonText: "Save & Continue",
	buttonClass: "button primary",
	buttonClassBack: "button grey",

	isInvoiceMandatory: function(model) {
		var state = this.get('state');
		if (state === 'NC' || state === 'WA') {
			return true;
		}
		return false;
	}.property('state'),

	stateName: function(model) {
		return zen.getUnambiguousStateName(this.get('state'));
	}.property('state'),

	getH1: function(model) {
		if (this.get('isInvoiceMandatory')) {
			return "Invoice Check (" + this.get('state') + ")";
		}

		if (this.get('hasAtleastOneSCOEEligibleEnrollment'))
		{
			return "Want to speed up your plan renewals?";
		}
		return "Want to expedite switch to Zenefits?";
	}.property('hasAtleastOneSCOEEligibleEnrollment', 'isInvoiceMandatory', 'state'),

	hasAtleastOneSCOEEligibleEnrollment: function() {
		var borHealthCarriers = this.get('borHealthCarriers');
		for (var i=0; i < borHealthCarriers.length; i++) {
			var borHealthCarrier = borHealthCarriers[i];
			if (borHealthCarrier.get('eligibleForShortCircuitOpenEnrollment')) {
				return true;
			}
		}
	}.property('borHealthCarriers'),

	uploadInvoice: function(currentHealthCarrier) {
		filepicker.setKey(FILEPICKER_KEY);
		filepicker.pickAndStore({
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
				services:['COMPUTER', 'GMAIL', 'DROPBOX']
			},
			{location:"S3"},
			function(FPFile) {
				this.get('borHealthCarriers').forEach(function(healthCarrier) {
					if (healthCarrier.get('carrier.id') == currentHealthCarrier.get('carrier.id')) {
						healthCarrier.set('invoiceUrl', FPFile[0].url);
					}
				});
				this.set('errorText', "");
			}.bind(this),
			function(FPError) {
				//console.log(FPError.toString());
			}
		);
	},
	uploadRenewalPacket: function(currentHealthCarrier) {
		filepicker.setKey(FILEPICKER_KEY);
		filepicker.pickAndStore({
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
				services:['COMPUTER', 'GMAIL', 'DROPBOX']
			},
			{location:"S3"},
			function(FPFile) {
				this.get('borHealthCarriers').forEach(function(healthCarrier) {
					if (healthCarrier.get('carrier.id') == currentHealthCarrier.get('carrier.id')) {
						healthCarrier.set('renewalPacketUrl', FPFile[0].url);
					}
				});
				this.set('errorText', "");
			}.bind(this),
			function(FPError) {
				//console.log(FPError.toString());
			}
		);
	},
	save: function() {
		var numStepsCompleted = 0;
		var uniqueCarrierIds = {};
		for(var i = 0; i < this.get('borHealthCarriers.length'); i++) {
			if (this.get('borHealthCarriers').objectAt(i).get('invoiceUrl') ||
				this.get('borHealthCarriers').objectAt(i).get('renewalPacketUrl')) {
				if (!uniqueCarrierIds[this.get('borHealthCarriers').objectAt(i).get('carrier.id')]) {
					numStepsCompleted++;
					uniqueCarrierIds[this.get('borHealthCarriers').objectAt(i).get('carrier.id')] = 1;
				}
			}
		}

		this.get('borHealthCarriers').forEach(function(healthCarrier) {
			if (healthCarrier.get('invoiceUrl')) {
				var status = healthCarrier.get('borStatus');
				status = (status) ? status : "";

				if (status.indexOf("invoice-uploaded") == -1) {
					status += "+invoice-uploaded";
					healthCarrier.set('borStatus', status);
				}
			}
		});
		App.ProductStage.createAllInsuranceEvent('progress', 0.5, this);
		this.set('buttonClass', 'button primary unclickable');
		this.set('buttonClassBack', "button grey unclickable");
		this.set("buttonText", "Saving...");
		this.saveNContinueWithNumStepsCompleted(numStepsCompleted).then(function() {
			this.set('buttonClass', 'button primary');
			this.set('buttonClassBack', "button grey");
			this.set("buttonText", "Save & Continue");
		}.bind(this));
	},
	nextRoute: function() {
		this.transitionToRoute(this.get('nextEmberRouteName'));
	}
});

App.BorreviewController = zen._AbstractSavingController.extend({
	isRejected: false,
	isRetracted: false,
	hideTheMessage: false,
	back: function() {
		this.transitionToRoute('index');
	},
	next: function() {
		this.transitionToRoute('index');
	},
	approve: function() {
		this.set('isRejected', false);
		this.set('completionDate', moment().format());
		this.set('status', 'A');
		this.set('hideTheMessage', true);
		return true;
	},
	reject: function() {
		this.set('isRejected', true);
	},
	retract: function() {
		this.set('isRetracted', true);
	},
	saveRejected: function() {
		if (!this.get('name')) {
			this.set('errorText', "Please enter your name.");
			return false;
		}
		if (!this.get('adminComment')) {
			this.set('errorText', "Don’t forget to tell us what’s wrong, so we can correct it!");
			return false;
		}
		this.set('completionDate', moment().format());
		this.set('status', 'R');
		this.set('hideTheMessage', true);
		return true;
	},
	showForm: function() {
		// Only show the form if the action notification is
		// still pending, otherwise show a message saying
		// that the review has already been done.
		// This message will flash on the screen for an instant
		// when the review is saved, so I've put in the
		// "hideTheMessage" flag to prevent this. There must
		// be a better way.
		if (this.get('isPending')) {
			return true;
		}
		return this.get('hideTheMessage');
	}.property('hideTheMessage', 'isPending')
});

App.BorreviewacceptedController = Ember.Controller.extend({
	next: function() {
		this.transitionToRoute('index');
	}
});

App.BorreviewrejectedController = Ember.Controller.extend({
	next: function() {
		this.transitionToRoute('index');
	}
});

App.EmployeeenrollmentsreviewController = App.BorreviewController.extend({
	approve: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('employeeenrollmentsreviewaccepted');
			}.bind(this));
		}
	},
	saveRejected: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('employeeenrollmentsreviewrejected');
			}.bind(this));
		}
	},
	reviewName: function() {
		return "Employee Enrollment Review";
	}.property(),

	reviewText: function() {
		return "employee enrollments";
	}.property(),

	capReviewText: function() {
		return "Employee Enrollment";
	}.property(),
});

App.EmployeeenrollmentsreviewacceptedController = App.BorreviewacceptedController.extend({
	nextStageName: function() {
		return "Confirming Deductions";
	}.property(),
});

App.EmployeeenrollmentsreviewrejectedController = App.BorreviewrejectedController.extend({
	reviewName: function() {
		return "Employee Enrollment Review";
	}.property(),
});

App.PlanreviewController = App.BorreviewController.extend({
	approve: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('planreviewaccepted');
			}.bind(this));
		}
	},
	saveRejected: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('planreviewrejected');
			}.bind(this));
		}
	},
	reviewName: function() {
		return "Plan Review";
	}.property(),

	reviewText: function() {
		return "plans";
	}.property(),

	capReviewText: function() {
		return "Plan";
	}.property(),
});
App.PlanreviewacceptedController = App.BorreviewacceptedController.extend({
	nextStageName: function() {
		return "Confirming Employee Benefits";
	}.property(),
});
App.PlanreviewrejectedController = App.BorreviewrejectedController.extend({
	reviewName: function() {
		return "Plan Review";
	}.property(),
});

App.DeductionsreviewController = App.BorreviewController.extend({
	approve: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('deductionsreviewaccepted');
			}.bind(this));
		}
	},
	saveRejected: function() {
		if (this._super()) {
			this.saveAndContinue().then(function() {
				this.transitionToRoute('deductionsreviewrejected');
			}.bind(this));
		}
	},
	reviewName: function() {
		return "Deductions Review";
	}.property(),

	reviewText: function() {
		return "deductions";
	}.property(),

	capReviewText: function() {
		return "Deductions";
	}.property(),
});
App.DeductionsreviewacceptedController = App.BorreviewacceptedController.extend({
	nextStageName: function() {
		return "Completion!";
	}.property(),
});
App.DeductionsreviewrejectedController = App.BorreviewrejectedController.extend({
	reviewName: function() {
		return "Deductions Review";
	}.property(),
});

App.SetupEmailsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function(params) {
		var employees = dashboard('employees');
		var employeeHealthStatusProperties = employees.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeHealthStatusProperties'));
		});
		return this.all(employees, {
			'employeeHealthStatusProperties': employeeHealthStatusProperties,
			'borMedicalHealthCarrier': zen.companyEnrollmentBorApi('borMedicalHealthCarrier'),
			'borAdditionalMedicalHealthCarrier': zen.companyEnrollmentBorApi('borAdditionalMedicalHealthCarrier'),
			'borDentalHealthCarrier': zen.companyEnrollmentBorApi('borDentalHealthCarrier'),
			'borVisionHealthCarrier': zen.companyEnrollmentBorApi('borVisionHealthCarrier'),
			'borLifeHealthCarrier': zen.companyEnrollmentBorApi('borLifeHealthCarrier'),
			'borStDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borStDisabilityHealthCarrier'),
			'borLtDisabilityHealthCarrier': zen.companyEnrollmentBorApi('borLtDisabilityHealthCarrier'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		var borHealthCarriers = [];

		if (controller.get('borMedicalHealthCarrier')) {
			borHealthCarriers.push(controller.get('borMedicalHealthCarrier'));
		}
		if (controller.get('borAdditionalMedicalHealthCarrier')) {
			borHealthCarriers.push(controller.get('borAdditionalMedicalHealthCarrier'));
		}
		if (controller.get('borDentalHealthCarrier')) {
			borHealthCarriers.push(controller.get('borDentalHealthCarrier'));
		}
		if (controller.get('borVisionHealthCarrier')) {
			borHealthCarriers.push(controller.get('borVisionHealthCarrier'));
		}
		if (controller.get('borLifeHealthCarrier')) {
			borHealthCarriers.push(controller.get('borLifeHealthCarrier'));
		}
		if (controller.get('borStDisabilityHealthCarrier')) {
			borHealthCarriers.push(controller.get('borStDisabilityHealthCarrier'));
		}
		if (controller.get('borLtDisabilityHealthCarrier')) {
			borHealthCarriers.push(controller.get('borLtDisabilityHealthCarrier'));
		}
		controller.set('borHealthCarriers', borHealthCarriers);
	}
});


App.SetupEmailsController = zen._TransactionSavingController.extend(zen._InviteEmailsMixin, {
	buttonText: "Save and Finish",
	buttonClass: "button",
	submit: function() {
		this.set('errorText', null);

		this.get('borHealthCarriers').forEach(function(borHealthCarrier) {
			var status = borHealthCarrier.get('borStatus');
			status = (status) ? status : "";
			if (status.indexOf("census") == -1) {
				status += "+census";
			}

			borHealthCarrier.set('borStatus', status);
		}.bind(this));

		this.set('buttonClass', 'button grey');
		this.set('buttonText', 'Saving...');
		this.saveAndContinue('setup.borcompletecongrats');
	}
});


App.GetquotesRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(App.CompanyHealthEnrollment.find({
			'lineOfCoverage': 'medical',
			'enrollmentType': 'IE',
			'isEnrollmentComplete': false
		}), {
			'company': dashboard('company'),
			'healthTodo': App.SetupTodo.findAll().then(function(todos) {
				todos.find(function(todo) {
					return todo.get('todoKey') === 'HEALTH_AND_BENEFITS';
				});
			})
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('medicalEnrollment', null);
		controller.set('censusUrl', null);

		controller.get('content').forEach(function(healthEnrollment) {
			if (healthEnrollment.get('enrollmentStatus') == 'quotes') {
				controller.set('medicalEnrollment', healthEnrollment);
				controller.set('censusUrl', healthEnrollment.get('censusUrl'));
			}
		});
	}
});

App.GetquotesController = zen._TransactionSavingController.extend(zen._PublicFilePickerMixin, {
	uploadCensus: function() {
		this.pickAndStore('censusUrl', { "extension": ['.xls','.xlsx','.XLS','.XLSX','.txt', '.tsv', '.csv', '.PDF', '.pdf'] });
	},
	save: function() {
		var censusUrl = this.get('censusUrl');
		if (!censusUrl) {
			this.set('errorText', 'Please upload the completed quote form to get quotes.');
			return;
		}

		var company = this.get('company');
		var medicalEnrollment = this.get('medicalEnrollment');
		if (medicalEnrollment) {
			medicalEnrollment.set('censusUrl', censusUrl);
		}
		else {
			// Create a new enrollment
			medicalEnrollment = App.CompanyHealthEnrollment.createRecord();
			medicalEnrollment.set('company', company);
			medicalEnrollment.set('lineOfCoverage', 'medical');
			medicalEnrollment.set('enrollmentType', 'IE');
			medicalEnrollment.set('startDate', formatDate(new Date()));
			medicalEnrollment.set('isEnrollmentComplete', false);
			medicalEnrollment.set('isActive', false);

			// Set the status and censusUrl
			medicalEnrollment.set('enrollmentStatus', 'quotes');
			medicalEnrollment.set('censusUrl', censusUrl);
		}

		if(this.get('healthTodo')) {
			this.get('healthTodo').complete();
		}

		this.saveAndContinue().then(function() {
			medicalEnrollment.reload();
			company.reload().then(function() {
				this.send('showModal', 'getquotes.nextsteps');
			}.bind(this));
		}.bind(this));
	}
});


App.LifegetquotesRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(App.RequestLifeQuotes.createRecord(), {
			'company': dashboard('company')
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var company = controller.get('company');
		if (company.get('isSyncStatusSuccess') && !company.get('isBusinessDateAYearAgo')) {
			if (company.get('businessEstablishedDate')) {
				model.dateOfIncorporation = company.get('businessEstablishedDate').format('L');
			}
			this.send('showModal', 'lifegetquotes.syncnextsteps', model);
		}
	}
});
App.LifegetquotesController = zen._AbstractSavingController.extend(zen._PublicFilePickerMixin, {
	uploadCensus: function() {
		this.pickAndStore('uploadUrl', { "extension": ['.xls','.xlsx','.XLS','.XLSX','.txt', '.tsv', '.csv', '.PDF', '.pdf'] });
	},
	save: function() {
		var model = this.get('model');
		if (!model.get('uploadUrl')) {
			this.set('errorText', 'Please upload the completed quote form to get quotes.');
			return;
		}

		App.ProductStage.createLifeEvent('marketing_page_view', 0.0);
		App.ProductStage.createSTDEvent('progress', 0.1);
		App.ProductStage.createLTDEvent('marketing_page_view', 0.0);
		App.ProductStage.createLifeEvent('progress', 0.1);
		App.ProductStage.createSTDEvent('marketing_page_view', 0.0);
		App.ProductStage.createLTDEvent('progress', 0.1);

		var company = this.get('model.company');
		company.set('lifequotes', true);
		company.save();
		model.save().then(function() {
			this.send('showModal', 'lifegetquotes.nextsteps');
		}.bind(this));
	}
});

App.LifegetquotesSyncnextstepsController = zen._AbstractSavingController.extend({
	pickDate: function() {
		var model = this.get('model');
		this.set('errorText', "");
		var dateOfIncorporation = model.get('dateOfIncorporation');
		if (!dateOfIncorporation) {
			this.set('errorText', 'Please enter Date of Incorporation, this is mandatory.');
			return;
		}
		this.send('hideModal');
		var values = dateOfIncorporation.split('/');
		var company = this.get('model.company');
		company.setProperties({
			dayBusinessEstablished: values[1],
			monthBusinessEstablished: values[0],
			yearBusinessEstablished: values[2]
		});
		var parentScope = this;
		company.save().then(function() {
			if (!company.get('isBusinessDateAYearAgo')) {
				parentScope.send('showModal', 'lifegetquotes.syncnextsteps', model);
			}
		});
	},
	continue: function(){
		this.send('hideModal');
	},
});

/*
 * Enrollment
 */
App.EnrollmentRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function () {
		return this.all(App.CompanyPolicy.find('me'), {
			"companyMedicalEnrollment": zen.companyEnrollmentApi("companyMedicalEnrollment"),
			"additionalMedicalEnrollment": zen.companyEnrollmentApi("additionalCompanyMedicalEnrollment"),
			"companyDentalEnrollment": zen.companyEnrollmentApi("companyDentalEnrollment"),
			"companyVisionEnrollment": zen.companyEnrollmentApi("companyVisionEnrollment"),
			"company": dashboard('company'),
			"allAgreements": App.BrokerPolicy.find(),
			"existingAgreements": dashboard('company').then(function(company) {
				return App.CompanyBrokerPolicy.find({
					'company_id': company.get('id')
				});
			}),
			"declinedEmployeesCount": Ember.$.get('/custom_api/get_number_declined_employees'),
			"acceptsWaiversPerLoc": Ember.$.get('/custom_api/get_accepts_waivers_per_loc'),
		});
	},
	afterModel : function(model) {
		var enrollmentSectionController = this.controllerFor('enrollmentSection');
		if (!enrollmentSectionController.get('content.length') || !enrollmentSectionController.get('subsections.length')) {
			return this.all(model, {
				sections: App.Section.find(),
				subsections: App.Subsection.find(),
			});
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var enrollmentSectionController = this.controllerFor('enrollmentSection');
		if (!enrollmentSectionController.get('content.length')) {
			enrollmentSectionController.set('content', controller.get('sections'));
		}

		if (!enrollmentSectionController.get('subsections.length')) {
			enrollmentSectionController.set('subsections', controller.get("subsections"));
		}

		try {
			// set company date to the company controller
			var sectionController = enrollmentSectionController;
			if (sectionController) {
				if (this.routeName.indexOf('.') != -1) {
					var routeParts = this.routeName.split('.');
					var sections = sectionController.get('content');
					if (routeParts.get('length') == 3) {
						var section = sections.filter(function(obj) {
							return obj.get('path') == routeParts[1];
						}).get('firstObject');

						sections.forEach(function(obj) {
							obj.set('isCurrentSection', obj == section);
						});

						sectionController.set('currentSection', section);
						if (sections.indexOf(section) == sections.get('length') - 1) {
							sectionController.set('isCurrentSectionLast', true);
						}
						else {
							sectionController.set('isCurrentSectionLast', false);
						}

						var subsections = section.get('subsections');

						var subsection = subsections.filter(function(obj) {
							return obj.get('path') == routeParts[2];
						}).get('firstObject');

						subsections.forEach(function(obj) {
							obj.set('isCurrentSubsection', obj == subsection);
						});

						sectionController.set('currentSubsection', subsection);

						if (subsections.indexOf(subsection) == subsections.get('length') - 1) {
							sectionController.set('isCurrentSubsectionLast', true);
						}
						else {
							sectionController.set('isCurrentSubsectionLast', false);
						}
						sectionController.updateContent();
					}
				}
			}
		}
		catch(e) {
			console.log(e);
		}
	}
});

App.EnrollmentIndexRoute = App.EnrollmentRoute.extend({
	redirect: function() {
		this.transitionTo('enrollment.planrequirements.coveragestartdate');
	}
});

App.SetupBoremployeeemailsRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	beforeModel: function() {
		return this.store.find('dashboard', 'me').then(function(me) {
			return me.reload();
		});
	},
	model: function(params) {
		var employees = dashboard('employees');
		var employeeHealthStatusProperties = employees.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeHealthStatusProperties'));
		});
		return this._super(params).then(function(superReturns) {
			return this.all(dashboard('company'), {
				'employees': employees,
				'employeeHealthStatusProperties': employeeHealthStatusProperties,
			});
		}.bind(this));
	},
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'employee-emails';
		this._super(controller, model);
		// We are not supporting international onboarding for now in BoR. TODO(zmohan): Check with Hari
		var employeeObjs = Ember.A();
		controller.get('employees').forEach(function(employee) {
			employeeObjs.addObject(employee);
		});
		controller.set('companyEmployees', employeeObjs);
		emailGuessedSetups(controller, controller.get('companyEmployees'), false);
	},
	deactivate: function() {
		var controller = this.get('controller');
		controller.get('employees').forEach(function(employee) {
			if (employee.get('isDirty')) {
				employee.rollback();
			}
		});
		controller.get('companyEmployees').forEach(function(employee) {
			if (!employee.get('id')) {
				employee.deleteRecord();
			}
		});
		controller.set('companyEmployees', Ember.A());
	},
});

App.SetupBoremployeeemailsController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._SetupUtilMixin, zen._InviteEmailsMixin, {
	shouldShowEmailSuggestion: false,
	errorText: "",
	buttonText: "Save & Continue",


	nonAdminEmployees: Ember.computed.filter('companyEmployees', function(employee, index, array) {
		return !employee.get('isTerminatedOrAdmin');
	}),

	hasNonAdminEmployees: Ember.computed.notEmpty('nonAdminEmployees'),

	saveAndSendEmails: function() {
		emailGuessedSetups(this, this.get('companyEmployees'), false);
		var self = this;
		this.get('companyEmployees').forEach(function(employee) {
			if (!employee.get('id')) {
				self.get('employees').pushObject(employee);
			}
		});
		if (!this._validate('boremployeeemails')) { // TODO(zmohan): Change this to employeeemails after adding employment type.
			this.get('companyEmployees').forEach(function(employee) {
				if (!employee.get('id')) {
					self.get('employees').removeObject(employee);
				}
			});
			return true;
		}
		this.set('errorText', "");
		this.set('hasMissingValues', false);
		this.set('buttonText', 'Saving...');
		if (App.switches.isActive('bor.beta')) {
			this.eventLogger.log('bor_beta_flow', {action: 'save', current_route: 'bor-emails'});
		}
		return this.saveNContinueWithNumStepsCompleted(1).then(function() {
			self.set('buttonText', 'Save & Continue');
		});
	},
	actions: {
		showTestDriveModal: function() {
			this.send('showModal', 'setup.eetestdrivemodal');
		},
		addNewEmployee: function() {
			this.get('companyEmployees').addObject(
				App.AllEmployee.createRecord({
					company: this.get('model'),
					status: 'Act',
				})
			);
		}
	}
});

App.SetupChoosecensusRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'meeting-your-employees';
		this._super(controller, model);
	}
});

App.SetupChoosecensusController = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._SetupUtilMixin, {
	gotoCensus: function() {
		this.transitionToRoute('setup.census');
	},
	gotoEmailsRoute: function() {
		this.transitionToRoute('setup.boremployeeemails');
	},
});

App.SetupBormetemployeesRoute = zen._BoRPostSignChildRoute.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	beforeModel: function() {
		return this.store.find('dashboard', 'me').then(function(me) {
			return me.reload();
		});
	},
	model: function(params) {
		var employees = dashboard('employees');
		var employeeHealthStatusProperties = employees.then(function(employees) {
			return Ember.RSVP.all(employees.mapBy('employeeHealthStatusProperties'));
		});
		return this._super(params).then(function(superReturns) {
			return this.all(dashboard('company'), {
				'employees': employees,
				'employeeHealthStatusProperties': employeeHealthStatusProperties,
			});
		}.bind(this));
	},
	afterModel: function(model) {
		var employees = this.otherModels['employees'];
		var promiseArray = [];
		employees.forEach(function(employee) {
			promiseArray.push(employee.get('user'));
		});
		return Ember.RSVP.all(promiseArray);
	},
	setupController: function(controller, model) {
		controller['rawRouteName'] = 'meeting-employees';
		this._super(controller, model);
		// We are not supporting international onboarding for now in BoR. TODO(zmohan): Check with Hari
		emailGuessedSetups(controller, controller.get('employees'), false);
	},
	deactivate: function() {
		var controller = this.get('controller');
		controller.get('employees').forEach(function(employee) {
			if (employee.get('isDirty')) {
				employee.rollback();
			}
		});
	},
});

App.SetupEetestdrivemodalController = zen._ModalObjectController.extend({
	actions: {
		cancel: function() {
			this.send('hideModal');
		},
	},
});


App.SetupBormetemployeesController  = zen._TransactionSavingController.extend(zen._BoRGamificationControllerUtils, zen._SetupUtilMixin, zen._InviteEmailsMixin, {

	nonAdminEmployees: Ember.computed.filter('employees', function(employee, index, array) {
		return !employee.get('isTerminatedOrAdmin');
	}),

	didWeSendEmail: function() {
		return this.numStepsCompletedForTheRoute('send-invites', this.get('borCompanyNav.borRoutesStatusesObj')) == 1;
  	}.property('borCompanyNav.borRoutesStatusesObj'),
  	// I am little afraid to use computed in this version of ember so using old style properties.
	noneMet: function() {
		return this.get('nonAdminEmployees').every(function(employee) {
			return !employee.get('user.isRegistered');
		});
	}.property('nonAdminEmployees.@each.user'),

	allMet: function() {
		return this.get('nonAdminEmployees').every(function(employee) {
			return employee.get('user.isRegistered');
		});
	}.property('nonAdminEmployees.@each.user'),

	metEmployeesCount: function() {
		var toRet = 0;
		this.get('nonAdminEmployees').forEach(function(employee) {
			if (employee.get('user.isRegistered')) {
				toRet++;
			}
		});
		return toRet;
	}.property('nonAdminEmployees.@each.user'),

	allUpdated: function() {
		return this.get('nonAdminEmployees').every(function(employee) {
			return employee.get('updatedRecords');
		});
	}.property('nonAdminEmployees.@each.updatedRecords'),


	checkIfCompleteAndContinue: function() {
		if (this.get('allUpdated') && this.get('allMet')) {
			return this.saveNContinueWithNumStepsCompleted(1);
		}
		else {
			return this.saveNContinueWithNumStepsCompleted(0);
		}
	}
});

App.LocationsRoute = Ember.Route.extend({
	model: function() {
		return dashboard('company');
	},
});

App.LocationsOverviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'),
		{
			"employeeLocation": dashboard('employee.location'),
			"isReadOnly": dashboard('isReadOnly'),
			"isAdmin": dashboard('isAdmin'),
			"isAdminAll": dashboard('isAdminAll'),
			"isAdminLocation": dashboard('isAdminLocation'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"employees": dashboard('employees'),
			"locations": dashboard('company.locations'),
		});
	}
});

App.LocationsAddlocationRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	afterModel: function(model) {
		return this.all(model,
		{
			"isAdminAll": dashboard('isAdminAll'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"showExperimental": dashboard('company.showExperimental'),
			"onboardingSettings": dashboard('company.onboardingSettings'),
			"_offerLetters": dashboard('company.onboardingSettings.offerLetters'),
			"payrollProvider": dashboard('company.payrollProvider'),
			"wfPayrollCodeType": dashboard('company.payroll.wfPayrollCodeType'),
            "locations": dashboard('company.locations'),
		});
	},
});

App.LocationsEditlocationRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	afterModel: function(model) {
		return this.all(model,
		{
			"isAdminAll": dashboard('isAdminAll'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"_intlAddress": thenpath(model, 'intlAddress'),
			"_intlCompanyTax": thenpath(model, 'intlCompanyTax'),
			"payrollProvider": dashboard('company.payrollProvider'),
			"wfPayrollCodeType": dashboard('company.payroll.wfPayrollCodeType'),
            "locations": dashboard('company.locations'),
		});
	},
	setupController : function(controller, model) {
		this._super(controller, model);
		controller.set('agreement', model.get('intlCompanyTax.tosAgreement'));
	}
});

App.LocationsAddcompanytaxinfoRoute = Ember.Route.extend(zen._MultiModelMixin, {
	afterModel: function(model) {
		return this.all(model,
		{
			"isAdminAll": dashboard('isAdminAll'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"locations": dashboard('company.locations'),
		});
	},
	setupController : function(controller, model) {
		this._super(controller, model);
		var _locations = controller.get('locations').filterProperty('country', model.get('country'));
		if (_locations && _locations.length == 1) {
			controller.set('isFirstLocationInCountry', true);
		} else {
			controller.set('isFirstLocationInCountry', false);
		}
		controller.set('agreement', model.get('tosAgreement'));
	}
});

App.EmployeehistoryRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	model: function() {
		return Ember.RSVP.hash({
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"company": dashboard('company'),
			"departments": App.Department.find(),
			"locations": dashboard('company.locations'),
			"employees": dashboard('employees')
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var payFrequency = controller.get('company.payFreq');
		if (!payFrequency) {
			payFrequency = 'SM';
		}
		var d = new Date();
		if (payFrequency == 'Mo') {
			d.setMonth(d.getMonth() - 1);
		}
		else if (payFrequency == 'SM') {
			d.setMonth(d.getMonth() - 1);
			var diff = new Date() - d;
			d = new Date(Ember.now() - (diff/2));
		}
		else if (payFrequency == 'BW') {
			d.setDate(d.getDate() - 14);
		}
		else if (payFrequency == 'We') {
			d.setDate(d.getDate() - 7);
		}
		controller.set('fromDate', moment(d).format("MM/DD/YYYY"));
	}
});

var lower = function(s) { return s ? s.toLowerCase() : s; };

App.EmployeehistoryController = Ember.Controller.extend({
	fromDate: null,
	company: Ember.computed.alias('model.company'),
	departments: Ember.computed.alias('model.departments'),
	locations: Ember.computed.alias('model.locations'),
	employeeHistoryPromiseRejected: Ember.computed.alias('employeeHistoryPromise.isRejected'),
	employeeHistoryPromisePending: Ember.computed.alias('employeeHistoryPromise.isPending'),
	employeeHistoryExportXSL: Ember.computed('employeeHistoryPromiseRejected', 'isLargerGroup', 'employeeHistory.[]', function(){
		return this.get('isLargerGroup') || this.get('employeeHistoryPromiseRejected');
	}),
	hideFilterOption: Ember.computed('employeeHistoryExportXSL', function() {
		return this.get('employeeHistoryExportXSL') && App.switches.isActive('hide_filter_option_employee_history');
	}),
	loadingMessages: ['History Data is Loading'],
	employeeHistoryPromise: Ember.computed(function() {
		var self = this;
		return Ember.PromiseObject.create({
			promise: Ember.ajaxSettings({
				url:"/api/employee_history",
				timeout: 10000
			}).then(function(res) {
				var empl = self.get('model.employees');

				var data = res.objects.map(function(object) {
					var findEmployee = empl.find(function(ee) {
						return ee.get('id') === object.employee_id;
					});
					if (findEmployee !== undefined) {
						object.employee = App.AllEmployee.find(object.employee_id);
					}
					return object;
				});
				self.set('employeeHistory', data);
			}).catch(function(err) {
				self.set('employeeHistoryPromiseRejected', true);
				self.set('employeeHistoryPromisePending', false);
			})
		});
	}),
	employeeHistory:  Ember.computed(function() {
		return [];
	}),
	filteredEmployees: function() {
		if(this.get('employeeHistoryPromiseRejected')) {
			return [];
		}
		var filtered = this.get('employeeHistory');
		var search = this.get('search');
		if (search) {
			search = search.toLowerCase();
			filtered = filtered.filter(function(e) {
				// Skip Contractors
				if (e.employee !== undefined) {
					var type = e.employee.get('type');
					if (type == 'HC') {
						return false;
					}
					var status = e.employee.get('status');
					if (status != 'Act' && status != 'Ter') {
						return false;
					}
					return search == e.employee.get('last_name').substring(0, search.length).toLowerCase() || search ==  e.employee.get('fullName').substring(0, search.length).toLowerCase();
				} else {
					return false;
				}
			});
		}
		filtered = filtered.filter(function(e) {
			// Skip Contractors
			if (e.employee !== undefined) {
				var type = e.employee.get('type');
				if (type == 'HC') {
					return false;
				} else {
					return e;
				}
			}
		});
		var filterField = lower(this.get('filterField'));
		var fromDate = this.get('fromDate');
		var showNew = this.get('showNew');
		var newParsedDate = moment(fromDate, "MM/DD/YYYY", true).isValid() ? zen.parseAmericanDate(fromDate) : moment(new Date()).format('MM/DD/YYYY');
		var newDate = new Date(newParsedDate.year, newParsedDate.month - 1, newParsedDate.day);
		var newUnix = newDate.getTime() / 1000;
		filtered = filtered.map(function(e) {
			var fields = e.fields;
			var filteredFields = [];
			var f;
			var filteredHistory;
			var current;
			var unix;
			var d;
			var h;
			var l;
			for (var k in fields) {
				f = fields[k];
				filteredHistory = [];
				for (var i = 0; i < f.length; i++) {
					current = f[i];
					if (filterField && filterField != lower(current.field.substring(0, filterField.length))) {
						continue;
					}
					unix = current.date;
					if (unix >= newUnix) {
						d = new Date(unix * 1000);
						filteredHistory.push(current);
					}
				}
				if (filteredHistory.length) {
					var index = 0;
					while (index < filteredHistory.length - 1) {
						h = filteredHistory[index];
						l = filteredHistory[index + 1];
						d = new Date(h.date * 1000);
						if (l.new_value == h.new_value || !l.new_value && !showNew) {
							index++;
							continue;
						}
						var diffHistory = {
							"field": h.field,
							"date": d.getFullYear() + "-" + zen.padZerosTwoDigits(1 + d.getMonth()) + "-" + zen.padZerosTwoDigits(d.getDate()) + " " + zen.padZerosTwoDigits(d.getHours()) + ":" + zen.padZerosTwoDigits(d.getMinutes()),
							"by": h.by,
							"old_value": l.new_value,
							"new_value": h.new_value,
						};
						filteredFields.push(diffHistory);
						index++;
					}

					var oldValue = filteredHistory[index].old_value;
					var newValue = filteredHistory[index].new_value;
					if (index === filteredHistory.length - 1 && (oldValue || showNew)) {
						h = filteredHistory[index];
						d = new Date(h.date * 1000);
						var diffHistory = {
							"field": filteredHistory[index].field,
							"date": d.getFullYear() + "-" + zen.padZerosTwoDigits(1 + d.getMonth()) + "-" + zen.padZerosTwoDigits(d.getDate()) + " " + zen.padZerosTwoDigits(d.getHours()) + ":" + zen.padZerosTwoDigits(d.getMinutes()),
							"by": filteredHistory[index].by,
							"old_value": oldValue,
							"new_value": newValue,
						};
						filteredFields.push(diffHistory);
					}
				}
			}
			return { "employee": e.employee, "fields": filteredFields };
		});
		filtered = filtered.filter(function(e) {
			return e.fields.length;
		});
		return filtered;
	}.property('fromDate', 'length', 'search', 'filterField', 'showNew', 'employeeHistory.[]', 'employeeHistoryPromisePending', 'employeeHistoryPromiseRejected', 'employeeHistoryPromiseFulfilled'),
	moreThanOneLocation: Ember.computed.gt('locations.length', 1),
	isLargerGroup: Ember.computed('company.allActiveEmployeesCount', 'company.syncEmployeeCount', 'employeeHistoryPromiseRejected',  function() {
		var numberOfEmployees = Math.max(
			this.get('company.syncEmployeeCount') || 0,
			this.get('company.allActiveEmployeesCount') || 0
		);
		return numberOfEmployees > 100 || this.get('employeeHistoryPromiseRejected');
	}),
	reportInTask: function() {
		var fromDate = this.get('fromDate');
		var newParsedDate = moment(fromDate, "MM/DD/YYYY", true).isValid() ?  zen.parseAmericanDate(fromDate) : moment(new Date()).format('MM/DD/YYYY');
		var newDate = new Date(newParsedDate.year, newParsedDate.month - 1, newParsedDate.day);
		var newUnix = newDate.getTime() / 1000;

		var filterFieldPass = "noFieldSelected";
		if(this.get('filterField')) {
			filterFieldPass = lower(this.get('filterField'));
		}

		var showNewPass = "noShowNew";
		if(this.get('showNew')) {
			showNewPass = "showNew";
		}

		var searchPass = "noSearch";
		if(this.get('search')) {
			searchPass = this.get('search').toLowerCase();
		}

		var runUrl = "/custom_api/reports/employee_history/" + newUnix + "/" + filterFieldPass + "/" + showNewPass + "/" + searchPass;
		var self = this;
		Ember.$.ajax({
			url: runUrl,
			type: 'GET',
			contentType: 'application/json',
			dataType: 'json',

			// Passing the parameter query parameter 'timeout'
			// if the employee history page had too much data to load
			data: {
				timeout: this.get('employeeHistoryPromiseRejected')
			},
			success: function(response) {
				self.send('showModal','reports.employeehistoryshowmodal');
			},
			error: function(jqXHR, textStatus, errorThrown) {
				console.warn("Error happened while sending emails.");
			}
		});
	},
	reportUrl: function() {
		if (!this.get('filteredEmployees')) {return "";}

		var fromDate = this.get('fromDate');
		var newParsedDate = moment(fromDate, "MM/DD/YYYY", true).isValid() ? zen.parseAmericanDate(fromDate) : moment(new Date()).format('MM/DD/YYYY');
		var newDate = new Date(newParsedDate.year, newParsedDate.month - 1, newParsedDate.day);
		var newUnix = newDate.getTime() / 1000;

		var filterFieldPass = "noFieldSelected";
		if(this.get('filterField')) {
			filterFieldPass = lower(this.get('filterField'));
		}

		var showNewPass = "noShowNew";
		if(this.get('showNew')) {
			showNewPass = "showNew";
		}

		var searchPass = "noSearch";
		if(this.get('search')) {
			searchPass = this.get('search').toLowerCase();
		}

		return "/custom_api/reports/employee_history/" + newUnix + "/" + filterFieldPass + "/" + showNewPass + "/" + searchPass;
	}.property('filteredEmployees', 'fromDate', 'filterField', 'showNew'),
	clearSearch: function(key) {
		this.set(key, null);
	}
});

App.ReportsEmployeehistoryshowmodalController = zen._AbstractSavingController.extend({
	confirm: function() {
		this.transitionTo('bi.premade-reports');
		this.send('hideModal');
	},
});


App.ApproversRoute = Ember.Route.extend(zen._MultiModelMixin, {
	model: function() {
		return dashboard('company.approverSettings');
	},
	afterModel: function(model) {
		return this.all(model, {
			companySettings: dashboard('company.settings'),
			isAdmin: dashboard('isAdmin'),
			isAdminAll: dashboard('isAdminAll'),
			managerAndNotAdminWithHirePermissions: dashboard('managerAndNotAdminWithHirePermissions'),
			isReadOnlyOrRestrictSensitiveAdmin: dashboard('isReadOnlyOrRestrictSensitiveAdmin'),
		});
	},
});

App.ApproversOverviewRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	beforeModel: function() {
		this.transitionTo('approvers.editpermissions');
	},
	model: function() {
		return this.all(dashboard('company'),
			{
				"_approverSettings": dashboard('company.approverSettings'),
				"employees": dashboard("employees"),
				"_permission": App.EmployeePermission.find(),
				"_departments": dashboard('company.departments'),
				"_stockOption": dashboard('company.stockOption'),
			}
		);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var filteredManagers;
		if (controller.get('employees')) {
			controller.get('employees').map(function(e) {
				if (e.get('reportToEmployee') && !e.get('reportToEmployee.hasReports')) {
					e.get('reportToEmployee').set('hasReports', true);
				}
			});
			filteredManagers = zen.allEmployeesEnricher(model.get('employees').filterProperty('hasReports'), true);
		}
		controller.set('filteredManagers', filteredManagers.rejectProperty('status','Ter'));
	}
});

App.ApproversOverviewController = zen._AbstractSavingController.extend(zen._MultiModelMixin, {
	actions: {
		savePermissions: function() {
				var promises = this.get('filteredManagers').map(function (employee) {
					return employee.get('permission').save();
				});
				promises.push(this.get('approverSettings').save());
				return Ember.RSVP.all(promises)
				.then(function() {
					this.send("showModal", 'approvers.showconfirm');
				}.bind(this));
		},
	},


	filterByLevel: function(level, type, flag) {
		this.get('filteredManagers').filterProperty('level', level).forEach(function(e) {
			e.get('permission').set(type, flag);
		});
	},

	finalManagerList: Ember.computed.sortByProperty('filteredManagers', 'dfsOrder'),
	selectManagerForComp: Ember.computed.equal('approverSettings.enableCompAccessToManagers', 'some'),
	selectManagerForEquity: Ember.computed.equal('approverSettings.enableEquityAccessToManagers', 'some'),

	enableI9toManagersVar: function(key, value) {
		if (arguments.length > 1) {
			this.set('approverSettings.enableI9toManagers', value === "yes");
		}
		return this.get('approverSettings.enableI9toManagers')? 'yes' : 'no';
	}.property('approverSettings.enableI9toManagers'),

});

App.ApproversShowconfirmController = Ember.ObjectController.extend({
	close: function() {
		this.send('hideModal');
	}
});

zen._ApproverEditMixin = Ember.Mixin.create({
	errorText: null,
	addedEmployees: Em.A(),
	removedEmployees: Em.A(),
	addedDepartments: Em.A(),
	removedDepartments: Em.A(),
	addedLocations: Em.A(),
	removedLocations: Em.A(),
	removedTierGroups: Em.A(),
	eligibleApprovers: function() {
		var allSelectedEmployees = this.get('tierGroups').reduce(function(results, group) {
			if (group.get('selectedEmployees')) {
				results.addObjects(group.get('selectedEmployees'));
			}
			if (group.get('addedEmployees')) {
				results.addObjects(group.get('addedEmployees'));
			}
			if (group.get('removedEmployees')) {
				results.removeObjects(group.get('removedEmployees'));
			}
			return results;
		}, []);
		return this.get('employees').filterProperty('isEligibleAsApprover', true).removeObjects(allSelectedEmployees);
	}.property('tierGroups.@each', 'tierGroups.@each.addedEmployees', 'tierGroups.@each.removedEmployees', 'employees'),
	tierGroupsAllSet: function() {
		if (!this.get('tierGroups.length')) {
			return false;
		}
		var notSetTierGroups = this.get('tierGroups').filterProperty('hasFinalEmployees', false);
		return !notSetTierGroups.length;
	}.property('tierGroups.@each.hasFinalEmployees'),
	activeEmployee: function() {
		return this.get('employees').filterProperty('isTerminated', false).filter(function(employee) {
			return employee.get('type') == 'RE' || employee.get('type') == 'IN';
		}.bind(this));
	}.property('employees'),
	managersWithVisibilitySubordinate: function() {
		return this.get('activeEmployee').filterProperty('isManager', true).filterProperty('canViewSubordinates', true);
	}.property('activeEmployee.@each.isManager', 'activeEmployee.@each.canViewSubordinates'),
	isInfoComplete: function() {
		var finalObjects = [];
		finalObjects = finalObjects.addObjects(this.get('selectedEmployees'))
						.addObjects(this.get('selectedLocations'))
						.addObjects(this.get('selectedDepartments'))
						.addObjects(this.get('addedEmployees'))
						.addObjects(this.get('addedDepartments'))
						.addObjects(this.get('addedLocations'))
						.removeObjects(this.get('removedEmployees'))
						.removeObjects(this.get('removedDepartments'))
						.removeObjects(this.get('removedLocations'));
		// TODO: !this.get('tier')
		if (!this.get('tierGroupsAllSet') ||
			!(this.get('canApproveTransfers') || this.get('canApproveHires') || this.get('canApproveSalary') ||
							this.get('canApproveTermination') || this.get('canApproveStockOptionAddition')) ||
			!(finalObjects.length )
			) {
			this.set('errorText', 'Please complete all info.');
			return false;
		}
		this.set('errorText', '');
		return true;
	},
	clearChanges: function() {
		this.set('addedEmployees', Em.A());
		this.set('addedDepartments', Em.A());
		this.set('addedLocations', Em.A());
		this.set('removedEmployees', Em.A());
		this.set('removedDepartments', Em.A());
		this.set('removedLocations', Em.A());
		this.get('tierGroups').forEach(function(tierGroup) {
			tierGroup.set('addedEmployees', Em.A());
			tierGroup.set('removedEmployees', Em.A());
		});
		this.get('removedTierGroups').forEach(function(tierGroup) {
			tierGroup.set('addedEmployees', Em.A());
			tierGroup.set('removedEmployees', Em.A());
		});
		this.set('removedTierGroups', Em.A());
	},
	actions: {
		submit : function() {
			if (!this.isInfoComplete()) {
				return;
			}

			var addedEmployeesCopy = this.get('addedEmployees').slice();
			var addedDepartmentsCopy = this.get('addedDepartments').slice();
			var addedLocationsCopy = this.get('addedLocations').slice();
			var removedEmployeesCopy = this.get('removedEmployees').slice();
			var removedDepartmentsCopy = this.get('removedDepartments').slice();
			var removedLocationsCopy = this.get('removedLocations').slice();
			var tierGroupsCopy = this.get('tierGroups').slice();
			var removedTierGroupsCopy = this.get('removedTierGroups').slice();
			this.set('removedTierGroups',Em.A());

			var approverDetail = this.get('model');
			var promises = [];
			return approverDetail.save().then(function() {
				//New additions
				promises = promises.concat(addedEmployeesCopy.map(function(employee) {
					var employeeList = App.ApproverEmployeeMapping.createRecord({
						"approverDetail": approverDetail,
						"employee": employee
					});
					return employeeList.save();
				}));

				promises = promises.concat(addedDepartmentsCopy.map(function(department) {
					var departmentList = App.ApproverDepartmentMapping.createRecord({
						"approverDetail": approverDetail,
						"department": department
					});
					return departmentList.save();
				}));

				promises = promises.concat(addedLocationsCopy.map(function(location) {
					var locationList = App.ApproverLocationMapping.createRecord({
						"approverDetail": approverDetail,
						"location": location
					});
					return locationList.save();
				}));

				//deletions
				promises = promises.concat(removedEmployeesCopy.map(function(employee) {
					var empListObj = approverDetail.get('employeeMappings').findProperty('employee', employee);
					empListObj.deleteRecord();
					return empListObj.save();
				}));

				promises = promises.concat(removedDepartmentsCopy.map(function(department) {
					var dptListObj = approverDetail.get('departmentMappings').findProperty('department', department);
					dptListObj.deleteRecord();
					return dptListObj.save();
				}));

				promises = promises.concat(removedLocationsCopy.map(function(location) {
					var locListObj = approverDetail.get('locationMappings').findProperty('location', location);
					locListObj.deleteRecord();
					return locListObj.save();
				}));

				return Ember.RSVP.all(promises)
				.then(function() {
					var promises = [];
					promises = tierGroupsCopy.reduce(function(promises, group) {
						var addedEmployeesCopy = group.get('addedEmployees').slice();
						var removedEmployeesCopy = group.get('removedEmployees').slice();
						promises = promises.concat(group.save().then(function() {
							var groupPromises = [];
							groupPromises = groupPromises.concat(addedEmployeesCopy.map(function(employee) {
								var approverTier = App.ApproverTier.createRecord({
									"tierGroup": group,
									"approver": employee,
								});
								return approverTier.save();
							}));
							groupPromises = groupPromises.concat(removedEmployeesCopy.map(function(employee) {
								var approverTierObj = group.get('approverTiers').findProperty('approver', employee);
								approverTierObj.deleteRecord();
								return approverTierObj.save();
							}));
							return groupPromises;
						}));
						return promises;
					}, []);
					return Ember.RSVP.all(promises).then(function() {
						var promises = [];
						promises = removedTierGroupsCopy.reduce(function(promise, group) {
							group.deleteRecord();
							promises = promises.concat(group.save());
							return promises;
						}, []);
						return Ember.RSVP.all(promises);
					});
				}.bind(this))
				.then(function() {
					for (var i = 0; i < this.get('sortedTierGroups.length'); ++i) {
						this.get('sortedTierGroups').objectAt(i).set('tier', i + 1);
						this.get('sortedTierGroups').objectAt(i).save();
					}
				}.bind(this))
				.then(function() {
					// Clear additions/removals once saved
					this.clearChanges();

					return this.get('model').reload();
				}.bind(this))
				.then(function() {
					return this.transitionToRoute('approvers.editpermissions');
				}.bind(this));
			}.bind(this));
		},
		addTierGroup: function() {
			var tier = 1;
			var tiers = this.get('tierGroups').mapProperty('tier');
			var maxTier = Math.max.apply(null, tiers);
			if (maxTier > 0) {
				tier = maxTier + 1;
			}
			var tierGroup = App.TierGroup.createRecord({
				"tier": tier,
				"approverDetail": this.get('model'),
			});
			this.get('sortedTierGroups').addObject(tierGroup);
		},
		removeTierGroup: function(tierGroup) {
			this.get('removedTierGroups').addObject(tierGroup);
			this.get('sortedTierGroups').removeObject(tierGroup);
		},
	},
});

App.ApproversAddapproverRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function(params) {
		var id = params['approver_detail_id'];
		if (id == 'null' || id == 'undefined') {
			this.transitionTo('approvers.editpermissions');
			return;
		}
		return App.ApproverDetail.find(id);
	},
	afterModel: function(model) {
		if (!model.get('tierGroups.length')) {
			App.TierGroup.createRecord({
				"tier": 1,
				"approverDetail": model,
			});
		}
		return this.all(model, {
			"employees": dashboard("employees"),
			"allDepartments": App.Department.find(),
			"allLocations": dashboard('company.locations'),
			"showExperimental": dashboard('company.showExperimental'),
			"_tierGroupsCache": thenpath(model, 'tierGroups').then(function(groups) {
				var promises = groups.reduce(function(promises, group) {
					if (group.get('approverTiers')) {
						promises.concat(group.get('approverTiers'));
					}
					return promises;
				}, []);
				return Ember.RSVP.all(promises);
			}),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var sortedTierGroups = Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['tier'],
			content: controller.get('tierGroups'),
		});
		controller.set("sortedTierGroups", sortedTierGroups);
	},
});

App.ApproversAddapproverController = zen._AbstractSavingController.extend(zen._ApproverEditMixin, zen._RollbackOnDeactivateMixin, {
	selectedEmployees: function() {
		if (this.get('employeeMappings')) {
			return this.get('employeeMappings').mapProperty('employee');
		}
		return Em.A();
	}.property('employeeMappings.@each.employee'),
	selectedDepartments: function() {
		if (this.get('departmentMappings')) {
			return this.get('departmentMappings').mapProperty('department');
		}
		return Em.A();
	}.property('departmentMappings.@each.department'),
	selectedLocations: function() {
		if (this.get('locationMappings')) {
			return this.get('locationMappings').mapProperty('location');
		}
		return Em.A();
	}.property('locationMappings.@each.location'),
	showTierDetails: function() {
		this.send('showModal', 'approvers.notification');
	},
	actions: {
		cancel: function() {
			//FIXME rahul: this does not work when some one has edited ad object, it fails to delete (ideally we should hide the model
			//after deleting the record but temp fix to hide the model if delete fails.
			this.set('errorText', '');
			this.clearChanges();
			if (this.get('model.id')) {
				this.get('model').reload().then(function() {
					this.transitionToRoute("approvers.editpermissions");
				}.bind(this));
			}
			else {
				this.transitionToRoute("approvers.editpermissions");
			}
		},
	},
});

App.ApproversDeleteapproverController = zen._AbstractSavingController.extend({
	submit: function() {
		var a = this.get('model');
		a.deleteRecord();
		a.save().then(function() {
			this.send('hideModal');
		}.bind(this));
	},
	cancel: function() {
		this.send('hideModal');
	}
});

App.ApproversNotificationController = Ember.ObjectController.extend({
	submit: function() {
		this.send('hideModal');
	}
});

App.ApproversEditpermissionsRoute = Ember.Route.extend(zen._MultiModelMixin, zen._RollbackOnDeactivateMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('employees'),
			{
				"approverSettings": dashboard('company.approverSettings'),
				"approverDetails": App.ApproverDetail.find(),
				"approverEmployeeMapping": App.ApproverEmployeeMapping.find(),
				"approverDepartmentMapping": App.ApproverLocationMapping.find(),
				"approverLocationMapping": App.ApproverDepartmentMapping.find(),
				"employees": dashboard("employees"),
				"allDepartments": App.Department.find(),
				"allLocations": dashboard('company.locations'),
				"showExperimental": dashboard('company.showExperimental'),
				"company": dashboard('company'),
			});
	}
});

App.ApproversEditpermissionsController = zen._AbstractArraySavingController.extend({
	addApproverDetail: function() {
		var approverDetail = App.ApproverDetail.createRecord({
			"company": this.get('company'),
			"canApproveHires": false,
			"canApproveSalary": false,
			"canApproveTransfers": false,
			"canApproveStockOptionAddition": false,
			"canApproveTermination": false,
		});
		this.transitionToRoute("approvers.addapprover", approverDetail);
	},
	deleteApproverDetail: function(ad) {
		this.send('showModal', 'approvers.deleteapprover', ad);
	},
});

App.AttributehistoryController = Ember.ObjectController.extend({
	isSalaryChange: Ember.computed.anyProperty('changes', 'isSalaryChange'),
	isTitleChange: Ember.computed.anyProperty('changes', 'isTitleChange'),
	isLocationChange: Ember.computed.anyProperty('changes', 'isLocationChange'),
	isDepartmentChange: Ember.computed.anyProperty('changes', 'isDepartmentChange'),
	isManagerChange: Ember.computed.anyProperty('changes', 'isManagerChange'),
	isHireDateChange: Ember.computed.anyProperty('changes', 'isHireDateChange'),
	isEmploymentTypeChange: Ember.computed.anyProperty('changes', 'isEmploymentTypeChange'),
	isPayScheduleChange: Ember.computed.anyProperty('changes', 'isPayScheduleChange'),
	sortedData: function() {
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: ['changeDate', 'id'],
			content: this.get('model.changes'),
			sortAscending: false,
		});
	}.property('model.changes.[]'),
	cancel: function() {
		this.send('hideModal');
	},
});

App.OverrideOfferController = zen._AbstractSavingController.extend({
	confirm: function() {
		var group = this.get('model.changeRequest.group');
		var newHire = this.get('model');
		group.set('status', 'over_written');
		group.save().then(function() {
			return newHire.get('employee').reload();
		}.bind(this))
		.then(function() {
			return newHire.reload();
		}.bind(this))
		.then(function() {
			this.transitionToRoute('hiring.basicinfo', newHire);
		}.bind(this));
	},
	hide: function() {
		this.send('hideModal');
	}
});


App.ApproverRequestDetailRoute = App.ApproverDeleteRequestDetailRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
	afterModel: function(model) {
		var promises = [];
		var changeRequest = this.get('model');
		if (changeRequest.get('salaryChange')) {
			promises.push(changeRequest.get('salaryChange'));
		}
		if (changeRequest.get('managerChange')) {
			promises.push(changeRequest.get('managerChange'));
		}
		if (changeRequest.get('locationChange')) {
			promises.push(changeRequest.get('salaryChange'));
		}
		if (changeRequest.get('departmentChange')) {
			promises.push(changeRequest.get('departmentChange'));
		}
		if (changeRequest.get('titleChange')) {
			promises.push(changeRequest.get('titleChange'));
		}
		if (changeRequest.get('hireDateChange')) {
			promises.push(changeRequest.get('hireDateChange'));
		}
		if (changeRequest.get('employmentTypeChange')) {
			promises.push(changeRequest.get('employmentTypeChange'));
		}
		promises.push(changeRequest.get('group'));
		return this.all(model, {
			"employees": dashboard('employees'),
			"locations": thenpath(dashboard('company'), 'locations'),
			"departments": thenpath(dashboard('company'), 'departments'),
			"_relatedObjects": Ember.RSVP.all(promises),
		});
	}
});

App.ApproversRequestDetailController = App.ApproversDeleteRequestDetailController = Ember.ObjectController.extend({
	submit: function() {
		this.send('hideModal');
	},
	delete: function() {
		var changeRequest = this.get('model');
		var group = changeRequest.get('group');
		if (group.get('status') === 'completed') {
			this.eventLogger.log('cancel_completed_change_request_group');
		} else {
			group.set('status', 'over_written');
		}
		group.save().then(function() {
			this.send('hideModal');
		}.bind(this));
	}
});

App.TransferemployeeRoute = App.RequestchangesRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, zen._AuthAnyMixin, zen._RollbackOnDeactivateMixin, {
	model: function(params) {
		var id = params['change_request_id'];
		if (id == 'null') {
			this.transitionTo('employeedirectory');
			return;
		}
		return App.ChangeRequest.find(id);
	},
	afterModel: function(model) {
		var today = new Date();
		return this.all(model, {
			"effectiveDate": moment(today).format('MM/DD/YYYY'),
			"company": dashboard("company"),
			"companyPayroll": dashboard("company.payroll"),
			"companyPayrollDepartments": dashboard("company.payrollDepartments"),
			"companyPaySchedules": dashboard("company.paySchedules"),
			"requester": dashboard("employee"),
			"employees": dashboard("employees"),
			"_employeePayrollSettings": dashboard("employees").then(function(employees) {
				return Ember.RSVP.all(employees.filterProperty('notContractor').mapProperty('payroll'));
			}),
			"isAdmin": dashboard('isAdmin'),
			"departments": App.Department.find(),
			"locations": dashboard('company.locations'),
			"_locationChange": thenpath(model, 'locationChange'),
			"_departmentChange": thenpath(model, 'departmentChange'),
			"_managerChange": thenpath(model, 'managerChange'),
			"_salaryChange": thenpath(model, 'salaryChange'),
			"_titleChange": thenpath(model, 'titleChange'),
			"_hireDateChange": thenpath(model, 'hireDateChange'),
			"_employmentTypeChange": thenpath(model, 'employmentTypeChange'),
			"_payScheduleChange": thenpath(model, 'payScheduleChange'),
			"errorText": null,
			"isManagerButNotAdmin": dashboard('managerAndNotAdminWithHirePermissions'),
			"canAccessSensitive": dashboard('canAccessSensitive'),
			"allRequests": thenpath(model, 'group')
			.then(function(group) {
				return wrapArrayPromise(group.get('requests'));
			}.bind(this))
			.then(function(requests) {
				if (requests) {
					var promises = requests.reduce(function(promises, changeRequest) {
						if (changeRequest.get('managerChange')) {
							promises.push(changeRequest.get('managerChange'));
						}
						promises.push(changeRequest.get('group'));
						return promises;
					}, []);
					return wrapArrayPromise(promises);
				}
			}.bind(this)),
			"activeChangeRequests": thenpath(model,'employee.changeRequests'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var activeChangeRequests = model.get('activeChangeRequests');
		if(activeChangeRequests==null){
			activeChangeRequests = [];
		}
		activeChangeRequests = activeChangeRequests.filterProperty('isGroupReadyToProcess');
		var existingTitleChanges = activeChangeRequests.mapBy('titleChange').compact();
		var existingSalaryChanges = activeChangeRequests.mapBy('salaryChange').compact();
		var existingLocationChanges = activeChangeRequests.mapBy('locationChange').compact();
		var existingDepartmentChanges = activeChangeRequests.mapBy('departmentChange').compact();
		var existingManagerChanges = activeChangeRequests.mapBy('managerChange').compact();
		var existingEmploymentChanges = activeChangeRequests.mapBy('employmentTypeChange').compact();
		var existingHireDateChanges = activeChangeRequests.mapBy('hireDateChange').compact();
		var existingPayScheduleChanges = activeChangeRequests.mapBy('payScheduleChange').compact();

		controller.set("existingTitleChanges",existingTitleChanges);
		controller.set("showExistingTitleChange", !!existingTitleChanges.length);
		controller.set("existingSalaryChanges",existingSalaryChanges);
		controller.set("showExistingSalaryChange", !!existingSalaryChanges.length);
		controller.set("existingLocationChanges",existingLocationChanges);
		controller.set("showExistingLocationChange", !!existingLocationChanges.length);
		controller.set("existingDepartmentChanges",existingDepartmentChanges);
		controller.set("showExistingDepartmentChange", !!existingDepartmentChanges.length);
		controller.set("existingManagerChanges",existingManagerChanges);
		controller.set("showExistingManagerChange", !!existingManagerChanges.length);
		controller.set("existingEmploymentChanges",existingEmploymentChanges);
		controller.set("showExistingEmploymentChange", !!existingEmploymentChanges.length);
		controller.set("existingPayScheduleChanges",existingPayScheduleChanges);
		controller.set("showExistingPayScheduleChange", !!existingPayScheduleChanges.length);
		controller.set("existingHireDateChanges",existingHireDateChanges);
		controller.set("showExistingHireDateChange", !!existingHireDateChanges.length);
		controller.set('group.applyImmediately', null);
	}
});

App.ChangerequestNotificationController = Ember.ObjectController.extend({
	submit: function() {
		this.send('hideModal');
	}
});

zen._LocationCascadingChangeMixin = Ember.Mixin.create({
	employeesWithoutSelf: function() {
		if (!this.get('employee')) {
			return [];
		}
		return this.get('employees').filterProperty('isAllActiveEmployee').filter(function(employee) {
			return employee != this.get('employee');
		}.bind(this));
	}.property('employee', 'employees.[]'),
	hasSalaryCascadingChange: function() {
		if (this.get('salaryChange') && this.get('company.hasActiveSyncedPayroll') && this.get('employee.payrollId')) {
			return true;
		}
		return false;
	}.property('company.hasActiveSyncedPayroll', 'salaryChange', 'employee.payrollId'),

	isSwitchActive: Ember.computed.bool('switches.pyp_psi_impacting_payroll_change'),

	hasPYPImpactingChange: Ember.computed('isPayrollProviderYP', 'salaryChange', function (){
		if (!this.get('isSwitchActive')) {
			return;
		}
		return this.get('isPayrollProviderYP')
			&& this.get('salaryChange');
	}),
	hasLocationCascadingChange: function() {
		var temp = this.get('locationChange') && this.get('locationChange.location.state');
		return temp && (this.get('locationChange.location.state') != this.get('employee.location.state'));
	}.property('locationChange','locationChange.location.state','employee.location.state'),
	inferredPayrollDepartment: Ember.computed('companyPayroll.departmentMapping', 'departmentChange.departmentNameHelper', function() {
		var deptMapStr = this.get('companyPayroll.departmentMapping');
		if (deptMapStr) {
			var deptMap = null;
			try {
				deptMap = JSON.parse(deptMapStr);
			}
			catch(e) {
			// no-op
			}
			if (deptMap && (this.get('departmentChange.departmentNameHelper') in deptMap)) {
				return deptMap[this.get('departmentChange.departmentNameHelper')];
			}
		}
		return null;
	}),
	hasWfSupervisorChoices: Ember.computed.gt('wfSupervisorChoices.length', 0),
	showPayrollSupervisorChange: Ember.computed.and('hasWfSupervisorChoices', 'employee.payroll.usesWfTimeAndAttendance', 'companyPayroll.usesWfTimeAndAttendance', 'isUnderWFCustomProtocolBetaTest'),
});

zen._ChangeRequestMixin = Ember.Mixin.create(zen._LocationCascadingChangeMixin, zen._PayrollSettingsMixin, {
	showEmptyReasonError: false,
	groupNotApplyImmediately: function() {
		return this.get('group.applyImmediately') != null && !this.get('group.applyImmediately');
	}.property('group.applyImmediately'),
	canShowProTip: function() {
		if (!this.get('isManagerUpdated') || !this.get('changedManager.fullName')) {
			return false;
		}
		if (this.get('isAdmin')) {
			return this.get('changedManager.canAdminister');
		}
		else {
			return this.get('changedManager.canManage');
		}
	}.property('isManagerUpdated', 'changedManager.fullName', 'changedManager.canManage', 'isAdmin'),
	canViewSubordinatesComp: function() {
		if (this.get('requester.permission.canViewSubordinatesComp')) {
			return true;
		}
		if (this.get('isManagerButNotAdmin')) {
			return false;
		}
		else {
			return this.get('canAccessSensitive');
		}
	}.property('requester.permission.canViewSubordinatesComp', 'canAccessSensitive', 'isManagerButNotAdmin'),
	effectiveDateGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('group.applyImmediately', value === "yes");
			if (this.get('group.applyImmediately')) {
				this.set('group.effectiveDate', null);
			}
		}
		if (this.get('group.applyImmediately') == null) {
			return null;
		}
		return this.get('group.applyImmediately') ? "yes" : "no";
	}.property('group.applyImmediately'),

	changedHireDate: function(key, value) {
		var hireDateChange= this.get('hireDateChange');
		if (arguments.length > 1) {
			if (hireDateChange) {
				hireDateChange.set('hireDate', value);
			}
			else {
				hireDateChange = App.HireDateChange.createRecord({
					"hireDate": value,
					"requestedBy": this.get('requester'),
				});
				this.set('hireDateChange', hireDateChange);
			}
		}
		if (hireDateChange) {
			return hireDateChange.get('hireDate');
		}
		else {
			return this.get('employee.hireDate');
		}
	}.property('hireDateChange.hireDate', 'employee.hireDate'),
	changedHireDateValid: function(){
		var dateRegEx = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2})*$");
		var changedHireDate = this.get('changedHireDate');
		return dateRegEx.test(changedHireDate) && changedHireDate;
	}.property('changedHireDate'),
	isHireDateUpdated: function() {
		if (this.get('employee.hireDate') == this.get('changedHireDate')) {
			return false;
		}
		return moment(this.get('employee.hireDate')).diff(this.get('changedHireDate')) !== 0;
	}.property('employee.hireDate', 'changedHireDate'),

	changedEmploymentType: function(key, value) {
		var employmentTypeChange= this.get('employmentTypeChange');
		if (arguments.length > 1) {
			if (employmentTypeChange) {
				employmentTypeChange.set('employmentType', value);
			}
			else {
				employmentTypeChange = App.EmploymentTypeChange.createRecord({
					"employmentType": value,
					"requestedBy": this.get('requester'),
				});
				this.set('employmentTypeChange', employmentTypeChange);
			}
		}
		if (employmentTypeChange) {
			return employmentTypeChange.get('employmentType');
		}
		else {
			return this.get('employee.employmentType');
		}
	}.property('employmentTypeChange.employmentType', 'employee.employmentType'),
	isEmploymentTypeUpdated: Ember.computed.arePropertiesUnEqual('employee.employmentType', 'changedEmploymentType'),

	showPaySchedule: function() {
		return !this.get('isManagerButNotAdmin') &&
				this.get('employee.company.hasPayScheduleFeatureEnabled') &&
				this.get('payScheduleChoices') &&
				this.get('payScheduleChoices').length > 0;
	}.property('payScheduleChoices', 'employee.company.hasPayScheduleFeatureEnabled', 'isManagerButNotAdmin'),

	payScheduleChoices: function () {
		var paySchedules = this.get('companyPaySchedules').filterProperty('status', 'ACTIVE');
		var today = moment();
		var actualEffectiveDate = this.get('group.applyImmediately') ? today : this.get('group.effectiveDate');
		return paySchedules.filter(function(paySchedule) {
			return (!paySchedule.anchorStartDate || !actualEffectiveDate ||
							!moment(actualEffectiveDate).isBefore(moment(paySchedule.get('anchorStartDate'))));
		});
	}.property('companyPaySchedules', 'group.applyImmediately', 'group.effectiveDate'),

	changedPaySchedule: function(key, value) {
		var payScheduleChange = this.get('payScheduleChange');
		if (arguments.length > 1) {
			if (payScheduleChange) {
				payScheduleChange.set('paySchedule', value);
			}
			else {
				payScheduleChange = App.PayScheduleChange.createRecord({
					"paySchedule": value,
					"requestedBy": this.get('requester'),
					"forZPOnboarding": false
				});
				this.set('payScheduleChange', payScheduleChange);
			}
		}
		if (payScheduleChange) {
			return payScheduleChange.get('paySchedule');
		}
		else {
			return this.get('employee.paySchedule');
		}
	}.property('payScheduleChange.paySchedule', 'employee.payScheduleChange'),
	isPayScheduleUpdated: Ember.computed.arePropertiesUnEqual('employee.paySchedule', 'changedPaySchedule'),

	changedTitle: function(key, value) {
		var titleChange= this.get('titleChange');
		if (arguments.length > 1) {
			if (titleChange) {
				titleChange.set('title', value);
			}
			else {
				titleChange = App.TitleChange.createRecord({
					"title": value,
					"requestedBy": this.get('requester'),
				});
				this.set('titleChange', titleChange);
			}
		}
		if (titleChange) {
			return titleChange.get('title');
		}
		else {
			return this.get('employee.title');
		}
	}.property('titleChange.title', 'employee.title'),
	isTitleUpdated: Ember.computed.arePropertiesUnEqual('employee.title', 'changedTitle'),
	changedDepartment: function(key, value) {
		var department = value;
		if (value && value.name === 'No Department') {
			department = null;
		}
		var departmentChange= this.get('departmentChange');
		if (arguments.length > 1) {
			if (departmentChange) {
				departmentChange.set('department', department);
			}
			else {
				departmentChange = App.DepartmentChange.createRecord({
					"department": department,
					"requestedBy": this.get('requester'),
				});
				this.set('departmentChange', departmentChange);
			}
			if (this.get("inferredPayrollDepartment")) {
				departmentChange.set("payrollDepartmentCode", this.get("inferredPayrollDepartment"));
			}
		}
		if (departmentChange) {
			return departmentChange.get('department');
		}
		else {
			return this.get('employee.department');
		}
	}.property('departmentChange.department', 'employee.department'),
	isDepartmentUpdated: Ember.computed.arePropertiesUnEqual('employee.department', 'changedDepartment'),

	changedLocation: function(key, value) {
		var locationChange= this.get('locationChange');
		if (arguments.length > 1) {
			if (locationChange) {
				locationChange.set('location', value);
			}
			else {
				locationChange = App.LocationChange.createRecord({
					"location": value,
					"requestedBy": this.get('requester'),
				});
				this.set('locationChange', locationChange);
			}
		}
		if (locationChange) {
			return locationChange.get('location');
		}
		else {
			return this.get('employee.location');
		}
	}.property('locationChange.location', 'employee.location'),
	isLocationUpdated: Ember.computed.arePropertiesUnEqual('employee.location', 'changedLocation'),

	changedManager: function(key, value) {
		var managerChange= this.get('managerChange');
		if (arguments.length > 1) {
			if (managerChange) {
				managerChange.set('manager', value);
			}
			else {
				managerChange = App.ManagerChange.createRecord({
					"manager": value,
					"requestedBy": this.get('requester'),
				});
				this.set('managerChange', managerChange);
			}
		}
		if (managerChange) {
			return managerChange.get('manager');
		}
		else {
			if (this.get('grabTransfer')) {
				return null;
			}
			else {
				return this.get('employee.reportToEmployee');
			}
		}
	}.property('managerChange.manager', 'employee.reportToEmployee'),
	isManagerUpdated: function() {
		if (!this.get('managerChange')) {
			return false;
		}
		return this.get('employee.reportToEmployee') != this.get('managerChange.manager');
	}.property('managerChange', 'managerChange.manager', 'employee.reportToEmployee'),

	changedSalary: function(key, value) {
		var salaryChange= this.get('salaryChange');
		if (arguments.length > 1) {
			if (salaryChange) {
				salaryChange.set('salary', value);
			}
			else {
				salaryChange = App.SalaryChange.createRecord({
					"salary": value,
					"compType": 'S',
					"requestedBy": this.get('requester'),
				});
				this.set('salaryChange', salaryChange);
			}
		}
		if (salaryChange && salaryChange.get('salary') != null) {
			return salaryChange.get('salary');
		}
		else {
			return this.get('employee.salary');
		}
	}.property('salaryChange.salary', 'employee.salary'),

	changedPayRate: function(key, value) {
		var salaryChange= this.get('salaryChange');
		if (arguments.length > 1) {
			if (salaryChange) {
				salaryChange.set('payRate', value);
			}
			else {
				salaryChange = App.SalaryChange.createRecord({
					"payRate": value,
					"compType": 'H',
					"requestedBy": this.get('requester'),
				});
				this.set('salaryChange', salaryChange);
			}
		}
		if (salaryChange && salaryChange.get('payRate') != null) {
			return salaryChange.get('payRate');
		}
		else {
			return this.get('employee.payRate');
		}
	}.property('salaryChange.payRate', 'employee.payRate'),

	changedCompType: function(key, value) {
		var salaryChange= this.get('salaryChange');
		if (arguments.length > 1) {
			if (salaryChange) {
				salaryChange.set('compType', value);
				salaryChange.set('payRate',  value == 'H' ? this.get('changedPayRate'): null);
				salaryChange.set('salary',  value == 'S' ? this.get('changedSalary'): null);
			}
			else {
				salaryChange = App.SalaryChange.createRecord({
					"compType": value,
					"requestedBy": this.get('requester'),
					"payRate":  value == 'H' ? this.get('changedPayRate'): null,
					"salary":  value == 'S' ? this.get('changedSalary'): null,
				});
				this.set('salaryChange', salaryChange);
			}
		}
		if (salaryChange && salaryChange.get('compType')) {
			return salaryChange.get('compType');
		}
		else {
			return this.get('employee.compType');
		}
	}.property('salaryChange.compType', 'employee.compType'),

	isChangedSalary: Ember.computed.equal('changedCompType', 'S'),
	isChangedHourly: Ember.computed.equal('changedCompType', 'H'),

	changedSalaryByPayFrequency: function() {
		var salary = this.get('changedSalary');
		if (Ember.isEmpty(salary)) {
			return "";
		}
		var payFreq = this.get('employee.payFrequency');
		if (payFreq == 'SM') { salary *= 0.5; }
		else if (payFreq == 'BW') { salary *= (1/26)*12; }
		else if (payFreq == 'We') { salary *= (1/52)*12; }
		else if (payFreq == 'Mo') { salary *= 1; }
		else { return ""; }
		return Number(Number(String(salary).replace(/,/g,'')).toFixed(2)).toLocaleString();
	}.property('changedSalary','employee.payFrequency'),

	changedSalaryAnnualNoCommas: function(key, value) {
		if (value != null) {
			this.set('changedSalary', (Number(value.replace(/,/g,'')) / 12));
		}
		var changedSalary = this.get('changedSalary');
		if (Ember.isEmpty(changedSalary)) {
			return "";
		}
		changedSalary = Math.round(changedSalary * 1200) / 100;
		return (String(changedSalary).replace(/,/g,'')).toString();
	}.property('changedSalary'),

	isSalaryUpdated: function () {
		return (this.get('changedSalary') != this.get('employee.salary') ) ||
			(this.get('changedPayRate') != this.get('employee.payRate') ) ||
			(this.get('changedCompType') != this.get('employee.compType') &&
				(this.get('salaryChange.salary') || this.get('salaryChange.payRate')));
	}.property('changedSalary', 'changedPayRate', 'employee.payRate', 'employee.changedCompType', 'employee.compType', 'salaryChange.salary', 'salaryChange.payRate'),

	employeesWithoutRequester: Ember.computed.rejectByProperty('employees', 'isRequester'),

	teamEmployees: function() {
		if (!this.get('employee')) {
			return [];
		}
		return this.get('employees').filterProperty('isAllActiveEmployee').filterProperty('canManage').addObject(this.get('requester'));
	}.property('model.employee', 'employees.[]', 'requester'),

	showTeamEmployees: function() {
		return this.get('isManagerButNotAdmin') && !this.get('employee.canManage');
	}.property('isManagerButNotAdmin', 'employee.canManage'),

	employeeAllowedLocations: function() {
		if (!this.get('employee')) {
			return [];
		}
		return this.get('employee.allowedLocations');
	}.property('model.employee'),

	existingReports: function() {
		if (!this.get('employees') || !this.get('employee')) {
			return [];
		}
		return this.get('employees').filterProperty('reportToEmployee', this.get('employee')).rejectProperty('status', 'Ter');
	}.property('employees.@each.reportToEmployee', 'employee'),

	//list of transfer request already associated with this request
	transferRequests: function() {
		if (!this.get('group.requests')) {
			return [];
		}
		return this.get('group.requests').reduce(function(transferRequests, request) {
			if (this.get('model') != request) {
				transferRequests.push(request);
			}
			return transferRequests;
		}.bind(this), []);
	}.property('group.requests.[]', 'model'),

	//list of employees you have been added to this employee
	previousAddedReports: function() {
		return this.get('transferRequests').reduce(function(addedReports, request) {
			if (request.get('managerChange.manager') === this.get('employee')) {
				addedReports.push(request.get('employee'));
			}
			return addedReports;
		}.bind(this), []);
	}.property('transferRequests.[]', 'employee'),

	//list of employees you have been added to this employee
	previousDeletedReports: function() {
		return this.get('transferRequests').reduce(function(addedReports, request) {
			if (request.get('managerChange.manager') != this.get('employee')) {
				addedReports.push(request.get('employee'));
			}
			return addedReports;
		}.bind(this), []);
	}.property('transferRequests.[]', 'employee'),

	//previous list of employees when this request was saved.
	selectedItems: function() {
		if (!this.get('employee')) {
			return Em.A();
		}
		//existingReports + previousAddedReports - previousDeletedReports
		var temp = this.get('existingReports')
		.concat(this.get('previousAddedReports'));
		return temp.removeObjects(this.get('previousDeletedReports'));
	}.property('existingReports.[]', 'previousAddedReports.[]' , 'previousDeletedReports.[]', 'employee', 'existingReports.length'),

	//newSelectedSet
	newSelectedSet: function() {
		var aI = this.get('addedItems') || [];
		var rI = this.get('removedItems') || [];
		var temp = this.get('selectedItems').concat(aI);
		return temp.removeObjects(rI);
	}.property('selectedItems.[]', 'addedItems.[]', 'removedItems.[]'),

	hasHireDateCascadingChange: function() {
		if (this.get('hireDateChange')) {
			return true;
		}
		return false;
	}.property('hireDateChange'),

	changedHireDateChangeType: function(key, value) {
		var hireDateChange = this.get('hireDateChange');
		if (arguments.length > 1) {
			if (hireDateChange) {
				hireDateChange.set('changeType', value);
			}
			// do nothing if hireDateChange is not there. changeType can only be set if hireDateChange exists.
		}
		if (hireDateChange) {
			return hireDateChange.get('changeType');
		}
		else {
			return null;
		}
	}.property('hireDateChange', 'hireDateChange.changeType'),

	hasDepartmentCascadingChange: function() {
		if (this.get('departmentChange') && this.get('existingReports.length')) {
			return true;
		}
		if (this.get('departmentChange') && this.get('payrollDepartmentChoices') != null) {
			return true;
		}
		if (this.get('managerChange.manager') && !this.get('departmentChange')) {
			return this.get('employee.department') != this.get('managerChange.manager.department');
		}
		return false;
	}.property('departmentChange', 'existingReports.length', 'employee.department', 'managerChange.manager.department', 'payrollDepartmentChoices'),

	hasEmploymentTypeCascadingChange: function() {
		return this.get('employmentTypeChange.employmentType');
	}.property('employmentTypeChange.employmentType'),

	adminBlockPastDate: Ember.computed('employee.hireDate', 'payrollProvider', function() {
		var employeeHireDate =  this.get('employee.hireDate');
		var prp = this.get('company.payrollProvider');
		return ((moment().diff(employeeHireDate, 'days') > 0) && (prp == 'ZN') && !this.get('isDirty'));
	}),
	adminWarnPastDate: Ember.computed('employee.hireDate', 'payrollProvider', function() {
		var employeeHireDate =  this.get('employee.hireDate');
		var prp = this.get('company.payrollProvider');
		var syncType = this.get('company.payrollSyncType');
		if ((moment().diff(employeeHireDate, 'days') > 0) && (syncType === 'Sync') && (prp === 'ZN') && this.get('isDirty')) {
			return "This date is in the past. Once you make this change, it cannot be undone.";
		}
	}),

	adminPayrollProviderFullname: Ember.computed(function() {
			var prp = this.get('company.payrollProvider');
			if (prp == 'ZN') {
				return "Gusto";
			}
			return "Your payroll provider";
	}),

	observeReason: function() {
		if(this.get('group.reason')){
			this.set('showEmptyReasonError', false);
		}
	}.observes('group.reason'),

	showExisting: function(existingChange, changeType) {
		existingChange.set(changeType,true);
		existingChange["showProperty"] = "showExisting"+changeType.substr(2);
		this.send('showModal', 'approvers.existingRequestDetail', existingChange);
	},

	hasManagerCascadingChange: function() {
		if (this.get('managerChange') && (this.get('existingReports.length') || this.get('showPayrollSupervisorChange'))) {
			return true;
		}
		return false;
	}.property('managerChange', 'existingReports.length', 'showPayrollSupervisorChange'),

	actions: {
		showNotificationDetails: function() {
			var data = Ember.Object.create({
				"isManagerButNotAdmin": this.get('isManagerButNotAdmin'),
			});
			this.send('showModal', 'changerequest.notification', data);
		},
		confirm: function() {
			this.set('errorText', '');
			if (!this.get('employee')) {
				this.set('errorText', 'Please select an employee for this change.');
				return;
			}

			if (!this.get('group.reason')) {
				this.set('showEmptyReasonError', true);
				this.set('errorText', 'Please provide a reason for this change.');
				return;
			}

			if (this.get('group.reason.length') > 10000) {
				this.set('errorText', "Reason can't have more then 10,000 characters.");
				return;
			}

			if (!this.get('group.applyImmediately') && !this.get('group.effectiveDate')) {
				this.set('errorText', 'Please provide an effective date for this change.');
				return;
			}

			if (!this.get('changedTitle') && this.get('employee.title')) {
				this.set('errorText', 'Proposed title can not be empty.');
				return;
			}

			if (!this.get('changedPaySchedule') && this.get('employee.paySchedule')) {
				this.set('errorText', 'Proposed Pay Schedule can not be empty.');
				return;
			}

			if (!this.get('changedDepartment') && this.get('employee.department')) {
				this.set('errorText', 'Proposed department can not be empty.');
				return;
			}

			if (!this.get('changedLocation') && this.get('employee.location')) {
				this.set('errorText', 'Proposed location can not be empty.');
				return;
			}

			if (!this.get('grabTransfer')) {
				if (!this.get('changedCompType') && this.get('compType')) {
					this.set('errorText', 'Proposed compensation type can not be empty.');
					return;
				}

				if (!this.get('changedSalary') && this.get('employee.salary')) {
					this.set('errorText', 'Proposed salary can not be empty.');
					return;
				}

				if (!this.get('changedPayRate') && this.get('employee.payRate')) {
					this.set('errorText', 'Proposed payRate can not be empty.');
					return;
				}

				if (!this.get('changedHireDate') && this.get('employee.hireDate')) {
					this.set('errorText', 'Proposed hire date can not be empty.');
					return;
				}

				if (!this.get('changedEmploymentType') && this.get('employee.employmentType')) {
					this.set('errorText', 'Proposed employment type can not be empty.');
					return;
				}
			}

			if (this.get('isManagerButNotAdmin') && !this.get('changedManager') && this.get('employee.reportToEmployee')) {
				this.set('errorText', 'Proposed manager can not be empty.');
				return;
			}

			if (!this.get('isTitleUpdated')) {
				this.set('titleChange', null);
			}

			if (!this.get('isPayScheduleUpdated')) {
				this.set('payScheduleChange', null);
			}

			if (!this.get('isDepartmentUpdated')) {
				this.set('departmentChange', null);
			}

			if (!this.get('isLocationUpdated')) {
				this.set('locationChange', null);
			}

			if (!this.get('isManagerUpdated')) {
				this.set('managerChange', null);
			}

			if (!this.get('isSalaryUpdated')) {
				this.set('salaryChange', null);
			}

			if (!this.get('isHireDateUpdated') || !this.get('changedHireDateValid')) {
				this.set('hireDateChange', null);
			}

			if (!this.get('isEmploymentTypeUpdated') || !this.get('employmentTypeChange.employmentType')) {
				this.set('employmentTypeChange', null);
			}

			var addedSet = [].concat(this.get('newSelectedSet'));
			addedSet.removeObjects(this.get('existingReports'));
			var removedSet = [].concat(this.get('existingReports'));
			removedSet.removeObjects(this.get('newSelectedSet'));

			if (!(this.get('isTitleUpdated') || this.get('isDepartmentUpdated') || this.get('isManagerUpdated') ||
				this.get('isLocationUpdated') || this.get('isSalaryUpdated') || this.get('isHireDateUpdated') ||
				this.get('isPayScheduleUpdated') || this.get('isEmploymentTypeUpdated') ||
				addedSet.length || removedSet.length)) {
				this.set('errorText', 'Please change at least one detail in employee.');
				return;
			}

			var didFailed = false;
			var errorFunction = function(error) {
				didFailed = true;
			};

			return this.get('group').save()
			.then(function() {
				if (this.get('titleChange')) {
					return this.get('titleChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('departmentChange')) {
					return this.get('departmentChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('payScheduleChange')) {
					return this.get('payScheduleChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('locationChange')) {
					return this.get('locationChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('salaryChange') && this.get('salaryChange.compType')) {
					return this.get('salaryChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('managerChange')) {
					return this.get('managerChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('hireDateChange') && this.get('hireDateChange.hireDate')) {
					return this.get('hireDateChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				if (this.get('employmentTypeChange') && this.get('employmentTypeChange.employmentType')) {
					return this.get('employmentTypeChange').save();
				}
			}.bind(this), errorFunction)
			.then(function() {
				var promises;
				if (this.get('transferRequests')) {
					promises = this.get('transferRequests').reduce(function(promises, transferRequest) {
						transferRequest.deleteRecord();
						promises.push(transferRequest.save());
						return promises;
					}.bind(this), []);
					return Ember.RSVP.all(promises);
				}
			}.bind(this), errorFunction)
			.then(function() {
				var promises;
				if (addedSet) {
					promises = addedSet.map(function(newEmployee) {
						var managerChange = App.ManagerChange.createRecord({
							"manager": this.get('employee'),
							"requestedBy": this.get('requester'),
						});
						return managerChange.save().then(function() {
							var changeRequest = App.ChangeRequest.createRecord({
								"employee": newEmployee,
								group: this.get('group'),
								managerChange: managerChange
							});
							return changeRequest.save();
						}.bind(this), errorFunction);
					}.bind(this));
					return Ember.RSVP.all(promises);
				}
			}.bind(this), errorFunction)
			.then(function() {
				var promises;
				if (removedSet) {
					promises = removedSet.map(function(newEmployee) {
						var changeRequest = App.ChangeRequest.createRecord({
							"employee": newEmployee,
							group: this.get('group'),
						});
						return changeRequest.save();
					}.bind(this), errorFunction);
					return Ember.RSVP.all(promises);
				}
			}.bind(this), errorFunction)
			.then(function() {
				return this.get('model').save();
			}.bind(this), errorFunction)
			.then(function() {
				var group;
				if (didFailed) {
					this.set('errorText', new Ember.Handlebars.SafeString("Not able to create change request, please contact <a href='https://www.zenefits.com/support' target='_blank' rel='noopener noreferrer'>Zenefits support</a>"));
					return;
				}
				group = this.get('group');
				if (removedSet.length || this.get('hasManagerCascadingChange') ||
					this.get('hasHireDateCascadingChange') || this.get('hasEmploymentTypeCascadingChange') ||
					this.get('hasSalaryCascadingChange') || this.get('hasDepartmentCascadingChange') ||
					this.get('hasLocationCascadingChange') || this.get('hasPYPImpactingChange')) {
					group.reload()
					.then(function() {
						if (this.get('salaryChange')) {
							return this.get('salaryChange').reload();
						}
						if (this.get('locationChange')) {
							return this.get('locationChange').reload();
						}
					}.bind(this))
					.then(function() {
						if (this.get('grabTransfer')) {
							return this.transitionToRoute('confirmtransfer', this.get('model.id'));
						} else {
							return this.transitionToRoute('confirmrequest', this.get('model.id'));
						}
					}.bind(this));
				} else {
					group.set('isReadyToProcess', true);
					return group.save()
					.then(function() {
						var promises = [];
						promises.push(this.get('employee').reload());
						if (this.get('salaryChange')) {
							promises.push(this.get('salaryChange').reload());
						}
						if (this.get('titleChange')) {
							promises.push(this.get('titleChange').reload());
						}
						if (this.get('departmentChange')) {
							promises.push(this.get('departmentChange').reload());
						}
						if (this.get('payScheduleChange')) {
							promises.push(this.get('payScheduleChange').reload());
						}
						if (this.get('locationChange')) {
							promises.push(this.get('locationChange').reload());
						}
						if (this.get('managerChange')) {
							promises.push(this.get('managerChange').reload());
						}
						if (this.get('hireDateChange')) {
							promises.push(this.get('hireDateChange').reload());
						}
						if (this.get('employee.currentEmployment')) {
							promises.push(this.get('employee.currentEmployment').reload());
						}
						promises.push(this.get('group').reload());
						return Ember.RSVP.all(promises);
					}.bind(this))
					.then(function() {
						return wrapArrayPromise(this.get('employee.changeRequests'))
						.then(function(oldRequests) {
							return Ember.RSVP.all(oldRequests.map(function(cr) {
								return cr.reload();
							}));
						});
					}.bind(this))
					.then(function() {
						var promises;
						if (this.get('transferRequests.length')) {
							promises = this.get('transferRequests').reduce(function(promises, transferRequest) {
								promises.push(transferRequest.get('employee').reload());
								return promises;
							}.bind(this), []);
							return Ember.RSVP.all(promises);
						}
					}.bind(this))
					.then(function() {
						if (this.get('grabTransfer')) {
							this.transitionTo('employeedirectory');
						} else {
							this.transitionTo('redirect.tearsheet', this.get('employee'));
						}
					}.bind(this));
				}
			}.bind(this));
		},
	},
});

App.RequestchangesController = zen._AbstractSavingController.extend(zen._MultiModelMixin, zen._ChangeRequestMixin, {
	cancel: function() {
		this.transitionTo('redirect.tearsheet', this.get('model.employee'));
	},
});

App.TransferemployeeController = zen._AbstractSavingController.extend(zen._MultiModelMixin, zen._ChangeRequestMixin, {
	grabTransfer: true,
	cancel: function() {
		this.transitionToRoute('employeedirectory');
	},
});

App.ApproversExistingRequestDetailController = Ember.ObjectController.extend({
	needs: ['requestchanges'],
	submit: function() {
		this.send('hideModal');
	},
	override: function(){
		var model = this.get('model');
		var rc = this.get('controllers.requestchanges');
		rc.set(model.get('showProperty'),false);
		this.send('hideModal');
	}
});

App.ConfirmtransferRoute = App.ConfirmrequestRoute = Ember.Route.extend(
	zen._AuthAnyMixin,
	zen._AuthManagerMixin,
	zen._RollbackOnDeactivateMixin,
	zen._ShortCircuitEmployerStatsRouteMixin, {
		model: function(params) {
			var changeRequestPromise = App.ChangeRequest.find(params.change_request_id);
			var companyPromise = dashboard('company');
			var companyPtosPromise = companyPromise.then(function(company) {
				return company.get('ptos');
			});
			var groupPromise = changeRequestPromise.then(function(changeRequest) {
				return changeRequest.get('group');
			});
			var requesterPromise = dashboard('employee');
			var requestsPromise = groupPromise.then(function(group) {
				return wrapArrayPromise(group.get('requests'));
			});
			var startDatePromise = groupPromise.then(function(group) {
				if (group.get('applyImmediately')) {
					return moment().format('L');
				} else {
					return group.get('effectiveDate');
				}
			});
			var terminationSettingsPromise = companyPromise.then(function(company) {
				return company.get('terminationSettings');
			});
			var effectiveDatesPromise = null;
			if (this.get('routeName') === 'confirmrequest') {
				effectiveDatesPromise = Ember.RSVP.all(
					[changeRequestPromise, startDatePromise]).then(function(promises) {
					var changeRequest = promises[0];
					var startDate = promises[1];
					var effectiveDateURL = (
						'/custom_api/employee_effective_dates/?employeeID=' +
						changeRequest.get('employee.id') +
						'&changeDate=' + startDate);
					return Ember.ajax({
						url: effectiveDateURL,
						type: 'GET',
					});
				});
			}
			var employees = dashboard('employees');
			var employeesPayrollProperties = employees.then(function(employees) {
				return Ember.RSVP.all(employees.mapBy('employeePayrollProperties'));
			});
			return Ember.RSVP.hash({
				'allRequests': requestsPromise.then(function(requests) {
					var promises = requests.reduce(function(promises, changeRequest) {
						return [
							'departmentChange',
							'employmentTypeChange',
							'hireDateChange',
							'locationChange',
							'managerChange',
						].map(function(key) {
							return changeRequest.get(key);
						}).compact();
					}, []);

					return wrapArrayPromise(promises);
				}),
				'changeRequest': changeRequestPromise,
				'cobraRule': terminationSettingsPromise.then(function(terminationSettings) {
					return terminationSettings.get('defaultCobraRule');
				}),
				'company': companyPromise,
				'companyPaySchedules': companyPromise.then(function(company) {
					return company.get('paySchedules');
				}),
				'companyPayroll': companyPromise.then(function(company) {
					return company.get('payroll');
				}),
				'companyPayrollDepartments': companyPromise.then(function(company) {
					return company.get('payrollDepartments');
				}),
				'companyPtos': companyPtosPromise,
				'companySettings': companyPromise.then(function(company) {
					return company.get('settings');
				}),
				'effectiveDates': effectiveDatesPromise,
				'employeeCobra': requesterPromise.then(function(requester) {
					return requester.get('employeeCobra');
				}),
				'employees': employees,
				'employeesPayrollProperties': employeesPayrollProperties,
				'group': groupPromise,
				'isManagerButNotAdmin': dashboard('managerAndNotAdminWithHirePermissions'),
				'requester': requesterPromise,
				'schEmployerStats': this.promiseForSchEmployerStats(),
				'startDate': startDatePromise,
				'terminationSettings': terminationSettingsPromise,
			});
		},
		setupController: function(controller, model) {
			this._super(controller, model);
			if (model.changeRequest.get('employmentTypeChange.movingOutOfFullTime')) {
				// This call will determine whether or not we're moving out of benefits based on
				// the new employment type and employee tagging.
				controller.updateEligibility();
				controller.afterSetupControllerCheckCobra();
			}

			var differentFromManagerDepartment = false;
			if (model.changeRequest.get('managerChange.manager') &&
				!model.changeRequest.get('departmentChange')) {
				var employeeDepartment = model.changeRequest.get('employee.department');
				var managerDepartment = model.changeRequest.get('managerChange.manager.department');
				differentFromManagerDepartment = employeeDepartment != managerDepartment;
			}

			controller.set('differentFromManagerDepartment', differentFromManagerDepartment);
			controller.set('errorText', '');
			controller.set('reassigningSubordinates', false);
		},
});

zen._transferconfirmMixin = Ember.Mixin.create(zen._FtPtValidatorMixin, zen._FtPtHandlerMixin, zen._TerminationBenefitsAndCobraMixin, zen._LocationCascadingChangeMixin, zen._PayrollSettingsMixin, {
	errorMessage: "Full Time end date cannot be before employee's hire date",
	category: "request",
	fullName: function() {
		return this.get('employee.first_name');
	}.property('employee.first_name'),

	first_name:function() {
		return this.get('employee.first_name');
	}.property('employee.first_name'),

	partTime: function() {
		return this.get('employmentTypeChange.employmentType') == 'PT';
	}.property('employmentTypeChange.employmentType'),

	ft_enddate: function() {
		if (this.get('group.applyImmediately')) {
			return moment().format("MM/DD/YYYY");
		}
		else {
			return this.get('group.effectiveDate');
		}
	}.property('group.effectiveDate'),

	askBenefitsQuestion: false,

	formTerminationType: function() {
		return "IN";
	}.property(),

	formEmploymentType: function() {
		return this.get('employmentTypeChange.employmentType');
	}.property('employmentTypeChange.employmentType'),

	prettyEmploymentType: function() {
		var employmentType = this.get('employmentTypeChange.employmentType');
		if (employmentType == 'PT') {
			return 'Part-Time';
		}
		if (employmentType == 'FT') {
			return 'Full-Time';
		}
		if (employmentType == 'TP') {
			return 'Temp/Intern';
		}
		if (employmentType == 'IN') {
			return 'Intern';
		}
		return '';
	}.property('employmentTypeChange.employmentType'),

	benefitsEndReason: 'fulltime end',

	moveSubordinateGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('reassigningSubordinates', value === "yes");
			if (this.get('reassigningSubordinates') && this.get('departmentChange.moveSubDepartment')) {
				var departmentChange = this.get('departmentChange');
				departmentChange.set('moveSubDepartment', false);
			}
		}
		return this.get('reassigningSubordinates') ? "yes" : "no";
	}.property('reassigningSubordinates', 'departmentChange.moveSubDepartment'),

	moveSubordinateDepartment: function(key, value) {
		if (!this.get('departmentChange')) {
			return;
		}
		var departmentChange = this.get('departmentChange');
		if (arguments.length > 1) {
			departmentChange.set('moveSubDepartment', value === "yes");
		}
		return departmentChange.get('moveSubDepartment') ? "yes" : "no";
	}.property('departmentChange.moveSubDepartment'),

	//list of transfer request already associated with this request
	transferRequests: function() {
		return this.get('group.requests').reduce(function(transferRequests, request) {
			if (this.get('changeRequest') != request) {
				transferRequests.push(request);
			}
			return transferRequests;
		}.bind(this), []);
	}.property('group.requests', 'changeRequest'),

	removedRequests: function() {
		return this.get('transferRequests').reduce(function(newReportsRequests, request) {
			if (!request.get('managerChange')) {
				newReportsRequests.push(request);
			}
			return newReportsRequests;
		}.bind(this), []);
	}.property('transferRequests'),

	removedReports: function() {
		return this.get('removedRequests').mapProperty('employee');
	}.property('transferRequests'),

	existingReports: function() {
		return this.get('employees').filterProperty('reportToEmployee', this.get('employee')).rejectProperty('status', 'Ter');
	}.property('employees.@each.reportToEmployee'),

	numberOfUnchangedEmployees: function() {
		return this.get('existingReports').length - this.get('removedRequests').length;
	}.property('removedRequests.length', 'existingReports.length'),

	unchangedEmployees: function() {
		var existingReportsCopy = [].concat(this.get('existingReports'));
		return existingReportsCopy.removeObjects(this.get('removedReports'));
	}.property('existingReports', 'removedReports'),

	numberOfUnchangedEmployeeSubordinates: function() {
		var number = 0;
		this.get('unchangedEmployees').forEach(function(employee) {
			number += employee.get('numberOfAllSubordinates') ? employee.get('numberOfAllSubordinates') : 0;
		});
		return number;
	}.property('unchangedEmployees'),

	moveToManagerDepartment: function(key, value) {
		var departmentChange= this.get('departmentChange');
		if (arguments.length > 1) {
			if (departmentChange) {
				if (value === 'yes') {
					departmentChange.set('department', this.get('managerChange.manager.department'));
					if (this.get("inferredPayrollDepartment")) {
						departmentChange.set("payrollDepartmentCode", this.get("inferredPayrollDepartment"));
					}
				}
				else {
					this.set('departmentChange', null);
				}
			}
			else {
				if (value === 'yes') {
					departmentChange = App.DepartmentChange.createRecord({
						"department": this.get('managerChange.manager.department'),
						"requestedBy": this.get('requester'),
						"moveSubDepartment": false,
					});
					this.set('departmentChange', departmentChange);
					if (this.get("inferredPayrollDepartment")) {
						departmentChange.set("payrollDepartmentCode", this.get("inferredPayrollDepartment"));
					}
				}
			}
		}
		return this.get('departmentChange.department') == this.get('managerChange.manager.department') ? "yes" : "no";
	}.property('departmentChange.department', 'managerChange.manager.department'),

	showMoveSubordinateDepartment: function() {
		if (this.get('departmentChange') && this.get('unchangedEmployees.length') && !this.get('reassigningSubordinates')) {
			return true;
		}
		return false;
	}.property('departmentChange', 'unchangedEmployees.length', 'reassigningSubordinates'),

	showPayrollDepartmentChange: function() {
		return this.get('payrollDepartmentChoices') != null && this.get('departmentChange') != null;
	}.property('payrollDepartmentChoices', 'departmentChange'),
	hasDepartmentCascadingChange: Ember.computed.or('showMoveSubordinateDepartment', 'differentFromManagerDepartment', 'showPayrollDepartmentChange'),

	schEmployerStats: null,
	isScSetupComplete: Ember.computed.and('schEmployerStats.medical', 'schEmployerStats.additionalMedical', 'schEmployerStats.dental', 'schEmployerStats.vision'),
	isScSetupIncomplete: Ember.computed.not('isScSetupComplete'),
	isMovingIntoFullTime: Ember.computed.not('employmentTypeChange.movingOutOfFullTime'),
	shouldRedirectToSCSetup: Ember.computed.and('employmentTypeChange', 'isMovingIntoFullTime', 'isScSetupIncomplete'),

	isSync: Ember.computed.equal('company.payrollSyncType', 'Sync'),
	isPayrollReports: function() {
		return this.get('company.isPayrollReports');
	}.property('company.isPayrollReports'),
	isFileSync: function() {
		return this.get('company.isFileSync');
	}.property('company.isFileSync'),
	hasSync: Ember.computed.or('isSync', 'isPayrollReports', 'isFileSync'),

	moveWFSupervisor: function(key, value) {
		var managerChange = this.get('managerChange');
		if (!managerChange) {
			return;
		}
		if (arguments.length > 1) {
			managerChange.set('moveWFSupervisor', value === "yes");
			if (value === "yes") {
				managerChange.set('wfSupervisorPayrollId', this.get('managerChange.manager.payrollId'));
			}
		}
		return managerChange.get('moveWFSupervisor') ? "yes" : "no";
	}.property('managerChange.moveWFSupervisor', 'managerChange.wfSupervisorPayrollId', 'managerChange.manager.payrollId'),

	changePtoYesNo: Ember.computed.boolToYesNo('employmentTypeChange.changePtoPolicy'),
	otherEligiblePolicies: function() {
		var companyPto = this.get('employee.pto.companyPto');
		return this.get('companyPtos').filter(function(pto){
			return pto.get('status') === 'complete' && pto != companyPto && ((companyPto && companyPto.get('isTimeAttendancePolicy')) || !pto.get('isTimeAttendancePolicy'));
		});
	}.property('employee.pto.companyPto', 'employee.pto.companyPto.isTimeAttendancePolicy', 'companyPtos.@each.status', 'companyPtos.@each.isTimeAttendancePolicy'),

	removeTrailingPolicy: function(policyName) {
		if(policyName.toLowerCase().endsWith('policy')){
			return policyName;
		}
		return policyName + ' policy';
	},

	onlyPTOPolicyNameStr: function() {
		var policy = this.get('completePolicies.firstObject');
		if(policy === null) {
			return;
		}
		return this.removeTrailingPolicy(policy.get('name'));
	}.property('completePolicies.firstObject', 'completePolicies.firstObject.name'),

	currentPolicyNameStr: function() {
		var policy = this.get('employee.pto.companyPto');
		if(policy === null) {
			return;
		}
		return this.removeTrailingPolicy(policy.get('name'));
	}.property('employee.pto.companyPto', 'employee.pto.companyPto.name'),

	enrolledInOnlyPolicy: function() {
		return this.get('completePolicies.length') === 1 && this.get('employee.pto.companyPto') === this.get('completePolicies.firstObject');
	}.property('completePolicies', 'employee.pto.companyPto'),

	notInOnlyPolicy: function() {
		return this.get('completePolicies.length') === 1 && this.get('employee.pto.companyPto') === null;
	}.property('completePolicies', 'employee.pto.companyPto'),

	enrolledInMultiplePolicies: function() {
		return this.get('completePolicies.length') > 1 && this.get('employee.pto.companyPto') !== null && this.get('completePolicies').contains(this.get('employee.pto.companyPto'));
	}.property('completePolicies', 'employee.pto.companyPto'),

	notInMultiplePolicies: function() {
		return this.get('completePolicies.length') > 1 && this.get('employee.pto.companyPto') === null;
	}.property('completePolicies', 'employee.pto.companyPto'),

	completePolicies: Ember.computed.filterByProperty('companyPtos', 'status', 'complete'),
	canChangeCompanyPTO: function() {
		return this.get('completePolicies.length') > 0 && this.get('employee.isEligibleForPTO');
	}.property('completePolicies', 'employee.isEligibleForPTO'),

	showFollowupSelection: function() {
		return this.get('completePolicies.length') > 1 && this.get('changePtoYesNo') === 'yes';
	}.property('completePolicies', 'changePtoYesNo'),

	changeCompanyPto: function() {
		var etChange = this.get('employmentTypeChange');
		if (!etChange) {
			return;
		}
		var newCompanyPto = this.get('selectedNewCompanyPto');
		if (this.get('changePtoYesNo') === 'yes'){
			if (this.get('enrolledInOnlyPolicy')){
				etChange.set('companyPtoId', null);
			}
			else if (this.get('notInOnlyPolicy')){
				etChange.set('companyPtoId', this.get('completePolicies.firstObject.id'));
			}
			else if (newCompanyPto) {
				etChange.set('companyPtoId', newCompanyPto.get('id'));
			}
			else{
				etChange.set('companyPtoId', null);
			}
		}
		if (newCompanyPto === this.get('employee.pto.companyPto')){
			etChange.set('changePtoPolicy', false);
		}
	}.observes('employee.pto.companyPto', 'selectedNewCompanyPto', 'employmentTypeChange', 'changePtoYesNo', 'changePtoPolicy', 'companyPtoId'),

	logPtoSwitch: function() {
		if (!this.get('employmentTypeChange.changePtoPolicy')){
			return;
		}
		var oldCompanyPto = "";
		var newCompanyPto = "";
		if (this.get('employee.pto.companyPto')){
			oldCompanyPto = this.get('employee.pto.companyPto.name');
		}
		else {
			oldCompanyPto = 'No PTO policy';
		}
		if (this.get('changeNewCompanyPto')){
			newCompanyPto = this.get('changeNewCompanyPto.name');
		}
		else {
			newCompanyPto = 'No PTO policy';
		}
		this.eventLogger.log('pto_change_policy_with_employment_type_change', {
			'old_employment_type': this.get('employee.employmentType'),
			'new_employment_type': this.get('employmentTypeChange.employmentType'),
			'old_pto_policy': oldCompanyPto,
			'new_pto_policy': newCompanyPto
		});
	},

	actions: {
		confirm: function() {
			if (this.get('managerChange') && this.get('reassigningSubordinates') && !this.get('newManagerForExistingEmployee')) {
				this.set('errorText', 'Please provide all fields.');
				return;
			}

			if (this.get('employee.wfSupervisorPayrollId') && this.get('managerChange.moveWFSupervisor') && !this.get('managerChange.wfSupervisorPayrollId')) {
				this.set('errorText', 'Please provide all fields.');
				return;
			}

			if (this.get('departmentChange') && this.get('payrollDepartmentChoices') && !this.get('departmentChange.payrollDepartmentCode') && !this.get('employee.isInternational')) {
				this.set('errorText', 'Please provide all fields.');
				return;
			}

			if (this.get('removedReports').length && !this.get('newManagerForRemovedEmployee')) {
				this.set('errorText', 'Please provide all fields.');
				return;
			}

			if (this.get('removeFromBenefits') && this.get('isMovingOutOfBenefits') && !this.validateCobra()) {
				return;
			}

			this.set('errorText', '');

			var promises = [];

			// This is quick fix -- best would be remove these employees from zenselect.
			if (this.get('newManagerForExistingEmployee') && this.get('unchangedEmployees') && this.get('unchangedEmployees').filterProperty('id', this.get('newManagerForExistingEmployee.id')).length) {
				this.set('errorText', this.get('newManagerForExistingEmployee.fullName') + " can't report to themself.");
				return;
			}

			if (this.get('removedRequests') && this.get('newManagerForRemovedEmployee') && this.get('unchangedEmployees').filterProperty('id', this.get('newManagerForExistingEmployee.id')).length) {
				this.set('errorText', this.get('newManagerForRemovedEmployee.fullName') + " can't report to themself.");
				return;
			}

			if (this.get('newManagerForExistingEmployee')) {
				promises = this.get('unchangedEmployees').map(function(employee) {
					var managerChange = App.ManagerChange.createRecord({
						"manager": this.get('newManagerForExistingEmployee'),
						"requestedBy": this.get('requester'),
					});
					return managerChange.save().then(function() {
						var changeRequest = App.ChangeRequest.createRecord({
							"employee": employee,
							group: this.get('group'),
							managerChange: managerChange
						});
						return changeRequest.save();
					}.bind(this));
				}.bind(this));
			}

			promises = promises.concat(this.get('removedRequests').map(function(request) {
				var managerChange = App.ManagerChange.createRecord({
					"manager": this.get('newManagerForRemovedEmployee'),
					"requestedBy": this.get('requester'),
				});
				return managerChange.save().then(function() {
					request.set('managerChange', managerChange);
					return request.save();
				}.bind(this));
			}.bind(this)));

			if (this.get('departmentChange')) {
				promises.push(this.get('departmentChange').save());
			}

			if (this.get('employmentTypeChange.movingOutOfFullTime')) {
				var etChange = this.get('employmentTypeChange');
				etChange.set('administerCOBRA', this.get('administerCOBRA'));
				etChange.set('coverFullCobra', this.get('coverFullCobra'));
				etChange.set('cobraMonths', this.get('cobraMonths'));
				promises.push(etChange.save());
			}

			if (this.get('employmentTypeChange.changePtoPolicy')) {
				var etChange = this.get('employmentTypeChange');
				promises.push(etChange.save());
				this.logPtoSwitch();
			}

			if(this.get('locationChange')) {
				promises.push(this.get('locationChange').save());
			}

			if(this.get('managerChange.moveWFSupervisor') && this.get('managerChange.wfSupervisorPayrollId') != this.get('employee.wfSupervisorPayrollId')) {
				promises.push(this.get('managerChange').save());
			}

			return Ember.RSVP.all(promises)
			.then(function() {
				return this.get('changeRequest').save();
			}.bind(this))
			.then(function() {
				this.get('group').set('isReadyToProcess', true);
				return this.get('group').save();
			}.bind(this))
			.then(function() {
				promises = [];
				promises.push(this.get('employee').reload());
				if (this.get('salaryChange')) {
					promises.push(this.get('salaryChange').reload());
				}
				if (this.get('titleChange')) {
					promises.push(this.get('titleChange').reload());
				}
				if (this.get('payScheduleChange')) {
					promises.push(this.get('payScheduleChange').reload());
				}
				if (this.get('managerChange')) {
					promises.push(this.get('managerChange').reload());
				}
				if (this.get('departmentChange')) {
					promises.push(this.get('departmentChange').reload());
				}
				if (this.get('employee.payroll') && (this.get('departmentChange.payrollDepartmentCode') || this.get('managerChange.moveWFSupervisor'))) {
					promises.push(this.get('employee.payroll').reload());
				}
				if (this.get('hireDateChange')) {
					promises.push(this.get('hireDateChange').reload());
				}
				if (this.get('employmentTypeChange')) {
					promises.push(this.get('employmentTypeChange').reload());
				}
				if (this.get('employee.currentEmployment')) {
					promises.push(this.get('employee.currentEmployment').reload());
				}
				if(this.get('locationChange')) {
					promises.push(this.get('locationChange').reload());
				}
				promises.push(this.get('group').reload());
				return Ember.RSVP.all(promises);
			}.bind(this))
			.then(function() {
				return wrapArrayPromise(this.get('employee.changeRequests'))
				.then(function(oldRequests) {
					return Ember.RSVP.all(oldRequests.map(function(cr) {
						return cr.reload();
					}));
				});
			}.bind(this))
			.then(function() {
				if (this.get('transferRequests.length')) {
					promises = this.get('transferRequests').reduce(function(promises, transferRequest) {
						promises.push(transferRequest.get('employee').reload());
						return promises;
					}.bind(this), []);
					return Ember.RSVP.all(promises);
				}
			}.bind(this))
			.then(function() {
				if (this.get('grabTransfer')) {
					this.transitionTo('employeedirectory');
				} else {
					this.transitionTo('redirect.tearsheet', this.get('employee'));
				}
			}.bind(this));
		},
		cancel: function() {
			this.onCancel();
		},
	},
});

App.ConfirmrequestController = zen._AbstractSavingController.extend(zen._MultiModelMixin, zen._transferconfirmMixin, {
	changeRequest: Ember.computed.alias('model.changeRequest'),
	company: Ember.computed.alias('model.company'),
	companyPtos: Ember.computed.alias('model.companyPtos'),
	companySettings: Ember.computed.alias('model.companySettings'),
	dentalEligibilityStart: Ember.computed.alias('effectiveDates.dental'),
	departmentChange: Ember.computed.alias('changeRequest.departmentChange'),
	employee: Ember.computed.alias('changeRequest.employee'),
	employees: Ember.computed.alias('model.employees'),
	employmentTypeChange: Ember.computed.alias('changeRequest.employmentTypeChange'),
	group: Ember.computed.alias('changeRequest.group'),
	hireDateChange: Ember.computed.alias('changeRequest.hireDateChange'),
	isEligibleToEnroll: function() {
		var latestEffectiveDate = null;
		var effectiveDates = ['medical', 'dental', 'vision'].map(function(lineOfCoverage) {
			return this.get('model.effectiveDates.' + lineOfCoverage);
		}.bind(this)).compact();
		effectiveDates.forEach(function(effectiveDate) {
			if (effectiveDate) {
				var momentDate = moment(effectiveDate, 'MM/DD/YYYY');
				if (!latestEffectiveDate || momentDate.isAfter(latestEffectiveDate)) {
					latestEffectiveDate = momentDate;
				}
			}
		});

		if (!latestEffectiveDate) {
			return false;
		}

		var eligibilityEnd = latestEffectiveDate.add(30, 'days');
		if (moment().startOf('day').isAfter(eligibilityEnd)) {
			return false;
		} else {
			return true;
		}
	}.property('model.effectiveDates'),
	isManagerButNotAdmin: Ember.computed.alias('model.isManagerButNotAdmin'),
	isMovingToFullTime: Ember.computed.equal('employmentTypeChange.employmentType', 'FT'),
	locationChange: Ember.computed.alias('changeRequest.locationChange'),
	managerChange: Ember.computed.alias('changeRequest.managerChange'),
	medicalEligibilityStart: Ember.computed.alias('effectiveDates.medical'),
	newManagerForExistingEmployee: Ember.computed.alias('changeRequest.newManagerForExistingEmployee'),
	newManagerForRemovedEmployee: Ember.computed.alias('changeRequest.newManagerForRemovedEmployee'),
	onCancel: function() {
		this.transitionTo('redirect.tearsheet', this.get('employee'));
	},
	payScheduleChange: Ember.computed.alias('changeRequest.payScheduleChange'),
	requester: Ember.computed.alias('model.requester'),
	salaryChange: Ember.computed.alias('changeRequest.salaryChange'),
	schEmployerStats: Ember.computed.alias('model.schEmployerStats'),
	showACAWarning: function() {
		var ACA_MEDIAN_EE_COUNT = 18;
		var employees = this.get('employees');
		var activeEmployees = employees.filterBy('status', 'Act');
		return activeEmployees.length > ACA_MEDIAN_EE_COUNT;
	}.property('employees.@each.status'),
	terminationSettings: Ember.computed.alias('model.terminationSettings'),
	titleChange: Ember.computed.alias('changeRequest.titleChange'),
	visionEligibilityStart: Ember.computed.alias('effectiveDates.vision'),

	isPYPCompanyBlockingOrganicGrowth: function() {
		var locationChangeState = "";
		if (this.get('locationChange')) {
				locationChangeState = this.get('locationChange.location.state');
			if (this.get('locationChange.isRemote')) {
				locationChangeState = this.get('employee.state');
			}
		}
		if (locationChangeState && (locationChangeState != this.get('employee.location.state'))) {
			var state = this.get('locationChange.location.state');
			Ember.$.ajax({
				url: '/custom_api/z_payroll/organicGrowthNotice/',
				type: 'post',
				data: {
					"state": state,
					"company_id": this.get('company.id')
				},
				success: function(response) {
					this.set('isPYPCompanyBlockingOrganicGrowth', response.result);
				}.bind(this),
				error: function(jqXHR, textStatus, errorThrown) {
					this.set('isPYPCompanyBlockingOrganicGrowth', false);
				}
			});
		} else {
			this.set('isPYPCompanyBlockingOrganicGrowth', false);
		}
	}.property('locationChange','locationChange.location.state','employee.location.state'),
});

App.ConfirmtransferController = zen._AbstractSavingController.extend(zen._MultiModelMixin, zen._transferconfirmMixin, {
	changeRequest: Ember.computed.alias('model.changeRequest'),
	company: Ember.computed.alias('model.company'),
	companySettings: Ember.computed.alias('model.companySettings'),
	departmentChange: Ember.computed.alias('changeRequest.departmentChange'),
	employee: Ember.computed.alias('changeRequest.employee'),
	employees: Ember.computed.alias('model.employees'),
	employmentTypeChange: Ember.computed.alias('changeRequest.employmentTypeChange'),
	grabTransfer: true,
	group: Ember.computed.alias('changeRequest.group'),
	hireDateChange: Ember.computed.alias('changeRequest.hireDateChange'),
	isManagerButNotAdmin: Ember.computed.alias('model.isManagerButNotAdmin'),
	locationChange: Ember.computed.alias('changeRequest.locationChange'),
	managerChange: Ember.computed.alias('changeRequest.managerChange'),
	newManagerForExistingEmployee: Ember.computed.alias('changeRequest.newManagerForExistingEmployee'),
	newManagerForRemovedEmployee: Ember.computed.alias('changeRequest.newManagerForRemovedEmployee'),
	onCancel: function() {
		this.transitionTo('employeedirectory');
	},
	payScheduleChange: Ember.computed.alias('changeRequest.payScheduleChange'),
	requester: Ember.computed.alias('model.requester'),
	salaryChange: Ember.computed.alias('changeRequest.salaryChange'),
	schEmployerStats: Ember.computed.alias('model.schEmployerStats'),
	terminationSettings: Ember.computed.alias('model.terminationSettings'),
	titleChange: Ember.computed.alias('changeRequest.titleChange'),
});

App.BenefitspreviewRoute = Ember.Route.extend(zen._MultiModelMixin, {
	model: function() {
		var companyPromise = dashboard('company');
		var previewPromise = companyPromise.then(function(company) {
			return company.get('benefitsPreview') || Ember.RSVP.resolve(null);
		});

		return this.all(previewPromise, {
			company: companyPromise,
		});
	},
});

App.AdminopenenrollmentRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"otherpromises": dashboard('company').then(function(company) {
				return Ember.RSVP.all([
					wrapArrayPromise(company.get('healthEnrollments')),
					wrapArrayPromise(company.get('healthCarriers')),
					thenpath(company, 'adminOpenEnrollment')
				]);
			}).then(function(result) {
				var enrollments = result[0];
				var carriers = result[1];
				var promises = [];
				enrollments.forEach(function(enrollment) {
					var previousEnrollment = enrollment.get('previousEnrollment');

					// Previous enrollment will be null for upsells.
					if (previousEnrollment != null) {
						var previousEnrollmentPromise = previousEnrollment.then(function(prevEnrollment) {
							return prevEnrollment.get('companyHealthCarrier').then(function(carrier) {
								return Ember.RSVP.all([
									wrapArrayPromise(carrier.get('companyHealthPlans')),
									wrapArrayPromise(carrier.get('depFixedContributions')),
									wrapArrayPromise(carrier.get('depCountFixedDeductions')),
									wrapArrayPromise(carrier.get('depTypeContributionSchemes')),
									wrapArrayPromise(carrier.get('employeeFixedDeductions')),
									wrapArrayPromise(carrier.get('planContributionSchemes')),
									wrapArrayPromise(carrier.get('planDepTypeContributionSchemes')),
									wrapArrayPromise(carrier.get('planFixedDeductions'))
								]);
							});
						});

						promises.pushObject(previousEnrollmentPromise);
					}
				});

				carriers.forEach(function(carrier) {
					promises.pushObject(wrapArrayPromise(carrier.get('companyHealthPlans')));
					promises.pushObject(wrapArrayPromise(carrier.get('depFixedContributions')));
					promises.pushObject(wrapArrayPromise(carrier.get('depCountFixedDeductions')));
					promises.pushObject(wrapArrayPromise(carrier.get('depTypeContributionSchemes')));
					promises.pushObject(wrapArrayPromise(carrier.get('employeeFixedDeductions')));
					promises.pushObject(wrapArrayPromise(carrier.get('planContributionSchemes')));
					promises.pushObject(wrapArrayPromise(carrier.get('planDepTypeContributionSchemes')));
					promises.pushObject(wrapArrayPromise(carrier.get('planFixedDeductions')));
				});

				return Ember.RSVP.all(promises);
			})
		});
	},
});

App.AdminopenenrollmentIndexRoute = Ember.Route.extend(zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	},
	afterModel: function(model) {
		if (model.get('hasMedicalOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewmedicalplans');
		} else if (model.get('hasDentalOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewdentalplans');
		} else if (model.get('hasVisionOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewvisionplans');
		} else if (model.get('hasLifeOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewlifeplans');
		} else if (model.get('hasADDOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewaddplans');
		} else if (model.get('hasSTDOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewstdplans');
		} else if (model.get('hasLTDOpenEnrollments')) {
			this.transitionTo('adminopenenrollment.reviewltdplans');
		}
	}
});

App.AdminopenenrollmentReviewmedicalplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentPreviewmedicalplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var company = dashboard('company');
		var premiums = company.then(function(company) {
			var premiumsMap = company.get('adminOpenEnrollment.premiumsMap');
			return (premiumsMap && JSON.parse(premiumsMap)['medical']) || Ember.$.get('/getCompanyPremiums', {lineOfCoverage: 'medical'});
		});
		return this.all(company, {
			'healthEnrollmentsPromises': company.then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'premiums': premiums,
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
		controller.set('previewEmployeeIndex', 0);
	}
});

App.AdminopenenrollmentReviewdentalplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentPreviewdentalplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var company = dashboard('company');
		var premiums = company.then(function(company) {
			var premiumsMap = company.get('adminOpenEnrollment.premiumsMap');
			return (premiumsMap && JSON.parse(premiumsMap)['dental']) || Ember.$.get('/getCompanyPremiums', {lineOfCoverage: 'dental'});
		});
		return this.all(company, {
			'healthEnrollmentsPromises': company.then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'premiums': premiums,
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
		controller.set('previewEmployeeIndex', 0);
	}
});

App.AdminopenenrollmentReviewvisionplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return dashboard('company');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentPreviewvisionplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		var company = dashboard('company');
		var premiums = company.then(function(company) {
			var premiumsMap = company.get('adminOpenEnrollment.premiumsMap');
			return (premiumsMap && JSON.parse(premiumsMap)['vision']) || Ember.$.get('/getCompanyPremiums', {lineOfCoverage: 'vision'});
		});
		return this.all(company, {
			'healthEnrollmentsPromises': company.then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'premiums': premiums,
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
		controller.set('previewEmployeeIndex', 0);
	}
});

App.AdminopenenrollmentReviewlifeplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"plans": zen.companyLifeDisabilityEnrollmentApi('lifePlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('lifeRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('lifeRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	},
});

App.AdminopenenrollmentPreviewlifeplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'healthEnrollmentsPromises': dashboard('company').then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'pairs': Ember.$.get('/getLdPlanPairs', {lineOfCoverage: 'lifenew'}),
			"plans": zen.companyLifeDisabilityEnrollmentApi('lifePlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('lifeRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('lifeRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.updateSlider();
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentReviewaddplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"plans": zen.companyLifeDisabilityEnrollmentApi('addPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('addRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('addRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	},
});

App.AdminopenenrollmentPreviewaddplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'healthEnrollmentsPromises': dashboard('company').then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'pairs': Ember.$.get('/getLdPlanPairs', {lineOfCoverage: 'add'}),
			"plans": zen.companyLifeDisabilityEnrollmentApi('addPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('addRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('addRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.updateSlider();
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentReviewstdplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"plans": zen.companyLifeDisabilityEnrollmentApi('stdPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('stdRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('stdRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	},
});

App.AdminopenenrollmentPreviewstdplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'healthEnrollmentsPromises': dashboard('company').then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'pairs': Ember.$.get('/getLdPlanPairs', {lineOfCoverage: 'std'}),
			"plans": zen.companyLifeDisabilityEnrollmentApi('stdPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('stdRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('stdRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.updateSlider();
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentReviewltdplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			"plans": zen.companyLifeDisabilityEnrollmentApi('ltdPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('ltdRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('ltdRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	},
});

App.AdminopenenrollmentPreviewltdplansRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('company'), {
			'healthEnrollmentsPromises': dashboard('company').then(function(company) {
				return wrapArrayPromise(company.get('healthEnrollments'));
			}),
			'employees': dashboard('employees'),
			'pairs': Ember.$.get('/getLdPlanPairs', {lineOfCoverage: 'ltd'}),
			"plans": zen.companyLifeDisabilityEnrollmentApi('ltdPlans'),
			"restrictions": zen.companyLifeDisabilityEnrollmentApi('ltdRestrictions'),
			"rates": zen.companyLifeDisabilityEnrollmentApi('ltdRates'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.updateSlider();
		controller.set('adminController', this.controllerFor('adminopenenrollment'));
	}
});

App.AdminopenenrollmentEmailemployeesRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthAdminMixin, {
	model: function() {
		return this.all(dashboard('employees'), {
			"company": dashboard('company')
		});
	}
});

App.OpenEnrollmentRouteTransitionMixin = Ember.Mixin.create({
	routes: ["previewmedicalplans", "previewdentalplans", "previewvisionplans",
		"previewlifeplans", "previewaddplans", "previewstdplans", "previewltdplans"],

	getNextRoute: function(currRoute, model) {
		var routes = this.get('routes');
		var currRouteIndex = routes.indexOf(currRoute);
		var nextRoute = "";

		if (currRouteIndex <= 0 && model.get('hasDentalOpenEnrollments')) {
			nextRoute = "reviewdentalplans";
		} else if (currRouteIndex <= 1 && model.get('hasVisionOpenEnrollments')) {
			nextRoute = "reviewvisionplans";
		} else if (currRouteIndex <= 2 && model.get('hasLifeOpenEnrollments')) {
			nextRoute = "reviewlifeplans";
		} else if (currRouteIndex <= 3 && model.get('hasADDOpenEnrollments')) {
			nextRoute = "reviewaddplans";
		} else if (currRouteIndex <= 4 && model.get('hasSTDOpenEnrollments')) {
			nextRoute = "reviewstdplans";
		} else if (currRouteIndex <= 5 && model.get('hasLTDOpenEnrollments')) {
			nextRoute = "reviewltdplans";
		} else {
			nextRoute = "emailemployees";
		}

		return "adminopenenrollment." + nextRoute;
	},
});

App.OpenEnrollmentPlanReviewMixin = Ember.Mixin.create({
	planType: undefined,
	planTypeClass: undefined,

	openEnrollmentEndDate: Ember.computed.alias(
			'enrollmentPlanPairs.firstObject.companyHealthCarrier.openEnrollmentEndDate'),

	renewalDate: Ember.computed.alias(
			'enrollmentPlanPairs.firstObject.companyHealthCarrier.renewalDate'),

	enrollmentPlanPairs: function() {
		var planType = this.get('planType');
		var planTypeClass = this.get('planTypeClass');
		var enrollments = this.getWithDefault('enrollments', []);
		var enrollmentPlanPairs = enrollments.map(function(enrollment) {
			var planPairs = [];
			var allPlans = enrollment.get('companyHealthCarrier.companyHealthPlans') || [];
			var mapping = enrollment.get('planMapping') || "{}";
			mapping = JSON.parse(mapping);
			var newPlans = {};
			for (var key in mapping) {
				if (mapping.hasOwnProperty(key)) {
					newPlans[mapping[key][0]] = true;
					var pair = Ember.Object.create({
						enrollment: enrollment,
						oldPlan: planTypeClass.find(key),
						newPlan: planTypeClass.find(mapping[key][0])
					});
					planPairs.pushObject(pair);
				}
			}
			allPlans.forEach(function(plan) {
				var key = plan.get(planType + '.id');
				if (!newPlans[key]) {
					var pair = Ember.Object.create({
						enrollment: enrollment,
						oldPlan: undefined,
						newPlan: planTypeClass.find(key)
					});
					planPairs.pushObject(pair);
				}
			});
			return Ember.ObjectProxy.create({
				content: enrollment,
				planPairs: planPairs
			});
		});
		return enrollmentPlanPairs;
	}.property('enrollments.planMapping', 'enrollments.healthCarriers.@each.companyHealthPlans')
});

App.OpenEnrollmentPlanPreviewMixin = Ember.Mixin.create(App.OpenEnrollmentRouteTransitionMixin, {
	premiums: undefined,
	previewEmployeeIndex: undefined, // This is initialized in setupController
	// SearchResult is overridden later by the search query
	// We use this as an intermediate value so that we don't directly
	// set the value of `previewEmployee` from the search function
	searchResult: undefined,
	employeeIdToIndexMap: function() {
		var map = {};
		this.get('sortedEmployees').forEach(function(currElem, index) {
			map[currElem.get('id')] = index;
		});
		return map;
	}.property(),
	searchResultObserver: function() {
		// Find the index of the search result and update previewEmployeeIndex
		var employeeId = this.get('searchResult.id');
		var index = this.get('employeeIdToIndexMap')[employeeId] || 0;
		this.set('previewEmployeeIndex', index);
	}.observes('searchResult'),
	previewEmployee: function() {
		return this.get('sortedEmployees')[this.get('previewEmployeeIndex')];
	}.property('previewEmployeeIndex'),

	maxEmployeeIndex: function() {
		return this.get('sortedEmployees.length') - 1;
	}.property('sortedEmployees.length'),

	sortedEmployees: function() {
		var allPremiums = this.get('premiums');
		if (!allPremiums) { return []; }
		var employeePremiums = {};
		allPremiums.forEach(function(premiums) {
			premiums.premiums.forEach(function(premium) {
				if (!employeePremiums[premium.employeeId]) {
					premium.youCost = parseFloat(premium.youCost);
					employeePremiums[premium.employeeId] = premium;
				} else {
					employeePremiums[premium.employeeId].youCost += parseFloat(premium.youCost);
				}
			});
		});
		// get tuple of employee -> totalPremium across all plans
		var employees = Object.keys(employeePremiums).map(function(key) {
			return employeePremiums[key];
		});
		employees = employees.sort(function(a, b) {
			if (a.youCost === b.youCost) {
				return a.name < b.name ? -1 : 1;
			}
			return a.youCost - b.youCost;
		});
		return employees.map(function(employee) {
			return App.AllEmployee.find(employee.employeeId);
		});
	}.property('employees', 'premiums'),

	numEnrolledDependents: function() {
		var planType = this.get('planType');
		if (planType === 'medicalPlan') {
			return this.get('previewEmployee.employeeSettings.numberDependentsMedical');
		} else if (planType === 'dentalPlan') {
			return this.get('previewEmployee.employeeSettings.numberDependentsDental');
		} else if (planType === 'visionPlan') {
			return this.get('previewEmployee.employeeSettings.numberDependentsVision');
		}
		return undefined;
	}.property('planType',
		'previewEmployee.employeeSettings.numberDependentsMedical',
		'previewEmployee.employeeSettings.numberDependentsDental',
		'previewEmployee.employeeSettings.numberDependentsVision'),

	planPairs: function() {
		var that = this;
		var planType = this.get('planType');
		var planPairClass = this.get('PlanPairClass');
		var enrollments = this.getWithDefault('enrollments', []);
		var planPairs = [];
		enrollments.forEach(function(enrollment) {
			var allPlans = enrollment.get('companyHealthCarrier.companyHealthPlans') || [];
			var mapping = enrollment.get('planMapping') || "{}";
			mapping = JSON.parse(mapping);
			var newPlans = {};
			Object.keys(mapping).forEach(function(key) {
				newPlans[mapping[key][0]] = true;
				var pair = planPairClass.create({
					controller: that,
					oldPlanId: key,
					newPlanId: mapping[key][0]
				});
				planPairs.pushObject(pair);
			});
			allPlans.forEach(function(plan) {
				var key = plan.get(planType + '.id');
				if (!newPlans[key]) {
					var pair = planPairClass.create({
						controller: that,
						oldPlanId: undefined,
						newPlanId: key
					});
					planPairs.pushObject(pair);
				}
			});
		});

		return planPairs;
	}.property('enrollments.planMapping', 'enrollments.healthCarriers.@each.companyHealthPlans'),

	selectLowestPremiumEmployee: function() {
		this.set('previewEmployeeIndex', 0);
	},

	selectHighestPremiumEmployee: function() {
		this.set('previewEmployeeIndex', this.get('sortedEmployees.length') - 1);
	},

	previewEmployeeIndexDidChange: function() {
		var employee = this.get('previewEmployee');
		if (employee) {
			var oldPromises = [];
			var newPromises = [];
			var employeeId = employee.get('id');
			var planPairs = this.getWithDefault('planPairs', []);

			var planURL = this.get('planURL');
			// IMPORTANT: do not use ember-data here. old and new plan might have same
			// id and premiums will get overwritten to whichever call comes last.
			planPairs.forEach(function(planPair) {
				var oldPlanId = planPair.get('oldPlanId');
				var newPlanId = planPair.get('newPlanId');
				if (oldPlanId) {
					oldPromises.pushObject(Ember.$.get(planURL, {
						"id":oldPlanId, "employee_id":employeeId, "mode":"oe_current", "getUnavailable": true}));
				}
				if (newPlanId) {
					newPromises.pushObject(Ember.$.get(planURL, {
						"id":newPlanId, "employee_id":employeeId, "getUnavailable": true}));
				}
			});

			var idToOldPlan = {};
			var idToNewPlan = {};
			// want to make sure employee settings load
			employee.get('employeeSettings').then(function(result) {
				return Ember.RSVP.all(newPromises);
			}).then(function(newPlans) {
				newPlans.forEach(function(plans) {
					var plan = Ember.ObjectProxy.create({ content: plans.objects[0] });
					idToNewPlan[plan.get('id')] = plan;
				});
				return Ember.RSVP.all(oldPromises);
			}).then(function(oldPlans) {
				oldPlans.forEach(function(plans) {
					var plan = Ember.ObjectProxy.create({ content: plans.objects[0] });
					idToOldPlan[plan.get('id')] = plan;
				});

				planPairs.forEach(function(planPair) {
					planPair.set('previewEmployee', employee);
					var oldPlanId = planPair.get('oldPlanId');
					var newPlanId = planPair.get('newPlanId');
					planPair.set('oldPlan', idToOldPlan[oldPlanId]);
					planPair.set('newPlan', idToNewPlan[newPlanId]);
				});
			});
		}
	}.observes('previewEmployeeIndex'),

	PlanPairClass: Ember.Object.extend({
		controller: undefined,
		oldPlanId: undefined,
		newPlanId: undefined,
		oldPlan: undefined,
		newPlan: undefined,
		previewEmployee: undefined,
		previewEmployeeSettings: Ember.computed.alias('previewEmployee.employeeSettings'),

		getEmployeeCost: function(type, plan) {
			return plan.get('youPremium');
		},

		getEmployerCost: function(type, plan) {
			return plan.get('youContribution');
		},

		employeePlanType: function() {
			var planType = this.get('controller.planType');
			if (planType === 'medicalPlan') {
				return this.get('previewEmployeeSettings.planType');
			} else if (planType === 'dentalPlan') {
				return this.get('previewEmployeeSettings.dentalPlanType');
			} else if (planType === 'visionPlan') {
				return this.get('previewEmployeeSettings.visionPlanType');
			}
			return undefined;
		}.property('controller.planType',
				'previewEmployeeSettings.planType',
				'previewEmployeeSettings.dentalPlanType',
				'previewEmployeeSettings.visionPlanType'),

		oldEmployeeCost: function() {
			var planType = this.get('employeePlanType');
			var plan = this.get('oldPlan');
			if (!plan) { return 0; }
			return this.getEmployeeCost(planType, plan);
		}.property('employeePlanType', 'oldPlan'),

		oldEmployerCost: function() {
			var planType = this.get('employeePlanType');
			var plan = this.get('oldPlan');
			if (!plan) { return 0; }
			return this.getEmployerCost(planType, plan);
		}.property('employeePlanType', 'oldPlan'),

		newEmployeeCost: function() {
			var planType = this.get('employeePlanType');
			var plan = this.get('newPlan');
			if (!plan) { return 0; }
			return this.getEmployeeCost(planType, plan);
		}.property('employeePlanType', 'newPlan'),

		newEmployerCost: function() {
			var planType = this.get('employeePlanType');
			var plan = this.get('newPlan');
			if (!plan) { return 0; }
			return this.getEmployerCost(planType, plan);
		}.property('employeePlanType', 'newPlan'),
	})
});

App.AdminopenenrollmentReviewmedicalplansController = Ember.Controller.extend(App.OpenEnrollmentPlanReviewMixin, {
	enrollments: Ember.computed.alias('model.medicalOpenEnrollments'),
	planType: 'medicalPlan',
	planTypeClass: function() { return App.Plan; }.property(),
	confirm: function() {
		this.set('adminController.isReviewMedicalPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewmedicalplans');
	}
});

App.AdminopenenrollmentPreviewmedicalplansController = Ember.Controller.extend(App.OpenEnrollmentPlanPreviewMixin, {
	enrollments: Ember.computed.alias('model.medicalOpenEnrollments'),
	planType: 'medicalPlan',
	planURL: "/api/e_plan",
	cplanTypeClass: function() { return App.CPlan; }.property(),
	planTypeClass: function() { return App.EPlan; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewMedicalPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewmedicalplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	}
});

App.AdminopenenrollmentReviewdentalplansController = Ember.Controller.extend(App.OpenEnrollmentPlanReviewMixin, {
	enrollments: Ember.computed.alias('model.dentalOpenEnrollments'),
	planType: 'dentalPlan',
	planTypeClass: function() { return App.DentalPlan; }.property(),
	confirm: function() {
		this.set('adminController.isReviewDentalPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewdentalplans');
	}
});

App.AdminopenenrollmentPreviewdentalplansController = Ember.Controller.extend(App.OpenEnrollmentPlanPreviewMixin, {
	enrollments: Ember.computed.alias('model.dentalOpenEnrollments'),
	planType: 'dentalPlan',
	planURL: "/api/e_dental_plan",
	cplanTypeClass: function() { return App.CDentalPlan; }.property(),
	planTypeClass: function() { return App.EDentalPlan; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewDentalPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewdentalplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	}
});

App.AdminopenenrollmentReviewvisionplansController = Ember.Controller.extend(App.OpenEnrollmentPlanReviewMixin, {
	enrollments: Ember.computed.alias('model.visionOpenEnrollments'),
	planType: 'visionPlan',
	planTypeClass: function() { return App.VisionPlan; }.property(),
	confirm: function() {
		this.set('adminController.isReviewVisionPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewvisionplans');
	}
});

App.AdminopenenrollmentPreviewvisionplansController = Ember.Controller.extend(App.OpenEnrollmentPlanPreviewMixin, {
	enrollments: Ember.computed.alias('model.visionOpenEnrollments'),
	planType: 'visionPlan',
	planURL: "/api/e_vision_plan",
	cplanTypeClass: function() { return App.CVisionPlan; }.property(),
	planTypeClass: function() { return App.EVisionPlan; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewVisionPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewvisionplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	}
});

App._LDOpenEnrollmentReviewController = Ember.Controller.extend(App.OpenEnrollmentPlanReviewMixin, {
	// these should be implemented in child controllers
	enrollments: undefined,
	companyHealthPlanPrefix: undefined,
	planClass: function() {}.property(),
	confirm: function() {},
	//

	// overrides the definition in OpenEnrollmentPlanReviewMixin
	enrollmentPlanPairs: function() {
		var LocPlan = this.get('companyHealthPlanPrefix') + 'Plan';

		var planClass = this.get('planClass');
		var enrollments = this.getWithDefault('enrollments', []);
		var enrollmentPlanPairs = enrollments.map(function(enrollment) {
			var planPairs = [];
			var allPlans = enrollment.get('companyHealthCarrier.companyHealthPlans') || [];

			var previousCHE = enrollment.get('previousEnrollment') || undefined;
			if (previousCHE) {
				var previousPlans = previousCHE.get('companyHealthCarrier.companyHealthPlans') || [];
			} else {
				var previousPlans = [];
			}

			// with life we don't need plan mapping, just find via previous CHE
			allPlans.forEach(function(plan) {
				var oldKey;
				var oldPlan;
				var newKey = plan.get(LocPlan + '.id');
				var newPlan = planClass.find(newKey);

				// get the previous plan with the same planType (basic or voluntary)
				var candidates = previousPlans.filterProperty(LocPlan + '.planType', newPlan.get('planType'));
				if (!Ember.isEmpty(candidates)) {

					// if multiple exist, return the one with greatest ID
					candidates.sort(function compare(a, b) {
						var a_id = a.get(LocPlan + '.id');
						var b_id = b.get(LocPlan + '.id');
						var a_key = !isNaN(a_id) ? a_id : 0;
						var b_key = !isNaN(b_id) ? b_id : 0;
						return b_key - a_key;
					});

					oldKey = candidates.objectAt(0).get(LocPlan + '.id');
					oldPlan = planClass.find(oldKey);
				}

				var pair = Ember.Object.create({
					enrollment: enrollment,
					oldPlan: oldPlan,
					newPlan: newPlan
				});
				planPairs.pushObject(pair);
			});

			return Ember.ObjectProxy.create({
				content: enrollment,
				planPairs: planPairs
			});
		});
		return enrollmentPlanPairs;
	}.property('enrollments.healthCarriers.@each.companyHealthPlans'),

});

App.AdminopenenrollmentReviewlifeplansController = App._LDOpenEnrollmentReviewController.extend({
	enrollments: Ember.computed.alias('model.lifeOpenEnrollments'),
	companyHealthPlanPrefix: 'life',
	planClass: function() { return App.LifePlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isReviewLifePlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewlifeplans');
	},
});

App.AdminopenenrollmentReviewaddplansController = App._LDOpenEnrollmentReviewController.extend({
	enrollments: Ember.computed.alias('model.addOpenEnrollments'),
	companyHealthPlanPrefix: 'adnd',
	planClass: function() { return App.AddPlan; }.property(),
	confirm: function() {
		this.set('adminController.isReviewADDPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewaddplans');
	},
});

App.AdminopenenrollmentReviewstdplansController = App._LDOpenEnrollmentReviewController.extend({
	enrollments: Ember.computed.alias('model.stdOpenEnrollments'),
	companyHealthPlanPrefix: 'std',
	planClass: function() { return App.StdPlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isReviewSTDPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewstdplans');
	},
});

App.AdminopenenrollmentReviewltdplansController = App._LDOpenEnrollmentReviewController.extend({
	enrollments: Ember.computed.alias('model.ltdOpenEnrollments'),
	companyHealthPlanPrefix: 'ltd',
	planClass: function() { return App.LtdPlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isReviewLTDPlansCompleted', true);
		this.transitionToRoute('adminopenenrollment.previewltdplans');
	},
});

App._LDOpenEnrollmentPreviewController = Ember.Controller.extend(App.OpenEnrollmentRouteTransitionMixin, {
	// these should be implemented in child controllers
	enrollments: undefined,
	companyHealthPlanPrefix: undefined,
	planClass: function() {}.property(),
	confirm: function() {},
	//

	previewEmployeeIndex: 0,

	previewEmployee: function() {
		return this.get('sortedEmployees')[this.get('previewEmployeeIndex')];
	}.property('previewEmployeeIndex'),

	maxEmployeeIndex: function() {
		return this.get('sortedEmployees.length') - 1;
	}.property('sortedEmployees.length'),

	sortedEmployees: function() {
		var pairs = this.getWithDefault('pairs', []);
		var employeePremiums = {};
		pairs.forEach(function(pair) {
			// sort on costs for new plan
			var employeeId2costs = pair.newPlan.employeeId2costs;
			for (var employeeId in employeeId2costs) {
				if (!employeeId2costs.hasOwnProperty(employeeId)) {
					continue; // skip properties which are only in prototype
				}

				if (!employeePremiums[employeeId]) {
					employeePremiums[employeeId] = 0;
				}

				var cost = parseFloat(employeeId2costs[employeeId].eeCost);
				employeePremiums[employeeId] += !isNaN(cost) ? cost : 0; // sometimes 'cost' will be a string message
			}
		});

		// make tuple of form (employeeId, cost)
		var tuples = Object.keys(employeePremiums).map(function(key) {
			return [key, employeePremiums[key]];
		});

		var tuples = tuples.sort(function(a, b) {
			if (a[1] === b[1]) { // if costs are equal, sort on id
				return a[0] < b[0] ? -1 : 1;
			}
			return a[1] - b[1];  // otherwise sort on cost
		});
		return tuples.map(function(tuple) { // return list of employee ids
			return App.AllEmployee.find(tuple[0]);
		});
	}.property('pairs'),

	plan2employee2costs: function() {
		var pairs = this.getWithDefault('pairs', []);
		var plan2employee2costs = {oldPlans:{}, newPlans:{}};
		for (var pair in pairs) {
			if (!pairs.hasOwnProperty(pair)) {
				continue; // skip properties which are only in prototype
			}
			plan2employee2costs.oldPlans[pairs[pair].oldPlan.planId] = pairs[pair].oldPlan;
			plan2employee2costs.newPlans[pairs[pair].newPlan.planId] = pairs[pair].newPlan;
		}
		return plan2employee2costs;
	}.property('pairs'),

	planPairs: function() {
		var that = this;
		var LocPlan = this.get('companyHealthPlanPrefix') + 'Plan';

		var planPairClass = this.get('PlanPairClass');
		var planClass = this.get('planClass');
		var enrollments = this.getWithDefault('enrollments', []);
		var planPairs = [];

		enrollments.forEach(function(enrollment) {
			var allPlans = enrollment.get('companyHealthCarrier.companyHealthPlans') || [];
			var previousCHE = enrollment.get('previousEnrollment') || undefined;
			if (previousCHE) {
				var previousPlans = previousCHE.get('companyHealthCarrier.companyHealthPlans') || [];
			} else {
				var previousPlans = [];
			}

			// with life we don't need plan mapping, just find via previous CHE
			allPlans.forEach(function(plan) {
				var oldKey;
				var oldPlan;
				var newKey = plan.get(LocPlan + '.id');
				var newPlan = planClass.find(newKey);

				// get the previous plan with the same planType (basic or voluntary)
				var candidates = previousPlans.filterProperty(LocPlan + '.planType', newPlan.get('planType'));
				if (!Ember.isEmpty(candidates)) {

					// if multiple exist, return the one with greatest ID
					candidates.sort(function compare(a, b) {
						var a_id = a.get(LocPlan + '.id');
						var b_id = b.get(LocPlan + '.id');
						var a_key = !isNaN(a_id) ? a_id : 0;
						var b_key = !isNaN(b_id) ? b_id : 0;
						return b_key - a_key;
					});

					oldKey = candidates.objectAt(0).get(LocPlan + '.id'); // there should only be one 'candidate'; take first
					oldPlan = planClass.find(oldKey);
				}

				var pair = planPairClass.create({
					controller: that,
					oldPlan: oldPlan,
					newPlan: newPlan,
				});
				planPairs.pushObject(pair);
			});
		});
		return planPairs;
	}.property('enrollments.healthCarriers.@each.companyHealthPlans'),

	selectLowestPremiumEmployee: function() {
		this.set('previewEmployeeIndex', 0);
	},

	selectHighestPremiumEmployee: function() {
		this.set('previewEmployeeIndex', this.get('sortedEmployees.length') - 1);
	},

	updateSlider: function() {
		var plan2employee2costs = this.get('plan2employee2costs');
		var employee = this.get('previewEmployee');
		var employees = this.get('sortedEmployees');
		if (employee) {
			var that = this;
			var employeeId = employee.get('id');
			var planPairs = this.getWithDefault('planPairs', []);
			planPairs.forEach(function(planPair) {
				var oldPlanId = planPair.getWithDefault('oldPlan.id', '');
				var newPlanId = planPair.get('newPlan.id');

				var oldPlanEmployeeCost;
				var oldPlanEmployerCost;
				var newPlanEmployeeCost;
				var newPlanEmployerCost;
				try {
					oldPlanEmployeeCost = plan2employee2costs.oldPlans[oldPlanId].employeeId2costs[employeeId].eeCost;
					oldPlanEmployerCost = plan2employee2costs.oldPlans[oldPlanId].employeeId2costs[employeeId].erCost;
					newPlanEmployeeCost = plan2employee2costs.newPlans[newPlanId].employeeId2costs[employeeId].eeCost;
					newPlanEmployerCost = plan2employee2costs.newPlans[newPlanId].employeeId2costs[employeeId].erCost;
				} catch(e) {
					console.log(e); // may get here if employee not enrolled in old plan
				}

				planPair.set('previewEmployee', employee);
				planPair.set('oldPlanEmployeeCost', oldPlanEmployeeCost);
				planPair.set('oldPlanEmployerCost', oldPlanEmployerCost);
				planPair.set('newPlanEmployeeCost', newPlanEmployeeCost);
				planPair.set('newPlanEmployerCost', newPlanEmployerCost);
			});
			that.set('previewEmployeeIndex', employees.indexOf(employee));
		}
	},

	previewEmployeeIndexDidChange: function() {
		this.updateSlider();
	}.observes('previewEmployeeIndex'),

	PlanPairClass: Ember.Object.extend({
		controller: undefined,
		oldPlan: undefined,
		newPlan: undefined,
		previewEmployee: undefined,
		oldPlanEmployeeCost: undefined,
		oldPlanEmployerCost: undefined,
		newPlanEmployeeCost: undefined,
		newPlanEmployerCost: undefined,

		oldEmployeeCost: function() {
			return this.get('oldPlanEmployeeCost');
		}.property('previewEmployee', 'oldPlan'),

		oldEmployerCost: function() {
			return this.get('oldPlanEmployerCost');
		}.property('previewEmployee', 'oldPlan'),

		newEmployeeCost: function() {
			return this.get('newPlanEmployeeCost');
		}.property('previewEmployee', 'newPlan'),

		newEmployerCost: function() {
			return this.get('newPlanEmployerCost');
		}.property('previewEmployee', 'newPlan'),
	})
});

App.AdminopenenrollmentPreviewlifeplansController = App._LDOpenEnrollmentPreviewController.extend({
	enrollments: Ember.computed.alias('model.lifeOpenEnrollments'),
	companyHealthPlanPrefix: 'life',
	planClass: function() { return App.LifePlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewLifePlansCompleted', true);

		var nextRoute = this.getNextRoute('previewlifeplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	},
});

App.AdminopenenrollmentPreviewaddplansController = App._LDOpenEnrollmentPreviewController.extend({
	enrollments: Ember.computed.alias('model.addOpenEnrollments'),
	companyHealthPlanPrefix: 'adnd',
	planClass: function() { return App.AddPlan; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewADDPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewaddplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	},
});

App.AdminopenenrollmentPreviewstdplansController = App._LDOpenEnrollmentPreviewController.extend({
	enrollments: Ember.computed.alias('model.stdOpenEnrollments'),
	companyHealthPlanPrefix: 'std',
	planClass: function() { return App.StdPlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewSTDPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewstdplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	},
});

App.AdminopenenrollmentPreviewltdplansController = App._LDOpenEnrollmentPreviewController.extend({
	enrollments: Ember.computed.alias('model.ltdOpenEnrollments'),
	companyHealthPlanPrefix: 'ltd',
	planClass: function() { return App.LtdPlanNew; }.property(),
	confirm: function() {
		this.set('adminController.isPreviewLTDPlansCompleted', true);

		var nextRoute = this.getNextRoute('previewltdplans', this.get('model'));
		this.transitionToRoute(nextRoute);
	},
});

App.AdminopenenrollmentReviewmedicalplansView =
App.AdminopenenrollmentReviewdentalplansView =
App.AdminopenenrollmentReviewvisionplansView =
App.AdminopenenrollmentReviewlifeplansView =
App.AdminopenenrollmentReviewaddplansView =
App.AdminopenenrollmentReviewstdplansView =
App.AdminopenenrollmentReviewltdplansView = Ember.View.extend({
	didInsertElement: function() {
		this.$('.accordion').accordion({
			header: '.panel-header',
			heightStyle: 'content'
		});
	}
});

App.AdminopenenrollmentPreviewmedicalplansView =
App.AdminopenenrollmentPreviewdentalplansView =
App.AdminopenenrollmentPreviewvisionplansView =
App.AdminopenenrollmentPreviewlifeplansView =
App.AdminopenenrollmentPreviewaddplansView =
App.AdminopenenrollmentPreviewstdplansView =
App.AdminopenenrollmentPreviewltdplansView = Ember.View.extend({
	didInsertElement: function() {
		Ember.run.next(this, function() {
			this.$('.ui-slider .ui-slider-handle').append('<div class="arrow"></div>');
		});
	}
});

App.AdminopenenrollmentDoneController = Ember.ObjectController.extend({
	medicalOE: Ember.computed.alias('company.medicalOpenEnrollments'),
	dentalOE: Ember.computed.alias('company.dentalOpenEnrollments'),
	visionOE: Ember.computed.alias('company.visionOpenEnrollments'),
	lifeOE: Ember.computed.alias('company.lifeOpenEnrollments'),
	addOE: Ember.computed.alias('company.addOpenEnrollments'),
	stdOE: Ember.computed.alias('company.stdOpenEnrollments'),
	ltdOE: Ember.computed.alias('company.ltdOpenEnrollments'),
	defaultChc: function() {
		var chc = this.get('medicalOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('dentalOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('visionOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('lifeOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('addOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('stdOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
		chc = this.get('ltdOE.firstObject.companyHealthCarrier');
		if (chc) { return chc; }
	}.property('medicalOE', 'dentalOE', 'visionOE', 'lifeOE', 'addOE', 'stdOE', 'ltdOE'),

	renewalDate: Ember.computed.alias('defaultChc.renewalDate'),
	openEnrollmentEndDate: Ember.computed.alias('defaultChc.openEnrollmentEndDate'),
	openEnrollmentYear: function() {
		return moment(this.get('openEnrollmentEndDate')).year();
	}.property('openEnrollmentEndDate')
});


var EmployeeTearsheetBaseRoute = Ember.Route.extend({
	getEmployeeId: function(transition) {
		return null;
	},

	beforeModel: function(transition) {
		var employeeId = parseInt(this.getEmployeeId(transition));
		this.transitionTo('employeeProfile.personalInfo', employeeId);
	},
});

// /redirect/tearsheet/123

App.RedirectTearsheetRoute = EmployeeTearsheetBaseRoute.extend({
	getEmployeeId: function(transition) {
		return transition.params["redirect.tearsheet"].all_employee_id;
	},
});

App.RedirectTearsheetController = Ember.ObjectController.extend();

// /employee/tearsheet/123

App.EmployeeTearsheetRoute = EmployeeTearsheetBaseRoute.extend({
	getEmployeeId: function(transition) {
		return transition.params["employee.tearsheet"].all_employee_id;
	},
});

App.EmployeeTearsheetController = Ember.ObjectController.extend();

// /employeetearsheet/123

App.EmployeetearsheetRoute = EmployeeTearsheetBaseRoute.extend({
	getEmployeeId: function(transition) {
		return transition.params.employeetearsheet.all_employee_id;
	},
});

App.EmployeetearsheetController = Ember.ObjectController.extend();

// /teamtearsheet/123

App.TeamtearsheetRoute = EmployeeTearsheetBaseRoute.extend({
	getEmployeeId: function(transition) {
		return transition.params.teamtearsheet.all_employee_id;
	},
});

App.TeamtearsheetController = Ember.ObjectController.extend();


App.RedirectdirectoryRoute = Ember.Route.extend(zen._MultiModelMixin, {
	model: function() {
		return this.all({
			"managerAndNotAdminWithHirePermissions": dashboard("managerAndNotAdminWithHirePermissions"),
		});
	},
	afterModel: function(model) {
		if (model.managerAndNotAdminWithHirePermissions) {
			this.transitionTo('teamdirectory');
		}
		else {
			this.transitionTo('employeedirectory');
		}
	},
});

App.OnboardingCalendarfeedsetupRoute = Ember.Route.extend(zen._MultiModelMixin,zen._AuthManagerMixin, {
	model: function() { return {};},
	afterModel: function(model) {
		return this.all(model, {
			"locations": dashboard('company.locations'),
			"departments": dashboard('company.departments'),
			//location and department are the dept or location of the employee if there is one, else null
			"location": dashboard('employee.location'),
			"department": dashboard('employee.department'),
			"isAdminLocation": dashboard('isAdminLocation'),
			"isAdminDeparment": dashboard('isAdminDepartment'),
			"incBDay": true,
			"incAnniv": true,
			"incOnb": true,
			"errorText": "",
			"managerAndNotAdminWithHirePermissions": dashboard('managerAndNotAdminWithHirePermissions')
		});
	},
});

App.OnboardingCalendarfeedsetupController = Ember.ObjectController.extend({
	urlPrefix: function() {
		return this.get('managerAndNotAdminWithHirePermissions')? "/employee_calendarfeed_address": "/company_calendarfeed_address";
	}.property('managerAndNotAdminWithHirePermissions'),

	next: function() {
		var queryStrings = [];
		if ( !(this.get("incBDay") || this.get("incAnniv") || this.get("incOnb"))) {
			this.set("errorText","Select atleast one of the checkboxes");
			return;
		} else {
			this.set("errorText","");
		}

		if (this.get("location")) {
			queryStrings.push("loc=" + this.get("location.id"));
		}
		if (this.get("department")) {
			queryStrings.push("dept=" + this.get("department.id"));
		}
		if (this.get("incBDay")) {
			queryStrings.push("incBDay=1");
		}
		if (this.get("incAnniv")) {
			queryStrings.push("incAnniv=1");
		}
		if (this.get("incOnb")) {
			queryStrings.push("incOnb=1");
		}

		var queryString = (queryStrings.length > 0 ? "?" + queryStrings.join("&") : "");
		this.transitionToRoute("onboarding.calendarfeedaddress", $.getJSON(this.get('urlPrefix') + queryString));
	}
});

App.OnboardingCalendarfeedaddressRoute = Ember.Route.extend(zen._MultiModelMixin, zen._AuthManagerMixin, {
		afterModel: function(model) {
		return this.all(model, {
			"managerAndNotAdminWithHirePermissions": dashboard('managerAndNotAdminWithHirePermissions')
		});
	}
});
App.OnboardingCalendarfeedaddressController = Ember.ObjectController.extend({
	calendarFilters: function() {
		if (this.get("managerAndNotAdminWithHirePermissions")) {
			return " all your subordinates";
		}
		var department_name = this.get('model.department_name');
		var location_name = this.get('model.location_name');
		if (department_name && location_name) {
			return "employees of location - " + location_name + " and department - " + department_name;
		} else if (department_name) {
			return "employees of department - " + department_name;
		} else if (location_name) {
			return "employees of location - " + location_name;
		} else {
			return "all employees";
		}
	}.property('model'),
	back: function () {
		this.transitionToRoute('onboarding.calendarfeedsetup');
	}
});

zen.globalizeSpecials();
