EmberENV = {
	FEATURES: {
		'ember-htmlbars': true,
		'ember-htmlbars-block-params': true,
		'ember-htmlbars-each-with-index': true,
		'ember-htmlbars-inline-if-helper': true,
	}
};

Ember.RSVP.configure('onerror', function(e) {
	if (!e || !e.message) {
		return;
	}
});

function trigger(router, handlerInfos, ignoreFailure, args) {
	var name = args.shift();
	var handler;
	var handlerInfo;
	var cb = function() {
		handler._actions[name].apply(handler, args);
	};

	for (var i=handlerInfos.length-1; i>=0; i--) {
		handlerInfo = handlerInfos[i];
		handler = handlerInfo.handler;

		if (handler._actions && handler._actions[name]) {
			Ember.run.next(cb);

			return;
		}
	}
}

Ember.Route.reopen({
	zeneSend: function() {
		var router = this.router.router;

		trigger(router, router.activeTransition.handlerInfos, false, [].slice.call(arguments));
	},
	redirectTo: function(route) {
		if (window.initialRedirectInProgress) {
			this.replaceWith(route);
		} else {
			this.transitionTo(route);
		}
	}
});

Ember.Application.initializer({
	name: "browsertest",
	initialize: function(container, application) {
		// use unprefixed websockets as a proxy for a modern browser (IE 10+, Firefox 11+, Chrome 14+)
		if ('WebSocket' in window) {
			return;
		}
		//render html the hard way to ensure compatibility with old browsers
		$('body').append('<div class="dialog-bg"></div> <section class="dialog outdated-browser visible"> <div class="container clear"> <h4 style="padding:0px; margin:0px">Time to upgrade your browser!</h4> <hr style="margin-top:15px; margin-bottom:15px"> <div class="left"> 	<div class="icon"></div> 	</div> 	<div class="right"> You are currently using an outdated browser. For the best Zenefits experience, upgrade to one of the following browsers: <div class="chrome"> <span class="browser-icon" style="background: transparent url(/static/img/outdated-browser/chrome.png)"></span><span class="browser-text"> <strong> Chrome 14.0+ </strong> <a href="https://www.google.com/chrome"> Download here</a> </span></div><div class="firefox"> <span class="browser-icon" style="background: transparent url(/static/img/outdated-browser/firefox.png)"></span><span class="browser-text"><strong> Firefox 11.0+ </strong><a href="https://www.mozzila.org/firefox"> Download here</a></span></div></section>');
	}
});

zen._TransactionSavingMixin = Ember.Mixin.create({
	saveAndContinue: function() {
		var args = Array.prototype.slice.apply(arguments);
		var transaction = this.get('transaction');
		var promise = transaction ? transaction.commit() : App.store.commit();
		if (!args.length) {
			return promise;
		}
		return promise.then(function() {
			return this.transitionToRoute.apply(this, args);
		}.bind(this));
	}
});

zen._TransactionArraySavingController = Ember.ArrayController.extend(zen._TransactionSavingMixin);
zen._TransactionSavingController = Ember.ObjectController.extend(zen._TransactionSavingMixin);

var isChildOf = function(parent, possibleChild) {
	if (parent === possibleChild) { return false; }
	var type = parent.constructor;
	var relKeys = type.getRelationships();
	for (var i = 0, l = relKeys.length; i < l; i++) {
		var key = relKeys[i];
		var a = parent.cacheFor(key);
		if (a === possibleChild) { return true; }
		//if (!a || a.get('isLoading')) continue;
		//var rel = type.metaForProperty(key);
		//if (rel.kind != 'belongsTo') continue;
	}
	return false;
};

App.SimpleFlowDispatcherMixin = Ember.Mixin.create({
	//this is a linear follow which means it can only move to index+1 section, if index+1 is not ready it will go back, being implemented by getNextSection
	errorText: null,
	finishLater: function(){
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var currentSection = this.get('currentSection');

		// Do not mark currentSection's isEntered to true here.
		// We do not want to accidentally complete flows and send emails.
		return spider(currentSection, true).then(function() {
			return this.transitionToRoute('index');
		}.bind(this));
	},
	getNextSection: function(flow, currentSection) {
		if (!currentSection || !flow) {
			return currentSection; //should never be undefined
		}

		// Simply get next index. If not present go back to first incomplete or unentered section
		var nextSection = flow.get('sections').findBy('index', currentSection.get('index') + 1);
		if (Ember.isEmpty(nextSection) || !nextSection.get('isReady')) {
			var unenteredSection = flow.get('sections').sortBy('index').filterBy('isReady', true).filterBy('isEntered', false).filter(function(section) { return section.get('index') < currentSection.get('index'); }).get('firstObject');
			var incompleteSection = flow.get('sections').sortBy('index').filterBy('isReady', true).filterBy('isComplete', false).filter(function(section) { return section.get('index') < currentSection.get('index'); }).get('firstObject');
			nextSection = !unenteredSection ? !incompleteSection ? null : incompleteSection : !incompleteSection ? unenteredSection : unenteredSection.get('index') < incompleteSection.get('index') ? unenteredSection : incompleteSection;
		}
		return nextSection;
	},
	_doTransition: function(baseState, nextSection) {
		var functionArgs = [baseState + '.' + nextSection.get('name'), nextSection.get('flow.dispatcherArgs'), nextSection.get('dispatcherArgs')];
		functionArgs = functionArgs.filter(function(arg) { return !!arg; });
		return this.transitionToRoute.apply(this, functionArgs);
	},
	saveWithoutTransition: function() {
		this.set("errorText", "");
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var currentSection = this.get('currentSection');
		if (currentSection && !currentSection.get('isEntered')) {
			currentSection.set('isEntered', true);
		}
		return spider(currentSection, true).then(function() {
			return currentSection.get('flow').reload().then(function() {
				return wrapArrayPromise(currentSection.get('flow.sections').invoke('reload')).then(function() {
					if (!currentSection.get('isComplete')) {
						this.set("errorText", "Please complete all required fields");
					}
				}.bind(this));
			}.bind(this));
		}.bind(this));
	},
	saveAndTransitionToNextSection: function() {
		this.set("errorText", "");
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var currentSection = this.get('currentSection');
		if (currentSection && !currentSection.get('isEntered')) {
			currentSection.set('isEntered', true);
		}
		return spider(currentSection, true).then(function() {
			return currentSection.get('flow').reload().then(function() {
				return wrapArrayPromise(currentSection.get('flow.sections').invoke('reload')).then(function() {
					if (!currentSection || currentSection.get('isComplete')) {
						var nextSection = this.getNextSection(currentSection.get('flow'), currentSection);
						if (!nextSection) {
							return this.transitionToRoute('index');
						} else {
							return this._doTransition(baseState, nextSection);
						}
					} else {
						this.set("errorText", "Please complete all required fields");
					}
				}.bind(this));
			}.bind(this));
		}.bind(this));
	},
	skipAndTransitionToNextSection: function() {
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var nextSection = this.getNextSection(this.get('flow'), this.get('currentSection'));
		if (!nextSection) {
			this.transitionToRoute('index');
		} else {
			this._doTransition(baseState, nextSection);
		}
	},
	resumeFlow: function() {
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var unenteredSection = this.get('currentSection.flow').get('sections').sortBy('index').filterBy('isReady', true).filterBy('isEntered', false).get('firstObject');
		var incompleteSection = this.get('currentSection.flow').get('sections').sortBy('index').filterBy('isReady', true).filterBy('isComplete', false).get('firstObject');
		var nextSection = !unenteredSection ? !incompleteSection ? null : incompleteSection : !incompleteSection ? unenteredSection : unenteredSection.get('index') < incompleteSection.get('index') ? unenteredSection : incompleteSection;

		if (!nextSection) {
			this.transitionToRoute('index');
		} else {
			this._doTransition(baseState, nextSection);
		}
	},
	backToPreviousSection: function() {
		var baseState = this.get('currentRouteName').substring(0, this.get('currentRouteName').lastIndexOf('.'));
		var flow = this.get('flow');
		var currentSection = this.get('currentSection');

		if (!currentSection || !flow) {
			return;
		}

		var previousSection = flow.get('sections').findBy('index', currentSection.get('index') - 1);
		if (!Ember.isEmpty(previousSection) && previousSection.get('isReady')) {
			this._doTransition(baseState, previousSection);
		}
		return;
	},
});

zen._FlowCurrentSectionRouteMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		var flow = controller.get('flow') || controller.get('backendFlowState');
		if (!flow) { return; }
		var currentState = this.routeName;
		var baseState = currentState.substring(0, currentState.lastIndexOf('.'));
		var currentSectionName = currentState.substring(currentState.lastIndexOf('.') + 1);
		var dispatcherArgs = controller.get('_dispatcherArgs');
		var currentSection;
		if (typeof dispatcherArgs == 'undefined'){
			currentSection = flow.get('sections').findBy('name', currentSectionName);
		} else {
			currentSection = flow.get('sections').find(function(section){
				return section.get('name') == currentSectionName && section.get('dispatcherArgs') == dispatcherArgs;
			});
		}
		controller.set('currentSection', currentSection);
		controller.set('currentRouteName', currentState);
	},
});


var _reverseChainedArrayInvoke = function(arr, method) {
	var helper = function(i) {
		return function() {
			return Ember.RSVP.all(arr[i].invoke(method));
		};
	};
	var promise = Ember.RSVP.resolve();
	for (var i = arr.length - 1, l = 0; i >= 0; i--) {
		promise = promise.then(helper(i));
	}
	return promise;
};

var spider = function(model, commit) {
	var method = commit ? "save" : "rollback";
	var newAndDirtyRecords = App.store.allDirtyRecords();
	var newRecords = [];
	var newRecodsParents = [];
	var newRecordsChildren = {};
	var dirtyRecords = [];
	for (var i = 0, l = newAndDirtyRecords.length; i < l; i++) {
		var record = newAndDirtyRecords[i];
		if (record.get('isNew')) {
			newRecords.push(record);
		}
		else {
			dirtyRecords.push(record);
		}
	}
	for (var i = 0, l = newRecords.length; i < l; i++) {
		var parent = newRecords[i];
		for (var j = 0, l2 = newRecords.length; j < l2; j++) {
			var possibleChild = newRecords[j];
			// optimization for mass creation
			if (parent.constructor === possibleChild.constructor) {
				continue;
			}
			if (isChildOf(parent, possibleChild)) {
				newRecordsChildren[possibleChild] = parent;
			}
		}
	}
	var newRecordNested = [];
	for (var i = 0, l = newRecords.length; i < l; i++) {
		var record = newRecords[i];
		var depth = 0;
		var current = record;
		while (true) { // eslint-disable-line no-constant-condition
			var current = newRecordsChildren[current];
			if (!current) { break; }
			depth++;
		}
		var nested = newRecordNested[depth];
		if (!nested) {
			nested = newRecordNested[depth] = [];
		}
		nested.push(record);
	}
	return _reverseChainedArrayInvoke(newRecordNested, method).then(function() {
		return Ember.RSVP.all(newRecodsParents.invoke(method));
	}).then(function() {
		return Ember.RSVP.all(dirtyRecords.invoke(method));
	}).then(function() {
		if (model instanceof Ember.Model) {
			return model.reload();
		}
	});
};

var saveWithChildren = function(model) {
	return spider(model, true);
};

var rollbackWithChildren = function(model) {
	return spider(model, false);
};

DS.Model.reopen({
	saveWithChildren: function() {
		return saveWithChildren(this);
	},
	rollbackWithChildren: function() {
		return rollbackWithChildren(this);
	},
});

zen._AbstractSavingMixin = Ember.Mixin.create({
	allProperties: function() {
		for (var i = 0; i < arguments.length; i++) {
			if (!this.get(arguments[i])) { return false; }
		}
		return true;
	},
	someProperties: function() {
		for (var i = 0; i < arguments.length; i++) {
			if (this.get(arguments[i])) { return true; }
		}
		return false;
	},
	_saveOrRollbackDelegate: function(saveOrRollbackFunction, args) {
		args = Array.prototype.slice.apply(args);
		var model = this.get('model');
		var promise = saveOrRollbackFunction(model);
		if (!args.length) {
			return promise;
		}
		return promise.then(function() {
			this.transitionToRoute.apply(this, args);
		}.bind(this));
	},
	saveAndContinue: function() {
		return this._saveOrRollbackDelegate(saveWithChildren, arguments);
	},
	rollbackAndContinue: function() {
		return this._saveOrRollbackDelegate(rollbackWithChildren, arguments);
	},
	errorText: null,
	hasMissingValues: false,
	saveCheckAndContinue: function() {
		var args = Array.prototype.slice.apply(arguments);
		var checkVariableName = args.shift();
		this.set("errorText", "");
		this.set('hasMissingValues', false);
		return this.saveAndContinue().then(function() {
			if (this.get(checkVariableName)) {
				if (args.length) {
					this.transitionToRoute.apply(this, args);
				}
			}
			else {
				this.set("errorText", "Please complete all required fields");
				this.set('hasMissingValues', true);
			}
		}.bind(this));
	}

});

zen._AbstractArraySavingController = Ember.ArrayController.extend(zen._AbstractSavingMixin);
zen._AbstractSavingController = Ember.ObjectController.extend(zen._AbstractSavingMixin);

zen._ModalObjectController = Ember.ObjectController.extend({
	save: function() {
		return this.get('model.transaction').commit().then(this.send.bind(this, "hideModal"));
	},
	close: function() {
		var model = this.get('model'),
		transaction = model.get('transaction');

		if (transaction) { transaction.rollback(); }
		if (model.get('errors')) { model.set('errors', null); }

		this.send("hideModal");
	}
});

zen._CountdownPromiseResolverMixin = Ember.Mixin.create({
	resolveIfZero: function(employeesLeft, resolve) {
		if(employeesLeft == 0) {
			resolve();
		}
	},
});

zen._RollbackWithChildrenOnDeactivateMixin = Ember.Mixin.create({
	actions: {
		willTransition: function() {
			var model = this.get('controller.model');
			return rollbackWithChildren(model);
		}
	}
});

zen._RollbackOnDeactivateMixin = Ember.Mixin.create({
	actions: {
		willTransition: function() {
			var model = this.get('controller.model');
			var doRollback = function (o) {
				if (o && o.get && (o.get('isModified') || o.get('isNew'))) {
					o.get('transaction').rollback();
				}
			};
			if (model instanceof Ember.ArrayProxy) {
				model.forEach(doRollback);
			}
			else if (model instanceof DS.Model || model instanceof Ember.ObjectProxy) {
				doRollback(model);
			}
			else if (model && typeof model == 'object') {
				var objectKeys = Object.keys(model);
				for (var i = 0; i < objectKeys.length; i++) {
					var item = objectKeys[i];
					var itemModel = model[item];
					if (itemModel instanceof DS.Model || itemModel instanceof Ember.ObjectProxy) {
						doRollback(itemModel);
					} else if (itemModel instanceof Ember.ArrayProxy) {
						itemModel.forEach(doRollback);
					}
				}
			}
			else {
				Ember.warn("zen._RollbackOnDeactivateMixin mixin performed a nil operation");
			}
		}
	}
});

zen._EmployeephotouploadMixin = Ember.Mixin.create({
	uploadFile: function(servicesList, model, complete){
		var employee = model;
		var transaction = App.store.transaction();
		filepicker.setKey(FILEPICKER_KEY);
		filepicker.pickAndStore({
				mimeTypes: ['image/*'],
				container:'window',
				services: servicesList,
				maxSize:   20*1024*1024  // Max file size 20MB
			},
			{location: "S3"},
			function(FPFile){
				filepicker.convert(FPFile[0].url,
					{height: 1100, width: 1100, fit:'max', rotate: "exif"},
					{location: "S3"},
					function(newFPFile){
						employee.set('photoUrl', newFPFile.url);
						transaction.commit().then(function() {
							if (complete) {
								complete();
							} else {
								this.send('showModal','employeephotocrop', employee);
							}
						}.bind(this));
					}.bind(this),
					function(newFPError){
						console.log(newFPError.toString());
					}
				);
			}.bind(this),
			function(FPError){
				console.log(FPError.toString());
			}
		);
	}
});

zen._CompanyLegalInfoVerificationRouteMixin = Ember.Mixin.create({
	// override serviceTypes if you prefer to use different services
	serviceTypes: function() {
		return ['B'];
	},
	// override needsVerification if extra logic is needed
	needsVerification: function(model) {
		return true;
	},
	redirect: function(model, transition) {
		this._super(model, transition);
		var legalInfoComplete = model.get('contractorSettings.company.isCompanyLegalInfoComplete');
		if (Ember.isNone(legalInfoComplete)) {
			return;
		}
		if (!legalInfoComplete || this.needsVerification(model)) {
			var verificationController = this.controllerFor('verification.verifycompany');
			verificationController.set('preVerificationTransition', this.routeName);
			verificationController.set('requestedServiceTypes', this.serviceTypes());
			verificationController.set('preModel', model.get('contractorSettings'));
			this.transitionTo('verification.verifycompany');
			return;
		}
	},
});

(function () {
	// Plain JS object extend (don't use on ember objects)
	var _extend = function(obj) {
		Array.prototype.slice.apply(arguments).slice(1).forEach(function(source) {
			if (source) {
				for (var prop in source) {
					obj[prop] = source[prop];
				}
			}
		});
		return obj;
	};

	zen._MultiModelMixin = Ember.Mixin.create({
		all: function(primary, others) {
			var hasPrimary;

			if (others === undefined) {
				others = primary;
				hasPrimary = false;
			}
			else {
				others = others || {};
				others['_'] = primary;
				hasPrimary = true;
			}
			for (var k in others) {
				var m = others[k];
				// always try and wrap with wrapArrayPromise (it won't do anything if it's not necessary)
				others[k] = wrapArrayPromise(m);
			}
			return Ember.RSVP.hash(others).then(function(hash) {
				if (hasPrimary) {
					var model = hash['_'];
					delete hash['_'];
					if (this.otherModels) {
						_extend(hash, this.otherModels);
					}
					this.otherModels = hash;
					return model;
				}
				else {
					return hash;
				}
			}.bind(this));
		},
		setupController: function(controller, model) {
			this._super(controller, model);
			controller.setProperties(this.otherModels);
			this.otherModels = null; // release memory?
		}
	});
})();
// Save a ref to this on App to avoid more global
// refs when used in Ember CLI app
if (typeof App != 'undefined') {
	App._MultiModelMixin = zen._MultiModelMixin;
}

zen._EmployeeRequestControllerMixin = Ember.Mixin.create({
	reset: function() {
		this.set('isDoingAgreements', false);
		this.set('isDoingEmployeeHandbook', false);
		this.set('isDoingBackgroundCheck', false);
		this.set('isDoingTax', false);
		this.set('isDoingEligibility', false);
		this.set('isDoingEligibilityProofUpload', false);
		this.set('forceRepeats', false);
		history.back();
	},
	cancel: function() {
		this.set('errorText', '');
		this.reset();
		this.send('hideModal');
	},
});

/**
 * Mixin for calculating the effective date that the commuter plan will go into effect
 * if we are making changes on the current day.
 */
zen._CommuterEffectiveDateMixin = Ember.Mixin.create({
	_monthNames: [
		"January", "February", "March",
		"April", "May", "June",
		"July", "August", "September",
		"October", "November", "December"
	],
	_calculateEffectiveDate: function() {
		var now = new Date();
		var day = parseInt(now.getDate());
		if (day <= 15) {
			return new Date(now.getFullYear(), now.getMonth() + 1, 1);
		}
		else {
			return new Date(now.getFullYear(), now.getMonth() + 2, 1);
		}
	},
	getEffectiveDate: function(minStartDate) {
		var effectiveDate = minStartDate || this._calculateEffectiveDate();
		return zen.dateAsString(effectiveDate);
	},
	effectiveMonth: function() {
		var effectiveDate = this._calculateEffectiveDate();
		return this.get('_monthNames').objectAt(effectiveDate.getMonth());
	}.property(),
	_getNextDate: function(date) {
		var day = parseInt(date.getDate());
		if (day <= 15) {
			return new Date(date.getFullYear(), date.getMonth() + 1, 1);
		}

		return new Date(date.getFullYear(), date.getMonth() + 2, 1);
	},
	effectiveDateChoices: function(minStartDate) {
		var choices = Ember.A();
		choices.pushObject(Ember.Object.create({'name': "Select", 'id': null}));

		var d = minStartDate || this._getNextDate(new Date());
		for (var i = 0; i < 6; i++) {
			choices.pushObject(Ember.Object.create({'name': d.getDate() + " " + monthsArray[d.getMonth()] + ", " + d.getFullYear(), 'id': zen.dateAsString(d)}));
			d = this._getNextDate(d);
		}
		return choices;
	},
});

zen._UnicardSetupStartDateMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('effectiveDateChoices', this.effectiveDateChoices());
		controller.set('effectiveDate', this.getEffectiveDate());
	},
});

zen._EffectiveDate401kMixin = Ember.Mixin.create({
	_calculateEffectiveDate: function(company401kEnrollment) {
		var now = new Date();
		var day = parseInt(now.getDate());
		var effectiveDate;
		// day <= 15 (and it is not march 2016 for which this hack was introduced. // https://jira.inside-zen.com/browse/F01K-494
		if (day <= 15 && !(now.getFullYear() == 2016 && now.getMonth() == 2)) {
			effectiveDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
		}
		else {
			effectiveDate = new Date(now.getFullYear(), now.getMonth() + 2, 1);
		}

		if (company401kEnrollment.get('isSafeHarborPlan') && effectiveDate.getMonth() > 9) {
			effectiveDate = new Date(effectiveDate.getFullYear() + 1, 0, 1);
		}

		return effectiveDate;
	},
	getEffectiveDate: function(company401kEnrollment) {
		var effectiveDate = this._calculateEffectiveDate(company401kEnrollment);
		return zen.dateAsString(effectiveDate);
	},
	_getNextDate: function(date, company401kEnrollment) {
		var day = parseInt(date.getDate());
		// day <= 15 (and it is not march 2016 for which this hack was introduced. // https://jira.inside-zen.com/browse/F01K-494
		if (day <= 15 && !(date.getFullYear() == 2016 && date.getMonth() == 2)) {
			var date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
			if (company401kEnrollment.get('isSafeHarborPlan') && date.getMonth() > 9) {
				date = new Date(date.getFullYear() + 1, 0, 1);
			}

			return date;
		}

		var date = new Date(date.getFullYear(), date.getMonth() + 2, 1);
		if (company401kEnrollment.get('isSafeHarborPlan') && date.getMonth() > 9) {
			date = new Date(date.getFullYear() + 1, 0, 1);
		}

		return date;
	},
	effectiveDateChoices: function(company401kEnrollment) {
		var choices = Ember.A();
		choices.pushObject(Ember.Object.create({'name': "Select", 'id': null}));

		var d = this._getNextDate(new Date(), company401kEnrollment);
		for (var i = 0; i < 6; i++) {
			choices.pushObject(Ember.Object.create({'name': d.getDate() + " " + monthsArray[d.getMonth()] + ", " + d.getFullYear(), 'id': zen.dateAsString(d)}));
			d = this._getNextDate(d, company401kEnrollment);
		}
		return choices;
	},
});

zen.ShowCancelTerminationModalMixin= Ember.Mixin.create({
	cancelTermination: function(terminationAction) {
		// Logging for metrics
		this.eventLogger.log('offboarding_terminate_v1_cancel_termination_modal_opened', {
			company_id: this.get('model.company.id'),
		});
		this.send(
			'showModal',
			'offboarding.terminate.modals.cancel-termination-modal',
			{ employee: terminationAction.get('employment.employee'), terminationAction: terminationAction });
	},
});

zen._ShowRevokeOfferModalMixin = Ember.Mixin.create({
	revokeOffer: function(newhire) {
		var employee = this.get('model.employee') || this.get('model');

		// Logging for metrics
		this.eventLogger.log('offboarding_show_revoke_offer_modal_clicked', {
			company_id: this.get('model.company.id'),
		});
		this.transitionToRoute('redirect.tearsheet', employee.get('id'));
		this.send(
			'showModal',
			'offboarding.terminate.modals.revoke-modals.basic-revoke-offer-modal',
			{ newhire: newhire, employee: employee, requester: this.get('requester')});
	},
});


zen._ShortCircuitEmployerStatsRouteMixin = Ember.Mixin.create({
	promiseForSchEmployerStats: function() {
		// XXXMOH & GW: Replace this ugly code with api (and combine short circuit OE stats)
		return new Ember.RSVP.Promise(function(resolve, reject) {
			var url = '/custom_api/schEmployerStats/'; // By default the schEmployerStats' enrollment type is BoR
			var defaultResponse = Ember.Object.create({
				medical: 1,
				additionalMedical: 1,
				dental: 1,
				vision: 1,
			});
			Ember.ajax({
				url: url,
				type: "get",
				success: function(response) {
					return resolve(Ember.Object.create(response));
				},
				failure: function(response) {
					reject(response);
				},
				error: function() {
					// Setting them to 1 will make sure the hiring page works. This means
					// that we should proactively monitor the schStats server errors and notify
					// IMs so that companies can enter their short circuit plans after hiring someone
					// with server error.
					return resolve(defaultResponse);
				},
			});
		});
	},
});


zen._TerminationBenefitsAndCobraMixin = Ember.Mixin.create({
	truthy: true,
	falsy: false,

	isTermDateValid: false,
	administerCOBRA: null,
	removeFromBenefits: null,
	isEligibleForBenefitsTermination: null,
	isMovingOutOfBenefits: null,
	isCobraEligibleOnDate: null,
	benefitsEndDate: null,
	earliestBenefitsEndDate: null,
	coverFullCobra: null,
	cobraMonths: null,
	borTerminateMedical: false,
	borTerminateDental: false,
	borTerminateVision: false,
	borTerminateLife: false,
	borTerminateLTD: false,
	borTerminateSTD: false,
	borTerminateADD: false,
	benefitsCalc: null,

	doNotRemoveFromBenefits: function() {
		return this.get('removeFromBenefits') === false;
	}.property('removeFromBenefits'),

	afterSetupControllerInitialState: function(benefits) {
		var employee = this.get('employee');
		if (!employee) {
			employee = this.get('model');
		}

		var settings = this.get('employeeSettings');
		// employeeSettings might be null here, most probably in tests. It's autoOneToOneField
		// but it doesn't seem to get auto-created when we try to access it from the front-end.

		this.setProperties({
			borCobraMedical: Ember.Object.create({
				'planType': settings? settings.get('planType'): null,
				'effectiveDate': settings? settings.get('approvedDate'): null,
				'planId': employee.get('selectedPlan'),
				'cost': settings? settings.get('medicalCost'): null
			}),
			borCobraDental: Ember.Object.create({
				'planType': settings? settings.get('dentalPlanType'): null,
				'effectiveDate': settings? settings.get('dentalApprovedDate'): null,
				'planId': employee.get('selectedDentalPlan'),
				'cost': settings? settings.get('dentalCost'): null
			}),
			borCobraVision: Ember.Object.create({
				'planType': settings? settings.get('visionPlanType'): null,
				'effectiveDate': settings? settings.get('visionApprovedDate'): null,
				'planId': employee.get('selectedVisionPlan'),
				'cost': settings? settings.get('visionCost'): null
			}),
		});

		var terminationBenefits = benefits;
		if (terminationBenefits && terminationBenefits['medicalSCReadOnly']) {
			this.set('borTerminateMedical', true);
		}
		if (terminationBenefits && terminationBenefits['dentalSCReadOnly']) {
			this.set('borTerminateDental', true);
		}
		if (terminationBenefits && terminationBenefits['visionSCReadOnly']) {
			this.set('borTerminateVision', true);
		}
		if (terminationBenefits && terminationBenefits['lifeSCReadOnly']) {
			this.set('borTerminateLife', true);
		}
		if (terminationBenefits && terminationBenefits['ltdSCReadOnly']) {
			this.set('borTerminateLTD', true);
		}
		if (terminationBenefits && terminationBenefits['stdSCReadOnly']) {
			this.set('borTerminateSTD', true);
		}
		if (terminationBenefits && terminationBenefits['addSCReadOnly']) {
			this.set('borTerminateADD', true);
		}
	},

	afterSetupControllerCheckCobra: function() {
		var hasSetupCobraClassification = this.get('companySettings.company.companyCobra.cobraClassification').sortBy('effectiveDate').get('lastObject');
		var employee = this.get('employee');
		if (!employee) {
			employee = this.get('model');
		}

		var o = Ember.Object.create({
			'product': this.get('isManagerButNotAdmin') ? "manager": "admin",
			'employeeId': employee.get('id'),
			'category': this.get('category'),
			'parentController': this,
		});

		if (!hasSetupCobraClassification && employee.get('hasPlansForCobra') && this.get('terminationSettings.companyCobraStatus') !== 'third-party') {
			if(!this.get('switches.cobra_configuration_redesign')) {
			this.send('showModal', 'offboarding.classificationpopup', o);
			}
			return false;
		}

		return true;
	},

	askAdministerQuestion: function() {
		return !this.get('isManagerButNotAdmin') && this.get('terminationSettings.companyCobraStatus') !== 'third-party';
	}.property('isManagerButNotAdmin', 'terminationSettings.companyCobraStatus'),

	defaultAdministerCOBRA: function() {
		if (!this.get('cobraRule.isCobraOffered')) {
			return false;
		}
		var employee = this.get('employee');
		if (!employee) {
			employee = this.get('model');
		}

		if (!employee.get('isFulltime')) {
			return false;
		}

		if (this.get('terminationSettings.companyCobraStatus') === 'third-party') {
			return false;
		}

		return true;
	}.property('terminationSettings.companyCobraStatus', 'employee.isFulltime', 'model.isFulltime', 'cobraRule.isCobraOffered'),
	terminationDaysAfterDate: function(terminationDate, fromDate) {
		if(!terminationDate) {
			return false;
		}
		return moment(terminationDate).diff(fromDate, 'days');
	},
	terminationDaysAfterToday: function(terminationDate) {
		return this.terminationDaysAfterDate(terminationDate, new Date());
	},
	termination100FutureHelper: function(terminationDate) {
		var daysAfterToday = this.terminationDaysAfterToday(terminationDate);
		if (daysAfterToday >= 100) {
			return true;
		}
		return false;
	},
	termination100Future: function() {
		return this.termination100FutureHelper(this.get('ft_enddate'));
	}.property('ft_enddate'),
	terminationDaysBeforeDate: function(terminationDate, fromDate) {
		if(!terminationDate) {
			return false;
		}
		return moment(fromDate).diff(terminationDate, 'days');
	},
	terminationDaysBeforeToday: function(terminationDate){
		return this.terminationDaysBeforeDate(terminationDate, new Date());
	},
	termination30Past: function() {
		return this.termination30PastHelper(this.get('ft_enddate'));
	}.property('ft_enddate'),
	terminationPastEarliest: function() {
		return this.terminationPastEarliestHelper(this.get('ft_enddate'), this.get('earliestBenefitsTerminationDate'));
	}.property('ft_enddate', 'earliestBenefitsTerminationDate'),
	terminationSameDay: function() {
		var daysFromToday = this.terminationDaysBeforeToday(this.get('ft_enddate'));
		return daysFromToday == 0;
	}.property('ft_enddate'),
	terminationInFuture: function() {
		var daysFromToday = this.terminationDaysBeforeToday(this.get('ft_enddate'));
		return daysFromToday < 0;
	}.property('ft_enddate'),
	terminationBeforeHireDate: function() {
		var daysToHireDate = this.terminationDaysBeforeDate(this.get('ft_enddate'), this.get('hireDate'));
		return daysToHireDate > 0;
	}.property('ft_enddate', 'hireDate'),
	terminationSameDayAsHireDate: function() {
		var daysToHireDate = this.terminationDaysBeforeDate(this.get('ft_enddate'), this.get('hireDate'));
		return daysToHireDate === 0;
	}.property('ft_enddate', 'hireDate'),
	terminationBeforeOrOnHireDate: function() {
		return this.get('terminationBeforeHireDate') || this.get('terminationSameDayAsHireDate');
	}.property('terminationBeforeHireDate', 'terminationSameDayAsHireDate'),
	_callEmployeeBenefits: function() {
		this.updateEmployeeBenefits(
			this.get('ft_enddate') || '',
			this.get('formTerminationType'),
			this.get('formEmploymentType')
		);
	},
	updateEmployeeBenefits: function(referenceDateString, formTerminationType, newEmploymentType) {
		var controller = this;
		var empId = this.get('employee.id');
		if (!empId) {
			empId = this.get('model.id');
		}

		var sanitizedDate = moment(referenceDateString, "MM/DD/YYYY");
		if (!sanitizedDate.isValid()) {
			controller.set('isTermDateValid', false);
			return Ember.RSVP.resolve();
		}

		// Python strftime() constrains to 1900, Python datetime limits to year 9999
		if (sanitizedDate.year() < 1900 || sanitizedDate.year() > 9999) {
			controller.set('isTermDateValid', false);
			return Ember.RSVP.resolve();
		}

		referenceDateString = sanitizedDate.format("MM/DD/YYYY");

		return Ember.ajax({
			url: '/api/termination_benefits/' + empId + "/?referenceDate=" + referenceDateString + "&terminationType=" + formTerminationType + "&newEmploymentType=" + newEmploymentType,
			type:'get',
		}).then(function(response) {
			controller.set('benefitsCalc', Ember.Object.create(response));
			// If employee is cobra eligible on a certain date then it means they also have active coverage on that date.
			// So that serves as a check for removeFromBenefits flag as well
			controller.set('removeFromBenefits', null);  // WATCH OUT: removeFromBenefits (True, None) will remove benefits (False) will not
			controller.set('isEligibleForBenefitsTermination', response.isEligibleForBenefitsTermination); // Just checks if they had benefits.
			controller.set('isCobraEligibleOnDate', response.isCobraEligibleOnDate); // Checks if they had benefits, plus some special COBRA logic
			controller.set('cobraIneligibilityReason', response.cobraIneligibilityReason);
			controller.set('benefitsEndDate', response.benefitsEndDate);
			controller.set('earliestBenefitsEndDate', response.earliestBenefitsEndDate);
			controller.set('earliestBenefitsTerminationDate', response.earliestBenefitsTerminationDate);
			controller.set('isTermDateValid', true);
			controller.set('administerCOBRA', controller.get('defaultAdministerCOBRA') && response.isCobraEligibleOnDate);
			controller.set('isMovingOutOfBenefits', response.isMovingOutOfBenefits); // Employee no longer qualifies for benefits.
		}.bind(this)).catch(function(error) {
			controller.set('benefitsCalc', Ember.Object.create({error: error}));
			controller.set('isTermDateValid', false);
		}.bind(this));
	},

	updateEligibility: function(){
		return this._callEmployeeBenefits();
	}.observes('ft_enddate', 'formTerminationType', 'formEmploymentType'),

	termination30PastHelper: function(terminationDate) {
		var daysFromToday = this.terminationDaysBeforeToday(terminationDate);
		if (daysFromToday > 30) {
			return true;
		}
		return false;
	},

	terminationPastEarliestHelper: function(terminationDate, earlyDate) {
		var termDate = moment(terminationDate, "MM/DD/YYYY");
		var earliestDate = moment(earlyDate, "MM/DD/YYYY");
		return termDate < earliestDate;
	},

	possibleBenefitsEndDate: function() {
		var benefitsEndDate = this.get('benefitsEndDate');
		var earliestBenefitsTerminationDate = this.get('earliestBenefitsTerminationDate');
		if (!benefitsEndDate) {
			return;
		}
		if (!earliestBenefitsTerminationDate) {
			return;
		}
		benefitsEndDate = moment(benefitsEndDate, "MM/DD/YYYY");
		earliestBenefitsTerminationDate = moment(earliestBenefitsTerminationDate, "MM/DD/YYYY");

		if (benefitsEndDate < earliestBenefitsTerminationDate) {
			return this.get('earliestBenefitsEndDate');
		} else {
			return benefitsEndDate;
		}
	}.property('benefitsEndDate', 'earliestBenefitsEndDate', 'earliestBenefitsTerminationDate'),

	cobraName: Ember.computed.oneWay('cobraRule.cobraName'),
	cobraType: function() {
		if (this.get('companySettings.cobraType')) {
			return this.get('companySettings.cobraType').charAt(0);
		} else {
			return 'F'; // default to federal COBRA
		}
	}.property('companySettings.cobraType'),
	isCobraOffered: Ember.computed.oneWay('cobraRule.isCobraOffered'),

	isFederalCOBRA: function(){
		return this.get('cobraType') == 'F';
	}.property('cobraType'),

	cobraOptionDeclined: function() {
		var cobraOptionDeclinedVar = ( typeof this.get('administerCOBRA') != "undefined" && this.get('administerCOBRA') == false);
		return cobraOptionDeclinedVar;
	}.property('administerCOBRA'),

	cobraMonthOptions: function() {
		var coverageMonths = this.get('cobraRule.coveragePeriodInMonths');
		if (coverageMonths) {
			var monthChoices = [];
			var i;
			for (i = 1; i <= coverageMonths; i++) {
				monthChoices.push({ "id": String(i), "name": String(i)});
			}
			return monthChoices;
		} else {
			return [];
		}
	}.property('cobraRule.coveragePeriodInMonths'),

	cobraNotificationDateHelper: function(lastDay) {
		var today = new Date();
		var terminationDate = zen.americanDateAsDate(lastDay);
		var cobraNotificationDate;

		if (today > terminationDate) {
			cobraNotificationDate = today;
		}
		else {
			cobraNotificationDate = terminationDate;
		}

		cobraNotificationDate.setDate(cobraNotificationDate.getDate() + 2);
		return (cobraNotificationDate.getMonth() + 1) + "/" + cobraNotificationDate.getDate() + "/" + cobraNotificationDate.getFullYear();
	},

	cobraNotificationDate: function() {
		return this.cobraNotificationDateHelper(this.get('ft_enddate'));
	}.property('ft_enddate'),

	isBorTerminatePrimaryLine: Ember.computed.or('borTerminateMedical', 'borTerminateDental', 'borTerminateVision'),

	isCobraEligibleOnDateOrBor: Ember.computed.or('isCobraEligibleOnDate', 'isBorTerminatePrimaryLine'),

	hasCobraOptions: function() {
		var employee = this.get('employee');
		if (!employee) {
			employee = this.get('model');
		}

		return this.get('isCobraEligibleOnDateOrBor') && employee.get('isEligibleForHealth') && this.get('cobraRule.isCobraOffered');
	}.property('isCobraEligibleOnDateOrBor', 'employee.isEligibleForHealth', 'cobraRule.isCobraOffered'),

	canOfferEmployerSponsoredCobra: Ember.computed.and('administerCOBRA', 'cobraRule.isZenefitsAdministering', 'isCobraEligibleOnDateOrBor'),

	borBenefits: Ember.computed.alias('benefitsCalc.hasBorBenefits'),
	askForMedicalPlan: Ember.computed.alias('benefitsCalc.askForMedicalSC'),
	askForDentalPlan: Ember.computed.alias('benefitsCalc.askForDentalSC'),
	askForVisionPlan: Ember.computed.alias('benefitsCalc.askForVisionSC'),
	askForLifePlan: Ember.computed.alias('benefitsCalc.askForLifeSC'),
	askForSTDPlan: Ember.computed.alias('benefitsCalc.askForStdSC'),
	askForLTDPlan: Ember.computed.alias('benefitsCalc.askForLtdSC'),
	askForADDPlan: Ember.computed.alias('benefitsCalc.askForAddSC'),
	medicalSCReadOnly: Ember.computed.alias('benefitsCalc.medicalSCReadOnly'),
	dentalSCReadOnly: Ember.computed.alias('benefitsCalc.dentalSCReadOnly'),
	visionSCReadOnly: Ember.computed.alias('benefitsCalc.visionSCReadOnly'),
	lifeSCReadOnly: Ember.computed.alias('benefitsCalc.lifeSCReadOnly'),
	stdSCReadOnly: Ember.computed.alias('benefitsCalc.stdSCReadOnly'),
	ltdSCReadOnly: Ember.computed.alias('benefitsCalc.ltdSCReadOnly'),
	addSCReadOnly: Ember.computed.alias('benefitsCalc.addSCReadOnly'),

	validateModelsForCobra: function() {
		var employee = this.get('employee');
		var variablesToCheck = ['isManagerButNotAdmin', 'ft_enddate', 'formTerminationType', 'cobraRule', 'companySettings', 'terminationSettings', 'administerCOBRA', 'category'];
		var retValue = true;

		for (var i = 0; i < variablesToCheck.length; i++) {
			var toCheck = this.get(variablesToCheck[i]);
			if (typeof(toCheck) === "undefined") {
				console.warn(variablesToCheck[i] + " is not defined");
				retValue = false;
			}
		}

		// this mixin is used with employee on controller or employee in the model
		if (typeof(employee) === 'undefined') {
			employee = this.get('model');
			if (typeof(employee) === 'undefined' || !employee) {
				console.warn("employee and/or object proxy to model is not defined");
				retValue = false;
			} else {
				if (employee.toString().indexOf('AllEmployee') === -1) {
					console.warn("object proxy to model must be an employee");
					retValue = false;
				}
			}
		}

		return retValue;
	},

	validateTermInfo: function() {
		if (!this.get('isTermDateValid')) {
			this.set('errorText', 'Provide a valid full-time end date in the format MM/DD/YYYY.');
			return false;
		}

		return true;
	},

	validateCobra: function() {
		var hasCobraOptions = this.get('hasCobraOptions');
		var canOfferSponsoredCobra = this.get('canOfferEmployerSponsoredCobra');

		if (!this.validateTermInfo()) {
			return false;
		}

		if (hasCobraOptions && this.get('administerCOBRA') == null) {
			this.set('errorText', 'Please specify if you would like us to offer and administer ' + this.get('cobraName') + ' for ' + this.get('first_name') + '.');
			return false;
		}

		if (canOfferSponsoredCobra && this.get('coverFullCobra') == null) {
			this.set('errorText', 'Please specify if you would like to cover 100% of ' + this.get('first_name') + '\'s ' + this.get('cobraName') + ' for a set period.');
			return false;
		}

		if (canOfferSponsoredCobra && this.get('coverFullCobra') && !this.get('cobraMonths')) {
			this.set('errorText', "Please specify the number of months that you would like to pay for COBRA.");
			return false;
		}

		if (!canOfferSponsoredCobra) {
			this.set('coverFullCobra', false);
			this.set('cobraMonths', null);
		}

		return true;
	}

});

/**
 * This function sets the start date and plan year end date to null if start date is not in the effectiveDateChoices list for incomplete enrollments.
 * This is to make sure that the admin has to re-select a new valid start date.
 */
window.checkIfStartDateInEffectiveDateChoices = zen.checkIfStartDateInEffectiveDateChoices = function(startDateProperty) {
		var startDate = this.get(startDateProperty),
			statusProperty = startDateProperty && startDateProperty.replace(/^(\w+\.)*startDate$/, '$1status'),
			planYearEndDateProperty = startDateProperty && startDateProperty.replace(/^(\w+\.)*startDate$/, '$1planYearEndDate'),
			startDateChoices = zen._CommuterEffectiveDateMixin.apply({}).effectiveDateChoices();

		for (var i = 0; i < startDateChoices.length; i++) {
			if (startDateChoices[i].id == startDate) {
				return true;
			}
		}


		if (this.get(statusProperty) != 'complete') {
			// Reset the startDate and plan year end date to null, if the enrollment is incomplete.
			this.set(startDateProperty, null);
			this.set(planYearEndDateProperty, null);
			this.saveAndContinue();
		}

		return false;
};

// Append a ... at the end if the passed property has more than the limitChars. The outputted
// string will have length = limitChars and meaningful string of limitChars - 3.
// Example: {{name-limited carrier limitChars=5}}
// Case1 : carrier = "Aetna", op = "Aetna"   (no quotes)
// Case2 : carrier = "Anthem", op="An..." (total characters = 5)
// Case3 : carrier = "KP", op="KP"
App.NameLimitedHelper = Ember.HTMLBars.makeBoundHelper(function(params, hash) {

	var property = params[0];
	var limitChars = hash.limitChars;
	var name = Ember.Handlebars.Utils.escapeExpression(property);
	if (limitChars <= 4 || name.length <= limitChars) {
		return new Ember.Handlebars.SafeString(name);
	}
	return new Ember.Handlebars.SafeString(name.substring(0, limitChars - 3) + '...');
});

window.afterModelThenpath = zen.afterModelThenpath = function(model, path) {
	// TODO(edward): This global store should be set in the Application initializer
	var names = path.split(".");

	var getPropertyMeta = function(modelClass, name) {
		var propertyMeta;
		modelClass.eachComputedProperty(function(propName, propMeta) {
			if (name === propName && propMeta.isRelationship) {
				propertyMeta = propMeta;
			}
		});
		return propertyMeta;
	};

	var rec = function(models) {
		if (models.get('length') === 0) {
			return;
		}

		var modelClass = models.get('firstObject').constructor;
		var name = names.shift();
		var propertyMeta = getPropertyMeta(modelClass, name);

		// Each promise here resolves to a list of models.
		var promises = [];

		if (propertyMeta.kind === 'belongsTo') {
			var ids = [];
			// sync relationship
			models.forEach(function(model) {
				ids.pushObject(model.get(name + '.id'));
			});
			ids = ids.compact();
			if (ids.length === 0) {
				return;
			}

			promises.pushObject(Ember.RSVP.all(ids.map(function(id) {
				return DS.appStore.find(propertyMeta.type, id);
			}, this)));
		} else {
			// async relationship
			models.forEach(function(model) {
				promises.pushObject(model.get(name));
			});
		}

		if (promises.length === 0) {
			return;
		}

		var ret = Ember.RSVP.all(promises).then(function(values) {
			return values.reduce(function(acc, c) {
				return acc.concat(c.compact());
			}, []);
		});

		if (names.length === 0) {
			return ret;
		}
		return ret.then(rec);
	};

	if (Ember.isArray(model)) {
		var models = model;
		return rec(models);
	}
	return rec([model]);
};

var flatMapThenpath = function(root, path) {
	var toArrayWhateverItIs = function(obj) {
			return Ember.isArray(obj) ? obj : [obj];
	};
	var names = path.split(".");
	var rec = function(models) {
		if (!models || models.get('length') == 0) {
			return null;
		}
		var name = names.shift();
		var ret = models.map(function(model) {
			return model.get(name);
		}, []);
		var promise = Ember.RSVP.all(ret).then(function(values) {
			return values.reduce(function(acc, c) {
				return acc.concat(toArrayWhateverItIs(c).compact());
			}, []);
		});

		if (names.length === 0) {
			return promise;
		}
		return promise.then(rec);
	};
	return rec(toArrayWhateverItIs(root));
};

var consoleThenpath = function(modelPromise, path) {
	return modelPromise.then(function(model) {
		return zen.afterModelThenpath(model, path);
	});
};

function emailGuessedSetups(controller, model, eligibility)
{
	var domainGuessed = false;
	model.forEach(function(employee)
	{
		if (employee.get('isTerminated') || (eligibility && !employee.get(eligibility))) {
			return;
		}
		var email = employee.get('email');
		employee.set('emailGuessed', email);
		if (!domainGuessed && email)
		{
			if (email.indexOf('gmail') == -1 &&
				email.indexOf('yahoo') == -1 &&
				email.indexOf('hotmail') == -1 &&
				email.indexOf('zenefits') == -1)
			{
				var domainArray = email.split('@');
				if (domainArray.length >= 2)
				{
					var domainDot = domainArray[1];
					controller.set('domain', domainDot);
					domainGuessed = true;
				}
			}
		}
	});
}

/* Copy emailGuessed to email */
window.setEmailToEmailGuessed = zen.setEmailToEmailGuessed = function(model, type)
{
	model.forEach(function(employee)
	{
		if (employee.get('isActive') && !employee.get('email')) {
			if ((type != 'fsa'  && type != 'hra' && type != 'hsa') ||
			(type == 'fsa'  && employee.get('isEligibleForFSA')) ||
			(type == 'hra'  && employee.get('isEligibleForHRA')) ||
			(type == 'hsa'  && employee.get('isEligibleForHSA') && employee.get('isMedicalCompleteOrApproved')))
			{
				employee.set('email', employee.get('emailGuessed'));
			}
		}
	});
};


function getReadableDateString(date) {
	var d = zen.parseAmericanDate(date);
	var monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	var readableDateString = '';
	readableDateString += d.day;
	readableDateString += " " + monthsArray[d.month - 1];
	readableDateString += ", " + d.year;

	return readableDateString;
}


window.getFirstDayOfNextMonth = zen.getFirstDayOfNextMonth = function (today) {
	if (!today) {
		var today = new Date();
	}

	if (today.getMonth() == 11) {
		return (new Date(today.getFullYear() + 1, 0, 1));
	}
	else {
		return (new Date(today.getFullYear(), today.getMonth() + 1, 1));
	}
};


function getMaxDate(d1, d2) {
	d1 = zen.parseAmericanDate(d1);
	var d1Obj = new Date(d1.year, d1.month - 1, d1.day);
	d2 = zen.parseAmericanDate(d2);
	var d2Obj = new Date(d2.year, d2.month - 1 , d2.day);

	return (d1Obj > d2Obj ? d1Obj : d2Obj);
}


window.compareDates = zen.compareDates = function(d1, d2) {
	d1 = zen.parseAmericanDate(d1);
	var d1Obj = new Date(d1.year, d1.month - 1, d1.day);
	d2 = zen.parseAmericanDate(d2);
	var d2Obj = new Date(d2.year, d2.month - 1 , d2.day);

	if (d1Obj > d2Obj) {
		return 1;
	}

	if (d1Obj < d2Obj) {
		return -1;
	}

	return 0;
};


function getNextDate(date) {
	var day;
	var month;
	var year;

	if (date.getDate() <= 10) {
		day = 15;
		month = date.getMonth();
		year = date.getFullYear();
	}
	else {
		if(date.getDate() > 10 && date.getDate() < 24) {
			day = 1;
		}
		else {
			day = 15;
		}
		if (month != 11) {
			month = date.getMonth() + 1;
			year = date.getFullYear();
		}
		else {
			month = 1;
			year = date.getFullYear() + 1;
		}
	}
	return new Date(year, month, day);
}

// Functions regarding business days.
var HOLIDAYS = {
	"2013/01/01":1, "2013/01/21":1, "2013/02/18":1, "2013/05/27":1, "2013/07/04":1, "2013/09/02":1, "2013/10/14":1,
	"2013/11/11":1, "2013/11/28":1, "2013/12/25":1, "2014/01/01":1, "2014/01/20":1, "2014/02/17":1, "2014/05/26":1,
	"2014/07/04":1, "2014/09/01":1, "2014/10/13":1, "2014/11/11":1, "2014/11/27":1, "2014/12/25":1, "2015/01/01":1,
	"2015/01/19":1, "2015/02/16":1, "2015/05/25":1, "2015/07/04":1, "2015/09/07":1, "2015/10/12":1, "2015/11/11":1,
	"2015/11/26":1, "2015/12/25":1, "2016/01/01":1, "2016/01/18":1, "2016/02/15":1, "2016/05/30":1, "2016/07/04":1,
	"2016/09/05":1, "2016/10/10":1, "2016/11/11":1, "2016/11/24":1, "2016/12/25":1, "2017/01/02":1, "2017/01/16":1,
	"2017/02/20":1, "2017/05/29":1, "2017/07/04":1, "2017/09/04":1, "2017/10/09":1, "2017/11/10":1, "2017/11/23":1,
	"2017/12/25":1, "2018/01/01":1, "2018/01/15":1, "2018/02/19":1, "2018/05/28":1, "2018/07/04":1, "2018/09/03":1,
	"2018/10/08":1, "2018/11/12":1, "2018/11/22":1, "2018/12/25":1, "2019/01/01":1, "2019/01/21":1, "2019/02/18":1,
	"2019/05/27":1, "2019/07/04":1, "2019/09/02":1, "2019/10/14":1, "2019/11/11":1, "2019/11/28":1, "2019/12/25":1,
	"2020/01/01":1, "2020/01/20":1, "2020/02/17":1, "2020/05/25":1, "2020/07/03":1, "2020/09/07":1, "2020/10/12":1,
	"2020/11/11":1, "2020/11/26":1, "2020/12/25":1,
	// Add more years/days here pls...
};

/**
 * This will add/subtract the number of business days from the given moment date. Please note that it will
 * modify the given momentDate parameter. Subtract the number of business days from the momentDate by passing
 * negative days.
 *
 * @param momentDate The given momentjs date instance. The same parameter will be added/subtracted with
 * 					 the given days and returned(passed original parameter will be modified).
 * @param 			 Positive or negative number of days.
 *
 * @return The modified momentDate object with the number of days added/removed.
 */
function addOrSubtractBusinessDays(momentDate, days) {
	var offset;
	// Replicate the positive and negative zero logic here.
	if (days >= 0) {
		offset = 1;
	}
	else {
		offset = -1;
	}
	while (true) { // eslint-disable-line no-constant-condition
		var isValidBusinessDay = isBusinessDay(momentDate);
		if (days * offset <= 0 && isValidBusinessDay) {
			return momentDate;
		}
		if (isValidBusinessDay) {
			days -= offset;
		}
		momentDate.add(offset, 'days');
	}
}

function isBusinessDay(momentDate) {
	var day = momentDate.get('day');
	// Weekend cannot be a business day
	if (day == 0 || day == 6) {
		return false;
	}
	if (momentDate.format("YYYY/MM/DD") in HOLIDAYS) {
		return false;
	}
	return true;
}

/**
 * This function converts the date passed as parameter to a YY-MM-DD format.  This function is helpful if date is to be passed
 * from javascript to python
 */
window.dateToYMD = zen.dateToYMD = function(date) {
	var d = date.getDate();
	var m = date.getMonth() + 1;
	var y = date.getFullYear();
	return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
};

function getNextDateFullMonth(date) {
	var day;
	var month;
	var year;

	month = date.getMonth();
	year = date.getFullYear();
	if(date.getDate() < 25) {
		day = 1;
	}
	else {
		// After the 24, skip another month
		day = 1;
		if (month != 11) {
			month += 1;
		}
		else {
			month = 0;
			year += 1;
		}
	}
	if (month != 11) {
		month += 1;
	}
	else {
		month = 0;
		year += 1;
	}

	return new Date(year, month, day);
}

var monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


var TaxMixin = Ember.Mixin.create({
	saveInProgress: false,
	formOptions: [
		{option: "W-4 2020 Form", id: "v2"},
		{option: "Legacy W-4 Form", id: "v1"},
	],
	isUsingV1Form: Ember.computed("formVersion", function() {
		return this.get('formVersion') === "v1";
	}),
	isUsingV2Form: Ember.computed("formVersion", function() {
		return this.get('formVersion') === "v2";
	}),
	federalFilingStatusOptions: Ember.computed('formVersion', function() {
		return zen.FEDERAL_FILING_STATUS_OPTIONS[this.get('formVersion')];
	}),
});


zen._FederalTaxRollbackRouteMixin = Ember.Mixin.create({
	actions: {
		willTransition: function() {
			this.set('controller.errorText', "");
			this.set('controller.isEditingFederalTax', false);
			if (this.get('controller.newFederalTax.isDirty')) {
				this.get('controller.newFederalTax').rollback();
			}
		}
	},
});

// needs employee to be defined in the controller where this is mixed in
zen._FederalTaxMixin = Ember.Mixin.create(TaxMixin, {
	showFicaExemption: false,
	isEditingFederalTax: false,
	newFederalTax: undefined,
	isReadyForFederalTaxFormSetup: Ember.computed.and('employee.socialSecurity', 'employee.state', 'employee.zip'),
	isCardInSetupFlow: false,
	errorText: undefined,
	federalFilingStatusErrorText: undefined,
	federalWithholdingAllowanceErrorText: undefined,
	additionalFederalWitholdingsErrorText: undefined,
	signatureErrorText: undefined,
	hasErrors: Ember.computed.or('errorText', 'federalFilingStatusErrorText', 'federalWithholdingAllowanceErrorText', 'signatureErrorText', 'additionalFederalWitholdingsErrorText'),
	clearErrorText: function() {
		this.set('errorText', undefined);
		this.set('federalFilingStatusErrorText', undefined);
		this.set('federalWithholdingAllowanceErrorText', undefined);
		this.set('additionalFederalWitholdingsErrorText', undefined);
		this.set('signatureErrorText', undefined);
	},
	finishEditing: function() {
		this.clearErrorText();
		if (!this.get('isCardInSetupFlow')) {
			this.set('isEditingFederalTax', false);
			this.set('newFederalTax', undefined);
		}
	},

	isSignatureRequired: Ember.computed('isUsingV1Form', function() {
		return !this.get('isUsingV1Form');
	}),
	showAdminMustCompleteWarning: Ember.computed("isRequesterAdmin", "isUsingV2Form", function() {
		/*
		1. Admins can only change old V1 forms
		2. Employees can only change new V2 forms (though they can select a dropdown that toggles the two)

		So if EE selects the old form in the dropdown, show a warning instead of the form
		*/
		return !this.get('isRequesterAdmin') && !this.get('isUsingV2Form');
	}),
	formVersion: Ember.computed("isEditingFederalTax", "employee.currentFederalTax.formVersion", "newFederalTax.formVersion", {
		get: function(key) {
			// show currentFederalTax if we're not editing (newFederalTax has no meaning if we're not editing since it hasn't been created yet)
			if (!this.get('isEditingFederalTax') && this.get('employee.currentFederalTax')) {
				return this.get('employee.currentFederalTax.formVersion');
			}
			if (this.get('newFederalTax.formVersion')) {
				// this is probably not needed because the newFederalTax was just created.
				return this.get('newFederalTax.formVersion');
			}
			return "v2";
		},
		set: function(key, value) {
			this.set('newFederalTax.formVersion', value);
			return value;
		}
	}),
	shouldShowBothW4FormOptions: Ember.computed("employee.hireDate", "employee.currentFederalTax.formVersion", "isEditingFederalTax", function() {
		if (this.get('requester.id') !== this.get('employee.id')){
			// admins cannot change the federal tax form only employee has the option to change it
			return false;
		}
		if (!this.get("isEditingFederalTax")) {
			return false;
		}
		if (this.get("employee.currentFederalTax") && this.get("employee.currentFederalTax.formVersion") == "v2") {
			// Employee currently have a 2020 form -> Do not allow changing it back to the old form
			return false;
		}

		// Employees with hire date after Jan 1st, 2020, cannot see the old W4 form
		return moment("2020-1-1").isAfter(
			this.get('employee.hireDate'),
			this.get('AMERICAN_DATE_FORMAT')
		);
	}),

	actions: {
		startEditingFederalTax: function() {
			this.set('isEditingFederalTax', true);

			// can't just copy currentFederalTax directly - some special logic needed
			// 1. admins can only edit v1
			// 2. EEs can only edit V2, though they can sometimes choose either (choosing V1 leads to a no-op form, see showAdminMustCompleteWarning)
			// (this could be simplified down to a single line boolean but given all the different
			// unrelated cases seemed more readable to make it a bunch of nested if's)
			var newFormVersion;
			if (this.get('requester.id') !== this.get('employee.id')) {
				// if admin, just copy the currentFederalTax value (think this should always be "v1",
				// but that's gated at the component level already, seems more future proof/easier to read to just
				// copy currentFederalTax)
				newFormVersion = this.get('employee.currentFederalTax.formVersion');
			} else {
				// otherwise, if non-admin EE, then check their form options
				if (this.get('shouldShowBothW4FormOptions')) {
					// if EE is able to do both (takes into account both rehire 2020 for onboarding and currentFederalTax for EE profile)
					// this should should also in theory always be "v1"
					newFormVersion = this.get('employee.currentFederalTax.formVersion');
				} else {
					// otherwise if they can't choose between v1 and v2, force v2
					// (this is needed to handle the rehire case of moving from a pre-2020 initial hire
					// with currentFederalTax.formVersion=V1 to a new rehire date of after 2020 of
					// newFormVersion=V2)
					newFormVersion = "v2";
				}
			}

			// copy over contents from employee.currentFederalTax to newFederalTax
			var newFederalTax = App.EmployeeFederalTax.createRecord({
				"employee": this.get('employee'),
				"isActive": false,
				"country": 'US',
				"federalFilingStatus": this.get('employee.currentFederalTax.federalFilingStatus'),
				"federalWithholdingAllowance": this.get('employee.currentFederalTax.federalWithholdingAllowance'),
				"additionalFederalWitholdings": this.get('employee.currentFederalTax.additionalFederalWitholdings'),
				"formVersion": newFormVersion,
				"hasTwoJobs": this.get('employee.currentFederalTax.hasTwoJobs'),
				"dependentsAmount": this.get('employee.currentFederalTax.dependentsAmount'),
				"otherIncomeAmount": this.get('employee.currentFederalTax.otherIncomeAmount'),
				"deductionsAmount": this.get('employee.currentFederalTax.deductionsAmount'),
			});

			this.set('newFederalTax', newFederalTax);
		},
		cancelEditingFederalTax: function() {
			return this._cancelEditingFederalTax();
		},
		saveFederalTax: function() {
			return this._saveFederalTax();
		}
	},
	_cancelEditingFederalTax: function() {
		this.clearErrorText();
		if (!this.get('isCardInSetupFlow')) {
			this.set('isEditingFederalTax', false);
		}
		if (this.get('newFederalTax.isDirty')) {
			this.get('newFederalTax').rollback();
		}
		return Ember.RSVP.resolve();
	},
	_saveFederalTax: function() {
		this.clearErrorText();

		// validate first
		if (this.get('anyErrors')) {
			this.set("errorText", 'Validation error - please fix your inputs');
			return Ember.RSVP.resolve();
		}
		var currentFederalTax = this.get('employee.currentFederalTax');

		if (this.get('isUsingV1Form')) {
			// when 2020 form is released, employees cannot fill out the form. So we need
			// to set the value to existing or default.
			this.set(
				'newFederalTax.federalWithholdingAllowance',
				this.get('newFederalTax.federalWithholdingAllowance') || 0
			);
			this.set(
				'newFederalTax.additionalFederalWitholdings',
				this.get('newFederalTax.additionalFederalWitholdings') || 0
			);
			this.set(
				'newFederalTax.federalFilingStatus',
				this.get('newFederalTax.federalFilingStatus') || "S"
			);
		}

		// set form version
		var formVersion = this.get('formVersion');
		this.set('newFederalTax.formVersion', formVersion);

		var result = this.isRequestValid(
			this.get('employee.currentFederalTax'),
			this.get('newFederalTax')
		);

		if (!result.isValid) {
			this.set("errorText", result.reason);
			return Ember.RSVP.resolve();
		}

		// No changes detected. Let it slide
		if (result.reason) {
			// rollback all changes so far
			if (this.get('newFederalTax.isDirty')) {
				this.get('newFederalTax').rollback();
			}
			if (result.reason == "FICA_EXEMPTION_CHANGE") {
				// Short circuit with employee save. No change to tax object (backend will do what is required)
				return this.get('employee').save().then(function() {
					this.finishEditing();
					return Ember.RSVP.resolve();
				}.bind(this));
			}
			this.finishEditing();
			return Ember.RSVP.resolve();
		}

		var signatureObject = this.get('newFederalTax.employeeSignature');

		// create the change object
		var federalTaxChange = App.FederalTaxChange.createRecord({
			"requestedBy": this.get('requester'),
			"oldFederalTax": this.get('employee.currentFederalTax'),
			"newFederalTax": this.get('newFederalTax'),
		});

		// create change request group
		var changeRequestGroup = App.ChangeRequestGroup.createRecord({
			"requestedBy": this.get('requester'),
			"isReadyToProcess": false,
			"applyImmediately": true,
			"type": "employee_details_change",
			"status": "created",
			"isPrimaryRequestGroup": true,
		});

		// create change request
		var changeRequest = App.ChangeRequest.createRecord({
			"employee": this.get('employee'),
			"group": changeRequestGroup,
			"federalTaxChange": federalTaxChange,
			"isMainRequest": true,
		});

		this.set('saveInProgress', true);
		var employeePromise = this.get('employee').get('isModified') ? this.get('employee').save() : Ember.RSVP.resolve();
		var signaturePromise = signatureObject ? signatureObject.save() : Ember.RSVP.resolve();
		return wrapArrayPromise([signaturePromise, employeePromise]).then(function() {
			return this.get('newFederalTax').save();
		}.bind(this)).then(function() {
			return federalTaxChange.save();
		}.bind(this)).then(function() {
			return changeRequestGroup.save();
		}.bind(this)).then(function() {
			return changeRequest.save();
		}.bind(this)).then(function() {
			changeRequestGroup.set('isReadyToProcess', true);
			return changeRequestGroup.save();
		}.bind(this)).then(function() {
			if (changeRequestGroup.get('status') == 'completed') {
				return this.get('employee').reload().then(function(employee) {
					return wrapArrayPromise(employee.get('federalTaxes').map(function(tax) { return tax.reload(); }));
				}.bind(this)).then(function() {
					this.finishEditing();
					this.set('saveInProgress', false);
					return Ember.RSVP.resolve();
				}.bind(this));
			} else if (changeRequestGroup.get('status') == 'declined') {
				this.set('errorText', "We encountered an error while processing your request. Please try again.");
				this.set('saveInProgress', false);
				return Ember.RSVP.resolve();
			}
		}.bind(this));
	},
	// validations
	// NOTE: This property should be symmetrical with isRequestValid in FederalTaxChange under approver/models.py
	// If you are modifying one, update the other one as well
	isRequestValid: function(oldTax, newTax) {
		if (this.get('isUsingV1Form')) {
			// non-null values for required fields
			if (!newTax || (!newTax.get('federalFilingStatus') && (!newTax.get('federalWithholdingAllowance') && newTax.get('federalWithholdingAllowance') !== 0))) {
				this.set('federalFilingStatusErrorText', "Please choose a federal filing status");
				this.set('federalWithholdingAllowanceErrorText', "Please select a valid value");
				return { isValid: false, reason: "Please choose valid values for filing status and withholding allowances" };
			}

			if (!newTax.get('federalFilingStatus')) {
				this.set('federalFilingStatusErrorText', "Please choose a federal filing status");
				return { isValid: false, reason: "Please choose a federal filing status" };
			}

			if (!newTax.get('federalWithholdingAllowance') && newTax.get('federalWithholdingAllowance') !== 0) {
				this.set('federalWithholdingAllowanceErrorText', "Please select a valid value");
				return { isValid: false, reason: "Please select a valid withholding allowance" };
			}

			if (newTax.get('additionalFederalWitholdings') && newTax.get('additionalFederalWitholdings') < 0) {
				this.set('additionalFederalWitholdingsErrorText', "Please enter a valid amount");
				return { isValid: false, reason: "Please enter a valid dollar amount" };
			}

			// consider fica exempt or not
			var isFicaExemptChanged = this.get('showFicaExemption') ? this.get('employee').get('isModified') : false;
			// when signature is not required, check if change is meaningful
			if (oldTax) {
				var duplicate = !newTax.get('employeeSignature') &&
								oldTax.get('formVersion') === newTax.get('formVersion') &&
								oldTax.get('federalFilingStatus') === newTax.get('federalFilingStatus') &&
								oldTax.get('federalWithholdingAllowance') === newTax.get('federalWithholdingAllowance') &&
								Number(oldTax.get('additionalFederalWitholdings')) === Number(newTax.get('additionalFederalWitholdings'));
				if (duplicate && !isFicaExemptChanged) {
					return { isValid: true, reason: "NO_CHANGES" };
				}
				else if (duplicate && isFicaExemptChanged) {
					return { isValid: true, reason: "FICA_EXEMPTION_CHANGE" };
				}
				//if duplicate is false, no matter isFicaExemptChanged is true or false, go to next step
			}
		} else if (this.get('isUsingV2Form')) {
			if (!newTax || !newTax.get('federalFilingStatus')) {
				this.set('federalFilingStatusErrorText', "Please choose a federal filing status");
				return { isValid: false, reason: "Please choose a federal filing status" };
			}

			// consider fica exempt or not
			var isFicaExemptChanged = this.get('showFicaExemption') ? this.get('employee').get('isModified') : false;
			// when signature is not required, check if change is meaningful
			if (oldTax) {
				var duplicate = !newTax.get('employeeSignature') &&
								oldTax.get('formVersion') === newTax.get('formVersion') &&
								oldTax.get('federalFilingStatus') === newTax.get('federalFilingStatus') &&
								oldTax.get('hasTwoJobs') === newTax.get('hasTwoJobs') &&
								Number(oldTax.get('dependentsAmount')) === Number(newTax.get('dependentsAmount')) &&
								Number(oldTax.get('otherIncomeAmount')) === Number(newTax.get('otherIncomeAmount')) &&
								Number(oldTax.get('deductionsAmount')) === Number(newTax.get('deductionsAmount')) &&
								Number(oldTax.get('additionalFederalWitholdings')) === Number(newTax.get('additionalFederalWitholdings'));
				if (duplicate && !isFicaExemptChanged) {
					return { isValid: true, reason: "NO_CHANGES" };
				}
				else if (duplicate && isFicaExemptChanged) {
					return { isValid: true, reason: "FICA_EXEMPTION_CHANGE" };
				}
				//if duplicate is false, no matter isFicaExemptChanged is true or false, go to next step
			}

			// non-null signature when required (based on creator)
			if (this.get('requester.id') == newTax.get('employee.id')) {
				if (!newTax.get('employeeSignature')) {
					this.set('signatureErrorText', "Please provide your signature");
					return { isValid: false, reason: "Please provide your signature to confirm changes" };
				}
			}
		} else {
			return { isValid: false, reason: "Invalid Form Version" };
		}

		return { isValid: true, reason: null };
	},
	isFederalWithholdingAllowanceTooHigh: function() {
		var allowance = this.get('newFederalTax.federalWithholdingAllowance');
		if (allowance == null || allowance === "") {
			return false;
		}
		return Number(allowance) > 12;
	}.property('newFederalTax.federalWithholdingAllowance'),
	federalWithholdingAllowanceMax:  99,
	federalWithholdingAllowanceMin:  0,
	standardFederalWithholdingAllowances: function() {
		var ret = [];
		//TODO replace with lodash or better solution
		for(var i = this.get('federalWithholdingAllowanceMin'); i <= this.get('federalWithholdingAllowanceMax'); i++)
		{
			ret.push({"id": i, "name": i.toString()});
		}
		return ret;
	}.property('federalWithholdingAllowanceMax','federalWithholdingAllowanceMin'),
	federalWithholdingPrompt: "Select Withholding Allowances",
	federalFilingPrompt: function() {
		if (this.get('newFederalTax.federalFilingStatus')) {
			return null;
		}
		else {
			return "Select Filing Status";
		}
	}.property('newFederalTax.federalFilingStatus'),
});

zen._StateTaxRollbackRouteMixin = Ember.Mixin.create({
	actions: {
		willTransition: function() {
			this.set('controller.errorText', "");
			this.set('controller.isEditingWorkStateTax', false);
			this.set('controller.isEditingResidenceStateTax', false);
			if (this.get('controller.newWorkStateTax.isDirty')) {
				this.get('controller.newWorkStateTax').rollback();
			}
			if (this.get('controller.newResidenceStateTax.isDirty')) {
				this.get('controller.newResidenceStateTax').rollback();
			}
		}
	},
});

var SMS_DEVICE_CONSTANT = 'SMS Device';

zen._TwoFactorAuthenticationMixin = Ember.Mixin.create({
	twoFactorDeviceType: Ember.computed.alias('model.twoFactorDeviceType'),
	displayTwoFactorOption: Ember.computed.or('model.twoFactorOption', 'twoFactorDeviceType.name'),
	isSmstwoFactorDevice: function(){
		return this.get('twoFactorDeviceType') != null && this.get('twoFactorDeviceType.name') == SMS_DEVICE_CONSTANT;
	}.property('twoFactorDeviceType','twoFactorDeviceType.name'),
	reset2FactorDevice: function(employee) {
		var data = {
			employee: employee,
			parentModel: this.get('model'),
		};

		this.send('showModal', 'account.twofactor.modals.resetdevice', data);
	},
});
zen._StateTaxMixin = Ember.Mixin.create(TaxMixin, {
	isEditingWorkStateTax: false,
	isEditingResidenceStateTax: false,
	newWorkStateTax: undefined,
	newResidenceStateTax: undefined,
	isReadyForStateTaxFormSetup: Ember.computed.and('employee.socialSecurity', 'employee.state', 'employee.zip'),
	stateHasNoIncomeTax: Ember.computed.not('doesStateHaveIncomeTax'),
	residenceStateHasNoIncomeTax: Ember.computed.not('doesResidenceStateHaveIncomeTax'),
	isReadOnlyWorkStateTax: Ember.computed.or('taxStateMisMatchesWorkLocation', 'isWorkStatePAAndResidentStateNotReciprocal', 'stateHasNoIncomeTax'),
	isReadOnlyResidenceStateTax: Ember.computed.or('residenceTaxStateMismatchesState', 'employeeResidenceStatePA', 'residenceStateHasNoIncomeTax'),
	noWorkStateMAstatePersonalBlindness: Ember.computed.empty('employee.currentWorkStateTax.MAstatePersonalBlindness'),
	noWorkStateMAstateSpouseBlindness: Ember.computed.empty('employee.currentWorkStateTax.MAstateSpouseBlindness'),
	noWorkStateMAstateFullTimeStudent: Ember.computed.empty('employee.currentWorkStateTax.MAstateFullTimeStudent'),
	noResidenceStateMAstatePersonalBlindness: Ember.computed.empty('employee.currentResidenceStateTax.MAstatePersonalBlindness'),
	noResidenceStateMAstateSpouseBlindness: Ember.computed.empty('employee.currentResidenceStateTax.MAstateSpouseBlindness'),
	noResidenceStateMAstateFullTimeStudent: Ember.computed.empty('employee.currentResidenceStateTax.MAstateFullTimeStudent'),
	noHasCertOfNonResidenceForTaxState: Ember.computed.empty('employee.hasCertOfNonResidenceForTaxState'),
	noEmployeeCurrentResidenceStateTaxLAstateNumberOfDependents: Ember.computed.empty('employee.currentResidenceStateTax.LAstateNumberOfDependents'),
	noEmployeeCurrentWorkStateTaxLAstateNumberOfDependents: Ember.computed.empty('employee.currentWorkStateTax.LAstateNumberOfDependents'),
	noEmployeeCurrentResidenceStateTaxGAstatePersonalAllowance: Ember.computed.empty('employee.currentResidenceStateTax.GAstatePersonalAllowance'),
	noEmployeeCurrentResidenceStateTaxGAstateDependentAllowance: Ember.computed.empty('employee.currentResidenceStateTax.GAstateDependentAllowance'),
	noEmployeeCurrentWorkStateTaxGAstatePersonalAllowance: Ember.computed.empty('employee.currentWorkStateTax.GAstatePersonalAllowance'),
	noEmployeeCurrentWorkStateTaxGAstateDependentAllowance: Ember.computed.empty('employee.currentWorkStateTax.GAstateDependentAllowance'),
	noEmployeeCurrentResidenceStateTaxILstateAdditionalAllowance: Ember.computed.empty('employee.currentResidenceStateTax.ILstateAdditionalAllowance'),
	noEmployeeCurrentWorkStateTaxILstateAdditionalAllowance: Ember.computed.empty('employee.currentWorkStateTax.ILstateAdditionalAllowance'),
	noEmployeeCurrentResidenceStateTaxINstateAdditionalDependentExemptions: Ember.computed.empty('employee.currentResidenceStateTax.INstateAdditionalDependentExemptions'),
	noEmployeeCurrentResidenceStateTaxINstateAdditionalDependentExemptionsFirstClaim: Ember.computed.empty('employee.currentResidenceStateTax.INstateAdditionalDependentExemptionsFirstClaim'),
	noEmployeeCurrentResidenceStateTaxINstateAdditionalCountyWithholdings: Ember.computed.empty('employee.currentResidenceStateTax.INstateAdditionalCountyWithholdings'),
	noEmployeeCurrentWorkStateTaxINstateAdditionalDependentExemptions: Ember.computed.empty('employee.currentWorkStateTax.INstateAdditionalDependentExemptions'),
	noEmployeeCurrentWorkStateTaxINstateAdditionalDependentExemptionsFirstClaim: Ember.computed.empty('employee.currentWorkStateTax.INstateAdditionalDependentExemptionsFirstClaim'),
	noEmployeeCurrentWorkStateTaxINstateAdditionalCountyWithholdings: Ember.computed.empty('employee.currentWorkStateTax.INstateAdditionalCountyWithholdings'),
	noEmployeeCurrentResidenceStateTaxLocalWithholdingAllowance: Ember.computed.empty('employee.currentResidenceStateTax.localWithholdingAllowance'),
	noEmployeeCurrentWorkStateTaxLocalWithholdingAllowance: Ember.computed.empty('employee.currentWorkStateTax.localWithholdingAllowance'),
	noEmployeeCurrentWorkStateTaxAdditionalLocalWithholdings: Ember.computed.empty('employee.currentWorkStateTax.additionalLocalWithholdings'),
	noEmployeeCurrentResidenceStateTaxAdditionalLocalWithholdings: Ember.computed.empty('employee.currentResidenceStateTax.additionalLocalWithholdings'),
	noEmployeeCurrentResidenceStateTaxVAstateAgeAndBlindnessExemptions: Ember.computed.empty('employee.currentResidenceStateTax.VAstateAgeAndBlindnessExemptions'),
	noEmployeeCurrentWorkStateTaxVAstateAgeAndBlindnessExemptions: Ember.computed.empty('employee.currentWorkStateTax.VAstateAgeAndBlindnessExemptions'),
	noEmployeeCurrentWorkStateTaxAdditionalStateWitholdings: Ember.computed.empty('employee.currentWorkStateTax.additionalStateWitholdings'),
	noEmployeeCurrentResidenceStateTaxAdditionalStateWitholdings: Ember.computed.empty('employee.currentResidenceStateTax.additionalStateWitholdings'),
	noEmployeeCurrentResidenceStateTaxMTstateReducedWithholdings: Ember.computed.empty('employee.currentResidenceStateTax.MTstateReducedWithholdings'),
	noEmployeeCurrentWorkStateTaxMTstateReducedWithholdings: Ember.computed.empty('employee.currentWorkStateTax.MTstateReducedWithholdings'),

	isCardInSetupFlow: false,
	errorText: undefined,
	hasCertOfNonResidenceForTaxStateErrorText: undefined,
	stateFilingStatusErrorText: undefined,
	stateWithholdingAllowanceErrorText: undefined,
	additionalStateWitholdingsErrorText: undefined,
	localWithholdingAllowanceErrorText: undefined,
	GAstatePersonalAllowanceErrorText: undefined,
	GAstateDependentAllowanceErrorText: undefined,
	ILstateAdditionalAllowanceErrorText: undefined,
	LAstateNumberOfDependentsErrorText: undefined,
	MAstatePersonalBlindnessErrorText: undefined,
	MAstateSpouseBlindnessErrorText: undefined,
	MAstateFullTimeStudentErrorText: undefined,
	INstateAdditionalDependentExemptionsErrorText: undefined,
	INstateAdditionalDependentExemptionsFirstClaimErrorText: undefined,
	INstateAdditionalCountyWithholdingsErrorText: undefined,
	MOstateSpouseWorksErrorText: undefined,
	VAstateAgeAndBlindnessExemptionsErrorText: undefined,
	WVstateOneEarnerWithholdAtLowerRateErrorText: undefined,
	MTstateReducedWithholdingsErrorText: undefined,
	stateTotalAllowanceValueErrorText: undefined,
	signatureErrorText: undefined,
	hasErrors: Ember.computed.or('errorText',
		'hasCertOfNonResidenceForTaxStateErrorText',
		'stateFilingStatusErrorText',
		'stateWithholdingAllowanceErrorText',
		'localWithholdingAllowanceErrorText',
		'GAstatePersonalAllowanceErrorText',
		'GAstateDependentAllowanceErrorText',
		'ILstateAdditionalAllowanceErrorText',
		'LAstateNumberOfDependentsErrorText',
		'MAstatePersonalBlindnessErrorText',
		'MAstateSpouseBlindnessErrorText',
		'MAstateFullTimeStudentErrorText',
		'INstateAdditionalDependentExemptionsErrorText',
		'INstateAdditionalDependentExemptionsFirstClaimErrorText',
		'INstateAdditionalCountyWithholdingsErrorText',
		'MOstateSpouseWorksErrorText',
		'VAstateAgeAndBlindnessExemptionsErrorText',
		'WVstateOneEarnerWithholdAtLowerRateErrorText',
		'MTstateReducedWithholdingsErrorText',
		'stateTotalAllowanceValueErrorText',
		'signatureErrorText'),
	clearErrorText: function() {
		this.set('errorText', undefined);
		this.set('hasCertOfNonResidenceForTaxStateErrorText', undefined);
		this.set('stateFilingStatusErrorText', undefined);
		this.set('stateWithholdingAllowanceErrorText', undefined);
		this.set('localWithholdingAllowanceErrorText', undefined);
		this.set('GAstatePersonalAllowanceErrorText', undefined);
		this.set('GAstateDependentAllowanceErrorText', undefined);
		this.set('ILstateAdditionalAllowanceErrorText', undefined);
		this.set('LAstateNumberOfDependentsErrorText', undefined);
		this.set('MAstatePersonalBlindnessErrorText', undefined);
		this.set('MAstateSpouseBlindnessErrorText', undefined);
		this.set('MAstateFullTimeStudentErrorText', undefined);
		this.set('INstateAdditionalDependentExemptionsErrorText', undefined);
		this.set('INstateAdditionalDependentExemptionsFirstClaimErrorText', undefined);
		this.set('INstateAdditionalCountyWithholdingsErrorText', undefined);
		this.set('MOstateSpouseWorksErrorText', undefined);
		this.set('VAstateAgeAndBlindnessExemptionsErrorText', undefined);
		this.set('WVstateOneEarnerWithholdAtLowerRateErrorText', undefined);
		this.set('MTstateReducedWithholdingsErrorText', undefined);
		this.set('stateTotalAllowanceValueErrorText', undefined);
		this.set('signatureErrorText', undefined);
		this.set('PRveteranExemptionErrorText', undefined);
		this.set('PRcompleteDependentsErrorText', undefined);
		this.set('PRjointDependentsErrorText', undefined);
		this.set('electionPercentageErrorText', undefined);
		this.set('electionValueErrorText', undefined);
		this.set('personalExemptionTypeErrorText', undefined);
		this.set('personalExemptionAmountErrorText', undefined);
	},
	finishEditing: function(isWorkState) {
		this.clearErrorText();
		if(isWorkState) {
			if (!this.get('isCardInSetupFlow')) {
				this.set('newWorkStateTax', undefined);
				this.set('isEditingWorkStateTax', false);
			}
		}
		else {
			if (!this.get('isCardInSetupFlow')) {
				this.set('newResidenceStateTax', undefined);
				this.set('isEditingResidenceStateTax', false);
			}
		}
	},

	// 2020 PR
	shouldUseNewPRForm: Ember.computed("switches", "currentStateTax.taxState", function() {
		return this.get('switches.w4_pr_2020') && this.get('currentStateTax.taxState') === "PR";
	}),
	prCheckBoxIsDisabled: Ember.computed.not("isEditingStateTax"),
	activeStateTax: Ember.computed("isEditingStateTax", "newStateTaxForm", "currentStateTax", function() {
		return this.get('isEditingStateTax') ? this.get('newStateTaxForm') : this.get('currentStateTax');
	}),
	prShowTaxes: Ember.computed("isWorkState", "showStateTaxes", "showResidenceStateTaxes", function() {
		return this.get('isWorkState') ? this.get('showStateTaxes') : this.get('showResidenceStateTaxes');
	}),
	isEditingStateTax: Ember.computed("isWorkState", "isEditingWorkStateTax", "isEditingResidenceStateTax", function() {
		return this.get('isWorkState') ? this.get('isEditingWorkStateTax') : this.get('isEditingResidenceStateTax');
	}),
	electionAmountType: Ember.computed('newStateTaxForm.additionalStateWitholdings', function() {
		return this.get('newStateTaxForm.additionalStateWitholdings') ? "total" : "percentage";
	}),
	personalExemptionType: Ember.computed('newStateTaxForm.PRpersonalExemption', function() {
		var PRpersonalExemption = this.get('newStateTaxForm.PRpersonalExemption');
		if (!PRpersonalExemption) {
			return null;
		}
		return {
			'SingleComplete': "single",
			'MarriedComplete': "married",
			'MarriedHalf': "married",
			'SingleNone': "single",
			'MarriedNone': "married",
		}[PRpersonalExemption];
	}),
	personalExemptionAmount: Ember.computed('newStateTaxForm.PRpersonalExemption', function() {
		var PRpersonalExemption = this.get('newStateTaxForm.PRpersonalExemption');
		if (!PRpersonalExemption) {
			return null;
		}
		return {
			'SingleComplete': "complete",
			'MarriedComplete': "complete",
			'MarriedHalf': "half",
			'SingleNone': "none",
			'MarriedNone': "none",
		}[PRpersonalExemption];
	}),
	personalExemptionTypeOptions: [
		Ember.Object.create({id: "single", name: "Individual Taxpayer"}),
		Ember.Object.create({id: "married", name: "Married Person"}),
	],
	personalExemptionAmountOptions: Ember.computed('personalExemptionType', function() {
		var options = [
			Ember.Object.create({id: "complete", name: "Complete (less withholding)"}),
		];
		if (this.get('personalExemptionType') == 'married') {
			options.push(Ember.Object.create({id: "half", name: "Half"}));
		}
		options.push(Ember.Object.create({id: "none", name: "None (more withholding)"}));

		return options;
	}),
	veteranExemptionAmountOptions: [
		Ember.Object.create({id: "VeteranComplete", name: "Complete (less withholding)"}),
		Ember.Object.create({id: "VeteranNone", name: "None (more withholding)"}),
	],
	electionAmountTypeOptions: [
		Ember.Object.create({id: "percentage", name: "Percentage of wages"}),
		Ember.Object.create({id: "total", name: "Total amount"}),
	],
	PRpersonalExemption: Ember.computed('personalExemptionType', 'personalExemptionAmount', function() {
		var mapping = {
			'single-complete': 'SingleComplete',
			'married-complete': 'MarriedComplete',
			'married-half': 'MarriedHalf',
			'single-none': 'SingleNone',
			'married-none': 'MarriedNone',
		};
		return mapping[this.get('personalExemptionType') + '-' + this.get('personalExemptionAmount')];
	}),
	// EO 2020 PR

	showSignatureBox: Ember.computed('isRequesterAdmin', 'isWorkState', 'isEditingWorkStateTax', 'isEditingResidenceStateTax', function() {
		if (this.get('isWorkState') && !this.get('isEditingWorkStateTax')) {
			// work read mode
			return true;
		}

		if (!this.get('isWorkState') && !this.get('isEditingResidenceStateTax')) {
			// residence read mode
			return true;
		}

		// edit mode
		return !this.get('isRequesterAdmin');
	}),

	isSignatureRequired: Ember.computed('isUsingV1Form', 'shouldUseV2FederalTaxFrom', function() {
		if (this.get('shouldUseV2FederalTaxFrom') && this.get('isUsingV1Form')) {
			return false;
		}
		return true;
	}),
	currentStateTax: Ember.computed('isWorkState', 'employee.currentWorkStateTax', 'employee.currentResidenceStateTax', function() {
		if (this.get('isWorkState')) {
			return this.get('employee.currentWorkStateTax');
		}
		return this.get('employee.currentResidenceStateTax');
	}),
	newStateTaxForm: Ember.computed('isWorkState', 'newWorkStateTax', 'newResidenceStateTax', function() {
		if (this.get('isWorkState')) {
			return this.get('newWorkStateTax');
		}
		return this.get('newResidenceStateTax');
	}),
	currentEmployeeState: Ember.computed('isWorkState', 'employee.workState', 'employee.residenceState', function() {
		if (this.get('isWorkState')) {
			return this.get('employee.workState');
		}
		return this.get('employee.residenceState');
	}),
	showV1Rows: Ember.computed("isRequesterAdmin", "isUsingV1Form", "isUsingV2Form", "shouldUseV2FederalTaxFrom", "isEditingWorkStateTax", "isEditingResidenceStateTax", "isWorkState", function(){
		if (this.get('isRequesterAdmin') || this.get('isUsingV2Form') || (this.get('isUsingV1Form') && !this.get('shouldUseV2FederalTaxFrom'))) {
			return true;
		}

		if (this.get('isWorkState') && !this.get('isEditingWorkStateTax')) {
			return true;
		}

		if (!this.get('isWorkState') && !this.get('isEditingResidenceStateTax')) {
			return true;
		}

		return false;
	}),
	shouldUseV2FederalTaxFrom: Ember.computed('currentEmployeeState', function() {
		return ['CO', 'ND', 'NM'].indexOf(this.get('currentEmployeeState')) > -1;
	}),
	formVersion: Ember.computed("isEditingResidenceStateTax", "isEditingWorkStateTax", "currentStateTax.formVersion", "newStateTaxForm.formVersion", "shouldUseV2FederalTaxFrom", {
		get: function(key) {
			// show currentStateTax if we're not editing (newStateTaxForm has no meaning if we're not editing since it hasn't been created yet)
			if (!this.get('isEditingResidenceStateTax') && !this.get('isEditingWorkStateTax') && this.get('currentStateTax')) {
				return this.get('currentStateTax.formVersion');
			}
			if (this.get('newStateTaxForm.formVersion')) {
				// this is probably not needed because the newStateTax was just created.
				return this.get('newStateTaxForm.formVersion');
			}
			if (this.get('shouldUseV2FederalTaxFrom')) {
				return "v2";
			}
			return "v1";
		},
		set: function(key, value) {
			this.set('newStateTaxForm.formVersion', value);
			return value;
		}
	}),
	shouldShowBothW4FormOptions: Ember.computed("employee.hireDate", "currentStateTax", "currentStateTax.formVersion", "currentStateTax.employeeSignature", "isEditingWorkStateTax", "isEditingResidenceStateTax", "isWorkState", "isCardInSetupFlow", function() {
		if (this.get('requester.id') !== this.get('employee.id')){
			// admins cannot change the state tax form only employee has the option to change it
			return false;
		}
		if (this.get('isWorkState')) {
			if (!this.get('isEditingWorkStateTax')) {
				return false;
			}
		} else {
			if (!this.get('isEditingResidenceStateTax')) {
				return false;
			}
		}
		if (this.get("isCardInSetupFlow") && this.get("currentStateTax") && !this.get("currentStateTax.employeeSignature")) {
			// first time during onboarding
			return moment("2020-1-1").isAfter(
				this.get('employee.hireDate'),
				this.get('AMERICAN_DATE_FORMAT')
			);
		}

		if (this.get("currentStateTax") && this.get("currentStateTax.formVersion") == "v2") {
			// Employee currently have a 2020 form -> Do not allow changing it back to the old form
			return false;
		}

		// Employees with hire date after Jan 1st, 2020, cannot see the old W4 form
		return moment("2020-1-1").isAfter(
			this.get('employee.hireDate'),
			this.get('AMERICAN_DATE_FORMAT')
		);
	}),

	copyStateTax: function(isWorkState) {
		var currentStateTax;
		var workOrResidenceState;
		if(isWorkState) {
			currentStateTax = this.get('employee.currentWorkStateTax');
			workOrResidenceState = this.get('employee.workState');
		}
		else {
			currentStateTax = this.get('employee.currentResidenceStateTax');
			workOrResidenceState = this.get('employee.residenceState');
		}

		var newStateTax = App.EmployeeStateTax.createRecord({
			"employee": this.get('employee'),
			"isWorkState": isWorkState,
			"isActive": false,
			"country": 'US',
			"taxState": workOrResidenceState,
		});
		if (currentStateTax) {
			newStateTax.set('stateFilingStatus', currentStateTax.get('stateFilingStatus'));
			newStateTax.set('stateWithholdingAllowance', currentStateTax.get('stateWithholdingAllowance'));
			newStateTax.set('additionalStateWitholdings', currentStateTax.get('additionalStateWitholdings'));
			newStateTax.set('localWithholdingAllowance', currentStateTax.get('localWithholdingAllowance'));
			newStateTax.set('additionalLocalWithholdings', currentStateTax.get('additionalLocalWithholdings'));
			newStateTax.set('GAstatePersonalAllowance', currentStateTax.get('GAstatePersonalAllowance'));
			newStateTax.set('GAstateDependentAllowance', currentStateTax.get('GAstateDependentAllowance'));
			newStateTax.set('ILstateAdditionalAllowance', currentStateTax.get('ILstateAdditionalAllowance'));
			newStateTax.set('INstateAdditionalDependentExemptions', currentStateTax.get('INstateAdditionalDependentExemptions'));
			newStateTax.set('INstateAdditionalDependentExemptionsFirstClaim', currentStateTax.get('INstateAdditionalDependentExemptionsFirstClaim'));
			newStateTax.set('INstateAdditionalCountyWithholdings', currentStateTax.get('INstateAdditionalCountyWithholdings'));
			newStateTax.set('LAstateNumberOfDependents', currentStateTax.get('LAstateNumberOfDependents'));
			newStateTax.set('MAstatePersonalBlindness', currentStateTax.get('MAstatePersonalBlindness'));
			newStateTax.set('MAstateSpouseBlindness', currentStateTax.get('MAstateSpouseBlindness'));
			newStateTax.set('MAstateFullTimeStudent', currentStateTax.get('MAstateFullTimeStudent'));
			newStateTax.set('MTstateReducedWithholdings', currentStateTax.get('MTstateReducedWithholdings'));
			newStateTax.set('NJstateWageChartLetter', currentStateTax.get('NJstateWageChartLetter'));
			newStateTax.set('VAstateAgeAndBlindnessExemptions', currentStateTax.get('VAstateAgeAndBlindnessExemptions'));
			newStateTax.set('WVstateOneEarnerWithholdAtLowerRate', currentStateTax.get('WVstateOneEarnerWithholdAtLowerRate'));
			newStateTax.set('MOstateSpouseWorks', false); // Value of 'MOStateSpouseWorks' field captured in 'stateFilingStatus' going forward
			newStateTax.set('ORmetroOptInAmount', currentStateTax.get('ORmetroOptInAmount'));
			newStateTax.set('ORmultnomahOptInAmount', currentStateTax.get('ORmultnomahOptInAmount'));
			newStateTax.set('stateTotalAllowanceValue', currentStateTax.get('stateTotalAllowanceValue'));

			// see startEditingFederalTax for comments about formVersion (outer logic is the same)
			if (this.get('requester.id') !== this.get('employee.id')) {
				newStateTax.set('formVersion', currentStateTax.get('formVersion'));
			} else {
				if (this.get('shouldShowBothW4FormOptions')) {
					newStateTax.set('formVersion', currentStateTax.get('formVersion'));
				} else {
					if (this.get('shouldUseV2FederalTaxFrom')) {
						// this is the only difference with startEditingFederalTax
						// only auto-upgrade to v2 if shouldUseV2FederalTaxFrom
						newStateTax.set('formVersion', "v2");
					} else {
						newStateTax.set('formVersion', currentStateTax.get('formVersion'));
					}
				}
			}

			newStateTax.set('hasTwoJobs', currentStateTax.get('hasTwoJobs'));
			newStateTax.set('dependentsAmount', currentStateTax.get('dependentsAmount'));
			newStateTax.set('otherIncomeAmount', currentStateTax.get('otherIncomeAmount'));
			newStateTax.set('deductionsAmount', currentStateTax.get('deductionsAmount'));
			newStateTax.set('PRmarried', currentStateTax.get('PRmarried'));
			newStateTax.set('PRyouthExemption', currentStateTax.get('PRyouthExemption'));
			newStateTax.set('PRpersonalExemption', currentStateTax.get('PRpersonalExemption'));
			newStateTax.set('PRveteranExemption', currentStateTax.get('PRveteranExemption'));
			newStateTax.set('PRcompleteDependents', currentStateTax.get('PRcompleteDependents'));
			newStateTax.set('PRjointDependents', currentStateTax.get('PRjointDependents'));
			newStateTax.set('PRallowance', currentStateTax.get('PRallowance'));
			newStateTax.set('PRadditionalWithholdingPercentage', currentStateTax.get('PRadditionalWithholdingPercentage'));
		}
		if(isWorkState) {
			this.set('newWorkStateTax', newStateTax);
		}
		else {
			this.set('newResidenceStateTax', newStateTax);
		}
	},
	_cancelEditingStateTax: function(isWorkState) {
		this.clearErrorText();
		if (isWorkState) {
			if (!this.get('isCardInSetupFlow')) {
				this.set('isEditingWorkStateTax', false);
			}
			if (this.get('newWorkStateTax.isDirty')) {
				this.get('newWorkStateTax').rollback();
			}
		}
		else {
			if (!this.get('isCardInSetupFlow')) {
				this.set('isEditingResidenceStateTax', false);
			}
			if (this.get('newResidenceStateTax.isDirty')) {
				this.get('newResidenceStateTax').rollback();
			}
		}
		return Ember.RSVP.resolve();
	},
	saveStateTax: function(isWorkState) {
		var currentStateTax;
		var stateTax;
		this.clearErrorText();
		if (isWorkState) {
			currentStateTax = this.get('employee.currentWorkStateTax');
			stateTax = this.get('newWorkStateTax');
		}
		else {
			currentStateTax = this.get('employee.currentResidenceStateTax');
			stateTax = this.get('newResidenceStateTax');
		}
		if (this.get('anyErrors')) {
			this.set("errorText", 'Validation error - please fix your inputs');
			return Ember.RSVP.resolve();
		}

		if (this.get('shouldUseV2FederalTaxFrom') && this.get('isUsingV1Form')) {
			// when 2020 form is released, employees cannot fill out the form. So we need
			// to set the value to existing or default.
			stateTax.set(
				'stateWithholdingAllowance',
				stateTax.get('stateWithholdingAllowance') || 0
			);
			stateTax.set(
				'additionalStateWitholdings',
				stateTax.get('additionalStateWitholdings') || 0
			);
			stateTax.set(
				'stateFilingStatus',
				stateTax.get('stateFilingStatus') || "S"
			);
		}

		if (this.get('shouldUseNewPRForm')) {
			// clean up fields based on radio and checkbox selections
			if (this.get('electionAmountType') === "percentage") {
				stateTax.set('additionalStateWitholdings', 0);
			} else {
				stateTax.set('PRadditionalWithholdingPercentage', 0);
			}

			if (this.get('personalExemptionType') === "single" && this.get('personalExemptionAmount') === "half") {
				this.set('personalExemptionAmount', null);
			}

			if (this.get('personalExemptionType') && this.get('personalExemptionAmount')) {
				stateTax.set('PRpersonalExemption', this.get('PRpersonalExemption'));
			}
		}

		// set form version
		var formVersion = this.get('formVersion');
		stateTax.set('formVersion', formVersion);

		var result = this.isStateTaxRequestValid(currentStateTax, stateTax);
		if (!result.isValid) {
			this.set("errorText", result.reason);
			return Ember.RSVP.resolve();
		}
		if (result.reason) {
			if (isWorkState && this.get('showCertOfNonResidenceForTaxState') && !(this.get('employee.hasCertOfNonResidenceForTaxState') === null || this.get('employee.hasCertOfNonResidenceForTaxState') === undefined)) {
				// Short circuit with employee save. No change to tax object (backend will do what is required)
				return this.get('employee').save().then(function() {
					if (this.get('newWorkStateTax.isDirty')) {
						this.get('newWorkStateTax').rollback();
					}
					this.finishEditing(isWorkState);
					return Ember.RSVP.resolve();
				}.bind(this));
			}
			else {
				// No changes detected. Let it slide
				// Rollback all changes so far
				if (isWorkState) {
					if (this.get('newWorkStateTax.isDirty')) {
						this.get('newWorkStateTax').rollback();
					}
				}
				else {
					if (this.get('newResidenceStateTax.isDirty')) {
						this.get('newResidenceStateTax').rollback();
					}
				}
				this.finishEditing(isWorkState);
				return Ember.RSVP.resolve();
			}
		}
		var signatureObject = stateTax.get('employeeSignature');

		// create the change object
		var stateTaxChange = App.StateTaxChange.createRecord({
			"requestedBy": this.get('requester'),
			"oldStateTax": currentStateTax,
			"newStateTax": stateTax,
		});

		// create change request group
		var changeRequestGroup = App.ChangeRequestGroup.createRecord({
			"requestedBy": this.get('requester'),
			"isReadyToProcess": false,
			"applyImmediately": true,
			"type": "employee_details_change",
			"status": "created",
			"isPrimaryRequestGroup": true,
		});

		// create change request
		var changeRequest = App.ChangeRequest.createRecord({
			"employee": this.get('employee'),
			"group": changeRequestGroup,
			"stateTaxChange": stateTaxChange,
			"isMainRequest": true,
		});

		this.set('saveInProgress', true);
		var employeePromise = this.get('employee').get('isModified') ? this.get('employee').save() : Ember.RSVP.resolve();
		var signaturePromise = signatureObject ? signatureObject.save() : Ember.RSVP.resolve();

		return wrapArrayPromise([signaturePromise, employeePromise]).then(function() {
			return stateTax.save();
		}.bind(this)).then(function() {
			return stateTaxChange.save();
		}.bind(this)).then(function() {
			return changeRequestGroup.save();
		}.bind(this)).then(function() {
			return changeRequest.save();
		}.bind(this)).then(function() {
			changeRequestGroup.set('isReadyToProcess', true);
			return changeRequestGroup.save();
		}.bind(this)).then(function() {
			if (changeRequestGroup.get('status') == 'completed') {
				return this.get('employee').reload().then(function(employee) {
					return wrapArrayPromise(employee.get('stateTaxes').map(function(tax) {
						if (isWorkState && tax.get('isWorkState')) {
							return tax.reload();
						}
						if (!isWorkState && !tax.get('isWorkState')) {
							return tax.reload();
						}
					}));
				}.bind(this)).then(function() {
					this.finishEditing(isWorkState);
					this.set('saveInProgress', false);
					return Ember.RSVP.resolve();
				}.bind(this));
			} else if (changeRequestGroup.get('status') == 'declined') {
				this.set('errorText', "We encountered an error while processing your request. Please try again.");
				this.set('saveInProgress', false);
				return Ember.RSVP.resolve();
			}
		}.bind(this));
	},
	actions: {
		startEditingWorkStateTax: function() {
			this.set('isEditingWorkStateTax', true);
			this.copyStateTax(true);
		},
		cancelEditingWorkStateTax: function() {
			return this._cancelEditingStateTax(true);
		},
		saveWorkStateTax: function() {
			return this.saveStateTax(true);
		},
		startEditingResidenceStateTax: function() {
			this.set('isEditingResidenceStateTax', true);
			this.copyStateTax(false);
		},
		cancelEditingResidenceStateTax: function() {
			return this._cancelEditingStateTax(false);
		},
		saveResidenceStateTax: function() {
			return this.saveStateTax(false);
		},
	},

	isStateTaxRequestValid: function(oldTax, newTax) {
		if (this.get('isUsingV1Form')) {
			// non-null values for required fields
			if (!newTax) {
				return { isValid: false, reason: "Please enter valid values for State Filing Status and Withholding Allowance" };
			}

			// TODO Add validations here
			if (newTax.get('isWorkState') && this.get('showCertOfNonResidenceForTaxState')) {
				if (newTax.get('employee.hasCertOfNonResidenceForTaxState') === null || newTax.get('employee.hasCertOfNonResidenceForTaxState') === undefined) {
					return { isValid: false, reason: "Please complete all required fields." };
				} else if (newTax.get('employee.hasCertOfNonResidenceForTaxState')) {
					return { isValid: true, reason: "Has certificate of non-residence." };
				}
			}

			if ((newTax.get('isWorkState') && (this.get('stateHasNoIncomeTax') || this.get('employeeWorkStatePA'))) ||
				(!newTax.get('isWorkState') && (this.get('residenceStateHasNoIncomeTax') || this.get('employeeResidenceStatePA')))) {
				return { isValid: true, reason: "State that don't require tax info." };
			}

			if (this.get('shouldUseNewPRForm')) {
				var hasErrors = false;
				if (!this.get('personalExemptionType')) {
					this.set('personalExemptionTypeErrorText', "Required");
					hasErrors = true;
				}

				if (!this.get('personalExemptionAmount')) {
					this.set('personalExemptionAmountErrorText', "Required");
					hasErrors = true;
				}

				if (hasErrors) {
					return { isValid: false, reason: "Please complete all required fields" };
				}
			}

			// when signature is not required, check if change is meaningful
			if (oldTax) {
				var duplicate = !newTax.get('employeeSignature') &&
					oldTax.get('formVersion') === newTax.get('formVersion') &&
					oldTax.get('stateFilingStatus') === newTax.get('stateFilingStatus') &&
					oldTax.get('stateWithholdingAllowance') === newTax.get('stateWithholdingAllowance') &&
					Number(oldTax.get('additionalStateWitholdings')) === Number(newTax.get('additionalStateWitholdings')) &&
					oldTax.get('localWithholdingAllowance') === newTax.get('localWithholdingAllowance') &&
					oldTax.get('additionalLocalWithholdings') === newTax.get('additionalLocalWithholdings') &&
					oldTax.get('GAstatePersonalAllowance') === newTax.get('GAstatePersonalAllowance') &&
					oldTax.get('GAstateDependentAllowance') === newTax.get('GAstateDependentAllowance') &&
					oldTax.get('ILstateAdditionalAllowance') === newTax.get('ILstateAdditionalAllowance') &&
					oldTax.get('INstateAdditionalDependentExemptions') === newTax.get('INstateAdditionalDependentExemptions') &&
					oldTax.get('INstateAdditionalDependentExemptionsFirstClaim') === newTax.get('INstateAdditionalDependentExemptionsFirstClaim') &&
					oldTax.get('INstateAdditionalCountyWithholdings') === newTax.get('INstateAdditionalCountyWithholdings') &&
					oldTax.get('LAstateNumberOfDependents') === newTax.get('LAstateNumberOfDependents') &&
					oldTax.get('MAstatePersonalBlindness') === newTax.get('MAstatePersonalBlindness') &&
					oldTax.get('MAstateSpouseBlindness') === newTax.get('MAstateSpouseBlindness') &&
					oldTax.get('MAstateFullTimeStudent') === newTax.get('MAstateFullTimeStudent') &&
					oldTax.get('MTstateReducedWithholdings') === newTax.get('MTstateReducedWithholdings') &&
					oldTax.get('NJstateWageChartLetter') === newTax.get('NJstateWageChartLetter') &&
					oldTax.get('VAstateAgeAndBlindnessExemptions') === newTax.get('VAstateAgeAndBlindnessExemptions') &&
					oldTax.get('WVstateOneEarnerWithholdAtLowerRate') === newTax.get('WVstateOneEarnerWithholdAtLowerRate') &&
					oldTax.get('MOstateSpouseWorks') === newTax.get('MOstateSpouseWorks') &&
					oldTax.get('PRmarried') === newTax.get('PRmarried') &&
					oldTax.get('PRyouthExemption') === newTax.get('PRyouthExemption') &&
					oldTax.get('PRpersonalExemption') === newTax.get('PRpersonalExemption') &&
					oldTax.get('PRveteranExemption') === newTax.get('PRveteranExemption') &&
					oldTax.get('PRcompleteDependents') === newTax.get('PRcompleteDependents') &&
					oldTax.get('PRjointDependents') === newTax.get('PRjointDependents') &&
					oldTax.get('PRallowance') === newTax.get('PRallowance') &&
					oldTax.get('PRadditionalWithholdingPercentage') === newTax.get('PRadditionalWithholdingPercentage') &&
					oldTax.get('ORmetroOptInAmount') === newTax.get('ORmetroOptInAmount') &&
					oldTax.get('ORmultnomahOptInAmount') === newTax.get('ORmultnomahOptInAmount') &&
					oldTax.get('stateTotalAllowanceValue') === newTax.get('stateTotalAllowanceValue');

				// IMPORTANT: We need to do this check, because payroll create default statetax object with sensible defaults.
				// This is different from federal tax for which the default object is not created.
				if (duplicate) {
					if (oldTax.get('employeeSignature')) {
						return { isValid: true, reason: "No changes detected" };
					}
				}
			}

			// non-null signature when required (based on creator)
			if (!this.get('shouldUseV2FederalTaxFrom')) {
				if (this.get('requester.id') == newTax.get('employee.id')) {
					if (!newTax.get('employeeSignature')) {
						this.set('signatureErrorText', "Please provide your signature to confirm changes");
						return { isValid: false, reason: "Please provide your signature to confirm changes" };
					}
				}
			}
		} else if (this.get('isUsingV2Form')) {
			if (!newTax || !newTax.get('stateFilingStatus')) {
				this.set('stateFilingStatusErrorText', "Please choose a state filing status");
				return { isValid: false, reason: "Please choose a state filing status" };
			}

			// when signature is not required, check if change is meaningful
			if (oldTax) {
				var duplicate = !newTax.get('employeeSignature') &&
								oldTax.get('formVersion') === newTax.get('formVersion') &&
								oldTax.get('stateFilingStatus') === newTax.get('stateFilingStatus') &&
								oldTax.get('hasTwoJobs') === newTax.get('hasTwoJobs') &&
								Number(oldTax.get('dependentsAmount')) === Number(newTax.get('dependentsAmount')) &&
								Number(oldTax.get('otherIncomeAmount')) === Number(newTax.get('otherIncomeAmount')) &&
								Number(oldTax.get('deductionsAmount')) === Number(newTax.get('deductionsAmount')) &&
								Number(oldTax.get('additionalStateWitholdings')) === Number(newTax.get('additionalStateWitholdings'));
				if (duplicate) {
					return { isValid: true, reason: "No changes detected" };
				}
			}

			// non-null signature when required (based on creator)
			if (this.get('requester.id') == newTax.get('employee.id')) {
				if (!newTax.get('employeeSignature')) {
					this.set('signatureErrorText', "Please provide your signature");
					return { isValid: false, reason: "Please provide your signature to confirm changes" };
				}
			}
		} else {
			return { isValid: false, reason: "Invalid Form Version" };
		}

		return { isValid: true, reason: null };
	},

	stateTaxAgeAndBlindnessExemptionsContent: function() {
		var options = [];
		for (var i = 0; i<= 4; i++) {
			options.push(Ember.Object.create({
				id: i,
				name: i.toString(),
			}));
		}
		return options;
	}.property(),

	stateTaxNumberOfDependentsContent: function() {
		var options = [];
		for (var i = 0; i<= 20; i++) {
			options.push(Ember.Object.create({
				id: i,
				name: i.toString(),
			}));
		}
		return options;
	}.property(),

	employeeResidenceStateHawaii: function() {
		return this.get('residenceState') == "HI";
	}.property('residenceState'),

	employeeResidenceStatePA: function() {
		return this.get('residenceState') == "PA";
	}.property('residenceState'),

	employeeWorkStatePA: function() {
		return this.get('workState') == "PA";
	}.property('workState'),

	employeeResidenceStateMA: function() {
		return this.get('residenceState') == "MA";
	}.property('residenceState'),

	employeeWorkStateMA: function() {
		return this.get('workState') == "MA";
	}.property('workState'),

	employeeResidenceStateGA: function() {
		return this.get('residenceState') == "GA";
	}.property('residenceState'),

	employeeWorkStateGA: function() {
		return this.get('workState') == "GA";
	}.property('workState'),

	employeeResidenceStateMO: function() {
		return this.get('residenceState') == "MO";
	}.property('residenceState'),

	employeeWorkStateMO: function() {
		return this.get('workState') == "MO";
	}.property('workState'),

	employeeResidenceStateIL: function() {
		return this.get('residenceState') == "IL";
	}.property('residenceState'),

	employeeWorkStateIL: function() {
		return this.get('workState') == "IL";
	}.property('workState'),

	employeeResidenceStateIN: function() {
		return this.get('residenceState') == "IN";
	}.property('residenceState'),

	employeeWorkStateIN: function() {
		return this.get('workState') == "IN";
	}.property('workState'),

	employeeResidenceStateWV: function() {
		return this.get('residenceState') == "WV";
	}.property('residenceState'),

	employeeWorkStateWV: function() {
		return this.get('workState') == "WV";
	}.property('workState'),

	employeeWorkStateNJ: function() {
		return this.get('workState') == "NJ";
	}.property('workState'),

	employeeResidenceStateNJ: function() {
		return this.get('residenceState') == "NJ";
	}.property('residenceState'),

	employeeResidenceStateLA: function() {
		return this.get('residenceState') == "LA";
	}.property('residenceState'),

	employeeWorkStateLA: function() {
		return this.get('workState') == "LA";
	}.property('workState'),

	employeeResidenceStateOR: function() {
		return this.get('residenceState') == "OR" && this.get('employee.livesInMetroMultnomah');
	}.property('residenceState', 'employee'),

	employeeWorkStateOR: function() {
		return this.get('workState') == "OR" && this.get('employee.livesInMetroMultnomah');
	}.property('workState', 'employee'),

	employeeResidenceStateVA: function() {
		return this.get('residenceState') == "VA";
	}.property('residenceState'),

	employeeWorkStateVA: function() {
		return this.get('workState') == "VA";
	}.property('workState'),

	employeeResidenceStateMI: function() {
		return this.get('residenceState') == "MI";
	}.property('residenceState'),

	employeeWorkStateMI: function() {
		return this.get('workState') == "MI";
	}.property('workState'),

	employeeResidenceStateWI: function() {
		return this.get('residenceState') == "WI";
	}.property('residenceState'),

	employeeWorkStateWI: function() {
		return this.get('workState') == "WI";
	}.property('workState'),

	employeeResidenceStateMT: function() {
		return this.get('residenceState') == "MT";
	}.property('residenceState'),

	employeeWorkStateMT: function() {
		return this.get('workState') == "MT";
	}.property('workState'),

	MOstateSpouseWorksText: function() {
		if (this.get('employee.currentWorkStateTax.MOstateSpouseWorks')) {
			return "Employee's spouse works or employee is single.";
		}
		if (this.get('employee.currentWorkStateTax.MOstateSpouseWorks')==false) {
			return "Employee's spouse doesn't work.";
		}
		if (!this.get('employee.currentWorkStateTax.MOstateSpouseWorks')) {
			return "Missing work status information for employee's spouse.";
		}
	}.property('employeeWorkStateMO', 'employee.currentWorkStateTax.MOstateSpouseWorks'),

	MOresidenceStateSpouseWorksText: function() {
		if (this.get('employee.currentResidenceStateTax.MOstateSpouseWorks')) {
			return "Employee's spouse works or employee is single.";
		}
		if (this.get('employee.currentResidenceStateTax.MOstateSpouseWorks')==false) {
			return "Employee's spouse doesn't work.";
		}
		if (!this.get('employee.currentResidenceStateTax.MOstateSpouseWorks')) {
			return "Missing work status information for employee's spouse.";
		}
	}.property('employeeResidenceStateMO', 'employee.currentResidenceStateTax.MOstateSpouseWorks'),

	isCertOfNonResidenceApplicable: function(workState, residenceState, residenceStateTaxApplicable) {
		if (!workState || !residenceState) {
			return false;
		}
		return (workState in window.RECIPROCAL_STATES && (window.RECIPROCAL_STATES[workState].indexOf(residenceState) != -1) && residenceStateTaxApplicable);
	},

	showCertOfNonResidenceForTaxState: function() {
		return this.isCertOfNonResidenceApplicable(
					this.get('workState'),  // taxState
					this.get('residenceState'), // residenceState
					this.get('residenceStateTaxApplicable')
			   );
	}.property('workState', 'residenceState', 'residenceStateTaxApplicable'),

	isWorkStatePAAndResidentStateNotReciprocal: function() {
		return this.get('employeeWorkStatePA') && !this.get('showCertOfNonResidenceForTaxState');
	}.property('employeeWorkStatePA', 'showCertOfNonResidenceForTaxState'),

	residenceStateHasTaxData: function() {
		return this.get('doesResidenceStateHaveIncomeTax');
	}.property('doesResidenceStateHaveIncomeTax'),

	isResidenceStateWithholdingEnumerated: function() {
		var state = this.get('residenceState');
		return (state in window.STATE_WITHHOLDING_OPTIONS);
	}.property('residenceState'),

	isResidenceStateWithholdingAllowanceNumberOfDependents: function() {
		var state = this.get('residenceState');
		return state == 'AL';
	}.property('residenceState'),

	isResidenceStateWithholdingAllowanceACode: function() {
		var state = this.get('residenceState');
		return state == 'CT';
	}.property('residenceState'),

	isResidenceStateWithholdingAllowanceDollarAmount: function() {
		var state = this.get('residenceState');
		return state == 'IA';
	}.property('residenceState'),

	isResidenceStateWithholdingExemptionDollarAmount: function() {
		var state = this.get('residenceState');
		return state == 'MS';
	}.property('residenceState'),

	isResidenceStateWithholdingAllowanceAPercent: function() {
		var state = this.get('residenceState');
		return state == 'AZ';
	}.property('residenceState'),

	isResidenceStateWithholdingAllowanceAExemptions: function() {
		var state = this.get('residenceState');
		return state == 'VA';
	}.property('residenceState'),

	WVstateAdditionalFieldsText: function() {
		if (this.get('employee.currentWorkStateTax.WVstateOneEarnerWithholdAtLowerRate')) {
			return "Yes, employee qualifies for one earner withholdings at lower rate.";
		}
		if (this.get('employee.currentWorkStateTax.WVstateOneEarnerWithholdAtLowerRate') == false) {
			return "Employee doesn't qualify for one earning withholdings.";
		}
		if (!this.get('employee.currentWorkStateTax.WVstateOneEarnerWithholdAtLowerRate')) {
			return "Missing information about employee's one earner withholding information.";
		}
	}.property('employee.currentWorkStateTax.WVstateOneEarnerWithholdAtLowerRate'),

	WVresidenceStateAdditionalFieldsText: function() {
		if (this.get('employee.currentResidenceStateTax.WVstateOneEarnerWithholdAtLowerRate')) {
			return "Yes, employee qualifies for one earner withholdings at lower rate.";
		}
		if (this.get('employee.currentResidenceStateTax.WVstateOneEarnerWithholdAtLowerRate') == false) {
			return "Employee doesn't qualify for one earning withholdings.";
		}
		if (!this.get('employee.currentResidenceStateTax.WVstateOneEarnerWithholdAtLowerRate')) {
			return "Missing information about employee's one earner withholding information.";
		}
	}.property('employee.currentResidenceStateTax.WVstateOneEarnerWithholdAtLowerRate'),

	NJstateAdditionalFieldsText: function() {
		if (this.get('employee.currentWorkStateTax.NJstateWageChartLetter') == null) {
			return "Wage Chart Letter: Not Applicable";
		}
		return "Wage Chart Letter: " + this.get('employee.currentWorkStateTax.NJstateWageChartLetter');
	}.property('employee.currentWorkStateTax.NJstateWageChartLetter'),

	NJresidenceStateAdditionalFieldsText: function() {
		if (this.get('employee.currentResidenceStateTax.NJstateWageChartLetter') == null) {
			return "Wage Chart Letter: Not Applicable";
		}
		return "Wage Chart Letter: " + this.get('employee.currentResidenceStateTax.NJstateWageChartLetter');
	}.property('employee.currentResidenceStateTax.NJstateWageChartLetter'),

	INstateAdditionalFieldsText: function() {
		var fieldInformation = [{'key': 'INstateAdditionalDependentExemptions',
								 'human': "Dependent Exemptions",
								 'prefix_by_dollar': false},
								 {'key': 'INstateAdditionalDependentExemptionsFirstClaim',
								 'human': "Dependent Exemptions First Claim",
								 'prefix_by_dollar': false},
								 {'key': 'INstateAdditionalCountyWithholdings',
								 'human': "Additional County Withholdings",
								 'prefix_by_dollar': true}
								];

		var message = "<br/>";
		for (var i = 0; i < fieldInformation.length; i++) {
			var field = fieldInformation[i].key;
			message += fieldInformation[i].human + ": ";
			if (this.get('employee.currentWorkStateTax.' + field) == null) {
				message += "Not yet filled";
			} else {
				message += this.get('employee.currentWorkStateTax.' + field);
			}
			message += "<br/>";
		}
		return message;
	}.property('employee.currentWorkStateTax.INstateAdditionalDependentExemptions',
			   'employee.currentWorkStateTax.INstateAdditionalDependentExemptionsFirstClaim',
			   'employee.currentWorkStateTax.INstateAdditionalCountyWithholdings'),

   INresidenceStateAdditionalFieldsText: function() {
		var fieldInformation = [{'key': 'INstateAdditionalDependentExemptions',
								 'human': "Dependent Exemptions",
								 'prefix_by_dollar': false
								},
								{'key': 'INstateAdditionalDependentExemptionsFirstClaim',
								 'human': "Dependent Exemptions First Claim",
								 'prefix_by_dollar': false},
								{'key': 'INstateAdditionalCountyWithholdings',
								 'human': "Additional County Withholdings",
								 'prefix_by_dollar': true
								}];

		var message = "<br/>";
		for (var i = 0; i < fieldInformation.length; i++) {
			var field = fieldInformation[i].key;
			message += fieldInformation[i].human + ": ";
			if (this.get('employee.currentResidenceStateTax.' + field) == null) {
				message += "Not yet filled";
			} else {
				message += this.get('employee.currentResidenceStateTax.' + field);
			}
			message += "<br/>";
		}
		return message;
	}.property('employee.currentResidenceStateTax.INstateAdditionalDependentExemptions',
			   'employee.currentResidenceStateTax.INstateAdditionalDependentExemptionsFirstClaim',
			   'employee.currentResidenceStateTax.INstateAdditionalCountyWithholdings'),

	MAstateAdditionalFieldsText: function() {
		var fieldInformation = {
			"MAstatePersonalBlindness": {
				true: "Employee is blind.",
				false: "Employee is not blind.",
				null: "Employee blindness status is not yet filled.",
			},
			"MAstateSpouseBlindness": {
				true: "Employee's spouse is blind.",
				false: "Employee's spouse is not blind.",
				null: "Employee's spouse blindness status is not yet filled.",
			},
			"MAstateFullTimeStudent": {
				true: "Employee is a full time student engaged in seasonal, part-time or temporary employment with income not exceeding $8,000.",
				false: "Employee is not a full time student engaged in seasonal, part-time or temporary employment with income not exceeding $8,000.",
				null:  "Employee's student status information is not yet filled.",
			},
		};

		var fieldNames = ['MAstatePersonalBlindness', 'MAstateSpouseBlindness',
						  'MAstateFullTimeStudent'];

		var message = "<br/>";
		for (var i = 0; i < fieldNames.length; i++) {
			var field = fieldNames[i];
			message += fieldInformation[field][this.get('employee.currentWorkStateTax.' + field)] + "<br/>";
		}
		return message;
	}.property('employee.currentWorkStateTax.MAstatePersonalBlindness', 'employee.currentWorkStateTax.MAstateSpouseBlindness',
			   'employee.currentWorkStateTax.MAstateFullTimeStudent'),

    MAresidenceStateAdditionalFieldsText: function() {
		var fieldInformation = {
			"MAstatePersonalBlindness": {
				true: "Employee is blind.",
				false: "Employee is not blind.",
				null: "Employee blindness status is not yet filled.",
			},
			"MAstateSpouseBlindness": {
				true: "Employee's spouse is blind.",
				false: "Employee's spouse is not blind.",
				null: "Employee's spouse blindness status is not yet filled.",
			},
			"MAstateFullTimeStudent": {
				true: "Employee is a full time student engaged in seasonal, part-time or temporary employment with income not exceeding $8,000.",
				false: "Employee is not a full time student engaged in seasonal, part-time or temporary employment with income not exceeding $8,000.",
				null:  "Employee's student status information is not yet filled.",
			},
		};

		var fieldNames = ['MAstatePersonalBlindness', 'MAstateSpouseBlindness',
						  'MAstateFullTimeStudent'];

		var message = "<br/>";
		for (var i = 0; i < fieldNames.length; i++) {
			var field = fieldNames[i];
			message += fieldInformation[field][this.get('employee.currentResidenceStateTax.' + field)] + "<br/>";
		}
		return message;
	}.property('employee.currentResidenceStateTax.MAstatePersonalBlindness',
			   'employee.currentResidenceStateTax.MAstateSpouseBlindness',
			   'employee.currentResidenceStateTax.MAstateFullTimeStudent'),

	stateWithholdingAllowanceHelptext: function() {
		if (this.get('isStateWithholdingAllowanceNumberOfDependents')) {
			return "and enter the number of dependents from line 4.";
		} else if (this.get('isStateWithholdingAllowanceDollarAmount')) {
			return "and enter the total amount of allowances from line 6.";
		} else if (this.get('isStateWithholdingExemptionDollarAmount')) {
			return "and enter the total amount of exemptions from line 6.";
		} else if (this.get('employeeWorkStateGA')) {
			return "and enter the additional allowances from line 5.";
		} else if (this.get('employeeWorkStateIN')) {
			return "and enter the total exemptions from line 5.";
		} else if (this.get('employeeWorkStateIL')) {
			return "and enter the personal allowances from line 4.";
		} else if (this.get('employeeWorkStateLA')) {
			return "and enter the total number of exemptions from line 6.";
		} else if (this.get('employeeWorkStateVA')) {
			return "and enter the personal exemptions from line 1(a).";
		} else if (this.get('employeeWorkStateWV')) {
			return "and enter the total exemptions from line 4.";
		} else if (this.get('employeeWorkStateMA')) {
			return "and enter the total exemptions from line 4.";
		} else if (this.get('employeeWorkStateMO')) {
			return "and enter the total allowances from line 5.";
		} else if (this.get('employeeWorkStateWI')) {
			return "and enter the total exemptions from line 1(d).";
		} else {
			return "to calculate your number.";
		}
	}.property('isStateWithholdingAllowanceNumberOfDependents',
			   'isStateWithholdingAllowanceDollarAmount',
			   'isStateWithholdingExemptionDollarAmount',
			   'employeeWorkStateGA',
			   'employeeWorkStateIL',
			   'employeeWorkStateLA',
			   'employeeWorkStateVA',
			   'employeeWorkStateIN',
			   'employeeWorkStateWV',
			   'employeeWorkStateMA',
			   'employeeWorkStateMO',
			   'employeeWorkStateWI'),

	residenceStateWithholdingAllowanceHelptext: function() {
		if (this.get('isResidenceStateWithholdingAllowanceNumberOfDependents')) {
			return "and enter the number of dependents from line 4.";
		} else if (this.get('employeeResidenceStateIN')) {
			return "and enter the total exemptions from line 5.";
		} else if (this.get('isResidenceStateWithholdingAllowanceDollarAmount')) {
			return "and enter the total amount of Allowances from line 6.";
		} else if (this.get('isResidenceStateWithholdingExemptionDollarAmount')) {
			return "and enter the total amount of exemptions from line 6.";
		} else if (this.get('employeeResidenceStateGA')) {
			return "and enter the additional allowances from line 5.";
		} else if (this.get('employeeResidenceStateMO')) {
			return "and enter the total allowances from line 5.";
		} else if (this.get('employeeResidenceStateIL')) {
			return "and enter the personal allowances from line 4.";
		} else if (this.get('employeeResidenceStateLA')) {
			return "and enter the total number of exemptions from line 6.";
		} else if (this.get('employeeResidenceStateVA')) {
			return "and enter the personal exemptions from line 1(a).";
		} else if (this.get('employeeResidenceStateWV')) {
			return "and enter the total exemptions from line 4.";
		} else if (this.get('employeeResidenceStateMA')) {
			return "and enter the total exemptions from line 4.";
		} else if (this.get('employeeResidenceStateWI')) {
			return "and enter the total exemptions from line 1(d).";
		} else {
			return "to calculate your number.";
		}
	}.property('isResidenceStateWithholdingAllowanceNumberOfDependents',
			   'isResidenceStateWithholdingAllowanceDollarAmount',
			   'isResidenceStateWithholdingExemptionDollarAmount',
			   'employeeResidenceStateGA',
			   'employeeResidenceStateIL',
			   'employeeResidenceStateLA',
			   'employeeResidenceStateVA',
			   'employeeResidenceStateIN',
			   'employeeResidenceStateWV',
			   'employeeResidenceStateMA',
			   'employeeResidenceStateMO',
			   'employeeResidenceStateWI'),

	residenceStateWithholdingName: function() {
		if (this.get('employeeResidenceStateIL')) {
			return "Personal Allowances";
		}
		if (this.get('employeeResidenceStateIN')) {
			return "Personal Exemptions";
		}
		if (this.get('employeeResidenceStateGA')) {
			return "Additional Allowances";
		}
		if (this.get('employeeResidenceStateVA')) {
			return "Personal Exemptions";
		}
		if (this.get('employeeResidenceStateWV')) {
			return "Total Exemptions";
		}
		if (this.get('employeeResidenceStateMA')) {
			return "Total Exemptions";
		}
		if (this.get('employeeResidenceStateMO')) {
			return "Total Allowances";
		}
		if (this.get('employeeResidenceStateWI')) {
			return "Total Exemptions";
		}
		if (this.get('isResidenceStateWithholdingAllowanceNumberOfDependents')) {
			return "Number of Dependents";
		}
		if (this.get('isResidenceStateWithholdingAllowanceACode')) {
			return "Withholding Code";
		}
		if (this.get('isResidenceStateWithholdingAllowanceAPercent')) {
			return "Withholding Percent";
		}
		if (this.get('isResidenceStateWithholdingAllowanceDollarAmount')) {
			return "Total Amount of Allowances $";
		}
		if (this.get('isResidenceStateWithholdingExemptionDollarAmount')) {
			return "Total Amount of Exemptions $";
		}
		return "Withholding Allowances";
	}.property('employeeResidenceStateIN',
			   'employeeResidenceStateIL',
			   'employeeResidenceStateWV',
			   'employeeResidenceStateGA',
			   'employeeResidenceStateVA',
			   'employeeResidenceStateMA',
			   'employeeResidenceStateMO',
			   'employeeResidenceStateWI',
			   'isResidenceStateWithholdingAllowanceACode',
			   'isResidenceStateWithholdingAllowanceAPercent',
			   'isResidenceStateWithholdingAllowanceDollarAmount',
			   'isResidenceStateWithholdingExemptionDollarAmount'),

	stateWithholdingName: function() {
		if (this.get('employeeWorkStateIL')) {
			return "Personal Allowances";
		}
		if (this.get('employeeWorkStateIN')) {
			return "Personal Exemptions";
		}
		if (this.get('employeeWorkStateGA')) {
			return "Additional Allowances";
		}
		if (this.get('employeeWorkStateWV')) {
			return "Total Exemptions";
		}
		if (this.get('employeeWorkStateVA')) {
			return "Personal Exemptions";
		}
		if (this.get('employeeWorkStateMA')) {
			return "Total Exemptions";
		}
		if (this.get('employeeWorkStateMO')) {
			return "Total Allowances";
		}
		if (this.get('employeeWorkStateWI')) {
			return "Total Exemptions";
		}
		if (this.get('isStateWithholdingAllowanceNumberOfDependents')) {
			return "Number of Dependents";
		}
		if (this.get('isStateWithholdingAllowanceACode')) {
			return "Withholding Code";
		}
		if (this.get('isStateWithholdingAllowanceAPercent')) {
			return "Withholding Percent";
		}
		if (this.get('isStateWithholdingAllowanceDollarAmount')) {
			return "Total Amount of Allowances $";
		}
		if (this.get('isStateWithholdingExemptionDollarAmount')) {
			return "Total Amount of Exemptions $";
		}
		return "Withholding Allowances";
	}.property('employeeWorkStateGA',
			   'employeeWorkStateIL',
			   'employeeWorkStateVA',
			   'employeeWorkStateIN',
			   'employeeWorkStateWV',
			   'employeeWorkStateMA',
			   'employeeWorkStateMO',
			   'employeeWorkStateWI',
			   'isStateWithholdingAllowanceACode',
			   'isStateWithholdingAllowanceDollarAmount',
			   'isStateWithholdingExemptionDollarAmount',
			   'isStateWithholdingAllowanceAPercent'),

	stateWithholdingPrompt: function() {
		return "Select " + this.get('stateWithholdingName');
	}.property('stateWithholdingName'),

	residenceStateWithholdingPrompt: function() {
		return "Select " + this.get('residenceStateWithholdingName');
	}.property('residenceStateWithholdingName'),

	stateFilingPrompt: function() {
		if (this.get('employee.stateFilingStatus')){
			return null;
		}
		else {
			return "Select Filing Status";
		}
	}.property('employee.stateFilingStatus'),
	isStateWithholdingEnumerated: function() {
		var state = this.get('workState');
		return (state in window.STATE_WITHHOLDING_OPTIONS);
	}.property('workState'),
	//TODO: Make max and min computed properties found by state
	// Currently this is a under utilized abstraction of max and min
	stateWithholdingAllowanceMax: 99,
	stateWithholdingAllowanceMin:  0,
	residenceStateWithholdingAllowanceMax: 99,
	residenceStateWithholdingAllowanceMin:  0,
	restrictedAllowanceMax: 20,

	GAstateDependentAllowanceOptions: function() {
		var ret = [];
		//TODO replace with lodash or better solution
		for(var i = this.get('stateWithholdingAllowanceMin'); i <= this.get('restrictedAllowanceMax'); i++)
		{
			ret.push({"id": i, "name": i.toString()});
		}
		return ret;
	}.property('stateWithholdingOptions','restrictedAllowanceMax','stateWithholdingAllowanceMin'),


	showNJstateWageChartLetterOptions: function() {
		if (!this.get('employee.stateFilingStatus') || this.get('employee.stateFilingStatus') == 'S' || this.get('employee.stateFilingStatus') == 'MS') {
			return false;
		}
		return true;
	}.property('employee.stateFilingStatus'),


	showNJresidenceStateWageChartLetterOptions: function() {
		if (!this.get('employee.residenceStateFilingStatus') || this.get('employee.residenceStateFilingStatus') == 'S' || this.get('employee.residenceStateFilingStatus') == 'MS') {
			return false;
		}
		return true;
	}.property('employee.residenceStateFilingStatus'),


	resetNJstateWageChartLetterOnFilingStatusChange: function () {
		if (!this.get('employee.stateFilingStatus') || this.get('employee.stateFilingStatus') == 'S' || this.get('employee.stateFilingStatus') == 'MS') {
			if(!this.get('employee.isInternational') && this.get('employee.isEmployee') && this.get('employee.currentWorkStateTax')) {
				this.set('employee.currentWorkStateTax.NJstateWageChartLetter', null);
			}
		}
	}.observes('employee.stateFilingStatus'),


	resetNJstateWageChartLetterOnResidenceStateFilingStatusChange: function () {
		if (!this.get('employee.residenceStateFilingStatus') || this.get('employee.residenceStateFilingStatus') == 'S' || this.get('employee.residenceStateFilingStatus') == 'MS') {
			if(!this.get('employee.isInternational') && this.get('employee.isEmployee') && this.get('employee.currentResidenceStateTax')) { this.set('employee.currentResidenceStateTax.NJstateWageChartLetter', null); }
		}
	}.observes('employee.residenceStateFilingStatus'),

	NJWageChartLetterOptions: function() {
		var ret = [];
		ret.push({"id": "A", "name": "A"});
		ret.push({"id": "B", "name": "B"});
		ret.push({"id": "C", "name": "C"});
		ret.push({"id": "D", "name": "D"});
		ret.push({"id": "E", "name": "E"});
		return ret;
	}.property(),

	GAstatePersonalAllowanceOptions: function() {
		var ret = [];
		//TODO replace with lodash or better solution
		for(var i = 0; i <= 2; i++)
		{
			ret.push({"id": i, "name": i.toString()});
		}
		return ret;
	}.property(),

	standardStateWithholdingAllowancesInNumber: function() {
		var ret = [];
		var maxValue = this.get('residenceState') == 'IN' || this.get('residenceState') == 'IL' ? this.get('restrictedAllowanceMax') : this.get('stateWithholdingAllowanceMax');
		//TODO replace with lodash or better solution
		for(var i = this.get('stateWithholdingAllowanceMin'); i <= maxValue; i++)
		{
			ret.push({"id": i, "name": i.toString()});
		}
		return ret;
	}.property('stateWithholdingOptions','stateWithholdingAllowanceMax','stateWithholdingAllowanceMin', 'restrictedAllowanceMax', 'residenceState'),


	standardStateWithholdingAllowances: function() {
		var ret = [];
		//TODO replace with lodash or better solution
		for(var i = this.get('stateWithholdingAllowanceMin'); i <= this.get('stateWithholdingAllowanceMax'); i++)
		{
			ret.push({"id": i.toString(), "name": i.toString()});
		}
		return ret;
	}.property('stateWithholdingOptions','stateWithholdingAllowanceMax','stateWithholdingAllowanceMin'),

	standardResidenceStateWithholdingAllowances: function() {
		var ret = [];
		//TODO replace with lodash or better solution
		for(var i = this.get('residenceStateWithholdingAllowanceMin'); i <= this.get('residenceStateWithholdingAllowanceMax'); i++)
		{
			ret.push({"id": i.toString(), "name": i.toString()});
		}
		return ret;
	}.property('residenceStateWithholdingAllowanceMax','residenceStateWithholdingAllowanceMin'),

	stateWithholdingOptions: function() {
		var state = this.get('workState');
		var stateOptions = window.STATE_WITHHOLDING_OPTIONS[state];
		return stateOptions;
	}.property('workState'),

	residenceStateWithholdingOptions: function() {
		var state = this.get('residenceState');
		var stateOptions = window.STATE_WITHHOLDING_OPTIONS[state];
		return stateOptions;
	}.property('residenceState'),

	stateTaxWithholdingOptions: function() {
		var state = this.get('workState');
		var stateOptions = STATE_FILING_STATUSES[state];
		// if it's not in the list then just the federal ones
		if (stateOptions == null) {
			return zen.FEDERAL_FILING_STATUS_OPTIONS['v1'];
		}
		return stateOptions;
	}.property('workState'),

	residenceStateTaxWithholdingOptions: function() {
		var state = this.get('residenceState');
		var stateOptions = STATE_FILING_STATUSES[state];
		// if it's not in the list then just the federal ones
		if (stateOptions == null) {
			return zen.FEDERAL_FILING_STATUS_OPTIONS['v1'];
		}
		return stateOptions;
	}.property('residenceState'),


	isStateWithholdingAllowanceTooHigh: function() {
		if(!this.get('stateTaxWithholdingOptions')) {
			return false;
		}
		var allowance = this.get('employee.stateWithholdingAllowance') || this.get('stateWithholdingAllowance');
		if (allowance == null || allowance === "") {
			return false;
		}
		return Number(allowance) > 12;
	}.property('employee.stateWithholdingAllowance', 'stateWithholdingAllowance'),

	isResidenceStateWithholdingAllowanceTooHigh: function() {
		if(!this.get('residenceStateTaxWithholdingOptions')) {
			return false;
		}
		var allowance = this.get('employee.residenceStateWithholdingAllowance') || this.get('residenceStateWithholdingAllowance');
		if (allowance == null || allowance === "") {
			return false;
		}
		return Number(allowance) > 12;
	}.property('employee.residenceStateWithholdingAllowance', 'residenceStateWithholdingAllowance'),

	stateTaxApplicable: function() {
		var certOfNonResidenceFlag = this.get('showCertOfNonResidenceForTaxState') ? this.get('employee.hasCertOfNonResidenceForTaxState') === false : this.get('employee.hasCertOfNonResidenceForTaxState') !== true;
		return this.get('showStateTaxes') && this.get('doesStateHaveIncomeTax') && certOfNonResidenceFlag;
	}.property('showStateTaxes', 'doesStateHaveIncomeTax', 'showCertOfNonResidenceForTaxState', 'employee.hasCertOfNonResidenceForTaxState'),

	showStateTaxes: function() {
		return !this.get('hasNoTaxStateNoWorkState');
	}.property('workState', 'employee.hasCertOfNonResidenceForTaxState', 'hasNoTaxStateNoWorkState'),

	showLocalTaxes: function() {
		var state = null;
		if (this.get('showResidenceStateTaxes')) {
			state = this.get('residenceState');
		} else {
			state = this.get('workState');
		}
		if (!state) { return false; }
		return window.STATES_WITH_LOCAL_TAX.indexOf(state) >= 0;
	}.property('residenceState', 'workState', 'showResidenceStateTaxes'),

	workState: function() {
		var taxState = this.get('employee.taxState') || this.get('taxState');
		var workLocationState = this.get('employee.location.state') || this.get('location.state');
		return taxState || workLocationState;
	}.property('employee.taxState', 'taxState', 'employee.location.state', 'location.state'),

	residenceState: function() {
		var state = this.get('employee.state');
		var residenceTaxState = this.get('employee.currentResidenceStateTax.taxState') || this.get('currentResidenceStateTax.taxState');
		var taxState = this.get('employee.currentWorkStateTax.taxState') || this.get('currentWorkStateTax.taxState');
		if (taxState) {
			return residenceTaxState || state;
		} else {
			return state;
		}
	}.property('employee.currentResidenceStateTax.taxState', 'currentResidenceStateTax.taxState', 'employee.state', 'currentWorkStateTax.taxState', 'employee.currentWorkStateTax.taxState'),

	showResidenceStateTaxes: function() {
		var taxState = this.get('employee.currentWorkStateTax.taxState') || this.get('currentWorkStateTax.taxState');
		var workLocationState = this.get('employee.location.state') || this.get('location.state');
		var state = this.get('employee.state');
		var residenceTaxState = this.get('employee.currentResidenceStateTax.taxState') || this.get('currentResidenceStateTax.taxState');

		if (taxState && !residenceTaxState) {
			return false;
		} else if (taxState && residenceTaxState) {
			return true;
		} else {
			return !!(workLocationState && state && (state != workLocationState));
		}
	}.property('currentResidenceStateTax.taxState', 'employee.currentResidenceStateTax.taxState', 'employee.state',
			   'employee.location.state', 'location.state', 'employee.currentWorkStateTax.taxState', 'currentWorkStateTax.taxState'),

	doesStateUseFederal: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_THAT_USE_FEDERAL.indexOf(state) != -1;
	}.property('workState'),

	testCOProperty: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return true;
	}.property('workState'),

	doesResidenceStateUseFederal: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_THAT_USE_FEDERAL.indexOf(state) != -1;
	}.property('residenceState'),

	doesStateMatchFederalFilingStatus: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_THAT_MATCH_FEDERAL_FILING_STATUS.indexOf(state) != -1;
	}.property('workState'),

	doesResidenceStateMatchFederalFilingStatus: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_THAT_MATCH_FEDERAL_FILING_STATUS.indexOf(state) != -1;
	}.property('residenceState'),

	doesStateNotMatchFederalFilingStatus: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_NOT_MATCHING_FEDERAL_STATUS.indexOf(state) != -1;
	}.property('workState'),

	doesResidenceStateNotMatchFederalFilingStatus: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_NOT_MATCHING_FEDERAL_STATUS.indexOf(state) != -1;
	}.property('residenceState'),

	doesStateMatchFederalAllowance: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_THAT_MATCH_FEDERAL_ALLOWANCE.indexOf(state) != -1;
	}.property('workState'),

	doesResidenceStateMatchFederalAllowance: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_THAT_MATCH_FEDERAL_ALLOWANCE.indexOf(state) != -1;
	}.property('residenceState'),

	doesStateNotMatchFederalAllowance: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_NOT_MATCHING_FEDERAL_ALLOWANCE.indexOf(state) != -1;
	}.property('workState'),

	doesResidenceStateNotMatchFederalAllowance: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_NOT_MATCHING_FEDERAL_ALLOWANCE.indexOf(state) != -1;
	}.property('residenceState'),

	showSaveAndContinueButton: function (argument) {
		return (!this.get('isEditingWorkStateTax') && !this.get('isEditingResidenceStateTax')) || !this.get('doesStateHaveIncomeTax') || !this.get('doesResidenceStateHaveIncomeTax') || this.get('employee.hasCertOfNonResidenceForTaxState') || !this.get('employee.taxState') || (this.get('employee.hasMultiStateTaxSetup') && !this.get('employee.residenceTaxState'));
	}.property('isEditingWorkStateTax', 'isEditingResidenceStateTax', 'doesStateHaveIncomeTax', 'doesResidenceStateHaveIncomeTax', 'employee.hasCertOfNonResidenceForTaxState', 'employee.taxState', 'employee.hasMultiStateTaxSetup', 'employee.residenceTaxState'),

	doesStateHaveIncomeTax: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_INCOME_TAX.indexOf(state) == -1;
	}.property('workState'),

	residenceStateTaxApplicable: function() {
		return this.get('showResidenceStateTaxes') && this.get('doesResidenceStateHaveIncomeTax');
	}.property('showResidenceStateTaxes', 'doesResidenceStateHaveIncomeTax'),

	doesResidenceStateHaveIncomeTax: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_INCOME_TAX.indexOf(state) == -1;
	}.property('residenceState'),

	showStateWithholdingAllowance: function() {
		var state = this.get('workState');
		if (!state) { return false; }

		return window.STATES_WITH_NO_WITHHOLDING_ALLOWANCE.indexOf(state) == -1;
	}.property('workState'),

	showResidenceStateWithholdingAllowance: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_WITHHOLDING_ALLOWANCE.indexOf(state) == -1;
	}.property('residenceState'),

	showResidenceStateAdditionalWithholding: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_ADDITIONAL_WITHHOLDING.indexOf(state) == -1;
	}.property('residenceState'),

	showStateAdditionalWithholding: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_ADDITIONAL_WITHHOLDING.indexOf(state) == -1;
	}.property('workState'),

	showResidenceStateFilingStatus: function() {
		var state = this.get('residenceState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_FILING_STATUS.indexOf(state) == -1;
	}.property('residenceState'),

	showStateFilingStatus: function() {
		var state = this.get('workState');
		if (!state) { return false; }
		return window.STATES_WITH_NO_FILING_STATUS.indexOf(state) == -1;
	}.property('workState'),

	isStateWithholdingAllowanceNumberOfDependents: function() {
		var state = this.get('workState');
		return state == 'AL';
	}.property('workState'),

	isStateWithholdingAllowanceDollarAmount: function() {
		var state = this.get('workState');
		return state == 'IA';
	}.property('workState'),

	isStateWithholdingExemptionDollarAmount: function() {
		var state = this.get('workState');
		return state == 'MS';
	}.property('workState'),

	isStateWithholdingAllowanceACode: function() {
		var state = this.get('workState');
		return state == 'CT';
	}.property('workState'),

	isStateWithholdingAllowanceAPercent: function() {
		var state = this.get('workState');
		return state == 'AZ';
	}.property('workState'),

	isStateWithholdingAllowanceAExemptions: function() {
		var state = this.get('workState');
		return state == 'VA';
	}.property('workState'),

	hasNoTaxStateNoWorkState: function() {
		return !(this.get('employee.taxState') || this.get('taxState') || this.get('employee.location.state') || this.get('location.state'));
	}.property('employee.taxState', 'taxState', 'location.state', 'employee.location'),

	hasTaxStateNoWorkLocation: function() {
		var taxState = this.get('employee.taxState') || this.get('taxState');
		var wl = this.get('employee.location') || this.get('location');
		if (taxState && !wl) {
			return true;
		}
		return false;
	}.property('employee.taxState', 'taxState', 'location', 'employee.location'),

	taxStateMatchesWorkLocation: function() {
		var taxState = this.get('employee.taxState') || this.get('taxState');
		var wl = this.get('employee.location') || this.get('location');
		if (!wl || !taxState || !wl.get('state')) {
			return false;
		}
		var wlState = wl.get('state');
		if (wlState == taxState) {
			return true;
		}
		return false;
	}.property('employee.taxState', 'taxState', 'location', 'employee.location'),

	taxStateMisMatchesWorkLocation: function() {
		var taxState = this.get('employee.taxState') || this.get('taxState');
		var wl = this.get('employee.location') || this.get('location');
		if (!wl || !taxState || !wl.get('state')) {
			return false;
		}
		var wlState = wl.get('state');
		if (wlState != taxState) {
			return true;
		}
		return false;
	}.property('employee.taxState', 'taxState', 'location', 'employee.location'),

	residenceTaxStateMismatchesState: function() {
		var residenceTaxState = this.get('employee.residenceTaxState') || this.get('residenceTaxState');
		var state = this.get('employee.state');
		if (!state || !residenceTaxState) {
			return false;
		}
		return state != residenceTaxState;
	}.property('employee.state', 'employee.residenceTaxState', 'residenceTaxState'),

	stateMismatchesWorkLocation: function() {
		var state = null;
		state = this.get('employee.residenceTaxState') || this.get('residenceTaxState') || this.get('employee.state');
		var wl = this.get('employee.location') || this.get('location');
		if (!wl || !state || !wl.get('state')) {
			return false;
		}
		var wlState = wl.get('state');
		if (state) {
			return (state != wlState);
		} else {
			return false;
		}
	}.property('employee.state', 'location.state', 'employee.location.state', 'residenceTaxState', 'employee.residenceTaxState'),
});


var COUNTRIES_WITH_COMPANY_TAX_ID = ['IN', 'AU', 'GB', 'CA', 'SG', 'HK', 'NL', 'US', 'IE', 'FR', 'BR'];

var COUNTRIES_WITH_STATE_TAX = ['CA', 'US'];

var COUNTRIES_WITH_NO_NATIONAL_ID = ['AU', 'IN', 'DE', 'BR'];

var COUNTRIES_WITH_NO_TAX_ID = ['SG', 'GB', 'CA', 'NL', 'HK', 'US', 'FR', 'IE'];

var COUNTRIES_WITH_NO_TAX_FIELDS = ['HK', 'IN', 'NL', 'IE'];

zen._InternationalEmployeeEligibilityMixin = Ember.Mixin.create({
	hasInProcessEligibilityNewHire: function() {
		return this.get('employee.newHires').find(function(h) {
			return !h.get('eligibility.isEligibilityVerificationComplete') && h.get('isDoingEligibility');
		});
	}.property('employee.newHires.@each.isDoingEligibility','employee.newHires.@each.eligibility.isEligibilityVerificationComplete'),
	eligibilityNewHire: function() {
		return this.get('employee.newHires').filterBy('isDoingEligibility').get('lastObject');
	}.property('employee.newHires.@each.isDoingEligibility'),
	// these are not use for v2 onboarding, will remove them after removing employeetearsheet
	isDoingEligibilityProofUpload: Ember.computed.bool("currentEligibility.newHire.isDoingEligibilityProofUpload"),
	isNotDoingEligibilityProofUpload: Ember.computed.not("isDoingEligibilityProofUpload"),
	hasEligibilityDocuments: Ember.computed.notEmpty("currentEligibility.eligibilityDocuments"),
	hasProofs: Ember.computed.or("isNotDoingEligibilityProofUpload", "hasEligibilityDocuments"),
	// ========================================================================================
	taxIdLabel: function() {
		var country = this.get('employee.country');
		if (country == 'AU') {
			return 'Tax File Number (TFN)';
		} else if (country == 'DE') {
			return 'Tax Identification Number (Steuerliche Identifikationsnummer)';
		} else if (country == 'IN') {
			return 'Permanent Account Number (PAN)';
		} else if (country == 'BR') {
			return 'Individual Taxpayer\'s Registration Number (CPF)';
		}
		return null;
	}.property('employee.country'),
	nationalIdLabel: function() {
		var country = this.get('employee.country');
		if (country == 'US' || country == 'FR') {
			return 'Social Security Number (SSN)';
		} else if (country == 'IE') {
			return 'Social Security Number (PPS)';
		} else if (country == 'CA') {
			return 'Social Insurance Number (SIN)';
		} else if (country == 'NL') {
			return 'Burgerservicenummer (BSN)';
		} else if (country == 'GB') {
			return 'National Insurance Number (NINO)';
		} else if (country == 'HK') {
			return 'HKID Number';
		} else if (country == 'SG') {
			return 'NRIC Number/FIN';
		}
		return null;
	}.property('employee.country'),
	hasTaxId: function() {
		return COUNTRIES_WITH_NO_TAX_ID.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	hasNationalId: function() {
		return COUNTRIES_WITH_NO_NATIONAL_ID.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	isOther: Ember.computed.equal('employee.currentEligibility.eligibilityType', 'OT'),
	currentEligibilityCountryHumanReadable: function() {
		return this.get('employee.currentEligibility.nationality') ? countryCodeToName(this.get('employee.currentEligibility.nationality')) : "";
	}.property('employee.currentEligibility.nationality'),
	showTaxIDOrNationalID: function() {
		if (this.get('isCardInSetupFlow')) {
			return !this.get('switches.hide_unsupported_intl_hr_features');
		}
		if (this.get('switches.hide_unsupported_intl_hr_features')) {
			return !Ember.isEmpty(this.get('employee.currentEligibility')) && (!Ember.isEmpty(this.get('employee.currentEligibility.nationalID')) || !Ember.isEmpty(this.get('employee.currentEligibility.taxID')));
		}
		return true;
	}.property('isCardInSetupFlow', 'employee.currentEligibility.nationalID', 'employee.currentEligibility.taxID'),
});

var DEPENDENT_TYPE_CHOICES = [
	'self',
	'Spouse',
	'Domestic Partner',
	'Child',
];

var DEPENDENT_TYPES = [
	'Spouse',
	'Domestic Partner',
	'Child',
];

var COUNTRY_TO_CURRENCY_MAPPING = {
	'AD': 'EUR',
	'AG': 'XCD',
	'AI': 'XCD',
	'AL': 'ALL',
	'AM': 'AMD',
	'AR': 'ARS',
	'AS': 'USD',
	'AT': 'EUR',
	'AU': 'AUD',
	'AX': 'EUR',
	'BA': 'BAM',
	'BB': 'BBD',
	'BE': 'EUR',
	'BG': 'BGN',
	'BL': 'EUR',
	'BM': 'BMD',
	'BN': 'BND',
	'BO': 'BOB',
	'BQ': 'USD',
	'BR': 'BRL',
	'BS': 'BSD',
	'BZ': 'BZD',
	'CA': 'CAD',
	'CC': 'AUD',
	'CH': 'CHF',
	'CK': 'NZD',
	'CL': 'CLP',
	'CO': 'COP',
	'CR': 'CRC',
	'CS': 'RSD',
	'CX': 'AUD',
	'CY': 'EUR',
	'CZ': 'CZK',
	'DE': 'EUR',
	'DK': 'DKK',
	'DO': 'DOP',
	'DM': 'XCD',
	'EC': 'USD',
	'EE': 'EUR',
	'EG': 'EGP',
	'ES': 'EUR',
	'FI': 'EUR',
	'FJ': 'FJD',
	'FK': 'FKP',
	'FM': 'USD',
	'FO': 'DKK',
	'FR': 'EUR',
	'GB': 'GBP',
	'GD': 'XCD',
	'GF': 'EUR',
	'GG': 'GBP',
	'GI': 'GIP',
	'GP': 'EUR',
	'GR': 'EUR',
	'GS': 'GBP',
	'GU': 'USD',
	'GY': 'GYD',
	'HM': 'AUD',
	'HR': 'HRK',
	'HT': 'HTG',
	'HU': 'HUF',
	'IE': 'EUR',
	'IM': 'GBP',
	'IN': 'INR',
	'IO': 'USD',
	'IS': 'ISK',
	'IT': 'EUR',
	'JE': 'GBP',
	'JM': 'JMD',
	'JP': 'JPY',
	'KE': 'KES',
	'KI': 'AUD',
	'KN': 'XCD',
	'KR': 'KRW',
	'KY': 'KYD',
	'LB': 'LBP',
	'LC': 'XCD',
	'LI': 'CHF',
	'LR': 'LRD',
	'LT': 'EUR',
	'LU': 'EUR',
	'LV': 'EUR',
	'MC': 'EUR',
	'MD': 'MDL',
	'ME': 'EUR',
	'MF': 'EUR',
	'MH': 'USD',
	'MK': 'MKD',
	'MP': 'USD',
	'MQ': 'EUR',
	'MS': 'XCD',
	'MT': 'EUR',
	'MX': 'MXN',
	'MY': 'MYR',
	'NA': 'NAD',
	'NG': 'NGN',
	'NF': 'AUD',
	'NL': 'EUR',
	'NO': 'NOK',
	'NR': 'AUD',
	'NU': 'NZD',
	'NZ': 'NZD',
	'PH': 'PHP',
	'PL': 'PLN',
	'PM': 'EUR',
	'PN': 'NZD',
	'PR': 'USD',
	'PT': 'EUR',
	'PW': 'USD',
	'RE': 'EUR',
	'RO': 'RON',
	'RS': 'RSD',
	'SB': 'SBD',
	'SD': 'SDG',
	'SE': 'SEK',
	'SG': 'SGD',
	'SH': 'SHP',
	'SI': 'EUR',
	'SJ': 'NOK',
	'SK': 'EUR',
	'SM': 'EUR',
	'SR': 'SRD',
	'SS': 'SSP',
	'SV': 'USD',
	'SY': 'SYP',
	'TC': 'USD',
	'TF': 'EUR',
	'TH': 'THB',
	'TK': 'NZD',
	'TL': 'USD',
	'TR': 'TRY',
	'TT': 'TTD',
	'TV': 'AUD',
	'TW': 'TWD',
	'UK': 'GBP',
	'UM': 'USD',
	'US': 'USD',
	'VA': 'EUR',
	'VC': 'XCD',
	'VG': 'USD',
	'VI': 'USD',
	'VN': 'VND',
	'XK': 'EUR',
	'YT': 'EUR',
	'ZW': 'ZWL',
};


var CURRENCY_SYMBOL_MAP = {
	'ALL': '',
	'AMD': '',
	'ARS': '',
	'AUD': '$',
	'BAM': '',
	'BBD': '$',
	'BGN': '',
	'BMD': '$',
	'BND': '$',
	'BRL': '',
	'BOB': '',
	'BSD': '$',
	'BZD': '',
	'CAD': '$',
	'CHF': '',
	'CLP': '',
	'COP': '$',
	'CRC': '',
	'CZK': '',
	'DKK': '',
	'DOP': '',
	'EGP': '',
	'EUR': '€',
	'FJD': '$',
	'FKP': '£',
	'GBP': '£',
	'GIP': '£',
	'GYD': '$',
	'HNL': '',
	'HRK': '',
	'HTG': '',
	'HUF': '',
	'INR': '',
	'ISK': '',
	'JMD': '$',
	'JPY': '',
	'KES': '',
	'KRW': '',
	'KYD': '$',
	'LBP': '£',
	'LRD': '$',
	'LKR': '',
	'MDL': '',
	'MKD': '',
	'MXN': '$',
	'MYR': '',
	'NAD': '$',
	'NGN': '',
	'NOK': '',
	'NZD': '$',
	'PEN': '',
	'PHP': '',
	'PKR': '',
	'PLN': '',
	'RON': '',
	'RSD': '',
	'SBD': '$',
	'SDG': '$',
	'SEK': '',
	'SGD': '$',
	'SHP': '£',
	'SRD': '$',
	'SSP': '',
	'SYP': '£',
	'THB': '',
	'TRY': '',
	'TTD': '$',
	'TWD': '',
	'USD': '$',
	'UYU': '',
	'VND': '',
	'XCD': '$',
	'ZAR': '$',
	'ZWL': '$',
};

zen._InternationalEmployeeEmploymentMixin = Ember.Mixin.create({
	currencySymbol: function() {
		return CURRENCY_SYMBOL_MAP[this.get('employee.currentEmployment.currency')];
	}.property('employee.currentEmployment.currency'),
	dropCurrencySymbol: function() {
		return Ember.isEmpty(this.get('currencySymbol'));
	}.property('currencySymbol'),
	employeeSalaryByPayFrequency: function() {
		var compType = this.get('employee.currentEmployment.compType');
		var payRate = this.get('employee.currentEmployment.payRate');
		var salary = this.get('employee.currentEmployment.monthlySalary');
		if (compType == 'H' && payRate) {
			salary = (payRate * 52 * 40 / 12);
		}
		if (salary == null || salary == "") {
			return "";
		}
		var payFreq = this.get('employee.payFrequency');
		if (payFreq == 'SM') {
			salary *= 0.5;
		} else if (payFreq == 'BW') {
			salary *= (1/26)*12;
		} else if (payFreq == 'We') {
			salary *= (1/52)*12;
		}	else if (payFreq == 'Mo') {
			salary *= 1;
		} else {
			salary *= 1; // assume default payFrequency to be Monthly
		}
		return Number(Number(String(salary).replace(/,/g,'')).toFixed(2)).toLocaleString();
	}.property('employee.currentEmployment.monthlySalary', 'employee.payFrequency', 'employee.currentEmployment.compType', 'employee.currentEmployment.payRate'),
	isSingapore: function() {
		if (this.get('employee.country') == 'SG') {
			return true;
		}
		return false;
	}.property('employee.country'),
	isFrance: function() {
		return this.get('employee.country') == 'FR';
	}.property('employee.country'),
	annualSalaryIncludesBonusGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.currentEmployment.annualSalaryIncludesBonus', value == 'yes');
		}
		var has = this.get('employee.currentEmployment.annualSalaryIncludesBonus');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.currentEmployment.annualSalaryIncludesBonus'),
	overtimeEligibilityGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.currentEmployment.overtimeEligibility', value == 'yes');
		}
		var has = this.get('employee.currentEmployment.overtimeEligibility');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.currentEmployment.overtimeEligibility'),
});

zen._InternationalCompanyTaxMixin = Ember.Mixin.create({
	hasCompanyTaxId: function() {
		return COUNTRIES_WITH_COMPANY_TAX_ID.indexOf(this.get('country')) != -1;
	}.property('country'),
	companyTaxIdLabel: function() {
		var country = this.get('country');
		if (country == 'US') {
			return 'Employer Identification Number (EIN)';
		} else if (country == 'AU') {
			return 'Australian Business Number (ABN)';
		} else if (country == 'IN') {
			return 'Tax Deduction Account Number (TAN)';
		} else if (country == 'GB') {
			return 'PAYE Reference Number';
		} else if (country == 'CA') {
			return 'Business Number (BN)';
		} else if (country == 'SG') {
			return 'Unique Entity Number (UEN)';
		} else if (country == 'HK') {
			return 'Business Registration Number (BRN)';
		} else if (country == 'NL') {
			return 'Federal Tax ID (EIN)';
		} else if (country == 'IE') {
			return 'PAYE/PRSI Number';
		} else if (country == 'FR') {
			return 'SIRET Number';
		} else if (country == 'BR') {
			return 'CNPJ Number';
		}
		return 'Company Tax Number';
	}.property('country'),
});

zen._InternationalEmployeeFederalTaxMixin = Ember.Mixin.create({
	isCardInSetupFlow: false,
	signatureErrorText: undefined,
	taxIdErrorText: undefined,
	singaporeRaceSelectChoiceErrorText: undefined,
	frFederalTaxNumberOfDependentsErrorText: undefined,
	brFederalTaxHasDependentsErrorText: undefined,
	brFederalTaxWorkingBookletNumberErrorText: undefined,
	brFederalTaxWorkingBookletSeriesErrorText: undefined,
	brFederalTaxWorkingBookletUFErrorText: undefined,
	brFederalTaxWorkingBookletIssueDateErrorText: undefined,
	brFederalTaxIdNumberErrorText: undefined,
	brFederalTaxIdNumberIssuingBodyErrorText: undefined,
	brFederalTaxHighestLevelOfEducationErrorText: undefined,
	brFederalTaxIsFirstJobErrorText: undefined,
	brFederalTaxHasUnionContributionErrorText: undefined,
	canadaFederalTaxErrorText: undefined,
	hasErrors: Ember.computed.or('signatureErrorText',
		'taxIdErrorText',
		'singaporeRaceSelectChoiceErrorText',
		'frFederalTaxNumberOfDependentsErrorText',
		'brFederalTaxHasDependentsErrorText',
		'brFederalTaxWorkingBookletNumberErrorText',
		'brFederalTaxWorkingBookletSeriesErrorText',
		'brFederalTaxWorkingBookletUFErrorText',
		'brFederalTaxWorkingBookletIssueDateErrorText',
		'brFederalTaxIdNumberErrorText',
		'brFederalTaxIdNumberIssuingBodyErrorText',
		'brFederalTaxHighestLevelOfEducationErrorText',
		'brFederalTaxIsFirstJobErrorText',
		'brFederalTaxHasUnionContributionErrorText',
		'canadaFederalTaxErrorText'),
	clearErrorTextInternationalFederaltax: function() {
		this.set('signatureErrorText', undefined);
		this.set('taxIdErrorText', undefined);
		this.set('singaporeRaceSelectChoiceErrorText', undefined);
		this.set('frFederalTaxNumberOfDependentsErrorText', undefined);
		this.set('brFederalTaxHasDependentsErrorText', undefined);
		this.set('brFederalTaxWorkingBookletNumberErrorText', undefined);
		this.set('brFederalTaxWorkingBookletSeriesErrorText', undefined);
		this.set('brFederalTaxWorkingBookletUFErrorText', undefined);
		this.set('brFederalTaxWorkingBookletIssueDateErrorText', undefined);
		this.set('brFederalTaxIdNumberErrorText', undefined);
		this.set('brFederalTaxIdNumberIssuingBodyErrorText', undefined);
		this.set('brFederalTaxHighestLevelOfEducationErrorText', undefined);
		this.set('brFederalTaxIsFirstJobErrorText', undefined);
		this.set('brFederalTaxHasUnionContributionErrorText', undefined);
		this.set('canadaFederalTaxErrorText', undefined);
	},
	finishEditingInternationalFederaltax: function() {
		this.clearErrorTextInternationalFederaltax();
		if (!this.get('isCardInSetupFlow')) {
			this.set('isEditingInternationalTax', false);
		}
	},
	cancelEditingInternationalTax: function() {
		this.clearErrorTextInternationalFederaltax();
		if (!this.get('isCardInSetupFlow')) {
			this.set('isEditingInternationalTax', false);
		}
		if (this.get('employee.isDirty')) {
			this.get('employee').rollback();
		}
		if (this.get('employee.sgFederalTax.isDirty')) {
			this.get('employee.sgFederalTax').rollback();
		}
		if (this.get('employee.gbFederalTax.isDirty')) {
			this.get('employee.gbFederalTax').rollback();
		}
		if (this.get('employee.deFederalTax.isDirty')) {
			this.get('employee.deFederalTax').rollback();
		}
		if (this.get('employee.auFederalTax.isDirty')) {
			this.get('employee.auFederalTax').rollback();
		}
		if (this.get('employee.caFederalTax.isDirty')) {
			this.get('employee.caFederalTax').rollback();
		}
		if (this.get('employee.currentCanadaStateTax.isDirty')) {
			this.get('employee.currentCanadaStateTax').rollback();
		}
		if (this.get('employee.frFederalTax.isDirty')) {
			this.get('employee.frFederalTax').rollback();
		}
		if (this.get('employee.brFederalTax.isDirty')) {
			this.get('employee.brFederalTax').rollback();
		}
		return Ember.RSVP.resolve();
	},
	startEditingInternationalTax: function() {
		this.set('isEditingInternationalTax', true);
	},
	saveInternationalTax: function() {
		this.clearErrorTextInternationalFederaltax();
		if (!this.get('signature')) {
			this.set('signatureErrorText', "Please provide your signature");
			return Ember.RSVP.resolve();
		}
		if (this.get('employee.country') === 'DE' && !this.get('employee.currentEligibility.taxID')) {
			this.set('taxIdErrorText', "Please provide your Tax Identification Number (Steuerliche Identifikationsnummer)");
			return Ember.RSVP.resolve();
		}

		if (this.get('employee.country') === 'AU' && !(this.get('employee.currentEligibility.taxID') || this.get('employee.auFederalTax.reasonForNoTaxID'))) {
			this.set('taxIdErrorText', "Please provide your Tax File Number (TFN).");
			return Ember.RSVP.resolve();
		}
		if (this.get('employee.country') === 'SG' && !this.get('employee.sgFederalTax.race')) {
			this.set('singaporeRaceSelectChoiceErrorText', "Please select your race.");
			return Ember.RSVP.resolve();
		}
		if (this.get('employee.country') === 'FR' && !this.get('employee.frFederalTax.numberOfDependents')) {
			this.set('frFederalTaxNumberOfDependentsErrorText', "Please provide number of dependents.");
			return Ember.RSVP.resolve();
		}
		if (this.get('employee.country') === 'BR' && !(this.get('employee.brFederalTax.workingBookletNumber') && this.get('employee.brFederalTax.workingBookletSeries') && this.get('employee.brFederalTax.workingBookletUF') && this.get('employee.brFederalTax.workingBookletIssueDate') && this.get('employee.brFederalTax.idNumber') && this.get('employee.brFederalTax.idNumberIssuingBody') && this.get('employee.brFederalTax.highestLevelOfEducation') && this.get('employee.brFederalTax.hasDependents') && this.get('employee.brFederalTax.isFirstJob') && this.get('employee.brFederalTax.hasUnionContribution'))) {
			var hasError = false;
			if (!this.get('employee.brFederalTax.workingBookletNumber')) {
				this.set('brFederalTaxWorkingBookletNumberErrorText', "Please provide your working booklet number.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.workingBookletSeries')) {
				this.set('brFederalTaxWorkingBookletSeriesErrorText', "Please provide your working booklet series.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.workingBookletUF')) {
				this.set('brFederalTaxWorkingBookletUFErrorText', "Please select your working booklet UF.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.workingBookletIssueDate')) {
				this.set('brFederalTaxWorkingBookletIssueDateErrorText', "Please provide your working booklet issue date.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.idNumber')) {
				this.set('brFederalTaxIdNumberErrorText', "Please provide your ID number.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.idNumberIssuingBody')) {
				this.set('brFederalTaxIdNumberIssuingBodyErrorText', "Please provide your ID number issuing body.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.highestLevelOfEducation')) {
				this.set('brFederalTaxHighestLevelOfEducationErrorText', "Please provide your highest level of education.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.hasDependents')) {
				this.set('brFederalTaxHasDependentsErrorText', "Please choose whether you have dependents.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.isFirstJob')) {
				this.set('brFederalTaxIsFirstJobErrorText', "Please choose whether this is your first job.");
				hasError = true;
			}
			if (!this.get('employee.brFederalTax.hasUnionContribution')) {
				this.set('brFederalTaxHasUnionContributionErrorText', "Please choose whether you has union contribution.");
				hasError = true;
			}
			if (hasError) {
				return Ember.RSVP.resolve();
			}
		}

		if (this.get('employee.country') === 'CA') {
			var missingFederalInfo = !(this.get('employee.caFederalTax.alreadyClaimedCredit') !== null || (this.get('employee.caFederalTax.basicPersonalAmount') !== null && this.get('employee.caFederalTax.totalClaimAmount') !== null ) || (this.get('employee.caFederalTax.isNonResident') !== null && this.get('employee.caFederalTax.isNonResident') === false));
			var missingStateInfo = !this.get('employee.currentCanadaStateTax') || !(this.get('employee.currentCanadaStateTax.alreadyClaimedCredit') !== null || (this.get('employee.currentCanadaStateTax.basicPersonalAmount') !== null && this.get('employee.currentCanadaStateTax.totalClaimAmount') !== null) || (this.get('employee.caFederalTax.isNonResident') !== null && this.get('employee.caFederalTax.isNonResident') === false));
			if (missingFederalInfo || missingStateInfo) {
				this.set('canadaFederalTaxErrorText', 'Please complete all required fields. Scroll up for more details.');
				return Ember.RSVP.resolve();
			}
		}

		return this.get('employee').saveWithChildren().then(function() {
			this.finishEditingInternationalFederaltax();
			return Ember.RSVP.resolve();
		}.bind(this));
	},
	deHasNoChurchAffiliationGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.deFederalTax.hasNoChurchAffiliation', value == 'yes');
		}
		var has = this.get('employee.deFederalTax.hasNoChurchAffiliation');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.deFederalTax.hasNoChurchAffiliation'),
	caNonResident: function() {
		if (this.get('employee.caFederalTax.permanentResidence') != null && this.get('employee.caFederalTax.permanentResidence') != 'CA') {
			return true;
		} else {
			return false;
		}
	}.property('employee.caFederalTax.permanentResidence'),
	caResident: function() {
		if (this.get('employee.caFederalTax.permanentResidence') != null && this.get('employee.caFederalTax.permanentResidence') == 'CA') {
			return true;
		} else {
			return false;
		}
	}.property('employee.caFederalTax.permanentResidence'),
	caIsNonResidentGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.caFederalTax.isNonResident', value === null ? null : value == 'yes');
		}
		var has = this.get('employee.caFederalTax.isNonResident');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.caFederalTax.isNonResident'),
	hidePersonalTaxCredits: Ember.computed.equal('employee.caFederalTax.isNonResident', false),
	alreadyClaimedCredit: Ember.computed.and('employee.caFederalTax.alreadyClaimedCredit', 'employee.currentCanadaStateTax.alreadyClaimedCredit'),
	signature: function(_key, value) {
		var key = 'employee.currentFederalTaxIntl.employeeSignature.signature';
		if (arguments.length > 1) {
			this.set(key, value);
		}
		return this.get(key);
	}.property('employee.country', 'employee.currentFederalTaxIntl.employeeSignature.signature'),
	signatureName: function(_key, value) {
		var key = 'employee.currentFederalTaxIntl.employeeSignature.signatureName';
		if (arguments.length > 1) {
			this.set(key, value);
		}
		return this.get(key);
	}.property('employee.country', 'employee.currentFederalTaxIntl.employeeSignature.signatureName'),
	hasStateTax: function() {
		return COUNTRIES_WITH_STATE_TAX.indexOf(this.get('employee.country')) != -1;
	}.property('employee.country'),
	auIsResidentGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.isResident', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.isResident');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.isResident'),
	auClaimTaxFreeThresholdGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.claimTaxFreeThreshold', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.claimTaxFreeThreshold');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.claimTaxFreeThreshold'),
	auClaimSeniorTaxOffsetGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.claimSeniorTaxOffset', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.claimSeniorTaxOffset');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.claimSeniorTaxOffset'),
	auClaimTaxOffsetGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.claimTaxOffset', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.claimTaxOffset');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.claimTaxOffset'),
	auHasHelpDebtGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.hasHelpDebt', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.hasHelpDebt');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.hasHelpDebt'),
	auHasFinancialSupplementDebtGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.hasFinancialSupplementDebt', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.hasFinancialSupplementDebt');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.hasFinancialSupplementDebt'),
	auAdditionalWithholdingGroup: function(key, value) {
		if (arguments.length > 1) {
			this.set('employee.auFederalTax.additionalWithholding', value == 'yes');
		}
		var has = this.get('employee.auFederalTax.additionalWithholding');
		if (has === true) {
			return 'yes';
		}
		else if (has === false) {
			return 'no';
		}
		return null;
	}.property('employee.auFederalTax.additionalWithholding'),
	hasTaxFields: function() {
		return COUNTRIES_WITH_NO_TAX_FIELDS.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	brDoesHaveUnionContribution: function() {
		return !!this.get('employee.brFederalTax.hasUnionContribution');
	}.property('employee.brFederalTax.hasUnionContribution'),
	hideResetNonResidentTax: Ember.computed.equal('caIsNonResidentGroup', null),
	actions: {
		resetCaIsNonResidentGroup: function() {
			this.set('caIsNonResidentGroup', null);
		}
	}
});

var COUNTRIES_WITH_NO_SWIFT_CODE = [];
//SG and HK use SWIFT whereas NL uses IBAN
var COUNTRIES_WITH_NO_ROUTING_NUMBER = [
	'AD', 'AL', 'AM', 'AR', 'AT', 'AX', 'BA', 'BE', 'BG', 'BO', 'BY', 'BZ', 'CH', 'CL', 'CN', 'CO', 'CR', 'CY', 'CZ', 'DE', 'DK',
	'DO', 'EC', 'EE', 'EG', 'ES', 'FI', 'FO', 'FR', 'GG', 'GI', 'GR', 'HK', 'HN', 'HR', 'HT', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'JP',
	'KE', 'KR', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK', 'MT', 'MX', 'MY', 'NG', 'NL', 'NO', 'NZ', 'PE', 'PH', 'PK', 'PL', 'PT',
	'RO', 'RS', 'RU', 'SE', 'SG', 'SI', 'SJ', 'SK', 'LK', 'SM', 'SV', 'TH', 'TR', 'TW', 'UA', 'UK', 'UY', 'VA', 'VN', 'XK', 'ZA'
];
var COUNTRIES_WITH_IBAN_ROUTING_LABEL = [
	'AD', 'AL', 'AT', 'AX', 'BA', 'BE', 'BG', 'BY', 'CH', 'CR', 'CY', 'CZ', 'DE', 'DK', 'DO', 'EE', 'ES', 'FI', 'FO',
	'FR', 'GG', 'GI', 'GR', 'HR', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK',
	'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RS', 'SE', 'SI', 'SJ', 'SK', 'SM', 'TR', 'UA', 'UK', 'VA', 'XK',
];
var COUNTRIES_WITH_NO_ADDITIONAL_ROUTING_NUMBER = ['AM', 'AU', 'BR', 'BO', 'BZ', 'CA', 'CL', 'CN', 'CO', 'EC', 'EG', 'GB', 'HK', 'HN', 'HT', 'JP', 'KE', 'KR', 'MY', 'NG', 'NZ', 'PE', 'PH', 'PK', 'SG', 'LK', 'SV', 'TH', 'TW', 'UY', 'VN', 'ZA'];
var COUNTRIES_WITH_ADDITIONAL_INFO = ['BR', 'FR', 'IE', 'PK', 'LK'];
var COUNTRIES_WITH_PHONE_NUMBER_REQUIRED = ['BR', 'BY', 'PH'];
var COUNTRIES_WITH_NO_ACCOUNT_NUMBER = ['AR'];

zen._InternationalEmployeeBankMixin = Ember.Mixin.create({
	routingNumberLabel: function() {
		var country = this.get('employee.country');
		if (country == 'CA') {
			return 'Routing/Transit Number';
		} else if (country == 'AU') {
			return 'Bank-State-Branch (BSB) Number';
		} else if (country == 'DE') {
			return 'Bankleitzahl (BLZ)';
		} else if (country == 'IN') {
			return 'MICR Code';
		} else if (country == 'GB' || country == 'IE') {
			return 'Sort Code';
		} else if (country == 'FR') {
			return 'RIB Code';
		} else if (country == 'BR') {
			return 'Bank Code';
		}
		return 'Routing Number';
	}.property('employee.country'),
	additionalRoutingNumberLabel: function() {
		var country = this.get('employee.country');
		if (country == 'IN') {
			return 'Indian Financial System Code (IFSC)';
		} else if (COUNTRIES_WITH_IBAN_ROUTING_LABEL.indexOf(country) != -1) {
			return 'International Bank Account Number (IBAN)';
		} else if (country == 'MX') {
			return 'Clave Bancaria Esandarizada (CLABE)';
		} else if (country == 'AR') {
			return 'Clave Bancaria Uniforme (CBU)';
		} else if (country == 'RU') {
			return 'Bank Identifier Code (BIK)';
		}
		return 'Additional Routing Number';
	}.property('employee.country'),
	additionalInfoLabel: function() {
		var country = this.get('employee.country');
		if (country == 'FR') {
			return 'Bank Account Beneficiary Name';
		} else if (['IE', 'BR', 'PK', 'LK'].includes(country)) {
			return 'Account Name';
		}
		return '';
	}.property('employee.country'),
	requirePhoneNumber: function() {
		return COUNTRIES_WITH_PHONE_NUMBER_REQUIRED.indexOf(this.get('employee.country')) != -1;
	}.property('employee.country'),
	hasAdditionalInfo: function() {
		return COUNTRIES_WITH_ADDITIONAL_INFO.indexOf(this.get('employee.country')) != -1;
	}.property('employee.country'),
	hasRoutingNumber: function() {
		return COUNTRIES_WITH_NO_ROUTING_NUMBER.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	hasSwiftCode: function() {
		return COUNTRIES_WITH_NO_SWIFT_CODE.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	hasAdditionalRoutingNumber: function() {
		return COUNTRIES_WITH_NO_ADDITIONAL_ROUTING_NUMBER.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
	hasBankAccountNumber: function() {
		return COUNTRIES_WITH_NO_ACCOUNT_NUMBER.indexOf(this.get('employee.country')) == -1;
	}.property('employee.country'),
});

zen._SecondaryEmployeeBankMixin = Ember.Mixin.create({
	secondBank: null,
	isModelNewHire: false,
	bankErrorText: null,
	bankAccountNumberErrorText: null,
	startCurrentEditingBank: function(bank) {
		bank.set('isEditingBank', true);
	},
	createSecondBankAccount: function(activeBanksNumber) {
		if(this.get('isModelNewHire') && !this.get('employee.lessThanPayrollMaxBank')) {
			return;
		}
		if(!this.get('isModelNewHire') && !this.get('lessThanPayrollMaxBank')) {
			return;
		}
		var bank = App.store.createRecord(App.EmployeeBank);
		this.set('secondBank', bank);
		if(this.get('isModelNewHire')) {
			this.set('secondBank.employee', this.get('employee'));
		}
		else {
			this.set('secondBank.employee', this.get('model'));
		}
		this.set('secondBank.isEditingBank', true);
		this.set('secondBank.isActive', true);
		this.set('secondBank.country', this.get('employee.country'));
		this.set('secondBank.isSalaryAccount', true);
		this.set('secondBank.isNewAccount', true);
		if (activeBanksNumber > 0) {
			// if primaryAcc exists, we only need to add secondary bank
			this.set('secondBank.priority', activeBanksNumber - 1);
			this.set('secondBank.isPrimaryAccount', false);
		} else {
			this.set('secondBank.priority', null);
			this.set('secondBank.isPrimaryAccount', true);
		}
	},
	confirmBankAccountNumber: function(bank) {
		if (!(bank.get('newAccountNickname') && bank.get('newBankRoutingNumber') && bank.get('newBankAccountNumber') && bank.get('newBankAccountType'))) {
			this.set('bankErrorText', "Please complete all required fields");
			return;
		}

		var maxChecking = this.get('employee.payrollMaxBankNumberChecking');
		var maxSavings = this.get('employee.payrollMaxBankNumberSavings');
		var numChecking = this.get('employee.banksNumberChecking');
		var numSavings = this.get('employee.banksNumberSavings');

		var newCheckingCount = numChecking;
		var newSavingsCount = numSavings;

		if (bank.get('bankAccountType') !=  bank.get('newBankAccountType')) {
			var deltaChecking = 0;
			var deltaSavings = 0;
			if (bank.get('bankAccountType') != 'C' && bank.get('bankAccountType') != 'S') {
				deltaChecking = (bank.get('newBankAccountType') == 'C' ? 1 : 0);
				deltaSavings = (bank.get('newBankAccountType') == 'S' ? 1 : 0);
			} else {
				deltaChecking = (bank.get('newBankAccountType') == 'C' ? 1 : -1);
				deltaSavings = (bank.get('newBankAccountType') == 'S' ? 1 : -1);
			}
			newCheckingCount +=  deltaChecking;
			newSavingsCount +=  deltaSavings;
		}

		if(newCheckingCount > maxChecking || newSavingsCount > maxSavings) {
			var checkingOrSavings = (newCheckingCount > maxChecking) ? "checking" : "savings";
			this.set('bankErrorText', "Exceeded maximum allowed number of " + checkingOrSavings + " accounts");
			return;
		}

		// All good at this point, all validations have passed, proceed to update the bank account.

		bank.set('accountNickname', bank.get('newAccountNickname'));
		bank.set('bankAccountNumber', bank.get('newBankAccountNumber'));
		bank.set('bankAccountType', bank.get('newBankAccountType'));
		bank.set('bankRoutingNumber', bank.get('newBankRoutingNumber'));

		bank.set('isEditingBank', false);
		bank.set('isConfirmingBankAccountNumber', true);
		bank.set('newBankAccountNumber', null);
		this.set('bankErrorText', "");
	},
	cancelConfirmBankAccountNumber: function(bank) {
		bank.set('isConfirmingBankAccountNumber', false);
		bank.set('isEditingBank', true);
		bank.set('bankAccountNumber', null);
	},
	saveNewAccount: function(bank) {
		if(bank.get('newBankAccountNumber') != bank.get('bankAccountNumber')) {
			this.set('bankAccountNumberErrorText', "Bank account number does not match");
			return;
		}

		if(this.get('isModelNewHire')) {
			// set new bank as primary bank if no primaryBank exists; otherwise set false.
			var primaryBank = this.get('employee.primaryBank');
			if (!primaryBank || bank.get('id') != primaryBank.get('id')) {
				bank.set('isPrimaryAccount', !this.get('employee.primaryBank'));
			}
		}
		else {
			var primaryBank = this.get('primaryBank');
			if (!primaryBank || bank.get('id') != primaryBank.get('id')) {
				bank.set('isPrimaryAccount', !this.get('primaryBank'));
			}
		}
		bank.set('isNewAccount', false);
		bank.set('isConfirmingBankAccountNumber', false);
		this.set('bankAccountNumberErrorText', "");
		return bank.save().then(function() {
			if (bank.get('employee.isDirty')) {
				return bank.get('employee').save();
			}
		}.bind(this));
	},

	saveAndConfirmNewAccount: function(bank) {
		this.send('confirmBankAccountNumber', bank);

		// if the confirmBankAccountNumber action failed because of the user input, we don't need to save anything
		if (this.get('bankErrorText')) {
			return;
		}

		if(this.get('isModelNewHire')) {
			// set new bank as primary bank if no primaryBank exists; otherwise set false.
			var primaryBank = this.get('employee.primaryBank');
			if (!primaryBank || bank.get('id') != primaryBank.get('id')) {
				bank.set('isPrimaryAccount', !this.get('employee.primaryBank'));
			}
		}
		else {
			var primaryBank = this.get('primaryBank');
			if (!primaryBank || bank.get('id') != primaryBank.get('id')) {
				bank.set('isPrimaryAccount', !this.get('primaryBank'));
			}
		}
		bank.set('isNewAccount', false);
		bank.set('isConfirmingBankAccountNumber', false);
		this.set('bankAccountNumberErrorText', "");
		bank.save().then(function() {
			if (bank.get('employee.isDirty')) {
				bank.get('employee').save();
			}
			this.send('save');
		}.bind(this));
	},

	cancelEditingBank: function(bank) {
		this.set('bankErrorText', "");
		bank.set('isEditingBank', false);
		if(!bank.get('isPrimaryAccount') && bank.get('isNewAccount')) {
			bank.deleteRecord();
		}
	},
	deleteBank: function(bank) {
		bank.set('isRemovingBank', true);
	},
	unsetDialogBoxError: function(bank) {
		bank.set('hasBankDialogBoxError', false);
		bank.set('bankDialogBoxErrorText', '');
	},
	confirmDeleteBank: function(bank) {
		bank.set('isActive', false);
		bank.set('isRemovingBank', false);
		this.set('errorText', "");
		if(bank.get('isPrimaryAccount')) {
			var bankList = [];
			if(this.get('isModelNewHire')) {
				bankList = this.get('employee.activeBanks');
			}
			else {
				bankList = this.get('activeBanks');
			}
			if(bankList.length > 0) {
				var lastBank = bankList[bankList.length - 1];
				lastBank.set('isPrimaryAccount', true);
				lastBank.set('amountPerPaycheck', null);
				lastBank.set('percentagePerPaycheck', null);
				lastBank.save();
			}
			bank.set('isPrimaryAccount', false);
		}
		bank.save();
	},
	cancelDeleteBank: function(bank) {
		bank.set('isRemovingBank', false);
		this.set('errorText', "");
	},
});

var COUNTRIES_SUPPORTED = [
	'AD', 'AL', 'AR', 'AT', 'AU', 'AX', 'BA', 'BO', 'BE', 'BG', 'BR', 'BY', 'CA', 'CH', 'CL', 'CN', 'CO', 'CR', 'CY', 'CZ', 'DE',
	'DK', 'DO', 'EE', 'ES', 'FI', 'FO', 'FR', 'GB', 'GG', 'GI', 'GR', 'HK', 'HR', 'HT', 'HU', 'IE', 'IM', 'IN', 'IS', 'IT',
	'JE', 'JP', 'KE', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK', 'MT', 'MX', 'MY', 'NG', 'NL', 'NO', 'NZ','PH', 'PL', 'PT',
	'RO', 'RS', 'RU', 'SE', 'SG', 'SI', 'SJ', 'SK', 'SM', 'SV', 'TH', 'TR', 'TW', 'UA', 'US', 'VA', 'VN', 'XK',
];
var COUNTRIES_WITH_NO_POSTAL_CODE = ['HK', 'BZ'];
window.COUNTRIES_WITH_NO_STATE = zen.COUNTRIES_WITH_NO_STATE = [
	'AR', 'DE', 'DO', 'FR', 'GB', 'HT', 'IE', 'IS', 'KE', 'NL', 'RS', 'SG', 'UA', 'UY'
];

zen._AddressMixin = Ember.Mixin.create({
	stateLabel: function() {
		var country = this.get('country');
		if (country == 'CA') {
			return 'Province';
		} else if (country == 'AU') {
			return 'State/Territory';
		} else if (country == 'HK') {
			return 'Territory';
		} else if (country == 'BR') {
			return 'State/Province';
		}
		return 'State'; //Default label
		}.property('country'),
	cityLabel: function() {
		var country = this.get('country');
		if (country == 'NL') {
			return 'Town/Locality';
		} else if (country == 'HK') {
			return 'Village/Town/District';
		} else if (country == 'DE' || country == 'GB' || country == 'IN' || country == 'FR') {
			return 'Town/City';
		}
		return 'City';
		}.property('country'),
	postalCodeLabel: function() {
		var country = this.get('country');
		if (country == 'GB' || country == 'AU') {
			return 'Postcode';
		}
		if (country == 'IN') {
			return 'PIN Code';
		}
		if (country == 'US') {
			return 'Zip';
		}
		if (country == 'IE' || country == 'BR') {
			return 'Post Code/Zip Code';
		}
		if (country == 'FR') {
			return 'Post Code'; // Not sure this is needed here, almost the same with Postcode
		}
		return 'Postal Code';
	}.property('country'),
	isCountryUS: Ember.computed.equal('country', 'US'),
	isCountrySupported: function() {
		return COUNTRIES_SUPPORTED.indexOf(this.get('country')) != -1;
	}.property('country'),
	hasState: function() {
		return zen.COUNTRIES_WITH_NO_STATE.indexOf(this.get('country')) == -1;
	}.property('country'),
	hasPostalCode: function() {
		return COUNTRIES_WITH_NO_POSTAL_CODE.indexOf(this.get('country')) == -1;
	}.property('country'),
	countryHumanReadable: function() {
		return this.get('country') ? countryCodeToName(this.get('country')) : "";
	}.property('country'),
	isCountryCanada: Ember.computed.equal('country', 'CA'),
	isCountryAustralia: Ember.computed.equal('country', 'AU'),
	isCountryGermany: Ember.computed.equal('country', 'DE'),
	isCountryUnitedKingdom: Ember.computed.equal('country', 'GB'),
	isCountryHongKong: Ember.computed.equal('country', 'HK'),
	isCountryIndia: Ember.computed.equal('country', 'IN'),
	isCountryNetherlands: Ember.computed.equal('country', 'NL'),
	isCountrySingapore: Ember.computed.equal('country', 'SG'),
	isCountryIreland: Ember.computed.equal('country', 'IE'),
	isCountryFrance: Ember.computed.equal('country', 'FR'),
	isCountryBrazil: Ember.computed.equal('country', 'BR'),
});

window.getUnambiguousStateName = zen.getUnambiguousStateName = function(state) {
	var statename = zen.constants.STATE_ABBREV_TO_NAME[state];
	if (state === 'WA' || state === 'NY') {
		statename = statename.concat(' State');
	}
	return statename;
};

zen._HiringAndEmployeesAppNavBarMixin = Ember.Mixin.create({
	employeesAppProductNavElements: function() {
		var directoryName = 'Overview';
		var directoryRoute = 'employeedirectory';
		var bulkRequestLabel = 'Bulk Employee Request';
		var bulkRequestRouteName = 'onboarding.bulkemployeerequest';

		if (App.switches.isActive('new_bulk_request')) {
			bulkRequestLabel = 'Bulk Information Request';
			bulkRequestRouteName = 'bulk-request';
		}

		var listOfNavElements = [{
			label: directoryName,
			routeName: directoryRoute
		}];

		var showStaffDirectory = this.get('showStaffDirectory');
		if (showStaffDirectory === undefined) {
			showStaffDirectory = this.get('companySettings.showStaffDirectory');
		}

		if (showStaffDirectory || this.get('isAdminAll')) {
			listOfNavElements = listOfNavElements.concat(
				{
					label: 'Org Chart',
					routeName: 'employeeorgchart'
				}
			);
		}

		if (this.get('managerAndNotAdminWithHirePermissions') || (this.get('isAdmin') && !this.get('isReadOnlyOrRestrictSensitiveAdmin'))) {
			listOfNavElements = listOfNavElements.concat(
				{
					label: 'Calendar Feed',
					routeName: 'onboarding.calendarfeedsetup'
				}
			);
			if (this.get('isAdmin') && !this.get('isReadOnlyOrRestrictSensitiveAdmin')) {
				listOfNavElements = listOfNavElements.concat(
					{
						label: bulkRequestLabel,
						routeName: bulkRequestRouteName
					},
					{
						label: 'Approvers Settings',
						routeName: 'approvers.editpermissions'
					}
				);
			}
		}
		return listOfNavElements;
	}.property(),

	hiringAppProductNavElements: function() {
		var listOfNavElements = [];

		//Add all your nav elements
		listOfNavElements.push({
				label: 'Overview',
				routeName: 'onboarding.overview',
		});

		// Managers, location only and department only admins cannot access these pages
		if (!this.get('managerAndNotAdminWithHirePermissions') && !this.get('isAdminDepartment') && !this.get('isAdminLocation')) {
			listOfNavElements = listOfNavElements.concat(
				{
					label: 'Settings',
					routeName: 'onboarding.settings',
				},
				{
					label: 'Custom Fields',
					routeName: 'onboarding.customfield',
				}
			);
		}

		return listOfNavElements;
	}.property(),

});

zen._PayrollToggleMixin = Ember.Mixin.create({
	numOfQuestions: 11,
	showQuestion1: false,
	showQuestion2: false,
	showQuestion3: false,
	showQuestion4: false,
	showQuestion5: false,
	showQuestion6: false,
	showQuestion7: false,
	showQuestion8: false,
	showQuestion9: false,
	showQuestion10: false,
	showQuestion11: false,
	expand: function() {
		for (var i = 1; i < this.get('numOfQuestions') + 1; i++) {
			var question = "showQuestion"+String(i);
			this.set(question, true);
		}
	},
	collapse: function() {
		for (var i = 1; i < this.get('numOfQuestions') + 1; i++) {
			var question = "showQuestion"+String(i);
			this.set(question, false);
		}
	},
	toggle: function(item) {
		this.set(item, !this.get(item));
	}
});

zen._OnboardToPayrollMixin = Ember.Mixin.create({
	onboardToPayroll: function(employee) {
		return employee.get('newHires').then(function(newHires) {
			var hire = newHires.findProperty('isInProcess');
			if (!hire) {
				var hire = App.NewHire.createRecord({
					"employee": employee,
					"isDoingOfferLetter": false,
					"isDoingAgreements": false,
					"isDoingEmployeeHandbook": false,
				});
			}
			hire.setProperties({
				"isDoingOnboarding": true,
				"isDoingTax": true,
				"isDoingEligibility": true,
				"isDoingEligibilityProofUpload": true,
				"status": "ready_to_send",
			});
			return hire.save().then(function() {
				return employee.reload();
			}.bind(this));
		});
	},
});

zen._WaiverMixin = Ember.Mixin.create({
	chosenMedicalPlan: Ember.computed.any('currentEnrollment.medicalPlan', 'employee.medicalPlan'),
	chosenDentalPlan: Ember.computed.any('currentDentalEnrollment.dentalPlan', 'employee.dentalPlan'),
	chosenVisionPlan: Ember.computed.any('currentVisionEnrollment.visionPlan', 'employee.visionPlan'),

	mainObject: function() {
		if (this.get('company.offersMedical')) {
			if (this.get('declineMedicalNames')) {
				return this.get('company');
			}

			return this.get('chosenMedicalPlan');
		}

		if (this.get('company.offersDental')) {
			if (this.get('declineDentalNames')) {
				return this.get('company');
			}

			return this.get('chosenDentalPlan');
		}

		if (this.get('company.offersVision')) {
			if (this.get('declineVisionNames')) {
				return this.get('company');
			}

			return this.get('chosenVisionPlan');
		}
	}.property('declineMedicalNames', 'company', 'chosenMedicalPlan', 'declineDentalNames', 'chosenDentalPlan',
				'declineVisionNames', 'chosenVisionPlan'),

	medicalCarrier: function() {
		var carriers = this.get('healthCarriers') || [];
		var medicalCarrier = carriers.find(function(carrier) {
			return carrier.get('isMedical') && carrier.get('isPrimaryCarrier');
		});

		return medicalCarrier ? medicalCarrier.get('carrier') : null;
	}.property('healthCarriers.@each.lineOfCoverage', 'healthCarriers.@each.carrier'),

	dentalCarrier: function() {
		var carriers = this.get('healthCarriers') || [];
		var dentalCarrier = carriers.find(function(carrier){
			return carrier.get('isDental');
		});

		return dentalCarrier ? dentalCarrier.get('carrier') : null;
	}.property('healthCarriers.@each.lineOfCoverage', 'healthCarriers.@each.carrier'),

	visionCarrier: function() {
		var carriers = this.get('healthCarriers') || [];
		var visionCarrier = carriers.find(function(carrier) {
			return carrier.get('isVision');
		});

		return visionCarrier ? visionCarrier.get('carrier') : null;
	}.property('healthCarriers.@each.lineOfCoverage', 'healthCarriers.@each.carrier'),

	companyMedicalLargeGroup: function() {
		var medicalCarrier = this.get('medicalCarrier');
		return medicalCarrier ? medicalCarrier.get('companyHealthEnrollment.largeGroupEnrollment') : false;
	}.property('medicalCarrier.companyHealthEnrollment.largeGroupEnrollment'),

	companyDentalLargeGroup: function() {
		var dentalCarrier = this.get('dentalCarrier');
		return dentalCarrier ? dentalCarrier.get('companyHealthEnrollment.largeGroupEnrollment') : false;
	}.property('dentalCarrier.companyHealthEnrollment.largeGroupEnrollment'),

	companyVisionLargeGroup: function() {
		var visionCarrier = this.get('visionCarrier');
		return visionCarrier ? visionCarrier.get('companyHealthEnrollment.largeGroupEnrollment') : false;
	}.property('visionCarrier.companyHealthEnrollment.largeGroupEnrollment'),

	openFilePickerWaiver: function() {
		filepicker.setKey(FILEPICKER_KEY);
		var waiver = this.get('waiver');
		filepicker.pickAndStore({
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
				services:['COMPUTER', 'FACEBOOK', 'GMAIL', 'DROPBOX']
			},
			{location:"S3"},
			function(FPFile){
				waiver.set('idCardUrl', FPFile[0].url);
			},
			function(FPError){
				// pass
			}
		);
	}
});

var money_equals = function(a,b,roundingAllowance) { return Math.abs(parseFloat(a || "0") - parseFloat(b || "0")) < (roundingAllowance || 0.01); };


/**********************
Ember property decorators
***********************/

Ember.computed.prefix = function(prefix, key) {
	return Ember.computed(function() {
		return prefix + this.get(key);
	}).property(key);
};

Ember.computed.setDifference = function(arrayOneKey, arrayTwoKey) {
	// O(n) set difference code
	// inputs arrays, outputs the set of the intersection of those arrays
	// may not work with arrays returned in afterModel in routes,
	// where objects are stored in .content
	return Ember.computed(function() {
		var arrayOne = this.get(arrayOneKey) || [];
		var arrayTwo = this.get(arrayTwoKey) || [];

		var a={}, diff=[];

		for(var i=0;i<arrayOne.length;i++) {
			a[arrayOne[i]]=[arrayOne[i], true];
		}
		for(var i=0;i<arrayTwo.length;i++) {
			if(a[arrayTwo[i]]) {
				delete a[arrayTwo[i]];
			}
		}
		for(var k in a) {
			diff.push(a[k][0]);
		}

		return diff;

	}).property(arrayOneKey + '.@each', arrayTwoKey + '.@each');
};

Ember.computed.arraysEqual = function(arrayOneKey, arrayTwoKey) {
	return Ember.computed(function() {
		var arrayOne = this.get(arrayOneKey) || [];
		var arrayTwo = this.get(arrayTwoKey) || [];

		if (arrayOne.length != arrayTwo.length) {
			return false;
		}

		return !arrayOne.some(function(element, index){
			return element != arrayTwo[index];
		});

	}).property(arrayOneKey + '.@each', arrayTwoKey + '.@each');
};

//When, start, and end must all be moment.js objects.
function dateIsInRange(when, start, end) {
		when = moment(when);
		start = moment(start);
		end = moment(end);
		return (when.isSame(start) || when.isSame(end) || (when.isBefore(end) && when.isAfter(start)));
}

Ember.computed.collect = function() {
	var args = [];
	for (var i = 0; i < arguments.length; i += 1) {
		args.push(arguments[i]);
	}

	var computed = Ember.computed(function() {
		var ctx = this;

		return args.map(function(key) {
			return ctx.get(key);
		});
	});

	computed.property.apply(computed, args);

	return computed;
};

Ember.computed.sum = function(arrayKey) {
	return Ember.computed(function() {
		var sum = 0;
		var array = this.get(arrayKey) || [];

		array.forEach(function(obj) {
			sum += Number(obj);
		});

		return sum;
	}).property(arrayKey + ".[]");
};

Ember.computed.sortByProperty = function(arrKey, propKey, sortAscending) {
	sortAscending = typeof sortAscending !== 'undefined' ? sortAscending : true;
	return Ember.computed(function() {
		 return Ember.ArrayProxy.extend(Ember.SortableMixin).create({
			sortProperties: [propKey],
			sortAscending: sortAscending,
			content: this.get(arrKey),
		});
	}).property(arrKey + ".@each." + propKey);
};

window.valueAsStringToIntOrUndefined = zen.valueAsStringToIntOrUndefined = function(valueString) {
	var value = Ember.typeOf(valueString) === 'string' ? parseInt(valueString) : undefined;
	return isNaN(value) ? undefined : value;
};

// There are three arguments for situations like oop, minOOP, maxOOP.
// The default one argument will work for situations like deductible, minDeductible, maxDeductible.
Ember.computed.rangeFilterProperty = function(valueKey, minKey, maxKey, valueTransform) {
	minKey = minKey || 'min' + Ember.String.capitalize(valueKey);
	maxKey = maxKey || 'max' + Ember.String.capitalize(valueKey);
	return Ember.computed(function() {
		var minString = this.get(minKey);
		var maxString = this.get(maxKey);

		var minVal = zen.valueAsStringToIntOrUndefined(minString);
		var maxVal = zen.valueAsStringToIntOrUndefined(maxString);

		// No filter is applied.
		if (minVal === undefined && maxVal === undefined) {
			return undefined;
		}

		return [
			valueKey,
			function(key, value) {
				if (Ember.typeOf(valueTransform) === 'function') {
					value = valueTransform.call(null, value);
				}
				if (minVal !== undefined && value < minVal) {
					return false;
				}
				if (maxVal !== undefined && value > maxVal) {
					return false;
				}
				return true;
			},
		];
	}).property(minKey, maxKey);
};

Ember.computed.booleanFilterProperty = function(valueKey, filteringValueKey, invert) {
	filteringValueKey = filteringValueKey || valueKey;
	return Ember.computed(function() {
		var filteringValue = this.get(filteringValueKey);
		// If filteringValue is null or undefined, the filter is unset, so return true.
		if (filteringValue == null) {
			return undefined;
		}

		return [
			valueKey,
			function(key, value) {
				return invert ? value !== filteringValue : value === filteringValue;
			},
		];
	}).property(filteringValueKey);
};

Ember.computed.lexSort = function(key, sortingKey) {
	return Ember.computed(function() {
		return this.get(key).toArray().sort(function(a, b) {
			return (a.get(sortingKey) || '').toLowerCase() >= (b.get(sortingKey) || '').toLowerCase();
		});
	}).property(key + '.@each.' + sortingKey);
};

// Warning: May only work in views and components.
Ember.computed.hasChildView = function(tagName) {
	return Ember.computed(function() {
		return this.get('childViews').isAny('tagName', tagName);
	}).property('childViews.@each.' + tagName);
};

// like oneWay alias but you want to be able to set it
// e.g. the aliased value is the default but it can be shadowed
Ember.computed.halfWay = function(attribute, digits) {
	var overridePath = '_' + attribute.replace('.', '_');
	return function(key, value){
		//setter
		if (arguments.length > 1) {
			this.set(overridePath, value);
			return value;
		}

		//getter
		var overrideValue = this.get(overridePath);
		if (overrideValue !== undefined) {
			return overrideValue;
		}

		return this.get(attribute);
	}.property(attribute);
};

/* Use this like {{maybe employee.first_name "?"}} // yields employee.first_name unless null or undefined, else ? */

/* Same as fn above 'maybe', except allows empty string "" */
App.MaybeStrHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var str = params[0];
	var noneOutput = params[1];
	if (typeof(str) === "undefined" || str === null || str.length === 0) {
		return noneOutput || "N/A";
	}
	return str;
});

App.UpperCaseFirstLetterHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var str = params[0];
	return str.charAt(0).toUpperCase() + str.substring(1);
});

/**
 * Use this like {{pretty-boolean anyBoolean}} or {{pretty-boolean anyBoolean true="Yes" false="No" default="-"}}
 * returns 0.1 -> 10% or 10 -> $10
 */
App.PrettyBooleanHelper = Ember.HTMLBars.makeBoundHelper(function(params, hash) {
	var value = params[0];
	var trueValue = hash.true || "Yes";
	var falseValue = hash.false || "No";
	var defaultValue = hash.default || "";
	if (value === undefined || value === null) {
		return defaultValue;
	}
	return value ? trueValue : falseValue;
});

window.unprettifyDecimal = zen.unprettifyDecimal = function(number, maxDigits, decimalPlaces) {
	decimalPlaces = decimalPlaces || 2;
	maxDigits = maxDigits || 10;

	number = number.toString().replace(/[^\d.]/g, '');

	var parts = number.split('.');
	var lastIndex = Math.max(1, parts.length - 1);
	var integerPart = parts.slice(0, lastIndex).join('');
	var fractionPart = parts[lastIndex] || '';
	integerPart = integerPart.slice(0, maxDigits - decimalPlaces);
	fractionPart = fractionPart.slice(0, decimalPlaces);

	number = integerPart + '.' + fractionPart;
	if (number[number.length - 1] == '.' && parts.length == 1) {
		number = number.slice(0, number.length - 1);
	}

	return number;
};

function prettyDateTime(date) {
	var defaultOutputFormat = "MMMM Do, YYYY HH:mm:ss";
	return zen.prettyDate(date, defaultOutputFormat);
}

function prettyDataSize(number, precision, defaultValue) {
	var defaultValue = defaultValue || '-';
	var precision = precision || 2;
	if (number === undefined || number === null || number < 0) {
		return defaultValue;
	}

	if (number>=1073741824) {
		number=(number/1073741824).toFixed(precision)+' GB';
	} else if (number>=1048576) {
		number=(number/1048576).toFixed(precision)+' MB';
	} else if (number>=1024) {
		number=(number/1024).toFixed(precision)+' KB';
	} else {
		number=number+' B';
	}
	return number;
}

App.PrettyDataSizeHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var number = params[0];
	return prettyDataSize(number);
});

/**
 * Use this to transform into MM/DD/YYYY date format
**/
function enrollmentDateFormat(date) {
	var defaultOutputFormat = "MM/DD/YYYY";
	return zen.prettyDate(date, defaultOutputFormat);
}


App.PrettyDateTimeHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var date = params[0];
	return prettyDateTime(date);
});

App.EnrollmentDateFormatHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var date = params[0];
	return enrollmentDateFormat(date);
});

App.TableDateHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var date = params[0];
	return zen.tableDate(date);
});




Ember.computed.toFixed = function(key, digits) {
	return Ember.computed(function() {
		return this.get(key).toFixed(digits);
	}).property(key);
};

Ember.computed.selectCompManagerByLevel = function (levelVar, level, type) {
	return Ember.computed(function(key, value) {
		if (arguments.length > 1) {
			this.set(levelVar, value);
			this.filterByLevel(level, type, value);
		}
		return this.get(levelVar);
	}).property(levelVar);
};

zen.allEmployeesSubordinateEnricher = function(emps) {
	var subordinatesMap = {
		'0': [],
	};
	var reportToEmployeeMap = {};
	emps.forEach(function(e) {
		var directManagerId = e.get('reportToEmployee.id') || '0';
		if (!subordinatesMap[directManagerId]) {
			subordinatesMap[directManagerId] = [];
		}
		if (e.get('id') != e.get('reportToEmployee.id')) {
			reportToEmployeeMap[e.get('id')] = e.get('reportToEmployee.id');
			subordinatesMap[directManagerId].push(e.get('id'));
		} else {
			reportToEmployeeMap[e.get('id')] = undefined;
			subordinatesMap['0'].push(e.get('id'));
		}
	});

	var numberOfAllSubordinatesMap = {};

	var dfsAllSubordinate = function(employee) {
		if(numberOfAllSubordinatesMap[employee]) {
			return numberOfAllSubordinatesMap[employee];
		}
		if(!subordinatesMap[employee]) {
			numberOfAllSubordinatesMap[employee] = 0;
			return 0;
		}
		numberOfAllSubordinatesMap[employee] = subordinatesMap[employee].get('length');
		subordinatesMap[employee].forEach(function(sub) {
			numberOfAllSubordinatesMap[employee] += dfsAllSubordinate(sub);
		});
		return numberOfAllSubordinatesMap[employee];
	};
	emps.forEach(function(e) {
		dfsAllSubordinate(e.get('id'));
	});

	return emps.map(function(e) {
		var subIds = subordinatesMap[e.get('id')];
		e.set('subordinates', subIds);
		e.set('numberOfAllSubordinates', numberOfAllSubordinatesMap[e.get('id')]);

		return e;
	});
};

window.allEmployeesEnricher = zen.allEmployeesEnricher = function(emps, isAdmin) {
	var subordinatesMap = {
		'0': [],
	};

	var j = 0;
	var levelMap = {};
	var dfsOrderMap = {};
	var teamLeaderMap = {};

	var reportToEmployeeMap = {};
	emps.forEach(function(e) {
		var directManagerId = e.get('reportToEmployee.id') || '0';
		if (!subordinatesMap[directManagerId]) {
			subordinatesMap[directManagerId] = [];
		}
		if (e.get('id') != e.get('reportToEmployee.id')) {
			reportToEmployeeMap[e.get('id')] = e.get('reportToEmployee.id');
			subordinatesMap[directManagerId].push(e.get('id'));
		} else {
			reportToEmployeeMap[e.get('id')] = undefined;
			subordinatesMap['0'].push(e.get('id'));
		}
	});


	(isAdmin ? subordinatesMap['0'] : [emps.findProperty('isRequester').get('id')]).forEach(function(top) {

		j=j+1;
		dfsOrderMap[top]= j;
		levelMap[top] = 1;

		if (!subordinatesMap[top]) {
			return;
		}

		teamLeaderMap[top] = top;
		subordinatesMap[top].forEach(function(tl) {

			j=j+1;
			dfsOrderMap[tl]= j;
			teamLeaderMap[tl] = top;

			var subs = subordinatesMap[tl] ? subordinatesMap[tl].slice(0) : [];
			levelMap[tl] = levelMap[reportToEmployeeMap[tl]] + 1;

			for(var i = 0; subs.length > 0 && i < 5000; i++) {
				var curr = subs.pop();

				j=j+1;
				dfsOrderMap[curr]= j;
				levelMap[curr] = levelMap[reportToEmployeeMap[curr]] + 1;

				teamLeaderMap[curr] = tl;
				if (subordinatesMap[curr]) {
					subs = subs.concat(subordinatesMap[curr].slice(0));
				}
			}
		});
	});

	var data = emps.map(function(e) {
		var subIds = subordinatesMap[e.get('id')];
		var tlId = teamLeaderMap[e.get('id')];
		e.set('dfsOrder', dfsOrderMap[e.get('id')]);
		e.set('levelCss', 'margin-left: ' + ((levelMap[e.get('id')]-1)*48 + 2) + 'px');
		e.set('level', levelMap[e.get('id')]);
		e.set('subordinates', subIds);
		e.set('teamLeaderId', tlId);
		e.set('teamLeaderName', (!tlId || (tlId == e.get('id') && !subIds)) ? (isAdmin ? 'Other Employees' : 'Other Subordinates') : emps.findProperty('id', tlId).get('fullName'));

		return e;
	});
	return data;
};

var flatten = function(arrays) {
	return arrays.reduce(function(accum, array) {
		accum.pushObjects(array.toArray());
		return accum;
	}, []);
};

function createCookie(name, value, options) {
	var opts = options || {};
	var expiryDays;
	var expires;
	var cookie;
	// Old API, passing just expiryDays
	if (typeof opts != 'object') {
		expiryDays = opts;
		opts = {
			expiryDays: opts
		};

		if (opts.expiryDays) {
			var date = new Date();
			date.setTime(date.getTime() + (opts.expiryDays * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else {
			expires = "";
		}
		cookie = name + "=" + value + expires + "; path=/";
	}
	else {
		cookie = name + "=" + value + ";";
		for (var key in options) {
			if (options.hasOwnProperty(key)) {
				cookie += key + "=" + options[key] + ";";
			}
		}
	}
	document.cookie = cookie;
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
			c = c.substring(1,c.length);
		}
		if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length,c.length); }
	}
	return null;
}

function eraseCookie(name) {
	createCookie(name,"",-1);
}

window.urlForCanScrapePdf = zen.urlForCanScrapePdf = function(url) {
	var parts = url.split('/');
	// Like https://www.filepicker.io/api/file/6j0eNjrfRjuNikt3UuG2 or
	// https://secure.zenefits.com/files/35WqmQj5LrgEBJlyd24dfOBQmoQZ3FtB
	// /securefile/35WqmQj5LrgEBJlyd24dfOBQmoQZ3FtB/
	var key = parts[parts.length - 1];
	var key_type = '';
	if (parts[parts.length - 2] === 'file') {
		key_type = 'filepicker';
	} else if (parts[parts.length - 2] == 'files') {
		key_type = 's3_file';
	} else if (parts[1] == 'securefile') {
		key_type = 'securefile';
		key = parts[2];
	}
	return '/can_scrape_pdf/' + key_type + '/' + key;
};

zen._DocumentsMixin = Ember.Mixin.create(zen._BlobHelperMixin, {
	createDefaultBlob: function(id) {
		return this.createBlobByType('DEFAULT_IP_TEMPLATE_BLOB');
	},
	useCustom: function() {
		// deal with the case where it's already set custom
		if (this.get('defaultOrCustom') == 'custom') {
			this.onDefaultOrCustom();
		}
		else {
			this.set('defaultOrCustom', 'custom');
		}
	},

	isUsingDefault: function() {
		return this.get('template') && !this.get('uploadUrl');
	}.property('defaultOrCustom', 'template', 'uploadUrl'),

	canScrapePdfPromiseObject: function() {
		var url = this.get('uploadUrl');
		if (!url || this.get('defaultOrCustom') != 'custom') {
			return Ember.PromiseObject.create({
				promise: Ember.RSVP.resolve(),
				scrapable: true,
			});
		}

		return Ember.PromiseObject.create({
			promise: Ember.getJSON(zen.urlForCanScrapePdf(url)),
		});
	}.property('uploadUrl', 'defaultOrCustom')
});

function escape_unicode_html(str) {
	return str ? str.replace(/[<>&\u00A0-\uFFFF]/g, function(c) {
		return '&#' + c.charCodeAt(0) + ';';
	}) : str;
}

Ember.Model.dataTypes['nullBoolean'] = {
	deserialize: function(serialized) {
		var type = typeof serialized;
		if (serialized === null){
			return null;
		} else if (type === "boolean") {
			return serialized;
		} else if (type === "string") {
			return serialized.match(/^true$|^t$|^1$/i) !== null;
		} else if (type === "number") {
			return serialized === 1;
		} else {
			return false;
		}
	},
	serialize: function(deserialized) {
		return deserialized === null ? null : Boolean(deserialized);
	}
};

Ember.Model.dataTypes['json'] = {
	deserialize: function(serialized) {
		return serialized ? JSON.parse(serialized) : {};
	},

	serialize: function(deserialized) {
		return JSON.stringify(deserialized);
	}
};

zen.getSupportedWaitingPeriodForChc = function(chcId) {
	var getWaitingPeriodsUrl = '/custom_api/company_carrier_waiting_periods/' + chcId;

	var supportedWaitingPeriods = Ember.ajaxGet(getWaitingPeriodsUrl).then(function(supportedWaitingPeriods) {
			var supportedWaitingPeriodsList = supportedWaitingPeriods.map(function(waitingPeriodObject) {
				return Ember.Object.create(waitingPeriodObject);
			});

			return supportedWaitingPeriodsList;
		}).catch(function(errorDetails) {
			return;
		});
	return supportedWaitingPeriods;
};


// TODO (vberteanu):
// Move this to `employee/enroll/mixins.js` after removing old EE enrollment flow and plansettings page.
zen._EmployeeAddressChanged = Ember.Mixin.create({

	didEmployeeAddressChange: function(employee) {
		var addressFields = ['address', 'city', 'state', 'zip'];

		var dirtyAttributes = employee.get('_dirtyAttributes');
		var hasDirtyAddress = false;
		if (dirtyAttributes) {
			hasDirtyAddress = dirtyAttributes.find(function(item) {
				return addressFields.includes(item);
			});
		}
		return hasDirtyAddress;
	},

	isEmployeeAddress2Empty: function(employee) {
		return Ember.isEmpty(employee.get('address2'));
	},

});


// IMPORTANT: please leave this at the bottom of the file
zen.globalizeSpecials();
