/**
 * Extending ember views
 */
//HACK HACK: Need to be removed after NGE-473
 Ember.ajax = function() {
	return Ember.RSVP.resolve(Ember.$.ajax.apply(this, arguments));
};

// When times are tough..
// TODO(JC): rip this out once Edward upgrades Ember
_ignoreMe = function() {};
if (_ignoreMe.on) {
	var _ValidationManagerMixin = Ember.Mixin.create({
		// DON'T SET VALUE - WILL OVERRIDE ANY COMPUTEDS ON WHATEVER YOU MIX INTO
		// value: null,
		validations: null,

		classNameBindings: ["useCustomErrorClass"],

		useCustomErrorClass: function() {
			if (!this.get('isValid') && this.get('customErrorClass')) {
				return this.get('customErrorClass');
			}
		}.property('isValid', 'customErrorClass'),

		manageValidations: function() {
			var validations = this.get('validations');
			if (!validations) {
				return;
			}
			var error = this.get('error');
			if (this.get('isValid')) {
				validations.remove(error);
			} else {
				validations.add(error);
			}
		}.observes('isValid'),

		beginManaging: function() {
			this.manageValidations();
		}.on('didInsertElement'),

		stopManaging: function() {
			var validations = this.get('validations');
			if (!validations) {
				return;
			}

			var error = this.get('error');
			validations.remove(error);
		}.on('willDestroyElement'),

	});
} else {
	var _ValidationManagerMixin = Ember.Mixin.create({
		// DON'T SET VALUE - WILL OVERRIDE ANY COMPUTEDS ON WHATEVER YOU MIX INTO
		// value: null,
		validations: null,

		classNameBindings: ["useCustomErrorClass"],

		useCustomErrorClass: function() {
			if (!this.get('isValid') && this.get('customErrorClass')) {
				return this.get('customErrorClass');
			}
		}.property('isValid', 'customErrorClass'),

		manageValidations: function() {
			var validations = this.get('validations');
			if (!validations) {
				return;
			}

			var error = this.get('error');

			if (this.get('isValid')) {
				validations.remove(error);
			} else {
				validations.add(error);
			}
		}.observes('isValid'),

		didInsertElement: function() {
			this._super.apply(this, arguments);
			this.manageValidations();
		},

		willDestroyElement: function() {
			this._super.apply(this, arguments);
			var validations = this.get('validations');
			if (!validations) {
				return;
			}

			var error = this.get('error');
			validations.remove(error);
		},

	});
}


_ValidationManagerMixin.reopen({
	optional: true,

	allowEmpty: true,

	isCompleted: Ember.computed(function() {
		var value = this.get('value');
		var optional = this.get('optional') && this.get('allowEmpty');
		if (!optional && (!value || String(value).length == 0)) {
			return false;
		} else {
			return true;
		}
	}).property('value', 'optional', 'allowEmpty'),

	isValid: Ember.computed.alias('isCompleted'),

	error: Ember.computed(function() {
		var placeholder = this.get('placeholder');
		var message;
		if (placeholder) {
			message = "Please fix your input for " + placeholder;
		} else {
			message = "Please fix your input";
		}

		return {
			reason: message,
		};
	}).property('placeholder'),
});


App.TextField = Ember.TextField.extend(_ValidationManagerMixin, {
	classNameBindings: ['showError:error'],
	init: function() {
		this._super();
		// get attributeBindings and add 'name'
		var attributeBindings = this.get('attributeBindings');
		attributeBindings.pushObject('name');
		attributeBindings.pushObject('tabindex');
		attributeBindings.pushObject('style');
		this.set('attributeBindings', attributeBindings);
	},
	showError: function() {
		if (this.get('value') && this.get('type') === 'email') {
			if(!this.get('value').match(/^\S+@\S+\.\S+$/)) {
				return true;
			}
		}
		return this.get('targetObject.hasMissingValues') && !this.get('optional') && !this.get('value');
	}.property('value', 'targetObject.hasMissingValues', 'optional'),
	error: Ember.computed(function() {
		var placeholder = this.get('placeholder');
		var message;
		if (placeholder) {
			message = placeholder + " is required";
		} else {
			message = "Field is required";
		}

		return {
			reason: message,
		};
	}).property('placeholder'),
});

App.TextArea = Ember.TextArea.extend(_ValidationManagerMixin, {
	classNameBindings: ['showError:error'],
	showError: function() {
		return this.get('targetObject.hasMissingValues') && !this.get('optional') && !this.get('value');
	}.property('value', 'targetObject.hasMissingValues', 'optional'),
});

//TODO Needs more options
//  A bit of a dupe of the component library component
//  But needed in static/js/*
App.WholeNumberField = App.TextField.extend({
	max : null,
	min : null,
	bound : false,
	_cleanValue: function() {
		var value = this.get('value');
		if (value) {
			value = value.toString().replace(/[^\d]/g, "");
			if (this.get('bound')) {
				var max = this.get('max');
				var min = this.get('min');
				if (Number(value) < min || max < Number(value)) {
					//TODO improve so that it throws a validation error
					//       and some aditional options for replacement
					//For now just fix at the width of max
					value = value.slice(0,max.toString().length);
					//TODO add if for still greater than max
				}
			}
			this.set('value', value);
		}
	}.observes('value'),
});

App.NumberField = App.TextField.extend({
	limitDecimals: false,
	noOfDecimals: 2,
	_cleanValue: function() {
		var value = this.get('value');
		if (value) {
			value = value.toString().replace(/[^\d.]/g, "");

			var parts = value.split('.');
			if (parts.length > 2) { //More than one decimal point
				value = parts[0] + '.' + parts.slice(1).join('');
			} //Restrict to one decimal point.
			if (this.get('limitDecimals')) {
				var parts = value.split('.');
				if (parts.length == 2){
					value = parts[0] + '.' + parts[1].substring(0, this.get('noOfDecimals'));
				}
			}

			this.set('value', value);
		}
	}.observes('value')
});

App.NumberFieldLimitDecimals = App.NumberField.extend({
	limitDecimals: true,
});

App.WholeNumberWithMaskField = App.TextField.extend({
	_cleanValue: function() {
		var value = this.get('value');
		if (value) {
			value = value.toString().replace(/[^\d*]/g, "");
			this.set('value', value);
		}
	}.observes('value')
});
App.NumberInputField = App.TextField.extend({
	type: 'number',
	attributeBindings: ['min', 'max', 'step'],
});


App.PercentageField = App.TextField.extend({
	max: 100,

	_cleanValue: function() {
		var value = this.get('value');
		if (value) {
			value = value.toString().replace(/[^\d.]/g, "");
			if (Number(value) > this.get('max')) {
				this.set('value', this.get('max'));
			}
			else {
				this.set('value', value);
			}
		}
	}.observes('value')
});


App.NegativeNumberField = App.TextField.extend({
	_cleanValue: function() {
		var value = this.get('value');
		if (value) {
			value = value.toString().replace(/[^-\d.]/g, "");
			this.set('value', value);
		}
	}.observes('value')
});

App.FormattedNumberField = App.TextField.extend({
	_cleanValue: function() {
		var value = this.get('value');
		value = value.toString().replace(/[^\d.,]/g, "");
		this.set('value', value);
	}.observes('value')
});


App.CurrencyField = App.TextField.extend(_ValidationManagerMixin, {
	classNameBindings: ['isValid::error'],
	allowEmpty: true,
	min: null,
	max: null,
	maxDigits: 10,
	decimalPlaces: 2,
	multiple: null,
	currency: null,
	blockUpdateValue: false,
	includeZeroCents: true,
	setValue: function(value) {
		this.set('blockUpdateValue', true);
		this.set('value', value);
		this.set('blockUpdateValue', false);
	},
	init: function() {
		this._super();
		this.setValue(this.get("currency"));
		this.formatValue();
	},
	formatValue: function() {
		var value = this.get('value');
		if (value) {
			var includeZeroCents = this.get('includeZeroCents') ? true : false;
			this.setValue(prettyCurrency(value, includeZeroCents, this.get('decimalPlaces')));
		}
	},
	unformatValue: function() {
		var value = this.get('value');
		if (value) {
			value = zen.unprettifyDecimal(
				value.toString(),
				this.get('maxDigits'),
				this.get('decimalPlaces')
			);
			this.setValue(value);
		}
	},
	updateValue: function() {
		if (!this.get('blockUpdateValue')) {
			this.set('blockUpdateValue', true);

			var element = this.$();
			var pos = element.prop('selectionStart');

			this.unformatValue();
			this.set('currency', this.get('value'));

			element.prop({
				selectionStart: pos,
				selectionEnd: pos,
			});

			this.set('blockUpdateValue', false);
		}
	}.observes('value'),
	focusIn: function() {
		this.unformatValue();
	},
	focusOut: function() {
		this.formatValue();
	},
	isValid: Ember.computed(function() {
		var value = this.get('value');
		if (!this.get('isCompleted')) {
			return false;
		}

		if (!value) {
			return true;
		}

		var currencyRegex = new RegExp('^[+-]?[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{0,2})?$');
		var valid = currencyRegex.test(value);
		var cleanedValue = value.toString().replace(/[^\d\+\-\.]/g, '');
		var min = this.get('min');
		if (!Ember.isNone(min)) {
			valid = valid && (Number(cleanedValue) >= min);
		}

		var max = this.get('max');
		if (!Ember.isNone(max)) {
			valid = valid && (Number(cleanedValue) <= max);
		}

		if (this.get('multiple')) {
			valid = valid && (Number(cleanedValue) % Number(this.get('multiple')) == 0);
		}

		return valid;
	}).property('value', 'isCompleted'),
	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid amount"
		};
	})
});

App.InternationalPhoneFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'international-phone-field',
	value: undefined,
	countryCode: '',
	isValid: Ember.computed(function() {
		if (!this.get('value')) {
			return true;
		}

		var value = this.get('value');
		var countryCode = this.get('countryCode') || 'US';
		var phoneNumber = formatE164(countryCode, value);
		countryCode = countryForE164Number(phoneNumber);
		if (countryCode == 'US') {
			phoneNumber = formatLocal(countryCode, value);
		}
		var validNumber = isValidNumber(phoneNumber, countryCode);
		if (validNumber) {
			this.set('value', phoneNumber);
			this.set('countryCode', countryCode);
			return true;
		}

		return false;
	}).property('value', 'countryCode'),

	error: Ember.computed(function() {
		return {
			reason: "Please select a country and enter a valid phone number",
		};
	}),
});


App.PhoneFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'phone-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "(XXX) XXX-XXXX",
	value: "",

	isValid: Ember.computed(function() {
		var value = this.get('value');
		var result = false;

		this.set('value', this.stripAlphabets(value));

		if (!this.get('isCompleted')) {
			return false;
		}

		if (!value) {
			result = true;
		} else {
			var phoneNumber = formatE164('US', value);
			var countryCode = countryForE164Number(phoneNumber);
			if (!countryCode) {
				// allow the user to type in random 10 digit numbers, such as (555)555-5555.
				var regexObj = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
				if (regexObj.test(value)) {
					var formattedPhoneNumber = value.replace(regexObj, "($1) $2-$3");
					this.set('value', formattedPhoneNumber);
					result = true;
				} else {
					result = false;
				}
				return result;
			}
			if (countryCode == 'US') {
				phoneNumber = formatLocal(countryCode, value);
				result = true;
			} else {
				// check if the number is a valid international number
				result = isValidNumber(phoneNumber, countryCode) ? true:false;
			}
			this.set('value', phoneNumber);
		}
		return result;
	}).property('value'),

	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid phone number, including area code",
		};
	}),

	stripAlphabets: function(input){
		if (!input) {
			return "";
		}
		return input.toString().replace(/[^-\d. ()]/g, "");
	}

});

App.PhoneFieldWithExtensionComponent = App.PhoneFieldComponent.extend({
	layoutName: 'phone-field-with-extension',
	extension: "",
	phoneNumber: Ember.computed.alias("value"),
	showLabels: false,

	isValid: Ember.computed(function(){
		var isExtensionValid = this.isExtensionValid();
		if(!isExtensionValid) {
			return false;
		}

		return this._super();
	}).property('phoneNumber','extension'),

	isExtensionValid: function (){
		var extension = this.get('extension');
		this.set('extension', this.stripAlphabets(extension));

		if(this.isPhoneNumberEmptyAndExtensionAvailable()) {
			return false;
		}

		if(!extension || extension.length <= 6) {
			return true;
		}

		//Restrict users from entering more than 6 digits
		extension = extension.slice(0,extension.length - 1);
		this.set('extension',extension);
		return true;
	},

	isPhoneNumberEmptyAndExtensionAvailable: function(){
		var extension = this.get('extension');
		var phoneNumber = this.get('phoneNumber');

		if(!phoneNumber && extension) {
			return true;
		}

		return false;
	},

});

App.EmailFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'email-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "",
	value: "",
	isValid: Ember.computed(function() {
		var value = this.get('value');

		if (!this.get('isCompleted')) {
			return false;
		}

		if (!value) {
			return true;
		}

		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(value);
	}).property('value', 'isCompleted'),

	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid email address",
		};
	}),
});

App.DateFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'date-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "MM/DD/YYYY",
	value: "",
	warnMonthOldDate: false,
	warnThreeMonthFutureDate: false,
	warnFutureDate: false,
	warnPastDate: false,
	allowTwoYear: false, //Do we ever want to allowTwoYear dates?
	warnUSHoliday: false,
	warnWeekend: false,

	minYearsAgo: undefined, // Ignored if falsey; use warnFutureDate if you wanted 'at least 0 years ago'.
	maxYearsAgo: undefined, // Ignored if false, compare whether date is older than given years.

	style: "margin-top: 10px; text-align: left;",

	allowEmpty: true,
	inlineErrors: true,

	showError: Ember.computed(function() {
		return !this.get('isValid') && this.get('inlineErrors');
	}).property('isValid', 'inlineErrors'),

	isValid: Ember.computed(function() {
		if (!this.get('elementInDom')) {
			return true;
		}

		if (!this.get('isCompleted')) {
			this.set('error.reason', "Please enter a date in MM/DD/YYYY format");
			return false;
		}

		var value = this.get('value') || "";
		value = value.toString().replace(/[^\d/.]/g, "");
		this.set('value', value);

		if (!value) {
			return true;
		}

		var dateRegEx = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)" + (this.get('allowTwoYear') ? "?" : "") + "[0-9]{2})*$");

		if (!dateRegEx.test(value)) {
			this.set('error.reason', "Please enter a valid date in the MM/DD/YYYY format");
			return false;
		}

		var date = moment(value, ['MM/DD/YY', 'MM/DD/YYYY'], true);
		if (!date.isValid()) { // Catches bad dates like June 31st, Feb 29th 2015, etc.
			this.set('error.reason', "Please enter a valid date.");
			return false;
		}

		return this._isInAllowedDateRange(date);
	}).property('value', 'elementInDom', 'isCompleted'),

	_isInAllowedDateRange: function(date) {
		// Note: moment's .add and .subtract do NOT generate a new moment.  They alter the moment they are called on and return it.
		// Therefore we need to generate a new moment() every time we call them, and we should not use them on date.
		if (this.get('warnWeekend') && date.isWeekend()) {
			this.set('error.reason', 'Are you sure? This date falls on the weekend.');
			return false;
		}
		else if (this.get('warnUSHoliday') && !date.isBusinessDay()) {
			this.set('error.reason', 'Are you sure? This date is a US national holiday.');
			return false;
		}
		else if (this.get('warnMonthOldDate') && date < moment().subtract(1, 'months')) {
			this.set('error.reason', 'Are you sure? This date is over a month in the past.');
			return false;
		}
		else if (this.get('warnThreeMonthFutureDate') && date > moment().add(3, 'months')) {
			this.set('error.reason', 'Are you sure? This date is more than three months from now.');
			return false;
		}
		else if (this.get('warnFutureDate') && date > moment().add(1, 'days')) {
			this.set('error.reason', 'Are you sure? This date is in the future.');
			return false;
		}
		else if (this.get('warnPastDate') && date < moment().subtract(1, 'days')) {
			this.set('error.reason', 'Are you sure? This date already passed.');
			return false;
		}
		var minYearsAgo = this.get('minYearsAgo');
		if (minYearsAgo && date > moment().subtract(minYearsAgo, 'years')) {
			this.set('error.reason', 'Are you sure? This date is less than ' + minYearsAgo + ' years ago.');
			return false;
		}
		var maxYearsAgo = this.get('maxYearsAgo');
		if (maxYearsAgo && date <= moment().subtract(maxYearsAgo, 'years')) {
			this.set('error.reason', 'Are you sure? This date is more than ' + maxYearsAgo + ' years old');
			return false;
		}

		this.set('error.reason', '');
		return true;
	},

	error: Ember.computed(function() {
		return {
			reason: "" // set reason dynamically.. ugly solution, but it works
		};
	}),

	// NOTE(ed): Because we have side effects in our computed property
	elementInDom: false,
	didInsertElement: function() {
		this._super.apply(this, arguments);
		this.set('elementInDom', true);
	},
	willDestroyElement: function() {
		this._super.apply(this, arguments);
		this.set('elementInDom', false);
	},
});

App.MonthDateFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'month-date-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "",
	value: "",
	style: "margin-top: 10px; text-align: left;",

	isValid: Ember.computed(function() {
		var value = this.get('value');
		if (!value) {
			return true;
		}

		value = value.toString().replace(/[^\d/.]/g, "");
		this.set('value', value);
		var dateRegEx = /^\d+\/\d+$/;
		if (!dateRegEx.test(value)) {
			return false;
		}
		// Using 2016 takes care of leap years too
		var testValue = '2016/' + value;
		var d = Date.parse(testValue);
		return !isNaN(d);
	}).property('value'),

	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid date in the MM/DD format",
		};
	}),
});

App.AlienRegistrationNumberFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'uscis-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "A########",
	value: "",

	isValid: Ember.computed(function() {
		var value = this.get('value');

		if (value) {
			value = value.toString().replace(/[^A\d]/g, "");
			this.set('value', value);
		}

		if (!value) {
			return true;
		}

		//Can be 7-9 digits, with or without a leading A.
		var alienRegistrationRegex = /^A?\d{7,9}$/;
		return alienRegistrationRegex.test(value);
	}).property('value'),

	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid USCIS alien registration number.",
		};
	}),

});

App.SSNFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'ssn-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "",
	value: "",
	shouldAllowMask: true,

	isValid: Ember.computed(function(key, value) {
		// skip validation logic if setting the value
		if (arguments.length > 1) {
			return value;
		}

		// value from server
		var value = this.get('value');
		if (!this.get('isCompleted') && this.get('shouldAllowMask')) {
			this.set('error.reason', "SSN is required");
			//Ember.set('error', 'reason', 'SSN is required');
			return false;
		}

		if (!value || (this.get('shouldAllowMask') && /^\*\*\*-\*\*-\d{4}$/.test(value)) || (this.get('shouldAllowMask') && /^\*\*\*\*\*\d{4}$/.test(value))) {
			return true;
		}

		value = value.toString().replace(/[^\d-.*_X]/g, "");
		this.set('value', value);

		var socialRegex = new RegExp("^\\d{3}[-]*\\d{2}[-]*\\d{4}?$");
		if(socialRegex.test(value) == false) {
			this.set('error.reason', "Please enter a valid SSN. SSNs are 9 digits long");
			return false;
		}

		Ember.$.ajax({
			url: '/custom_api/validateSSN',
			data: JSON.stringify({"ssn" : value}),
			type: "POST",
			dataType: "json"
		}).then(function(data) {
			if (value == this.get('value')) {
				if (data.status == "invalid") {
					// if server side validation fails, populate error state
					this.set('error.reason', "Invalid SSN. Please contact support if you believe this is a mistake");
					this.set('isValid', false);
				}
				else {
					this.set('error.reason', "");
					this.set('isValid', true);
				}
			}
		}.bind(this));

		// field is in error until server-side validation completes
		this.set('error.reason', 'Validating...');
		return false;
	}).property('value', 'isCompleted'),

	error: Ember.computed(function() {
		return { reason: "" };
	}).property(),
});

App.SSNFieldComponentWithoutMask = App.SSNFieldComponent.extend({
	shouldAllowMask: false,
});

App.SSNITINFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'ssn-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "",
	value: "",

	isValid: Ember.computed(function(key, value) {
		// skip validation logic if setting the value
		if (arguments.length > 1) {
			return value;
		}

		// value from server
		var value = this.get('value');
		if (!this.get('isCompleted')) {
			this.set('error.reason', "SSN / ITIN is required");
			return false;
		}

		if (!value || /^\*\*\*-\*\*-\d{4}$/.test(value) || /^\*\*\*\*\*\d{4}$/.test(value)) {
			return true;
		}

		value = value.toString().replace(/[^\d-.*_X]/g, "");
		this.set('value', value);

		var socialRegex = new RegExp("^\\d{3}[-]*\\d{2}[-]*\\d{4}?$");
		if(socialRegex.test(value) == false) {
			this.set('error.reason', "Please enter a valid SSN or ITIN. SSNs / ITINs are 9 digits long");
			return false;
		}

		Ember.$.ajax({
			url: '/custom_api/validateSSN',
			data: JSON.stringify({"ssn" : value, "checkITIN": true}),
			type: "POST",
			dataType: "json"
		}).then(function(data) {
			if (value == this.get('value')) {
				if (data.status == "invalid") {
					// if server side validation fails, populate error state
					this.set('error.reason', new Ember.Handlebars.SafeString("Invalid SSN / ITIN. Please contact <a href='https://www.zenefits.com/support' target='_blank' rel='noopener noreferrer'>Zenefits support</a> if you believe this is a mistake."));
					this.set('isValid', false);
				}
				else {
					this.set('error.reason', "");
					this.set('isValid', true);
				}
			}
		}.bind(this));

		// field is in error until server-side validation completes
		this.set('error.reason', 'Validating...');
		return false;
	}).property('value', 'isCompleted'),

	error: Ember.computed(function() {
		return { reason: "Please enter a valid SSN or ITIN. SSNs / ITINs are 9 digits long" };
	}).property(),
});

App.EINFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'ein-field',
	textFieldClassNames: 'medium', //This is the most suitable default.  Good size for EINField.
	value: '',
	optional: false,
	errorText: "",
	name: "",
	shouldAllowMask: true,
	isValid: Ember.computed(function() {
		var value = this.get('value');

		if (Ember.isEmpty(value)) {
			return true;
		}

		value = value.toString().replace(/[^-\d*]/g, "");
		this.set('value', value);

		if (Ember.isEmpty(value) || (this.get('shouldAllowMask') && /^\*{5}\d{4}$/.test(value))) {
			return true;
		}

		var socialRegex = new RegExp("^\\d{2}-?\\d{7}?$");
		return socialRegex.test(value);
	}).property('value'),

	error: Ember.computed(function() {
		return {
			reason: "Please enter a valid EIN. EIN codes are 9 digits",
		};
	}),
});

App.EINFieldComponentWithoutMask = App.EINFieldComponent.extend({
	shouldAllowMask: false,
});

App.ZipCodeFieldComponent = Ember.Component.extend(_ValidationManagerMixin, {
	layoutName: 'zip-code-field',
	textFieldClassNames: undefined,
	name: "",
	placeholder: "",
	value: "",
	errorReason: "Invalid zipcode.", // Shorter to avoid breaking data entry tables too much.
	allowEmpty: true,
	inlineErrors: true,
	showError: Ember.computed(function() {
		return !this.get('isValid') && this.get('inlineErrors');
	}).property('isValid', 'inlineErrors'),
	validateZip: true,
	isValid: Ember.computed(function(key, value) {
		var component = this;

		// skip validation logic if setting the value
		if (arguments.length > 1) {
			return value;
		}

		if (!this.get('isCompleted')) { // (ETS) What is this for?  We don't set an 'isCompleted' variable...
			return false;
		}

		var value = this.get('value') || ""; // Default to "" so we can .toString() without crashing on null/undefined.
		value = value.toString().replace(/[^-\d]/g, "");
		this.set('value', value);

		if (Ember.isEmpty(value)) {
			return true;
		}

		if (!/^\d{5}(-\d{4})?$/.test(value)) {
			return false;
		}

		if (this.get('validateZip')) {
			Ember.ajax({
				url: '/custom_api/validateUSZipCode',
				data: JSON.stringify({"zip" : value}),
				type: "POST",
				dataType: "json"
			}).then(function(data) {
				ajaxReturned = true;
				if (component.get('isDestroyed') || component.get('isDestroying')) {
					return;
				}
				if (data.status == "invalid") {
					component.set('isValid', false);
				}
				else {
					component.set('isValid', true);
				}
			},function(){
				component.set('isValid',true);
			});
		}
		return true;
	}).property('value','isCompleted'),

	error: Ember.computed(function() {
		return {
			reason: this.get('errorReason'),
		};
	}),
});

App.SelectBox = Ember.Select.extend({
	sortOrder:"asc",
	sorting: false,
	sortKey: null,
	init: function() {
		this._super();
		if (this.get('sorting')) {
			var sortKey = this.get('sortKey') ||  this.get("optionLabelPath").replace('content.', '');
			this.sortContent();
			this.addObserver('items.@each.' + sortKey, this, 'sortContent');
		}
		var attributeBindings = this.get('attributeBindings');
		attributeBindings.pushObject('title');
		attributeBindings.pushObject('name');
        this.set('attributeBindings', attributeBindings);
	},
	sortContent:function() {
		var content = this.get("content").toArray();
		var sortKey = this.get('sortKey') ||  this.get("optionLabelPath").replace('content.', '');
		var sortOrder = this.get('sortOrder');
		var sortedContent = content.sort(function(a,b) {
			// convenience since can't use truthy (need values of 0, false, etc.)
			function isnull(a) { return a == null || a == undefined; }

			try {
				var order = (sortOrder == 'asc') ? 1 : -1;
				a = a.get(sortKey);
				b = b.get(sortKey);

				var result = 0;
				if(isnull(a) && isnull(b))  { result = 0; }
				else if(isnull(a)) { result = -1; }
				else if(isnull(b)) { result = 1; }
				else if(a > b) { result = 1; }
				else if(a < b) { result = -1;}
				return result * order;
			}
			catch(e) {
				return 0;
			}
		});
		this.set("content",sortedContent);
	},
	optionValuePath: "content.id",
	optionLabelPath: "content.name",
	selectBoxClass: "selectbox",
	size: 'large', // Default size is large. Replace this with small/medium to get small/medium select boxes.
	overrideWidth: null,
	setValueToFirst: function() {
		if (this.get('isDestroying') || this.get('isDestroyed')) {
			return;
		}
		if (!this.get('multiple') && !this.get('prompt') && !Ember.isEmpty(this.get('content'))) {
			if (!this.get('value')) {
				this.set('selection', this.get('content.firstObject'));
			}
			this.notifyPropertyChange('value'); // This should override what value is bound to.
		}
	},
	didInsertElement: function() {
		var select = this.$();
		var selectBoxClass = this.get('selectBoxClass');
		var size = this.get('size');
		if(size == 'small' || size == 'medium' || size == 'small-medium') {
			selectBoxClass = size + selectBoxClass + " " + selectBoxClass;
		}
		var addClass = this.get('addClass');
		if(addClass){
			selectBoxClass = selectBoxClass + " "+ addClass;
		}
		var overrideWidthStyle = '';
		if (this.get('overrideWidth')) {
			overrideWidthStyle = 'width:' + this.get('overrideWidth') + 'px;';
		}
		select.wrap('<span class="' + selectBoxClass +(select.hasClass('flat')?' flat':'')+'" style="display:inline-block;position:relative;' + overrideWidthStyle + '" />');
		select.after('<span class="arrow" />');
		select.after('<span class="selected-value" />');
		select.css({
			'z-index' : 2,
			'opacity' : 0,
			'position' : 'absolute',
			'left' : 0,
			'top' : 0
		}).show();
		var selectbox = select.parent();
		var selection = selectbox.find('option:selected').text();
		var default_selection = $(this).children('select').attr('data-default');
		if (selection == '') {
			selectbox.children('span.selected-value').text(default_selection);
		} else {
			selectbox.children('span.selected-value').text(selection);
		}
		Ember.run.next(this, this.setValueToFirst);
	},
	showError: function() {
		return this.get('targetObject.hasMissingValues') && !this.get('optional') && !this.get('value');
	}.property('value', 'targetObject.hasMissingValues', 'optional'),
	// layout doesn't seem to work here, do it via jquery instead
	onError: function() {
		this.$().parent().toggleClass('error', !!this.get('showError'));
	}.observes('showError'),
	onChange: function() {
		var element = this.$();
		if ( !element ) {
			return;
		}
		var labelPath = this.get('optionLabelPath').replace(/^content\.?/, '');
		var label = this.get('selection.' + labelPath);
		var selectedElement = element.siblings("span.selected-value");
		if(selectedElement) {
			selectedElement.text(label || this.get('prompt') || "");
		}
	}.observes('selection'),
});

App.GenderSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,
	optional: false,
	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Male", id: "M"}),
		Ember.Object.create({name: "Female", id: "F"})
	],
	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select a gender',
		};
	}),
});


App.CobraQualifyingEventSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Termination", id: "termination"}),
		Ember.Object.create({name: "Full-Time to Part-Time", id: "move_out_of_ft"}),
		/*
		Not implemented: https://jira.inside-zen.com/browse/BEN-2988
		Ember.Object.create({name: "Spouse Termination", id: "dep_termination"}),
		Ember.Object.create({name: "Spouse Full-Time to Part-Time", id: "dep_move_out_of_ft"}),
		Ember.Object.create({name: "Spouse Divorced", id: "sp_divorced"}),
		Ember.Object.create({name: "Spouse on Medicare", id: "dep_medicare"}),
		Ember.Object.create({name: "Spouse Deceased", id: "dep_deceased"}),
		Ember.Object.create({name: "Child Over 26 Years Old", id: "child_age_out"}),
		*/
	],

	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select an eligibility reason',
		};
	}),
});


App.TerminationTypeSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Involuntary", id: "IN"}),
		Ember.Object.create({name: "Voluntary, Can Rehire", id: "VR"}),
		Ember.Object.create({name: "Voluntary, No Rehire", id: "VN"}),
		Ember.Object.create({name: "Unclassify", id: "UN"}),
	],

	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select a termination type',
		};
	}),
});


App.CobraBorStatusSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,

	content: function() {
		if (this.get('canZenefitsAdministerCobra')) {
			return [
				Ember.Object.create({name: "Select", id: ""}),
				Ember.Object.create({name: "Already enrolled", id: "previously_covered"}),
				Ember.Object.create({name: "Only notified", id: "notified"}),
				Ember.Object.create({name: "Not notified", id: "new"}),
			];
		} else {
			return [
				Ember.Object.create({name: "Select", id: ""}),
				Ember.Object.create({name: "Not notified", id: "new"}),
			];
		}
	}.property('canZenefitsAdministerCobra'),

	canZenefitsAdministerCobra: true,

	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select a COBRA enrollment status',
		};
	}),
});

App.CobraBorCategorySelect = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id:null, name: "None"}),
		Ember.Object.create({id:"EE", name: "Employee Only"}),
		Ember.Object.create({id:"EE+SP", name: "Employee + Spouse"}),
		Ember.Object.create({id:"EE+CH", name: "Employee + Child"}),
		Ember.Object.create({id:"FAM", name: "Employee + Family"})
	],
});


App.CompanyCobraClassificationSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,

	content: function() {
		return [
			Ember.Object.create({name: "Select", id: ""}),
			Ember.Object.create({name: "Federal", id: "Federal"}),
			Ember.Object.create({name: "State", id: "State"}),
		];
	}.property(),

	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select a COBRA classification',
		};
	}),

});


App.CobraBorClassificationSelectView = App.SelectBox.extend(_ValidationManagerMixin, {
	sorting: false,

	content: function() {
		if (this.get('hasStateCobra')) {
			return [
				Ember.Object.create({name: "Select", id: ""}),
				Ember.Object.create({name: "Federal", id: "F"}),
				Ember.Object.create({name: "State", id: "S"}),
			];
		} else {
			return [
				Ember.Object.create({name: "Select", id: ""}),
				Ember.Object.create({name: "Federal", id: "F"}),
			];
		}
	}.property('hasStateCobra'),

	hasStateCobra: true,

	isInvalid: Ember.computed.equal('value', ''),
	isValid: Ember.computed.not('isInvalid'),
	error: Ember.computed(function() {
		return {
			reason: 'Please select a COBRA classification',
		};
	}),
});

App.ApproverTierSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({id: 5, name: "Tier 5 - Initial"}),
		Ember.Object.create({id: 4, name: "Tier 4"}),
		Ember.Object.create({id: 3, name: "Tier 3"}),
		Ember.Object.create({id: 2, name: "Tier 2"}),
		Ember.Object.create({id: 1, name: "Tier 1 - Final"})
	],
});

App.ErrorSelectView = Ember.Select.extend(_ValidationManagerMixin, {
	classNameBindings: ['showError:error'],
	showError: function() {
		return this.get('targetObject.hasMissingValues') && !this.get('optional') && !this.get('value');
	}.property('value', 'targetObject.hasMissingValues', 'optional'),
});

if (typeof(JQ) !== 'undefined') {
	App.DatePicker = JQ.DatePicker.extend({
		dateFormat : 'mm/dd/yy',
		placeholder: 'MM/DD/YYYY',
		value: '',
		onSelect : function(dateText, inst) {
			this.set('value', dateText);
		},
		beforeShow: function() {
			var baseZIndex = this.$().zIndex() || 0;
			// NOTE: this is needed to bump the z-index higher than a select field's z-index
			setTimeout(function() {
				$(".ui-datepicker").css("z-index", 1000000 + baseZIndex);
			}, 10);
		}
	});
}

App.AgeInputField = Ember.Component.extend(_ValidationManagerMixin, {
	type: 'number',
	textFieldClassNames: undefined,
	inlineErrors: true,
	layoutName: 'age-field',

	min: 0,
	max: 200,

	_validateAge: function() {
		var value = this.get('value');
		var min = this.get('min');
		var max = this.get('max');

		var isValid = value ? (value >= min && value <= max) : this.get('allowEmpty');
		this.set('isValid', isValid);

		if (!isValid) {
			var errorMsg = "Age should be between " + String(min) + " and XXX";
			this.set('error.reason', errorMsg);
		}
	}.observes('value', 'min'),

	willInsertElement: function() {
		//Call it to throw errors on pre-existing objects
		this._validateAge();
	}

});
