App.EmployeeSubsectionsCompletedHelper = Ember.HTMLBars.makeBoundHelper(function(params) {
	var section = params[0];
	var completed = 0;
	section.get('subsections').forEach(function(obj) {
		if (obj.get('isCompleted')) {
			completed++;
		}
	});

	return completed;
});
App.EmployeeEnrollmentv1View = Ember.View.extend({
	didViewChange: function() {
		this.rerender();
	}.observes('App.EmployeeEnrollmentv1SectionController.refresh')
});
App.EmployeeEnrollmentv1SectionController = _TransactionArraySavingController.extend({
	subsections: null,
	currentSection: null,
	currentSubsection: null,
	currentSectionLength: null,
	isCurrentSectionLast: false,
	isCurrentSubsectionLast: false,
	buttonText: 'Continue Enrollment',
	subheadText: '',
	errorClass: false,
	refresh: 0,
	employee: null,

	currentSubsectionPos: function() {
		var index = 1;
		section = this.get('currentSection');
		var currentSubsection = this.get('currentSubsection');
		index = section.get('subsections').indexOf(currentSubsection) + 1;
		return index;
	}.property('currentSection', 'currentSubsection'),

	updateContent: function() {
		this.set('refresh', this.get('refresh') + 1);
	},
	next: function(isComplete) {
		var section = this.get('currentSection');
		var subsection = this.get('currentSubsection');

		var currentSubsections = section.get('subsections');
		var nextIndex = (currentSubsections.indexOf(subsection)) + 1;
		var nextSubsection;

		if (nextIndex == currentSubsections.get('length')) {
			var sections = this.get('content');
			var nextIndex = (sections.indexOf(section)) + 1;
			if (nextIndex == sections.get('length')) {
				subsection.set('isCompleted', isComplete);
				if (isComplete) {
					var sectionCompleted = true;
					for (var i = 0; i < currentSubsections.get('length'); i++) {
						sectionCompleted =
							sectionCompleted && currentSubsections.objectAt(i).get('isCompleted');
					}
					section.set('isCompleted', sectionCompleted);
				}
				else {
					section.set('isCompleted', false);
				}
				return this.saveAndContinue().then(function(){
					var allSectionsCompleted = true;
					for (var i = 0; i < sections.get('length'); i++) {
						allSectionsCompleted =
							allSectionsCompleted && sections.objectAt(i).get('isCompleted');
					}
					// TODO if all items are not complete, take to enrollment summary form
					if (allSectionsCompleted) {
						this.set('subheadText', '');
						this.transitionTo('employee.enrollmentfinish');
					}
					else {
						this.set('subheadText', 'Looks like some sections are still incomplete. Please complete them to finish enrollment.');
						this.set('errorClass', true);
						var timer = setTimeout(function () {
							this.transitionTo('employee.enrollmentsummary');
						}.bind(this), 350);
					}
				}.bind(this));
			}
			subsection.set('isCompleted', isComplete);
			if (isComplete) {
				var sectionCompleted = true;
				for(var i = 0; i < currentSubsections.get('length'); i++) {
					sectionCompleted =
						sectionCompleted && currentSubsections.objectAt(i).get('isCompleted');
				}
				section.set('isCompleted', sectionCompleted);
			}
			else {
				section.set('isCompleted', false);
			}

			return this.saveAndContinue().then(function(){
				var nextSection = sections.objectAt(nextIndex);
				nextSubsection = nextSection.get('subsections').get('firstObject');
				var nextPath = nextSubsection.get('path');
				if (nextPath == 'medicaldetails') {
					var medicalPlan = this.get('medicalPlan');
					if (!medicalPlan) {
						medicalPlan = this.get('company');
					}
					if (medicalPlan.get('isBlueCrossSelected')) {
						nextPath = 'bluecross';
					}
					else if (medicalPlan.get('isBlueShieldSelected')) {
						nextPath = 'blueshield';
					}
					else if (medicalPlan.get('isUnitedSelected')) {
						nextPath = 'united';
					}
					else if (medicalPlan.get('isHealthNetSelected')) {
						nextPath = 'healthnet';
					}
					else if (medicalPlan.get('isSeeChangeSelected')) {
						nextPath = 'seechange';
					}
				}
				return this.transitionTo(
				"employee.enrollmentv1."+ nextSection.get('path') + "." + nextPath
				);
			}.bind(this)); // save this back to the server
		}
		else {
			subsection.set('isCompleted', isComplete);
			return this.saveAndContinue().then(function(){
				nextSubsection = currentSubsections.objectAt(nextIndex);
				return section.reload();
			}.bind(this)).then(function(){
				return this.transitionTo("employee.enrollmentv1." + section.get('path') + "." + nextSubsection.get('path'));
			}.bind(this));
		}
	},
	prev: function() {
		var section = this.get('currentSection');
		var subsection = this.get('currentSubsection');

		var currentSubsections = section.get('subsections');
		var prevIndex = (currentSubsections.indexOf(subsection)) - 1;
		var prevSubsection;
		if (prevIndex < 0) {
			var sections = this.get('content');
			prevIndex = (sections.indexOf(section)) - 1;
			if (prevIndex < 0) {
				this.transitionTo('employee.enrollmentsummary');
				return;
			}
			var prevSection = sections.objectAt(prevIndex);
			var length = prevSection.get('subsections').get('length');
			prevSubsection = prevSection.get('subsections').objectAt(length - 1);

			this.transitionTo(
				"employee.enrollmentv1."+ prevSection.get('path') + "." + prevSubsection.get('path')
			);

			return;
		}
		else {
			prevSubsection = currentSubsections.objectAt(prevIndex);
		}
		var prevPath = prevSubsection.get('path');
		var employee = this.get('employee');
		if (prevPath == 'medicaldetails') {
			var medicalPlan = employee.get('medicalPlan');
			if (!medicalPlan) {
				medicalPlan = this.get('company');
			}
			if (medicalPlan.get('isBlueCrossSelected')) {
				prevPath = 'bluecross';
			}
			else if (medicalPlan.get('isBlueShieldSelected')) {
				prevPath = 'blueshield';
			}
			else if (medicalPlan.get('isUnitedSelected')) {
				prevPath = 'united';
			}
			else if (medicalPlan.get('isHealthNetSelected')) {
				prevPath = 'healthnet';
			}
			else if (medicalPlan.get('isSeeChangeSelected')) {
				var nextPath = 'seechange';
			}
		}
		this.transitionTo(
			"employee.enrollmentv1."+ section.get('path') + "." + prevPath
		);
	}
});
App.EmployeeEnrollmentsummaryRoute = _EmployeeEnrollmentBaseRoute.extend({
	beforeModel: function(transition) {
		//redirect to React employee enrollment flow
		window.location.href = '/app/benconnect/#/employee-enrollment';
		return Ember.RSVP.resolve();
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		// Invalidate EPlan in-memory cache when entering enrollment summary.
		EPlanManager.invalidate();

		var sections = controller.get('sections');
		var subsections = controller.get('subsections');
		var sectionController = controller.get('sectionController');

		var allSectionsCompleted = sections.every(function(section) {
			return section.get('isCompleted');
		});

		if (allSectionsCompleted) {
			sectionController.set('buttonText', 'Finish Enrollment');
		}
		else {
			var enrollmentStarted = subsections.any(function(section) {
				return section.get('isCompleted');
			});

			if (enrollmentStarted) {
				sectionController.set('buttonText', 'Resume');
			}
		}

		// Pre-load enrollment models here.
		App.PersonalInfo.find("me");
		App.PreviousCoverage.find("me");
	},
	redirect: function(model, transition) {
		var employee = model;

		/*
		 * Redirect the employee back to [medical|dental|vision]planselect page, if:
		 * 0) Apply to IE only
		 * 1) EHE for this line of coverage exist, AND
		 * 2) It has a status (not null) and the status is not 'decline' AND
		 * 3) no selected[|Dental|Vision]Plan set for this employee in the profile
		 * 4) EHE is ongoing
		 */
		var currentEnrollment = model.get('currentEnrollment');
		if (currentEnrollment && currentEnrollment.get('isEnrollmentOngoing') && currentEnrollment.get('type') && currentEnrollment.get('type') == 'initial_enrollment') {
			if (currentEnrollment.get('status') && currentEnrollment.get('status') != 'decline') {
				if(employee.get('selectedPlan') == null) {
					this.transitionTo('employee.planselector');
					return;
				}
			}
		}

		var currentDentalEnrollment = model.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing') && currentDentalEnrollment.get('type') && currentDentalEnrollment.get('type') == 'initial_enrollment') {
			if (currentDentalEnrollment.get('status') && currentDentalEnrollment.get('status') != 'decline') {
				if(employee.get('selectedDentalPlan') == null) {
					this.transitionTo('employee.dentalplanselector');
					return;
				}
			}
		}

		var currentVisionEnrollment = model.get('currentVisionEnrollment');
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing') && currentVisionEnrollment.get('type') && currentVisionEnrollment.get('type') == 'initial_enrollment') {
			if (currentVisionEnrollment.get('status') && currentVisionEnrollment.get('status') != 'decline') {
				if(employee.get('selectedVisionPlan') == null) {
					this.transitionTo('employee.visionplanselector');
					return;
				}
			}
		}
	},
});
App.EmployeeEnrollmentv1IndexRoute = _EmployeeEnrollmentSectionRoute.extend({
	redirect: function(){
		this.transitionTo('employee.enrollmentv1.personalinfo.identifyinginfo');
	}
});

App.EmployeeEnrollmentv1Route = _EmployeeEnrollmentBaseRoute.extend({});

App.EmployeeEnrollmentv1PersonalinfoRoute = _EmployeeEnrollmentSectionRoute.extend({
	model: function(params) {
		return this.all(App.PersonalInfo.find("me"), { "employee": dashboard('employee') });
	}
});
App.EmployeeEnrollmentv1PersonalinfoIdentifyinginfoRoute = App.EmployeeEnrollmentv1PersonalinfoRoute.extend({
	model: function(params) {
		return dashboard('employee');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('employee', model); // backwards compatibility for the controller superclasses.
	}
});
App.EmployeeEnrollmentv1PersonalinfoMaritalstatusRoute = App.EmployeeEnrollmentv1PersonalinfoRoute.extend({
	model: function(params) {
		var activeDependents = dashboard('employee.dependents').then(function(dependents) {
			return dependents.filterBy('status', 'Active');
		}.bind(this));
		return this.all(App.PersonalInfo.find("me"), {
			"dependents": activeDependents,
			"employee": dashboard("employee"),
		});
	},
});
App.EmployeeEnrollmentv1PersonalinfoEmploymentinfoRoute = App.EmployeeEnrollmentv1PersonalinfoRoute.extend({
	model: function(params) {
		return this.all( App.PersonalInfo.find('me'), {
				'employee': dashboard('employee'),
				'company': dashboard('company')
			}
		);
	}
});
App.EmployeeEnrollmentv1PersonalinfoContactinfoRoute = App.EmployeeEnrollmentv1PersonalinfoRoute.extend({
	model: function(params) {
		return this.all(dashboard('employee'), { "personalInfo": App.PersonalInfo.find("me") });
	},

	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('employee', model); // backwards compatibility with controller superclasses
	}
});

App.EmployeeEnrollmentv1DependentsRoute = _EmployeeEnrollmentSectionRoute.extend({
	model: function(params) {
		return this.all( dashboard('employee.dependents'), {
				'personalInfo': App.PersonalInfo.find("me"),
				'employee': dashboard('employee'),
				'setting': dashboard('employee.employeeSettings'),
				'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
				'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
				'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			}
		);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
	},
});
App.EmployeeEnrollmentv1DependentsListdependentsRoute = App.EmployeeEnrollmentv1DependentsRoute.extend();
App.EmployeeEnrollmentv1DependentsEnrolldependentsRoute = App.EmployeeEnrollmentv1DependentsRoute.extend({
	model: function(params) {
		return this.all(dashboard('employee.dependents'), {
			'personalInfo': App.PersonalInfo.find("me"),
			'employee': dashboard('employee'),
			'employeeHealthStatusProperties': dashboard('employee.employeeHealthStatusProperties'),
			'employeeCobra': dashboard('employee.employeeCobra'),
			'dependents': dashboard('employee.dependents'),
			'company': dashboard('company'),
			'companySettings': dashboard('company.settings'),
			'setting': dashboard('employee.employeeSettings'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'chosenMedicalPlan': dashboard('employee.currentEnrollment.medicalPlan'),
			'chosenDentalPlan': dashboard('employee.currentDentalEnrollment.dentalPlan'),
			'chosenVisionPlan': dashboard('employee.currentVisionEnrollment.visionPlan'),
			'shortCircuitOEStats' : App.ShortCircuitOpenEnrollmentStats.find('me'),
		});
	},
	afterModel: function(model) {
		var promiseArray = [];
		model.forEach(function(dependent) {
			var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
			linesOfCoverage.forEach(function(lineOfCoverage) {
				if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
					});
				}
			});
		});

		var currentEnrollment = this.otherModels.currentEnrollment;
		var currentDentalEnrollment = this.otherModels.currentDentalEnrollment;
		var currentVisionEnrollment = this.otherModels.currentVisionEnrollment;
		var employee = this.otherModels.employee;
		var chosenMedicalPlan = this.otherModels.chosenMedicalPlan;
		var chosenDentalPlan = this.otherModels.chosenDentalPlan;
		var chosenVisionPlan = this.otherModels.chosenVisionPlan;

		if (currentEnrollment && currentEnrollment.get('medicalPlan') && currentEnrollment.get('allowEditReviewPlans') && employee.get('allowDependentMedicalEnrollment')) {
			var medicalPlanId = currentEnrollment.get('medicalPlan.id');
			promiseArray.push(App.EPlan.find(medicalPlanId).then(function(medicalPlan) {
				this.otherModels['medicalPlan'] = medicalPlan;
				return medicalPlan;
			}.bind(this)));
		}

		if (currentDentalEnrollment && currentDentalEnrollment.get('dentalPlan') && currentDentalEnrollment.get('allowEditReviewPlans') && employee.get('allowDependentDentalEnrollment')) {
			var dentalPlanId = currentDentalEnrollment.get('dentalPlan.id');
			promiseArray.push(App.EDentalPlan.find(dentalPlanId).then(function(dentalPlan) {
				this.otherModels['dentalPlan'] = dentalPlan;
			}.bind(this)));
		}

		if (currentVisionEnrollment && currentVisionEnrollment.get('visionPlan') && currentVisionEnrollment.get('allowEditReviewPlans') && employee.get('allowDependentVisionEnrollment')) {
			var visionPlanId = currentVisionEnrollment.get('visionPlan.id');
			promiseArray.push(App.EVisionPlan.find(visionPlanId).then(function(visionPlan) {
				this.otherModels['visionPlan'] = visionPlan;
			}.bind(this)));
		}

		if (chosenMedicalPlan) {
			this.disableDependentEnrollment(model, currentEnrollment);
		}

		if (chosenDentalPlan) {
			this.disableDependentEnrollment(model, currentDentalEnrollment);
		}

		if (chosenVisionPlan) {
			this.disableDependentEnrollment(model, currentVisionEnrollment);
		}
		return Ember.RSVP.all(promiseArray);
	},
	disableDependentEnrollment : function(model, employeeEnrollment) {
		var coverageType = employeeEnrollment.get('coverage_type');
		var enrollmentType =  coverageType.charAt(0).toUpperCase() + coverageType.slice(1) + "Enrollment";

		model.forEach(function(dependent) {
			var disableEnrollment = dependent.get('isCourtOrderActive') || employeeEnrollment.get('disableDependentCoverageChanges');
			dependent.set('disable' + enrollmentType, disableEnrollment);
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var employee = controller.get('employee');
		var currentEnrollment = controller.get('currentEnrollment');
		var currentDentalEnrollment = controller.get('currentDentalEnrollment');
		var currentVisionEnrollment = controller.get('currentVisionEnrollment');

		var haveDependents = false;
		var medicalSCOECreated = controller.get('shortCircuitOEStats.atleastOneMedicalSCOEStepsComplete');
		var dentalSCOECreated = controller.get('shortCircuitOEStats.dentalSCOEStepsComplete');
		var visionSCOECreated = controller.get('shortCircuitOEStats.visionSCOEStepsComplete');

		var showDependentEnrolledInMedical = false;
		var showDependentEnrolledInDental = false;
		var showDependentEnrolledInVision = false;

		model.forEach(function(dependent) {
			if (dependent.get('type') != 'self') {
				haveDependents = true;
			}
			else {
				if (currentEnrollment) {
					if (currentEnrollment.get('medicalPlan')) {
						dependent.set('medicalCheckBoxStatus', true);
					}
					// If there is short circuit OE and there is old Plan. This means that
					// there are both old and new plan in short circuit OE. In that case, we should
					// show the dependent enroll**ed** in medical. This is going to repeat for dental
					// and vision as well so listen up here.
					if (medicalSCOECreated && currentEnrollment.get('oldPlan')) {
						showDependentEnrolledInMedical = true;
						dependent.set('enrolledInMedical', true);
						// Employee declined their previous enrollment.
						if (currentEnrollment.get('oldPlan') == -1) {
							showDependentEnrolledInMedical = false;
							dependent.set('enrolledInMedical', false);
						}
					}

				}

				if (currentDentalEnrollment) {
					if (currentDentalEnrollment.get('dentalPlan')) {
						dependent.set('dentalCheckBoxStatus', true);
					}

					if (dentalSCOECreated && currentDentalEnrollment.get('oldPlan')) {
						showDependentEnrolledInDental = true;
						dependent.set('enrolledInDental', true);
						if (currentDentalEnrollment.get('oldPlan') == -1) {
							showDependentEnrolledInDental = false;
							dependent.set('enrolledInDental', false);
						}
					}
				}

				if (currentVisionEnrollment) {
					if (currentVisionEnrollment.get('visionPlan')) {
						dependent.set('visionCheckBoxStatus', true);
					}
					if (visionSCOECreated && currentVisionEnrollment.get('oldPlan')) {
						showDependentEnrolledInVision = true;
						dependent.set('enrolledInVision', true);
						if (currentVisionEnrollment.get('oldPlan') == -1) {
							showDependentEnrolledInVision = false;
							dependent.set('enrolledInVision', false);
						}
					}
				}
				// Now it is time to reconcile. If we have no reason to show the enroll**ed**inMedical/Dental/Vision
				// then we should set the enrolledInMedical/Vision/Dental to false.
				if (!showDependentEnrolledInMedical) {
					dependent.set('enrolledInMedical', false);
				}
				if (!showDependentEnrolledInDental) {
					dependent.set('enrolledInDental', false);
				}
				if (!showDependentEnrolledInVision) {
					dependent.set('enrolledInVision', false);
				}
			}
		});

		controller.set('showDependentEnrolledInMedical', showDependentEnrolledInMedical);
		controller.set('showDependentEnrolledInDental', showDependentEnrolledInDental);
		controller.set('showDependentEnrolledInVision', showDependentEnrolledInVision);
		controller.set('haveDependents', haveDependents);
	}
});
App.EmployeeEnrollmentv1DependentsDependentsinfoRoute = App.EmployeeEnrollmentv1DependentsRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		var currentEnrollment = controller.get('currentEnrollment');
		var currentDentalEnrollment = controller.get('currentDentalEnrollment');
		var currentVisionEnrollment = controller.get('currentVisionEnrollment');
		if (currentEnrollment) {
			if (currentEnrollment.get('coverage_type') == 'dental') {
				controller.set('ancillaryEnrollment', true);
			}
			else if (currentEnrollment.get('coverage_type') == 'vision') {
				controller.set('ancillaryEnrollment', true);
			}
			else {
				controller.set('ancillaryEnrollment', false);
			}
		}
		else {
			if (currentDentalEnrollment || currentVisionEnrollment) {
				controller.set('ancillaryEnrollment', true);
			}
			else {
				controller.set('ancillaryEnrollment', false);
			}
		}
	}
});
App.EmployeeEnrollmentv1DependentsDependentsphysicianRoute = App.EmployeeEnrollmentv1DependentsRoute.extend();
App.EmployeeEnrollmentv1DependentsDependentsdentistRoute = App.EmployeeEnrollmentv1DependentsDependentsphysicianRoute.extend();
App.EmployeeEnrollmentv1DependentsWaiverRoute = _EmployeeEnrollmentSectionRoute.extend({
	model: function(params) {
		var employeePromise = dashboard('employee');
		var currentEnrollmentPromises = Ember.RSVP.all([
			dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
		]);

		var existingWaivers = currentEnrollmentPromises.then(function(enrollments) {
			return Ember.RSVP.all(enrollments.map(function(enrollment) {
				return App.EnrollmentWaiver.find({
					'enrollment_id': enrollment.get('id'),
				});
			})).then(function(waiversOrNot) {
				var flatWaivers = [];

				waiversOrNot.forEach(function(maybeWaivers) {
					if (maybeWaivers.get('length') > 0) {
						flatWaivers = flatWaivers.concat(maybeWaivers.toArray());
					}
				});

				return Ember.RSVP.resolve(flatWaivers);
			});
		});

		return this.all(employeePromise, {
				'company': dashboard('company'),
				'dependents': dashboard('employee.dependents'),
				'healthEnrollments': dashboard('company.healthEnrollments'),
				'healthCarriers': dashboard('company.healthCarriers'),
				'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
				'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
				'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
				'enrollments': currentEnrollmentPromises,
				'existingWaivers': existingWaivers,
			}
		);
	}
});
App.EmployeeEnrollmentv1PreviouscoverageRoute = _EmployeeEnrollmentSectionRoute.extend({
	model: function(params) {
		return this.all(dashboard('employee.dependents'), {
			'employee': dashboard('employee'),
			'company': dashboard('company'),
			'previousCoverageInfo': App.PreviousCoverage.find('me'),
		});
	},
	afterModel: function(model) {
		return Ember.RSVP.all(model.map(function(dependent) {
			return dependent.get('previousCoverage');
		}));
	},
});
App.EmployeeEnrollmentv1PreviouscoverageRecentcoverageRoute = App.EmployeeEnrollmentv1PreviouscoverageRoute.extend();
App.EmployeeEnrollmentv1PreviouscoverageOthercoverageRoute = App.EmployeeEnrollmentv1PreviouscoverageRoute.extend();
App.EmployeeEnrollmentv1PreviouscoverageOtherdentalcoverageRoute = App.EmployeeEnrollmentv1PreviouscoverageRoute.extend();
App.EmployeeEnrollmentv1OfficerstatementRoute = _EmployeeEnrollmentSectionRoute.extend({
	model: function() {
		return this.all(App.OfficerStatement.find("me"), { "employee": dashboard('employee') });
	}
});
App.EmployeeEnrollmentv1OfficerstatementOfficerstatementRoute = App.EmployeeEnrollmentv1OfficerstatementRoute.extend();
App.EmployeeEnrollmentv1MedicaldetailsRoute = _EmployeeEnrollmentSectionRoute.extend();

App._EmployeeEnrollmentMedicaldetailsRoute = App.EmployeeEnrollmentv1MedicaldetailsRoute.extend({
	model: function() {
		return this.all(this.get('modelClass').find('me'), {
			'employee': dashboard('employee')
		});
	}
});

App.EmployeeEnrollmentfinishRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	beforeModel: function(transition) {
		//redirect to React employee enrollment flow
		window.location.href = '/app/benconnect/#/employee-enrollment';
		return Ember.RSVP.resolve();
	},
	model: function(params) {
		return this.all(dashboard('employee'), {
			'dependents': dashboard('employee.dependents'),
			'signature': Ember.Object.create({name: null, signature: null}),
			'company': dashboard('company'),
			'employeeCobraEnrolleeProperties': dashboard('employee.employeeCobraEnrolleeProperties'),
			'employeeCobra': dashboard('employee.employeeCobra'),
			'companyHsa': dashboard('company.hsa'),
			'employeeHsa': dashboard('employee.hsa'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'qualifyingEvents': dashboard('employee.qualifyingEvents'),
			'settings': dashboard('company.settings')
		});
	},
	afterModel: function(model) {
		var promiseArray = [];
		this.otherModels.dependents.forEach(function(dependent) {
			var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
			linesOfCoverage.forEach(function(lineOfCoverage) {
				if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
					});
				}
			});
		});
		var currentEnrollment = this.otherModels.currentEnrollment;
		var currentDentalEnrollment = this.otherModels.currentDentalEnrollment;
		var currentVisionEnrollment = this.otherModels.currentVisionEnrollment;
		var employee = this;

		if (currentEnrollment && currentEnrollment.get('medicalPlan') && currentEnrollment.get('allowEditReviewPlans')) {
			var medicalPlanId = currentEnrollment.get('medicalPlan.id');
			promiseArray.push(App.EPlan.find(medicalPlanId).then(function(medicalPlan) {
				this.otherModels['ePlan'] = medicalPlan;
			}.bind(this)));
		}

		if (currentDentalEnrollment && currentDentalEnrollment.get('dentalPlan') && currentDentalEnrollment.get('allowEditReviewPlans')) {
			var dentalPlanId = currentDentalEnrollment.get('dentalPlan.id');
			promiseArray.push(App.EDentalPlan.find(dentalPlanId).then(function(dentalPlan) {
				this.otherModels['eDentalPlan'] = dentalPlan;
			}.bind(this)));
		}

		if (currentVisionEnrollment && currentVisionEnrollment.get('visionPlan') && currentVisionEnrollment.get('allowEditReviewPlans')) {
			var visionPlanId = currentVisionEnrollment.get('visionPlan.id');
			promiseArray.push(App.EVisionPlan.find(visionPlanId).then(function(visionPlan) {
				this.otherModels['eVisionPlan'] = visionPlan;
			}.bind(this)));
		}

		var qualifyingEvent = null;
		if (currentEnrollment) {
			var medicalPlan = currentEnrollment.get('medicalPlan');
			if (medicalPlan) {
				promiseArray.push(medicalPlan.then(function(plan) {
					this.otherModels['chosenMedicalPlan'] = plan;
				}.bind(this)));
			}
			qualifyingEvent = currentEnrollment.get('qualifyingEvent');
			if (qualifyingEvent) {
				promiseArray.push(qualifyingEvent);
			}
		}

		if (currentDentalEnrollment) {
			var dentalPlan = currentDentalEnrollment.get('dentalPlan');
			if (dentalPlan) {
				promiseArray.push(dentalPlan.then(function(dentalPlan) {
					this.otherModels['chosenDentalPlan'] = dentalPlan;
				}.bind(this)));
			}
			qualifyingEvent = currentDentalEnrollment.get('qualifyingEvent');
			if (qualifyingEvent) {
				promiseArray.push(qualifyingEvent);
			}
		}

		if (currentVisionEnrollment) {
			var visionPlan = currentVisionEnrollment.get('visionPlan');
			if (visionPlan) {
				promiseArray.push(visionPlan.then(function(visionPlan) {
					this.otherModels['chosenVisionPlan'] = visionPlan;
				}.bind(this)));
			}
			qualifyingEvent = currentVisionEnrollment.get('qualifyingEvent');
			if (qualifyingEvent) {
				promiseArray.push(qualifyingEvent);
			}
		}

		return Ember.RSVP.all(promiseArray);
	},
	medicalPlanCost: function() {
		var medicalCost = 0;
		var medicalPlan = this.get('medicalPlan');
		if (medicalPlan && !medicalPlan.get('isShortCircuitPlan')) {
			if (medicalPlan.get('familyPremium')) {
				this.set('medicalYourCost', medicalPlan.get(this.get('medicalPlanType') + "Premium"));
				this.set('medicalYourContribution', medicalPlan.get(this.get('medicalPlanType') + "Contribution"));
			}
			else {
				var dependents = this.get('dependents');
				var params = 'employee=' + this.get('model.id') + '&planId=' + medicalPlan.get('id') + '&dependents=';
				dependents.forEach(function(item) {
					if (item.get('medicalCheckBoxStatus') && !item.get('isSelf')) {
						params += item.get('id') + ",";
					}
				});
				var self = this;
				$.ajax({
					url: '/custom_api/getEmployeePremiums?' + params,
					type: "get",
					success: function(response) {
						self.set('medicalYourCost', response.youCost);
						self.set('medicalYourContribution', response.youContribution);
					}
				});
			}
		}

		return 0;
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		var employee = model;
		var signature = controller.get('signature');
		signature.set('signature', null);
		controller.set('employee', employee);
	}
});
