
// some helper functions
_LifeDisabilityComputedPremiumMixin = Ember.Mixin.create({
	getComputedPremiums: function(lineOfCoverage, applicableClass, objectIds, amount) {
		objectIds = (objectIds) ? objectIds : [];

		var premiumResults = {};
		Ember.$.ajax({
			type: "GET",
			async: false,
			url: "/custom_api/lifedisability/frontendhelper",
			data: {
				lineOfCoverage: lineOfCoverage,
				applicableClass: applicableClass,
				objectIds: objectIds,
				attr: 'computedPremium',
				setAttrMap: JSON.stringify({
					amount: amount
				})
			},
			contentType: 'application/json'
		}).then(function (results) {
			for(var objId in results) {
				premiumResults[Number(objId)] = Number(results[objId]);
			}
		});
		return premiumResults;
	},

	// Sums the values in a dictionary returned by the result of a function (assumes a flat key->value dict)
	getFunctionSummator: function(fn) {
		return function() {
			var results = fn.apply(this, arguments);

			var sum = 0;
			for(var key in results) {
				sum += results[key];
			}
			return sum;
		};
	}
});

_LifeDisabilityDependentMixin = Ember.Mixin.create({
	canEnrollDependents: function() {
		var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
		return voluntaryPlan && voluntaryPlan.get('applicableEnrollees') != _LifeDisabilityApplicableEnrollees.EMPLOYEE_ONLY;
	}.property('voluntaryPlans.@each.applicableEnrollees'),
});

_LifeDisabilityUtilityMixin = Ember.Mixin.create(_LifeDisabilityDependentMixin, {
	deleteEnrollmentsAndPlans: function(plan, isDeleteEmployeeObjects, isDeleteDependentObjects) {
		var deletePromises = [];

		if (isDeleteDependentObjects) {
			this.get('employee.dependents').forEach(function (dependent) {
				if (!dependent.get('isSelf')) {
					var dependentEnrollment = this.get('dependentEnrollments').findBy('dependent', dependent);
					if (dependentEnrollment) {
						var dependentLifePlan = dependentEnrollment.get('dependentPlans').findBy('plan', plan);
						if (dependentLifePlan) {
							// delete references
							dependentEnrollment.get('dependentPlans').removeObject(dependentLifePlan);

							// delete plan, and if all plans deleted, delete dependent enrollment
							deletePromises.push(dependentLifePlan.destroyRecord().then(function() {
								if (!dependentEnrollment.get('dependentPlans.length')) {
									this.get('employeeEnrollment.dependentLifeDisabilityEnrollments').removeObject(dependentEnrollment);
									return dependentEnrollment.destroyRecord();
								}
							}.bind(this)));
						}
					}
				}
			}.bind(this));
		}

		if (isDeleteEmployeeObjects) {
			var employeeLifePlan = this.get('employeePlans').findBy('plan', plan);
			if (employeeLifePlan) {
				// delete references
				this.get('employeeEnrollment.employeePlans').removeObject(employeeLifePlan);

				// delete plan
				deletePromises.push(employeeLifePlan.destroyRecord());
			}
		}

		return Ember.RSVP.all(deletePromises).then(function() {
			return this.get('_employeeLifeDisabilityEnrollmentApi').reload();
		}.bind(this));
	},
	saveReloadAndTransition: function(isRoute1, nextRoute1, nextRoute2) {
		this.saveAndContinue().then(function() {
			var reloadPromises = [
				this.get('_employeeLifeDisabilityEnrollmentApi').reload(),
				this.get('employeeEnrollment').reload(),
				this.get('dependentEnrollments').reload()
			];

			Ember.RSVP.all(reloadPromises).then(function() {
				if (isRoute1) {
					this.transitionToRoute(nextRoute1);
				}
				else {
					this.transitionToRoute(nextRoute2);
				}
			}.bind(this));
		}.bind(this));
	},
	createEmployeePlan: function() {
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LIFE) {
			return App.EmployeeLifePlanNew.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.ADD) {
			return App.EmployeeAddPlan.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.STD) {
			return App.EmployeeStdPlanNew.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LTD) {
			return App.EmployeeLtdPlanNew.createRecord();
		}
	},
	createDependentPlan: function() {
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LIFE) {
			return App.DependentLifePlan.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.ADD) {
			return App.DependentAddPlan.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.STD) {
			return App.DependentStdPlan.createRecord();
		}
		if (this.get('lineOfCoverage') == _LDEAbstractModel.prototype.LTD) {
			return App.DependentLtdPlan.createRecord();
		}
	},

	getBeneficiariesOrEnrollmentFinish: function(employeeEnrollment) {
		if ([_LDEAbstractModel.prototype.LIFE, _LDEAbstractModel.prototype.ADD].indexOf(employeeEnrollment.get('lineOfCoverage')) >= 0) {
			return "employee."+this.get("transitionLineOfCoverage")+".beneficiaries";
		}
		return "employee."+this.get("transitionLineOfCoverage")+".enrollmentfinish";
	},
});

/* Dynamic Premium Mixin */
_PlanPremiumReloadMixin = Ember.Mixin.create(_LifeDisabilityComputedPremiumMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);
		var computePremium = this.getFunctionSummator(this.getComputedPremiums);

		[controller.get('employeePlans'), controller.get('dependentPlans')].forEach(function(plans) {
			if (plans) {
				plans.forEach(function(plan) {
					Ember.defineProperty(plan, 'premium', Ember.computed('amount', function() {
						var applicableClass = ((this instanceof App.EmployeeLifePlanNew) || (this instanceof App.EmployeeAddPlan) || (this instanceof App.EmployeeStdPlanNew) || (this instanceof App.EmployeeLtdPlanNew)) ? _LifeDisabilityApplicableClasses.EMPLOYEE_PLAN : _LifeDisabilityApplicableClasses.DEPENDENT_PLAN;
						return computePremium(this.get('plan.planLineOfCoverage'), applicableClass, [this.get('id')], this.get('amount'));
					}));
				});
			}
		});
	},
});

zen._EnrollmentCompleteRedirectMixin = Ember.Mixin.create({
	redirect: function() {
		this._super();
		// if employee already finished enrolling, redirect to dashboard
		var employeeEnrollment = this.otherModels['employeeEnrollment'];
		if(employeeEnrollment && employeeEnrollment.get('isEnrollmentComplete')) {
			this.transitionTo("");
		}
	}
});

zen._AuthEmployeeMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
		return dashboard('employee').then(function (employee) {
			if (!employee) {
				throw new Error('Not Authorized');
			}
		});
	}
});

_EmployeeLifeDisabilityRoute = Ember.Route.extend(_MultiModelMixin, zen._AuthEmployeeMixin, _PlanPremiumReloadMixin, {
	afterModel: function() {
		var promiseArray = [];
		this.otherModels['plans'].forEach(function(plan) {
			promiseArray.push(thenpath(plan, 'restrictions'));
			promiseArray.push(thenpath(plan, 'rates'));
		});
		return Ember.RSVP.all(promiseArray);
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('basicPlans', controller.get('plans').filterProperty('planType', 'basic').slice(0, 1));
		controller.set('voluntaryPlans', controller.get('plans').filterProperty('planType', 'voluntary').slice(0, 1));

		controller.get('dependents').forEach(function(dependent) {
			dependent.set('isSelected', false);
		});

		var voluntaryPlan = controller.get('voluntaryPlans').objectAt(0);
		controller.get('dependentEnrollments').forEach(function(dependentEnrollment) {
			dependentEnrollment.set('dependent.isSelected', !!dependentEnrollment.get('dependentPlans').findProperty('plan', voluntaryPlan));
		});
		controller.set('errorText', '');

		// short circuit
		controller.set('isShortCircuit', controller.get('plans').any(function(plan) {
			return plan && plan.get('isShortCircuitPlan');
		}));
	}
});


_EmployeeLifeRoute = _EmployeeLifeDisabilityRoute.extend({
	model: function() {
		return this.all(dashboard('employee'), {
			"employee": dashboard('employee'),
			"beneficiaries": dashboard('employee.beneficiaries'),
			"dependents": dashboard('employee.dependents'),
			"_employeeLifeDisabilityEnrollmentApi": employeeLifeDisabilityEnrollmentApi(''),
			"plans": employeeLifeDisabilityEnrollmentApi('lifePlans'),
			"employeeEnrollment": employeeLifeDisabilityEnrollmentApi('employeeLifeEnrollment'),
			"dependentEnrollments": employeeLifeDisabilityEnrollmentApi('dependentLifeEnrollments'),
			"employeePlans": employeeLifeDisabilityEnrollmentApi('employeeLifePlans'),
			"dependentPlans": employeeLifeDisabilityEnrollmentApi('dependentLifePlans'),
			"employeeAddEnrollment": employeeLifeDisabilityEnrollmentApi('employeeAddEnrollment'),
			"employeeStdEnrollment": employeeLifeDisabilityEnrollmentApi('employeeStdEnrollment'),
			"employeeLtdEnrollment": employeeLifeDisabilityEnrollmentApi('employeeLtdEnrollment'),
			"adndPlans": employeeLifeDisabilityEnrollmentApi('addPlans'),
			"stdPlans": employeeLifeDisabilityEnrollmentApi('stdPlans'),
			"ltdPlans": employeeLifeDisabilityEnrollmentApi('ltdPlans'),

			"prevEnrollment": employeeLifeDisabilityEnrollmentApi('prevEmployeeLifeEnrollment'),
			"prevPlans": employeeLifeDisabilityEnrollmentApi('prevLifePlans'),
			"prevDependentEnrollments": employeeLifeDisabilityEnrollmentApi('prevDependentLifeEnrollments'),
			"prevEmployeePlans": employeeLifeDisabilityEnrollmentApi('prevEmployeeLifePlans'),
			"prevDependentPlans": employeeLifeDisabilityEnrollmentApi('prevDependentLifePlans'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('lineOfCoverage', _LDEAbstractModel.prototype.LIFE);
		controller.set('transitionLineOfCoverage', "life");
		controller.set('displayLineOfCoverage', "Life");
		controller.set('shortDisplayLineOfCoverage', "Life");
		controller.set('objectLineOfCoverage', "Life");
		controller.set('legalLineOfCoverage', "life");
		controller.set('isLife', true);
	}
});

_EmployeeAdndRoute = _EmployeeLifeDisabilityRoute.extend({
	model: function() {
		return this.all(dashboard('employee'), {
			"employee": dashboard('employee'),
			"dependents": dashboard('employee.dependents'),
			"_employeeLifeDisabilityEnrollmentApi": employeeLifeDisabilityEnrollmentApi(''),
			"plans": employeeLifeDisabilityEnrollmentApi('addPlans'),
			"employeeEnrollment": employeeLifeDisabilityEnrollmentApi('employeeAddEnrollment'),
			"dependentEnrollments": employeeLifeDisabilityEnrollmentApi('dependentAddEnrollments'),
			"employeePlans": employeeLifeDisabilityEnrollmentApi('employeeAddPlans'),
			"dependentPlans": employeeLifeDisabilityEnrollmentApi('dependentAddPlans'),
			"employeeStdEnrollment": employeeLifeDisabilityEnrollmentApi('employeeStdEnrollment'),
			"employeeLtdEnrollment": employeeLifeDisabilityEnrollmentApi('employeeLtdEnrollment'),
			"stdPlans": employeeLifeDisabilityEnrollmentApi('stdPlans'),
			"ltdPlans": employeeLifeDisabilityEnrollmentApi('ltdPlans'),

			"prevEnrollment": employeeLifeDisabilityEnrollmentApi('prevEmployeeAddEnrollment'),
			"prevPlans": employeeLifeDisabilityEnrollmentApi('prevAddPlans'),
			"prevDependentEnrollments": employeeLifeDisabilityEnrollmentApi('prevDependentAddEnrollments'),
			"prevEmployeePlans": employeeLifeDisabilityEnrollmentApi('prevEmployeeAddPlans'),
			"prevDependentPlans": employeeLifeDisabilityEnrollmentApi('prevDependentAddPlans'),
		});
	},

	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('lineOfCoverage', _LDEAbstractModel.prototype.ADD);
		controller.set('transitionLineOfCoverage', "adnd");
		controller.set('displayLineOfCoverage', "AD&D");
		controller.set('shortDisplayLineOfCoverage', "AD&D");
		controller.set('objectLineOfCoverage', "Add");
		controller.set('legalLineOfCoverage', "AD&D");
		controller.set('isLife', false);
	}
});

_EmployeeStdRoute = _EmployeeLifeDisabilityRoute.extend({
	model: function() {
		return this.all(dashboard('employee'), {
			"employee": dashboard('employee'),
			"dependents": dashboard('employee.dependents'),
			"_employeeLifeDisabilityEnrollmentApi": employeeLifeDisabilityEnrollmentApi(''),
			"plans": employeeLifeDisabilityEnrollmentApi('stdPlans'),
			"employeeEnrollment": employeeLifeDisabilityEnrollmentApi('employeeStdEnrollment'),
			"dependentEnrollments": employeeLifeDisabilityEnrollmentApi('dependentStdEnrollments'),
			"employeePlans": employeeLifeDisabilityEnrollmentApi('employeeStdPlans'),
			"dependentPlans": employeeLifeDisabilityEnrollmentApi('dependentStdPlans'),
			"employeeLtdEnrollment": employeeLifeDisabilityEnrollmentApi('employeeLtdEnrollment'),
			"ltdPlans": employeeLifeDisabilityEnrollmentApi('ltdPlans'),

			"prevEnrollment": employeeLifeDisabilityEnrollmentApi('prevEmployeeStdEnrollment'),
			"prevPlans": employeeLifeDisabilityEnrollmentApi('prevStdPlans'),
			"prevDependentEnrollments": employeeLifeDisabilityEnrollmentApi('prevDependentStdEnrollments'),
			"prevEmployeePlans": employeeLifeDisabilityEnrollmentApi('prevEmployeeStdPlans'),
			"prevDependentPlans": employeeLifeDisabilityEnrollmentApi('prevDependentStdPlans'),
		});
	},

	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('lineOfCoverage', _LDEAbstractModel.prototype.STD);
		controller.set('transitionLineOfCoverage', "std");
		controller.set('displayLineOfCoverage', "Short Term Disability");
		controller.set('shortDisplayLineOfCoverage', "STD");
		controller.set('objectLineOfCoverage', "Std");
		controller.set('legalLineOfCoverage', "std");
		controller.set('isLife', false);
	}
});

_EmployeeLtdRoute = _EmployeeLifeDisabilityRoute.extend({
	model: function() {
		return this.all(dashboard('employee'), {
			"employee": dashboard('employee'),
			"dependents": dashboard('employee.dependents'),
			"_employeeLifeDisabilityEnrollmentApi": employeeLifeDisabilityEnrollmentApi(''),
			"plans": employeeLifeDisabilityEnrollmentApi('ltdPlans'),
			"employeeEnrollment": employeeLifeDisabilityEnrollmentApi('employeeLtdEnrollment'),
			"dependentEnrollments": employeeLifeDisabilityEnrollmentApi('dependentLtdEnrollments'),
			"employeePlans": employeeLifeDisabilityEnrollmentApi('employeeLtdPlans'),
			"dependentPlans": employeeLifeDisabilityEnrollmentApi('dependentLtdPlans'),

			"prevEnrollment": employeeLifeDisabilityEnrollmentApi('prevEmployeeLtdEnrollment'),
			"prevPlans": employeeLifeDisabilityEnrollmentApi('prevLtdPlans'),
			"prevDependentEnrollments": employeeLifeDisabilityEnrollmentApi('prevDependentLtdEnrollments'),
			"prevEmployeePlans": employeeLifeDisabilityEnrollmentApi('prevEmployeeLtdPlans'),
			"prevDependentPlans": employeeLifeDisabilityEnrollmentApi('prevDependentLtdPlans'),
		});
	},

	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('lineOfCoverage', _LDEAbstractModel.prototype.LTD);
		controller.set('transitionLineOfCoverage', "ltd");
		controller.set('displayLineOfCoverage', "Long Term Disability");
		controller.set('shortDisplayLineOfCoverage', "LTD");
		controller.set('objectLineOfCoverage', "Ltd");
		controller.set('legalLineOfCoverage', "ltd");
		controller.set('isLife', false);
	}
});

_EmployeeLifeDisabilityController = _TransactionSavingController.extend(_RollbackOnDeactivateMixin, _LifeDisabilityUtilityMixin, {
	validations: Ember.computed(function() {
		return Ember.Set.create();
	}).property(''),

	checkSave: function() {
		if (this.get('validations.length')) {
			this.set('errorText', "Please enter valid values for coverage amount.");
			return false;
		}
		this.set('errorText', "");
		return true;
	}
});

App.EmployeeLifeRoute = _EmployeeLifeRoute.extend();
App.EmployeeAdndRoute = _EmployeeAdndRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life', {controller: controller});
	},
});
App.EmployeeStdRoute = _EmployeeStdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life', {controller: controller});
	},
});
App.EmployeeLtdRoute = _EmployeeLtdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life', {controller: controller});
	},
});

_NavControllerMixin = Ember.Mixin.create(_LifeDisabilityDependentMixin, {
	isAllShownSectionsComplete: function() {
		if (this.get('basicplanLink') && !this.get('basicplanStatus')) {
			return false;
		}
		if (this.get('voluntaryLink') && !this.get('voluntaryStatus')) {
			return false;
		}
		if (this.get('voluntaryselfenrollLink') && !this.get('voluntaryselfenrollStatus')) {
			return false;
		}
		if (this.get('dependentsLink') && !this.get('dependentsStatus')) {
			return false;
		}
		if (this.get('dependentenrollLink') && !this.get('dependentenrollStatus')) {
			return false;
		}
		return true;
	}.property('basicplanLink', 'basicplanStatus', 'voluntaryLink', 'voluntaryStatus',
				'voluntaryselfenrollLink', 'voluntaryselfenrollStatus', 'dependentsLink', 'dependentsStatus', 'dependentenrollLink', 'dependentenrollStatus'),

	isBasicPlanPresent: function() {
		return !!this.get('plans').findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
	}.property('plans.@each.planType'),

	isVoluntaryPlanPresent: function() {
		return !!this.get('plans').findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY);
	}.property('plans.@each.planType'),

	navSectionTitleLink: function() {
		return this.get('basicplanLink') || this.get('voluntaryLink');
	}.property('basicplanLink', 'voluntaryLink'),

	basicplanLink: function() {
		if (!this.get('isBasicPlanPresent')) {
			return null;
		}
		return 'employee.'+this.get('transitionLineOfCoverage')+'.basicplan';
	}.property('isBasicPlanPresent'),
	basicplanStatus: function() {
		return !!(this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC));
	}.property('employeePlans.@each'),

	voluntaryLink: function() {
		if (!this.get('isVoluntaryPlanPresent')) {
			return null;
		}

		// do not show link until they create basic eelp
		if (this.get('isBasicPlanPresent')) {
			if (!this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC)) {
				return null;
			}
		}
		return 'employee.'+this.get('transitionLineOfCoverage')+'.voluntary';
	}.property('isBasicPlanPresent', 'isVoluntaryPlanPresent', 'employeePlans.@each'),
	voluntaryStatus: function() {
		// if there is a voluntary plan, then for sure finished
		var voluntaryEelp = this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY);
		if(voluntaryEelp) {
			return true;
		}

		// unfortunately if basic is available, there's no clear cut way to determine if employee declined voluntary
		// so we'll just assume they completed
		if (this.get('isBasicPlanPresent')) {
			return true;
		} else {
			return this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.DECLINE;
		}
	}.property('isBasicPlanPresent', 'employeePlans.@each', 'employeeEnrollment.status', 'router.url'),

	voluntaryselfenrollLink: function() {
		if (!(this.get('isVoluntaryPlanPresent') && this.get('employeePlans.length') &&
				this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY))) {
			return null;
		}
		return 'employee.'+this.get('transitionLineOfCoverage')+'.voluntaryselfenroll';
	}.property('isVoluntaryPlanPresent', 'employeePlans.@each'),
	voluntaryselfenrollStatus: function() {
		var employeePlan = this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY);
		return !!(employeePlan && employeePlan.get('amount') != 0 && !employeePlan.get('isDirty'));
	}.property('employeePlans.@each.amount'),

	dependentsLink: function() {
		this.set('voluntaryPlans', this.get('plans').filterProperty('planType', 'voluntary').slice(0, 1));
		if (!((this.get('voluntaryselfenrollLink') && this.get('canEnrollDependents') && this.get('dependentEnrollments.length')) ||
				(location.href.indexOf('dependents') != -1))) {
			return null;
		}
		return 'employee.'+this.get('transitionLineOfCoverage')+'.dependents';
	}.property('canEnrollDependents', 'voluntaryselfenrollLink', 'plans.@each'),
	dependentsStatus: function() {
		return !!this.get('dependentEnrollments.length');
	}.property('dependentEnrollments.@each'),

	dependentenrollLink: function() {
		if (!this.get('dependentsLink') || !this.get('dependentEnrollments.length')) {
			return null;
		}
		return 'employee.'+this.get('transitionLineOfCoverage')+'.dependentenroll';
	}.property('dependentsLink', 'dependentEnrollments.@each'),
	dependentenrollStatus: function() {
		return !!(this.get('dependentPlans.length') && !this.get('dependentPlans').findProperty('amount', 0) && !this.get('dependentPlans').findProperty('isDirty'));
	}.property('dependentPlans.@each.amount'),

	eoiLink: function() {
		if(!this.get('isAllShownSectionsComplete')) {
			return null;
		}
		if(this.get("switches.eoiProcess") && !this.get('employeeEnrollment.isAnyVoluntaryEnrollmentOverGI')) {
			return null;
		}
		if(!this.get("switches.eoiProcess") && !this.get('employeeEnrollment.isEnrollmentOverGI')) {
			return null;
		}
		if(this.get("switches.eoiProcess")) {
			return 'employee.' + this.get('transitionLineOfCoverage') + '.eoinew';
		}
		else {
			return 'employee.' + this.get('transitionLineOfCoverage') + '.eoi';
		}
	}.property('employeeEnrollment.isEnrollmentOverGI', 'isAllShownSectionsComplete'),
	eoiStatus: function() {
		return !this.get('employeeEnrollment.isEnrollmentPendingEOI');
	}.property('employeeEnrollment.isEnrollmentPendingEOI'),


	beneficiariesLink: function() {
	 	if(!this.get('isAllShownSectionsComplete')) {
	 		return null;
	 	}
		if(!this.get('isLife')) {
			return null;
		}

		return 'employee.'+this.get('transitionLineOfCoverage')+'.beneficiaries';
	}.property('employeeEnrollment.status', 'isLife', 'isAllShownSectionsComplete'),
	beneficiariesStatus: function() {
		return !!(this.get('beneficiaries.length'));
	}.property('beneficiaries', 'employeeEnrollment.status'),

	showNav: function() {
		if (!this.get('employeeEnrollment') ||
			this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE ||
			this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.APPROVED) {

			return false;
		}
		else {
			return true;
		}
	}.property('employeeEnrollment'),
});

App.EmployeeLifeController = _TransactionSavingController.extend(_NavControllerMixin, {});
App.EmployeeAdndController = _TransactionSavingController.extend(_NavControllerMixin, {});
App.EmployeeStdController = _TransactionSavingController.extend(_NavControllerMixin, {});
App.EmployeeLtdController = _TransactionSavingController.extend(_NavControllerMixin, {});


/* Employee L&D - Basic plan */
_EmployeeLifeDisabilityBasicplanMixin = Ember.Mixin.create({
	multipleBasicPlans: function() {
		return this.get('basicPlans.length') > 1;
	}.property('basicPlans.@each'),

	overGIBasicPlans: function() {
		var overGIPlans = [];
		this.get('basicPlans').forEach(function(basicPlan) {
			Ember.$.ajax({
				type: "GET",
				async: false,
				url: "/custom_api/lifedisability/ramObjectComputeProperty",
				contentType: 'application/json',
				data: {
					lineOfCoverage: this.get('lineOfCoverage'),
					applicableClass: _LifeDisabilityApplicableClasses.EMPLOYEE_PLAN,
					attr: 'isOverGI',
					setAttrMap: JSON.stringify({
						amount: basicPlan.get('eeRestriction.maxAmount') || 0
					}),
					setObjMap: JSON.stringify({
						plan: {
							applicableClass: _LifeDisabilityApplicableClasses.PLAN,
							id: basicPlan.get('id')
						},
						enrollment: {
							applicableClass: _LifeDisabilityApplicableClasses.EMPLOYEE_ENROLLMENT,
							id: this.get('employeeEnrollment.id')
						}
					}),
				},
				success: function (result) {
					if (result['isOverGI']) { overGIPlans.push(basicPlan); }
				}
			});
		}.bind(this));

		// return list of plans over GI (should just be 1 for most part)
		return overGIPlans;
	}.property('basicPlans.@each'),

	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		this.get('basicPlans').forEach(function(basicPlan) {
			var employeePlan = this.get('employeePlans').findProperty('plan', basicPlan);

			if (!employeePlan) {
				employeePlan = this.createEmployeePlan();
			}
			employeePlan.set('enrollment', this.get('employeeEnrollment'));
			employeePlan.set('plan', basicPlan);
			if (basicPlan.get('isShortCircuitPlan')) {
				employeePlan.set('amount', 0);
			} else {
				employeePlan.set('amount', Number(basicPlan.get('eeRestriction.maxAmount')));
			}

			this.set('employeeEnrollment.status', _LDEAbstractModel.prototype.BEGIN);
			this.set('employeeEnrollment.enrollmentBegunTimestamp', new Date());
		}.bind(this));

		if (this.get('voluntaryPlans.length')) {
			this.saveReloadAndTransition(true, "employee."+this.get('transitionLineOfCoverage')+".voluntary");
		} else {
			if (this.get('employeeEnrollment.isOverGI') && !this.get("switches.eoiProcess")) {
				this.send("showModal", "employee.life.eoimodal", this);
			} else {
				this.saveReloadAndTransition(true, this.getBeneficiariesOrEnrollmentFinish(this.get('employeeEnrollment')));
			}
		}
	}
});

App.EmployeeLifeBasicplanRoute = _EmployeeLifeRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	redirect: function() {
		this._super();
		var isBasicPlanPresent = this.otherModels['plans'].findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
		if (!isBasicPlanPresent) {
			this.transitionTo("employee.life.voluntary");
		}
	}
});

App.EmployeeLifeBasicplanController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityBasicplanMixin, {});


App.EmployeeAdndBasicplanRoute = _EmployeeAdndRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.basicplan', {controller: controller});
	},
	redirect: function() {
		this._super();
		var isBasicPlanPresent = this.otherModels['plans'].findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
		if (!isBasicPlanPresent) {
			this.transitionTo("employee.adnd.voluntary");
		}
	}
});

App.EmployeeAdndBasicplanController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityBasicplanMixin, {});


App.EmployeeStdBasicplanRoute = _EmployeeStdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.basicplan', {controller: controller});
	},
	redirect: function() {
		this._super();
		var isBasicPlanPresent = this.otherModels['plans'].findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
		if (!isBasicPlanPresent) {
			this.transitionTo("employee.std.voluntary");
		}
	}
});

App.EmployeeStdBasicplanController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityBasicplanMixin, {});


App.EmployeeLtdBasicplanRoute = _EmployeeLtdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.basicplan', {controller: controller});
	},
	redirect: function() {
		this._super();
		var isBasicPlanPresent = this.otherModels['plans'].findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
		if (!isBasicPlanPresent) {
			this.transitionTo("employee.ltd.voluntary");
		}
	}
});

App.EmployeeLtdBasicplanController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityBasicplanMixin, {});


/* Employee L&D - Voluntary plan */
_EmployeeLifeDisabilityVoluntaryMixin = Ember.Mixin.create({
	multipleVolPlans: function() {
		return this.get('voluntaryPlans.length') > 1;
	}.property('voluntaryPlans.@each'),

	enrollSelfInVoluntaryPlan: function(key, value) {
		if (arguments.length > 1) {
			return value;
		}
		else {
			return (!!(this.get('employeePlans.length') &&
					this.get('employeePlans').findProperty('plan.planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY))).toString();
		}
	}.property('employeePlans.@each'),

	back: function() {
		var isBasicPlanPresent = this.get('plans').findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.BASIC);
		if (isBasicPlanPresent) {
			this.transitionToRoute("employee."+this.get('transitionLineOfCoverage')+".basicplan");
		}
		else {
			this.transitionToRoute("index");
		}
	},
	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}
		if (this.get('enrollSelfInVoluntaryPlan') == 'true') {

			var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
			var employeePlan = this.get('employeePlans').findProperty('plan', voluntaryPlan);
			if (!employeePlan) {
				employeePlan = this.createEmployeePlan();
				employeePlan.set('enrollment', this.get('employeeEnrollment'));
				employeePlan.set('plan', voluntaryPlan);
				employeePlan.set('amount', 0);
			}

			this.set('employeeEnrollment.status', _LDEAbstractModel.prototype.BEGIN);
			this.saveReloadAndTransition(true, "employee."+this.get("transitionLineOfCoverage")+".voluntaryselfenroll");
		}
		else {
			this.deleteEnrollmentsAndPlans(this.get('voluntaryPlans').objectAt(0), true, true).then(function() {
				if (this.get('employeePlans.length')) {
					if (this.get('employeeEnrollment.isAnyVoluntaryOverGI')) {
						if(this.get("switches.eoiProcess")) {
							this.saveReloadAndTransition(true, "employee." + this.get("transitionLineOfCoverage") + ".eoinew");
						}
						else {
							this.send("showModal", "employee.life.eoimodal", this);
						}

					} else {
						this.saveReloadAndTransition(true, this.getBeneficiariesOrEnrollmentFinish(this.get('employeeEnrollment')));
					}
				}
				else {
					this.set('employeeEnrollment.status', _LDEAbstractModel.prototype.DECLINE);
					this.saveReloadAndTransition(true, "employee."+this.get("transitionLineOfCoverage")+".enrollmentfinish");
				}
			}.bind(this));
		}
	}
});

App.EmployeeLifeVoluntaryRoute = _EmployeeLifeRoute.extend(zen._EnrollmentCompleteRedirectMixin);

App.EmployeeLifeVoluntaryController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryMixin, {});

App.EmployeeAdndVoluntaryRoute = _EmployeeAdndRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntary', {controller: controller});
	},
});

App.EmployeeAdndVoluntaryController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryMixin, {});

App.EmployeeStdVoluntaryRoute = _EmployeeStdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntary', {controller: controller});
	},
});

App.EmployeeStdVoluntaryController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryMixin, {});

App.EmployeeLtdVoluntaryRoute = _EmployeeLtdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntary', {controller: controller});
	},
});

App.EmployeeLtdVoluntaryController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryMixin, {});


/* Employee L&D enroll self in voluntary plan */
_EmployeeLifeDisabilityVoluntaryselfenrollMixin = Ember.Mixin.create({
	validate: function() {
		var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
		var employeePlan = this.get('employeePlans').findBy('plan', voluntaryPlan);

		var errors = [];
		errors.pushObjects(this.validateEnrolleePlan(employeePlan));
		return errors;
	},

	plan: function() {
		return this.get('voluntaryPlans').objectAt(0);
	}.property('voluntaryPlans.@each'),
	enrollDependentsInVoluntaryPlan: function() {
		return (!!this.get('dependentEnrollments.length')).toString();
	}.property('dependentEnrollments.@each'),

	back: function() {
		this.transitionToRoute("employee."+this.get('transitionLineOfCoverage')+".voluntary");
	},
	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
		var employeePlan = this.get('employeePlans').findProperty('plan', voluntaryPlan);

		if (!employeePlan) {
			employeePlan = this.createEmployeePlan();
		}
		employeePlan.set('enrollment', this.get('employeeEnrollment'));
		employeePlan.set('plan', voluntaryPlan);

		if (this.get('enrollDependentsInVoluntaryPlan') == 'true') {
			this.saveReloadAndTransition(true, "employee."+this.get('transitionLineOfCoverage')+".dependents");
		}
		else {
			this.deleteEnrollmentsAndPlans(this.get('voluntaryPlans').objectAt(0), false, true).then(function() {
				//Enable this modal for non EOI table user
				if (this.get('employeeEnrollment.isAnyVoluntaryOverGI')) {
					if(this.get("switches.eoiProcess")) {
						this.saveReloadAndTransition(true, "employee." + this.get('transitionLineOfCoverage') + ".eoinew");
					}
					else {
						this.send("showModal", "employee.life.eoimodal", this);
					}
				}
				else {
					this.saveReloadAndTransition(true, this.getBeneficiariesOrEnrollmentFinish(this.get('employeeEnrollment')));
				}
			}.bind(this));
		}
	}
});

App.EmployeeLifeVoluntaryselfenrollRoute = _EmployeeLifeRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);

		var voluntaryPlan = controller.get('voluntaryPlans').objectAt(0);
		controller.set('employeePlan', controller.get('employeePlans').findProperty('plan', voluntaryPlan));
	}
});

App.EmployeeLifeVoluntaryselfenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryselfenrollMixin, {});

App.EmployeeAdndVoluntaryselfenrollRoute = _EmployeeAdndRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);

		var voluntaryPlan = controller.get('voluntaryPlans').objectAt(0);
		controller.set('employeePlan', controller.get('employeePlans').findProperty('plan', voluntaryPlan));
	},
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntaryselfenroll', {controller: controller});
	},
});

App.EmployeeAdndVoluntaryselfenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryselfenrollMixin, {});

App.EmployeeStdVoluntaryselfenrollRoute = _EmployeeStdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);

		var voluntaryPlan = controller.get('voluntaryPlans').objectAt(0);
		controller.set('employeePlan', controller.get('employeePlans').findProperty('plan', voluntaryPlan));
	},
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntaryselfenroll', {controller: controller});
	},
});

App.EmployeeStdVoluntaryselfenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryselfenrollMixin, {});

App.EmployeeLtdVoluntaryselfenrollRoute = _EmployeeLtdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	setupController: function(controller, model) {
		this._super(controller, model);

		var voluntaryPlan = controller.get('voluntaryPlans').objectAt(0);
		controller.set('employeePlan', controller.get('employeePlans').findProperty('plan', voluntaryPlan));
	},
	renderTemplate: function(controller, model) {
		this.render('employee.life.voluntaryselfenroll', {controller: controller});
	},
});

App.EmployeeLtdVoluntaryselfenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityVoluntaryselfenrollMixin, {});

/* L&D enrollment - Dependents information */
_EmployeeLifeDisabilityDependentsMixin = Ember.Mixin.create({
	back: function() {
		this.transitionToRoute("employee."+this.get('transitionLineOfCoverage')+".voluntaryselfenroll");
	},
	addDependent: function() {
		var dependent = App.Dependent.createRecord();
		dependent.set('employee', this.get('employee'));
	},
	deleteDependent: function(dependent) {
		// if you're going to use this function, please tell Kevin Yang, this function needs to be redone
		// (not currently used so avoiding the rewrite)

		var dependentEnrollment = dependent.getLifeDisabilityEnrollment(this.get('lineOfCoverage'));
		if (dependentEnrollment) {
			dependentEnrollment.get('dependentPlans').invoke('deleteRecord');
			dependentEnrollment.deleteRecord();
		}
		dependent.deleteRecord();
	},
	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		if (!this.get('dependents').findProperty('isSelected', true)) {
			this.set('errorText', "Please select a dependent.");
			return;
		}

		var isComplete = true;
		this.get('dependents').forEach(function(dependent) {
			if (!dependent.get('isSelf')) {
				var attrs = ['firstName', 'lastName', 'socialSecurity', 'type', 'gender','dateOfBirth'];
				attrs.forEach(function(attr) {
					if (!dependent.get(attr)) {
						isComplete = false;
					}
				});
			}
		}.bind(this));

		if (!isComplete) {
			this.set('errorText', "Please complete all fields.");
			return;
		}

		this.saveAndContinue().then(function() {
			this.get('_employeeLifeDisabilityEnrollmentApi').reload().then(function() {
				this.get('employee').reload().then(function() {
					this.get('employee.dependents').forEach(function(dependent) {
						if (!dependent.get('isSelf')) {
							if (dependent.get('isSelected')) {
								if (!this.get('dependentEnrollments').findProperty('dependent', dependent)) {
									var dependentEnrollment = App.DependentLifeDisabilityEnrollment.createRecord();
									dependentEnrollment.set("dependent", dependent);
									dependentEnrollment.set("employeeLifeDisabilityEnrollment", this.get('employeeEnrollment'));
									dependentEnrollment.set("type", App.DependentLifeDisabilityEnrollment.prototype.ADDITION);
									dependentEnrollment.set("lineOfCoverage", this.get('lineOfCoverage'));
									dependentEnrollment.set("isActive", true);
									dependentEnrollment.set("isEnrollmentComplete", false);
									dependentEnrollment.set("status", _LDEAbstractModel.prototype.BEGIN);
									dependentEnrollment.set("effectiveDate", this.get('employeeEnrollment.effectiveDate'));
									dependentEnrollment.set("enrollmentBegunTimestamp", new Date());
								}
							}
							else {
								var dependentEnrollment = this.get('dependentEnrollments').findProperty('dependent', dependent);
								if (dependentEnrollment) {
									// plans should be deleted along with enrollment
									this.get('employeeEnrollment.dependentLifeDisabilityEnrollments').removeObject(dependentEnrollment);
									dependentEnrollment.deleteRecord();
								}
							}
						}
					}.bind(this));

					this.saveAndContinue().then(function() {
						this.get('_employeeLifeDisabilityEnrollmentApi').reload().then(function() {
							var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
							this.get('_employeeLifeDisabilityEnrollmentApi.dependent'+this.get('objectLineOfCoverage')+'Enrollments').forEach(function(dependentEnrollment) {
								var dependentPlan = dependentEnrollment.get('dependentPlans').findProperty('plan', voluntaryPlan);
								if (!dependentPlan) {
									dependentPlan = this.createDependentPlan();
									dependentPlan.set('enrollment', dependentEnrollment);
									dependentPlan.set('plan', voluntaryPlan);
									dependentPlan.set('amount', 0);
								}
							}.bind(this));
							this.saveReloadAndTransition(true, "employee."+this.get('transitionLineOfCoverage')+".dependentenroll");
						}.bind(this));
					}.bind(this));
				}.bind(this));
			}.bind(this));
		}.bind(this));
	}
});

App.EmployeeLifeDependentsRoute = _EmployeeLifeRoute.extend(zen._EnrollmentCompleteRedirectMixin);

App.EmployeeLifeDependentsController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentsMixin, {});

App.EmployeeAdndDependentsRoute = _EmployeeAdndRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependents', {controller: controller});
	},
});

App.EmployeeAdndDependentsController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentsMixin, {});

App.EmployeeStdDependentsRoute = _EmployeeStdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependents', {controller: controller});
	},
});

App.EmployeeStdDependentsController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentsMixin, {});

App.EmployeeLtdDependentsRoute = _EmployeeLtdRoute.extend(zen._EnrollmentCompleteRedirectMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependents', {controller: controller});
	},
});

App.EmployeeLtdDependentsController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentsMixin, {});

/* L&D - enroll dependents */
_TierMixin = Ember.Mixin.create(_LifeDisabilityComputedPremiumMixin, {
	// we dealing with elps

	hasTiers: function() {
		var checker = this.isChildTiered;

		var tiered = false;
		this.get('dependentPlans').forEach(function(delp) {
			if(checker(delp)) {
				tiered = true;
			}
		});
		return tiered;
	}.property('dependentPlans.@each'),

	tiers: function() {
		var checker = this.isChildTiered;
		var getComputedPremiums = this.getComputedPremiums;

		var childDelps = [];
		this.get('dependentPlans').forEach(function(delp) {
			if(checker(delp)) {
				childDelps.pushObject(delp);
			}
		});

		var tiers = [];
		if(childDelps && childDelps.get('length') > 0) {
			tiers.pushObject({
				delps: childDelps,
				dummyDelp: (function() {
					var dummy = childDelps[0];
					dummy.set('computedPremium', Ember.computed('amount', function() {
						// amount + getting IDs for computed premium
						var amount = this.get('amount');
						var childDelpsIDs = [];
						childDelps.forEach(function(delp) {
							delp.set('amount', amount);
							childDelpsIDs.pushObject(delp.get('id'));
						});

						// premiums
						var computedPremiums = getComputedPremiums(this.get('plan.planLineOfCoverage'), _LifeDisabilityApplicableClasses.DEPENDENT_PLAN, childDelpsIDs, amount);
						var totalPremium = 0;
						childDelps.forEach(function(delp) {
							delp.set('premium', computedPremiums[delp.get('id')]);
							totalPremium += Number(computedPremiums[delp.get('id')]);
						});

						return totalPremium;
					}));
					return dummy;
				})(),
			});
		}
		return tiers;
	}.property(),

	notTiers: function() {
		var checker = this.isChildTiered;

		var delps = [];
		this.get('dependentPlans').forEach(function(delp) {
			if(!checker(delp)) {
				delps.pushObject(delp);
			}
		});
		return delps;
	}.property(),

	// idea is checker = isChildTiered() && isDinosaurTiered() etc.
	isChildTiered: function(delp) {
		return delp.get('enrollment.dependent.type') === 'Child' && delp.get('restriction.rateType') === 'child_tiered';
	},
});

_ReloadRestrictionMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		model.get('dependentPlans.@each.restriction').forEach(function(restriction) {
			if(restriction && restriction.get('enrolleeMaxAmountStyle') === 'multiple_of_ee_selected_amount') {
				restriction.reload();
			}
		});
	}
});

_EmployeeLifeDisabilityDependentenrollMixin = Ember.Mixin.create({
	validate: function() {
		var voluntaryPlan = this.get('voluntaryPlans').objectAt(0);
		var errors = [];

		// Validate all enrollee plans
		this.get('dependentPlans').forEach(function(dependentPlan) {
			errors.pushObjects(this.validateEnrolleePlan(dependentPlan));
		}.bind(this));

		return errors;
	},

	isDependentsOverGI: function() {
		return this.get('dependentEnrollments').someProperty('isOverGI', true);
	}.property('dependentEnrollments.@each.isOverGI'),

	back: function() {
		this.transitionToRoute("employee."+this.get('transitionLineOfCoverage')+".dependents");
	},
	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		var isValid = true;
		this.get('dependentPlans').forEach(function(dependentPlan) {
			if (!dependentPlan.get('amount')) {
				isValid = false;
			}
		}.bind(this));

		if (!isValid) {
			this.set("errorText", "Please choose an amount");
			return;
		}

		if (this.get('employeeEnrollment.isAnyVoluntaryEnrollmentOverGI')) {
			if(this.get("switches.eoiProcess")) {
				this.saveReloadAndTransition(true, "employee." + this.get('transitionLineOfCoverage') + ".eoinew");
			}
			else {
				this.send("showModal", "employee.life.eoimodal", this);
			}
		}
		else {
			this.saveReloadAndTransition(true, this.getBeneficiariesOrEnrollmentFinish(this.get('employeeEnrollment')));
		}
	}
});

App.EmployeeLifeEoimodalController = _TransactionSavingController.extend({
	save: function() {
		this.get('model').saveReloadAndTransition(true, "employee."+this.get('transitionLineOfCoverage')+".eoi");
	},
	cancel: function() {
		this.send('hideModal');
	}
});

App.EmployeeLifeDependentenrollRoute = _EmployeeLifeRoute.extend(zen._EnrollmentCompleteRedirectMixin, _ReloadRestrictionMixin);

App.EmployeeLifeDependentenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentenrollMixin, _TierMixin, {
	plan: function() {
		return this.get('voluntaryPlans').objectAt(0);
	}.property('voluntaryPlans.@each'),
});

App.EmployeeAdndDependentenrollRoute = _EmployeeAdndRoute.extend(zen._EnrollmentCompleteRedirectMixin, _ReloadRestrictionMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependentenroll', {controller: controller});
	},
});

App.EmployeeAdndDependentenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentenrollMixin, _TierMixin, {});

App.EmployeeStdDependentenrollRoute = _EmployeeStdRoute.extend(zen._EnrollmentCompleteRedirectMixin, _ReloadRestrictionMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependentenroll', {controller: controller});
	},
});

App.EmployeeStdDependentenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentenrollMixin, _TierMixin, {});

App.EmployeeLtdDependentenrollRoute = _EmployeeLtdRoute.extend(zen._EnrollmentCompleteRedirectMixin, _ReloadRestrictionMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.dependentenroll', {controller: controller});
	},
});

App.EmployeeLtdDependentenrollController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityDependentenrollMixin, _TierMixin, {});

/* EOI Route */
_EmployeeLifeDisabilityEoiMixin = Ember.Mixin.create({
	eoiForm: function() {
		var plan = this.get('plans').objectAt(0);
		if (plan) {
			return plan.get('evidenceOfInsurabilityForm');
		}
	}.property('plans'),
	employeeDependentPlans: function() {
		var plans = [];
		if (this.get('employeeEnrollment.isOverGI')) {
			var employeePlan = this.get('employeeEnrollment.employeePlans').findProperty('isOverGI', true);
			if (employeePlan) {
				employeePlan.set('enrollee', employeePlan.get('enrollment.employee'));
				employeePlan.set('enrolleeType', "Self");
				plans.push(employeePlan);
			}
		}

		this.get('dependentEnrollments').forEach(function(dependentEnrollment) {
			var dependentPlan = dependentEnrollment.get('dependentPlans').findProperty('isOverGI', true);
			if(dependentPlan) {
				dependentPlan.set('enrollee', dependentPlan.get('enrollment.dependent'));
				dependentPlan.set('enrolleeType', dependentPlan.get('enrollment.dependent.type'));
				plans.push(dependentPlan);
			}
		});

		return plans;
	}.property('employeeEnrollment.isOverGI', 'dependentEnrollments.@each.isOverGI'),

	fileCategory: "eoi_document",

	acceptedFileTypes: ['pdf', 'doc'],

	actions: {
		onEoiFormUploadSuccess: function(employeeDependentPlan, file, response, identifier) {
			employeeDependentPlan.set('docName', file.name);

			var doc = App.Document.createRecord({
				company: employeeDependentPlan.get('enrollee.employee.company'),
				employee: employeeDependentPlan.get('enrollee.employee'),
				description: 'Employee Evidence of Insurability Form',
				name: file.name,
				url: response.url,
				mimetype: file.type,
				isAgreement: false,
				uploadTime: moment(),
			});
			doc.save().then(function(doc) {
				employeeDependentPlan.get('enrollment.enrolleePlans').filterProperty('isOverGI', true).forEach(function(eelp) {
					eelp.set('evidenceOfInsurabilityFormDocument', doc);
				});
			});
		},

		onEoiFormRemoval: function(employeeDependentPlan, file, fileKey) {
			employeeDependentPlan.get('enrollment.enrolleePlans').filterProperty('isOverGI', true).forEach(function(eelp) {
				eelp.set('evidenceOfInsurabilityFormDocument', null);
			});
		},

		onFileUploadError: function(employeeDependentPlan, file, response, identifier) {
			employeeDependentPlan.get('enrollment.enrolleePlans').filterProperty('isOverGI', true).forEach(function(eelp) {
				eelp.set('evidenceOfInsurabilityFormDocument', null);
			});
		},
	},

	getNextRoute: function() {
		if (this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
			return "index";
		}
		else {
			return this.getBeneficiariesOrEnrollmentFinish(this.get('employeeEnrollment'));
		}
	},

	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		this.saveAndContinue().then(function() {
			if (this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE) {
				$.ajax({
					url: '/lifeDisabilityEmployeeNotification?lineOfCoverage=' + this.get('lineOfCoverage'),
					type: "get",
					async: false
				});
			}

			this.get('employeeEnrollment').reload().then(function() {
				this.transitionToRoute(this.getNextRoute());
			}.bind(this));
		}.bind(this));
	},
	finishLater: function() {
		this.transitionToRoute(this.getNextRoute());
	},

	changeMyElection: function(){
		this.transitionToRoute('employee.life.voluntaryselfenroll');
	},
	gotIt: function(laddaButton){
		this.saveAndContinue().then(function() {
			laddaButton.stop();
		}.bind(this)).then(function() {
			this.transitionToRoute(this.getNextRoute());
		}.bind(this));
	},
});

App.EmployeeLifeEoiRoute = _EmployeeLifeRoute.extend();

App.EmployeeLifeEoiController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeAdndEoiRoute = _EmployeeAdndRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoi', {controller: controller});
	},
});

App.EmployeeAdndEoiController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeStdEoiRoute = _EmployeeStdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoi', {controller: controller});
	},
});

App.EmployeeStdEoiController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeLtdEoiRoute = _EmployeeLtdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoi', {controller: controller});
	},
});

App.EmployeeLtdEoiController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

var currency = function (n, currency) {
	return currency + n.toFixed(2).replace(/./g, function(c, i, a) {
		return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
	});
};

var employeeLifeEoinewRouteMixin = Ember.Mixin.create({

	setupController: function(controller, model) {
		this._super(controller, model);
		//helper function number to Currency format

		//we don't use basicElectedAmountSummary basicGuaranteeIssueSummary in the
		//template, but i still want to keep the code in case of need
		var employeePlans = model.get("employeePlans");
		var dependentPlans = model.get("dependentPlans");
		var lineOfCoverage = model.get("displayLineOfCoverage");
		var hasDependentOverGI = false;
		var voluntaryElectedAmountSummary = "";
		var basicElectedAmountSummary = "";
		employeePlans.forEach(function(ePlan) {
			if(ePlan.get("plan.isBasic")) {
				basicElectedAmountSummary += currency(ePlan.get("amount"), "$");
			}
			if(ePlan.get("plan.isVoluntary")) {
				voluntaryElectedAmountSummary += currency(ePlan.get("amount"), "$");
			}
		}.bind(this));

		var hasBasicDependent =false;
		var hasVolDependent =false;
		dependentPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				hasBasicDependent = true;
			}
			if(dPlan.get("plan.isVoluntary")) {
				hasVolDependent = true;
			}
		});

		if(hasBasicDependent){
			basicElectedAmountSummary += " for yourself";
		}
		if(hasVolDependent){
			voluntaryElectedAmountSummary += " for yourself";
		}

		dependentPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				basicElectedAmountSummary += "; " + currency(dPlan.get("amount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName");
			}
			if(dPlan.get("plan.isVoluntary")) {
				voluntaryElectedAmountSummary += "; " + currency(dPlan.get("amount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName");
			}
		}.bind(this));

		//Elected Amount
		if(voluntaryElectedAmountSummary != ""){
			controller.set("model.volElectedAmount",voluntaryElectedAmountSummary);
		}
		else{
			controller.set("model.volElectedAmount", "");
		}

		if(basicElectedAmountSummary != ""){
			controller.set("model.basicElectedAmount",basicElectedAmountSummary);
		}
		else{
			controller.set("model.basicElectedAmount","");
		}

		var basicGuaranteeIssueSummary = "";
		var volGuaranteeIssueSummary = "";
		var eeVolElectedAmount = undefined;
		var eeVolGuaranteeIssue = undefined;
		var eeBaiscGuaranteeIssue = undefined;
		var eeBaiscElectedAmount = undefined;
		var eeOverGI = false;


		employeePlans.forEach(function(ePlan) {
			if(ePlan.get("plan.isBasic")) {
				if(ePlan.get("guaranteeIssue") < Math.max(ePlan.get("amount"), ePlan.get("electedAmount"))) {
					basicGuaranteeIssueSummary += currency(ePlan.get("guaranteeIssue"), '$') + " for " + "yourself" + "; ";
					eeBaiscGuaranteeIssue = ePlan.get("guaranteeIssue");
					eeBaiscElectedAmount = ePlan.get("amount");
				}
			}
			if(ePlan.get("plan.isVoluntary")) {
				if(ePlan.get("guaranteeIssue") < Math.max(ePlan.get("amount"), ePlan.get("electedAmount"))) {
					volGuaranteeIssueSummary += currency(ePlan.get("guaranteeIssue"), '$') + " for " + "yourself" + "; ";
					eeVolGuaranteeIssue = ePlan.get("guaranteeIssue");
					eeVolElectedAmount = ePlan.get("amount");
					eeOverGI = true;
				}
			}
		}.bind(this));

		var depsNames = "";
		var hasDependentGIZero = false;
		dependentPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				if (dPlan.get("guaranteeIssue") < Math.max(dPlan.get("amount"), dPlan.get("electedAmount"))) {
					basicGuaranteeIssueSummary += currency(dPlan.get("guaranteeIssue"), '$') + " for " + dPlan.get("enrollment.dependent.fullName") + "; ";
					hasDependentOverGI = true;
				}
			}
			if(dPlan.get("plan.isVoluntary")) {
				if (dPlan.get("guaranteeIssue") < Math.max(dPlan.get("amount"), dPlan.get("electedAmount"))) {
					volGuaranteeIssueSummary += currency(dPlan.get("guaranteeIssue"), '$') + " for " + dPlan.get("enrollment.dependent.fullName") + "; ";
					hasDependentOverGI = true;
					hasDependentGIZero |= dPlan.get("guaranteeIssue") == 0;
					depsNames += dPlan.get("enrollment.dependent.fullName") + ", ";
				}
			}
		}.bind(this));

		if(depsNames.endsWith(", ")){
			depsNames = depsNames.substring(0, depsNames.length-2);
		}
		if(basicGuaranteeIssueSummary.endsWith("; ")) {
			basicGuaranteeIssueSummary = basicGuaranteeIssueSummary.substring(0, basicGuaranteeIssueSummary.length - 2);
		}
		if(volGuaranteeIssueSummary.endsWith("; ")) {
			volGuaranteeIssueSummary = volGuaranteeIssueSummary.substring(0, volGuaranteeIssueSummary.length - 2);
		}

		var doNotSubmitEOISentence = "If you do not submit an EOI form, ";
		if(eeVolGuaranteeIssue == 0 && !hasDependentOverGI){
			volGuaranteeIssueSummary = "you will not be eligible for Voluntary " + lineOfCoverage + " insurance";
		}
		if(eeVolGuaranteeIssue == 0 && hasDependentOverGI){
			volGuaranteeIssueSummary = "you and " + depsNames + " will not be eligible for Voluntary " + lineOfCoverage + " insurance";
		}
		if(eeVolGuaranteeIssue != 0 && hasDependentGIZero){
			volGuaranteeIssueSummary = depsNames + " will not be eligible for Voluntary " + lineOfCoverage + " insurance. But you can elect coverage for yourself less than or equal to: " + currency(eeVolGuaranteeIssue, "$");
		}
		if(eeVolGuaranteeIssue != 0 && !hasDependentGIZero){
			volGuaranteeIssueSummary = "please elect an amount below or equal to:" + volGuaranteeIssueSummary;
			doNotSubmitEOISentence = "If you do not want to submit an EOI form, ";
		}
		controller.set("model.doNotSubmitEOISentence", doNotSubmitEOISentence);
		if(!hasDependentOverGI) {
			controller.set("model.first", "Based on your elected coverage amount of ");
			controller.set("model.second", currency(eeVolElectedAmount, "$"));
			controller.set("model.third", "");
			controller.set("model.fourth", "");
		}
		else{
			controller.set("model.first", "Based on your elected coverage amount for");
			var overGINames =undefined;
			if(eeOverGI){
				overGINames = "yourself, "+depsNames;
			}
			else{
				overGINames = depsNames;
			}
			controller.set("model.second", overGINames);
			controller.set("model.third", "As a reminder, you elected the following coverage amounts:");
			controller.set("model.fourth", voluntaryElectedAmountSummary + ".");
		}

		//GuaranteeIssue Amounts that elected Amount will go down to, if not submitting EOI
		controller.set("model.showGuaranteeIssue",volGuaranteeIssueSummary);
		if(voluntaryElectedAmountSummary != ""){
			controller.set("model.volGuaranteeIssueSummary",volGuaranteeIssueSummary);
		}
		else{
			controller.set("model.volGuaranteeIssueSummary", "");
		}

		if(basicGuaranteeIssueSummary != ""){
			controller.set("model.basicGuaranteeIssueSummary",basicGuaranteeIssueSummary);
		}
		else{
			controller.set("model.basicGuaranteeIssueSummary","");
		}

		if(hasDependentOverGI) {
			controller.set("model.hasDependentOverGI", " and each dependent");
		}
		else{
			controller.set("model.hasDependentOverGI", "");
		}
	}
});

App.EmployeeLifeEoinewRoute = _EmployeeLifeRoute.extend(employeeLifeEoinewRouteMixin,{});

App.EmployeeLifeEoinewController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeAdndEoinewRoute = _EmployeeAdndRoute.extend(employeeLifeEoinewRouteMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoinew', {controller: controller});
	},
});

App.EmployeeAdndEoinewController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeStdEoinewRoute = _EmployeeStdRoute.extend(employeeLifeEoinewRouteMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoinew', {controller: controller});
	},
});

App.EmployeeStdEoinewController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});

App.EmployeeLtdEoinewRoute = _EmployeeLtdRoute.extend(employeeLifeEoinewRouteMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.life.eoinew', {controller: controller});
	},
});

App.EmployeeLtdEoinewController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEoiMixin, {});



/* L&D - beneficiaries */
App.EmployeeLifeBeneficiariesRoute = Ember.Route.extend(_RollbackOnDeactivateMixin, zen._AuthEmployeeMixin, {
	model: function(params) {
		return dashboard('employee');
	},
	afterModel: function(model) {
		return thenpath(model, 'beneficiaries');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('beneficiaries', model.get('beneficiaries'));
	}
});

App.EmployeeLifeBeneficiariesController = _TransactionSavingController.extend({
	addBeneficiary: function() {
		var record = App.store.createRecord(App.Beneficiary);
		record.set('first_name', "");
		record.set('last_name', "");
		record.set('percentage', "");
		record.set('relationship', "");
		var beneficiaries = this.get('beneficiaries');
		beneficiaries.pushObject(record);
	},
	deleteBeneficiary: function(beneficiary) {
		var beneficiaries = this.get('beneficiaries');
		beneficiaries.removeObject(beneficiary);
		beneficiary.deleteRecord();
	},
	save: function(laddaButton) {
		// validate
		var beneficiaries = this.get('beneficiaries');
		if (beneficiaries.get('length') == 0) {
			this.set('errorText', "Please enter beneficiary information. This is required.");
			laddaButton.stop();
			return;
		}
		var percentTotal = 0;
		var isValidNames = true;
		var isValidRelationship = true;
		var isValidPercent = true;
		var isValidPercentValue = true;
		beneficiaries.forEach(function(obj) {
			if (!obj.get('firstName')) {
				isValidNames = false;
			}
			if (!obj.get('lastName')) {
				isValidNames = false;
			}
			if (!obj.get('relationship')) {
				isValidRelationship = false;
			}
			if (!obj.get('percentage')) {
				isValidPercent = false;
			}

			// checking percent value
			var percentage = Number(obj.get('percentage'));
			if (!percentage) {
				isValidPercentValue = false;
			} else {
				percentTotal += percentage;
			}
		});
		if (!isValidNames) {
			this.set('errorText', "Please fill in all names.");
			laddaButton.stop();
			return;
		}
		if (!isValidPercent) {
			this.set('errorText', "Please fill in all percentages.");
			laddaButton.stop();
			return;
		}
		if (!isValidRelationship) {
			this.set('errorText', "Please fill in all relationship details.");
			laddaButton.stop();
			return;
		}
		if (!isValidPercentValue) {
			this.set('errorText', "Please use valid percentage numbers (eg. 30.00)");
			laddaButton.stop();
			return;
		}
		if (Number(percentTotal.toFixed(2)) != 100) {
			this.set('errorText', 'Percentage numbers must add up to 100%.');
			laddaButton.stop();
			return;
		}
		this.set('errorText', '');
		var self = this;
		var employee = this.get('model');
		var isPendingApproval = this.get('model.employeeLifePlan.isPendingApproval');
		var isApproved = this.get('model.employeeLifePlan.isApproved');
		this.saveAndContinue().then(function() {
			if (!this.get('employeeEnrollment') ||
				this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.COMPLETE ||
				this.get('employeeEnrollment.status') == _LDEAbstractModel.prototype.APPROVED) {

				this.transitionToRoute("employee.overview.lnd.newlife");
			}
			else {
				this.transitionToRoute('employee.life.enrollmentfinish');
			}
		}.bind(this));
	}
});

App.EmployeeAdndBeneficiariesRoute = Ember.Route.extend({
	redirect: function() {
		this.transitionTo("employee.adnd.enrollmentfinish");
	}
});

/* L&D - enrollment finish */
_EmployeeLifeDisabilityEnrollmentfinishMixin = Ember.Mixin.create({
	eoiForm: function() {
		var plan = this.get('plans').objectAt(0);
		if (plan) {
			return plan.get('evidenceOfInsurabilityForm');
		}
	}.property('plans'),

	isVoluntaryOffered: function() {
		return !!this.get('plans').findProperty('planType', App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY);
	}.property('plans'),

	isEnrolledInVoluntary: function() {
		return !!this.get('employeePlans').findProperty('plan.planType',  App._LifeDisabilityPlanAbstractModel.prototype.VOLUNTARY);
	}.property('employeePlans'),

	save: function(laddaButton) {
		if (!this.checkSave()) {
			laddaButton.stop();
			return;
		}

		var employeeEnrollment = this.get('employeeEnrollment');
		if (!employeeEnrollment.get('authName') || !employeeEnrollment.get('authTitle') || !employeeEnrollment.get('authSignature')) {
			this.set("errorText", "Please complete all required fields");
			return;
		}

		employeeEnrollment.set('authDate', new Date());
		employeeEnrollment.set('isEnrollmentComplete', true);
		if (!employeeEnrollment.get('employeePlans.length')) {
			employeeEnrollment.set('status', _LDEAbstractModel.prototype.DECLINE);
			employeeEnrollment.set('isActive', false);
		}
		else {
			employeeEnrollment.set('status', _LDEAbstractModel.prototype.COMPLETE);
		}
		employeeEnrollment.set('enrollmentCompleteTimestamp', new Date());

		this.get("employeePlans").forEach(function(plan) {
			var amount = plan.get("amount");
			var gi = plan.get("guaranteeIssue");
			plan.set("electedAmount", amount);
			if(gi != null && amount > gi) {
				if (this.get("switches.eoiProcess")) {
					plan.set("amount", gi);
				}
			}
		}.bind(this));

		this.get("dependentPlans").forEach(function(plan) {
			var amount = plan.get("amount");
			var gi = plan.get("guaranteeIssue");
			plan.set("electedAmount", amount);

			if(gi != null && amount > gi) {
				if (this.get("switches.eoiProcess")) {
					plan.set("amount", gi);
				}
			}
		}.bind(this));

		var cheId = this.get("employeeEnrollment.companyHealthEnrollment.id");
		var eeId = this.get("employee.id");
		var lineOfCoverage = this.get("displayLineOfCoverage");
		var endDate = moment(this.get("employeeEnrollment.endDate")).format('L');
		var eePlans = this.get("employeePlans");
		var dPlans = this.get("dependentPlans");
		var eoiForm = this.get("eoiForm");
		var ldeId = this.get("employeeEnrollment.id");
		var electedAmount = "";
		var basicElectedAmountSummary = "";
		var voluntaryElectedAmountSummary = "";

		eePlans.forEach(function(ePlan) {
			if(ePlan.get("plan.isBasic")) {
				basicElectedAmountSummary += currency(ePlan.get("electedAmount"), "$");
			}
			if(ePlan.get("plan.isVoluntary")) {
				voluntaryElectedAmountSummary += currency(ePlan.get("electedAmount"), "$");
			}
		}.bind(this));

		var hasBasicDependent =false;
		var hasVolDependent =false;
		dPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				hasBasicDependent = true;
			}
			if(dPlan.get("plan.isVoluntary")) {
				hasVolDependent = true;
			}
		});

		if(hasBasicDependent){
			basicElectedAmountSummary += " for yourself";
		}
		if(hasVolDependent){
			voluntaryElectedAmountSummary += " for yourself";
		}

		dPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				basicElectedAmountSummary += "; " + currency(dPlan.get("electedAmount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName");
			}
			if(dPlan.get("plan.isVoluntary")) {
				voluntaryElectedAmountSummary += "; " + currency(dPlan.get("electedAmount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName");
			}
		}.bind(this));

		var basicGuaranteeIssueSummary = "";
		var volGuaranteeIssueSummary = "";
		eePlans.forEach(function(ePlan) {
			if(ePlan.get("plan.isBasic")) {
				basicGuaranteeIssueSummary += currency(ePlan.get("amount"), "$") + " for " + "yourself" + "; ";
			}
			if(ePlan.get("plan.isVoluntary")) {
				volGuaranteeIssueSummary += currency(ePlan.get("amount"), "$") + " for " + "yourself" + "; ";
			}
		}.bind(this));

		dPlans.forEach(function(dPlan) {
			if(dPlan.get("plan.isBasic")) {
				basicGuaranteeIssueSummary += currency(dPlan.get("amount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName") + "; ";
			}
			if(dPlan.get("plan.isVoluntary")) {
				volGuaranteeIssueSummary += currency(dPlan.get("amount"), "$") + " for " + dPlan.get("enrollment.dependent.fullName") + "; ";
			}
		}.bind(this));
		if(basicGuaranteeIssueSummary.endsWith("; ")) {
			basicGuaranteeIssueSummary = "Basic " + lineOfCoverage + " insurance: " + basicGuaranteeIssueSummary.substring(0, basicGuaranteeIssueSummary.length - 2) + ".";
		}
		if(volGuaranteeIssueSummary.endsWith("; ")) {
			volGuaranteeIssueSummary = "Voluntary " + lineOfCoverage + " insurance: " + volGuaranteeIssueSummary.substring(0, volGuaranteeIssueSummary.length - 2) + ".";
		}

		if (this.get('employeeEnrollment.isAnyVoluntaryEnrollmentOverGI')){
			electedAmount = voluntaryElectedAmountSummary;
		}
		else{
			electedAmount = basicElectedAmountSummary;
		}

		this.saveAndContinue().then(function() {
			this.get('_employeeLifeDisabilityEnrollmentApi').reload().then(function() {
				if (!this.get('employeeEnrollment.isOverGI')){
					return;
				}
				if(!this.get("switches.eoiProcess")) {
					return;
				}

				var chePromise = this.get("employeeEnrollment.companyHealthEnrollment");
				var chcPromise = chePromise.then(function(che){
					return che.get("companyHealthCarrier");
				}.bind(this));

				chcPromise.then(function(chc) {
					var carrierName = chc.get("carrierDisplayName");
					var policyNumber = chc.get("groupID");

					var consequence = "";
					if(basicGuaranteeIssueSummary){
						consequence += basicGuaranteeIssueSummary + "<br/>";
					}
					if(volGuaranteeIssueSummary){
						consequence += volGuaranteeIssueSummary + "<br/>";
					}
					return new Ember.RSVP.Promise(function (resolve, reject) {
						Ember.$.ajax({
							url: "/custom_api/lifedisability/generateAdminEmployeeEoiRequiredActionV2",
							type: "POST",
							contentType: 'application/json',
							data: JSON.stringify({
								cheId: cheId,
								eeId: eeId,
								eoiForm : eoiForm,
								carrierName: carrierName,
								lineOfCoverage: lineOfCoverage,
								endDate: endDate,
								consequence: consequence,
								electedAmount: electedAmount,
								policyNumber: policyNumber,
								ldeId: ldeId,
							}),
							success: function (response) {
								resolve(response);
							},
							error: function (reason) {
								reject(reason);
							},
						});
					});
				}.bind(this));
			}.bind(this));
		}.bind(this)).then(function() {
			this.get('_employeeLifeDisabilityEnrollmentApi').reload().then(function() {
				$.ajax({
					url: '/lifeDisabilityEmployeeNotification?lineOfCoverage=' + this.get('lineOfCoverage'),
					type: "get",
					async: false
				});

				var carrier = this.get('employeeEnrollment.employeePlans.length') ? this.get('employeeEnrollment.employeePlans').objectAt(0).get('plan.stateCarrier') : null;
				var changeCoverageEndDate = employeeEnrollment.get('endDate');
				changeCoverageEndDate.setDate(changeCoverageEndDate.getDate() - 3);
				var today = new Date();
				this.send("showModal", "employee.life.enrollmentfinishmodal",
						Ember.Object.create({
							carrier: carrier,
							obj: this,
							changeCoverageEndDate: changeCoverageEndDate,
							canChangeCoverage: changeCoverageEndDate >= today,
							transitionText: this.get('transitionText'),
							nextRoute: this.get('nextRoute'),
							messageLineOfCoverage: this.get('messageLineOfCoverage'),
							messageLineOfCoverageShort: this.get('messageLineOfCoverageShort'),
						}));
			}.bind(this));
		}.bind(this));
	},
	transitionText: function() {
		if (this.get('isAddPresent')) {
			this.set('nextRoute', 'employee.adnd.basicplan');
			this.set('messageLineOfCoverage', 'Accidental Death and Dismemberment');
			this.set('messageLineOfCoverageShort', 'AD&D');
			return "Continue to AD&D";
		}
		if (this.get('isStdPresent')) {
			this.set('nextRoute', 'employee.std.basicplan');
			this.set('messageLineOfCoverage', 'Short Term Disability');
			this.set('messageLineOfCoverageShort', 'STD');
			return "Continue to Short Term Disability";
		}
		if (this.get('isLtdPresent')) {
			this.set('nextRoute', 'employee.ltd.basicplan');
			this.set('messageLineOfCoverage', 'Long Term Disability');
			this.set('messageLineOfCoverageShort', 'LTD');
			return "Continue to Long Term Disability";
		}

		this.set('nextRoute', 'index');
		this.set('messageLineOfCoverage', '');
		this.set('messageLineOfCoverageShort', '');
		return "Got it! Return to Dashboard";
	}.property('isAddPresent', 'isStdPresent', 'isLtdPresent'),

	isAddPresent: function() {
		return (this.get('employeeAddEnrollment') && this.get('adndPlans.length') && !this.get('employeeAddEnrollment.isEnrollmentComplete') && this.get('employee.isEligibleForAdd'));
	}.property('employeeAddEnrollment', 'employeeAddEnrollment.isEnrollmentComplete', 'adndPlans.length', 'employee.isEligibleForAdd'),
	isStdPresent: function() {
		return (this.get('employeeStdEnrollment') && this.get('stdPlans.length') && !this.get('employeeStdEnrollment.isEnrollmentComplete') && this.get('employee.isEligibleForSTD'));
	}.property('employeeStdEnrollment', 'employeeStdEnrollment.isEnrollmentComplete', 'stdPlans.length', 'employee.isEligibleForSTD'),
	isLtdPresent: function() {
		return (this.get('employeeLtdEnrollment') && this.get('ltdPlans.length') && !this.get('employeeLtdEnrollment.isEnrollmentComplete') && this.get('employee.isEligibleForLTD'));
	}.property('employeeLtdEnrollment', 'employeeLtdEnrollment.isEnrollmentComplete', 'ltdPlans.length', 'employee.isEligibleForLTD'),
});

App.EmployeeLifeEnrollmentfinishmodalController = _TransactionSavingController.extend({
	cancel: function() {
		this.send('hideModal');
	},
	transition: function() {
		this.send('hideModal');
		this.transitionTo(this.get('model.nextRoute'));
	}

});

App.EmployeeLifeEnrollmentfinishRoute = _EmployeeLifeRoute.extend();

App.EmployeeLifeEnrollmentfinishController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEnrollmentfinishMixin, {});

App.EmployeeAdndEnrollmentfinishRoute = _EmployeeAdndRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.enrollmentfinish', {controller: controller});
	},
});

App.EmployeeAdndEnrollmentfinishController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEnrollmentfinishMixin, {});

App.EmployeeStdEnrollmentfinishRoute = _EmployeeStdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.enrollmentfinish', {controller: controller});
	},
});

App.EmployeeStdEnrollmentfinishController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEnrollmentfinishMixin, {});

App.EmployeeLtdEnrollmentfinishRoute = _EmployeeLtdRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.life.enrollmentfinish', {controller: controller});
	},
});

App.EmployeeLtdEnrollmentfinishController = _EmployeeLifeDisabilityController.extend(_EmployeeLifeDisabilityEnrollmentfinishMixin, {});

/* L&D - settings */
/* TODO: MV */
App.EmployeeVollifeCancelRoute = Ember.Route.extend(_MultiModelMixin, zen._AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.lifePlan'), {'company': dashboard('company')});
	}
});

App.EmployeeVollifeCancelController = _TransactionSavingController.extend({
	save: function() {
		var employeeLifePlan = this.get('model');
		employeeLifePlan.set('status', 'pending_cancel');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.overview.lnd.newlife');
		}.bind(this));
	},
	back: function() {
		this.transitionToRoute('index');
	}
});
