App.ModalContainer = Ember.ContainerView.extend({
	isVisible: false,

	init: function () {
		var view = this.get('controller.contentView');
		var controller = this.get("controller");
		view = App[view.capitalize() + 'View']; // -> App.SomeView
		view = this.createChildView(view); // Hook up to correct container
		this._super();
		this.pushObject(view);
	},

	//To do a CSS animated fade-in via opacity transition, you
	//have to init the isVisible property to false, then toggle it
	//on didInsertElement so that the "becameVisible" event fires,
	//and THEN change the opacity. Yuck.
	didInsertElement: function() {
		this.toggleProperty("isVisible"); //Triggers "becameVisible"
	},

	becameVisible: function() {
		this.$().closest(".modal").addClass("visible");
		this._disableBodyEvents();
	},

	willDestroyElement: function() {
		this.$().closest(".modal").removeClass("visible");
		this._enableBodyEvents();
	},

	_disableBodyEvents: function() {
		var $body = $("body");

		$body.css("overflow", "hidden");
		Ember.$(window).on("keydown", this._keyDown.bind(this)) //Note: Ember's regular keyboard event binding does not work for this ContainerView
			.on("scroll", this._onScroll.bind(this));
	},

	_enableBodyEvents: function() {
		var $body = $("body");

		$body.css("overflow", "visible");
		Ember.$(window).off("keydown").on("scroll");
	},

	_keyDown: function(e) {

		switch(e.keyCode) {
			case 27:
				this.closeModal();
				break;
		}
	},

	_onScroll: function(e) {
		e.stopPropagation();
	},

	closeModal: function() {
		var applicationRoute = App.AppRouteInstance;
		var controller = this.get("controller");

		//If you want to add a "Are you sure you want to nav away" confirmation,
		//define "beforeModalClose" in the modal content's controller and return
		//a boolean on whether to allow nav or not.
		//TODO: Add a handler for "beforeunload" for actual page nav as well.
		if (controller && typeof controller.beforeModalCloseConfirmation === "function") {
			if (!controller.beforeModalCloseConfirmation()) {
				return;
			}
		}

		// Console app does not bubble up the hideModal event to ApplicationRoute.
		// Reference it manually and close the modal.
		// Ember1-11.0-beta.1.prod.js line 27264 - "send" method in ActionHandlerMixin.
		// This is because the newer version of Ember expects an "actions" hash with
		// the action methods that return true to bubble the events up. However, in
		// this beta version of Ember, the events don't get bubbled all the way up
		// to the ApplicationRouter anyway. Also, we don't want to write a hideModal
		// action into each and every controller that uses this modal code, but there is
		// no Ember.ControllerMixin defined in this version yet that will automatically
		// merge actions in correctly.
		// TODO: Once we upgrade Ember, get rid of this and use a ControllerMixin
		// with a hideModal action with "return true" defined in the actions hash.
		if (applicationRoute && applicationRoute._actions) {
			applicationRoute._actions['hideModal'].apply(applicationRoute);
		}
		//Main app bubbles up the controller event to ApplicationRoute correctly.
		else {
			this.get("controller").send("hideModal");
		}
	}
});
