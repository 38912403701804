App.EmployeeBasicinfoController = Ember.ObjectController.extend({
	save: function() {
		var that = this;
		var employee = this.get('model');
		if (!employee.get('first_name') || !employee.get('last_name') ||
			!employee.get('dob') || !employee.get('zip'))
		{
			this.set('errorText', 'All fields are required, please enter missing fields.');
			return;
		}

		this.set('errorText', '');
		employee.save().then(function() {
			setTimeout(function () {
				that.transitionToRoute('index');
			}, 800);
		});
		App.store.commit();
	}
});

var _CobraDeclineOverviewMixin = Ember.Mixin.create({
	getCobraDeclineModalData: function() {
		var isCobraEnrollee = this.get('employee.isCobraEnrollee');
		if (!isCobraEnrollee) {
			return {
				isCobraEnrollee: false,
			};
		}

		var loc = this.get('currLineOfCoverage').toLowerCase();
		var enrollment = this.get('current' + (loc === 'medical' ? "" : loc.capitalize()) + 'Enrollment');
		if (!enrollment) {
			return {};
		}

		var cobraExpiryDate = moment(
			this.get('employee.employeeCobra.cobraCoverageEndDate'),
			"MM/DD/YYYY");
		var nextEffectiveDate = moment(
			enrollment.get('effectiveDate'),
			"MM/DD/YYYY").add(1, 'years');
		return {
			capitalizedLoc: loc.capitalize(),
			cobraExpiryDate: cobraExpiryDate,
			doesCobraExpireBeforeNextRenewal: cobraExpiryDate.diff(nextEffectiveDate) < 0,
			effectiveDate: enrollment.get('effectiveDate'),
			isCobraEnrollee: true,
			lineOfCoverage: loc,
			nextEffectiveDate: nextEffectiveDate,
		};
	}
});

_PlanSelectorControllerMixin = Ember.Mixin.create(_ShortCircuitUtilsForPlanSelector, _CobraDeclineOverviewMixin, _EmployeeDependentsEnrollmentMixin, {
	currentlySelectedPlan: null,
	limitInstructions: true,
	plans: null,
	isReloadingPlans: false,

	currentActiveHraPlan: function() {
		var currentCompanyHraPlan = this.get('currentCompanyHraPlan');
		var nextCompanyHraPlan = this.get('nextCompanyHraPlan');
		if (currentCompanyHraPlan && currentCompanyHraPlan.get('isComplete')) {
			return currentCompanyHraPlan;
		} else if (nextCompanyHraPlan && nextCompanyHraPlan.get('isComplete')) {
			return nextCompanyHraPlan;
		}
		return null;
	}.property('currentCompanyHraPlan', 'currentCompanyHraPlan.isComplete', 'nextCompanyHraPlan', 'nextCompanyHraPlan.isComplete'),
	employerHraContribution: function() {
		var companyHraPlan = this.get('currentActiveHraPlan');
		if(companyHraPlan) {
			var employerContribution = parseFloat(companyHraPlan.get('employeeContribution'));
			if (this.get('hasSelectedQuoteDependents')) {
				employerContribution += parseFloat(companyHraPlan.get('dependentContribution'));
			}
			return employerContribution;
		}
		return null;
	}.property('currentActiveHraPlan', 'currentActiveHraPlan.employeeContribution', 'currentActiveHraPlan.dependentContribution', 'hasSelectedQuoteDependents'),
	declineOverview: function() {
		var o = Ember.Object.create({
			bundles: this.get('bundles'), // from _BundlingMixins
			company: this.get('company'),
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment'),
			currLineOfCoverage: this.get('currLineOfCoverage'),
		});
		o.setProperties(this.getCobraDeclineModalData());
		this.send('showModal', 'employee.declineoverview', o);
	},
	availablePlans: Ember.computed(function() {
		var plans = this.get('plans');
		var stateCarrier = this.get('currentEnrollment.stateCarrier');
		var availablePlans = Ember.A();
		if (plans && stateCarrier) {
			plans.forEach(function(plan) {
				var carrier = plan.get('stateCarrier');
				if (carrier && carrier.get('id') == stateCarrier.get('id')) {
					availablePlans.pushObject(plan);
				}
			});

			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: this.get('sortProperties'),
				content: availablePlans
			});
		}
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: this.get('sortProperties'),
			content: plans
		});
	}).property('currentEnrollment.stateCarrier', 'plans.[]'),

	// Type 'A' > 'B', 'R' > 'N'. If there are atleast one short circuit plan with type 'A', this will
	// return 'A'. If there are atleast one short circuit plan with type 'B' it will return 'B'. If there
	// are one short circuit plan with 'B' and one with 'R', it will return 'A'.
	overallShortCircuitPlanType: function() {
		return this.getOverallShortCircuitPlanType(this.get('availablePlans'));
	}.property('availablePlans.@each'),
	shouldShowQuoteDependents: function() {
		return this.calculateShouldShowQuoteDependents(this.get('availablePlans'));
	}.property('availablePlans.@each'),
	hasAtleastOneShortCircuitPlanWithBenefitsNotRates: Ember.computed.equal('overallShortCircuitPlanType', 'B'),
	hasAtleastOneShortCircuitPlanWithRatesNotBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'R'),
	hasAtleastOneShortCircuitPlanWithRatesAndBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'A'),
	hasAlternatives: function() {
		var availablePlans = this.get('plans');
		var hasAlternatives = false;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isSelected')) {
					hasAlternatives = true;
				}
			});
		}

		return hasAlternatives;
	}.property('plans.@each.isSelected'),
	hsaComplete: function() {
		return this.get('hsa.status') == 'complete';
	}.property('hsa.status'),
	isYouPremium: function() {
		return this.get('priceLevel') == 'youPremium';
	}.property('priceLevel'),
	isYouAndSpousePremium: function() {
		return this.get('priceLevel') == 'youAndSpousePremium';
	}.property('priceLevel'),
	isYouAndChildPremium: function() {
		return this.get('priceLevel') == 'youAndChildPremium';
	}.property('priceLevel'),
	isFamilyPremium: function() {
		return this.get('priceLevel') == 'familyPremium';
	}.property('priceLevel'),
	warnOnWaitingPeriod: function() {
		if (this.get('currentEnrollment.isOpenEnrollment') || this.get('isShortCircuitOEPlanSelector')) {
			return false;
		}

		if (this.get('company.isApproved') && !this.get('employee.isMedicalApproved') && this.get('settings.hasMedicalWaitingPeriod')) {
			return true;
		}
		return false;
	}.property('currentEnrollment.isOpenEnrollment', 'company.isApproved', 'employee.isMedicalApproved', 'settings.hasMedicalWaitingPeriod'),
	medicalPlanChangeObserver: function() {
		var availablePlans = this.get('plans');
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (item.get('id') == this.get('currentlySelectedPlan.id')) {
					item.set('isCurrentlySelected', true);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			}, this);
		}
	}.observes('currentlySelectedPlan'),
	showHsaCancellationMessage: function() {
		if ( !this.get('currentlySelectedPlan') || this.get('currentlySelectedPlan.HSA') || this.get('employee.hsa.status') != 'complete') {
			return;
		}
		var o = Ember.Object.create({
			plan: this.get('currentlySelectedPlan'),
		});

		this.send('showModal', 'employee.hsaCancellation', o);
	}.observes('currentlySelectedPlan'),
	instructionsLimited: function() {
		this.set('limitInstructions', true);
	},
	instructionsBig: function() {
		this.set('limitInstructions', false);
	},
	showLimitedInstructions: function() {
		return this.get('limitInstructions');
	}.property('limitInstructions'),
	hideEmployerCostsBreakdown: function() {
		return this.get("companySettings.hideCompanyContributions");
	}.property("companySettings.hideCompanyContributions"),

	showPediatricDentalRiderOverview: function(plan) {
		var o = Ember.Object.create({
			plan: plan,
		});

		this.send('showModal', 'employee.pediatricdentalrideroverview', o);
	},

	reloadPlans: function () {
		var dependents = this.get('selectedQuoteDependents');
		var dependentIds = dependents.mapBy('id');
		var query = {
			dependents: dependentIds.join(','),
			forQuoting: true,
			getUnavailable: true,
		};
		if (this.get('mode')) {
			query.mode = this.get('mode');
		}

		this.set('isReloadingPlans', true);
		return Ember.RSVP.resolve().then(function() {
			var oldPlanId = this.get('oldPlan.id');
			if (oldPlanId && !this.get('plans').findBy('id', oldPlanId)) {
				return App.EPlan.find(Ember.merge({
					id: oldPlanId,
				}, query));
			}
			return Ember.RSVP.resolve();
		}.bind(this)).then(function(oldPlans) {
			if (!Ember.isEmpty(oldPlans)) {
				this.set('oldPlan', oldPlans.get('firstObject'));
			}
			return App.EPlan.find(query);
		}.bind(this)).then(function (updatedPlans) {
			this.set('plans', updatedPlans);
		}.bind(this)).finally(function () {
			this.set('isReloadingPlans', false);
		}.bind(this));
	},
	actions: {
		goBack: function () {
			return this.goBack();
		},
		onDependentChange: function(dependent) {
			dependent.toggleProperty('isSelected');
			return this.reloadPlans();
		},
	},
});

App.EmployeePlanselectorController = _TransactionSavingController.extend(_PlanSelectorControllerMixin, _DependentDeclineMixin, _BundlingControllerMixin, {
	needs: ['employee/planoverview'],
	sortProperties: ['youPremium'],
	priceLevel: 'youPremium',

	decline: function(o) {
		var obj = o ? o : this;

		var employee = obj.get('employee');
		var currentEnrollment = obj.get('currentEnrollment');
		var currentDentalEnrollment = obj.get('currentDentalEnrollment');
		var currentVisionEnrollment = obj.get('currentVisionEnrollment');

		currentEnrollment.set('status', 'decline');
		this.declineDependentCoverage(obj.get('dependents'), currentEnrollment);
		this.saveAndContinue().then(function() {
			if (currentDentalEnrollment &&
				(currentDentalEnrollment.get('status') != 'complete' || currentDentalEnrollment.get('allowEditReviewPlans')))
			{
				if (currentDentalEnrollment.get('isOpenEnrollment')) {
					this.transitionToRoute('employee.dentalopenenrollment');
				}
				else {
					this.transitionToRoute('employee.dentalplanselector');
				}
				return;
			}
			if (currentVisionEnrollment &&
				(currentVisionEnrollment.get('status') != 'complete' || currentVisionEnrollment.get('allowEditReviewPlans')))
			{
				if (currentVisionEnrollment.get('isOpenEnrollment')) {
					this.transitionToRoute('employee.visionopenenrollment');
				}
				else {
					this.transitionToRoute('employee.visionplanselector');
				}
				return;
			}

			this.transitionToRoute('employee.decline.info');
		}.bind(this));
	},
	showOverview: function(plan) {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var o = Ember.Object.create({
			plan: plan,
			showEnroll: true,
			company: this.get('company'),
			employee: this.get('employee'),
			currentEnrollment: currentEnrollment,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
			isSCHPlan: plan.get('isShortCircuitPlan'),
			planHasNoBenefits: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'B', // A normal plan can also be of type 'A'
			planHasNoRates: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'R',
		});

		this.send('showModal', this.planOverviewModalName(), o);
	},

	continueToNextPage: function(plan) {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var employeePlanModel = Ember.Object.create({
			plan: plan,
			company: this.get('company'),
			employee: this.get('employee'),
			dependents: this.get('dependents'),
			currentEnrollment: currentEnrollment,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment
		});

		var controller = this.get('controllers.employee/planoverview');
		controller.set('model', employeePlanModel);
		return controller.enroll();
	},

	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');
			// Hmm. Interesting: if I gave these properties the same name as
			// the functions in the bundling controller mixin, the template will complain about types.
			// It'll try to load the functions rather than the strings...even though it shouldn't have
			// access to the mixin model... weird.
			if (this.hasSelectedBundledPlan(plan, this.get('selectedMedical'))) {
				var transitionModel = Ember.Object.create({
					planSelectorController: this,
					plan: plan,
					continueToNextPage: this.get('continueToNextPage'),
					carrierName: (this.get('selectedCarrierName').bind(this))(plan),
					selectedLineOfCoverage: this.get('selectedMedical'),
					allBundledLines: (this.get('allBundledLinesOfCoverage')).bind(this)(plan, this.get('selectedMedical')),
					allOtherBundledLines: (this.get('allOtherBundledLinesOfCoverage')).bind(this)(plan, this.get('selectedMedical')),
					hasAtLeastOneCarrierWithoutBundling: this.get('hasAtLeastOneCarrierWithoutBundledPlans'),
				});

				return new Ember.RSVP.Promise(function (resolve, reject) {
					transitionModel.setProperties({ resolve: resolve, reject: reject });
					// Redirect to next page after user clicks continue from modal
					this.send('showModal', 'employee.selectedbundledplanmodal', transitionModel);
				}.bind(this));
			} else {
				return this.continueToNextPage(plan);
			}
		},

		goToGlossarySection: function() {
			var $target = Ember.$('.js-medicalGlossary');

			Ember.$('html, body').animate({
				scrollTop: $target.offset().top
			}, 500);
		},
	},

	planOverviewModalName: function() {
		return 'employee.planoverview';
	},
	hasOnlyShortCircuitPlans: function() {
		var availablePlans = this.get('plans');
		var hasOnlyShortCircuitPlans = true;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isShortCircuitPlan')) {
					hasOnlyShortCircuitPlans = false;
				}
			});
		}

		return hasOnlyShortCircuitPlans;
	}.property('plans.@each.isShortCircuitPlan'),
	saveButtonText: function() {
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
			return 'Save & Continue to Dental';
		}

		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
			return 'Save & Continue to Vision';
		}

		return 'Save & Continue to Summary';
	}.property('currentDentalEnrollment', 'currentDentalEnrollment.isEnrollmentOngoing',
		'currentVisionEnrollment', 'currentVisionEnrollment.isEnrollmentOngoing'),
	// The html texts.
	getH1: function() {
		return 'Would you like to enroll in health insurance?';
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return 'Your employer has chosen to offer ' + this.get('arrangedContent').length + ' health insurance plans, that are available for you. You can choose to enroll in one below.';
	}.property(),
	declineBtnTxt: function() {
		return 'Decline Medical Coverage';
	}.property(),
	isShortCircuitOEPlanSelector: function() {
		return false;
	}.property()
});

App.EmployeeScoeoldplanselectorController = App.EmployeePlanselectorController.extend({
	needs: ['employeescoeoldplanoverview'],

	selectCurrentPlan: function(plan) {
		if (this.get('declineSCOEOldPlans') == true) {
			this.decline();
			return;
		}
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var currentEnrollment = this.get('currentEnrollment');

		var o = Ember.Object.create({
			plan: plan,
			showEnroll: true,
			company: this.get('company'),
			employee: this.get('employee'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment'),
			isSCHPlan: true,
		});
		var controller = this.get('controllers.employeescoeoldplanoverview');
		controller.set('model', o);
		controller.enroll();
	},
	getH1: function() {
		return "Which health plans are you currently enrolled in?";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select your current insurance plan from the list below.";
	}.property(),
	declineBtnTxt: function() {
		return "I declined coverage";
	}.property(),
	saveButtonText: function() {
			return 'Save & Continue';
	}.property(),
	decline: function() {
		var currentEnrollment = this.get('currentEnrollment');
		// -1 in old plan symbolizing that the old enrollment was declined.
		currentEnrollment.set('oldPlan', -1);
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.scoenewplanselector');
		}.bind(this));
	},
	planOverviewModalName: function() {
		return 'employeescoeoldplanoverview';
	},
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	isShortCircuitOEOldPlanSelector: function() {
		return true;
	}.property(),
	showPlanSelector: function() {
		// If employee hasn't selected anything in the radio button, then don't show the plan selector
		if (this.get('declineSCOEOldPlans') == true || this.get('declineSCOEOldPlans') == null) {
			return false;
		}
		return true;
	}.property('declineSCOEOldPlans'),
});

App.EmployeeScoeoldplanselectorView = App.EmployeePlanselectorView.extend({
	templateName: 'medicalenrollment'
});

App.EmployeeScoenewplanselectorController = App.EmployeePlanselectorController.extend({
	planOverviewModalName: function() {
		return 'employeescoenewplanoverview';
	},
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	isShortCircuitOENewPlanSelector: function() {
		return true;
	}.property(),
	getH1: function() {
		return "Would you like to enroll in medical plan for the next 12 months ? ";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select a new medical plan from the list below. You can also choose to decline coverage, if you don't want or need medical insurance for the next 12 months. ";
	}.property(),
	enrolledInOldSCOEPlan: function() {
		if (this.get('currentEnrollment.oldPlan') && this.get('currentEnrollment.oldPlan') != -1) {
			return true;
		}
		return false;
	}.property('currentEnrollment.oldPlan'),
	goBack: function() {
		return this.transitionToRoute('employee.scoeoldplanselector');
	},
});

App.EmployeeScoenewplanselectorView = App.EmployeePlanselectorView.extend({
	templateName: 'medicalenrollment'
});

App.EmployeeOpenenrollmentController = _TransactionSavingController.extend(_PlanSelectorControllerMixin, _DependentDeclineMixin,{
	needs: ['employee/planoverview'],
	sortProperties: ['youPremium'],
	priceLevel: 'youPremium',

	showOverview: function(plan) {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var o = Ember.Object.create({
			plan: plan,
			showEnroll: plan.get('isSelected') && !currentEnrollment.get('currentPlanOffered') ? false : true,
			isOpenEnrollment: true,
			company: this.get('company'),
			employee: this.get('employee'),
			employeeCobra: this.get('employeeCobra'),
			currentEnrollment: currentEnrollment,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
		});

		this.send('showModal', 'employee.planoverview', o);
	},

	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');
			var currentEnrollment = this.get('currentEnrollment');
			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			var currentVisionEnrollment = this.get('currentVisionEnrollment');
			var employeePlanModel = Ember.Object.create({
				plan: plan,
				company: this.get('company'),
				employee: this.get('employee'),
				dependents: this.get('dependents'),
				currentEnrollment: currentEnrollment,
				currentDentalEnrollment: currentDentalEnrollment,
				currentVisionEnrollment: currentVisionEnrollment,
			});

			var controller = this.get('controllers.employee/planoverview');
			controller.set('model', employeePlanModel);
			controller.enroll();
		},
	},

	dontShowCostBreakDown: function(){
		if (this.get('employeeCobra.administerCOBRA')) {
			return true;
		}
		return false;
	}.property('employeeCobra.administerCOBRA'),
	looksGood: function(action, o) {
		var obj = o ? o : this;

		var company = obj.get('company');
		var employee = obj.get('employee');
		var currentEnrollment = obj.get('currentEnrollment');
		if (action == 'decline') {
			// Clear out plan on the EHE
			if (currentEnrollment.get('medicalPlan')) {
				currentEnrollment.set('medicalPlan', null);
				currentEnrollment.set('medicalPlan_id', null);
			}
			currentEnrollment.set('companyHealthPlan', null);
			this.declineDependentCoverage(employee.get('dependents'), currentEnrollment);
		}
		else if (action == 'renew') {
			// Copy selected plan to EHE
			currentEnrollment.set('medicalPlan', employee.get('medicalPlan'));
			currentEnrollment.set('companyHealthPlan', employee.get('employeeSettings.medicalCompanyHealthPlan'));
			var riders = this.get('currentlySelectedPlan.riders');
			if (riders){
				currentEnrollment.set('healthRiders', riders);
			}
		}

		// Previously had coverage, declining now => status should be 'decline', (or)
		// Previously had coverage, renewing plan => status should be 'reviewed', (or)
		// Previously declined, declining now too => status should be 'reviewed'
		if (employee.get('isMedicalApproved')) {
			if (action == 'decline') {
				currentEnrollment.set('status', 'decline');
			}
			else if (currentEnrollment.get('currentPlanOffered') && action == 'renew') {
				currentEnrollment.set('status', 'reviewed');
			}
		}
		else if (employee.get('hasDeclinedMedicalCoverage') && action == 'decline') {
			currentEnrollment.set('status', 'reviewed');
		}

		var currentDentalEnrollment = employee.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
			if (currentDentalEnrollment.get('isOpenEnrollment')) {
				this.saveAndContinue('employee.dentalopenenrollment');
			}
			else {
				this.saveAndContinue('employee.dentalplanselector');
			}
			return;
		}

		var currentVisionEnrollment = employee.get('currentVisionEnrollment');
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
			if (currentVisionEnrollment.get('isOpenEnrollment')) {
				this.saveAndContinue('employee.visionopenenrollment');
			} else {
				this.saveAndContinue('employee.visionplanselector');
			}
			return;
		}

		var employee = this.get('employee');
		if (currentEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}

		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.decline.info');
		}.bind(this));
	},
	isRenewingPlan: function() {
		return (this.get('currentlySelectedPlan.id') == this.get('oldPlan.id'));
	}.property('currentlySelectedPlan', 'oldPlan'),
	hasSwitchedPlan: function() {
		return (this.get('currentlySelectedPlan.plan.id') == this.get('currentEnrollment.medicalPlan.id'));
	}.property('currentlySelectedPlan.plan', 'currentEnrollment.medicalPlan'),
	saveButtonText: function() {
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
			return 'Save & Continue to Dental';
		}

		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
			return 'Save & Continue to Vision';
		}

		if (this.get('currentEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}

		return 'Save & Continue';
	}.property('currentEnrollment.inProcess', 'currentDentalEnrollment', 'currentDentalEnrollment.isEnrollmentOngoing',
				'currentVisionEnrollment', 'currentVisionEnrollment.isEnrollmentOngoing'),
});

_DentalPlanSelectorControllerMixin = Ember.Mixin.create(_ShortCircuitUtilsForPlanSelector, _CobraDeclineOverviewMixin, _EmployeeDependentsEnrollmentMixin, {
	currentlySelectedPlan: null,
	limitInstructions: true,
	isReloadingPlans: false,

	availablePlans: Ember.computed(function() {
		var plans = this.get('plans');
		var stateCarrier = this.get('currentDentalEnrollment.stateCarrier');
		var availablePlans = Ember.A();
		if (plans && stateCarrier) {
			plans.forEach(function(plan) {
				var carrier = plan.get('stateCarrier');
				if (carrier && carrier.get('id') == stateCarrier.get('id')) {
					availablePlans.pushObject(plan);
				}
			});

			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: this.get('sortProperties'),
				content: availablePlans
			});
		}
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: this.get('sortProperties'),
			content: plans
		});
	}).property('currentDentalEnrollment.stateCarrier', 'plans.[]'),

	goBack: function() {
		var currentEnrollment = this.get('currentEnrollment');
		if (currentEnrollment && currentEnrollment.get('isEnrollmentOngoing')) {
			if (currentEnrollment.get('isOpenEnrollment')) {
				return this.transitionToRoute('employee.openenrollment');
			}
			else {
				return this.transitionToRoute('employee.planselector');
			}
		}

		return this.transitionToRoute('index');
	},
	declineOverview: function() {
		var o = Ember.Object.create({
			bundles: this.get('bundles'), // from _BundlingMixins
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment'),
			currLineOfCoverage: this.get('currLineOfCoverage'),
		});
		o.setProperties(this.getCobraDeclineModalData());
		this.send('showModal', 'employee.dentaldeclineoverview', o);
	},
	backButtonText: function() {
		var currentEnrollment = this.get('currentEnrollment');
		if (currentEnrollment && currentEnrollment.get('isEnrollmentOngoing')) {
			return 'Back to Medical';
		}

		return 'Back to Dashboard';
	}.property('currentEnrollment', 'currentEnrollment.isEnrollmentOngoing'),
	hasAlternatives: function() {
		var availablePlans = this.get('availablePlans');
		var hasAlternatives = false;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isSelected')) {
					hasAlternatives = true;
				}
			});
		}

		return hasAlternatives;
	}.property('plans.@each.isSelected'),
	isYouPremium: function() {
		return this.get('priceLevel') == 'youPremium';
	}.property('priceLevel'),
	isYouAndSpousePremium: function() {
		return this.get('priceLevel') == 'youAndSpousePremium';
	}.property('priceLevel'),
	isYouAndChildPremium: function() {
		return this.get('priceLevel') == 'youAndChildPremium';
	}.property('priceLevel'),
	isFamilyPremium: function() {
		return this.get('priceLevel') == 'familyPremium';
	}.property('priceLevel'),
	warnOnWaitingPeriod: function() {
		if (this.get('currentDentalEnrollment.isOpenEnrollment') || this.get('isShortCircuitOEPlanSelector')) {
			return false;
		}

		if (this.get('company.isApproved') && !this.get('employee.isDentalApproved') && this.get('settings.hasDentalWaitingPeriod')) {
			return true;
		}
		return false;
	}.property('currentDentalEnrollment.isOpenEnrollment', 'company.isApproved', 'employee.isDentalApproved', 'settings.hasDentalWaitingPeriod'),
	dentalPlanChangeObserver: function() {
		var availablePlans = this.get('plans');
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (item.get('id') == this.get('currentlySelectedPlan.id')) {
					item.set('isCurrentlySelected', true);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			}, this);
		}
	}.observes('currentlySelectedPlan'),
	instructionsLimited: function() {
		this.set('limitInstructions', true);
	},
	instructionsBig: function() {
		this.set('limitInstructions', false);
	},
	showLimitedInstructions: function() {
		return this.get('limitInstructions');
	}.property('limitInstructions'),
	hideEmployerCostsBreakdown: function() {
		return this.get("companySettings.hideCompanyContributions");
	}.property("companySettings.hideCompanyContributions"),
	overallShortCircuitPlanType: function() {
		return this.getOverallShortCircuitPlanType(this.get('plans'));
	}.property('plans.@each'),
	shouldShowQuoteDependents: function() {
		return this.calculateShouldShowQuoteDependents(this.get('plans'));
	}.property('plans.@each'),
	hasAtleastOneShortCircuitPlanWithBenefitsNotRates: Ember.computed.equal('overallShortCircuitPlanType', 'B'),
	hasAtleastOneShortCircuitPlanWithRatesNotBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'R'),
	hasAtleastOneShortCircuitPlanWithRatesAndBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'A'),

	reloadPlans: function () {
		var dependents = this.get('selectedQuoteDependents');
		var dependentIds = dependents.mapBy('id');
		var query = {
			dependents: dependentIds.join(','),
			forQuoting: true,
			getUnavailable: true,
		};
		if (this.get('mode')) {
			query.mode = this.get('mode');
		}

		this.set('isReloadingPlans', true);
		return Ember.RSVP.resolve().then(function() {
			var oldPlanId = this.get('oldDentalPlan.id');
			if (oldPlanId && !this.get('plans').findBy('id', oldPlanId)) {
				return App.EDentalPlan.find(Ember.merge({
					id: oldPlanId,
				}, query));
			}
			return Ember.RSVP.resolve();
		}.bind(this)).then(function(oldPlans) {
			if (!Ember.isEmpty(oldPlans)) {
				this.set('oldDentalPlan', oldPlans.get('firstObject'));
			}
			return App.EDentalPlan.find(query);
		}.bind(this)).then(function (updatedPlans) {
			this.set('plans', updatedPlans);
		}.bind(this)).finally(function () {
			this.set('isReloadingPlans', false);
		}.bind(this));
	},
	actions: {
		goBack: function () {
			return this.goBack();
		},
		onDependentChange: function(dependent) {
			dependent.toggleProperty('isSelected');
			return this.reloadPlans();
		},
	},
});

App.EmployeeDentalplanselectorController = _TransactionSavingController.extend(_DentalPlanSelectorControllerMixin, _DependentDeclineMixin, _BundlingControllerMixin, {
	needs: ['employee/dentalplanoverview'],
	priceLevel: 'youPremium',

	decline: function(o) {
		var obj = o ? o : this;

		var employee = obj.get('employee');
		var currentEnrollment = obj.get('currentEnrollment');
		var currentDentalEnrollment = obj.get('currentDentalEnrollment');
		var currentVisionEnrollment = obj.get('currentVisionEnrollment');

		currentDentalEnrollment.set('status', 'decline');
		this.declineDependentCoverage(obj.get('dependents'), currentDentalEnrollment);

		var transitionRoute = '';
		if (currentVisionEnrollment &&
			(currentVisionEnrollment.get('status') != 'complete' || currentVisionEnrollment.get('allowEditReviewPlans')))
		{
			if (currentVisionEnrollment.get('isOpenEnrollment')) {
				transitionRoute = 'employee.visionopenenrollment';
			}
			else {
				transitionRoute = 'employee.visionplanselector';
			}
		}
		else if (currentEnrollment) {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
						employee.get('medicalApprovalStatus') == 'decline' &&
						currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			if (hasDeclinedMedical) {
				transitionRoute = 'employee.decline.info';
			}
			else if (currentEnrollment.get('status') == 'complete' ||
						currentEnrollment.get('status') == 'reviewed' ||
						!currentEnrollment.get('isEnrollmentOngoing'))
			{
				transitionRoute = 'employee.decline.dentalinfo';
			}
			else {
				transitionRoute = 'employee.enrollmentsummary';
			}
		}
		else {
			// The employee does not have a current medical or vision enrollment
			transitionRoute = 'employee.decline.dentalinfo';
		}

		this.saveAndContinue().then(function() {
			this.transitionToRoute(transitionRoute);
		}.bind(this));
	},
	showOverview: function(plan) {
		var selected = this.get('priceLevel');
		var contribution = "youContribution";
		if (selected == 'youAndSpousePremium') {
			contribution = "youAndSpouseContribution";
		}
		if (selected == 'youAndChildPremium') {
			contribution = "youAndChildContribution";
		}
		if (selected == 'familyPremium') {
			contribution = "familyContribution";
		}

		var settings = this.get('settings');
		var spanningContribution = settings.get('spanningContribution');
		var spanningContributionEmployee = settings.get('spanningEmployeeContribution');

		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var o = Ember.Object.create({
			plan: plan,
			employee: this.get('employee'),
			company: this.get('company'),
			companySettings: this.get('companySettings'),
			spanningContribution: spanningContribution,
			spanningContributionEmployee: spanningContributionEmployee,
			premium: plan.get(selected),
			contribution: plan.get(contribution),
			showEnroll: true,
			currentEnrollment: null,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
			hasVisionEnrollmentOpen: (currentVisionEnrollment) ? true : false,
			isSCHPlan: plan.get('isShortCircuitPlan'),
			planHasNoBenefits: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'B', // A normal plan can also be of type 'A'
			planHasNoRates: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'R',
		});

		this.send('showModal', this.planOverviewModalName(), o);
	},

	continueToNextPage: function(plan) {
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var dentalPlanModel = Ember.Object.create({
			plan: plan,
			company: this.get('company'),
			employee: this.get('employee'),
			dependents: this.get('dependents'),
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment
		});

		var controller = this.get('controllers.employee/dentalplanoverview');
		controller.set('model', dentalPlanModel);
		return controller.enroll();
	},

	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');

			// Only display bundling modal again if they haven't already seen it before
			// If they've selected a medical plan that was bundled, they will have seen the
			// bundling modal
			if (this.hasSelectedBundledPlan(plan, this.get('selectedDental')) && !(this.get('hasSelectedBundledMedical'))) {
				var transitionModel = Ember.Object.create({
					planSelectorController: this,
					plan: plan,
					continueToNextPage: this.get('continueToNextPage'),
					carrierName: (this.get('selectedCarrierName').bind(this))(plan),
					selectedLineOfCoverage: this.get('selectedDental'),
					allOtherBundledLines: (this.get('allOtherBundledLinesOfCoverage')).bind(this)(plan, this.get('selectedDental')),
					allBundledLines: (this.get('allBundledLinesOfCoverage')).bind(this)(plan, this.get('selectedDental')),
					hasAtLeastOneCarrierWithoutBundling: this.get('hasAtLeastOneCarrierWithoutBundledPlans'),
				});

				return new Ember.RSVP.Promise(function (resolve, reject) {
					transitionModel.setProperties({ resolve: resolve, reject: reject });
					// Redirect to next page after user clicks continue from modal
					this.send('showModal', 'employee.selectedbundledplanmodal', transitionModel);
				}.bind(this));
			} else {
				return this.continueToNextPage(plan);
			}
		},

		goToGlossarySection: function() {
			var $target = Ember.$('.js-dentalGlossary');

			Ember.$('html, body').animate({
				scrollTop: $target.offset().top
			}, 500);
		},
	},

	hasOnlyShortCircuitPlans: function() {
		var availablePlans = this.get('plans');
		var hasOnlyShortCircuitPlans = true;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isShortCircuitPlan')) {
					hasOnlyShortCircuitPlans = false;
				}
			});
		}

		return hasOnlyShortCircuitPlans;
	}.property('plans.@each.isShortCircuitPlan'),
	planOverviewModalName: function() {
		return 'employee.dentalplanoverview';
	},
	saveButtonText: function() {
		if (this.get('mustDeclineCoverage')) {
			// An EE must decline coverage if no plans are selectable.
			// This can happen if the EE had previously selected or declined
			// bundled plans, and as a result, has invalidated what plans
			// are offered for this current line of coverage.
			return 'Decline & Continue to Vision';
		}

		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
			return 'Save & Continue to Vision';
		}

		return 'Save & Continue to Summary';
	}.property('currentVisionEnrollment', 'mustDeclineCoverage', 'currentVisionEnrollment.isEnrollmentOngoing'),
	// The html texts.
	getH1: function() {
		return 'Would you like to enroll in dental insurance?';
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return 'Simply click on the plan below to enroll or <a href="#" {{action "decline"}}>decline dental coverage</a>.';
	}.property(),
	declineBtnTxt: function() {
		return 'Decline Dental Coverage';
	}.property(),
	isShortCircuitOEPlanSelector: function() {
		return false;
	}.property()
});

App.EmployeeDentalplanselectorView = Ember.View.extend({
	templateName: 'dentalenrollment'
});

App.EmployeeDentalopenenrollmentController = _TransactionSavingController.extend(_DentalPlanSelectorControllerMixin, _DependentDeclineMixin, {
	needs: ['employee/dentalplanoverview'],
	priceLevel: 'youPremium',

	looksGood: function(action, o) {
		var obj = o ? o : this;

		var employee = o ? obj.get('employee') : obj.get('model');
		var currentEnrollment = obj.get('currentEnrollment');
		var currentDentalEnrollment = obj.get('currentDentalEnrollment');
		var currentVisionEnrollment = obj.get('currentVisionEnrollment');

		if (action == 'decline') {
			// Clear out plan on the EHE
			if (currentDentalEnrollment.get('dentalPlan')) {
				currentDentalEnrollment.set('dentalPlan', null);
				currentDentalEnrollment.set('dentalPlan_id', null);
			}
			currentDentalEnrollment.set('companyHealthPlan', null);
			this.declineDependentCoverage(employee.get('dependents'), currentDentalEnrollment);
		}
		else if (action == 'renew') {
			// Copy selected dental plan to EHE
			currentDentalEnrollment.set('dentalPlan', employee.get('dentalPlan'));
			currentDentalEnrollment.set('companyHealthPlan', employee.get('employeeSettings.dentalCompanyHealthPlan'));
		}

		// Previously had coverage, declining now => status should be 'decline', (or)
		// Previously had coverage, renewing plan => status should be 'reviewed', (or)
		// Previously declined, declining now too => status should be 'reviewed'
		if (employee.get('isDentalApproved')) {
			if (action == 'decline') {
				currentDentalEnrollment.set('status', 'decline');
			}
			else if (currentDentalEnrollment.get('currentDentalPlanOffered') && action == 'renew') {
				currentDentalEnrollment.set('status', 'reviewed');
			}
		}
		else if (employee.get('hasDeclinedDentalCoverage') && action == 'decline') {
			currentDentalEnrollment.set('status', 'reviewed');
		}

		// If there is a vision open enrollment, transition to vision OE screen
		if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
			if (currentVisionEnrollment.get('isOpenEnrollment')) {
				this.saveAndContinue('employee.visionopenenrollment');
			}
			else {
				this.saveAndContinue('employee.visionplanselector');
			}
			return;
		}

		// If there is a dental open enrollment in progress and a change was made
		if (currentDentalEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}
		// If there is a medical open enrollment in progress and a change was made
		if (currentEnrollment && currentEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}

		var transitionRoute = "employee.enrollmentsummary";
		if (currentEnrollment) {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
					employee.get('medicalApprovalStatus') == 'decline' &&
					currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			if (hasDeclinedMedical) {
				transitionRoute = 'employee.decline.info';
			}
		}
		else {
			// The employee does not have a current medical or vision enrollment
			transitionRoute = 'employee.decline.dentalinfo';
		}

		this.saveAndContinue().then(function() {
			this.transitionToRoute(transitionRoute);
		}.bind(this));
	},
	showOverview: function(plan) {
		var selected = this.get('priceLevel');
		var contribution = "youContribution";
		if (selected == 'youAndSpousePremium') {
			contribution = "youAndSpouseContribution";
		}
		if (selected == 'youAndChildPremium') {
			contribution = "youAndChildContribution";
		}
		if (selected == 'familyPremium') {
			contribution = "familyContribution";
		}

		var hasVisionEnrollmentOpen = false;
		if (this.get('currentVisionEnrollment')) {
			hasVisionEnrollmentOpen = true;
		}

		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var employeeDentalPlanObject = Ember.Object.create({
			plan: plan,
			employee: this.get('employee'),
			company: this.get('company'),
			companySettings: this.get('companySettings'),
			premium: plan.get(selected),
			contribution: plan.get(contribution),
			showEnroll: plan.get('isSelected') && !currentDentalEnrollment.get('currentDentalPlanOffered') ? false : true,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: this.get('currentVisionEnrollment'),
			employeeCobra: this.get('employeeCobra'),
			isOpenEnrollment: true,
			dentalEnrollment: null,
			hasVisionEnrollmentOpen: hasVisionEnrollmentOpen,
		});

		this.send('showModal', 'employee.dentalplanoverview', employeeDentalPlanObject);
	},

	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');
			var employeeDentalPlanModel= Ember.Object.create({
				plan: plan,
				company: this.get('company'),
				employee: this.get('employee'),
				dependents: this.get('dependents'),
				currentDentalEnrollment: this.get('currentDentalEnrollment'),
				currentVisionEnrollment: this.get('currentVisionEnrollment'),
			});

			var controller = this.get('controllers.employee/dentalplanoverview');
			controller.set('model', employeeDentalPlanModel);
			controller.enroll();
		},
	},

	isRenewingPlan: function() {
		return (this.get('currentlySelectedPlan.id') == this.get('oldDentalPlan.id'));
	}.property('currentlySelectedPlan', 'oldDentalPlan'),
	hasSwitchedPlan: function() {
		return (this.get('currentlySelectedPlan.plan.id') == this.get('currentDentalEnrollment.dentalPlan.id'));
	}.property('currentlySelectedPlan.plan', 'currentDentalEnrollment.dentalPlan'),
	saveButtonText: function() {
		// If there is a vision enrollment, transition to vision screen
		if (this.get('currentVisionEnrollment.isEnrollmentOngoing')) {
			return 'Save & Continue to Vision';
		}

		// If there is a dental enrollment in progress and a change was made
		if (this.get('currentDentalEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}

		// If there is a medical enrollment in progress and a change was made
		if (this.get('currentEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}

		return 'Save & Continue';
	}.property('currentEnrollment.inProcess', 'currentDentalEnrollment.inProcess', 'currentVisionEnrollment.isEnrollmentOngoing')
});

App.EmployeeDentalopenenrollmentView = Ember.View.extend({
	templateName: 'dentalenrollment'
});

App.EmployeeScoeolddentalplanselectorController = App.EmployeeDentalplanselectorController.extend({
	needs: ['employee/scoeolddentalplanoverview'],

	selectCurrentPlan: function(plan) {
		if (this.get('declineSCOEOldPlans') == true) {
			this.decline();
			return;
		}
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var o = Ember.Object.create({
			plan: plan,
			company: this.get('company'),
			employee: this.get('employee'),
			dependents: this.get('dependents'),
			spanningContribution: null,
			spanningContributionEmployee: null,
			premium: null,
			contribution: null,
			showEnroll: true,
			currentEnrollment: null,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
			hasVisionEnrollmentOpen: (currentVisionEnrollment) ? true : false,
			isSCHPlan: true
		});
		var controller = this.get('controllers.employee/scoeolddentalplanoverview');
		controller.set('model', o);
		controller.enroll();
	},
	decline: function() {
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		// -1 in old plan symbolizing that the old enrollment was declined.
		currentDentalEnrollment.set('oldPlan', -1);
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.scoenewdentalplanselector');
		}.bind(this));
	},
	planOverviewModalName: function() {
		return 'employee.scoeolddentalplanoverview';
	},
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	isShortCircuitOEOldPlanSelector: function() {
		return true;
	},
	getH1: function() {
		return "Which dental plans are you currently enrolled in?";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select your current dental plan from the list below.";
	}.property(),
	declineBtnTxt: function() {
		return "I declined coverage";
	}.property(),
	saveButtonText: function() {
		return 'Save & Continue';
	}.property(),
	showPlanSelector: function() {
		// If employee hasn't selected anything in the radio button, then don't show the plan selector
		if (this.get('declineSCOEOldPlans') == true || this.get('declineSCOEOldPlans') == null) {
			return false;
		}
		return true;
	}.property('declineSCOEOldPlans'),
});

App.EmployeeScoeolddentalplanselectorView = App.EmployeeDentalplanselectorView.extend({
	templateName: 'dentalenrollment'
});

App.EmployeeScoenewdentalplanselectorController = App.EmployeeDentalplanselectorController.extend({
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	backButtonText: function() {
		return 'Back';
	}.property(),
	goBack: function() {
		return this.transitionToRoute('employee.scoeolddentalplanselector');
	},
	isShortCircuitOENewPlanSelector: function() {
		return true;
	}.property(),
	getH1: function() {
		return "Would you like to enroll in dental plan for the next 12 months ? ";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select a new dental plan from the list below. You can also choose to decline coverage, if you don't want or need dental insurance for the next 12 months. ";
	}.property(),
	enrolledInOldSCOEPlan: function() {
		if (this.get('currentDentalEnrollment.oldPlan') && this.get('currentDentalEnrollment.oldPlan') != -1) {
			return true;
		}
		return false;
	}.property('currentDentalEnrollment.oldPlan'),
});

App.EmployeeScoenewdentalplanselectorView = App.EmployeeDentalplanselectorView.extend({
	templateName: 'dentalenrollment'
});

_VisionPlanSelectorControllerMixin = Ember.Mixin.create(_ShortCircuitUtilsForPlanSelector, _CobraDeclineOverviewMixin, _EmployeeDependentsEnrollmentMixin, {
	currentlySelectedPlan: null,
	limitInstructions: true,
	isReloadingPlans: false,

	availablePlans: Ember.computed(function() {
		var plans = this.get('plans');
		var stateCarrier = this.get('currentVisionEnrollment.stateCarrier');
		var availablePlans = Ember.A();
		if (plans && stateCarrier) {
			plans.forEach(function(plan) {
				var carrier = plan.get('stateCarrier');
				if (carrier && carrier.get('id') == stateCarrier.get('id')) {
					availablePlans.pushObject(plan);
				}
			});

			return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: this.get('sortProperties'),
				content: availablePlans
			});
		}
		return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
			sortProperties: this.get('sortProperties'),
			content: plans
		});
	}).property('currentVisionEnrollment.stateCarrier', 'plans.[]'),

	goBack: function() {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
			if (currentDentalEnrollment.get('isOpenEnrollment')) {
				return this.transitionToRoute('employee.dentalopenenrollment');
			}
			else {
				return this.transitionToRoute('employee.dentalplanselector');
			}
		}

		if (currentEnrollment && currentEnrollment.get('isEnrollmentOngoing')) {
			if (currentEnrollment.get('isOpenEnrollment')) {
				return this.transitionToRoute('employee.openenrollment');
			}
			else {
				return this.transitionToRoute('employee.planselector');
			}
		}

		return this.transitionToRoute('index');
	},
	declineOverview: function() {
		var o = Ember.Object.create({
			bundles: this.get('bundles'),
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment'),
			currLineOfCoverage: this.get('currLineOfCoverage'),
		});
		o.setProperties(this.getCobraDeclineModalData());
		this.send('showModal', 'employee.visiondeclineoverview', o);
	},
	backButtonText: function() {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
			return 'Back to Dental';
		}

		if (currentEnrollment && currentEnrollment.get('isEnrollmentOngoing')) {
			return 'Back to Medical';
		}

		return 'Back to Dashboard';
	}.property('currentEnrollment', 'currentEnrollment.isEnrollmentOngoing',
				'currentDentalEnrollment', 'currentDentalEnrollment.isEnrollmentOngoing'),
	hasAlternatives: function() {
		var availablePlans = this.get('availablePlans');
		var hasAlternatives = false;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isSelected')) {
					hasAlternatives = true;
				}
			});
		}

		return hasAlternatives;
	}.property('plans.@each.isSelected'),
	dentalAvailable: function() {
		return this.get('company.selectedDentalPlan');
	}.property('company.selectedDentalPlan'),
	isYouPremium: function() {
		return this.get('priceLevel') == 'youPremium';
	}.property('priceLevel'),
	isYouAndSpousePremium: function() {
		return this.get('priceLevel') == 'youAndSpousePremium';
	}.property('priceLevel'),
	isYouAndChildPremium: function() {
		return this.get('priceLevel') == 'youAndChildPremium';
	}.property('priceLevel'),
	isFamilyPremium: function() {
		return this.get('priceLevel') == 'familyPremium';
	}.property('priceLevel'),
	warnOnWaitingPeriod: function() {
		if (this.get('currentVisionEnrollment.isOpenEnrollment') || this.get('isShortCircuitOEPlanSelector')) {
			return false;
		}

		if (this.get('company.isApproved') && !this.get('employee.isVisionApproved') && this.get('settings.hasVisionWaitingPeriod')) {
			return true;
		}
		return false;
	}.property('currentVisionEnrollment.isOpenEnrollment', 'company.isApproved', 'employee.isVisionApproved', 'settings.hasVisionWaitingPeriod'),
	visionPlanChangeObserver: function() {
		var availablePlans = this.get('plans');
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (item.get('id') == this.get('currentlySelectedPlan.id')) {
					item.set('isCurrentlySelected', true);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			}, this);
		}
	}.observes('currentlySelectedPlan'),
	instructionsLimited: function() {
		this.set('limitInstructions', true);
	},
	instructionsBig: function() {
		this.set('limitInstructions', false);
	},
	showLimitedInstructions: function() {
		return this.get('limitInstructions');
	}.property('limitInstructions'),
	hideEmployerCostsBreakdown: function() {
		return this.get("companySettings.hideCompanyContributions");
	}.property("companySettings.hideCompanyContributions"),
	overallShortCircuitPlanType: function() {
		return this.getOverallShortCircuitPlanType(this.get('plans'));
	}.property('plans.@each'),
	shouldShowQuoteDependents: function() {
		return this.calculateShouldShowQuoteDependents(this.get('plans'));
	}.property('plans.@each'),
	hasAtleastOneShortCircuitPlanWithBenefitsNotRates: Ember.computed.equal('overallShortCircuitPlanType', 'B'),
	hasAtleastOneShortCircuitPlanWithRatesNotBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'R'),
	hasAtleastOneShortCircuitPlanWithRatesAndBenefits: Ember.computed.equal('overallShortCircuitPlanType', 'A'),

	reloadPlans: function () {
		var dependents = this.get('selectedQuoteDependents');
		var dependentIds = dependents.mapBy('id');
		var query = {
			dependents: dependentIds.join(','),
			forQuoting: true,
			getUnavailable: true,
		};
		if (this.get('mode')) {
			query.mode = this.get('mode');
		}

		this.set('isReloadingPlans', true);
		return Ember.RSVP.resolve().then(function() {
			var oldPlanId = this.get('oldVisionPlan.id');
			if (oldPlanId && !this.get('plans').findBy('id', oldPlanId)) {
				return App.EVisionPlan.find(Ember.merge({
					id: oldPlanId,
				}, query));
			}
			return Ember.RSVP.resolve();
		}.bind(this)).then(function(oldPlans) {
			if (!Ember.isEmpty(oldPlans)) {
				this.set('oldVisionPlan', oldPlans.get('firstObject'));
			}
			return App.EVisionPlan.find(query);
		}.bind(this)).then(function (updatedPlans) {
			this.set('plans', updatedPlans);
		}.bind(this)).finally(function () {
			this.set('isReloadingPlans', false);
		}.bind(this));
	},
	actions: {
		goBack: function () {
			return this.goBack();
		},
		onDependentChange: function(dependent) {
			dependent.toggleProperty('isSelected');
			return this.reloadPlans();
		},
	},
});

App.EmployeeVisionplanselectorController = _TransactionSavingController.extend(_VisionPlanSelectorControllerMixin, _DependentDeclineMixin, _BundlingControllerMixin, {
	needs: ['employee/visionplanoverview'],
	priceLevel: 'youPremium',

	decline: function(o) {
		var obj = o ? o : this;

		var employee = obj.get('employee');
		var currentEnrollment = obj.get('currentEnrollment');
		var currentDentalEnrollment = obj.get('currentDentalEnrollment');
		var currentVisionEnrollment = obj.get('currentVisionEnrollment');

		currentVisionEnrollment.set('status', 'decline');
		this.declineDependentCoverage(obj.get('dependents'), currentVisionEnrollment);

		var transitionRoute = '';
		if (!currentEnrollment && !currentDentalEnrollment) {
			transitionRoute = 'employee.decline.visioninfo';
		}
		else if (currentEnrollment && !currentDentalEnrollment) {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
						employee.get('medicalApprovalStatus') == 'decline' &&
						currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			if (hasDeclinedMedical) {
				transitionRoute = 'employee.decline.info';
			}
			else if (currentEnrollment.get('status') == 'complete' ||
						currentEnrollment.get('status') == 'reviewed' ||
						!currentEnrollment.get('isEnrollmentOngoing'))
			{
				transitionRoute = 'employee.decline.visioninfo';
			}
			else {
				// Has begun medical, but is declining vision
				transitionRoute = 'employee.enrollmentsummary';
			}
		}
		else if (!currentEnrollment && currentDentalEnrollment) {
			var hasDeclinedDental = false;
			if (currentDentalEnrollment.get('status') == 'decline') {
				hasDeclinedDental = true;
			}
			else if (currentDentalEnrollment.get('isOpenEnrollment') &&
						employee.get('dentalApprovalStatus') == 'decline' &&
						currentDentalEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined dental and is declining dental again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedDental = true;
			}

			if (hasDeclinedDental) {
				transitionRoute = 'employee.decline.info';
			}
			else if (currentDentalEnrollment.get('status') == 'complete' ||
						currentDentalEnrollment.get('status') == 'reviewed' ||
						!currentDentalEnrollment.get('isEnrollmentOngoing'))
			{
				transitionRoute = 'employee.decline.visioninfo';
			}
			else {
				// Has begun dental, but is declining vision
				transitionRoute = 'employee.enrollmentsummary';
			}
		}
		else {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
						employee.get('medicalApprovalStatus') == 'decline' &&
						currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			var hasDeclinedDental = false;
			if (currentDentalEnrollment.get('status') == 'decline') {
				hasDeclinedDental = true;
			}
			else if (currentDentalEnrollment.get('isOpenEnrollment') &&
						employee.get('dentalApprovalStatus') == 'decline' &&
						currentDentalEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined dental and is declining dental again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedDental = true;
			}

			if (hasDeclinedMedical && hasDeclinedDental) {
				transitionRoute = 'employee.decline.info';
			}
			else if ((currentEnrollment.get('status') == 'complete' ||
						currentEnrollment.get('status') == 'reviewed' ||
						!currentEnrollment.get('isEnrollmentOngoing')) &&
					(currentDentalEnrollment.get('status') == 'complete' ||
						currentDentalEnrollment.get('status') == 'reviewed' ||
						!currentDentalEnrollment.get('isEnrollmentOngoing')))
			{
				transitionRoute = 'employee.decline.visioninfo';
			}
			else {
				transitionRoute = 'employee.enrollmentsummary';
			}
		}

		this.saveAndContinue().then(function() {
			this.transitionToRoute(transitionRoute);
		}.bind(this));
	},
	showOverview: function(plan) {
		var selected = this.get('priceLevel');
		var contribution = "youContribution";
		if (selected == 'youAndSpousePremium') {
			contribution = "youAndSpouseContribution";
		}
		if (selected == 'youAndChildPremium') {
			contribution = "youAndChildContribution";
		}
		if (selected == 'familyPremium') {
			contribution = "familyContribution";
		}

		var settings = this.get('settings');
		var spanningContribution = settings.get('spanningContribution');
		var spanningContributionEmployee = settings.get('spanningEmployeeContribution');

		var currentEnrollment = this.get('currentEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');

		var o = Ember.Object.create({
			plan: plan,
			employee: this.get('employee'),
			company: this.get('company'),
			companySettings: this.get('companySettings'),
			premium: plan.get(selected),
			contribution: plan.get(contribution),
			spanningContribution: spanningContribution,
			spanningContributionEmployee: spanningContributionEmployee,
			showEnroll: true,
			currentEnrollment: currentEnrollment,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
			isSCHPlan: plan.get('isShortCircuitPlan'),
			planHasNoBenefits: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'B', // A normal plan can also be of type 'A'
			planHasNoRates: plan.get('shortCircuitPlanType') != 'A' && plan.get('shortCircuitPlanType') != 'R',
		});

		this.send('showModal', this.planOverviewModalName(), o);
	},
	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');
			var employeeVisionPlanModel = Ember.Object.create({
				plan: plan,
				company: this.get('company'),
				employee: this.get('employee'),
				dependents: this.get('dependents'),
				currentEnrollment: this.get('currentEnrollment'),
				currentDentalEnrollment: this.get('currentDentalEnrollment'),
				currentVisionEnrollment: this.get('currentVisionEnrollment')
			});
			var controller = this.get('controllers.employee/visionplanoverview');
			controller.set('model', employeeVisionPlanModel);
			return controller.enroll();
		},

		goToGlossarySection: function() {
			var $target = Ember.$('.js-visionGlossary');

			Ember.$('html, body').animate({
				scrollTop: $target.offset().top
			}, 500);
		},
	},

	hasOnlyShortCircuitPlans: function() {
		var availablePlans = this.get('plans');
		var hasOnlyShortCircuitPlans = true;
		if (availablePlans) {
			availablePlans.forEach(function(item) {
				if (!item.get('isShortCircuitPlan')) {
					hasOnlyShortCircuitPlans = false;
				}
			});
		}

		return hasOnlyShortCircuitPlans;
	}.property('plans.@each.isShortCircuitPlan'),
	planOverviewModalName: function() {
		return 'employee.visionplanoverview';
	},
	saveButtonText: function() {
		if (this.get('mustDeclineCoverage')) {
			// An EE must decline coverage if no plans are selectable.
			// This can happen if the EE had previously selected or declined
			// bundled plans, and as a result, has invalidated what plans
			// are offered for this current line of coverage.
			return 'Decline & Continue to Summary';
		}
		return 'Save & Continue to Summary';
	}.property('currentVisionEnrollment', 'mustDeclineCoverage'),
	// All htmls copies
	getH1: function() {
		return "Would you like to enroll in vision insurance?";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return 'Simply click on the plan below to enroll or <a href="#" {{action "decline"}}>decline dental coverage</a>.';
	}.property(),
	declineBtnTxt: function() {
		return "Decline Vision Coverage";
	}.property(),
	isShortCircuitOEPlanSelector: function() {
		return false;
	}.property(),
});

App.EmployeeVisionplanselectorView = Ember.View.extend({
	templateName: 'visionenrollment'
});

App.EmployeeScoeoldvisionplanselectorController = App.EmployeeVisionplanselectorController.extend({
	needs: ['employee/scoeoldvisionplanoverview'],

	selectCurrentPlan: function(plan) {
		if (this.get('declineSCOEOldPlans') == true) {
			this.decline();
			return;
		}

		var currentEnrollment = this.get('currentEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var o = Ember.Object.create({
			plan: plan,
			employee: this.get('employee'),
			dependents: this.get('dependents'),
			company: this.get('company'),
			premium: null,
			contribution: null,
			spanningContribution: null,
			spanningContributionEmployee: null,
			showEnroll: true,
			currentEnrollment: currentEnrollment,
			currentDentalEnrollment: currentDentalEnrollment,
			currentVisionEnrollment: currentVisionEnrollment,
			isSCHPlan: true,
		});
		var controller = this.get('controllers.employee/scoeoldvisionplanoverview');
		controller.set('model', o);
		controller.enroll();
	},

	decline: function() {
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		// -1 in old plan symbolizing that the old enrollment was declined.
		currentVisionEnrollment.set('oldPlan', -1);
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.scoenewvisionplanselector');
		}.bind(this));
	},
	planOverviewModalName: function() {
		return 'employee.scoeoldvisionplanoverview';
	},
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	isShortCircuitOEOldPlanSelector: function() {
		return true;
	},
	getH1: function() {
		return "Which vision plans are you currently enrolled in?";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select your current vision plan from the list below.";
	}.property(),
	declineBtnTxt: function() {
		return "I declined coverage";
	}.property(),
	saveButtonText: function() {
		return 'Save & Continue';
	}.property(),
	showPlanSelector: function() {
		// If employee hasn't selected anything in the radio button, then don't show the plan selector
		if (this.get('declineSCOEOldPlans') == true || this.get('declineSCOEOldPlans') == null) {
			return false;
		}
		return true;
	}.property('declineSCOEOldPlans'),
});

App.EmployeeScoeoldvisionplanselectorView = App.EmployeeVisionplanselectorView.extend({
	templateName: 'visionenrollment'
});

App.EmployeeScoenewvisionplanselectorController = App.EmployeeVisionplanselectorController.extend({
	isShortCircuitOEPlanSelector: function() {
		return true;
	}.property(),
	backButtonText: function() {
		return 'Back';
	}.property(),
	goBack: function() {
		return this.transitionToRoute('employee.scoeoldvisionplanselector');
	},
	getH1: function() {
		return "Would you like to enroll in vision plan for the next 12 months ? ";
	}.property(),
	getTextBeforePlanselectorTable: function() {
		return "Select a new vision plan from the list below. You can also choose to decline coverage, if you don't want or need vision insurance for the next 12 months. ";
	}.property(),
	enrolledInOldSCOEPlan: function() {
		if (this.get('currentVisionEnrollment.oldPlan') && this.get('currentVisionEnrollment.oldPlan') != -1) {
			return true;
		}
		return false;
	}.property('currentVisionEnrollment.oldPlan'),
});

App.EmployeeScoenewvisionplanselectorView = App.EmployeeVisionplanselectorView.extend({
	templateName: 'visionenrollment'
});

App.EmployeeVisionopenenrollmentController = _TransactionSavingController.extend(_VisionPlanSelectorControllerMixin, _DependentDeclineMixin, {
	needs: ['employee/visionplanoverview'],
	priceLevel: 'youPremium',

	looksGood: function(action, o) {
		var obj = o ? o : this;

		var employee = o ? obj.get('employee') : obj.get('model');
		var currentEnrollment = obj.get('currentEnrollment');
		var currentDentalEnrollment = obj.get('currentDentalEnrollment');
		var currentVisionEnrollment = obj.get('currentVisionEnrollment');

		// Should we reset the selection?
		if (action == 'decline') {
			// Clear out plan on the EHE
			if (currentVisionEnrollment.get('visionPlan')) {
				currentVisionEnrollment.set('visionPlan', null);
				currentVisionEnrollment.set('visionPlan_id', null);
			}
			currentVisionEnrollment.set('companyHealthPlan', null);
			this.declineDependentCoverage(employee.get('dependents'), currentVisionEnrollment);
		}
		else if (action == 'renew') {
			// Copy selected vision plan to EHE
			currentVisionEnrollment.set('visionPlan', employee.get('visionPlan'));
			currentVisionEnrollment.set('companyHealthPlan', employee.get('employeeSettings.visionCompanyHealthPlan'));
		}

		// Previously had coverage, declining now => status should be 'decline', (or)
		// Previously had coverage, renewing plan => status should be 'reviewed', (or)
		// Previously declined, declining now too => status should be 'reviewed'
		if (employee.get('isVisionApproved')) {
			if (action == 'decline') {
				currentVisionEnrollment.set('status', 'decline');
			}
			else if (currentVisionEnrollment.get('currentVisionPlanOffered') && action == 'renew') {
				currentVisionEnrollment.set('status', 'reviewed');
			}
		}
		else if (employee.get('hasDeclinedVisionCoverage') && action == 'decline') {
			currentVisionEnrollment.set('status', 'reviewed');
		}

		// Vision enrollment in progress
		if (currentVisionEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}
		// If there is a dental open enrollment in progress and a change was made
		if (currentDentalEnrollment && currentDentalEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}
		// If there is a medical open enrollment in progress and a change was made
		if (currentEnrollment && currentEnrollment.get('inProcess')) {
			this.saveAndContinue('employee.enrollmentsummary');
			return;
		}

		var transitionRoute = 'employee.enrollmentsummary';
		if (!currentEnrollment && !currentDentalEnrollment) {
			transitionRoute = 'employee.decline.visioninfo';
		}
		else if (currentEnrollment && !currentDentalEnrollment) {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
					employee.get('medicalApprovalStatus') == 'decline' &&
					currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			if (hasDeclinedMedical) {
				transitionRoute = 'employee.decline.info';
			}
		}
		else if (!currentEnrollment && currentDentalEnrollment) {
			var hasDeclinedDental = false;
			if (currentDentalEnrollment.get('status') == 'decline') {
				hasDeclinedDental = true;
			}
			else if (currentDentalEnrollment.get('isOpenEnrollment') &&
					employee.get('dentalApprovalStatus') == 'decline' &&
					currentDentalEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined dental and is declining dental again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedDental = true;
			}

			if (hasDeclinedDental) {
				transitionRoute = 'employee.decline.info';
			}
		}
		else {
			var hasDeclinedMedical = false;
			if (currentEnrollment.get('status') == 'decline') {
				hasDeclinedMedical = true;
			}
			else if (currentEnrollment.get('isOpenEnrollment') &&
					employee.get('medicalApprovalStatus') == 'decline' &&
					currentEnrollment.get('status') == 'reviewed')
			{
				// If the employee has previously declined medical and is declining medical again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedMedical = true;
			}

			var hasDeclinedDental = false;
			if (currentDentalEnrollment.get('status') == 'decline') {
				hasDeclinedDental = true;
			}
			else if (currentDentalEnrollment.get('isOpenEnrollment') &&
					employee.get('dentalApprovalStatus') == 'decline' &&
					(currentDentalEnrollment.get('status') == 'reviewed'))
			{
				// If the employee has previously declined dental and is declining dental again as part of open enrollment,
				// redirect the employee appropriately
				hasDeclinedDental = true;
			}

			if (hasDeclinedMedical && hasDeclinedDental) {
				transitionRoute = 'employee.decline.info';
			}
		}

		this.saveAndContinue().then(function() {
			this.transitionToRoute(transitionRoute);
		}.bind(this));
	},
	showOverview: function(plan) {
		var selected = this.get('priceLevel');
		var contribution = "youContribution";
		if (selected == 'youAndSpousePremium') {
			contribution = "youAndSpouseContribution";
		}
		if (selected == 'youAndChildPremium') {
			contribution = "youAndChildContribution";
		}
		if (selected == 'familyPremium') {
			contribution = "familyContribution";
		}
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var o = Ember.Object.create({
			plan: plan,
			employee: this.get('employee'),
			company: this.get('company'),
			companySettings: this.get('companySettings'),
			premium: plan.get(selected),
			contribution: plan.get(contribution),
			showEnroll: plan.get('isSelected') && !currentVisionEnrollment.get('currentVisionPlanOffered') ? false : true,
			currentVisionEnrollment: currentVisionEnrollment,
			isOpenEnrollment: currentVisionEnrollment.get('isOpenEnrollment'),
			dentalEnrollment: null,
			visionEnrollment: null,
			employeeCobra: this.get('employeeCobra')
		});
		this.send('showModal', 'employee.visionplanoverview', o);
	},

	actions: {
		enroll: function() {
			var plan = this.get('currentlySelectedPlan');
			var employeeVisionPlanModel= Ember.Object.create({
				plan: plan,
				company: this.get('company'),
				employee: this.get('employee'),
				dependents: this.get('dependents'),
				currentEnrollment: this.get('currentEnrollment'),
				currentDentalEnrollment: this.get('currentDentalEnrollment'),
				currentVisionEnrollment: this.get('currentVisionEnrollment')
			});

			var controller = this.get('controllers.employee/visionplanoverview');
			controller.set('model', employeeVisionPlanModel);
			controller.enroll();
		},
	},

	isRenewingPlan: function() {
		return (this.get('currentlySelectedPlan.id') == this.get('oldVisionPlan.id'));
	}.property('currentlySelectedPlan', 'oldVisionPlan'),
	hasSwitchedPlan: function() {
		return (this.get('currentlySelectedPlan.plan.id') == this.get('currentVisionEnrollment.visionPlan.id'));
	}.property('currentlySelectedPlan.plan', 'currentVisionEnrollment.visionPlan'),
	saveButtonText: function() {
		// Vision enrollment in progress
		if (this.get('currentVisionEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}
		// If there is a dental open enrollment in progress and a change was made
		if (this.get('currentDentalEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}
		// If there is a medical open enrollment in progress and a change was made
		if (this.get('currentEnrollment.inProcess')) {
			return 'Save & Continue to Summary';
		}

		return 'Save & Continue';
	}.property('currentEnrollment.inProcess', 'currentDentalEnrollment.inProcess', 'currentVisionEnrollment.inProcess'),
});

App.EmployeeVisionopenenrollmentView = Ember.View.extend({
	templateName: 'visionenrollment'
});
