/*
 * EPlanManager manages queries to all MDV EPlan APIs.
 *
 * Why:
 *     - App.EPlan.find({...}) send new request everytime its called
 *     - Plan selectors do not have a common parent route, so there is not
 *       a central place to store and share the EPlan response.
 * How:
 *     It loads and keeps track of all EPlan API responses in local mappings.
 *     For all subsequent GET requests, it serves from the local mappings.
 * Limitation:
 *     Must call .invalidate() when EHE/enrollment effective date has changed,
 *     i.e., IE/OE and QLE/OE.
 */
var EPlanManager = Ember.Object.create({
	// Returns a promise which resolves a EPlans array.
	getEPlans: function(lineOfCoverage, mode) {
		// If mode is given, do not look into local mapping.
		if (mode) {
			return this._loadEPlans(lineOfCoverage, false, mode);
		}

		// Return response from local mapping.
		return this._loadCachedEPlanLists().then(function(locToPlans) {
			return locToPlans[lineOfCoverage];
		});
	},

	// Returns a promise which resolves a EPlan object.
	getEPlanById: function(lineOfCoverage, id, mode) {
		if (!id) {
			return Ember.RSVP.resolve();
		}

		// If mode is given, do not look into local mapping.
		if (mode) {
			return this._loadEPlan(lineOfCoverage, mode, id);
		}

		// Try find in EPlan promises first.
		var ePlanPromises = this._getEPlanPromises(lineOfCoverage);
		if (ePlanPromises.hasOwnProperty(id)) {
			return ePlanPromises[id];
		}

		// Then, try find the plan from cached EPlans list.
		var _this = this;
		return this._loadCachedEPlanLists().then(function(locToPlans) {
			var plans = locToPlans[lineOfCoverage];
			var plan = plans.findBy('id', id);
			if (plan) {
				return plan;
			}

			// Could not find plan from any local mapping, request from server.
			var ePlanPromise = _this._loadEPlan(lineOfCoverage, null, id);
			// Store EPlan promise for next hit.
			ePlanPromises[id] = ePlanPromise;
			return ePlanPromise;
		});
	},
	invalidate: function() {
		this._cachedEPlanListsPromise = null;
		this._ePlanPromisesByLoc = {};
	},

	// Private
	_EPLANS: {
		medical: App.EPlan,
		dental: App.EDentalPlan,
		vision: App.EVisionPlan,
	},
	_cachedEPlanListsPromise: null,
	_ePlanPromisesByLoc: {},
	_getEPlanPromises: function(lineOfCoverage) {
		this._ePlanPromisesByLoc[lineOfCoverage] = this._ePlanPromisesByLoc[lineOfCoverage] || {};
		return this._ePlanPromisesByLoc[lineOfCoverage];
	},
	_loadEPlans: function(lineOfCoverage, fromCache, mode, id) {
		var params = {
			forQuoting: true,
			getUnavailable: true,
		};
		if (mode) {
			params.mode = mode;
		}
		if (fromCache) {
			params.fromCache = fromCache;
		}
		if (id) {
			params.id = id;
		}
		return this._EPLANS[lineOfCoverage].find(params);
	},
	_loadEPlan: function(lineOfCoverage, mode, id) {
		return this._loadEPlans(lineOfCoverage, false, mode, id).then(function(plans) {
			// Returns `undefined` when `plans` is empty
			return plans.get('firstObject');
		});
	},
	_loadCachedEPlanLists: function() {
		if (!this._cachedEPlanListsPromise) {
			this._cachedEPlanListsPromise = Ember.RSVP.hash({
				medical: this._loadEPlans('medical', true),
				dental: this._loadEPlans('dental', true),
				vision: this._loadEPlans('vision', true),
			});
		}
		return this._cachedEPlanListsPromise;
	},
});

App.EmployeeBasicinfoRoute = Ember.Route.extend(_AuthEmployeeMixin, {
	model: function(params) {
		return dashboard('employee');
	}
});
App.EmployeePlanselectorRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, _BundlingMixin, {
	// redirect to confirm basic info if EE doesn't have DoB and Zip.
	beforeModel: function(transition) {
		return dashboard('employee').then(function(employee) {
			if (!employee.get('zip') || !employee.get('dob') || !employee.get('sex')) {
				this.transitionTo('employee.dependents', {
					queryParams: {
						fromRoute: 'employee.planselector',
						forceValidation: true}});
				transition.abort();
				return employee;
			}
		}.bind(this));
	},
	model: function() {
		var currentEnrollmentPromise = dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment');
		var employerHSAContribution = currentEnrollmentPromise.then(function(currentEnrollment) {
			if (!currentEnrollment) {
				return null;
			}
			return Ember.ajax('/custom_api/getEmployerHSAContributionsByEffectiveDate?effectiveDate=' + currentEnrollment.get('effectiveDate'));
		});

		var medicalPlans = EPlanManager.getEPlans('medical');
		var dentalPlans = EPlanManager.getEPlans('dental');
		var visionPlans = EPlanManager.getEPlans('vision');
		var oldMedicalPlan = dashboard('employee').then(function(employee) {
			return EPlanManager.getEPlanById('medical', employee.get('selectedPlan'));
		});

		return this.all(dashboard('employee'), {
			'plans': EPlanManager.getEPlans('medical', this.getMode()),
			'company': dashboard('company'),
			'companySettings': dashboard('company.settings'),
			'employee': dashboard('employee'),
			'employeeCobraEnrolleeProperties': dashboard('employee.employeeCobraEnrolleeProperties'),
			'employeeCobra': dashboard('employee.employeeCobra'),
			'dependents': dashboard('employee.dependents'),
			'settings': dashboard('employee.employeeSettings'),
			'hsa': dashboard('employee.hsa'),
			'companyHsa': dashboard('company.hsa'),
			'currentCompanyHraPlan': dashboard('company.hra.current'),
			'nextCompanyHraPlan': dashboard('company.hra.next'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'shortCircuitOEStats' : App.ShortCircuitOpenEnrollmentStats.find('me'),
			'currLineOfCoverage': 'MEDICAL',
			'employerHSAContribution': employerHSAContribution,
			'oldPlan': oldMedicalPlan,
			'bundles': this.loadBundles(medicalPlans, dentalPlans, visionPlans), // from _BundlingMixin
		});
	},
	getMode : function() {
		return null;
	},
	afterModel: function(model) {
		var promiseArray = [];

		var employee = this.otherModels.employee;
		var plans = this.otherModels.plans;

		// If there is a medical short circuit OE, then redirect to that route.
		if (this.shouldRedirectToSCOEPlanSelector() &&
			this.otherModels.shortCircuitOEStats.get('atleastOneMedicalSCOEStepsComplete'))
		{
			this.transitionTo('employee.scoeoldplanselector');
		}

		var dependents = this.otherModels.dependents;
		promiseArray.push(Ember.RSVP.all(dependents.mapBy('dependentHealthEnrollments')));

		return Ember.RSVP.all(promiseArray);
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return true;
	},
	redirect: function(model, transition) {
		this._super(model, transition);
		var employeeEnrollment = this.otherModels.currentEnrollment;
		if (!(employeeEnrollment && employeeEnrollment.get('isEnrollmentOngoing'))) {
			this.transitionTo('employee.dentalplanselector');
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		// Bundling
		this._bundlingSetup(controller);

		// Reset controller properties
		controller.setProperties({
			isReloadingPlans: false,
			currentlySelectedPlan: null,
		});

		var plans = controller.get('plans');
		var currentEnrollment = controller.get('currentEnrollment');
		if (currentEnrollment && !currentEnrollment.get('status')) {
			currentEnrollment.set('status', 'start');
		}

		var selectedPlan = controller.get('employee.selectedPlan');
		var oldPlan = null;
		if (selectedPlan) {
			plans.forEach(function(item) {
				if (item.get('id') == selectedPlan) {
					oldPlan = item;
				}
			});
		}

		var currentPlan = currentEnrollment.get('medicalPlan');
		if (currentPlan) {
			plans.forEach(function(item) {
				if (!item.get('isCurrentlySelected')) {
					if (currentPlan && item.get('id') == currentPlan.get('id')) {
						item.set('isCurrentlySelected', true);
						controller.set('currentlySelectedPlan', item);
					}
					else {
						item.set('isCurrentlySelected', false);
					}
				}
			});
		}

		if (oldPlan && !controller.get('currentlySelectedPlan')) {
			oldPlan.set('isCurrentlySelected', true);
		}

		controller.set('mode', this.getMode());
		controller.set('currLineOfCoverage', 'MEDICAL');
		controller.set('routeName', this.get('routeName'));

		// Update dependent.isSelected for medical
		var dependents = controller.get('dependents');
		dependents.forEach(function (dependent) {
			dependent.set('isSelected', dependent.get('medicalCheckBoxStatus'));
		});

		// Always reload for up to date EPlans, but we should NOT wait for it.
		controller.reloadPlans();
	}
});
App.EmployeeOpenenrollmentRoute = App.EmployeePlanselectorRoute.extend({
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('priceLevel', 'youPremium');
	}
});
App.EmployeeScoeoldplanselectorRoute = App.EmployeePlanselectorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		// Reset the selected plans.
		var plans = controller.get('plans');
		var currentEnrollment = controller.get('currentEnrollment');
		// This will take care of setting appropriate value for radio buttons which inturn controls
		// whether the plan selector is going to appear or not.
		if (currentEnrollment.get('oldPlan')) {
			if (currentEnrollment.get('oldPlan') == -1) {
				controller.set('declineSCOEOldPlans', true);
			}
			else {
				controller.set('declineSCOEOldPlans', false);
			}
		}
		var selectedPlan = (currentEnrollment.get('oldPlan') && currentEnrollment.get('oldPlan') != -1) ? currentEnrollment.get('oldPlan') : currentEnrollment.get('medicalPlan.id');
		controller.set('currentlySelectedPlan', null);
		if (selectedPlan) {
			plans.forEach(function(item) {
				if (item.get('id') == selectedPlan) {
					item.set('isCurrentlySelected', true);
					controller.set('currentlySelectedPlan', item);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			});
		}
	},
	getMode: function() {
		return "sc_oe_current";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	},
});
App.EmployeeScoenewplanselectorRoute = App.EmployeePlanselectorRoute.extend({
	getMode: function() {
		return "sc_oe_new";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	}
});
App.EmployeeDentalplanselectorRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, _BundlingMixin, {
	// redirect to confirm basic info if EE doesn't have DoB and Zip.
	beforeModel: function(transition) {
		return dashboard('employee').then(function(employee) {
			if (!employee.get('zip') || !employee.get('dob') || !employee.get('sex')) {
				this.transitionTo('employee.dependents', {
					queryParams: {
						fromRoute: 'employee.dentalplanselector',
						forceValidation: true}});
				transition.abort();
				return employee;
			}
		}.bind(this));
	},
	model: function() {
		var medicalPlans = EPlanManager.getEPlans('medical');
		var dentalPlans = EPlanManager.getEPlans('dental');
		var visionPlans = EPlanManager.getEPlans('vision');
		var oldDentalPlan = dashboard('employee').then(function(employee) {
			return EPlanManager.getEPlanById('dental', employee.get('selectedDentalPlan'));
		});

		return this.all(dashboard('employee'), {
			'plans': EPlanManager.getEPlans('dental', this.getMode()),
			'dependents': dashboard('employee.dependents'),
			'settings': dashboard('employee.employeeSettings'),
			'employee': dashboard('employee'),
			'employeeCobraEnrolleeProperties': dashboard('employee.employeeCobraEnrolleeProperties'),
			'company': dashboard('company'),
			'companySettings': dashboard('company.settings'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'currLineOfCoverage' : 'DENTAL',
			'oldDentalPlan': oldDentalPlan,
			'bundles': this.loadBundles(medicalPlans, dentalPlans, visionPlans), // from _BundlingMixin
		});
	},
	afterModel: function(model) {
		var promiseArray = [];

		// If there is a dental short circuit OE, then redirect to that route.
		if (this.shouldRedirectToSCOEPlanSelector() &&
			this.otherModels.shortCircuitOEStats.get('dentalSCOEStepsComplete'))
		{
			this.transitionTo('employee.scoeolddentalplanselector');
			return;
		}

		var dependents = this.otherModels.dependents;
		promiseArray.push(Ember.RSVP.all(dependents.mapBy('dependentHealthEnrollments')));

		return Ember.RSVP.all(promiseArray);
	},
	redirect: function(model, transition) {
		this._super(model, transition);
		var employeeEnrollment = this.otherModels.currentDentalEnrollment;
		if (!(employeeEnrollment && employeeEnrollment.get('isEnrollmentOngoing'))) {
			this.transitionTo('employee.visionplanselector');
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		this._bundlingSetup(controller);

		// Reset controller properties
		controller.setProperties({
			isReloadingPlans: false,
			currentlySelectedPlan: null,
		});

		var currentDentalEnrollment = controller.get('currentDentalEnrollment');
		if (currentDentalEnrollment && !currentDentalEnrollment.get('status')) {
			currentDentalEnrollment.set('status', 'start');
		}

		var plans = controller.get('plans');

		// TODO (chenefits):
		// I am replacing plans with selectableDentalPlans
		// but I don't know if that's ok since the 'plans' in the model
		// calls getMode().
		// Not really sure what getMode does. Should I change the
		// bundling call to use getMode as well?
		var currentEnrollment = controller.get('currentEnrollment');

		// Bundling
		var selectableDentalPlans = this.getSelectableDentalPlans(controller, currentEnrollment, currentDentalEnrollment);

		if (Object.keys(selectableDentalPlans).length === 0) {
			controller.set('mustDeclineCoverage', true);
		}

		plans.forEach(function(dPlan) {
			// If a plan was originally unavailable (for non-bundling reasons),
			// do not adjust it's properties
			if (!dPlan.isAvailable) {
				return;
			}
			else if (selectableDentalPlans[dPlan.get('id')]) {
				dPlan.set('isDisabled', false);
			}
			else {
				dPlan.set('isDisabled', true);
			}
		});

		var selectedDentalPlan = model.get('selectedDentalPlan');
		var oldPlan = null;
		if (selectedDentalPlan) {
			plans.forEach(function(item) {
				if (item.get('id') == selectedDentalPlan) {
					oldPlan = item;
				}
			});
		}

		var currentDentalPlan = currentDentalEnrollment.get('dentalPlan');
		if (currentDentalPlan) {
			plans.forEach(function(item) {
				if (!item.get('isCurrentlySelected')) {
					if (currentDentalPlan && item.get('id') == currentDentalPlan.get('id')) {
						item.set('isCurrentlySelected', true);
						controller.set('currentlySelectedPlan', item);
					}
					else {
						item.set('isCurrentlySelected', false);
					}
				}
			});
		}

		if (oldPlan && !controller.get('currentlySelectedPlan')) {
			oldPlan.set('isCurrentlySelected', true);
		}

		// Bundling
		// Unselect any plans that are disabled (due to bundling)
		plans.forEach(function(plan) {
			if (plan.isDisabled) {
				plan.set('isCurrentlySelected', false);
				controller.set('currentlySelectedPlan', null);
			}
		});

		controller.set('mode', this.getMode());
		controller.set('currLineOfCoverage', 'DENTAL');
		controller.set('routeName', this.get('routeName'));

		// Update dependent.isSelected for dental
		var dependents = controller.get('dependents');
		dependents.forEach(function (dependent) {
			dependent.set('isSelected', dependent.get('dentalCheckBoxStatus'));
		});

		// Always reload for up to date EPlans, but we should NOT wait for it.
		controller.reloadPlans();
	},
	// Function which can be overrided to give the mode of operation for EPlanResource. Depending on
	// the mode of operation, it will give different EPlan objects for the employee. One such mode is
	// sc_oe_current which gives the current plan for the employee in short circuit open enrollment (this
	// would include the OE's old plans and non-OE's new plans.)
	getMode: function() {
		return null;
	},
	shouldRedirectToSCOEPlanSelector: function() {
		return true;
	}
});
App.EmployeeScoeolddentalplanselectorRoute = App.EmployeeDentalplanselectorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		// Reset the selected plans.
		controller.set('currentlySelectedPlan', null);
		var currentEnrollment = controller.get('currentDentalEnrollment');
		// This will take care of setting appropriate value for radio buttons which inturn controls
		// whether the plan selector is going to appear or not.
		if (currentEnrollment.get('oldPlan')) {
			if (currentEnrollment.get('oldPlan') == -1) {
				controller.set('declineSCOEOldPlans', true);
			}
			else {
				controller.set('declineSCOEOldPlans', false);
			}
		}

		// Set the old plan to selected plan if it exists.
		var selectedPlan = (currentEnrollment.get('oldPlan') && currentEnrollment.get('oldPlan') != -1) ? currentEnrollment.get('oldPlan') : null;
		if (selectedPlan) {
			controller.get('plans').forEach(function(item) {
				if (item.get('id') == selectedPlan) {
					item.set('isCurrentlySelected', true);
					controller.set('currentlySelectedPlan', item);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			});
		}
	},
	getMode: function() {
		return "sc_oe_current";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	},
});
App.EmployeeScoenewdentalplanselectorRoute = App.EmployeeDentalplanselectorRoute.extend({
	getMode: function() {
		return "sc_oe_new";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	}
});
App.EmployeeDentalopenenrollmentRoute = App.EmployeeDentalplanselectorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('priceLevel', 'youPremium');
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	},
});
App.EmployeeVisionplanselectorRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, _BundlingMixin, {
	// redirect to confirm basic info if EE doesn't have DoB and Zip.
	beforeModel: function(transition) {
		return dashboard('employee').then(function(employee) {
			if (!employee.get('zip') || !employee.get('dob') || !employee.get('sex')) {
				this.transitionTo('employee.dependents', {
					queryParams: {
						fromRoute: 'employee.visionplanselector',
						forceValidation: true}});
				transition.abort();
				return employee;
			}
		}.bind(this));
	},
	model: function() {
		var medicalPlans = EPlanManager.getEPlans('medical');
		var dentalPlans = EPlanManager.getEPlans('dental');
		var visionPlans = EPlanManager.getEPlans('vision');
		var oldVisionPlan = dashboard('employee').then(function(employee) {
			return EPlanManager.getEPlanById('vision', employee.get('selectedVisionPlan'));
		});


		return this.all(dashboard('employee'), {
			'plans': EPlanManager.getEPlans('vision', this.getMode()),
			'settings': dashboard('employee.employeeSettings'),
			'dependents': dashboard('employee.dependents'),
			'employee': dashboard('employee'),
			'employeeCobraEnrolleeProperties': dashboard('employee.employeeCobraEnrolleeProperties'),
			'company': dashboard('company'),
			'companySettings': dashboard('company.settings'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'currLineOfCoverage': 'VISION',
			'oldVisionPlan': oldVisionPlan,
			'bundles': this.loadBundles(medicalPlans, dentalPlans, visionPlans), // from _BundlingMixin
		});
	},
	afterModel: function(model) {
		var promiseArray = [];

		// If there is a vision short circuit OE, then redirect to that route.
		if (this.shouldRedirectToSCOEPlanSelector() &&
			this.otherModels.shortCircuitOEStats.get('visionSCOEStepsComplete'))
		{
			this.transitionTo('employee.scoeoldvisionplanselector');
			return;
		}

		var dependents = this.otherModels.dependents;
		promiseArray.push(Ember.RSVP.all(dependents.mapBy('dependentHealthEnrollments')));

		return Ember.RSVP.all(promiseArray);
	},
	redirect: function(model, transition) {
		this._super(model, transition);
		var employeeEnrollment = this.otherModels.currentVisionEnrollment;
		if (!(employeeEnrollment && employeeEnrollment.get('isEnrollmentOngoing'))) {
			this.transitionTo('employee.enrollmentsummary');
		}
	},
	setupController: function(controller, model) {
		try {
			this._super(controller, model);

			this._bundlingSetup(controller);

			// Reset controller properties
			controller.setProperties({
				isReloadingPlans: false,
				currentlySelectedPlan: null,
			});

			var plans = controller.get('plans');

			var currentVisionEnrollment = controller.get('currentVisionEnrollment');
			if (currentVisionEnrollment && !currentVisionEnrollment.get('status')) {
				currentVisionEnrollment.set('status', 'start');
			}

			var currentEnrollment = controller.get('currentEnrollment');
			var currentDentalEnrollment = controller.get('currentDentalEnrollment');

			// Bundling
			var selectableVisionPlans = this.getSelectableVisionPlans(controller, currentEnrollment, currentDentalEnrollment);
			if (Object.keys(selectableVisionPlans).length === 0) {
				controller.set('mustDeclineCoverage', true);
			}

			plans.forEach(function(vPlan) {
				// If a plan was originally unavailable (for non-bundling reasons),
				// do not adjust it's properties
				if (!vPlan.isAvailable) {
					return;
				}
				else if (selectableVisionPlans[vPlan.get('id')]) {
					vPlan.set('isDisabled', false);
				}
				else {
					vPlan.set('isDisabled', true);
				}
			});

			var selectedVisionPlan = model.get('selectedVisionPlan');
			var oldPlan = null;
			if (selectedVisionPlan) {
				plans.forEach(function (item) {
					if (item.get('id') == selectedVisionPlan) {
						oldPlan = item;
					}
				});
			}

			var currentVisionPlan = currentVisionEnrollment.get('visionPlan');
			if (currentVisionPlan) {
				plans.forEach(function (item) {
					if (!item.get('isCurrentlySelected')) {
						if (currentVisionPlan && item.get('id') == currentVisionPlan.get('id')) {
							item.set('isCurrentlySelected', true);
							controller.set('currentlySelectedPlan', item);
						}
						else {
							item.set('isCurrentlySelected', false);
						}
					}
				});
			}

			if (oldPlan && !controller.get('currentlySelectedPlan')) {
				oldPlan.set('isCurrentlySelected', true);
			}

			// Unselect any plans that are disabled (due to bundling)
			plans.forEach(function(plan) {
				if (plan.isDisabled) {
					plan.set('isCurrentlySelected', false);
					controller.set('currentlySelectedPlan', null);
				}
			});

			controller.set('mode', this.getMode());
			controller.set('currLineOfCoverage', 'VISION');
			controller.set('routeName', this.get('routeName'));

			// Update dependent.isSelected for vision
			var dependents = controller.get('dependents');
			dependents.forEach(function (dependent) {
				dependent.set('isSelected', dependent.get('visionCheckBoxStatus'));
			});

			// Always reload for up to date EPlans, but we should NOT wait for it.
			controller.reloadPlans();
		}
		catch(e) {
			console.log(e);
		}
	},
	getMode: function() {
		return null;
	},
	shouldRedirectToSCOEPlanSelector: function() {
		return true;
	},
});
App.EmployeeScoeoldvisionplanselectorRoute = App.EmployeeVisionplanselectorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		// Reset the selected plans.
		controller.set('currentlySelectedPlan', null);
		var currentEnrollment = controller.get('currentVisionEnrollment');
		// This will take care of setting appropriate value for radio buttons which inturn controls
		// whether the plan selector is going to appear or not.
		if (currentEnrollment.get('oldPlan')) {
			if (currentEnrollment.get('oldPlan') == -1) {
				controller.set('declineSCOEOldPlans', true);
			}
			else {
				controller.set('declineSCOEOldPlans', false);
			}
		}
		// Set the old plan to selected plan if it exists.
		var selectedPlan = (currentEnrollment.get('oldPlan') && currentEnrollment.get('oldPlan') != -1) ? currentEnrollment.get('oldPlan') : null;
		if (selectedPlan) {
			controller.get('plans').forEach(function(item) {
				if (item.get('id') == selectedPlan) {
					item.set('isCurrentlySelected', true);
					controller.set('currentlySelectedPlan', item);
				}
				else {
					item.set('isCurrentlySelected', false);
				}
			});
		}
	},
	getMode: function() {
		return "sc_oe_current";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	}
});
App.EmployeeScoenewvisionplanselectorRoute = App.EmployeeVisionplanselectorRoute.extend({
	getMode: function() {
		return "sc_oe_new";
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	}
});
App.EmployeeVisionopenenrollmentRoute = App.EmployeeVisionplanselectorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);

		controller.set('priceLevel', 'youPremium');
	},
	shouldRedirectToSCOEPlanSelector : function() {
		return false;
	},
});

