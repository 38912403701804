// models live in eager/help_center_models.js

App.HelpCenterController = Ember.ArrayController.extend({
	contentView: 'helpCenter',
	selectedTopicIndex: 0,
	sectionId: 1,

	// preload all data in init()
	sections: null,
	helpFaqs: [],
	helpFaqCategories: [],
	helpSetupSteps: [],

	//If these two initialScrollToItem* are specified, scroll directly to this item on load
	initalScrollToItemType: null, //"step" or "faq"
	initialScrollToItemId: null, //The item's id, i.e. "32"

	setCurrentSection: function() {
		if (!this.get("currentSection")) {
			this.set("currentSection", this.get("sections").findProperty("id", this.get("sectionId")));
		}
	}.observes("sections.@each"),

	title: Ember.computed.alias("currentSection.productTitle"),

	faqs: function() {
		var currentSection = this.get("currentSection");
		var faqIdList;

		if (!currentSection) {
			return [];
		}

		faqIdList = currentSection.get("faqIds").split(",");

		return this.get("helpFaqs").filter(function(faq) {
			if (faqIdList.contains(faq.get("id"))) {
				return faq;
			}
		});
	}.property("currentSection", "helpFaqs.@each.id"),

	setupSteps: function() {
		var currentSection = this.get("currentSection");
		var setupStepIdList;

		if (!currentSection) {
			return [];
		}

		setupStepIdList = currentSection.get("setupStepIds").split(",");

		return this.get("helpSetupSteps").filter(function(step) {
			if (setupStepIdList.contains(step.get("id"))) {
				return step;
			}
		});
	}.property("currentSection", "helpSetupSteps.@each.id"),

	setCategorizedFaqs: function() {
		var self = this;
		var categoryIds;
		var faqsData = [];
		var categoriesList;
		var faqsByCategory = {};

		//Only generate this structure once when everything is loaded for efficiency.
		if (!this.get("currentSection.categoryIds.length") || !this.get("helpFaqCategories.length") || !this.get("faqs.length")) {
			return;
		}

		categoryIds = this.get("currentSection.categoryIds").split(",");

		categoriesList = this.get("helpFaqCategories").filter(function(category) {
			return categoryIds.contains(category.get("id"));
		});

		this.get("faqs").forEach(function(faq) {
			var categoryId = faq.get("category");

			if (!faqsByCategory[categoryId]) {
				faqsByCategory[categoryId] = [];
			}

			faqsByCategory[categoryId].push(faq);
		});

		categoriesList.forEach(function(category) {
			faqsData.push({
				categoryId: category.get("id"),
				categoryName: category.get("name"),
				categoryFaqs: faqsByCategory[category.get("id")] || [],
				isExpanded: false
			});
		});

		this.set("categorizedFaqs", faqsData);
	}.observes("currentSection.categoryIds", "helpFaqCategories.@each.id", "faqs.@each"),

	init: function() {
		this._super.apply(this, arguments);

		// HACK: Passing empty object to `find` forces it down the `findQuery` execution
		// path. We want this instead of `findAll` to avoid a bunch of change-event spam.
		// `findQuery` only emits a change event once when all items have been added to the
		// collection, `findAll` emits for each item added. See the methods
		// `didUpdateAll`/`didUpdateQuery` on DS.Store -- static/js/libs/ember-data.js
		// lines 1913-1932.
		this.setProperties({
			"sections": App.HelpSection.find({}),
			"helpFaqs": App.HelpFaq.find({}),
			"helpFaqCategories": App.HelpFaqCategory.find({}),
			"helpSetupSteps": App.HelpSetupStep.find({})
		});
	},

	allItemIds: function() {
		return this.get("setupSteps").getEach("htmlId").concat(this.get("faqs").getEach("htmlId"));
	}.property("faqs", "setupSteps"),

	isPrevTopicDisabled: function() {
		return this.get("selectedTopicIndex") < 1;
	}.property('selectedTopicIndex'),

	isNextTopicDisabled: function() {
		return this.get("selectedTopicIndex") >= (this.get("allItemIds.length") - 1);
	}.property("selectedTopicIndex", "allItemIds.length"),

	resetProperties: function() {
		this.set("selectedTopicIndex", 0);
		this.get("categorizedFaqs").forEach(function(faq) {
			Ember.set(faq, "isExpanded", false);
		});
	}
});

App.HelpCenterView = Ember.View.extend({
	templateName: 'helpCenter',
	selectedTopicDomId: null,

	noScrollOnTopicChange: false,	//Flag to indicate whether body should scroll when nav topic changes
									//(since we do not want to re-scroll on a user-initiated scroll)

	navScrollData: {
		navItems: [],
		scrollItems: []
	},

	debouncedScrollToItem: App.Utils.debounce(function(itemId) {
		var $itemsList = this.$(".e-helpcenter-items");
		var $item = this.$("#" + itemId);

		if (!$item.length) {
			return;
		}

		this._unbindScroll();
		$itemsList.animate({
			scrollTop: $itemsList.scrollTop() + $item.parent().position().top
		}, null, null, this._bindToScroll.bind(this));
	}, 200),

	debouncedUpdateNavSelection: App.Utils.debounce(function(itemId, isFromUserScroll) {
		var navScrollData = this.get("navScrollData");
		var $navMenu = this.$(".e-helpcenter-nav");
		var $selectedNavItem;
		var controller;
		var selectedNavItemOffset;
		var parentPosition;

		navScrollData.navItems.removeClass("selected disabled");
		$selectedNavItem = navScrollData.navItems.filter(function() {
			return $(this).data("item-id") == itemId;
		});
		$selectedNavItem.addClass("selected disabled");

		selectedNavItemOffset = $selectedNavItem.offset();
		parentPosition = $selectedNavItem.parent().position();

		//If the nav option is scrolled off-screen, scroll $navMenu to make it visible.
		if ((selectedNavItemOffset && selectedNavItemOffset.top > $navMenu.parent().height()) ||
			(parentPosition && parentPosition.top < 0)){

			$navMenu.parent().animate({
				scrollTop: $navMenu.parent().scrollTop() + parentPosition.top
			});
		}

		//Update index of currently selected nav item (for Previous/Next navigation)
		controller = this.get("controller");

		//If a user-initiated scroll event occurred, set this flag so that we do not
		//initiate yet another scroll on the controller.selectedTopicIndex value change.
		if (isFromUserScroll) {
			this.set("noScrollOnTopicChange", true);
		}

		controller.set("selectedTopicIndex", controller.get("allItemIds").indexOf(itemId));
		this.set("noScrollOnTopicChange", false);
	}, 200),

	scrollBodyOnNavSelection: function() {
		var itemId;

		//Do not scroll if a user-initiated scroll caused the topic to change
		if (this.get("noScrollOnTopicChange")) {
			return;
		}

		itemId = this.get("controller.allItemIds")[this.get("controller.selectedTopicIndex")];

		this.debouncedScrollToItem(itemId);
	}.observes("controller.selectedTopicIndex"),

	didInsertElement: function() {
		this._bindToScroll();
		this._updateScrollPositionsCache();
		this._directScrollToItemOnLoad();
	},

	willDestroyElement: function() {
		this._unbindScroll();
		this.get("controller").resetProperties();
	},

	onDataLoad: function() {
		this._updateScrollPositionsCache();
		Ember.run.scheduleOnce('afterRender', this._directScrollToItemOnLoad.bind(this));
	}.observes("controller.setupSteps.length", "controller.faqs.length"),

	_cacheScrollPositions: function() {
		var $linksList = this.$(".e-helpcenter-nav");
		var self = this;
		var navItems;
		var scrollItems;
		var item;

		navItems = $linksList.find("a");

		scrollItems = navItems.map(function() {
			var $item = $("#" + $(this).data("item-id"));
			if ($item.length) {
				return $item;
			}
		});

		this.set("navScrollData", {
			navItems: navItems,
			scrollItems: scrollItems
		});

	}.observes("controller.setupSteps.length", "controller.faqs.length"),

	_bindToScroll: function() {
		var self = this;
		var $itemsList = this.$(".e-helpcenter-items");
		var $selectedNavItem;

		$itemsList.on("scroll", function() {
			var navScrollData = self.get("navScrollData");
			var fromTop = self.$(".e-helpcenter-items").offset().top;
			var scrollItems;
			var currentScrollItem;
			var itemId;

			// Get id of current scroll item
			scrollItems = navScrollData.scrollItems.map(function() {
				if ($(this).offset().top < fromTop + 60) {
					return this;
				}
			});

			// Get the id of the current element
			currentScrollItem = scrollItems[scrollItems.length - 1];
			itemId = currentScrollItem && currentScrollItem.length ? currentScrollItem[0].id : "";

			if (self.get("selectedTopicDomId") !== itemId) {
				self.setProperties({
					"selectedTopicDomId": itemId
				});

				self.debouncedUpdateNavSelection(itemId, true);
			}
		});
	},

	_unbindScroll: function() {
		this.$(".e-helpcenter-items").off("scroll");
	},

	_directScrollToItemOnLoad: function() {
		var initialScrollToItemType = this.get("controller.initialScrollToItemType");
		var initialScrollToItemId = this.get("controller.initialScrollToItemId");
		var navItems;

		if (initialScrollToItemType && initialScrollToItemId) {
			this.debouncedScrollToItem("helpcenter-" + initialScrollToItemType +
				"-" + initialScrollToItemId);
		}
		else {
			navItems = this.$(".e-helpcenter-nav").find("a");

			if (navItems && navItems.length) {
				navItems.removeClass("selected disabled"); //remove in case faqs got rendered first
				navItems.first().addClass("selected disabled");
			}
		}
	},

	nextTopic: function() {
		var controller = this.get("controller");

		if (!controller.get("isNextTopicDisabled")) {
			controller.incrementProperty("selectedTopicIndex");
		}
	},

	prevTopic: function() {
		var controller = this.get("controller");

		if (controller.get("selectedTopicIndex") > 0) {
			controller.decrementProperty('selectedTopicIndex');
		}
	},

	toggleExpand: function(categoryId) {
		var categorizedFaqs = this.get("controller.categorizedFaqs");
		var faqData = categorizedFaqs.find(function(data) {
			return Ember.get(data, "categoryId") == categoryId;
		});

		Ember.set(faqData, "isExpanded", !Ember.get(faqData, "isExpanded"));
	},

	//Cache offsets of each nav link after the view is done rendering.
	_updateScrollPositionsCache: function() {
		Ember.run.scheduleOnce('afterRender', this._cacheScrollPositions.bind(this));
	}.observes("controller.categorizedFaqs.@each.isExpanded")
});
