var clearUnsavedRecords = function(objs) {
	objs.forEach(function(waiver) {
		if (waiver.get('isNew') && !waiver.get('isSaving')) {
			waiver.deleteRecord();
		}
	});
};

var saveAndContinueIfValid = function(isValid) {
	if (!isValid) {
		this.set('errorText', 'All fields are required. Please answer all the questions.');
		return new Ember.RSVP.resolve();
	}

	this.set('errorText', '');
	return this.saveAndContinue().then(function(){
		return this.next();
	}.bind(this));
};

var simplerFilepicker = function(args) {
	filepicker.setKey(FILEPICKER_KEY);
	filepicker.pickAndStore(
		{
			extension: args['extensions'] || ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
			container: 'window',
			services:['COMPUTER', 'GMAIL', 'DROPBOX']
		},
		{location:'S3'},
		args['success'] || function(FPFile){},
		args['failure'] || function(FPError){}
	);
};

_AbstractNavigationMixin = Ember.Mixin.create({
	needs: ['employee/enrollmentv1/section'],
	enrollmentsectionController: Ember.computed.alias("controllers.employee/enrollmentv1/section"),
	refresh: 0,
	employee: null,
	sectionController: null,
	optionalAttributes: [
		'dependentType', 'dependentDob', 'dependentFirstName', 'dependentLastName',
		'dependentSocialSecurity', 'dependentGender', 'dependentSameAddress',
		'dependentAddress', 'dependentCity', 'dependentState', 'dependentZip',
		'dependentMarried', 'startDate', 'providedByEmployer', 'employementStatus',
		'contractType', 'marriageState', 'marriageCountry', 'socialSecurity',
		'height', 'weight', 'isDisabled', 'marriageDate', 'enrollInMedical', 'salary',
		'enrollInDental', 'enrollInVision', 'isSmoker', 'hmoDentalName', 'hmoDentalProviderNumber',
		'hmoDentalExistingPatient', 'havePreviousCoverage', 'sameAsEmployee',
		'carrier', 'isInsuranceActive', 'endDate', 'insuranceType', 'policyID', 'isMedicare',
		'medicareIDUrl', 'isPartA', 'isPartB', 'isPartC', 'proofUrl', 'isSixtyFive',
		'hasESRD', 'isDependentDisabled', 'esrdEffectiveDate', 'isReceivingSSDiablilityInsurance',
		'medicareClaimNumber', 'otherType', 'continueOtherCoverage', 'continueCarrier',
		'continueEffectiveDate', 'continueEndDate', 'otherCarrier', 'otherIDNumber',
		'medicalRecordNumber', 'priorCoverageCarrier', 'priorCoverageBeginDate',
		'priorCoverageEndDate', 'priorCoverageType', 'priorCoveragePolicyID',
		'hmoPhysicianProviderPAOrMGNumber', 'hmoPhysicianProviderPPGNumber',
		'spouseDPEmployed', 'spouseDPEmployer', 'spouseDPEmployerAddress', 'idCardUrl'
	],
	isAttrPresent: function() {
		var attrList = Ember.Object.create();
		this.get('sectionController.currentSubsection.attrList').forEach(function(attr) {
			attrList.set(attr, true);
		}.bind(this));
		return attrList;
	}.property('sectionController.currentSubsection.attrList.[]'),
	navTo: function(section, subsection) {
		var router = this.target.get("router");
		if (!subsection) {
			subsection = section.get('subsections').objectAt(0);
		}
		var nextPath = subsection.get('path');
		var employee = this.get('model');
		if (nextPath == 'medicaldetails') {
			var medicalPlan = employee.get('medicalPlan');
			if (!medicalPlan) {
				medicalPlan = this.get('company');
			}
			if (medicalPlan.get('isBlueCrossSelected')) {
				nextPath = 'bluecross';
			}
			else if (medicalPlan.get('isBlueShieldSelected')) {
				nextPath = 'blueshield';
			}
			else if (medicalPlan.get('isUnitedSelected')) {
				nextPath = 'united';
			}
			else if (medicalPlan.get('isHealthNetSelected')) {
				nextPath = 'healthnet';
			}
			else if (medicalPlan.get('isSeeChangeSelected')) {
				nextPath = 'seechange';
			}
		}
		var path = 'employee.enrollmentv1.' + section.get('path') + "." + nextPath;
		section.reload();
		router.transitionTo(path);
	},
	next: function(isComplete, skipRequired) {
		var model = this.get('content');
		var sectionController = this.get('sectionController');
		var subsection = sectionController.get('currentSubsection');

		var attrList = subsection.get('attrList');
		var optionals = this.get('optionalAttributes');
		isComplete = (isComplete != null) ? isComplete : true;
		skipRequired = (skipRequired != null) ? skipRequired : false;
		for (var i = 0; i < attrList.get('length'); i++) {
			var attr = attrList.objectAt(i);
			if (optionals.indexOf(attr) != -1) {
				// console.log('yay, optional, skipping');
				continue;
			}
			var value = model.get(attr);
			if (typeof(value) == 'object' && !skipRequired && !value) {
				isComplete = false;
				break;
			}
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (!skipRequired && (!value || value == '')) {
					isComplete = false;
					break;
				}
			}
			if (typeof(value) == 'boolean') {
				if (!value) {
					model.set(attrList.objectAt(i), false);
				}
			}
		}
		return this.saveAndContinue().then(function(){
			return sectionController.next(isComplete);
		}.bind(this));
	},
	prev: function() {
		var model = this.get('content');
		var sectionController = this.get('sectionController');
		this.saveAndContinue().then(function(){
			sectionController.prev();
		}.bind(this));
	}
});
App.EmployeeEnrollmentsummaryController = _TransactionSavingController.extend({
	needs: ['employee/enrollmentv1/section'],
	enrollmentsectionController: Ember.computed.alias("controllers.employee/enrollmentv1/section"),
	nextSubsection: function(subsection) {
		var nextPath = subsection.get('path');

		if (nextPath == 'medicaldetails') {
			var planOrCompany = this.get('medicalPlan');
			if (!planOrCompany) {
				var planOrCompany = this.get('company');
			}
			if (planOrCompany.get('isBlueCrossSelected')) {
				nextPath = 'bluecross';
			}
			else if (planOrCompany.get('isBlueShieldSelected')) {
				nextPath = 'blueshield';
			}
			else if (planOrCompany.get('isUnitedSelected')) {
				nextPath = 'united';
			}
			else if (planOrCompany.get('isHealthNetSelected')) {
				nextPath = 'healthnet';
			}
			else if (planOrCompany.get('isSeeChangeSelected')) {
				nextPath = 'seechange';
			}
		}

		return nextPath;
	},
	navTo: function(section, subsection) {
		if (!subsection) {
			subsection = section.get('subsections').objectAt(0);
		}
		var path = 'employee.enrollmentv1.' + section.get('path') + "." + this.nextSubsection(subsection);
		this.transitionToRoute(path);
	},
	actions: {
		navToFirstUncompleted: function() {
			var sections = this.get('sectionController').get('content');
			var path = "enrollmentv1";
			var employee = this.get('employee');
			var sectionCount = sections.get('length');
			var sectionsCompleted = 0;
			var section;
			var firstIncompleteSectionFound = false;
			for(var i = 0; i < sectionCount && !firstIncompleteSectionFound; i++) {
				section = sections.objectAt(i);
				var firstIncompleteSubsectionFound;
				if (section.get('isCompleted')) {
					sectionsCompleted++;
				}
				else{
					var subsections = section.get('subsections');
					var subsectionCount = subsections.get('length');
					var subsection;
					var subsectionsCompleted = 0;
					firstIncompleteSubsectionFound = false;
					for (var j = 0; j < subsectionCount && !firstIncompleteSubsectionFound; j++) {
						subsection = subsections.objectAt(j);
						if (subsection.get('isCompleted')) {
							subsectionsCompleted++;
						}
						else{
							path += '.' + section.get('path') + "." + this.nextSubsection(subsection);
							firstIncompleteSubsectionFound = true;
						}
					}
				}
				if (firstIncompleteSubsectionFound) {
					firstIncompleteSectionFound = true;
				}
				else{
					// Mark section as complete, When all subsections are complete but section is still marked as inComplete
					section.set('isCompleted', true);
				}
			}
			if (path.indexOf(".") == -1 && sectionsCompleted == 0) {
				path += ".index";
			}
			if (path.indexOf(".") == -1 && sectionsCompleted > 0) {
				path = 'enrollmentfinish';
			}

			var controller = this;
			['currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'].forEach(function(enrollmentPath) {
				var enrollment = controller.get(enrollmentPath);

				if (enrollment && enrollment.get('status') == 'start' && enrollment.get('relevantPlan')) {
					enrollment.set('status', 'begin');
				}
			});

			return this.saveAndContinue().then(function() {
				return this.transitionToRoute("employee." + path);
			}.bind(this));
		},
	},
});

App.EmployeeEnrollmentv1Controller = _TransactionSavingController.extend(_AbstractNavigationMixin, {
	actions: {
		save: function() {
			return this.next();
		},
		resetSubsection: function (sectionPath, subsectionPath) {
			var sections = this.get('sectionController').get('content');
			var section = sections.findBy('path', sectionPath);
			if (!section) {
				return Ember.RSVP.resolve();
			}

			var subsections = section.get('subsections');
			var subsection = subsections.findBy('path', subsectionPath);
			if (!subsection) {
				return Ember.RSVP.resolve();
			}

			if (subsection.get('isCompleted')) {
				subsection.set('isCompleted', false);
				return subsection.save().then(function () {
					return section.reload();
				});
			}
			return Ember.RSVP.resolve();
		},
	},
});

App.EmployeeEnrollmentv1PersonalinfoController = App.EmployeeEnrollmentv1Controller.extend(
		App.ValidationsControllerMixin, zen._EmployeeAddressChanged, {

	errorText: '',

	areFieldsFilledAndValid: function(requiredFields) {
		if(this.get('anyErrors')) {
			this.set('errorText', this.get('validationErrorText'));
			return false;
		}

		for (var field in requiredFields) {
			var fieldValue = this.get(field);
			if (fieldValue === '' || fieldValue === null) { //field is empty
				this.set('errorText', 'Please fill in your ' + requiredFields[field] + '.');
				return false;
			}
		}
		this.set('errorText', '');

		return true;
	},
	actions: {
		save: function(requiredFields) { //Pass in {firstName: "first name", dob: "date of birth"} etc. for each field you want to require.
			if (!this.areFieldsFilledAndValid(requiredFields)) {
				return;
			}
			var employee = this.get('model');
			if (this.didEmployeeAddressChange(employee) && !this.isEmployeeAddress2Empty(employee)) {
				employee.set('address2', '');
			}
			return this.saveAndContinue().then(function() {
				return this.get('employee.dependents');
			}.bind(this)).then(function(dependents) {
				var promiseArray = [];
				dependents.forEach(function(dependent) {
					promiseArray.push(dependent.reload());
				});
				return Ember.RSVP.all(promiseArray);
			}).then(function() {
				return this.next();
			}.bind(this));
		},
	},
});

App.EmployeeEnrollmentv1PersonalinfoIdentifyinginfoController = App.EmployeeEnrollmentv1PersonalinfoController.extend({
	toggle: function(){
		this.toggleProperty('isSSNProofVisible');
	},

	socialSecurityInputValue: Ember.computed.oneWay('socialSecurity'),
	employee: Ember.computed.oneWay('model'), // backwards compatibility in superclasses.

	errorText: "",
	actions: {
		save: function() {
			var employee = this.get('employee');
			var requiredFields = {first_name: 'first name', last_name: 'last name', sex: 'gender', dob: "date of birth"};
			var originalSocialSecurity = employee.get('socialSecurity');

			// If we do not have SSN on file and SSN Proof is visible, EE is waiting for SSN.
			if (!originalSocialSecurity && this.get('isSSNProofVisible')) {
				requiredFields['socialSecurityExpectedDate'] = "Social Security applied date";
				this.set('socialSecurityInputValue', null); // Can't have an SSN if we're waiting for one.
				employee.set('isWaitingForSSNInfo', true);
			} else {
				requiredFields['socialSecurity'] = "Social Security Number";
				employee.set('socialSecurityExpectedDate', null); // Can't have an SSN expected date if we already have an SSN.
			}

			if(this.get('socialSecurityInputValue')){
				employee.set('isWaitingForSSNInfo', false);
			}
			employee.set('socialSecurity', this.get('socialSecurityInputValue') || originalSocialSecurity);

			return this._super(requiredFields);
		},
	},
});

App.EmployeeEnrollmentv1PersonalinfoMaritalstatusController = App.EmployeeEnrollmentv1PersonalinfoController.extend({
	dependentDescription: function() {
		var spouseCount = this.get('dependents').filterBy('type', 'Spouse').get('length');
		var partnerCount = this.get('dependents').filterBy('type', 'Domestic Partner').get('length');
		var childCount = this.get('dependents').filterBy('type', 'Child').get('length');
		var dependentStrings = [];
		if (spouseCount > 0) {
			dependentStrings.push((spouseCount > 1 ? spouseCount: 'a') + ' Spouse' + (spouseCount > 1 ? 's': ''));
		}
		if (partnerCount > 0) {
			dependentStrings.push((partnerCount > 1 ? partnerCount: 'a') + ' Domestic Partner' + (partnerCount > 1 ? 's': ''));
		}
		if (childCount > 0) {
			dependentStrings.push(childCount + ' Child' + (childCount > 1 ? 'ren': ''));
		}
		if (dependentStrings.length === 1) {
			return 'You have ' + dependentStrings[0] + '.';
		} else if (dependentStrings.length === 2) {
			return 'You have ' + dependentStrings[0] + ' and ' + dependentStrings[1] + '.';
		} else if (dependentStrings.length === 3) {
			return 'You have ' + dependentStrings[0] + ', ' + dependentStrings[1] + ' and ' + dependentStrings[2] + '.';
		} else {
			return 'You have no dependent in the system.';
		}
	}.property('dependents'),
	getRequiredFields: function() {
		var requiredFields = {maritalStatus: "marital status"};

		if (this.get('isMarried')) {
			requiredFields['marriageDate'] = "date of " + this.get('marriageText');

			if (this.get('isAttrPresent.spouseDPEmployed')) {
				requiredFields['spouseDPEmployed'] = "spouse or domestic partner's employment status";
				if (this.get('isSpouseDPEmployed')) {
					if (this.get('isAttrPresent.spouseDPEmployer')) {
						requiredFields['spouseDPEmployer'] = "spouse or domestic partner's employer";
					}
					if (this.get('isAttrPresent.spouseDPEmployerAddress')) {
						requiredFields['spouseDPEmployerAddress'] = "spouse or domestic partner's employer's address";
					}
				}
			}
		} else {
			this.set('marriageDate', null); //In case we toggle open a marriage, change the date, and then toggle it closed.
		}

		return requiredFields;
	},

	actions: {
		save: function() {
			if (!this.areFieldsFilledAndValid(this.getRequiredFields())) {
				return new Ember.RSVP.resolve(); //areFieldsFilled handles the errorText
			}
			this.set('errorText', '');
			var controller = this;
			var promiseArray = [];
			var employee = this.get('employee');
			var hasPersonalInfoChanged = this.get('model.isDirty');

			return this.saveAndContinue().then(function() {
				var settings = employee.get('employeeSettings');
				if (settings) {
					settings.then(function() {
						promiseArray.push(settings.reload());
					});
				}
				return employee.reload();
			}).then(function() {
				employee.get('dependents').forEach(function(dependent) {
					if (dependent.get('isLoaded')) {
						promiseArray.push(dependent.reload());
					}
				});

				return Ember.RSVP.all(promiseArray);
			}).then(function() {
				if (hasPersonalInfoChanged) {
					return controller.send('resetSubsection', 'dependents', 'listdependents');
				}
				return Ember.RSVP.resolve();
			}).then(function() {
				return controller.next();
			});
		},
	},
});

App.EmployeeEnrollmentv1PersonalinfoEmploymentinfoController = App.EmployeeEnrollmentv1PersonalinfoController.extend({
	personalInfo: Ember.computed.oneWay('model'),
	fieldsRequired: function() {
		var fieldsRequired = ['employmentStatus', 'hireDate', 'jobTitle'];
		fieldsRequired = fieldsRequired.concat(this.get('sectionController.currentSubsection.attrList'));

		var employee = this.get('employee');
		var salaryIdx = fieldsRequired.indexOf('salary');
		if (employee.get('isHourly') && salaryIdx != -1) {
			fieldsRequired.splice(salaryIdx, 1);
		}

		return fieldsRequired.uniq();
	}.property('model', 'sectionController.currentSubsection.attrList.[]'),

	fieldsRequiredMap: function() {
		var fieldsRequired = this.get('fieldsRequired');
		var fieldsRequiredMap = {
			employmentStatus : 'employment status',
			hireDate : 'hire date',
			jobTitle : 'job title',
			salary : 'salary',
			hoursPerWeek : 'hours per week'
		};

		for (var field in fieldsRequiredMap) {
			if (!fieldsRequired.contains(field)) {
				delete fieldsRequiredMap[field];
			}
		}

		return fieldsRequiredMap;
	}.property('fieldsRequired'),

	isFieldRequired: function() {
		var fieldsRequired = this.get('fieldsRequired');
		var isFieldRequired = Ember.Object.create();

		fieldsRequired.forEach(function(field) {
			isFieldRequired.set(field, true);
		});

		return isFieldRequired;
	}.property('fieldsRequired'),

	actions: {
		save: function() {
			if (!this.areFieldsFilledAndValid(this.get('fieldsRequiredMap'))) {
				return new Ember.RSVP.resolve();
			}

			var promiseArray = [];
			this.get('employee.dependents').then(function(dependents) {
				dependents.forEach(function(dependent) {
					promiseArray.push(dependent.reload());
				});
			});

			return Ember.RSVP.all(promiseArray).then(function() {
				return this.next();
			}.bind(this));
		},
	},
});

App.EmployeeEnrollmentv1PersonalinfoContactinfoController = App.EmployeeEnrollmentv1PersonalinfoController.extend({
	actions: {
		save: function() {
			var requiredFields = {homeAddress: 'street address', homeState: 'state', homeCity: 'city', homeZip: 'zip code',	homePhone: 'home phone'};
			if (this.get('isAttrPresent.email')) {
				requiredFields['email'] = 'email address';
			}

			if (this.get('isAttrPresent.contactPreference')) {
				requiredFields['personalInfo.contactPreference'] = 'contact preference';
			}
			return this._super(requiredFields);
		}
	},
});

App.EmployeeEnrollmentv1DependentsController = App.EmployeeEnrollmentv1Controller.extend({
	dependents: [],
	newDependents: [],
	dependentMe: null,
	courtOrderHiddenFields: ['address', 'address2', 'city', 'state', 'zip', 'courtOrderEndDate', 'isCourtOrderedDependent'],

	_validateAttr: function(dependent, attr) {
		var value = dependent.get(attr);
		value = value ? $.trim(value) : value;
		return !!value;
	},
	_validateDependent: function(dependent) {
		var attrList = this.get('attrList');
		var courtOrderHiddenFields = this.get('courtOrderHiddenFields');

		return attrList.every(function(attr) {
			// We hide some fields on the server-side for court-ordered dependents, in which case,
			// it'd be okay and expected that the values are empty
			if (dependent.get('isCourtOrderActive') && courtOrderHiddenFields.contains(attr)) {
				return true;
			}
			return this._validateAttr(dependent, attr);
		}.bind(this));
	},
});

App.EmployeeEnrollmentv1DependentsListdependentsController = App.EmployeeEnrollmentv1DependentsController.extend(App.ValidationsControllerMixin, {
	errorText: "",
	attrList: ['firstName', 'lastName', 'type', 'dateOfBirth', 'zip'],
	actions: {
		save: function() {
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return;
			}
			// validate all the data
			var dependents = this.get('model');

			var numberDependentsEnrolling = dependents.get('length') - 1;
			var isValid = true;

			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				isValid = isValid && this._validateDependent(dependent);
			}

			return saveAndContinueIfValid.call(this, isValid);
		},
	},
});

window.cobraDependentsComputed = zen.cobraDependentsComputed = function(lineOfCoverage) {
	return Ember.computed(function() {
		var dependentsEnrolled = 'You';

		this.get('employee.dependents').forEach(function(dependent) {
			if (!dependent.get('isSelf') && dependent.get(lineOfCoverage + 'CheckBoxStatus')) {
				dependentsEnrolled += ' + ' + dependent.get('firstName');
			}
		});

		return dependentsEnrolled;
	}).property(
		'employee.dependents.@each.' + lineOfCoverage + 'CheckBoxStatus',
		'employee.dependents.@each.isSelf',
		'employee.dependents.@each.firstName'
	);
};

App.EmployeeEnrollmentv1DependentsEnrolldependentsController = App.EmployeeEnrollmentv1DependentsController.extend(_AbstractNavigationMixin, _EmployeeEnrollmentDependentsMixIn, _EmployeeDependentsEnrollmentMixin, {
	isSaving: false,
	cobraDependentsMedical: zen.cobraDependentsComputed('medical'),
	cobraDependentsDental: zen.cobraDependentsComputed('dental'),
	cobraDependentsVision: zen.cobraDependentsComputed('vision'),

	errorText: "",
	attrList: ['firstName', 'lastName', 'type', 'dateOfBirth', 'zip'],

	dependentsEnrolledInMedical: function() {
		if (!this.get('selfDependent.enrollInMedical')) {
			return 'None';
		}
		var dependents = this.get('dependents');
		var dependentsEnrolled = 'You';
		dependents.forEach(function(dependent) {
			if (!dependent.get('isSelf') && dependent.get('enrollInMedical')) {
				dependentsEnrolled += ' + ' + dependent.get('firstName');
			}
		});
		return dependentsEnrolled;
	}.property('selfDependent.enrollInMedical', 'dependents.@each.isSelf', 'dependents.@each.enrollInMedical', 'dependents.@each.firstName'),
	linesOfCoverageOffCycle: function () {
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var linesOffCycle = [];

		if (currentEnrollment && this.get('chosenMedicalPlan') && currentEnrollment.get('disableDependentCoverageChanges')) {
			linesOffCycle.push('medical');
		}
		if (currentDentalEnrollment && this.get('chosenDentalPlan') && currentDentalEnrollment.get('disableDependentCoverageChanges')) {
			linesOffCycle.push('dental');
		}
		if (currentVisionEnrollment && this.get('chosenVisionPlan') && currentVisionEnrollment.get('disableDependentCoverageChanges')) {
			linesOffCycle.push('vision');
		}
		return linesOffCycle;
	}.property('currentEnrollment.disableDependentCoverageChanges', 'currentDentalEnrollment.disableDependentCoverageChanges',
			'currentVisionEnrollment.disableDependentCoverageChanges'),
	linesOffCycleInfoText: function() {
		var linesOffCycle = this.get('linesOfCoverageOffCycle');
		if (!linesOffCycle.length) {
			return '';
		} else {
			var linesString = linesOffCycle.slice(0,-1).join(', ')+' and '+linesOffCycle[linesOffCycle.length-1];
			var linesText = linesOffCycle.length > 1 ? linesString : linesOffCycle[0];
			return 'Enrollment is not in progress for the chosen ' + linesText + ' plan';
		}
	}.property('linesOfCoverageOffCycle'),
	actions: {
		save: function() {
			if (this.get('isSaving')) {
				return new Ember.RSVP.resolve();
			}
			this.set('isSaving', true);

			var dependents = this.get('model');
			var nonSelfDependents = this.get('nonSelfDependents');
			var employee = this.get('employee');
			var ehes = [];

			var isValid = true;
			for (var i = 0; i < nonSelfDependents.get('length'); i++) {
				var dependent = nonSelfDependents.objectAt(i);
				if (!dependent.get('isSelf')) {
					var isMedicalChecked = $('.js-medical-enrollin').length && $('.js-medical-enrollin')[i].checked;
					var isDentalChecked = $('.js-dental-enrollin').length && $('.js-dental-enrollin')[i].checked;
					var isVisionChecked = $('.js-vision-enrollin').length && $('.js-vision-enrollin')[i].checked;
					if (isMedicalChecked || isDentalChecked || isVisionChecked) {
						isValid = isValid && this._validateDependent(dependent);
					}
				}
			}

			if (!isValid) {
				this.set('errorText', 'Dependents Info missing. Please fill all the Dependents required fields.');
				this.set('isSaving', false);
				return;
			}

			if (this.get('currentEnrollment') && this.get('currentEnrollment.allowEditReviewPlans') && employee.get('allowDependentMedicalEnrollment')) {
				ehes.push(this.get('currentEnrollment'));
			}

			if (this.get('currentDentalEnrollment') && this.get('currentDentalEnrollment.allowEditReviewPlans') && employee.get('allowDependentDentalEnrollment')) {
				ehes.push(this.get('currentDentalEnrollment'));
			}

			if (this.get('currentVisionEnrollment') && this.get('currentVisionEnrollment.allowEditReviewPlans') && employee.get('allowDependentVisionEnrollment')) {
				ehes.push(this.get('currentVisionEnrollment'));
			}

			this.createAndSaveDependentHealthEnrollments(ehes);

			var controller = this;

			return this.saveAndContinue().then(function() {
				var promiseArray = dependents.map(function(dependent) {
					return dependent.reload();
				});

				return Ember.RSVP.all(promiseArray);
			}).then(function() {
				return controller.send('resetSubsection', 'dependents', 'dependentsinfo');
			}).then(function() {
				controller.set('isSaving', false);
				return App.ESection.find();
			}).then(function(loadedSections) {
				controller.get('sectionController').set('content', loadedSections);
				return App.ESubsection.find();
			}).then(function(loadedSubsections) {
				controller.get('sectionController').set('subsections', loadedSubsections);
				return controller.next();
			}).catch(App.Utils.ApiValidationErrors.errotTextHandler(this, 'errorText'));
		},
	},
	selfDependent: function() {
		return this.get('model').findBy('isSelf');
	}.property('model.@each.isSelf'),
	dependents: Ember.computed.oneWay('model'),
	numberMedicalEnrollees: function() {
		return this.get('model').filter(function(item) {
			return item.get('medicalCheckBoxStatus');
		}).get('length');
	}.property('model.@each.medicalCheckBoxStatus'),
	numberDentalEnrollees: function() {
		return this.get('model').filter(function(item) {
			return item.get('dentalCheckBoxStatus');
		}).get('length');
	}.property('model.@each.dentalCheckBoxStatus'),
	numberVisionEnrollees: function() {
		return this.get('model').filter(function(item) {
			return item.get('visionCheckBoxStatus');
		}).get('length');
	}.property('model.@each.visionCheckBoxStatus'),
	medicalPlanCost: function() {
		var medicalPlan = this.get('medicalPlan');
		if (medicalPlan && medicalPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('dependents');
			var params = 'employee=' + this.get('employee.id') + '&decimalDigits=2&planId=' + medicalPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('medicalCheckBoxStatus') && !item.get('isSelf')) {
					params += item.get('id') + ",";
				}
			});
			var self = this;
			$.ajax({
				url: '/custom_api/getEmployeePremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('medicalYourCost', response.youCost);
					self.set('medicalYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	}.observes('employee.dependents.@each.medicalCheckBoxStatus'),
	dentalPlanCost: function() {
		var dentalPlan = this.get('dentalPlan');
		if (dentalPlan && dentalPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('model');
			var params = 'employee=' + this.get('employee.id') + '&decimalDigits=2&planId=' + dentalPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('dentalCheckBoxStatus') && !item.get('isSelf')) {
					params += item.get('id') + ",";
				}
			});
			var self = this;
			$.ajax({
				url: '/custom_api/getEmployeeDentalPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('dentalYourCost', response.youCost);
					self.set('dentalYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	}.observes('employee.dependents.@each.dentalCheckBoxStatus'),
	visionPlanCost: function() {
		var visionPlan = this.get('visionPlan');
		if (visionPlan && visionPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('model');
			var params = 'employee=' + this.get('employee.id') + '&decimalDigits=2&planId=' + visionPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('visionCheckBoxStatus') && !item.get('isSelf')) {
					params += item.get('id') + ",";
				}
			});
			var self = this;
			$.ajax({
				url: '/custom_api/getEmployeeVisionPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('visionYourCost', response.youCost);
					self.set('visionYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	}.observes('employee.dependents.@each.visionCheckBoxStatus'),
	adminMailToLink: function() {
		return "mailTo:" + this.get('company.adminEmail');
	}.property('company.adminEmail'),

	medicalChkboxClass: function() {
		if (this.get('showDependentEnrolledInMedical') && this.get('medicalPlan'))
		{
			return "person-column wide-half-is-enrolled";
		}
		return "person-column wide-is-enrolled";
	}.property('showDependentEnrolledInMedical', 'medicalPlan'),
	dentalChkboxClass: function() {
		if (this.get('showDependentEnrolledInDental') && this.get('dentalPlan'))
		{
			return "person-column wide-half-is-enrolled";
		}
		return "person-column wide-is-enrolled";
	}.property('showDependentEnrolledInDental', 'dentalPlan'),
	visionChkboxClass: function() {
		if (this.get('showDependentEnrolledInVision') && this.get('visionPlan'))
		{
			return "person-column wide-half-is-enrolled";
		}
		return "person-column wide-is-enrolled";
	}.property('showDependentEnrolledInVision', 'visionPlan'),
	hideEmployerContribution: Ember.computed.or('setting.spanningContribution', 'companySettings.hideCompanyContributions')
});

App.EmployeeEnrollmentv1DependentsDependentsinfoController = App.EmployeeEnrollmentv1DependentsController.extend(App.ValidationsControllerMixin, {
	errorText: "",
	attrList: ['socialSecurity', 'gender', 'address', 'city', 'state', 'zip'],
	courtOrderHiddenFields: ['address', 'address2', 'city', 'state', 'zip', 'courtOrderEndDate', 'isCourtOrderedDependent'],


	_validateDependent: function(dependent) {
		var attrList = this.get('attrList');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		return attrList.every(function (attr) {
			if (currentDentalEnrollment || currentVisionEnrollment) {
				if (attr == 'isFullTimeStudent' || attr == 'isSmoker' || attr == 'isDependentDisabled') {
					return true;
				}
			}
			var value = dependent.get(attr);

			// skip marriage date check for any non-spouse dependent
			if (attr == 'marriageDate' && dependent.get('type') != 'Spouse') {
				return true;
			}

			// skip SSN check for self dependent if EE is waiting for SSN
			if (attr == 'socialSecurity' && dependent.get('type') == 'self' && this.get('employee.isWaitingForSSNInfo')) {
				return true;
			}

			if (dependent.get('isCourtOrderActive') && this.get('courtOrderHiddenFields').contains(attr)) {
				return true;
			}

			// check SSN
			if (attr == 'socialSecurity' && this.get('anyErrors')) {
				return false;
			}

			// check for null
			if (typeof(value) == 'object' && !value) {
				return false;
			}
			// check string
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (!value) {
					return false;
				}
			}
			// set boolean as false explicitly
			if (typeof(value) == 'boolean') {
				if (!value) {
					dependent.set(attr, false);
				}
			}

			return true;
		}, this);
	},
	actions: {
		save: function() {
			// Validate all the data
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return;
			}
			var dependents = this.get('model');
			var isValid = dependents.every(function (dependent) {
				if (!dependent.get('hasDependentEnrollmentBegun')) {
					return true;
				}
				if (!dependent.get('id')) {
					return true;
				}

				return this._validateDependent(dependent);
			}, this);

			return saveAndContinueIfValid.call(this, isValid);
		},
	},

});

App.EmployeeEnrollmentv1DependentsDependentsphysicianController = App.EmployeeEnrollmentv1DependentsController.extend({
	errorText: "",
	employee: null,
	autoSelectedAllPCP: function(key, value){
		var dependents = this.get('model');
		var ret = true;
		//Setter
		if(arguments.length > 1){
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				dependent.set('autoSelectPCP', value);
				ret = ret && value;
			}
		} else {
			//Getter
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				ret = ret && dependent.get('autoSelectPCP');
			}
		}
		return ret;
	}.property('model.@each.autoSelectPCP'),

	_validateDependent: function(dependent) {
		var autoSelectPCP = dependent.get('autoSelectPCP');
		if(!autoSelectPCP){
			var attrList = this.get('sectionController').get('currentSubsection').get('attrList');
			for (var i = 0; i < attrList.get('length'); i++) {
				var attr = attrList.objectAt(i);
				if (attr == 'hmoPhysicianProviderPPGNumber' || attr == 'hmoPhysicianProviderPAOrMGNumber') {
					continue;
				}
				var value = dependent.get(attr);
				// Check for null
				if (typeof(value) == 'object' && !value) {
					return false;
				}
				if (typeof(value) == 'string') {
					value = value ? $.trim(value) : value;
					if (!value || value == '') {
						return false;
					}
				}
				if (typeof(value) == 'boolean') {
					if (!value) {
						dependent.set(attrList.objectAt(i), false);
					}
				}
			}
		}
		return true;
	},
	actions: {
		save: function() {
			// Validate all the data
			var dependents = this.get('model');
			var isValid = true;

			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				if (!dependent.get('medicalCheckBoxStatus')) {
					continue;
				}
				isValid = isValid && this._validateDependent(dependent);
			}

			return saveAndContinueIfValid.call(this, isValid);
		},
	},
});

App.EmployeeEnrollmentv1DependentsDependentsdentistController = App.EmployeeEnrollmentv1DependentsController.extend({
	errorText: "",
	employee: null,
	autoSelectedAllPCP: function(key, value){
		var dependents = this.get('model');
		var ret = true;
		//Setter
		if(arguments.length > 1){
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				dependent.set('autoSelectDentalPCP', value);
				ret = ret && value;
			}
		} else {
			//Getter
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				ret = ret && dependent.get('autoSelectDentalPCP');
			}
		}
		return ret;
	}.property('model.@each.autoSelectDentalPCP'),

	_validateDependent: function(dependent) {
		var autoSelectDentalPCP = dependent.get('autoSelectDentalPCP');
		if(!autoSelectDentalPCP){
			var attrList = this.get('sectionController').get('currentSubsection').get('attrList');
			for (var i = 0; i < attrList.get('length'); i++) {
				var value = dependent.get(attrList.objectAt(i));
				// check for null
				if (typeof(value) == 'object' && !value) {
					return false;
				}
				if (typeof(value) == 'string') {
					value = value ? $.trim(value) : value;
					if (!value || value == '') {
						return false;
					}
				}
				if (typeof(value) == 'boolean') {
					if (!value) {
						dependent.set(attrList.objectAt(i), false);
					}
				}
			}
		}

		return true;
	},
	actions: {
		save: function() {
			var dependents = this.get('model');
			var isValid = true;
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				if (!dependent.get('dentalCheckBoxStatus')) {
					continue;
				}
				isValid = isValid && this._validateDependent(dependent);
			}

			return saveAndContinueIfValid.call(this, isValid);
		},
	},

});

App.EmployeeEnrollmentv1DependentsWaiverController = App.EmployeeEnrollmentv1DependentsController.extend(_WaiverMixin, {
	errorText: "",
	attrList: ['firstName', 'lastName', 'type', 'dateOfBirth', 'zip'],

	waivers: Ember.computed(function() {
		var controller = this;
		var waivers = [];
		var selfDependent = this.get('dependents').findBy('type', 'self');
		var nonSelfDependents = this.get('dependents').filter(function(dependent) {
			return dependent.get('type') != 'self';
		});

		var existingWaivers = this.get('existingWaivers');

		function findOrCreateWaiver(enrollment, dependent) {
			var existingWaiver = existingWaivers.find(function(waiver) {
				return waiver.get('dependent.id') == dependent.get('id') &&
						waiver.get('enrollment.id') == enrollment.get('id');
			});

			return existingWaiver ? existingWaiver : App.EnrollmentWaiver.createRecord({
				enrollment: enrollment,
				dependent: dependent,
			});
		}

		controller.get('enrollments').forEach(function(ehe) {
			var lineOfCoverage = ehe.get('coverage_type');

			if (ehe.get('status') == 'decline' || (ehe.get('status') == 'reviewed' && !ehe.get('oldPlan'))) {
				var waiver = findOrCreateWaiver(ehe, selfDependent);
				waivers.push(waiver);
			} else {
				nonSelfDependents.forEach(function(dependent) {
					if (dependent.get(lineOfCoverage + 'CheckBoxStatus') || ehe.get('isCardBlocked')) {
						return;
					}

					var waiver = findOrCreateWaiver(ehe, dependent);
					waivers.push(waiver);
				});
			}
		});

		return waivers;
	}).property('enrollments.[]'),

	signature: Ember.computed.oneWay('existingWaivers.firstObject.signature'),

	_decliningMedical: Ember.computed.findByProperty('waivers', 'lineOfCoverage', 'medical'),
	decliningMedical: Ember.computed.bool('_decliningMedical'),

	_decliningDental: Ember.computed.findByProperty('waivers', 'lineOfCoverage', 'dental'),
	decliningDental: Ember.computed.bool('_decliningDental'),

	_decliningVision: Ember.computed.findByProperty('waivers', 'lineOfCoverage', 'vision'),
	decliningVision: Ember.computed.bool('_decliningVision'),

	errors: null,
	actions: {
		save: function() {
			var self = this;
			var errors = [];
			var waivers = this.get('waivers');
			var noWaiveReasonCoverageArray = [];

			waivers.forEach(function(waiver) {
				errors = errors.concat(waiver.validate());

				if(!waiver.get('waiveReason')) {
					noWaiveReasonCoverageArray.push(waiver.get('capitalizedLineOfCoverage'));
				}
			});

			var isValid = true;
			var dependents = this.get('dependents');
			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				if (!dependent.get('isCourtOrderActive')) {
					isValid = isValid && this._validateDependent(dependent);
				}
			}
			if (!isValid) {
				errors.push('Dependents Info missing. Please fill all the Dependents required fields.');
			}

			// If a user waives multiple lines of coverage w/o providing a reason,
			// aggregate the error messages displayed.
			var noWaiveReasonErrorText = humanizeList(noWaiveReasonCoverageArray);
			if (noWaiveReasonErrorText !== "") {
				errors.push("Please select a reason for declining your company's " +
									noWaiveReasonErrorText + " coverage.");
			}

			var signature = this.get('signature');
			if (!signature) {
				errors.push('Your signature is required');
			}

			if (errors.get('length') != 0) {
				this.set('errors', errors);
				return;
			}

			waivers.forEach(function(waiver) {
				waiver.set('signature', signature);
			});


			var currentEnrollment = this.get('currentEnrollment');
			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			var currentVisionEnrollment = this.get('currentVisionEnrollment');

			// we shouldn't rely on the UI to udpate properties like this.. should be server-side hooks if anything
			if (currentEnrollment && currentEnrollment.get('status') == 'decline') {
				currentEnrollment.set('hasSignedWaiver', true);
			}
			if (currentDentalEnrollment && currentDentalEnrollment.get('status') == 'decline') {
				currentDentalEnrollment.set('hasSignedWaiver', true);
			}
			if (currentVisionEnrollment && currentVisionEnrollment.get('status') == 'decline') {
				currentVisionEnrollment.set('hasSignedWaiver', true);
			}

			return this.saveAndContinue().then(function() {
				return self.next();
			});
		},
	},

});

App.EmployeeEnrollmentv1DependentsWaiverView = Ember.View.extend({
	willDestroyElement: function() {
		clearUnsavedRecords(this.get('controller.waivers'));
	},
});

App.EmployeeEnrollmentv1PreviouscoverageController = App.EmployeeEnrollmentv1Controller.extend({
	dependentMe: Ember.computed.findByProperty('employee.dependents', 'type', 'self'),
	saveButtonText: function() {
		if (this.get('App.EmployeeEnrollmentv1SectionController.isCurrentSectionLast') && this.get('App.EmployeeEnrollmentv1SectionController.isCurrentSubsectionLast')) {
			return 'Finish Enrollment';
		}
		return 'Save and Continue';
	}.property('App.EmployeeEnrollmentv1SectionController.isCurrentSectionLast', 'App.EmployeeEnrollmentv1SectionController.isCurrentSubsectionLast'),

});

App.EmployeeEnrollmentv1PreviouscoverageRecentcoverageController = App.EmployeeEnrollmentv1PreviouscoverageController.extend(App.ValidationsControllerMixin, {
	medicareDependentFields: [
		'isPartA', 'isPartB', 'isPartD',
		'medicareClaimNumber', 'isDependentDisabled', 'isReceivingSSDiablilityInsurance',
		'hasESRD', 'esrdEffectiveDate'
	],
	priorCoverageDependentFields: [
		'priorCoverageCarrier', 'priorCoverageBeginDate', 'priorCoverageEndDate',
		'priorCoverageType'
	],
	hasAttrSomeLineCovered: Ember.computed.or('isAttrPresent.isMedicalCovered', 'isAttrPresent.isDentalCovered', 'isAttrPresent.isVisionCovered'),

	havePreviousCoverageAtCarrierThatIsNotKaiserCAKaiserOR: function() {
		var medicalCarrierName = this.get('employee.currentEnrollment.medicalPlan.carrier');
		var dentalCarrierName = this.get('employee.currentDentalEnrollment.dentalPlan.carrier');
		var visionCarrierName = this.get('employee.currentVisionEnrollment.visionPlan.carrier');

		var isKaiserCAKaiserORTheOnlyCarrier = (
			(!medicalCarrierName || medicalCarrierName == 'Kaiser Permanente' || medicalCarrierName == 'Kaiser Permanente OR') &&
			(!dentalCarrierName || dentalCarrierName == 'Kaiser Permanente' || dentalCarrierName == 'Kaiser Permanente OR') &&
			(!visionCarrierName || visionCarrierName == 'Kaiser Permanente' || visionCarrierName == 'Kaiser Permanente OR')

		);
		return !isKaiserCAKaiserORTheOnlyCarrier;
	}.property(
		'employee.currentEnrollment.medicalPlan.carrier',
		'employee.currentDentalEnrollment.dentalPlan.carrier',
		'employee.currentVisionEnrollment.visionPlan.carrier'
	),

	_validateDependent: function(dependent, attrList) {
		if (!dependent.get('medicalCheckBoxStatus')) {
			return true;
		}
		var dependentPreviousCoverage = dependent.get('previousCoverage');
		if (dependentPreviousCoverage.get('havePreviousCoverage') == 'no') {
			return true;
		}
		if (dependentPreviousCoverage.get('type') != 'self' && dependentPreviousCoverage.get('sameAsEmployee')) {
			return true;
		}
		var medicareFields = this.get('medicareDependentFields');
		var isMedicare = dependentPreviousCoverage.get('isMedicare');
		for (var i = 0; i < attrList.get('length'); i++) {
			var attr = attrList.objectAt(i);
			var value = dependentPreviousCoverage.get(attr);
			// check for null
			if (attr == 'otherType') {
				if (!value && dependentPreviousCoverage.get('insuranceType') == 'Other') {
					return false;
				}

				continue;
			}
			if ((attr == 'endDate' || attr == 'reasonForEnding') &&
				 dependentPreviousCoverage.get('isInsuranceActive'))
			{
				continue;
			}
			if (medicareFields.indexOf(attr) != -1) {
				if (!isMedicare) {
					continue;
				}
			}
			if (attr == 'isReceivingSSDiablilityInsurance') {
				if (!this.get('isDependentDisabled')) {
					continue;
				}
			}
			if (attr == 'esrdEffectiveDate') {
				if (!this.get('hasESRD')) {
					continue;
				}
			}
			if (typeof(value) == 'object' && !value) {
				return false;
			}
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (!value || value == '') {
					return false;
				}
			}
			if (typeof(value) == 'boolean') {
				if (value == null || value == undefined) {
					dependentPreviousCoverage.set(attrList.objectAt(i), false);
				}
			}
		}
		return true;
	},
	actions: {
		save: function() {
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return;
			}

			// validate all the data
			var model = this.get('previousCoverageInfo');
			var isValid = true;
			var priorCoverageFields = this.get('priorCoverageDependentFields');
			var havePriorCoverage = (model.get('havePriorCoverage') == 'yes');
			var attrList = this.get('sectionController').get('currentSubsection').get('attrList');

			if (attrList.indexOf('havePriorCoverage') != -1) {
				if (havePriorCoverage) {
					for (var i = 0; i < priorCoverageFields.get('length'); i++) {
						if (!model.get(priorCoverageFields.objectAt(i))) {
							isValid = false;
							break;
						}
					}
				}
			}

			if (attrList.indexOf('havePriorDentalCoverage') != -1) {
				var havePriorDentalCoverage = (model.get('havePriorDentalCoverage') == 'yes');
				if (havePriorDentalCoverage) {
					if (!model.get('priorCoverageCarrier') || !model.get('priorCoveragePolicyID')) {
						isValid = false;
					}
				}
			}
			if (isValid && model.get('havePreviousCoverageAtCarrier') != 'no') {
				var dependents = this.get('model');
				for (var i = 0; i < dependents.get('length'); i++) {
					var dependent = dependents.objectAt(i);
					if (!dependent.get('medicalCheckBoxStatus')) {
						continue;
					}
					isValid = isValid && this._validateDependent(dependent, attrList);
				}
			}

			if (!isValid) {
				this.set('errorText', 'All fields are required. Please fill in the missing fields.');
				return;
			}

			this.set('errorText', '');
			return this.saveAndContinue().then(function() {
				return this.next();
			}.bind(this));
		},
	},
});

App.EmployeeEnrollmentv1PreviouscoverageOthercoverageController = App.EmployeeEnrollmentv1PreviouscoverageController.extend(App.ValidationsControllerMixin, {
	_validateDependent: function(dependentPreviousCoverage, attrList) {
		if (dependentPreviousCoverage.get('continueOtherCoverage') == 'no') {
			return true;
		}
		for (var i = 0; i < attrList.get('length'); i++) {
			var attr = attrList.objectAt(i);
			var value = dependentPreviousCoverage.get(attr);
			if (typeof(value) == 'object' && !value) {
				return false;
			}
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (!value || value == '') {
					return false;
				}
			}
			if (typeof(value) == 'boolean') {
				if (value == null || value == undefined) {
					dependentPreviousCoverage.set(attrList.objectAt(i), false);
				}
			}
		}

		return true;
	},
	actions: {
		save: function() {
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return;
			}
			// validate all the data
			var dependents = this.get('model');
			var attrList = this.get('sectionController').get('currentSubsection').get('attrList');
			var isValid = true;

			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				if (!dependent.get('medicalCheckBoxStatus')) {
					continue;
				}
				isValid = isValid && this._validateDependent(dependent.get('previousCoverage'), attrList);
			}

			return saveAndContinueIfValid.call(this, isValid);
		},
	},
});

App.EmployeeEnrollmentv1PreviouscoverageOtherdentalcoverageController = App.EmployeeEnrollmentv1PreviouscoverageController.extend({
	_validateDependent: function(dependent, attrList) {
		if (dependent.get('continueOtherDentalCoverage') == 'no') {
			return true;
		}
		for (var i = 0; i < attrList.get('length'); i++) {
			var attr = attrList.objectAt(i);
			var value = dependent.get(attr);
			if (typeof(value) == 'object' && !value) {
				return false;
			}
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (!value || value == '') {
					return false;
				}
			}
			if (typeof(value) == 'boolean') {
				if (value == null || value == undefined) {
					dependent.set(attrList.objectAt(i), false);
				}
			}
		}

		return true;
	},
	actions: {
		save: function() {
			// validate all the data
			var dependents = this.get('model');
			var attrList = this.get('sectionController').get('currentSubsection').get('attrList');
			var isValid = true;

			for (var i = 0; i < dependents.get('length'); i++) {
				var dependent = dependents.objectAt(i);
				if (!dependent.get('dentalCheckBoxStatus')) {
					continue;
				}
				isValid = isValid && this._validateDependent(dependent.get('previousCoverage'), attrList);
			}

			return saveAndContinueIfValid.call(this, isValid);
		},
	},
});

App.EmployeeEnrollmentv1OfficerstatementController = App.EmployeeEnrollmentv1Controller.extend();

App.EmployeeEnrollmentv1OfficerstatementOfficerstatementController = App.EmployeeEnrollmentv1Controller.extend({
	openFilePickerOfficerStatement: function() {
		var model = this.get('content');
		var successFn = function(FPFile) {
			model.set('docProofUrl', FPFile[0].url);
			model.set('docProofName', FPFile[0].filename);
		};

		simplerFilepicker({
			extensions: ['.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
			success: successFn,
		});
	}
});

App.EmployeeEnrollmentv1MedicaldetailsController = App.EmployeeEnrollmentv1Controller.extend({
	dependents: [],
	employee: null,
	followups: [],
	_validateAttr: function(model, attr) {
		var value = model.get(attr);
		if (typeof(value) == 'object' && value == null) {
			return false;
		}
		if (typeof(value) == 'string') {
			value = value ? $.trim(value) : value;
			if (!value || value == '') {
				return false;
			}
		}
		if (typeof(value) == 'boolean') {
			if (value == null || value == undefined) {
				model.set(attr, false);
			}
		}

		if (value == 'yes') {
			var followup = this.get(attr + "Details");
			if (followup) {
				// set dependent data
				var followupAttrList = this.get('followupAttrs');
				for (var i = 0; i < followupAttrList.get('length'); i++) {
					var attr = followupAttrList.objectAt(i);
					var value = followup.get(attr);
					value = value ? $.trim(value) : value;
					if (!value || value == '') {
						return false;
					}
				}
			}
		}

		return true;
	},
	saveButtonText: function() {
		if (this.get('App.EmployeeEnrollmentv1SectionController.isCurrentSectionLast') && this.get('App.EmployeeEnrollmentv1SectionController.isCurrentSubsectionLast')) {
			return 'Finish Enrollment';
		}
		return 'Save and Continue';
	}.property('App.EmployeeEnrollmentv1SectionController.isCurrentSectionLast', 'App.EmployeeEnrollmentv1SectionController.isCurrentSubsectionLast'),
});

App.EmployeeEnrollmentfinishController = _TransactionSavingController.extend({
	employee: null,
	errorText: '',
	signatureObj: null,
	actions: {
		save: function() {
			var signature = this.get('signature');
			var signatureObj = this.get('signatureObj');
			if (!signatureObj || !signatureObj.get('signature') || !signatureObj.get('signatureName') || !signatureObj.get('employee') ) {
				this.set('errorText', 'All fields are required. Please fill out the missing fields.');
				return;
			}

			signature.set('signature', signatureObj.get('signature'));
			signature.set('name', signatureObj.get('signatureName'));
			signature.set('date', signatureObj.get('signatureDate'));

			this.set('errorText', '');

			var carrier = '';
			var employee = this.get('model');
			var company = this.get('company');
			if (this.get('chosenMedicalPlan')) {
				carrier = this.get('chosenMedicalPlan.stateCarrier.displayName');
			}

			var today = new Date();
			var formattedDate = formatDate(today);

			var company = this.get('model');
			var currentEnrollment = this.get('currentEnrollment');
			var currentDentalEnrollment = this.get('currentDentalEnrollment');
			var currentVisionEnrollment = this.get('currentVisionEnrollment');
			var dependentCoverageChanged = employee.get('dependentCoverageChanged');

			var medical = false, dental = false, vision = false;
			var enrollments = Ember.A();
			var dependentEnrollments = [];
			var statusList = ['complete', 'decline', 'reviewed'];

			var a = $(".agreements");
			var agreementContent = a[0].innerHTML;

			if (currentEnrollment) {
				if (currentEnrollment.get('status') && statusList.indexOf(currentEnrollment.get('status')) == -1 && this.get('chosenMedicalPlan')) {
					currentEnrollment.set('status', 'complete');
				}

				enrollments.pushObject(currentEnrollment);
				dependentEnrollments.push(currentEnrollment);
				$.ajax({
					type: "POST",
					url: "/custom_api/createAgreementEHE",
					data: JSON.stringify({
						eheId: this.get('currentEnrollment.id'),
						agreementContent: agreementContent,
						authName: signature.get('name'),
						authDate: formattedDate,
						authSignature: signature.get('signature'),
						documentType: 'Enrollment Agreement',
						enrollmentType: 'medical'
					}),
					contentType: 'application/json',
					success: function(result) {}
				});

				medical = true;
			}

			if (currentDentalEnrollment) {
				if (currentDentalEnrollment.get('status') && statusList.indexOf(currentDentalEnrollment.get('status')) == -1 && this.get('chosenDentalPlan')) {
					currentDentalEnrollment.set('status', 'complete');
				}

				enrollments.pushObject(currentDentalEnrollment);
				dependentEnrollments.push(currentDentalEnrollment);
				$.ajax({
					type: "POST",
					url: "/custom_api/createAgreementEHE",
					data: JSON.stringify({
						eheId: this.get('currentDentalEnrollment.id'),
						agreementContent: agreementContent,
						authName: signature.get('name'),
						authDate: formattedDate,
						authSignature: signature.get('signature'),
						documentType: 'Enrollment Agreement',
						enrollmentType: 'dental'
					}),
					contentType: 'application/json',
					success: function(result) {}
				});

				dental = true;
			}

			if (currentVisionEnrollment) {
				if (currentVisionEnrollment.get('status') && statusList.indexOf(currentVisionEnrollment.get('status')) == -1 && this.get('chosenVisionPlan')) {
					currentVisionEnrollment.set('status', 'complete');
				}

				enrollments.pushObject(currentVisionEnrollment);
				dependentEnrollments.push(currentVisionEnrollment);
				$.ajax({
					type: "POST",
					url: "/custom_api/createAgreementEHE",
					data: JSON.stringify({
						eheId: this.get('currentVisionEnrollment.id'),
						agreementContent: agreementContent,
						authName: signature.get('name'),
						authDate: formattedDate,
						authSignature: signature.get('signature'),
						documentType: 'Enrollment Agreement',
						enrollmentType: 'vision'
					}),
					contentType: 'application/json',
					success: function(result) {}
				});

				vision = true;
			}
			var dependents = this.get('dependents');
			dependents.forEach(function(dependent) {
				if(!dependent.get('isSelf')) {
					dependentEnrollments.forEach(function(ehe) {
						var dhes = dependent.get('dependent' + ehe.get('coverage_type').capitalize() + 'Enrollments');
						if(dhes && dhes.get('length')) {
							dhes.forEach(function(dhe) {
								if (dhe.get('employeeHealthEnrollment.id') == ehe.get('id')) {
									dhe.set('isEnrollmentComplete', true);
									dhe.set('authName', signature.get('name'));
									dhe.set('authDate', formattedDate);
									dhe.set('authSignature', signature.get('signature'));
									if (dhe.get('enrollmentStatus') != 'decline') {
										dhe.set('enrollmentStatus', 'complete');
									}
									ehe.set('dependentCoverageChanged', true);
									dependentCoverageChanged = true;
								}
							});
						}
					});
				}
			});

			return signatureObj.save().then(function(authSignatureObj) {
				enrollments.forEach(function(enrollment) {
					enrollment.set('authSignature', authSignatureObj);
				});
				if (currentEnrollment && statusList.indexOf(currentEnrollment.get('status')) > -1) {
					return this.saveAndContinue().then(function() {
						$.ajax({
							url: '/custom_api/employeeNotification?action=enrollmentComplete&medical=' + medical + '&dental='+ dental + '&vision=' + vision,
							type: "get",
							async: false
						});


						employee.reload();
						dependents = employee.get('dependents');
						dependents.forEach(function(dependent) {
							dependent.reload();
						});

						var modalRoute = 'employee.successfinish', takeThemToHSA = false;
						if (this.get('chosenMedicalPlan.HSA') &&
							this.get('companyHsa.status') == 'complete' &&
							(this.get('employeeHsa.status') == null || this.get('employeeHsa.isCanceled')))
						{
							modalRoute = 'employee.successfinishwithhsa';
							takeThemToHSA = true;
						}

						var qualifyingEvent = currentEnrollment.get('qualifyingEvent');
						if (qualifyingEvent && qualifyingEvent.get('type') != 'move_to_ft') {
							modalRoute = 'employee.qleenrollmentfinishsuccess';
							if (currentEnrollment.get('needDocument')) {
								modalRoute = 'employee.qleenrollmentfinishsuccesswithoutdocument';
							}
						}

						if (currentEnrollment.get('isOpenEnrollment')) {
							var o = Ember.Object.create({
								'effectiveDate': currentEnrollment.get('effectiveDate'),
								'takeThemToHSA': takeThemToHSA,
							});
							this.send('showModal', 'employee.openenrollmentfinishsuccess', o);
						}
						else {
							var o = Ember.Object.create({
								'carrier': carrier,
								'lineOfCoverage': currentEnrollment.get('coverage_type'),
								'effectiveDate': currentEnrollment.get('effectiveDate'),
								'isShortCircuitPlan': this.get('chosenMedicalPlan.isShortCircuitPlan'),
								'isNewHireOEOrSW': currentEnrollment.get('isNewHireOEOrSW'),
								'companyName': company.get('name'),
								'companyEffectiveDate': currentEnrollment.get('companyEffectiveDate'),
								'qualifyingEvent': qualifyingEvent,
								'planSelectorLink': 'employee.openenrollment',
							});
							this.send('showModal', modalRoute, o);
						}
					}.bind(this));
				}
				else if (currentDentalEnrollment && statusList.indexOf(currentDentalEnrollment.get('status')) > -1) {
					return this.saveAndContinue().then(function() {
						$.ajax({
							url: '/custom_api/employeeNotification?action=enrollmentComplete&medical=' + medical + '&dental='+ dental + '&vision=' + vision,
							type: "get",
							async: false
						});

						employee.reload();

						var modalRoute = 'employee.successfinish';
						var qualifyingEvent = currentDentalEnrollment.get('qualifyingEvent');
						if (qualifyingEvent && qualifyingEvent.get('type') != 'move_to_ft') {
							modalRoute = 'employee.qleenrollmentfinishsuccess';
							if (currentDentalEnrollment.get('needDocument')) {
								modalRoute = 'employee.qleenrollmentfinishsuccesswithoutdocument';
							}
						}

						if (currentDentalEnrollment.get('isOpenEnrollment')) {
							var o = Ember.Object.create({
								'effectiveDate': currentDentalEnrollment.get('effectiveDate'),
							});
							this.send('showModal', 'employee.openenrollmentfinishsuccess', o);
						}
						else {
							carrier = this.get('chosenDentalPlan.stateCarrier.displayName');
							var o = Ember.Object.create({
								'carrier': carrier,
								'lineOfCoverage': currentDentalEnrollment.get('coverage_type'),
								'effectiveDate': currentDentalEnrollment.get('effectiveDate'),
								'isShortCircuitPlan': this.get('chosenDentalPlan.isShortCircuitPlan'),
								'isNewHireOEOrSW': currentDentalEnrollment.get('isNewHireOEOrSW'),
								'companyName': company.get('name'),
								'companyEffectiveDate': currentDentalEnrollment.get('companyEffectiveDate'),
								'qualifyingEvent': qualifyingEvent,
								'planSelectorLink': 'employee.dentalopenenrollment',
							});
							this.send('showModal', modalRoute, o);
						}
					}.bind(this));
				}
				else if (currentVisionEnrollment && statusList.indexOf(currentVisionEnrollment.get('status')) > -1) {
					return this.saveAndContinue().then(function() {
						$.ajax({
							url: '/custom_api/employeeNotification?action=enrollmentComplete&medical=' + medical + '&dental='+ dental + '&vision=' + vision,
							type: "get",
							async: false
						});

						employee.reload();

						var modalRoute = 'employee.successfinish';
						var qualifyingEvent = currentVisionEnrollment.get('qualifyingEvent');
						if (qualifyingEvent && qualifyingEvent.get('type') != 'move_to_ft') {
							modalRoute = 'employee.qleenrollmentfinishsuccess';
							if (currentVisionEnrollment.get('needDocument')) {
								modalRoute = 'employee.qleenrollmentfinishsuccesswithoutdocument';
							}
						}

						if (currentVisionEnrollment.get('isOpenEnrollment')) {
							var o = Ember.Object.create({
								'effectiveDate': currentVisionEnrollment.get('effectiveDate'),
							});
							this.send('showModal', 'employee.openenrollmentfinishsuccess', o);
						}
						else {
							carrier = this.get('chosenVisionPlan.stateCarrier.displayName');
							var o = Ember.Object.create({
								'carrier': carrier,
								'lineOfCoverage': currentVisionEnrollment.get('coverage_type'),
								'effectiveDate': currentVisionEnrollment.get('effectiveDate'),
								'isShortCircuitPlan': this.get('chosenVisionPlan.isShortCircuitPlan'),
								'isNewHireOEOrSW': currentVisionEnrollment.get('isNewHireOEOrSW'),
								'companyName': company.get('name'),
								'companyEffectiveDate': currentVisionEnrollment.get('companyEffectiveDate'),
								'qualifyingEvent': qualifyingEvent,
								'planSelectorLink': 'employee.visionopenenrollment',
							});
							this.send('showModal', modalRoute, o);
						}
					}.bind(this));
				}
			}.bind(this));
		},
	},
	medicalPlanCost: function() {
		var medicalPlan = this.get('ePlan');
		if (medicalPlan && medicalPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('dependents');
			var params = 'employee=' + this.get('model.id') + '&decimalDigits=2&planId=' + medicalPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('medicalCheckBoxStatus') && !item.get('isSelf')) {
					params += item.get('id') + ",";
				}
			});
			var self = this;
			$.ajax({
				url: '/custom_api/getEmployeePremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('medicalYourCost', response.youCost);
					self.set('medicalYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	},
	dentalPlanCost: function() {
		var dentalPlan = this.get('eDentalPlan');
		var self = this;
		if (dentalPlan && dentalPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('dependents');
			var params = 'employee=' + this.get('model.id') + '&decimalDigits=2&planId=' + dentalPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('dentalCheckBoxStatus')) {
					params += item.get('id') + ",";
				}
			});
			$.ajax({
				url: '/custom_api/getEmployeeDentalPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('dentalYourCost', response.youCost);
					self.set('dentalYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	},
	visionPlanCost: function() {
		var visionPlan = this.get('eVisionPlan');
		var self = this;
		if (visionPlan && visionPlan.get('shortCircuitPlanTypeWithRates')) {
			var dependents = this.get('dependents');
			var params = 'employee=' + this.get('model.id') + '&decimalDigits=2&planId=' + visionPlan.get('id') + '&dependents=';
			dependents.forEach(function(item) {
				if (item.get('visionCheckBoxStatus')) {
					params += item.get('id') + ",";
				}
			});
			$.ajax({
				url: '/custom_api/getEmployeeVisionPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('visionYourCost', response.youCost);
					self.set('visionYourContribution', response.youContribution);
				}
			});
		}

		return 0;
	},
	cobraOETotalCost: function() {
		if (!this.get('medicalYourCost') || !this.get('medicalYourContribution')) {
			this.medicalPlanCost();
		}
		if (!this.get('dentalYourCost') || !this.get('dentalYourContribution')) {
			this.dentalPlanCost();
		}
		if (!this.get('visionYourCost') || !this.get('visionYourContribution')) {
			this.visionPlanCost();
		}
		var totalCost = 0;
		var adminFee = parseFloat(this.get('employeeCobra.computedAdminFee'));
		['medical', 'dental', 'vision'].forEach(function(line) {
			var cost = this.get(line + 'YourCost') + this.get(line + 'YourContribution');
			if (!cost || isNaN(cost)) {
				return;
			}
			var costWithAdminFee = cost * adminFee;
			costWithAdminFee = Math.round(costWithAdminFee * 100) / 100;
			totalCost += costWithAdminFee;
		}.bind(this));
		return +totalCost.toFixed(2);
	}.property('employeeCobra.computedAdminFee', 'medicalYourCost','dentalYourCost','visionYourCost',
	           'medicalYourContribution', 'dentalYourContribution', 'visionYourContribution'),
	isDifferentDentalCarrier: function() {
		var medicalPlan = this.get('chosenMedicalPlan');
		var dentalPlan = this.get('chosenDentalPlan');
		if (medicalPlan && dentalPlan) {
			return medicalPlan.get('stateCarrier.name') != dentalPlan.get('stateCarrier.name');
		}
		if (!medicalPlan && dentalPlan) {
			return true;
		}

		return false;
	}.property('chosenMedicalPlan', 'chosenDentalPlan', 'chosenMedicalPlan.stateCarrier.name', 'chosenDentalPlan.stateCarrier.name'),
	isSameDentalVisionCarrier: function() {
		var visionPlan = this.get('chosenVisionPlan');
		var dentalPlan = this.get('chosenDentalPlan');
		if (visionPlan && dentalPlan) {
			return visionPlan.get('stateCarrier.name') == dentalPlan.get('stateCarrier.name');
		}

		return false;
	}.property('chosenDentalPlan', 'chosenVisionPlan', 'chosenDentalPlan.stateCarrier.name', 'chosenVisionPlan.stateCarrier.name'),
	isDifferentVisionCarrier: function() {
		var medicalPlan = this.get('chosenMedicalPlan');
		var visionPlan = this.get('chosenVisionPlan');
		if (medicalPlan && visionPlan) {
			return medicalPlan.get('stateCarrier.name') != visionPlan.get('stateCarrier.name');
		}
		if (!medicalPlan && visionPlan) {
			return true;
		}

		return false;
	}.property('chosenMedicalPlan', 'chosenVisionPlan', 'chosenMedicalPlan.stateCarrier.name', 'chosenVisionPlan.stateCarrier.name'),
	cobraCoverageStartMonth: function(){
		var cobraCoverageStartDate = this.get('employeeCobra.cobraCoverageStartDate');
	}.property('employeeCobra.cobraCoverageStartDate'),
	cobraDebitDateString: function(){
		//Debit Date is max of
		//1. effectiveDate
		//2. endDate + 1 day
		// If dates are different for different Lines of coverage, use the max Date
		var debitDate = new Date();
		if(this.get('currentEnrollment.effectiveDate')){
			var medicalEffectiveDate = new Date(this.get('currentEnrollment.effectiveDate'));
			var medicalEndDate = new Date(this.get('currentEnrollment.endDate'));
			var ddMedical = (medicalEffectiveDate > medicalEndDate) ? medicalEffectiveDate : medicalEndDate.setDate(medicalEndDate.getDate() + 1);
			debitDate = ddMedical;
		}
		if(this.get('currentDentalEnrollment.effectiveDate')){
			var dentalEffectiveDate = new Date(this.get('currentDentalEnrollment.effectiveDate'));
			var dentalEndDate = new Date(this.get('currentDentalEnrollment.endDate'));
			var ddDental;
			if(dentalEffectiveDate > dentalEndDate){
				ddDental = dentalEffectiveDate;
			}
			else{
				dentalEndDate.setDate(dentalEndDate.getDate() + 1);
				ddDental = dentalEndDate;
			}
			if( ddDental < debitDate){
				debitDate = ddDental;
			}
		}
		if(this.get('currentVisionEnrollment.effectiveDate')){
			var visionEffectiveDate = new Date(this.get('currentVisionEnrollment.effectiveDate'));
			var visionEndDate = new Date(this.get('currentVisionEnrollment.endDate'));
			var ddVision;
			if(visionEffectiveDate > visionEndDate){
				ddVision = visionEffectiveDate;
			}else{
				ddVision = visionEndDate.setDate(visionEndDate.getDate() + 1);
			}
			if( ddVision < debitDate){
				debitDate = ddVision;
			}
		}
		return debitDate;
	}.property('currentEnrollment.effectiveDate','currentDentalEnrollment.effectiveDate','currentVisionEnrollment.effectiveDate','currentEnrollment.endDate','currentDentalEnrollment.endDate','currentVisionEnrollment.endDate'),
	cobraAgreementLastEffectiveDate: function(){
		var agreementLastEffectiveDate = new Date();
		if(this.get('currentEnrollment.effectiveDate')){
			var medicalEffectiveDate = new Date(this.get('currentEnrollment.effectiveDate'));
			agreementLastEffectiveDate = medicalEffectiveDate;
		}
		if(this.get('currentDentalEnrollment.effectiveDate')){
			var dentalEffectiveDate = new Date(this.get('currentDentalEnrollment.effectiveDate'));
			if( dentalEffectiveDate < agreementLastEffectiveDate){
				agreementLastEffectiveDate = dentalEffectiveDate;
			}
		}
		if(this.get('currentVisionEnrollment.effectiveDate')){
			var visionEffectiveDate = new Date(this.get('currentVisionEnrollment.effectiveDate'));
			if( visionEffectiveDate < agreementLastEffectiveDate){
				agreementLastEffectiveDate = visionEffectiveDate;
			}
		}
		return agreementLastEffectiveDate;
	}.property('currentEnrollment.effectiveDate','currentDentalEnrollment.effectiveDate','currentVisionEnrollment.effectiveDate'),
	cobraEffectiveMonth: function(){
		var monthNames = [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];
		var cobraAgreementLastEffectiveDate = new Date(this.get('cobraAgreementLastEffectiveDate'));

		var cobraEffectiveMonthName = monthNames[cobraAgreementLastEffectiveDate.getMonth()];
		return cobraEffectiveMonthName;
	}.property('cobraAgreementLastEffectiveDate'),
	hasDeclinedAnyCoverage: function(){
		var medicalDeclinedDependents = this.get('medicalDeclinedDependents');
		var dentalDeclinedDependents = this.get('dentalDeclinedDependents');
		var visionDeclinedDependents = this.get('visionDeclinedDependents');

		return (medicalDeclinedDependents.length > 0 || dentalDeclinedDependents.length > 0 || visionDeclinedDependents.length > 0);
	}.property("medicalDeclinedDependents","dentalDeclinedDependents","visionDeclinedDependents"),
	medicalDeclinedDependents: function(){
		var medicalDeclinedDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// enrollInMedical means employee/dependent has an active coverage
			// employee is declining a plan if EHE has no plan attached
			// dependent is declining a plan if medicalCheckBoxStatus is false
			if (dependent.get('enrollInMedical') &&
					(dependent.get('isSelf') && !this.get('ePlan') ||
					!dependent.get('isSelf') && !dependent.get('medicalCheckBoxStatus'))) {
				medicalDeclinedDependents = medicalDeclinedDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return medicalDeclinedDependents.substring(1);
	}.property(
		'dependents.@each.medicalCheckBoxStatus',
		'dependents.@each.enrollInMedical',
		'ePlan'
	),
	dentalDeclinedDependents: function(){
		var dentalDeclinedDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// enrollInDental means employee/dependent has an active coverage
			// employee is declining a plan if EHE has no plan attached
			// dependent is declining a plan if dentalCheckBoxStatus is false
			if (dependent.get('enrollInDental') &&
					(dependent.get('isSelf') && !this.get('eDentalPlan') ||
					!dependent.get('isSelf') && !dependent.get('dentalCheckBoxStatus'))) {
				dentalDeclinedDependents = dentalDeclinedDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return dentalDeclinedDependents.substring(1);
	}.property(
		'dependents.@each.dentalCheckBoxStatus',
		'dependents.@each.enrollInDental',
		'eDentalPlan'
	),
	visionDeclinedDependents: function(){
		var visionDeclinedDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// enrollInVision means employee/dependent has an active coverage
			// employee is declining a plan if EHE has no plan attached
			// dependent is declining a plan if visionCheckBoxStatus is false
			if (dependent.get('enrollInVision') &&
					(dependent.get('isSelf') && !this.get('eVisionPlan') ||
					!dependent.get('isSelf') && !dependent.get('visionCheckBoxStatus'))) {
				visionDeclinedDependents = visionDeclinedDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return visionDeclinedDependents.substring(1);
	}.property(
		'dependents.@each.visionCheckBoxStatus',
		'dependents.@each.enrollInVision',
		'eVisionPlan'
	),
	medicalDependents: function(){
		var medicalDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// For self dependent, check if employee is electing a plan
			// for non self dependents, check medicalCheckBoxStatus
			if (dependent.get('isSelf') && this.get('ePlan') ||
					!dependent.get('isSelf') && dependent.get('medicalCheckBoxStatus')) {
				medicalDependents = medicalDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return medicalDependents.substring(1);
	}.property('dependents.@each.medicalCheckBoxStatus', 'ePlan'),
	dentalDependents: function(){
		var dentalDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// For self dependent, check if employee is electing a plan
			// for non self dependents, check dentalCheckBoxStatus
			if (dependent.get('isSelf') && this.get('eDentalPlan') ||
					!dependent.get('isSelf') && dependent.get('dentalCheckBoxStatus')) {
				dentalDependents = dentalDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return dentalDependents.substring(1);
	}.property('dependents.@each.dentalCheckBoxStatus', 'eDentalPlan'),
	visionDependents: function(){
		var visionDependents = "";
		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			// For self dependent, check if employee is electing a plan
			// for non self dependents, check visionCheckBoxStatus
			if (dependent.get('isSelf') && this.get('eVisionPlan') ||
					!dependent.get('isSelf') && dependent.get('visionCheckBoxStatus')) {
				visionDependents = visionDependents + ", " + dependent.get('firstName');
			}
		}.bind(this));
		return visionDependents.substring(1);
	}.property('dependents.@each.visionCheckBoxStatus', 'eVisionPlan'),
});
