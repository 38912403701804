App.MailtoLinkComponent = Ember.Component.extend({
	layoutName: 'mailtoLinkComponent',
	email: undefined,
	mailtoLink: function() {
		return "mailto:" + this.get('email');
	}.property('email')
});

App.TelephoneLinkComponent = Ember.Component.extend({
	layoutName: 'telephoneLinkComponent',
	telephone: undefined,
	extension: undefined,
	showExtension: false,
	telLink: function() {
		var tel = this.get('telephone') || '';
		return "tel:" + tel.replace(/[^a-zA-Z0-9-_]/g, '');
	}.property('telephone')
});

App.MissingEmailsConfigurationComponent = Ember.Component.extend(_InviteEmailsMixin, {
	layoutName: 'missingEmailsConfigurationComponent',
	employees: undefined,
	showAllEmployees: false,
	// we need a content property because invite emails mixin expects that
	content: Ember.computed.alias('employees'),

	// note: this should be a property of employees instead of employees.@each.emails so
	// that the employees don't abruptly disappear from the table when the emails are
	// updated
	employeesWithMissingEmails: function() {
		var employees = this.getWithDefault('employees', []);
		return employees.filter(function(employee) {
			return Ember.isEmpty(employee.get('email'));
		});
	}.property('employees'),

	hasMissingEmails: function() {
		return this.get('employeesWithMissingEmails.length') > 0;
	}.property('employeesWithMissingEmails.length')
});

App.EmployeeEmailListComponent = Ember.Component.extend({
	classNames: ['employee-email-list-component'],
	layoutName: 'employeeEmailListComponent',
	showLessEmployee: true,
	numEmployeesToShow: 25,
	employees: undefined,

	hasTooManyEmployees: function() {
		return this.get('employees.length') > this.get('numEmployeesToShow');
	}.property('employees.length', 'numEmployeesToShow'),

	employeesToShow: function() {
		var employees = this.get('employees');
		if (this.get('hasTooManyEmployees') && this.get('showLessEmployee')) {
			return employees.toArray().slice(0, this.get('numEmployeesToShow'));
		} else {
			return employees;
		}
	}.property('employees', 'hasTooManyEmployees', 'showLessEmployee', 'numEmployeesToShow'),

	didInsertElement: function() {
		// http://jqueryui.com/tooltip/#custom-style
		var $element = $(this.get('element'));
		$element.tooltip({
			position: {
			my: "center bottom-20",
			at: "center",
			// add arrow to jquery ui tooltip
			using: function( position, feedback ) {
				$( this ).css( position );
				$( "<div>" )
				.addClass( "arrow" )
				.addClass( feedback.vertical )
				.addClass( feedback.horizontal )
				.appendTo( this );
			}
			}
		});
	},

	willDestroyElement: function() {
		var $element = $(this.get('element'));
		$element.tooltip("destroy");
	},

	// TODO: we should use linkTo when we upgrade ember. Current linkTo does
	// not allow opening route in new window
	actions: {
		openEmployeeTearSheet: function(employee) {
			window.open('#/employeetearsheet/' + employee.get('id'));
		},

		toggleShowLessEmployee: function() {
			this.toggleProperty('showLessEmployee');
		}
	}
});


App.SliderComponent = Ember.Component.extend({
	min: undefined,
	max: undefined,

	didInsertElement: function() {
		var that = this;
		var options = { animate: true };
		if (this.get('min')) { options['min'] = this.get('min'); }
		if (this.get('max')) { options['max'] = this.get('max'); }
		if (this.get('value')) { options['value'] = this.get('value'); }
		options['change'] = function(event, ui) {
      that.set('value', ui.value);
    };
		this.$().slider(options);
	},

	willDestroyElement: function() {
		this.$().slider('destroy');
	},

	valueDidChange: function() {
		var value = this.get('value');
		if (this.$().slider('value') !== value) {
			this.$().slider('value', value);
		}
	}.observes('value'),

	minMaxDidChange: function() {
		var min = this.get('min');
		var max = this.get('max');
		this.$().slider('option', 'min', min);
		this.$().slider('option', 'max', max);
	}.observes('min', 'max')

});

App.ContributionSchemeComponent = Ember.Component.extend({
	layoutName: 'contributionSchemeComponent',
	plan: undefined,
	companyHealthCarrier: undefined,

	company: Ember.computed.alias('companyHealthCarrier.company'),
	genericContributionScheme: Ember.computed.alias('company.genericContributionScheme'),

	/**
	 * Shows all custom contribution schemes that have been set-up for a given plan.
	 * It is Console's responsibility to make sure contribution schemes are non-conflicting.
	 * @return {array} Array of wrapped contribution scheme objects
	 */
	customSchemes: function() {
		var myCustomSchemes = [];
		var wrapScheme = function(scheme, key) {
			var hash = { content: scheme };
			hash[key] = true;
			return Ember.ObjectProxy.create(hash);
		};
		var planContributionSchemes = this.get('planContributionSchemes');
		var planFixedDeductions = this.get('planFixedDeductions');
		var planDepTypeContributionSchemes = this.get('planDepTypeContributionSchemes');
		var employeeFixedDeductions = this.get('employeeFixedDeductions');
		var depFixedContributions = this.get('depFixedContributions');
		var depCountFixedDeductions = this.get('depCountFixedDeductions');
		var depTypeContributionSchemes = this.get('depTypeContributionSchemes');
		if (planContributionSchemes) {
			myCustomSchemes.push(wrapScheme(planContributionSchemes, 'isPlanContributionSchemes'));
		}
		if (planFixedDeductions) {
			myCustomSchemes.push(wrapScheme(planFixedDeductions, 'isPlanFixedDeductions'));
		}
		if (planDepTypeContributionSchemes) {
			myCustomSchemes.push(wrapScheme(planDepTypeContributionSchemes, 'isPlanDepTypeContributionSchemes'));
		}
		if (employeeFixedDeductions) {
			myCustomSchemes.push(wrapScheme(employeeFixedDeductions, 'isEmployeeFixedDeductions'));
		}
		if (depFixedContributions) {
			myCustomSchemes.push(wrapScheme(depFixedContributions, 'isDepFixedContributions'));
		}
		if (depCountFixedDeductions) {
			myCustomSchemes.push(wrapScheme(depCountFixedDeductions, 'isDepCountFixedDeductions'));
		}
		if (depTypeContributionSchemes) {
			myCustomSchemes.push(wrapScheme(depTypeContributionSchemes, 'isDepTypeContributionSchemes'));
		}
		return myCustomSchemes;
	}.property('depFixedContributions', 'depTypeContributionSchemes',
		'employeeFixedDeductions', 'planContributionSchemes', 'depCountFixedDeductions',
		'planDepTypeContributionSchemes', 'planFixedDeductions'),

	depFixedContributions: Ember.computed.alias('companyHealthCarrier.depFixedContributions.firstObject'),
	depCountFixedDeductions: Ember.computed.alias('companyHealthCarrier.depCountFixedDeductions.firstObject'),
	depTypeContributionSchemes: Ember.computed.alias('companyHealthCarrier.depTypeContributionSchemes.firstObject'),
	employeeFixedDeductions: Ember.computed.alias('companyHealthCarrier.employeeFixedDeductions.firstObject'),

	getPlanScheme: function(path) {
		var planId = +this.get('plan.id');
		var schemes = this.getWithDefault(path, []);
		return schemes.filterProperty('planId', planId).get('firstObject');
	},

	planContributionSchemes: function() {
		return this.getPlanScheme('companyHealthCarrier.planContributionSchemes');
	}.property('plan.id', 'companyHealthCarrier.planContributionSchemes.@each.planId'),

	planDepTypeContributionSchemes: function() {
		return this.getPlanScheme('companyHealthCarrier.planDepTypeContributionSchemes');
	}.property('plan.id', 'companyHealthCarrier.planDepTypeContributionSchemes.@each.planId'),

	planFixedDeductions: function() {
		return this.getPlanScheme('companyHealthCarrier.planFixedDeductions');
	}.property('plan.id', 'companyHealthCarrier.planFixedDeductions.@each.planId'),

	genericContributionSchemeDescription: function() {
		var scheme = this.get('genericContributionScheme');
		if (!scheme) {
			return null;
		}
		return scheme.descriptionLinesForChcAndPlan(this.get('companyHealthCarrier.id'), this.get('plan.id'));
	}.property('genericContributionScheme.description', 'companyHealthCarrier.id', 'plan.id'),
});

App.LdAdminSignoffComponent = Ember.Component.extend({
	layoutName: undefined,
	plan: undefined,
	prettyLineOfCoverage: undefined,

	guaranteeIssue: function() {
		return Number(this.get('plan.eeRestriction.guaranteeIssue'));
	}.property('plan.eeRestriction.guaranteeIssue'),

	maxAmount: function() {
		return Number(this.get('plan.eeRestriction.maxAmount'));
	}.property('plan.eeRestriction.maxAmount'),

	formattedMaxMultiplier: function() {
		var fmtMaxMultiplier = Number(this.get('plan.eeRestriction.maxMultiplier'));

		// return falsy value if no max multiplier to get proper display
		if (fmtMaxMultiplier === 0) {
			return null;
		}

		if (fmtMaxMultiplier <= 1) {
			var asPercent = fmtMaxMultiplier*100;
			return asPercent.toString() + "% of";
		} else {
			return fmtMaxMultiplier.toString() + "x"; // to have consistent return type..
		}

	}.property('plan.eeRestriction.maxMultiplier'),

	flatAmountList: function() {
		var flatAmounts = this.get('plan.eeRestriction.flatAmounts');
		if (flatAmounts) {
			var numberArray = flatAmounts.split(',');
			for (var i=0; i<numberArray.length; i++) {
				numberArray[i] = parseInt(numberArray[i], 10);
			}
			return numberArray;
		}
		return [];
	}.property('plan.eeRestriction.flatAmounts'),
});

App.ReviewLdPlansComponent = Ember.Component.extend({
	layoutName: "reviewLdPlansComponent",
	prettyLineOfCoverage: undefined,
	openEnrollmentEndDate: undefined,
	renewalDate: undefined,
	enrollmentPlanPairs: undefined,

	// only display the "AD&D, yes or no" comparison row when life
	isLife: function() {
		return this.get('prettyLineOfCoverage') === 'life';
	}.property('prettyLineOfCoverage'),

	// no guarantee issue comparison row for AD&D
	isNotADD: function() {
		return this.get('prettyLineOfCoverage') !== 'AD&D';
	}.property('prettyLineOfCoverage'),
});

App.PrettyCurrencyOrStringComponent = Ember.Component.extend({
	layoutName: 'prettyCurrencyOrStringComponent',
	value: undefined,

	isNumber: function() {
		return !isNaN(Number(this.get('value')));
	}.property('value'),
});


App.WaitingPeriodComponent = Ember.Component.extend({
	layoutName: 'waitingPeriodComponent',
	companyHealthCarrier: undefined,

	nonMDV: function() {
		return this.get('companyHealthCarrier.lineOfCoverage') == 'lifenew' ||
			this.get('companyHealthCarrier.lineOfCoverage') == 'add' ||
			this.get('companyHealthCarrier.lineOfCoverage') == 'std' ||
			this.get('companyHealthCarrier.lineOfCoverage') == 'ltd' ||
			!this.get('companyHealthCarrier.lineOfCoverage');
	}.property('companyHealthCarrier.lineOfCoverage'),

	isEmpty: Ember.computed.empty('companyHealthCarrier.waitingPeriod'),
	isDefault: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Default'),
	isHireDate: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Hire Date'),
	isOneMonth: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'One Month'),
	isTwoMonths: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Two Months'),
	isThreeMonths: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Three Months'),
	isHirePlus30: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Hire + 30'),
	isHirePlus60: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Hire + 60'),
	isHirePlus90: Ember.computed.equal('companyHealthCarrier.waitingPeriod', 'Hire + 90')
});


App.CarrierLogoComponent = Ember.Component.extend({
	tagName: 'img',
	attributeBindings: ['src'],
	name: undefined,
	src: function() {
		var name = this.get('name') || "";
		name = name.trim().toLowerCase();
		if (name === 'aetna') {
			return "/static/img/carrier-logos_aetna.png";
		} else if(name === 'anthem blue cross') {
			return "/static/img/carrier-logos_bluecross.png";
		} else if(name === 'blue cross') {
			return "/static/img/carrier-logos_bluecross.png";
		} else if(name === 'blue shield') {
			return "/static/img/carrier-logos_blueshield.png";
		} else if(name === 'cigna') {
			return "/static/img/carrier-logos_cigna.png";
		} else if(name === 'healthnet') {
			return "/static/img/carrier-logos_healthnet.png";
		} else if(name === 'kaiser') {
			return "/static/img/carrier-logos_kaiser.png";
		} else if(name === 'united') {
			return "/static/img/carrier-logos_united.png";
		}
	}.property('name')
});


App.LifePlanAdditionalCoverageComponent = Ember.Component.extend({
	layoutName: 'lifePlanAdditionalCoverageComponent',
	employeelifeplan: undefined,
	title: undefined,
	requirement: undefined,
	maxamountBinding: undefined,
	rate: Ember.computed.alias('employeelifeplan.supplementalRate'),
	shouldDisplayComponent: Ember.computed.gt('setValuesArray.length', 0),

	setValuesArray: function() {
		var values = this.get('requirement.setValues');
		var eeLifeMax = this.get('employeelifeplan.eeLifeMax');
		var spouseLifeMax = this.get('employeelifeplan.spouseLifeMax');
		var childLifeMax = this.get('employeelifeplan.childLifeMax');
		var eeAddMax = this.get('employeelifeplan.eeAddMax');
		var spouseAddMax = this.get('employeelifeplan.spouseAddMax');
		var childAddMax = this.get('employeelifeplan.childAddMax');
		var isEELife = this.get('requirement.isEELife');
		var isSpouseLife = this.get('requirement.isSpouseLife');
		var isChildLife = this.get('requirement.isChildLife');
		var isEEAdd = this.get('requirement.isEEAdd');
		var isSpouseAdd = this.get('requirement.isSpouseAdd');
		var isChildAdd = this.get('requirement.isChildAdd');

		if (!values) { return []; }
		var dependentTypeMax;
		if (isEELife) {
			dependentTypeMax = eeLifeMax;
		}
		else if (isSpouseLife) {
			dependentTypeMax = spouseLifeMax;
		}
		else if (isChildLife) {
			dependentTypeMax = childLifeMax;
		}
		else if (isEEAdd) {
			dependentTypeMax = eeAddMax;
		}
		else if (isSpouseAdd) {
			dependentTypeMax = spouseAddMax;
		}
		else if (isChildAdd) {
			dependentTypeMax = childAddMax;
		}

		return values.split(',').map(function(value) {
			return Number(value);
		}).filter(function(value) {
			if (dependentTypeMax !== undefined){
				return dependentTypeMax >= value;
			}
			return false;
		});
	}.property('requirement.setValues',
		'employeelifeplan.employee', 'employeelifeplan.supplementalAmount',
		'employeelifeplan.eeLifeMax', 'employeelifeplan.spouseLifeMax',
		'employeelifeplan.childLifeMax', 'employeelifeplan.eeAddMax',
		'employeelifeplan.spouseAddMax', 'employeelifeplan.childAddMax'),

	onSetValuesArrayChanges: function() {
		if (!this.get('requirement.isStyleSetValues')) { return; }
		var array = this.getWithDefault('setValuesArray', []);
		if (Ember.isEmpty(array)) { return; }
		var value = this.get('value');
		var maxValue = Math.max.apply(null, array);
		value = value > maxValue ? maxValue : value;
		this.set('value', value);
	}.observes('setValuesArray'),

	calculatedPrice: function() {
		var value = this.get('value');
		var rate = this.get('rate');
		if (!value || !rate) {
			return 0;
		}
		//HACKINGGG until support built out for dependent rates
		return (value * rate * 2) / 1000.0;
		// return (value * rate) / 1000.0;
	}.property('value', 'rate')
});

App.IncrementallyRenderedListComponent = Ember.Component.extend({
	tagName: '',
	templateName: 'incrementallyRenderedList',
	content: null,
	timerId: null,
	chunkSize: 50,
	waitTime: 150,
	renderedList: null, // don't worry, it'll be initialized in the init

	contentDidChange: function() {
		this.cancelPushItems();
		this.set('renderedList', Em.A());
		this.pushItems();
	}.observes('content.[]').on('init'),

	pushItems: function() {
		var content = this.get('content');
		var renderedList = this.get('renderedList');
		var currentIndex = renderedList.get('length');
		if (!content || currentIndex >= content.get('length')) { return; }
		var itemsToRender = content.slice(currentIndex, currentIndex + (+this.get('chunkSize')));
		renderedList.pushObjects(itemsToRender);
		this.set('timerId', Ember.run.later(this, this.pushItems, +this.get('waitTime')));
	},

	cancelPushItems: function() {
		if (this.get('timerId')) {
			Ember.run.cancel(this.get('timerId'));
			this.set('timerId', null);
		}
	},

	didInsertElement: function() {
		this.cancelPushItems();
		this.pushItems();
	},

	willDestroyElement: function() {
		this.cancelPushItems();
	}
});

App.DarkTableComponent = Ember.Component.extend({
	tagName: 'table',
	classNames: ['dark-table'],
	templateName: 'darkTable',
	title: '',
	hasCaption: Ember.computed.hasChildView('caption'),
});

App.DarkTableActionsComponent = Ember.Component.extend({
	tagName: 'caption',
	templateName: 'darkTableActions',
});

App.DarkTableColgroupComponent = Ember.Component.extend({
	tagName: 'colgroup',
	templateName: 'darkTableColgroup',
});

App.DarkTableHeadComponent = Ember.Component.extend({
	tagName: 'thead',
	templateName: 'darkTableHead',
});

App.DarkTableRowsComponent = Ember.Component.extend({
	tagName: 'tbody',
	templateName: 'darkTableRows',
	items: null,
	chunkSize: 50,
	waitTime: 150,
});
