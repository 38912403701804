App.EmployeephotocropController = _AbstractSavingController.extend(zen._FilePickerMixin,{
	newPhotoUrl: '',
	x: '',
	y: '',
	h: '',
	w: '',
	save: function(){
		var employee = this.get('model');
		var photoUrl = employee.get('photoUrl');
		filepicker.convert(photoUrl,{
				crop:[this.get('x'), this.get('y'), this.get('w'), this.get('h')],
				rotate:"exif"
			},
			{location: "S3"},
			function(FPFile){
				employee.set('photoUrl', FPFile.url);
				filepicker.convert(FPFile.url,
					{height: 256, width: 256, fit:'crop', align: 'faces'},
					{location: "S3"},
					function(thumbnailFPFile){
						employee.set('photoThumbnailUrl', thumbnailFPFile.url);
						this.saveAndContinue();
					}.bind(this));
				this.saveAndContinue().then(function(){
					this.send('hideModal');
				}.bind(this));
			}.bind(this),
			function(FPError){
				console.log(FPError.toString());
			}
		);
	},
	cancel: function(){
		var employee = this.get('model');
		employee.set('photoUrl', null);
		this.saveAndContinue().then(function(){
			this.send('hideModal');
		}.bind(this));
	}
});

App.EmployeePersonalinfoController = _AbstractSavingController.extend(_EmployeephotouploadMixin, {
	newPhotoUrl: '',
	isRequiredPersonalInfoFieldsComplete: true,
	productNavElements: function() {
		var listOfNavElements = [
			{
				label: 'Basic Info',
				routeName: 'employee.personalinfo.basicinfo',
			},
		];
		if (this.get('isContractor')) {
			listOfNavElements.push({
				label: 'Contact Info',
				routeName: 'employee.personalinfo.contactinfo',
			});

			if (this.get('contractor.isCountryUsa')) {
				listOfNavElements.push({
					label: 'Bank Account',
					routeName: 'employee.personalinfo.contractorbankinfo',
				});
				listOfNavElements.push({
					label: 'Exemptions',
					routeName: 'employee.personalinfo.contractorexemptions',
				});
				listOfNavElements.push({
					label: 'Paystubs',
					routeName: 'employee.personalinfo.paystubs',
				});
			}

			listOfNavElements.push({
				label: 'Documents',
				routeName: 'employee.personalinfo.documents',
			});

			if (!this.get('contractor.isW9Complete')) {
				listOfNavElements.push({
					label: 'W9',
					routeName: 'employee.personalinfo.contractorw9',
				});
			}
		} else {
			if (this.get('isEmployeeCustomFieldsPresent')) {
				listOfNavElements.push({
					label: 'Custom Fields',
					routeName: 'employee.personalinfo.customfields',
				});
			}
			listOfNavElements = listOfNavElements.concat(
				{
					label: 'Contact Info',
					routeName: 'employee.personalinfo.contactinfo',
				},
				{
					label: 'Emergency Contacts',
					routeName: 'employee.personalinfo.emergency',
				},
				{
					label: 'Employment Info',
					routeName: 'employee.personalinfo.employmentinfo',
					additionalNavElementClasses: 'js-glue-employment-info',
				}
			);
			if (this.get('hasDocuments')) {
				listOfNavElements.push({
					label: 'Documents',
					routeName: 'employee.personalinfo.documents',
				});
			}
			if (this.get('hasPaystubs')) {
				listOfNavElements.push({
					label: 'Paystubs',
					routeName: 'employee.personalinfo.paystubs',
				});
			}
		}
		return listOfNavElements;
	}.property('isEmployeeCustomFieldsPresent', 'hasDocuments', 'hasPaystubs', 'contractor.isCountryUsa','contractor.isW9Complete'),
	_errorText: function(){
		if(this.get('isRequiredPersonalInfoFieldsComplete')) {
			return '';
		} else {
			return 'Please complete all required fields';
		}
	}.property('isRequiredPersonalInfoFieldsComplete'),
	openFilePicker:function(){
		this.uploadFile(['COMPUTER', 'WEBCAM', 'FACEBOOK', 'INSTAGRAM', 'PICASA', 'FLICKR',
						 'DROPBOX', 'GOOGLE_DRIVE', 'SKYDRIVE', 'GMAIL', 'URL'],
			this.get('model'));
	},
	save: function() {
		if(!this.get('isRequiredPersonalInfoFieldsComplete')){
			return;
		}
		this.saveAndContinue('index');
	},
	removePhoto: function() {
		this.set('photoUrl', '');
		this.set('photoThumbnailUrl', '');
	}
});

App.EmployeePersonalinfoBasicinfoController = App.EmployeePersonalinfoController.extend(App.ValidationsControllerMixin, {
	clickedMoreGenderOptions: false,
	hasCustomGenderFilledOut: Ember.computed.or('genderIdentity', 'personalPronounsId'),
	moreGenderOptionsEnabled: Ember.computed.or('clickedMoreGenderOptions', 'hasCustomGenderFilledOut'),
	showMoreGenderOptions: function() {
		this.set('clickedMoreGenderOptions', true);
	},
	personalPronounsOptions: function() {
		var personalPronounsMap = App.PersonalPronounMap;
		var choices = Ember.A();
		Object.keys(personalPronounsMap).forEach(function(key) {
			var pronouns = personalPronounsMap.get(key);
			choices.pushObject(Ember.Object.create({
				'displayName': pronouns.get("subjective").capitalize() + '/' + pronouns.get("objective") + '/' + pronouns.get('possessive'),
				'id': parseInt(key),
			}));
		});
		return choices;
	}.property('personalPronounsMap'),

	isRequiredPersonalInfoFieldsComplete: function() {
		if (this.get('isContractor')) {
			if (this.get('contractor.isInternational')) {
				return (this.get('contractor.name') || this.get('contractor.legalName')) && this.get('contractor.entityType');
			}
			else {
				return (this.get('contractor.name') || this.get('contractor.legalName')) && this.get('contractor.entityType') && (this.get('contractor.taxId') || this.get('newTaxId'));
			}
		} else {
			if (!this.get('isInternational') ) {
				if(this.get('isWaitingForSSNInfo')){
					return this.get('first_name') && this.get('last_name') && this.get('dob') && this.get('socialSecurity') && this.get('sex');
				}
				else {
					return this.get('first_name') && this.get('last_name') && this.get('dob')&& this.get('sex');
				}
			} else {
				return this.get('first_name') && this.get('last_name') && this.get('dob') && this.get('sex');
			}
		}
	}.property('first_name', 'last_name', 'dob', 'socialSecurity', 'sex', 'contractor.name', 'contractor.legalName', 'contractor.entityType', 'contractor.taxId', 'newTaxId'),
	errorText: Ember.computed.alias('_errorText'),
	showNoSSNBanner: Ember.computed.alias('isWaitingForSSNInfo'),
	// Contractor specific operations
	showLLCClassification: Ember.computed.equal('contractor.entityType', 'LC'),
	showSoleProprietorship: Ember.computed.equal('contractor.entityType', 'SP'),
	showIdentification: Ember.computed.or('showLLCClassification', 'showSoleProprietorship'),
	save: function() {
		if(this.get('newSocialSecurity') && this.get('isEmployee')){
 			this.set('socialSecurity', this.get('newSocialSecurity'));
 		}

		if (!this.get('isRequiredPersonalInfoFieldsComplete')){
			return;
		}

		if (this.get('anyErrors')) {
			return;
		}

		//Contractor Only
		if (this.get('isContractor')) {
			if (this.get('newTaxId')) {
				var contractor = this.get('contractor');
				if (contractor.get('isBusiness')) {
					contractor.set('identification', 'EIN');
				} else {
					contractor.set('identification', 'SSN');
				}
				contractor.set('taxId', this.get('newTaxId'));
			}
		}

		this.saveAndContinue('index');
	},

});
App.EmployeePersonalinfoCustomfieldsController = App.EmployeePersonalinfoController.extend({
	save: function() {
		this.saveCheckAndContinue('isEmployeeCustomFieldsComplete', 'index');
	}
});

App.EmployeeresidencestatechangemodalController = _ModalObjectController.extend({
	save: function() {
		this.send('hideModal');
		return this.transitionToRoute('employee.payrollinfo.statetaxinfo');
	},
});


App.EmployeePersonalinfoEmergencyController = App.EmployeePersonalinfoController.extend();
App.EmployeePersonalinfoEmploymentinfoController = App.EmployeePersonalinfoController.extend();
App.EmployeePersonalinfoPaystusbsController = App.EmployeePersonalinfoController.extend();

App.EmployeePayrollinfoController = _AbstractSavingController.extend(App.ValidationsControllerMixin, {
	productNavElements: function() {
		var listOfNavElements = [
			{
				label: 'Bank Info',
				routeName: 'employee.payrollinfo.bankinfo',
			},
		];
		if (!this.get('isTerminated')) {
			listOfNavElements.push({
				label: 'Federal Tax Info',
				routeName: 'employee.payrollinfo.federaltaxinfo',
			});
			if(!this.get('isInternational')) {
				listOfNavElements.push({
					label: 'State Tax Info',
					routeName: 'employee.payrollinfo.statetaxinfo',
				});
			}
		}
		if (App.switches.isActive('employee_paystubs')) {
			listOfNavElements.push({
				label: 'Paystubs',
				routeName: 'paystubs',
			});
		}

		return listOfNavElements;
	}.property('isTerminated'),
	isRequiredFieldsComplete: true,
	save: function() {
		this.set('errorText', "");
		if (this.get('anyErrors')) {
			return;
		}
		this.saveAndContinue('index');
	}
});

App.EmployeetearsheetresettaxmodalController = _ModalObjectController.extend({
	save: function() {
		var url = '/custom_api/reset_employee_taxes/';
		var json_data = JSON.stringify({"employee_id": this.get('employee_id')});
		return Ember.ajaxPost(url, json_data).then(function(ret) {
			this.send('hideModal');
			return this.controllerFor('employee.payrollinfo.statetaxinfo').send('reloadObject');
		}.bind(this)).catch(function(err, ret){
			this.eventLogger.log('reset_employee_taxes_flow', {
				employee_id: this.get('employee_id'),
				error: JSON.stringify(err)
			});
		}.bind(this));
	},

	cancel: function() {
		this.send('hideModal');
	}
});



App.EmployeePlansettingsView = Ember.View.extend({
	didViewChange: function() {
		this.rerender();
	}.observes('App.EmployeePlanSettingsNavController.refresh')
});

App.EmployeePlanSettingsNavController = Ember.ArrayController.create({
	isoverview: true,
	isadddependents: false,
	isremovedependents: false,
	ischangeplan: false,
	isapplyforinsurance: false,
	iscancel: false,
	refresh: 0,
	updateContent: function() {
		this.set('refresh', this.get('refresh') + 1);
	},
	thisIsAddDependents: function() {
		this.set('isoverview', false);
		this.set('isdependents', false);
		this.set('isremovedependents', false);
		this.set('ischangeplan', false);
		this.set('isapplyforinsurance', false);
		this.set('iscancel', false);
		this.set('isinsurancecards', false);
		this.set('isadddependents', true);
		this.updateContent();
	}
});

var spdsComputed = function(plansPath, selectedPlanPath) {
	return Ember.computed(function() {
		var planPromises = this.get(plansPath);
		var selectedPlan = this.get(selectedPlanPath);

		if (!planPromises || !planPromises.length) {
			return [];
		}

		return planPromises[0].filter(function(item) {
			return item.get('summaryPlanDescriptionDocument') && item.get('planId') == selectedPlan;
		});
	}).property(plansPath, selectedPlanPath);
};

App.EmployeePlansettingsController = _TransactionSavingController.extend({
	medicalSpds: spdsComputed('medicalPromises', 'employee.medicalPlan.id'),
	dentalSpds: spdsComputed('dentalPromises', 'employee.dentalPlan.id'),
	visionSpds: spdsComputed('visionPromises', 'employee.visionPlan.id'),
	hasMedicalSpd: Ember.computed.gt('medicalSpds.length', 0),
	hasDentalSpd: Ember.computed.gt('dentalSpds.length', 0),
	hasVisionSpd: Ember.computed.gt('visionSpds.length', 0),
	hasAnySpd: function(){
		return this.get('hasMedicalSpd')||this.get('hasDentalSpd')||this.get('hasVisionSpd');
	}.property('hasMedicalSpd','hasDentalSpd','hasVisionSpd'),

	canAddRemoveDependent: function() {
		var currentMedicalEnrollment = this.get('currentMedicalEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');
		var employee = this.get('model');

		// Allow add/remove dependent through QLE iff
		// - DHE is allowed
		// - EHE is OE or EHE does not allow to edit
		var allowMedical = employee.get('allowDependentMedicalEnrollment') && (!currentMedicalEnrollment || currentMedicalEnrollment.get('isOpenEnrollment') || !currentMedicalEnrollment.get('allowEditReviewPlans'));
		var allowDental = employee.get('allowDependentDentalEnrollment') && (!currentDentalEnrollment || currentDentalEnrollment.get('isOpenEnrollment') || !currentDentalEnrollment.get('allowEditReviewPlans'));
		var allowVision = employee.get('allowDependentVisionEnrollment') && (!currentVisionEnrollment || currentVisionEnrollment.get('isOpenEnrollment') || !currentVisionEnrollment.get('allowEditReviewPlans'));

		return allowMedical || allowDental || allowVision;
	}.property('currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'),

	hasActivePlans: function () {
		return !this.get('hasDeclinedCoverage') && !this.get('areAllPlansDeactive');
	}.property('hasDeclinedCoverage', 'areAllPlansDeactive'),
	hasActiveAndApprovedPlans: function () {
		return !this.get('hasDeclinedCoverage') && !this.get('areAllPlansDeactive') && this.get('isApproved');
	}.property('hasDeclinedCoverage', 'areAllPlansDeactive', 'isApproved'),
	hasActivePlansWithDependents: function () {
		return !this.get('hasDeclinedCoverage') && !this.get('areAllPlansDeactive') && this.get('isAddingDependentsPossible');
	}.property('hasDeclinedCoverage', 'areAllPlansDeactive', 'isAddingDependentsPossible'),

	// Consider has active plans if either CHE complete or BoR plans are loaded
	hasActiveMedicalPlans: Ember.computed.or('company.isMedicalEnrollmentComplete', 'borStats.hasMedicalPlans'),
	hasActiveDentalPlans: Ember.computed.or('company.isDentalEnrollmentComplete', 'borStats.hasDentalPlans'),
	hasActiveVisionPlans: Ember.computed.or('company.isVisionEnrollmentComplete', 'borStats.hasVisionPlans'),

	// Can apply for insurance if EE is declined and have plan to choose from
	canApplyForMedical: Ember.computed.and('employee.isMedicalDeclined', 'hasActiveMedicalPlans'),
	canApplyForDental: Ember.computed.and('employee.isDentalDeclined', 'hasActiveDentalPlans'),
	canApplyForVision: Ember.computed.and('employee.isVisionDeclined', 'hasActiveVisionPlans'),
	canApplyForInsurance: Ember.computed.or('canApplyForMedical', 'canApplyForDental', 'canApplyForVision'),

	// We show a warning on this page if MDV Employee apps are blocked
	// We get a cardBlockReasonKey as part of zApp subscription
	MDVEmployeeZAppSubscriptions: function() {
		var zAppSubscriptions = this.get('zAppSubscriptions');
		var healthInsuranceEmployeeZAppIds = ['1.com.zenefits.MedicalInsuranceEmployee',
			'1.com.zenefits.DentalInsuranceEmployee', '1.com.zenefits.VisionInsuranceEmployee'];

		return zAppSubscriptions.filter(function (subscription) {
			var uniqueId = subscription.get('appInstall.app.uniqueId');
			return healthInsuranceEmployeeZAppIds.contains(uniqueId);
		});
	}.property('zAppSubscriptions.@each'),

	MDVEmployeeZAppCardBlockReasonKey: function() {
		// So far, all the cards are blocked together if they are
		// Referring to cardBlockReason on one of them is sufficient
		// NOTE: This might change later
		var healthInsuranceEmployeeSubscription = this.get('MDVEmployeeZAppSubscriptions').get('firstObject');
		if (!healthInsuranceEmployeeSubscription) {
			return;
		}
		return healthInsuranceEmployeeSubscription.get('preferences.cardBlockReasonKey');
	}.property('MDVEmployeeZAppSubscriptions.@each.preferences.cardBlockReasonKey'),

	waitingOnAdminSignOffDuringSCOE: Ember.computed.equal('MDVEmployeeZAppCardBlockReasonKey', 'waitingOnAdminSignOffDuringSCOE'),
	borInProgressShowShortCircuitQLELink: Ember.computed.equal('MDVEmployeeZAppCardBlockReasonKey', 'borInProgressShowShortCircuitQLELink'),
	borInProgressDoNotShowShortCircuitQLELink: Ember.computed.equal('MDVEmployeeZAppCardBlockReasonKey', 'borInProgressDoNotShowShortCircuitQLELink'),
	newHireBlockedFromEnrolling: Ember.computed.equal('MDVEmployeeZAppCardBlockReasonKey', 'newHireBlockedFromEnrolling'),
	unidentifiedCardBlockKey: Ember.computed.equal('MDVEmployeeZAppCardBlockReasonKey', 'unidentified'),

	// We show the nav with links to other pages only if there is no card block reason
	productNavElements: function() {
		var listOfNavElements = [];

		//Add all your nav elements
		if (this.get('hasActiveMedicalPlans')) {
			listOfNavElements.push({
				label: 'Medical Overview',
				routeName: 'employee.overview.mdv.medical',
			});
		}

		if (this.get('hasActiveDentalPlans')) {
			listOfNavElements.push({
				label: 'Dental Overview',
				routeName: 'employee.overview.mdv.dental',
			});
		}

		if (this.get('hasActiveVisionPlans')) {
			listOfNavElements.push({
				label: 'Vision Overview',
				routeName: 'employee.overview.mdv.vision',
			});
		}

		if (this.get('hasActivePlans')) {
			listOfNavElements.push({
				label: 'Insurance Cards',
				routeName: 'employee.plansettings.insurancecards',
			});
		}

		listOfNavElements.push({
			label: 'Change my Insurance',
			routeName: 'employee.qle',
		});

		if (this.get('hasAnySpd')) {
			listOfNavElements.push({
				label: 'Plan Description',
				routeName: 'employee.plansettings.spds',
			});
		}

		return listOfNavElements;
	}.property('hasActiveMedicalPlans', 'hasActiveDentalPlans', 'hasActiveVisionPlans', 'hasActivePlans', 'hasActivePlansWithDependents', 'canAddRemoveDependent', 'canApplyForInsurance', 'hasAnySpd'),
});


App.EmployeePlansettingsSpdsController = _TransactionSavingController.extend({
	medicalSpds : spdsComputed('medicalPromises', 'employee.medicalPlan.id'),
	dentalSpds: spdsComputed('dentalPromises', 'employee.dentalPlan.id'),
	visionSpds: spdsComputed('visionPromises', 'employee.visionPlan.id'),
	hasMedicalSpd : Ember.computed.gt('medicalSpds.length', 0),
	hasDentalSpd : Ember.computed.gt('dentalSpds.length', 0),
	hasVisionSpd : Ember.computed.gt('visionSpds.length', 0),
	hasAnySpd : Ember.computed.or('hasMedicalSpd', 'hasDentalSpd', 'hasVisionSpd'),
});

App.EmployeePlansettingsDependentsController = App.EmployeePlansettingsController.extend({
	noneSelfDependents: Ember.computed.rejectByProperty('dependents', 'isSelf'),
	canRemoveThroughOpenEnrollment: Ember.computed.and('currentEnrollment.isOpenEnrollment', 'currentEnrollment.allowEditReviewPlans'),
	hasDependents: function() {
		if (this.get('dependents.length') == 1) {
			return false;
		}

		return true;
	}.property('dependents.length'),
});


var _EmployeePlansettingsEnrollmentDatesMixin = Ember.Mixin.create({
	enrollmentEndDates: function () {
		var enrollmentProperties = ['currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'];
		return enrollmentProperties.map(function (property) {
			return this.get([property, 'endDate'].join('.'));
		}, this).filter(function (endDate) {
			return endDate;
		}).map(function (endDate) {
			return new Date(endDate);
		});
	}.property('currentEnrollment.endDate', 'currentDentalEnrollment.endDate', 'currentVisionEnrollment.endDate'),
	earliestEnrollmentEndDate: function () {
		return this.get('enrollmentEndDates').reduce(function (acc, endDate) {
			return acc < endDate ? acc : endDate;
		});
	}.property('enrollmentEndDates'),
});

var _EmployeePlansettingsNewCostsMixin = Ember.Mixin.create({
	newMedicalCost: function() {
		var currentPlan = this.get('currentPlan');
		if (!currentPlan) {
			return 0;
		}

		return currentPlan.get('youPremium');
	}.property('currentPlan', 'currentPlan.youPremium'),
	newDentalCost: function() {
		var currentDentalPlan = this.get('currentDentalPlan');
		if (!currentDentalPlan) {
			return 0;
		}

		return currentDentalPlan.get('youPremium');
	}.property('currentDentalPlan', 'currentDentalPlan.youPremium'),
	newVisionCost: function() {
		var currentVisionPlan = this.get('currentVisionPlan');
		if (!currentVisionPlan) {
			return 0;
		}

		return currentVisionPlan.get('youPremium');
	}.property('currentVisionPlan', 'currentVisionPlan.youPremium'),
});

var _EmployeePlansettingsDependentsMixin = Ember.Mixin.create({
	dependentsLength: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') && item.get('hasCoverage')) {
					count++;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.isSelf', 'dependents.@each.hasCoverage'),
	// Medical
	enrolledDependentsMedical: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') && item.get('enrollInMedical')) {
					count++;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.isSelf', 'dependents.@each.enrollInMedical'),
	enrollingDependentsMedical: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') &&
					(item.get('medicalCoverageStartDate') || item.get('enrollInMedical')) &&
					(!item.get('medicalCoverageEndDate') || (item.get('medicalCoverageStartDate') && item.get('medicalCoverageStartDate') > item.get('medicalCoverageEndDate')))) {
					count += 1;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.isSelf', 'dependents.@each.medicalCoverageStartDate', 'dependents.@each.medicalCoverageEndDate'),
	// Dental
	enrolledDependentsDental: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') && item.get('enrollInDental')) {
					count++;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.enrollInDental'),
	enrollingDependentsDental: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') &&
					(item.get('dentalCoverageStartDate') || item.get('enrollInDental')) &&
					(!item.get('dentalCoverageEndDate') || (item.get('dentalCoverageStartDate') && item.get('dentalCoverageStartDate') > item.get('dentalCoverageEndDate')))) {
					count += 1;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.isSelf', 'dependents.@each.dentalCoverageStartDate', 'dependents.@each.dentalCoverageEndDate'),
	// Vision
	enrolledDependentsVision: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') && item.get('enrollInVision')) {
					count++;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.enrollInVision'),
	enrollingDependentsVision: function() {
		var dependents = this.get('dependents');
		if (dependents) {
			var count = 0;
			dependents.forEach(function(item) {
				if (!item.get('isSelf') &&
					(item.get('visionCoverageStartDate') || item.get('enrollInVision')) &&
					(!item.get('visionCoverageEndDate') || (item.get('visionCoverageStartDate') && item.get('visionCoverageStartDate') > item.get('visionCoverageEndDate')))) {
					count += 1;
				}
			});

			return count;
		}

		return 0;
	}.property('dependents.@each.isSelf', 'dependents.@each.visionCoverageStartDate', 'dependents.@each.visionCoverageEndDate'),
});

App.EmployeePlansettingsOverviewController = App.EmployeePlansettingsController.extend(_EmployeePlansettingsNewCostsMixin, _EmployeePlansettingsDependentsMixin, _EmployeePlansettingsEnrollmentDatesMixin, {
	dependents: [],
	settings: null,
	companySettings: null,

	// Status pending is applicable only when we have to decide whether the employee has declined or
	// not. For BoR Switched carrier, they are decline only when they explicitly mark the state as decline.
	// For SCOE, they are decline when their enrollment status is decline.
	medicalStatusPending: function() {
		if(this.get('borStats.hasAtleastOneMedicalSwitched')) {
			return this.get('medicalApprovalStatus') != 'decline';
		}
		if(this.get('shortCircuitOEStats.atleastOneMedicalSCOEStepsComplete')) {
			return this.get('currentEnrollment.status') != 'decline';
		}
	}.property('borStats.hasAtleastOneMedicalSwitched', 'shortCircuitOEStats.atleastOneMedicalSCOEStepsComplete', 'medicalApprovalStatus', 'currentEnrollment.status'),

	dentalStatusPending: function() {
		if(this.get('borStats.hasDentalPlans')) {
			return this.get('dentalApprovalStatus') != 'decline';
		}
		if(this.get('shortCircuitOEStats.dentalSCOEStepsComplete')) {
			return this.get('currentDentalEnrollment.status') != 'decline';
		}
	}.property('borStats.hasDentalPlans', 'shortCircuitOEStats.dentalSCOEStepsComplete', 'dentalApprovalStatus', 'currentDentalEnrollment.status'),

	visionStatusPending: function() {
		if(this.get('borStats.hasVisionPlans')) {
			return this.get('visionApprovalStatus') != 'decline';
		}
		if(this.get('shortCircuitOEStats.visionSCOEStepsComplete')) {
			return this.get('currentVisionEnrollment.status') != 'decline';
		}
	}.property('borStats.hasVisionPlans', 'shortCircuitOEStats.visionSCOEStepsComplete', 'visionApprovalStatus', 'currentVisionEnrollment.status'),

	isMedicalApprovedOrSentElectronic: Ember.computed.or('isMedicalApproved', 'settings.isMedicalNewHireApplicationSentElectronic'),

	isDentalApprovedOrSentElectronic: Ember.computed.or('isDentalApproved', 'settings.isDentalNewHireApplicationSentElectronic'),

	isVisionApprovedOrSentElectronic: Ember.computed.or('isVisionApproved', 'settings.isVisionNewHireApplicationSentElectronic'),

	changeSummary: function() {
		var offset = $('#summary').offset();
		offset.left -= 10;
		offset.top -= 10;

		$('html, body').animate({
			scrollTop: offset.top,
			scrollLeft: offset.left
		});
	},

	hideEmployerCostsBreakdown: function() {
		return this.get("companySettings.hideCompanyContributions");
	}.property("companySettings.hideCompanyContributions"),

	enrollmentEndDate: function() {
		var dates = [];
		if(this.get('currentEnrollment.endDate')) {
			dates.push(new Date(this.get('currentEnrollment.endDate')));
		}
		if(this.get('currentDentalEnrollment.endDate')) {
			dates.push(new Date(this.get('currentDentalEnrollment.endDate')));
		}
		if(this.get('currentVisionEnrollment.endDate')) {
			dates.push(new Date(this.get('currentVisionEnrollment.endDate')));
		}

		var endDate = new Date(Math.min.apply(null, dates));
		return endDate;
	}.property('currentEnrollment', 'currentDentalEnrollment', 'currentVisionEnrollment'),

	displayEnrollmentEndDate: function() {
		return (this.get('isOEOrSWOn') && this.get('enrollmentEndDate'));
	}.property('isOEOrSWOn', 'enrollmentEndDate'),

	presentMedicalPlan: function() {
		return  this.get('chosenMedicalPlan') || this.get('defaultMedicalPlan');
	}.property('defaultMedicalPlan', 'chosenMedicalPlan'),

	presentDentalPlan: function() {
		return this.get('chosenDentalPlan') || this.get('defaultDentalPlan');
	}.property('defaultDentalPlan', 'chosenDentalPlan'),

	presentVisionPlan: function() {
		return this.get('chosenVisionPlan') || this.get('defaultVisionPlan');
	}.property('defaultVisionPlan', 'chosenVisionPlan'),

	medicalPlanCost: function() {
		var chosenMedicalPlan = this.get('chosenMedicalPlan');
		var defaultMedicalEPlan = this.get('defaultMedicalEPlan');
		var newMedicalCost = this.get('newMedicalCost');

		if (chosenMedicalPlan != null) {
			return newMedicalCost;
		} else if (defaultMedicalEPlan != null) {
			return defaultMedicalEPlan.get('youPremium');
		}

		return 0;
	}.property('newMedicalCost', 'defaultMedicalEPlan', 'chosenMedicalPlan'),

	dentalPlanCost: function() {
		var chosenDentalPlan = this.get('chosenDentalPlan');
		var defaultDentalEPlan = this.get('defaultDentalEPlan');
		var newDentalCost = this.get('newDentalCost');

		if (chosenDentalPlan != null) {
			return newDentalCost;
		} else if (defaultDentalEPlan != null) {
			return defaultDentalEPlan.get('youPremium');
		}

		return 0;
	}.property('newDentalCost', 'defaultDentalEPlan', 'chosenDentalPlan'),

	visionPlanCost: function() {
		var chosenVisionPlan = this.get('chosenVisionPlan');
		var defaultVisionEPlan = this.get('defaultVisionEPlan');
		var newVisionCost = this.get('newVisionCost');

		if (chosenVisionPlan != null) {
			return newVisionCost;
		} else if (defaultVisionEPlan != null) {
			return defaultVisionEPlan.get('youPremium');
		}

		return 0;
	}.property('newVisionCost', 'defaultVisionEPlan', 'chosenVisionPlan'),
});

App.FeetSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Ft.", id: ""}),
		Ember.Object.create({name: "0", id: "0"}),
		Ember.Object.create({name: "1", id: "1"}),
		Ember.Object.create({name: "2", id: "2"}),
		Ember.Object.create({name: "3", id: "3"}),
		Ember.Object.create({name: "4", id: "4"}),
		Ember.Object.create({name: "5", id: "5"}),
		Ember.Object.create({name: "6", id: "6"}),
		Ember.Object.create({name: "7", id: "7"})
	],
});

App.InchesSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "In.", id: ""}),
		Ember.Object.create({name: "0", id: "0"}),
		Ember.Object.create({name: "1", id: "1"}),
		Ember.Object.create({name: "2", id: "2"}),
		Ember.Object.create({name: "3", id: "3"}),
		Ember.Object.create({name: "4", id: "4"}),
		Ember.Object.create({name: "5", id: "5"}),
		Ember.Object.create({name: "6", id: "6"}),
		Ember.Object.create({name: "7", id: "7"}),
		Ember.Object.create({name: "8", id: "8"}),
		Ember.Object.create({name: "9", id: "9"}),
		Ember.Object.create({name: "10", id: "10"}),
		Ember.Object.create({name: "11", id: "11"})
	],
});


App.EmployeeEmployeeinfoController = Ember.ObjectController.extend({
	done: function() {
		App.store.commit();
		this.transitionToRoute('index');
	}
});

App.EmployeeDeclineIndexController = Ember.ObjectController.extend({
	employee: null
});
App.EmployeeDeclineInfoController = _TransactionSavingController.extend(App.ValidationsControllerMixin, {
  employee: null,
	errorText: "",
	attrList: ['firstName', 'lastName', 'numberOfDependents', 'socialSecurity',
		'hireDate', 'maritalStatus', 'homeAddress', 'homeCity', 'homeState',
		'homeZip', 'jobTitle'],

	validateAttributes: function() {
		var model = this.get('content');
		var attrList = this.get('attrList');

		for (var i = 0; i < attrList.get('length'); i++) {
			var attr = attrList.objectAt(i);
			var value = model.get(attr);
			if (typeof(value) == 'object' && !value) {
				return false;
			}
			if (typeof(value) == 'string') {
				value = value ? $.trim(value) : value;
				if (value == "0") {
					continue;
				}
				if (!value || value == '') {
					return false;
				}
			}
		}

		return true;
	},

	isDentalEnrollmentComplete: Ember.computed.equal('currentDentalEnrollment.status', 'complete'),
	isVisionEnrollmentComplete: Ember.computed.equal('currentVisionEnrollment.status', 'complete'),
	save: function() {
		if (this.get('anyErrors')) {
			this.set('errorText', this.get('validationErrorText'));
			return;
		}
		var that = this;
		var model = this.get('content');
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		if (!this.validateAttributes()) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}
		var transitionRoute = "";

		var statusList = ['complete', 'reviewed'];
		if (currentEnrollment && statusList.indexOf(currentEnrollment.get('status')) == -1) {
			currentEnrollment.set('status', 'decline');
		}
		if (currentDentalEnrollment && statusList.indexOf(currentDentalEnrollment.get('status')) == -1) {
			currentDentalEnrollment.set('status', 'decline');
		}
		if (currentVisionEnrollment && statusList.indexOf(currentVisionEnrollment.get('status')) == -1) {
			currentVisionEnrollment.set('status', 'decline');
		}

		this.set('errorText', '');
		var isDirty = model.get('isDirty');
		model.save().then(function(savedModel) {
			this.get('employee.employments').then(function() {
				if (this.get('employee.fullTimeEndDate') && this.get('employeeCobra.administerCOBRA')){
					that.transitionToRoute('employee.declineCobraCoverage');
				}else{
					that.transitionToRoute('employee.decline.attest');
				}
			}.bind(this));
		}.bind(this));
		App.store.commit();

		if (!isDirty) {
			this.transitionToRoute(transitionRoute);
		}
	}
});

App.EmployeeDeclineDentalinfoController = App.EmployeeDeclineInfoController.extend({
	dentalCarrier: Ember.computed.findByProperty('companyHealthCarriers', 'lineOfCoverage', 'dental'),

	save: function() {
		if (this.get('anyErrors')) {
			this.set('errorText', this.get('validationErrorText'));
			return;
		}
		var model = this.get('content');
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		if (!this.validateAttributes()) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}

		this.set('errorText', '');
		var isDirty = model.get('isDirty');
		model.save().then(function() {
			this.transitionToRoute('employee.decline.dentalattest');
		}.bind(this));
		App.store.commit();

		if (!isDirty) {
			this.transitionToRoute(transitionRoute);
		}
	}
});
App.EmployeeDeclineVisioninfoController = App.EmployeeDeclineInfoController.extend({
	visionCarrier: Ember.computed.findByProperty('companyHealthCarriers', 'lineOfCoverage', 'vision'),

	save: function() {
		if (this.get('anyErrors')) {
			this.set('errorText', this.get('validationErrorText'));
			return;
		}
		var model = this.get('content');
		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		if (!this.validateAttributes()) {
			this.set('errorText', 'All fields are required. Please fill in the missing fields.');
			return;
		}

		this.set('errorText', '');
		var isDirty = model.get('isDirty');
		model.save().then(function() {
			this.transitionToRoute('employee.decline.visionattest');
		}.bind(this));
		App.store.commit();

		if (!isDirty) {
			this.transitionToRoute(transitionRoute);
		}
	}
});

App.EmployeeDeclineAttestView = Ember.View.extend({
	willDestroyElement: function() {
		this.get('controller.waivers').forEach(function(waiver) {
			if (waiver.get('isNew') && !waiver.get('isSaving')) {
				waiver.deleteRecord();
			}
		});
	},
});

App.EmployeeDeclineAttestController = _TransactionSavingController.extend({
	errorText: "",
	employee: null,
	enrollments: null,
	signatureObj: null,

	waiverCopies: Ember.computed(function() {
		return this.get('enrollments').map(function(enrollment) {
			var lineOfCoverage = enrollment.get('coverage_type');
			var companyHealthCarrier = this.get('company.healthCarriers').findBy('lineOfCoverage', lineOfCoverage);
			var copy = companyHealthCarrier.get('carrier.copy.employeeDeclineWaiver');

			return Ember.Object.create({
				lineOfCoverage: lineOfCoverage,
				copy: copy,
			});
		}, this);
	}).property('enrollments.@each.coverage_type'),
	waivers: Ember.computed(function() {
		var selfDependent = this.get('selfDependent');

		var enrollmentIdToWaiver = {};
		this.get('selfWaivers').forEach(function(waiver) {
			enrollmentIdToWaiver[waiver.get('enrollment.id')] = waiver;
		});

		return this.get('enrollments').map(function(enrollment) {
			var existingWaiver = enrollmentIdToWaiver[enrollment.get('id')];

			if (existingWaiver) {
				return existingWaiver;
			}

			return App.EnrollmentWaiver.createRecord({
				enrollment: enrollment,
				dependent: selfDependent,
			});
		});
	}).property('enrollments.[]'),
	openFilePickerDecline: function() {
		filepicker.setKey(FILEPICKER_KEY);
		var model = this.get('waiver');
		filepicker.pickAndStore({
				extension: ['.png', '.PNG', '.jpg', '.JPG', '.jpeg', '.JPEG', '.gif', '.GIF', '.pdf', '.doc', '.docx', '.PDF', '.DOC', '.DOCX'],
				container: 'window',
				services:['COMPUTER', 'FACEBOOK', 'GMAIL', 'DROPBOX']
			},
			{location:"S3"},
			function(FPFile){
				model.set('idCardUrl', FPFile[0].url);
			},
			function(FPError){
				// pass
			}
		);
	},

	save: function() {
		var employee = this.get('model');
		var company = employee.get('company');
		var waivers = this.get('waivers');
		var errors = [];
		var noWaiveReasonCoverageArray = [];

		waivers.forEach(function(waiver) {
			errors = errors.concat(waiver.validate());

			if(!waiver.get('waiveReason')) {
				noWaiveReasonCoverageArray.push(waiver.get('capitalizedLineOfCoverage'));
			}
		});

		// If a user waives multiple lines of coverage w/o providing a reason,
		// aggregate the error messages displayed.
		var noWaiveReasonErrorText = humanizeList(noWaiveReasonCoverageArray);
		if (noWaiveReasonErrorText !== "") {
			errors.push("Please select a reason for declining your company's " +
								noWaiveReasonErrorText + " coverage.");
		}

		var signatureObj = this.get('signatureObj');
		if (!signatureObj || !signatureObj.get('signature') || !signatureObj.get('signatureName')) {
			errors.push('You must add your signature to continue.');
		}
		this.set('errors', errors);
		if (!Ember.isEmpty(errors)) {
			return;
		}

		waivers.forEach(function(waiver) {
			waiver.set('signature', signatureObj.get('signature'));
		});


		var ehes = [];
		var medical = false, dental = false, vision = false;

		if (this.get('currentEnrollment.status') == 'decline' ||
			(this.get('currentEnrollment.status') == 'reviewed' && !this.get('currentEnrollment.defaultPlan')))
		{
			ehes.push(this.get('currentEnrollment'));
			medical = true;
		}
		if (this.get('currentDentalEnrollment.status') == 'decline' ||
			(this.get('currentDentalEnrollment.status') == 'reviewed' && !this.get('currentDentalEnrollment.defaultPlan')))
		{
			ehes.push(this.get('currentDentalEnrollment'));
			dental = true;
		}
		if (this.get('currentVisionEnrollment.status') == 'decline' ||
			(this.get('currentVisionEnrollment.status') == 'reviewed' && !this.get('currentVisionEnrollment.defaultPlan')))
		{
			ehes.push(this.get('currentVisionEnrollment'));
			vision = true;
		}

		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
				linesOfCoverage.forEach(function(lineOfCoverage) {
					if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
						var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
						dhes.forEach(function(dhe) {
							if (dhe.get('type') == '+' && (!dhe.get('isEnrollmentComplete') || !dhe.get('qualifyingEvent'))) {
								dhe.deleteRecord();
							}
						});
					}
				});
			}
		});

		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				ehes.forEach(function(ehe) {
					var lineOfCoverage = ehe.get('coverage_type').capitalize();
					if (dependent.get('enrollIn' + lineOfCoverage)) {
						var createRemoveDhe = true;
						var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
						dhes.forEach(function(dhe) {
							if (dhe.get('type') == '-' && zen.compareDates(dhe.get('endDate'), ehe.get('effectiveDate')) <= 0) {
								createRemoveDhe = false;
							}
						});
						if (createRemoveDhe) {
							var dependentEnrollment = App.DependentHealthEnrollment.createRecord();
							dependentEnrollment.set('dependent', dependent);
							dependentEnrollment.set('type', '-');
							dependentEnrollment.set('lineOfCoverage', lineOfCoverage.toLowerCase());
							dependentEnrollment.set('enrollmentStatus', 'decline');
							dependentEnrollment.set('isEnrollmentComplete', true);
							dependentEnrollment.set('employeeHealthEnrollment', ehe);
							dependentEnrollment.set('isActive', true);
							dependentEnrollment.set('endDate', ehe.get('effectiveDate'));
						}
					}
				}.bind(this));
			}
		}.bind(this));

		this.set('errorText', '');

		return signatureObj.save().then(function(authSignatureObj) {
			ehes.forEach(function(ehe) {
				ehe.set('hasSignedWaiver', true);
				ehe.set('authSignature', authSignatureObj);
			});
			return this.saveAndContinue().then(function() {
				$.ajax({
					url: '/custom_api/employeeNotification?action=enrollmentComplete&medical=' + medical + '&dental='+ dental + '&vision=' + vision,
					type: "get",
					async: false,
					success: function(response) {}
				});
				return employee.reload().then(function() {
					this.transitionToRoute('index');
				}.bind(this));
			}.bind(this));
		}.bind(this));
	}
});

App.EmployeeDeclineDeclineCoverageReasonsController = Ember.ObjectController.extend({
	metadataDescriptions: Ember.computed(function() {
		return App.WaiverMetadata.find().filter(function(waiver) {
			return !waiver.get('softDeleted');
		});
	}).property(),
	close: function() {
		this.send("hideModal");
	}
});

App.EmployeeDeclineDentalattestView = Ember.View.extend({
	willDestroyElement: function() {
		var waiver = this.get('controller.waiver');

		if (waiver.get('isNew') && !waiver.get('isSaving')) {
			waiver.deleteRecord();
		}
	},
});

App.SelfWaiverControllerMixin = Ember.Mixin.create({
	waiver: Ember.computed(function() {
		var enrollment = this.get('enrollment');
		var selfDependent = this.get('selfDependent');

		var enrollmentIdToWaiver = {};
		this.get('selfWaivers').forEach(function(waiver) {
			enrollmentIdToWaiver[waiver.get('enrollment.id')] = waiver;
		});

		var existingWaiver = enrollmentIdToWaiver[enrollment.get('id')];
		if (existingWaiver) {
			return existingWaiver;
		}
		return App.EnrollmentWaiver.createRecord({
			enrollment: enrollment,
			dependent: selfDependent,
		});
	}).property('dependents', 'enrollment'),
});

App.EmployeeDeclineDentalattestController = _TransactionSavingController.extend(App.SelfWaiverControllerMixin, {
	signatureObj: null,
	errors: [],
	save: function() {
		var waiver = this.get('waiver');
		var errors = waiver.validate();

		var enrollment = this.get('enrollment');
		var signatureObj = this.get('signatureObj');
		if (!signatureObj || !signatureObj.get('signature') || !signatureObj.get('signatureName')) {
			errors.push("Please add your signature");
		}
		this.set('errors', errors);
		if (!Ember.isEmpty(errors)) {
			return;
		}

		var employee = this.get('model');
		// If the EE hadn't had coverage before and declined coverage in OE, the EHE status will be 'reviewed'
		// already, in which case we want to preserve it to prevent unnecessary wq generation.
		if (!(employee.get('dentalApprovalStatus') === 'decline' && enrollment.get('status') === 'reviewed')) {
			enrollment.set('status', 'decline');
		}
		enrollment.set('hasSignedWaiver', true);
		waiver.set('signature', signatureObj.get('signature'));

		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				if(dependent.get('dependentDentalEnrollments.length')) {
					var dhes = dependent.get('dependentDentalEnrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '+' && (!dhe.get('isEnrollmentComplete') || !dhe.get('qualifyingEvent'))) {
							dhe.deleteRecord();
						}
					});
				}
			}
		});

		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				var ehe = enrollment;
				if (dependent.get('enrollInDental')) {
					var createRemoveDhe = true;
					var dhes = dependent.get('dependentDentalEnrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '-' && zen.compareDates(dhe.get('endDate'), ehe.get('effectiveDate')) <= 0) {
							createRemoveDhe = false;
						}
					});
					if (createRemoveDhe) {
						var dependentEnrollment = App.DependentHealthEnrollment.createRecord();
						dependentEnrollment.set('dependent', dependent);
						dependentEnrollment.set('type', '-');
						dependentEnrollment.set('lineOfCoverage', ehe.get('coverage_type'));
						dependentEnrollment.set('enrollmentStatus', 'decline');
						dependentEnrollment.set('isEnrollmentComplete', true);
						dependentEnrollment.set('employeeHealthEnrollment', ehe);
						dependentEnrollment.set('isActive', true);
						dependentEnrollment.set('endDate', ehe.get('effectiveDate'));
					}
				}
			}
		}.bind(this));

		var url = '/custom_api/employeeNotification?action=enrollmentComplete&medical=false&dental=true&vision=false';
		if (enrollment.get('isSwitchCarrierEnrollment')) {
			url += '&isSwitchCarrierEnrollment=true';
		}
		else if (!enrollment.get('isOpenEnrollment')) {
			var employee = enrollment.get('employee');
			if (!this.get('shortCircuitOEStats.dentalSCOEStepsComplete')) {
				// This will be taken care during Short circuit OE realization.
				employee.set('dentalApprovalStatus', 'decline');
				// We don't set the enrollment.isActive flag, since it is taken care of in the backend by the 'employee_approval_hook'.
				// See NERD-9284 for more details.
			}
		}
		return signatureObj.save().then(function(authSignatureObj) {
			enrollment.set('authSignature', authSignatureObj);
			this.saveAndContinue().then(function() {
				$.ajax({
					url: url,
					type: "get",
					success: function(response) {}
				});
				this.transitionToRoute('index');
			}.bind(this));
		}.bind(this));
	}
});


App.EmployeeDeclineVisionattestView = Ember.View.extend({
	willDestroyElement: function() {
		var waiver = this.get('controller.waiver');

		if (waiver.get('isNew') && !waiver.get('isSaving')) {
			waiver.deleteRecord();
		}
	},
});

App.EmployeeDeclineVisionattestController = _TransactionSavingController.extend(App.SelfWaiverControllerMixin, {
	signatureObj: null,
	errors: [],
	save: function() {
		var waiver = this.get('waiver');
		var errors = waiver.validate();

		var enrollment = this.get('enrollment');
		var signatureObj = this.get('signatureObj');
		if (!signatureObj || !signatureObj.get('signature') || !signatureObj.get('signatureName')) {
			errors.push("Please add your signature");
		}
		this.set('errors', errors);
		if (!Ember.isEmpty(errors)) {
			return;
		}

		var employee = this.get('model');
		// If the EE hadn't had coverage before and declined coverage in OE, the EHE status will be 'reviewed'
		// already, in which case we want to preserve it to prevent unnecessary wq generation.
		if (!(employee.get('visionApprovalStatus') === 'decline' && enrollment.get('status') === 'reviewed')) {
			enrollment.set('status', 'decline');
		}

		enrollment.set('hasSignedWaiver', true);
		waiver.set('signature', signatureObj.get('signature'));

		var dependents = this.get('dependents');
		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				if(dependent.get('dependentVisionEnrollments.length')) {
					var dhes = dependent.get('dependentVisionEnrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '+' && (!dhe.get('isEnrollmentComplete') || !dhe.get('qualifyingEvent'))) {
							dhe.deleteRecord();
						}
					});
				}
			}
		});

		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				var ehe = enrollment;
				if (dependent.get('enrollInVision')) {
					var createRemoveDhe = true;
					var dhes = dependent.get('dependentVisionEnrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '-' && zen.compareDates(dhe.get('endDate'), ehe.get('effectiveDate')) <= 0) {
							createRemoveDhe = false;
						}
					});
					if (createRemoveDhe) {
						var dependentEnrollment = App.DependentHealthEnrollment.createRecord();
						dependentEnrollment.set('dependent', dependent);
						dependentEnrollment.set('type', '-');
						dependentEnrollment.set('lineOfCoverage', ehe.get('coverage_type'));
						dependentEnrollment.set('enrollmentStatus', 'decline');
						dependentEnrollment.set('isEnrollmentComplete', true);
						dependentEnrollment.set('employeeHealthEnrollment', ehe);
						dependentEnrollment.set('isActive', true);
						dependentEnrollment.set('endDate', ehe.get('effectiveDate'));
					}
				}
			}
		}.bind(this));

		var url = '/custom_api/employeeNotification?action=enrollmentComplete&medical=false&dental=false&vision=true';
		if (enrollment.get('isSwitchCarrierEnrollment')) {
			url += '&isSwitchCarrierEnrollment=true';
		}
		else if (!enrollment.get('isOpenEnrollment')) {
			if (!this.get('shortCircuitOEStats.visionSCOEStepsComplete')) {
				employee.set('visionApprovalStatus', 'decline');
				// We don't set the enrollment.isActive flag, since it is taken care of in the backend by the 'employee_approval_hook'
				// See NERD-9284 for more details.
			}
		}
		return signatureObj.save().then(function(authSignatureObj) {
			enrollment.set('authSignature', authSignatureObj);
			this.saveAndContinue().then(function() {
				$.ajax({
					url: url,
					type: "get",
					success: function(response) {}
				});
				this.transitionToRoute('index');
			}.bind(this));
		}.bind(this));
	}
});


/* MEDICAL */

_DependentDeclineMixin = Ember.Mixin.create({
	declineDependentCoverage: function(dependents, ehe) {
		var lineOfCoverage = ehe.get('coverage_type').capitalize();

		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '+' && (!dhe.get('isEnrollmentComplete') || !dhe.get('qualifyingEvent'))) {
							dhe.deleteRecord();
						}
					});
				}
			}
		});

		dependents.forEach(function(dependent) {
			if(!dependent.get('isSelf')) {
				if (dependent.get('enrollIn' + lineOfCoverage)) {
					var createRemoveDhe = true;
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '-' && zen.compareDates(dhe.get('endDate'), ehe.get('effectiveDate')) <= 0) {
							createRemoveDhe = false;
						}
					});
					if (createRemoveDhe) {
						var dependentEnrollment = App.DependentHealthEnrollment.createRecord();
						dependentEnrollment.set('dependent', dependent);
						dependentEnrollment.set('type', '-');
						dependentEnrollment.set('lineOfCoverage', ehe.get('coverage_type'));
						dependentEnrollment.set('enrollmentStatus', 'decline');
						dependentEnrollment.set('isEnrollmentComplete', true);
						dependentEnrollment.set('employeeHealthEnrollment', ehe);
						dependentEnrollment.set('isActive', true);
						dependentEnrollment.set('endDate', ehe.get('effectiveDate'));
					}
				}
			}
		});
	}
});







_ShortCircuitUtilsForPlanSelector = Ember.Mixin.create({
	getOverallShortCircuitPlanType: function(availablePlans) {
		var shortCircuitPlanType = null;
		var hasShortCircuitPlan = false;
		var hasShortCircuitPlanWithBenefits = false;
		var hasShortCircuitPlanWithRates = false;
		var hasRealPlan = false;
		if (!availablePlans) {
			return shortCircuitPlanType; // will be null
		}
		availablePlans.forEach(function(plan) {
			if(plan.get('isShortCircuitPlan')) {
				hasShortCircuitPlan = true;
				if (plan.get('shortCircuitPlanType') == 'A') {
					hasShortCircuitPlanWithBenefits = hasShortCircuitPlanWithRates = true;
				}
				else if (plan.get('shortCircuitPlanType') == 'R') {
					hasShortCircuitPlanWithRates = true;
				}
				else if (plan.get('shortCircuitPlanType') == 'B') {
					hasShortCircuitPlanWithBenefits = true;
				}
			}
			else {
				hasRealPlan = true;
			}
		}.bind(this));
		// Don't change ordering of if-else.
		if (hasShortCircuitPlan) {
			shortCircuitPlanType = 'N';
		}
		if (hasShortCircuitPlanWithBenefits) {
			shortCircuitPlanType = 'B';
		}
		if (hasShortCircuitPlanWithRates) {
			shortCircuitPlanType = 'R';
		}
		if (hasShortCircuitPlanWithBenefits && hasShortCircuitPlanWithRates) {
			shortCircuitPlanType = 'A';
		}
		if (hasShortCircuitPlan && hasRealPlan) {
			shortCircuitPlanType = 'A';
		}
		return shortCircuitPlanType;
	},
	calculateShouldShowQuoteDependents: function(availablePlans) {
		// If there are no plans available, then it is same as a normal mode - show quote dependents.
		if (!availablePlans) {
			return true;
		}
		var shouldShowQuoteDependents = false;
		availablePlans.forEach(function(plan) {
			if (plan.get('shortCircuitPlanType') == 'A' || plan.get('shortCircuitPlanType') == 'R') {
				shouldShowQuoteDependents = true;
				return true;
			}
		}.bind(this));
		return shouldShowQuoteDependents;
	},
});

_BundlingControllerMixin = zen._BundlingControllerMixin = Ember.Mixin.create({
	MEDICAL: 'medical',
	DENTAL: 'dental',
	VISION: 'vision',
	currLineOfCoverage : null,
	/**
	 * Returns an object containing text needed to communicate to
	 * an EE that he/she has selected a bundled plan in a previous line of coverage
	 */
	getYouSelectedBundledPriorText: function() {
		// TODO (chenefits): Use the enums defined above
		if (this.get('currLineOfCoverage') === 'DENTAL') {
			return this.get('hasSelectedBundledMedical');
		}
		else if (this.get('currLineOfCoverage') === 'VISION') {
			return this.get('hasSelectedBundledDental');
		}
	}.property(),
	/**
	 * Returns an object containing text needed to communicate to
	 * an EE that he/she has declined a plan that bundles in a previous line
	 * of coverage
	 */
	getYouDeclinedBundledPriorText: function() {
		// TODO (chenefits): Use the enums defined above
		if (this.get('currLineOfCoverage') === 'DENTAL') {
			return this.get('hasDeclinedBundledMedical');
		}
		else if (this.get('currLineOfCoverage') === 'VISION') {
			return this.get('hasDeclinedBundledDental');
		}
	}.property(),
	/**
	 * Check if selected plan bundles with a plan
	 * in any other line of coverage
	 * @param  {PlanObject}  plan
	 * @return {Boolean}     True - does bundle with a plan in any other line
	 */
	hasSelectedBundledPlan: function(plan, currLine) {
		var bundledLinesForPlan = this.bundledLinesForPlan(plan) || [];

		var bundles = this.get('bundles');
		var selectedCarrierId = plan.get('stateCarrier.id');

		function _carrierIsInPlans(plans, carrierId) {
			if (plans) {
				for (var i = 0; i < plans.length; i++) {
					if (plans[i].get('stateCarrier.id') === carrierId) {
						return true;
					}
				}
			}
			return false;
		}

		for (var i = 0; i < bundledLinesForPlan.length; i++) {
			var line = bundledLinesForPlan[i];
			if (line.toLowerCase() !== currLine.toLowerCase()) {
				if (line === this.MEDICAL) {
					var medicalPlans = bundles.medicalPlans.content || [];
					if (_carrierIsInPlans(medicalPlans, selectedCarrierId)) {
						return true;
					}
				}
				else if (line === this.DENTAL) {
					var dentalPlans = bundles.dentalPlans.content || [];
					if (_carrierIsInPlans(dentalPlans, selectedCarrierId)) {
						return true;
					}
				}
				else if (line === this.VISION) {
					var visionPlans = bundles.visionPlans.content || [];
					if (_carrierIsInPlans(visionPlans, selectedCarrierId)) {
						return true;
					}
				}
			}
		}
		return false;
		// return this.bundledLinesForPlan(plan).length > 1;
	},
	/**
	 * Returns all the lines of coverage that plan object is bundled
	 * with as an array of strings
	 * e.g. ['medical', 'dental', 'vision']
	 */
	bundledLinesForPlan: function(plan) {
		var carrierId = plan.get('stateCarrier.id');
		return this.get('bundledLinesForCarrier').bind(this)(carrierId);
	},
	/**
	 * Returns an array of strings for all the lines
	 * that this carrier bundles together
	 * E.g. ['Medical', 'Dental', 'Vision']
	 */
	bundledLinesForCarrier: function(carrierId) {
		var bundledLines = {};
		var carrierToBundleMap = this.get('bundles').carrierToBundleMap;
		var carrierBundles = carrierToBundleMap[carrierId] || [];

		carrierBundles.forEach(function(bundle) {
			if (bundle.get('isMedicalBundled')) {
				bundledLines['medical'] = true;
			}
			if (bundle.get('isDentalBundled')) {
				bundledLines['dental'] = true;
			}
			if (bundle.get('isVisionBundled')) {
				bundledLines['vision'] = true;
			}
		});

		return Object.keys(bundledLines);
	},
	/**
	 * Returns the name of the carrier that offers
	 * the plan
	 */
	selectedCarrierName: function(plan) {
		var carrierId = plan.get('stateCarrier.id');
		return this.get('bundles').carriers[carrierId];
	},
	/**
	 * Returns the string, 'medical'. Can be used
	 * to specify what the current line of coverage is.
	 */
	selectedMedical: function() {
		return this.MEDICAL;
	}.property(),
	/**
	 * Returns the string, 'dental'. Can be used
	 * to specify what the current line of coverage is.
	 */
	selectedDental: function() {
		return this.DENTAL;
	}.property(),
	/**
	 * Returns an array of strings specifying
	 * all the lines that selected plan is bundled with.
	 * Lines are bundled if:
	 * 1. The carrier has plan bundling rules for stated lines
	 * of coverage
	 * 2. Plans from that carrier are offered in those lines
	 *
	 * @param  {Plan object} plan     Selected plan
	 * @param  {string} currLine -- 'medical', 'dental', 'vision'
	 * @return {array of strings}
	 */
	bundledLinesForPlanHelper: function(plan, currLine) {
		var bundledPlans = this.get('bundles').bundledPlans;
		var linesOfCoverage = ['MEDICAL', 'DENTAL', 'VISION'];
		var allBundledLines = [];
		var computedBundles = bundledPlans[plan.get('stateCarrier.id')] || [];
		for (var i = 0; i < computedBundles.length; i++) {
			// Computed bundle specifies the plans that are bundled together,
			// organized by line of coverage
			var computedBundle = computedBundles[i];
			// Search to see if the selected plan is part of a bundle
			var planIsPartOfBundle = computedBundle[currLine.toUpperCase()].filter(function(cPlans) {
				return cPlans.get('id') === plan.get('id');
			}).length;
			if (planIsPartOfBundle) {
				linesOfCoverage.forEach(function(loc) {
					if (computedBundle[loc].length) {
						allBundledLines.push(loc.toLowerCase());
					}
				});
				return allBundledLines;
			}
		}
		return [];
	},
	/**
	 * Returns a string in human-readable format of all the lines
	 * of coverage that are bundled with a given plan
	 */
	allBundledLinesOfCoverage: function(plan, selectedLine) {
		return humanizeList(this.get('bundledLinesForPlanHelper').bind(this)(plan, selectedLine));
	},
	/**
	 * Returns a string in human-readable format of all the lines
	 * of coverage that are bundled with a given plan, excluding
	 * the plan's line of coverage.
	 */
	allOtherBundledLinesOfCoverage: function(plan, selectedLine) {
		return humanizeList(this.get('bundledLinesForPlanHelper').bind(this)(plan, selectedLine).removeObject(selectedLine));
	},
	/**
	 * For a given line of coverage, returns the number
	 * of carriers who are offering bundled plans
	 */
	numCarriersWithBundledPlans: function() {
		var computedBundles = this.get('bundles').bundledPlans;
		return Object.keys(computedBundles).length;
	},
	hasAtLeastOneCarrierWithoutBundledPlans: function() {
		return this.get('numCarriersWithBundledPlans').bind(this)() < Object.keys(this.get('bundles').carrierToBundleMap).length;
	}.property(),
	hasBundledPlans: function() {
		// Used by declineOverviews.
		var bundles = this.get('bundles');
		var currLine = this.get('currLineOfCoverage');

		var planMapper = {
			'MEDICAL': bundles.medicalPlans.content,
			'DENTAL': bundles.dentalPlans.content,
			'VISION': bundles.visionPlans.content,
		};
		var currLineOfPlans = planMapper[currLine];
		for (var i = 0; i < currLineOfPlans.length; i++) {
			if ((this.get('hasSelectedBundledPlan').bind(this))(currLineOfPlans[i], currLine)) {
				return true;
			}
		}
		return false;
	}.property(),
	bundledLinesForCarriers: function() {
		// Used by declineOverviews
		// Construct an array of carrier objects, where each carrier object
		// contains the following information:
		//   - carrier name
		//   - all bundled lines bundled for carrier
		//   - all other lines bundled for carrier
		var carrierNames = this.get('bundles').carriers;
		var bundledPlans = this.get('bundles').bundledPlans;

		var carrierObjects = [];
		var linesOfCoverage = ['MEDICAL', 'DENTAL', 'VISION'];
		var currLineOfCoverage = this.get('currLineOfCoverage');

		for (var carrierId in bundledPlans) {
			var allBundledLines = [];
			var allOtherBundledLines = [];
			bundledPlans[carrierId].forEach(function(bundle) {
				// A carrier may have multiple plan bundles.
				// These temp variables are used to construct intermediate
				// strings
				// e.g.
				// ['medical', 'dental', 'vision'] --> 'medical, dental, and vision'
				// Whereas the all-variables combine these intermediate strings:
				// ['medical, dental, and vision', 'life and disability'] -->
				// 'medical, dental, and vision, and life and disablity'
				var tempAllBundledLines = [];
				var tempAllOtherBundledLines = [];

				linesOfCoverage.forEach(function(loc) {
					if (bundle[loc].length) {
						tempAllBundledLines.push(loc.toLowerCase());
						if (loc !== currLineOfCoverage) {
							tempAllOtherBundledLines.push(loc.toLowerCase());
						}
					}
				});
				allBundledLines.push(humanizeList(tempAllBundledLines));
				allOtherBundledLines.push(humanizeList(tempAllOtherBundledLines));
			});

			var carrierObj = {
				'name': carrierNames[carrierId],
				'allBundledLines': humanizeList(allBundledLines),
				'allOtherBundledLines': humanizeList(allOtherBundledLines),
			};

			carrierObjects.push(carrierObj);
		}
		return carrierObjects;
	}.property(),
	hasDisabledPlans: function() {
		var hasDisabled = false;
		var plans = this.get('plans') || [];
		plans.forEach(function(plan) {
			if (plan.isDisabled) {
				hasDisabled = true;
			}
		});
		return hasDisabled;
	}.property('model.plans.@each.isDisabled'),
});

var _EmployeeDependentsEnrollmentMixin = Ember.Mixin.create({
	// Depends on the existance of `dependents` attr.
	nonSelfDependents: Ember.computed.filterBy('dependents', 'isSelf', false),
	selectedQuoteDependents: Ember.computed.filterBy('nonSelfDependents', 'isSelected', true),
	hasSelectedQuoteDependents: Ember.computed.gt('selectedQuoteDependents.length', 0),

	updateDependentsCheckboxStatus: function (lineOfCoverage) {
		var checkboxStatusPropertyKey = lineOfCoverage + 'CheckBoxStatus';
		var nonSelfDependents = this.get('nonSelfDependents');
		nonSelfDependents.forEach(function(dependent) {
			var checkboxStatus = dependent.get(checkboxStatusPropertyKey);
			var isSelected = dependent.get('isSelected');

			// Only set when checkbox status is not equal to is selected. Otherwise, don't overwrite the computed property.
			if (checkboxStatus != isSelected) {
				dependent.set(checkboxStatusPropertyKey, isSelected);
			}
		});
	},
	createAndSaveDependentHealthEnrollments: function (ehes) {
		var nonSelfDependents = this.get('nonSelfDependents');
		nonSelfDependents.forEach(function(dependent) {
			ehes.forEach(function(ehe) {
				var dhes = dependent.get('dependent' + ehe.get('coverage_type').capitalize() + 'Enrollments');
				var checkBoxStatus = dependent.get(ehe.get('coverage_type') + 'CheckBoxStatus');
				var enrollInFlag = dependent.get('enrollIn' + ehe.get('coverage_type').capitalize());

				var createAddDhe = false;
				var createRemoveDhe = false;
				var addDhe = null;
				var removeDhe = null;
				var currEffectiveDate = ehe.get('effectiveDate');

				// If EE want to enroll the dependent
				if (checkBoxStatus) {
					// Check all existing DHEs
					dhes.forEach(function(dhe) {
						// If current one is remove DHE
						if (dhe.get('type') == '-') {
							var comp = (removeDhe ? zen.compareDates(removeDhe.get('endDate'), dhe.get('endDate')) : 0);
							// If no last known remove DHE,
							// or last known remove DHE takes effect later than this one,
							// or two DHEs have same end date but current DHE was created later
							if (!removeDhe || (comp > 0) || (!comp && removeDhe.get('id') < dhe.get('id'))) {
								// Use current DHE as last known remove DHE, if it is complete. Otherwise we delete it.
								if (dhe.get('isEnrollmentComplete')) {
									removeDhe = dhe;
								}
								else {
									dhe.deleteRecord();
								}
							}
						}

						// If current one is add DHE
						if (dhe.get('type') == '+') {
							var comp = (addDhe ? zen.compareDates(addDhe.get('effectiveStartDate'), dhe.get('effectiveStartDate')) : 0);
							// If no last known add DHE,
							// or last knwon add DHE take effect later than this one,
							// or two DHEs has same effective date but current DHE was created later
							if (!addDhe || (comp > 0) || (!comp && addDhe.get('id') < dhe.get('id'))) {
								// Use current DHE as last known add DHE
								addDhe = dhe;
							}
						}
					});

					// If remove DHE exists or
					// enroll in flag is false and no add dhe
					if (removeDhe || (!enrollInFlag && !addDhe)) {
						// If no add DHE exits or
						// remove DHE created later than add DHE
						if (!addDhe || removeDhe.get('id') > addDhe.get('id')) {
							// create new add DHE
							createAddDhe = true;
						}
						// If not creating add DHE but current add DHE takes effect later, still create new DHE
						if (!createAddDhe && zen.compareDates(addDhe.get('effectiveStartDate'), currEffectiveDate) > 0) {
							createAddDhe = true;
						}
					}
				} else {
					dhes.forEach(function(dhe) {
						if (dhe.get('type') == '+') {
							var comp = (addDhe ? zen.compareDates(addDhe.get('effectiveStartDate'), dhe.get('effectiveStartDate')) : 0);
							if (!addDhe || (comp > 0) || (!comp && addDhe.get('id') < dhe.get('id'))) {
								if (dhe.get('isEnrollmentComplete')) {
									addDhe = dhe;
								}
								else {
									dhe.deleteRecord();
								}
							}
						}

						if (dhe.get('type') == '-') {
							var comp = (removeDhe ? zen.compareDates(removeDhe.get('endDate'), dhe.get('endDate')) : 0);
							if (!removeDhe || (comp > 0) || (!comp && removeDhe.get('id') < dhe.get('id'))) {
								removeDhe = dhe;
							}
						}
					});

					if (addDhe || (enrollInFlag && !removeDhe)) {
						if (!removeDhe || addDhe.get('id') > removeDhe.get('id')) {
							createRemoveDhe = true;
						}
						if (!createRemoveDhe && zen.compareDates(removeDhe.get('endDate'), currEffectiveDate) > 0) {
							createRemoveDhe = true;
						}
					}
				}

				if (createAddDhe || createRemoveDhe) {
					var type;
					var enrollmentStatus;

					if (createAddDhe) {
						type = '+';
						enrollmentStatus = 'begin';
					}
					else {
						type = '-';
						enrollmentStatus = 'decline';
					}

					var dependentEnrollment = App.DependentHealthEnrollment.createRecord({
						dependent: dependent,
						type: type,
						lineOfCoverage: ehe.get('coverage_type'),
						enrollmentStatus: enrollmentStatus,
						isEnrollmentComplete: false,
						employeeHealthEnrollment: ehe,
						isActive: true,
					});

					if (createAddDhe) {
						dependentEnrollment.set('effectiveStartDate', ehe.get('effectiveDate'));
					}
					else {
						dependentEnrollment.set('endDate', ehe.get('effectiveDate'));
					}
				}
			}.bind(this));
		}.bind(this));
	}
});

/**
 * Shows a modal explaining to the EE that he/she has selected a bundled plan &
 * what that implies.
 */
App.EmployeeSelectedbundledplanmodalController = Ember.ObjectController.extend(_BundlingControllerMixin, {
	cancel: function() {
		this.send('hideModal');
		// Reject promise
		(this.get('reject') || Ember.K)();
	},
	continue: function(successCallback) {
		this.send('hideModal');
		// Move on to next page
		var val = successCallback.call(this.get('planSelectorController'), this.get('plan'));
		// Resolve promise with the return value
		(this.get('resolve') || Ember.K)(val);
	}
});



App.EmployeePlanselectorView = Ember.View.extend({
	templateName: 'medicalenrollment'
});

App.MedicalEnrollmentView = Ember.View.extend({
	didInsertElement: function() {
		$('.box.employee a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('note orange') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});


App.EmployeePediatricdentalrideroverviewController = Ember.ObjectController.extend({

});

App.EmployeePlanoverviewController = _TransactionSavingController.extend(_EmployeeDependentsEnrollmentMixin, {
	enroll: function() {
		var plan = this.get('plan');
		var riders = this.get('plan.riders');
		var employee = this.get('employee');
		var company = this.get('company');

		var currentEnrollment = this.get('currentEnrollment');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var isInitialEnrollment = currentEnrollment.get('isInitialEnrollment');
		var isOpenEnrollment = currentEnrollment.get('isOpenEnrollment');
		var isSwitchCarrierEnrollment = currentEnrollment.get('isSwitchCarrierEnrollment');

		if (riders) {
			currentEnrollment.set('healthRiders', riders);
		}

		var previouslySelectedPlan = currentEnrollment.get('medicalPlan');
		currentEnrollment.set('medicalPlan', plan.get('plan'));
		currentEnrollment.set('companyHealthPlan', plan.get('companyHealthPlan'));
		if (currentEnrollment.get('status') == 'complete') {
			var selectedCarrier = previouslySelectedPlan.get('stateCarrier');
			var planCarrier = plan.get('stateCarrier');
			if (selectedCarrier != planCarrier) {
				// Reset the enrollment status only if the employee is choosing a plan from a different carrier
				// after they have completed their enrollment
				currentEnrollment.set('status', 'begin');
			}
		}
		else {
			// Check if the EE had previously declined coverage and reset approval status if so.
			if (employee.get('medicalApprovalStatus') == 'decline' || currentEnrollment.get('status') == 'decline') {
				if (isInitialEnrollment) {
					employee.set('medicalApprovalStatus', null);
				}
				currentEnrollment.set('hasSignedWaiver', false);
			}
			currentEnrollment.set('status', 'begin');
		}

		this.updateDependentsCheckboxStatus('medical');
		this.createAndSaveDependentHealthEnrollments([currentEnrollment]);

		return this.saveAndContinue().then(function() {
			// The Notification URL might return empty string in which case we shouldn't notify.
			if (isInitialEnrollment && this.getNotificationURL()) {
				$.ajax({
					url: this.getNotificationURL(),
					type: "get",
					success: function(response) {
					}
				});
			}

			this.send('hideModal');
			// EE is not dirty in ember data. SO, we have to reload to reflect server side changes.
			return employee.reload();
		}.bind(this)).then(function() {
			if (currentDentalEnrollment && currentDentalEnrollment.get('isEnrollmentOngoing')) {
				if (currentDentalEnrollment.get('isOpenEnrollment')) {
					return this.transitionToRoute('employee.dentalopenenrollment');
				}
				else {
					return this.transitionToRoute('employee.dentalplanselector');
				}
			}
			if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
				if (currentVisionEnrollment.get('isOpenEnrollment')) {
					return this.transitionToRoute('employee.visionopenenrollment');
				}
				else {
					return this.transitionToRoute('employee.visionplanselector');
				}
			}

			return this.transitionToRoute('employee.enrollmentsummary');
		}.bind(this));
	},
	getNotificationURL: function() {
		return '/custom_api/employeeNotification?action=planSelected';
	},
});

App.EmployeescoeoldplanoverviewController = App.EmployeePlanoverviewController.extend({
	enroll: function() {
		// This is of type EPlan. EPlan will have isOldPlan set for short circuit OE if the current plan is from
		// the previous inactive enrollment.
		var plan = this.get('plan');
		var currentEnrollment = this.get('currentEnrollment');
		var employee = this.get('employee');
		if (plan.get('isOldPlan')) {
			// Reset the selected plan from ehe and employee. Then set the old plan to the current plan.
			currentEnrollment.set('oldPlan', plan.get('id'));
			currentEnrollment.set('oldCompanyHealthPlan', plan.get('companyHealthPlan'));
			return this.saveAndContinue().then(function() {
				this.send('hideModal');
				return this.transitionToRoute('employee.scoenewplanselector');
			}.bind(this));
		}
		else {
			currentEnrollment.set('oldPlan', null);
			return this._super();
		}
	},
	getNotificationURL: function() {
		return '';
	}
});

App.EmployeescoenewplanoverviewController = App.EmployeePlanoverviewController.extend({
	getNotificationURL: function() {
		return '/custom_api/employeeNotification?action=shortCircuitOEPlanSelected';
	}
});

App.EmployeescoeoldplanoverviewView = Ember.View.extend({
	templateName: 'employee/planoverview'
});

App.EmployeescoenewplanoverviewView = Ember.View.extend({
	templateName: 'employee/planoverview'
});

App.EmployeeDeclineoverviewController = App.EmployeePlanoverviewController.extend(_BundlingControllerMixin, {
	needs: ['employee/planselector', 'employee/openenrollment'],

	// We need this alias for _BundlingControllerMixin to access currLineOfCoverage.
	currLineOfCoverage: Ember.computed.oneWay('model.currLineOfCoverage'),

	content: function() {
		var o = Ember.Object.create({
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment')
		});

		return o;
	},
	decline: function() {
		var controller = this.get('controllers.employee/planselector');
		controller.decline(this.get('content'));
	},
	looksGood: function(action) {
		var controller = this.get('controllers.employee/openenrollment');
		controller.looksGood(action, this.get('content'));
	},
});

App.EmployeeHsaCancellationController = Ember.ObjectController.extend();

App.EmployeeOpenenrollmentView = Ember.View.extend({
	templateName: 'medicalenrollment'
});

App.DentalEnrollmentView = Ember.View.extend({
	didInsertElement: function() {
		$('a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('class="note orange"') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});

App.EmployeeDentalplanoverviewController = _TransactionSavingController.extend(_EmployeeDependentsEnrollmentMixin, {
	enroll: function() {
		// Enroll in the company dental plan and transition to
		// vision plan selector
		var plan = this.get('plan');
		var employee = this.get('employee');
		var company = this.get('company');
		var currentDentalEnrollment = this.get('currentDentalEnrollment');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var previouslySelectedPlan = currentDentalEnrollment.get('dentalPlan');
		currentDentalEnrollment.set('dentalPlan', plan.get('plan'));
		currentDentalEnrollment.set('companyHealthPlan', plan.get('companyHealthPlan'));
		if (currentDentalEnrollment.get('status') == 'complete') {
			var selectedCarrier = previouslySelectedPlan.get('stateCarrier');
			var planCarrier = plan.get('stateCarrier');
			if (selectedCarrier != planCarrier) {
				// Reset the enrollment status only if the employee is choosing a plan from a different carrier
				// after they have completed their enrollment
				currentDentalEnrollment.set('status', 'begin');
			}
		}
		else {
			// Check if the EE had previously declined coverage and reset approval status if so.
			if (employee.get('dentalApprovalStatus') == 'decline' || currentDentalEnrollment.get('status') == 'decline') {
				if (currentDentalEnrollment.get('isInitialEnrollment')) {
					employee.set('dentalApprovalStatus', null);
				}
				currentDentalEnrollment.set('hasSignedWaiver', false);
			}
			currentDentalEnrollment.set('status', 'begin');
		}

		this.updateDependentsCheckboxStatus('dental');
		this.createAndSaveDependentHealthEnrollments([currentDentalEnrollment]);

		return this.saveAndContinue().then(function() {
			this.send('hideModal');

			// EE is not dirty in ember data. SO, we have to reload to reflect server side changes.
			return employee.reload();
		}.bind(this)).then(function() {
			if (currentVisionEnrollment && currentVisionEnrollment.get('isEnrollmentOngoing')) {
				if (currentVisionEnrollment.get('isOpenEnrollment')) {
					return this.transitionToRoute('employee.visionopenenrollment');
				}
				else {
					return this.transitionToRoute('employee.visionplanselector');
				}
			}

			return this.transitionToRoute('employee.enrollmentsummary');
		}.bind(this));
	},
	close: function() {
		this.send('hideModal');
	}
});

App.EmployeeScoeolddentalplanoverviewController = App.EmployeeDentalplanoverviewController.extend({
	enroll: function() {
		// This is of type EDentalPlan. EDentalPlan will have isOldPlan set for short circuit OE if the current plan is from
		// the previous inactive enrollment.
		var plan = this.get('plan');
		var currentEnrollment = this.get('currentDentalEnrollment');
		var employee = this.get('employee');
		if (plan.get('isOldPlan')) {
			currentEnrollment.set('oldPlan', plan.get('id'));
			return this.saveAndContinue().then(function() {
				this.send('hideModal');
				return this.transitionToRoute('employee.scoenewdentalplanselector');
			}.bind(this));
		}
		else {
			currentEnrollment.set('oldPlan', null);
			return this._super();
		}
	}
});

App.EmployeeScoeolddentalplanoverviewView = Ember.View.extend({
	templateName: 'employee/dentalplanoverview'
});

App.EmployeeDentaldeclineoverviewController = App.EmployeeDentalplanoverviewController.extend(_BundlingControllerMixin, {
	needs: ['employee/dentalplanselector', 'employee/dentalopenenrollment'],

	// We need this alias for _BundlingControllerMixin to access currLineOfCoverage.
	currLineOfCoverage: Ember.computed.oneWay('model.currLineOfCoverage'),

	content: function() {
		var o = Ember.Object.create({
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment')
		});

		return o;
	},
	decline: function() {
		var controller = this.get('controllers.employee/dentalplanselector');
		controller.decline(this.get('content'));
	},
	looksGood: function(action) {
		var controller = this.get('controllers.employee/dentalopenenrollment');
		controller.looksGood(action, this.get('content'));
	}
});

App.VisionEnrollmentView = Ember.View.extend({
	didInsertElement: function() {
		$('a.info.plain').each(function() {
			var note = $(this).html();
			if (note.indexOf('class="note orange"') == -1) {
				$(this).html('').append('<div class="note orange"><span class="value">'+note+'</span><div class="tip"></div></div>');
			}
		});
	}
});

App.EmployeeVisionplanoverviewController = _TransactionSavingController.extend(_EmployeeDependentsEnrollmentMixin, {
	enroll: function() {
		// Enroll in the company vision plan and transition to
		// enrollment summary
		var plan = this.get('plan');
		var employee = this.get('employee');
		var company = this.get('company');
		var currentVisionEnrollment = this.get('currentVisionEnrollment');

		var previouslySelectedPlan = currentVisionEnrollment.get('visionPlan');
		currentVisionEnrollment.set('visionPlan', plan.get('plan'));
		currentVisionEnrollment.set('companyHealthPlan', plan.get('companyHealthPlan'));
		if (currentVisionEnrollment.get('status') == 'complete') {
			var selectedCarrier = previouslySelectedPlan.get('stateCarrier');
			var planCarrier = plan.get('stateCarrier');
			if (selectedCarrier != planCarrier) {
				// Reset the enrollment status only if the employee is choosing a plan from a different carrier
				// after they have completed enrollment
				currentVisionEnrollment.set('status', 'begin');
			}
		}
		else {
			// Check if the EE had previously declined coverage and reset approval status if so.
			if (employee.get('visionApprovalStatus') == 'decline' || currentVisionEnrollment.get('status') == 'decline') {
				if (currentVisionEnrollment.get('isInitialEnrollment')) {
					employee.set('visionApprovalStatus', null);
				}
				currentVisionEnrollment.set('hasSignedWaiver', false);
			}
			currentVisionEnrollment.set('status', 'begin');
		}

		this.updateDependentsCheckboxStatus('vision');
		this.createAndSaveDependentHealthEnrollments([currentVisionEnrollment]);

		return this.saveAndContinue().then(function() {
			this.send('hideModal');
			// EE is not dirty in ember data. SO, we have to reload to reflect server side changes.
			return employee.reload();
		}.bind(this)).then(function() {
			return this.transitionToRoute('employee.enrollmentsummary');
		}.bind(this));
	},
	close: function() {
		this.send('hideModal');
	}
});

App.EmployeeScoeoldvisionplanoverviewController = App.EmployeeVisionplanoverviewController.extend({
	enroll: function() {
		// This is of type EDentalPlan. EDentalPlan will have isOldPlan set for short circuit OE if the current plan is from
		// the previous inactive enrollment.
		var plan = this.get('plan');
		var currentEnrollment = this.get('currentVisionEnrollment');
		var employee = this.get('employee');
		if (plan.get('isOldPlan')) {
			currentEnrollment.set('oldPlan', plan.get('id'));
			return this.saveAndContinue().then(function() {
				this.send('hideModal');
				return this.transitionToRoute('employee.scoenewvisionplanselector');
			}.bind(this));
		}
		else {
			currentEnrollment.set('oldPlan', null);
			return this._super();
		}
	}
});

App.EmployeeScoeoldvisionplanoverviewView = Ember.View.extend({
	templateName: 'employee/visionplanoverview'
});

App.EmployeeVisiondeclineoverviewController = App.EmployeeVisionplanoverviewController.extend(_BundlingControllerMixin, {
	needs: ['employee/visionplanselector', 'employee/visionopenenrollment'],

	// We need this alias for _BundlingControllerMixin to access currLineOfCoverage.
	currLineOfCoverage: Ember.computed.oneWay('model.currLineOfCoverage'),

	content: function() {
		var o = Ember.Object.create({
			employee: this.get('employee'),
			employeeSettings: this.get('settings'),
			dependents: this.get('dependents'),
			currentEnrollment: this.get('currentEnrollment'),
			currentDentalEnrollment: this.get('currentDentalEnrollment'),
			currentVisionEnrollment: this.get('currentVisionEnrollment')
		});

		return o;
	},
	decline: function() {
		var controller = this.get('controllers.employee/visionplanselector');
		controller.decline(this.get('content'));
	},
	looksGood: function(action) {
		var controller = this.get('controllers.employee/visionopenenrollment');
		controller.looksGood(action, this.get('content'));
	}
});


App.CoverageTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: null}),
		Ember.Object.create({name: "Individual", id: "Individual"}),
		Ember.Object.create({name: "Group", id: "Group"}),
		Ember.Object.create({name: "Other", id: "Other"})
	],
});

App.EmploymentStatusSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Full Time", id: "FT"}),
		Ember.Object.create({name: "Part Time", id: "PT"}),
		Ember.Object.create({name: "1099", id: "1099"}),
		Ember.Object.create({name: "Retiree", id: "Retiree"}),
		Ember.Object.create({name: "Seasonal", id: "Seasonal"}),
		Ember.Object.create({name: "Temporary", id: "Temporary"})
	],
});

App.ActiveOrRetireeEmploymentStatusSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: null}),
		Ember.Object.create({name: "Active", id: "Active"}),
		Ember.Object.create({name: "Retiree", id: "Retiree"}),
	],
});

App.ContractTypeSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: null}),
		Ember.Object.create({name: "Individual", id: "Individual"}),
		Ember.Object.create({name: "Family", id: "Family"}),
		Ember.Object.create({name: "Employee/Spouse", id: "Employee/Spouse"}),
		Ember.Object.create({name: "Parent/Child(ren)", id: "Parent/Child(ren)"})
	],
});

App.MaritalStatusSelectView = App.SelectBox.extend({
	sorting: false,
	dependents: [],
	hasSpouse: function() {
		if (!Ember.isEmpty(this.get('dependents'))) {
			return this.get('dependents').filterBy('type', 'Spouse').get('length') > 0;
		}
		return false;
	}.property('dependents'),
	hasDomesticPartner: function() {
		if (!Ember.isEmpty(this.get('dependents'))) {
			return this.get('dependents').filterBy('type', 'Domestic Partner').get('length') > 0;
		}
		return false;
	}.property('dependents'),
	content: function() {
		var maritalStatusList = Ember.A();
		maritalStatusList.pushObject(Ember.Object.create({name: "Select", id: null}));
		if (Ember.isEmpty(this.get('dependents')) || (!this.get('hasSpouse') && !this.get('hasDomesticPartner'))) {
			maritalStatusList.pushObject(Ember.Object.create({name: "Single", id: "Single"}));
		}
		maritalStatusList.pushObject(Ember.Object.create({name: "Married", id: "Married"}));
		maritalStatusList.pushObject(Ember.Object.create({name: "Divorced", id: "Divorced"}));
		maritalStatusList.pushObject(Ember.Object.create({name: "Widow", id: "Widow"}));
		maritalStatusList.pushObject(Ember.Object.create({name: "Legally Separated", id: "Legally Separated"}));
		maritalStatusList.pushObject(Ember.Object.create({name: "Domestic Partner", id: "Domestic Partner"}));
		return maritalStatusList;
	}.property('dependents', 'hasSpouse', 'hasDomesticPartner'),
});

App.UseSameCheckbox = Ember.Checkbox.extend({
	dependent: null,
	controller: null,
	change: function() {
		var checked = this.get('checked');
		var dependent = this.get('dependent');
		var me = this.get('controller').get('dependentMe');
		if (checked) {
			dependent.set('hmoDentalName', me.get('hmoDentalName'));
			dependent.set('hmoDentalProviderNumber', me.get('hmoDentalProviderNumber'));
			dependent.set('hmoDentalExistingPatient', me.get('hmoDentalExistingPatient'));
		}
	}
});

App.UseSamePhysicianCheckbox = Ember.Checkbox.extend({
	dependent: null,
	controller: null,
	change: function() {
		var checked = this.get('checked');
		var dependent = this.get('dependent');
		var me = this.get('controller').get('dependentMe');
		if (checked) {
			dependent.set('hmoPhysicianName', me.get('hmoPhysicianName'));
			dependent.set('hmoPhysicianProviderNumber', me.get('hmoPhysicianProviderNumber'));
			dependent.set('hmoPhysicianProviderPAOrMGNumber', me.get('hmoPhysicianProviderPAOrMGNumber'));
			dependent.set('hmoPhysicianProviderPPGNumber', me.get('hmoPhysicianProviderPPGNumber'));
			dependent.set('hmoPhysicianExistingPatient', me.get('hmoPhysicianExistingPatient'));
		}
	}
});


App.EmployeePersonalinfoContactinfoController = App.EmployeePersonalinfoController.extend(_AddressMixin, _StateTaxMixin, App.ValidationsControllerMixin, {
	employee: Ember.computed(function() {
		return this;
	}),
	country: Ember.computed(function() {
		return this.get('homeAddress.country');
	}).property('homeAddress.country'),
	isRequiredPersonalInfoFieldsComplete: function() {
		if (this.get('isContractor')) {
			var contractor = this.get('contractor');
			if (contractor.get('isCountryUsa')) {
				return this.get('contractor.address') && this.get('contractor.city') && this.get('contractor.state') && this.get('contractor.zip') && this.get('contractor.email');
			} else {
				return this.get('contractor.address') && this.get('contractor.city') && this.get('contractor.email');
			}
		} else if (!this.get('isInternational') ) {
			return this.get('address') && this.get('city') && this.get('state') && this.get('zip') && this.get('email');
		} else {
			return this.get('email');
		}
	}.property('address','city','state','zip','email', 'contractor.address', 'contractor.city', 'contractor.state', 'contractor.zip', 'contractor.email'),
	errorText: Ember.computed.alias('_errorText'),
	addressRelatedProperties: ['address', 'address2', 'city', 'state', 'zip'],

	checkStateThenGoToNextRoute: function() {
		if (this.get('isEmployee') && this.get('employeeOldState') != this.get('state')) {
			this.send('showModal', "employeeresidencestatechangemodal");
			return Ember.RSVP.resolve();
		}
		return this.transitionToRoute('index');
	},

	actions: {
		save: function() {
			if(!this.get('isRequiredPersonalInfoFieldsComplete')){
				return Ember.RSVP.reject();
			}
			if (this.get('validations.length') >= 1) {
				return Ember.RSVP.reject();
			}

			//Contractor only
			if (this.get('isContractor')) {
				// Contractor specific operations

				//Disable below code for now, we can switch back when using employee.address
				// if (this.get('isInternationalContractor')) {
				// 	this.set('homeAddress.postalCode', '');
				// 	this.set('phone', '');
				// 	this.set('workPhone', '');
				// }
				var contractor = this.get('contractor');
				if (!contractor.get('isCountryUsa')) {
					contractor.set('zip', '');
					contractor.set('phone', '');
				}
			}

			var isAddressChanged = this.get('employee._dirtyAttributes').uniq().any(function(attribute) {
				return this.get('addressRelatedProperties').contains(attribute);
			}.bind(this));

			// If address gets updated and the employee has dependents, show apply to dependents modal
			// the employee must be in US, the dependent does not support foreign address
			return this.saveAndContinue().then(function() {
				if (this.get('isEmployee') &&
						!this.get('isInternational') &&
						isAddressChanged &&
						this.get('employee.nonSelfDependents.length') > 0) {
					// By default, set the dependents to use the same address
					this.get('employee.dependents').forEach(function(dependent) {
						dependent.set('sameAddress', true);
					});
					// must specify model here, route.js in modal folder won't work
					this.send('showModal', 'employee.personalinfo.contactinfo.modals.apply-to-dependents-modal', this);
					return Ember.RSVP.resolve();
				} else {
					return this.checkStateThenGoToNextRoute();
				}
			}.bind(this));
		},
	},
});

App.EmployeePersonalinfoContractorbankinfoController = App.EmployeePersonalinfoController.extend(_SecondaryEmployeeBankMixin, {
	employee: Ember.computed.alias('model'),
	save: function() {
		if (this.get('isContractor')) {
			//HACK, temporary fix to make sure contractor has a consistent bankAccountNumber, bankRoutingNumber, bankAccountType
			var contractor = this.get('contractor');
			var bank = this.get('primaryBank');
			contractor.set('bankAccountNumber', bank.get('bankAccountNumber'));
			contractor.set('bankRoutingNumber', bank.get('bankRoutingNumber'));
			contractor.set('bankAccountType', bank.get('bankAccountType'));
		}
		this.saveAndContinue();
	},
});

//Contractor only controller
App.EmployeePersonalinfoContractorexemptionsController = App.EmployeePersonalinfoController.extend(_AddressMixin, _StateTaxMixin, App.ValidationsControllerMixin, {
	isFatcaAndWhExempt: 'N',
	isExemptionCodesShowing: Ember.computed.equal('isFatcaAndWhExempt', 'Y'),

	save: function() {
		this.set('contractor.fatcaAndWhExempt', this.get('isFatcaAndWhExempt'));
		if (this.get('isFatcaAndWhExempt') === 'N') {
			this.set('contractor.fatcaCodes', '');
			this.set('contractor.exemptPayeeCodes', '');
		} else {
			if (!this.get('contractor.fatcaCodes') && !this.get('contractor.exemptPayeeCodes')) {
				return;
			}
		}
		this.saveAndContinue('index');
	},
});

//Contractor only controller
App.EmployeePersonalinfoContractorw9Controller = App.EmployeePersonalinfoController.extend( App.ValidationsControllerMixin, {
	save: function() {
		this.saveAndContinue('index');
	},
});

App.ContactPreferenceSelectView = App.SelectBox.extend({
	sorting: false,

	content: [
		Ember.Object.create({name: "Select", id: ""}),
		Ember.Object.create({name: "Email", id: "Email"}),
		Ember.Object.create({name: "Mail", id: "Mail"}),
		Ember.Object.create({name: "Work Phone", id: "Work Phone"}),
		Ember.Object.create({name: "Home Phone", id: "Home Phone"})
	],
});

App.DependentSelectView = App.SelectBox.extend({
	sorting: false,

	contentBinding: 'controller.dependents',
	optionLabelPath:"content.fullName",
	optionValuePath:"content.id"
});

App.PrescriptionMeds = Em.TextArea.extend({
	classNames: ['prescriptionMeds']
});


function storeAuthDetailsInDhe(ctx) {
	var attrs = ctx.get('attrs');
	var dependentHealthEnrollments = ctx.get('dhes');

	for (var i = 0; i < attrs.length; i++) {
		if (!ctx.get(attrs[i])) {
			ctx.set('errorText', 'All fields are required. Please fill out the missing fields.');
			return false;
		}
	}

	if (dependentHealthEnrollments && dependentHealthEnrollments.get('length')) {
		dependentHealthEnrollments.forEach(function(enrollment) {
			attrs.forEach(function(attr) {
				enrollment.set(attr, ctx.get(attr));
			});
		});
	}

	ctx.set('errorText', '');
	return true;
}

