
(function(module) {
	'use strict';
	// var context = this;
	var DEBUG = this.DEBUG;
	function detectLoops(parentLinks) {
		var seenNodes = {};
		var finalLoops = [];

		// traverses up until it finds a node a second time
		// returns that value until the first time it was seen
		// (i.e. the last node in the loop for the stack)
		// then continue returning null for the rest of the way
		function traverseParents(node, nodesInPath) {
			// return once we reach the top of this (sub?)tree
			if (!node) {
				return [false, null];
			}

			// if we've seen this node in the current path, return it as a loop
			if (nodesInPath[node]) {
				return [true, [""+node]];
			}

			// if we've already seen this node in a previous call, don't do the work again
			if (seenNodes[node]) {
				return [false, null];
			}

			seenNodes[node] = true;
			nodesInPath[node] = true;
			var result = traverseParents(parentLinks[node], nodesInPath);
			var isInLoop = result[0];
			var loop = result[1];

			if (loop) {
				if (loop[0] == node) {
					isInLoop = false;
				}

				if (isInLoop) {
					loop.push(""+node);
				}

				return [isInLoop, loop];
			}

			return [false, null];
		}

		Object.keys(parentLinks).forEach(function(child) {
			if (seenNodes[child]) {
				return;
			}

			var result = traverseParents(child, {});
			var loop = result[1];

			if (loop) {
				finalLoops.push(loop);
			}
		});

		if (DEBUG) {
			console.log();
		}

		return finalLoops;
	}

	// Standard compat layer with node for testing purposes
  if (typeof this.define === 'function') {
  	this.define('zen/loop-detection', [], function () {
	    return { 'default': detectLoops };
	  });
  } else if (typeof module !== 'undefined' && module.exports) { // Asuming node, but checking
  	module.exports = detectLoops;
  } else {
  	throw Error('We should run either in node or in the browser with loader.js and none of those were detected');
  }
/* eslint-disable */
}).call(this, typeof module !== 'undefined' ? module : this);
/* eslint-enable */
