// Prevents company admins and external admins from accessing the route
_EmployeeOnlyMixin = Ember.Mixin.create({
	beforeModel: function(transition) {
		this._super();
		return dashboard('employee').then(function (employee) {
			if (employee.get("type") === "AD"){
				return this.transitionTo("index");
			}
		}.bind(this));
	}
});

_EmployeeRoute = Ember.Route.extend(_AuthEmployeeMixin, {
	model: function() {
		return dashboard('employee');
	}
});

_NewHireRoute = Ember.Route.extend(_AuthEmployeeMixin, _FlowCurrentSectionRouteMixin, {
	model: function() {
		var model = this.modelFor('employee.onboarding');
		return model;
	},
	afterModel: function(model) {
		return model.get('employee').then(function(e) { return e.get('company'); });
	},
});

App.EmployeeEmployeeinfoRoute = _EmployeeRoute.extend({
	afterModel: function(model) {
		return model.get('currentEmployment');
	}
});

App.EmployeeOrgchartRoute = Ember.Route.extend({
	beforeModel: function() {
		return this.transitionTo('employeeorgchart');
	}
});

var ONBOARDING_NAV_STATES = Ember.A(["employee.onboarding.personal","employee.onboarding.customfields","employee.onboarding.emergency","employee.onboarding.eeo","employee.onboarding.federaltax","employee.onboarding.statetax","employee.onboarding.bank","employee.onboarding.paycheckdistribution","employee.onboarding.eligibility", "employee.onboarding.upload", "employeeOnboardingPlatform.flow"]);

var ONBOARDING_V2_NAV_STATES = Ember.A(["employee.onboarding-v2.personal","employee.onboarding-v2.customfields","employee.onboarding-v2.emergency","employee.onboarding-v2.vaccination","employee.onboarding-v2.eeo","employee.onboarding-v2.federaltax","employee.onboarding-v2.statetax","employee.onboarding-v2.bank","employee.onboarding-v2.paycheckdistribution","employee.onboarding-v2.eligibility","employee.onboarding-v2.upload", "employeeOnboardingPlatform.flow"]);



_AbstractOnboardingPayrollInfo = _NewHireRoute.extend({
	afterModel: function(model) {
		// Location is also loaded so that isInternational can be used in extended routes
		// International tax objects are loaded if needed
		var promises = [thenpath(model, 'employee.location'),
						thenpath(model, 'employee.federalTaxes').then(function() { return thenpath(model, 'employee.currentFederalTax.employeeSignature'); }),
						thenpath(model, 'employee.auFederalTax.employeeSignature'),
						thenpath(model, 'employee.brFederalTax.employeeSignature'),
						thenpath(model, 'employee.caFederalTax.employeeSignature'),
						thenpath(model, 'employee.deFederalTax.employeeSignature'),
						thenpath(model, 'employee.frFederalTax.employeeSignature'),
						thenpath(model, 'employee.hkFederalTax.employeeSignature'),
						thenpath(model, 'employee.inFederalTax.employeeSignature'),
						thenpath(model, 'employee.nlFederalTax.employeeSignature'),
						thenpath(model, 'employee.gbFederalTax.employeeSignature'),
						thenpath(model, 'employee.sgFederalTax.employeeSignature'),
						thenpath(model, 'employee.ieFederalTax.employeeSignature'),
						thenpath(model, 'employee.currentEligibility'),
						thenpath(model, 'employee.company.payrollProviderMetadata'),
						];
		return wrapArrayPromise(promises);
	}
});

_AbstractEmployeeOnboardingFlowController = _AbstractSavingController.extend(App.SimpleFlowDispatcherMixin, {
	needs: ['employee/onboarding'],
});

_OnboardingEligibilityController = _AbstractEmployeeOnboardingFlowController.extend({
	isCitizen: function() {
		return this.get('eligibility.eligibilityType') == 'CZ' || this.get('eligibility.eligibilityType') == 'NN';
	}.property('eligibility.eligibilityType'),
	isGreenCard: function() {
		return this.get('eligibility.eligibilityType') == 'PR' || this.get('eligibility.eligibilityType') == 'EA';
	}.property('eligibility.eligibilityType'),
	isEAD: Ember.computed.equal('eligibility.eligibilityType', 'EA'),
	isWorkVisa: Ember.computed.equal('eligibility.eligibilityType', 'WV'),
});

App.EmployeePrintinsurancecardsRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function() {
		return this.all(dashboard('employee'), {
			"companySettings": dashboard('company.settings'),
			"settings": model.get('employeeSettings'),
		});
	},
});

App.EmployeePersonalinfoRoute = _EmployeeRoute.extend(_MultiModelMixin, _EmployeeOnlyMixin, {
	redirect: function(model, transition) {
		if (model.get('notContractor')) {
			this.transitionTo('employeeProfile.personalInfo', model.get('id'));
		}
	},
	afterModel: function(model) {
		if (model.get('isContractor')) {
			this.all(model, {
				"_customfields": wrapArrayPromise(model.get('rawEmployeeCustomFieldValues')).then(function(values) {
					return Ember.RSVP.all(values.map(function(value) {
						return value.get('customField');
					}));
				}),
				'contractor': model.get('contractor')
			});
		} else {
			this.all(model, {
				"_customfields": wrapArrayPromise(model.get('rawEmployeeCustomFieldValues')).then(function(values) {
					return Ember.RSVP.all(values.map(function(value) {
						return value.get('customField');
					}));
				}),
			});
		}
	}
});

App.EmployeePersonalinfoBasicinfoRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		var promises = [thenpath(model, 'company.onboardingSettings')];
		// TODO (contractors) remove in future if possbile
		if (model.get('isContractor')) {
			promises.push(thenpath(model, 'contractor'));
		}
		return Ember.RSVP.all(promises);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		// TODO (contractors) remove in future if possbile
		if (model.get('isContractor')) {
			controller.set('isIdentificationEIN', model.get('contractor.isBusiness') && model.get('contractor.taxId'));
			controller.set('isIdentificationSSN', !model.get('contractor.isBusiness') && model.get('contractor.taxId'));
			controller.set('newTaxId', '');
		}
	},
});


App.EmployeePersonalinfoCustomfieldsRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		// wait on customFieldSelectChoices
		return wrapArrayPromise(model.get('rawEmployeeCustomFieldValues')).then(function(values) {
				return Ember.RSVP.all(values.map(function(v) {
					return v.get('customField').then(function(field) {
						var customFieldPromises = [];
						if (field.get('isSelectType')) {
							customFieldPromises.pushObject(field.get('customFieldSelectChoices'));
						}
						if (field.get('customFieldSection')) {
							customFieldPromises.pushObject(field.get('customFieldSection'));
						}
						return wrapArrayPromise(customFieldPromises);
					});
				}));
			});
	}
});

App.EmployeePersonalinfoContactinfoRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		var promises = [
			thenpath(model, 'homeAddress'),
			thenpath(model, 'dependents')];
		//Contractor only
		if (model.get('isContractor')) {
			promises.push(thenpath(model, 'contractor'));
		}
		return Ember.RSVP.all(promises);
	},

	setupController: function(controller, model) {
		this._super(controller, model);
		if (model.get('isEmployee')) {
			controller.set('employeeOldState', model.get('state'));
		}
	}
});

// Contractor Only Route
App.EmployeePersonalinfoContractorbankinfoRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		var promises = [thenpath(model, 'banks'), thenpath(model, 'contractor')];
		return Ember.RSVP.all(promises);
	},
});

// Contractor Only Route
App.EmployeePersonalinfoContractorexemptionsRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		return thenpath(model, 'contractor');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var isYes = model.get('contractor.fatcaCodes') || model.get('contractor.exemptPayeeCodes');
		if (isYes) {
			controller.set('isFatcaAndWhExempt', 'Y');
		} else {
			controller.set('isFatcaAndWhExempt', 'N');
		}
	},
});

App.EmployeePersonalinfoEmploymentinfoRoute = App.EmployeePersonalinfoRoute.extend({
	afterModel: function(model) {
		return thenpath(model, 'currentEmployment');
	}
});
App.EmployeePersonalinfoPaystubsRoute = App.EmployeePersonalinfoRoute.extend({
	//Contractor Only
	afterModel: function(model) {
		if (model.get('isContractor')) {
			return this.all(model, {
				'contractorPayments': App.ContractorPayment.find(),
			});
		}
	}
});

App.EmployeePersonalinfoEmergencyRoute = App.EmployeePersonalinfoRoute.extend(_RollbackWithChildrenOnDeactivateMixin, {
	afterModel: function(model) {
		return thenpath(model, 'location');
	}
});
App.EmployeePersonalinfoDocumentsRoute = App.EmployeePersonalinfoRoute.extend(_MultiModelMixin, {
	model: function() {

		var allEmployeeEnrollmentsPromise = dashboard('employee').then(function(employee) {
			return App.EmployeeHealthEnrollment.find({
				'employee_id': employee.get('id'),
			});
		});

		return this.all(dashboard('employee'), {
			'allEmployeeEnrollments': allEmployeeEnrollmentsPromise,
			'allAgreements': dashboard('employee.allAgreements'),
			'agreementEmployeeFsa': dashboard('employee.allFsaAgreements'),
			'_eligibilities': dashboard('employee.eligibilities'),
			"ipAssignments": dashboard('company.onboardingSettings.ipAssignments'),
		});
	},
	afterModel: function(model) {
		// contractor only
		if (model.get('isContractor')) {
			var promises = [thenpath(model, 'contractor'), thenpath(model, 'contractor.contracts'), thenpath(model, 'contractor.ipAgreements')];
			return Ember.RSVP.all(promises);
		}
	},
});

App.EmployeePersonalinfoDocumentsController = Ember.ObjectController.extend({
	allFsaAgreements: Ember.computed.alias('agreementEmployeeFsa'),
	fsaShowSummaryPlanDescription: Ember.computed.and('fsa.summaryPlanDescriptionUrl', 'company.isCompanyAlegeusCustomer'),
	hraShowSummaryPlanDescription: Ember.computed.and('hra.summaryPlanDescriptionUrl', 'company.isCompanyAlegeusCustomer'),
	fsaShowAdministrativeServiceAgreement: Ember.computed.and('fsa.agreement.url', 'company.isCompanyAlegeusCustomer'),
	hraShowAdministrativeServiceAgreement: Ember.computed.and('hra.firstAvailablePlan.agreement.url', 'company.isCompanyAlegeusCustomer'),
	unicardCommuterShowAdministrativeServiceAgreement: Ember.computed.and('unicardCommuter.agreement.url', 'company.isCompanyAlegeusCustomer'),
	allAgreementEhes: function() {
		var eheIds = this.get('allEmployeeEnrollments').mapBy('id');
		var filteredAgreements = Ember.A();
		eheIds.forEach(function(eheId) {
			var latestAgreement = this.get('allAgreements').sortBy('id').filter(function(agreement){
				return !Ember.isEmpty(eheId) && eheId===agreement.get('employeeHealthEnrollment.id');
			}).get('lastObject');
			if (!Ember.isEmpty(latestAgreement)){
				filteredAgreements.pushObject(latestAgreement);
			}
		}.bind(this));

		return filteredAgreements;
	}.property('allAgreements'),
	viewOrEditUploadedDocuments: function() {
		var model = this.get('model');
		var eligibility = model.get('currentEligibility');
		var newHire = eligibility.get('newHire');
		newHire.then(function() {
			this.transitionTo('employee.onboarding.upload', newHire);
			return;
		}.bind(this));
	},
	showAttachments: function(agreement) {
		this.send("showModal", "showAttachmentsModal", agreement);
	}
});

//Contractor Only
App.EmployeePersonalinfoContractorw9Route = App.EmployeePersonalinfoRoute.extend(_MultiModelMixin, {
	afterModel: function(model) {
		if (model.get('isContractor')) {
			var promises = [thenpath(model, 'contractor')];
			return Ember.RSVP.all(promises);
		}
	},
});

App.ShowAttachmentsModalController = Ember.ObjectController.extend();

App.EmployeePayrollinfoRoute = _EmployeeRoute.extend();

_EmployeeHsaRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, _AuthEmployeeMixin, {
	model: function() {
		var isHsaBlocked = Ember.ajax('/custom_api/getEmployeeHsaStatus').then(function(response) {
			return response.status == 'blocked';
		});
		var employeeEligibility = Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function(result) {
			return result;
		});
		return Ember.RSVP.hash({
			'employee': dashboard('employee'),
			'employeeEligibility': employeeEligibility,
			'company': dashboard('company'),
			'hsa': dashboard('employee.hsa'),
			'companyHsa': dashboard('employee.company.hsa'),
			'isHsaBlocked': isHsaBlocked,
		});
	},
	redirect: function(model, transition) {
		var hsa = model['hsa'];
		var isHsaBlocked = model['isHsaBlocked'];
		if (hsa.get('status') == 'complete') {
			this.transitionTo('employee.hsaSettings.overview');
		}
		if(isHsaBlocked) {
			this.transitionTo('index');
		}
		if(App.switches.isActive('employee_hsa_v2_enrollment')) {
			this.transitionTo('employee.hsaSetup');
		}
	},
	resetController: function(controller, isExiting, transition) {
		controller.setProperties({
			errorText: undefined,
		});
	},
});

App._HsaModelControllerMixin = Ember.Mixin.create({
	employee: Ember.computed.alias('model.employee'),
	employeeEligibility: Ember.computed.alias('model.employeeEligibility'),
	hsa: Ember.computed.alias('model.hsa'),
	company: Ember.computed.alias('model.company'),
});

App.EmployeeHsaIntroRoute = _EmployeeHsaRoute.extend();
App.EmployeeHsaIntroController = Ember.Controller.extend(App._HsaModelControllerMixin);

App.EmployeeHsaRoute = _EmployeeHsaRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);
		var hsa = model['hsa'];
		if (hsa.get('status') == null) {
			hsa.set('status', 'filling-out');
			App.store.commit();
		}
	}
});

App.EmployeeHsaController = _TransactionSavingController.extend(App._HsaModelControllerMixin, {
	isBasicInfoComplete: Ember.computed.oneWay('hsa.isBasicInfoComplete'),
	isContactInfoComplete: Ember.computed.oneWay('hsa.isContactInfoComplete'),
	isContributionInfoComplete: function() {
		if(this.get('hsa.previousYTDContributionRequired')) {
			return this.get('hsa.employeeContribution') && this.get('hsa.previousYTDContribution');
		}
		return this.get('hsa.employeeContribution');
	}.property('hsa.employeeContribution', 'hsa.previousYTDContributionRequired', 'hsa.previousYTDContribution'),
	employerContributionForEmployee: function() {
		var contribution = this.get('hsa.overrideEmployerContributionForEmployee');
		if (contribution != null) {
			return parseFloat(contribution);
		}
		return 0;
	}.property('hsa.overrideEmployerContributionForEmployee'),
	employerContributionForDependents: function() {
		var contribution = this.get('hsa.overrideEmployerContributionForDependents');
		if (contribution != null) {
			return parseFloat(contribution);
		}
		return 0;
	}.property('hsa.overrideEmployerContributionForDependents'),
	maxJointContribution: function() {
		var maxJointContribution = 0;
		var previousYearToDateContribution = this.get('hsa.previousYTDContributionRequired') ? this.get('hsa.previousYTDContribution') : 0;
		maxJointContribution = (this.get('hsa.maximumComputedAnnualHsaContribution') - previousYearToDateContribution -
				this.get('hsa.overrideFrontLoadedCompanyContribution'))/ this.get('hsa.totalMonthsForEmployeeContribution');
		maxJointContribution = maxJointContribution.toFixed(2);
		return maxJointContribution;
	}.property('hsa.previousYTDContributionRequired', 'hsa.previousYTDContribution', 'hsa.overrideFrontLoadedCompanyContribution',
			   'hsa.totalMonthsForEmployeeContribution', 'hsa.maximumComputedAnnualHsaContribution'),
	maxEmployeeContribution: function() {
		var maxEmployeeContribution = Math.max(0, this.get('maxJointContribution') - this.get('hsa.overrideEmployerTotalContributionForNextChange'));
		maxEmployeeContribution = maxEmployeeContribution.toFixed(2);
		return maxEmployeeContribution;
	}.property('maxJointContribution', 'hsa.overrideEmployerTotalContributionForNextChange'),

});

App.EmployeeHsaBasicinfoRoute = App.EmployeeHsaRoute.extend();
App.EmployeeHsaBasicinfoController = _TransactionSavingController.extend(App._HsaModelControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'hsa.isBasicInfoComplete',
	nextRoute: 'employee.hsa.contact',
	continue: function() {
		this.save([this.get('hsa')]);
	}
});

App.EmployeeHsaContactRoute = App.EmployeeHsaRoute.extend();
App.EmployeeHsaContactController = _TransactionSavingController.extend(App._HsaModelControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'hsa.isContactInfoComplete',
	prevRoute: 'employee.hsa.basicinfo',
	nextRoute: 'employee.hsa.setup',
	continue: function() {
		this.save([this.get('hsa')]);
	}
});

App.EmployeeHsaSetupRoute = App.EmployeeHsaRoute.extend();
App.EmployeeHsaSetupController = App.EmployeeHsaController.extend(App.NextEffectiveDateCalculationMixin, {
	prevRoute: 'employee.hsa.contact',
	nextRoute: 'employee.hsafinish',
	deadlineCutoffDay : function() {
		return this.get('company.hsa.deadlineCutoffDayForEmployees');
	}.property('company.hsa.deadlineCutoffDayForEmployees'),

	calculatedEffectiveDate: Ember.computed.alias('hsa.computedStartDate'),
	save: function() {
		var self = this;
		self.set('errorText', null);

		if (this.get('hsa.previousYTDContributionRequired') &&
			(this.get('hsa.previousYTDContribution') == null || this.get('hsa.previousYTDContribution') == '')) {
			 self.set('errorText', 'Please specify your year-to-date contribution to any HSA ' +
					 '(enter $0 if you have not contributed anything).');
			 return ;
		}

		// condition should be removed once prorated hsa is enabled for all employees
		if (this.get('hsa.employeeContribution') == null || this.get('hsa.employeeContribution') == '') {
			self.set('errorText', 'Please specify how much you wish to contribute to your HSA ' +
					'(enter $0 if you don\'t wish to contribute anything).');
			return ;
		}

		// We are comparing previousYTDContribution with maximumComputedAnnualFamilyHsaContribution even if employee doesn't have dependents
		// just to give them the benefit of doubt as they had family and now they are single.
		if (parseFloat(this.get('hsa.previousYTDContribution')) > this.get('hsa.maximumComputedAnnualFamilyHsaContribution')) {
			self.set('errorText', 'Your year-to-date HSA contribution is more than the yearly HSA limit');
			return;
		}

		if (parseFloat(this.get('hsa.employeeContribution')) > self.get('maxEmployeeContribution')) {
			self.set('errorText', 'Your contribution cannot exceed $' + self.get('maxEmployeeContribution') + '/month' +
					' -- the joint contribution cannot exceed $' +
					 Math.max(this.get('hsa.overrideEmployerTotalContributionForNextChange'), self.get('maxJointContribution')) + '/month.');
			return;
		}

		if (!this.get('hsa.isBasicInfoComplete')) {
			this.transitionTo('employee.hsa.basicinfo');
			return;
		} else if (!this.get('hsa.isContactInfoComplete')) {
			this.transitionTo('employee.hsa.contact');
			return;
		} else if (!this.get('isContributionInfoComplete')) {
			this.transitionTo('employee.hsa.setup');
			return;
		}

		if (this.get('employeeEligibility.isEligibleForFSA') || this.get('employeeEligibility.isEligibleForHRA')) {
			this.send('showModal', 'employee.hsa.warning', this);
		} else {
			this.saveAndGotoNextStep();
		}
	},
	saveAndGotoNextStep: function() {
		var self = this;

		// incase previousYTDContributionRequired changes in between two updates
		if (!this.get('hsa.previousYTDContributionRequired')) {
			this.set('hsa.previousYTDContribution', null);
		}

		this.set('hsa.enrolledDate', formatDate(new Date()));
		this.set('hsa.startDate', this.get('hsa.computedStartDate'));
		this.set('hsa.frontLoadedCompanyContribution', this.get('hsa.overrideFrontLoadedCompanyContribution'));
		this.set('hsa.effectiveDate', null);
		this.set('hsa.effectiveEndDate', null);

		// incase previousYTDContributionRequired changes in between two updates
		if(!this.get('hsa.previousYTDContributionRequired')) {
			this.set('hsa.previousYTDContribution', null);
		}

		this.saveAndContinue().then(function() {
			this.transitionTo(this.get('nextRoute'));
		}.bind(this));
	},
});

App.EmployeeHsafinishRoute = App.EmployeeHsaRoute.extend();
App.EmployeeHsafinishController = App.EmployeeHsaController.extend({
	hsaEnrollmentCompleteMailURL: '/custom_api/sendHsaEmployeeEnrollmentCompleteEmail',
	modal: 'employee.hsa.nextSteps',
	save: function() {
		if (this.get('hsa.authName') && this.get('hsa.authSignature')) {
			var hsa = this.get('hsa');
			hsa.set('status', 'complete');

			this.saveAndContinue().then(function() {
				var agreementContent = Ember.$('.paper.js-hsa-ee-agreement')[0].innerHTML;
				var today = new Date();
				var formattedDate = formatDate(today);
				Ember.ajaxPost('/custom_api/createAgreementEmployeeHsa',
					JSON.stringify({
						agreementContent: agreementContent,
						authName: this.get('hsa.authName'),
						authDate: formattedDate,
						authSignature: this.get('hsa.authSignature'),
						enrollmentType: 'Employee HSA Agreement',
						documentType: 'Enrollment Agreement',
					})
				).then(function() {
					hsa.reload().then(function(){
						Ember.ajaxPost(this.get('hsaEnrollmentCompleteMailURL'));
						var o = Ember.Object.create({
							effectiveDate: this.get('hsa.computedStartDate'),
							isCompanyHsaWithAlegeus: this.get('company.hsa.isCompanyHsaWithAlegeus'),
						});
						this.eventLogger.log("hsa_employee_setup_complete");
						this.send('showModal', this.get('modal'), o);
					}.bind(this));
				}.bind(this));
			}.bind(this));
		} else {
			this.set('errorText', 'Some fields are missing, all fields are required.');
		}
	},
});

App.EmployeeHsaWarningRoute = _EmployeeHsaRoute.extend();
App.EmployeeHsaWarningController = App.EmployeeHsaController.extend({
	continue: function() {
		this.get('model').send('hideModal');
		this.get('model').saveAndGotoNextStep();
	},
});

App.EmployeeHsaNextStepsController = Ember.ObjectController.extend();

App.RequestNewZFBCardController = Ember.ObjectController.extend({
	close: function() {
		this.send('hideModal');
	},
	submit: function() {
		if (!this.get('requestStatusChangeReason')) {
			this.set('errorText', 'Please select a reason');
			return;
		}
		$.ajax({
			async: false,
			type: "POST",
			url: "/requestNewZFBCard",
			data:
				JSON.stringify({
					statusChangeReason: this.get('requestStatusChangeReason'),
					cardProxyNumber: this.get('cardProxyNumber'),
				}),
			contentType: 'application/json',
			success: function(result) {
				this.controllerFor(this.get('controllerToNotify')).notifyPropertyChange('zfbCards');
				this.send('hideModal');
			}.bind(this),
			error: function() {
				this.set('errorText', 'We were unable to process at this time, please try again later');
			}.bind(this)
		});
	}
});

App.DisplayZfbPinNumberController = Ember.ObjectController.extend({
	pinPromise: function() {
		var promise = Ember.ajax('/custom_api/getZFBCardPinNumber?cardProxyNumber=' + this.get('cardProxyNumber')).then(function(data) {
			if (data.error) {
				this.set('error', data.error);
				this.set('error_message', data.error_message);
				return;
			}
			this.set('pin', data.pin);
		}.bind(this));

		return Ember.PromiseObject.create({
			promise: promise,
		});
	}.property('cardProxyNumber'),
	close: function() {
		this.send('hideModal');
	},
});

App.AlegeusEmployeeBankMixin = Ember.Mixin.create({
	afterModel: function(model) {
		var superResult = this._super.apply(this, arguments);

		if (!App.switches.isActive('is_alegeus_bank_migrated')) {
			return superResult;
		}

		var alegeusSettings = model['alegeusSettings'];
		if (Ember.isEmpty(alegeusSettings) || (!Ember.isEmpty(alegeusSettings.get('bank')) && alegeusSettings.get('bank.isActive'))) {
			// alegeus bank is set and is active (not deleted)
			return superResult;
		}

		dashboard('employee').then(function(value) {
			alegeusSettings.set('bank', App.EmployeeBank.createRecord({ bankAccountName: 'Alegeus Bank', isActive: true, employee: value }));
		});

		return superResult;
	},
});

App.EmployeeFsaIntroRoute = Ember.Route.extend({
	redirect: function(model, transition) {
		if(App.switches.isActive('employee_fsa_v2_enrollment')) {
			this.transitionTo('fsaSetup');
		}
	}
});

_EmployeeFsaRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, _AuthEmployeeMixin, _MultiModelMixin, {
	model: function() {
		var employeeEligibility = Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function(result) {
			return result;
		});
		return Ember.RSVP.hash({
			employee: dashboard('employee'),
			employeeEligibility: employeeEligibility,
			employeeHealthStatusProperties: dashboard('employee.employeeHealthStatusProperties'),
			company: dashboard('company'),
			fsa: dashboard('employee.fsa'),
			fsaRenewal: dashboard('employee.fsa.renewal'),
			hsa: dashboard('employee.hsa'),
			alegeusSettings: dashboard('employee.alegeusSettings'),
			alegeusBank: dashboard('employee.alegeusSettings.bank'),
			companyFsa: dashboard('company.fsa'),
			companyRenewal: dashboard('company.fsa.renewal'),
			companyHsa: dashboard('company.hsa'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		var fsa = model['fsa'];
		if (fsa.get('status') == null || fsa.get('startDate') == null) {
			var transaction = App.store.transaction();
			transaction.add(fsa);
			if (fsa.get('status') == null) {
				fsa.set('status', 'filling-out');
			}

			if (fsa.get('startDate') == null) {
				fsa.set('startDate', fsa.get('computedStartDate'));
			}
			transaction.commit();
		}
		var useCompanyRenewalContribution = fsa.get('useCompanyRenewalContribution');
		if (!controller.get('companyFsa')) {
			controller.set('companyFsa', controller.get('company.fsa' + (useCompanyRenewalContribution ? '.renewal' : '')));
		}
	}
});

App.EmployeeFsaRoute = _EmployeeFsaRoute.extend({
	redirect: function(model, transition) {
		var fsa = model['fsa'];
		if (fsa.get('status') == 'complete') {
			this.transitionTo('employee.fsasettings.overview');
		}

		if(App.switches.isActive('employee_fsa_v2_enrollment')) {
			this.transitionTo('fsaSetup');
		}
	}
});

App.EmployeeFsaController = _TransactionSavingController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	isBasicInfoComplete: Ember.computed.oneWay('fsa.isBasicInfoComplete'),
	isContactInfoComplete: Ember.computed.oneWay('fsa.isContactInfoComplete'),
	isBankInfoComplete: Ember.computed.oneWay('alegeusSettings.isBankInfoComplete'),
	basicRoute: 'employee.fsa.basicinfo',
	contactRoute: 'employee.fsa.contact',
	bankRoute: 'employee.fsa.bank',
	contributionRoute: 'employee.fsa.contribution',
	currFsa: Ember.computed.alias('fsa'),
	isContributionComplete: function() {
		return (this.get('currFsa.isOptedOutOfMedical') || this.get('currFsa.medicalCareContribution')) && this.get('currFsa.dependentCareContribution');
	}.property('currFsa.medicalCareContribution', 'currFsa.dependentCareContribution', 'currFsa.isOptedOutOfMedical'),
});

App.EmployeeFsaBasicinfoRoute = App.EmployeeFsaRoute.extend();
App.EmployeeFsaBasicinfoController = App.EmployeeFsaController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isBasicInfoComplete',
	nextRoute: 'employee.fsa.contact',
	continue: function() {
		this.save([this.get('fsa')]);
	}
});

App.EmployeeFsaContactRoute = App.EmployeeFsaRoute.extend();
App.EmployeeFsaContactController = App.EmployeeFsaController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isContactInfoComplete',
	nextRoute: 'employee.fsa.bank',
	prevRoute: 'employee.fsa.basicinfo',
	continue: function() {
		this.save([this.get('fsa')]);
	}
});

App._AlegeusBankSwitchMixin = Ember.Mixin.create({
	bankInfo: Ember.computed('alegeusSettings', 'alegeusSettings.bank', function() {
		if (App.switches.isActive('is_alegeus_bank_migrated')) {
			return this.get('alegeusSettings.bank');
		} else {
			return this.get('alegeusSettings');
		}
	}),
	continue: function() {
		var savePromise = null;
		if (App.switches.isActive('is_alegeus_bank_migrated')) {
			savePromise = this.get('alegeusSettings.bank').save();
		} else {
			savePromise = this.get('alegeusSettings').save();
		}
		savePromise.then(function() {
			this.save([this.get('alegeusSettings')]);
		}.bind(this));
	},
});

App.EmployeeFsaBankRoute = App.EmployeeFsaRoute.extend(App.AlegeusEmployeeBankMixin);
App.EmployeeFsaBankController = App.EmployeeFsaController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, App._AlegeusBankSwitchMixin, {
	checkCompleteAfterSave: 'isBankInfoComplete',
	nextRoute: 'employee.fsa.contribution',
	prevRoute: 'employee.fsa.contact',
});

App._FsaEmployeeContributionControllerMixin = Ember.Mixin.create({
	fieldValidations: function() {
		this.set('errorText', '');
		var fsa = this.get('currFsa');
		var maxMedicalCareContribution = this.get('companyFsa.maxMedicalCareContribution');
		if (fsa.get('medicalCareContribution') > maxMedicalCareContribution) {
			this.set('errorText', 'Your medical care contribution cannot exceed $' + maxMedicalCareContribution + ' this year.');
			return;
		}
		var companyDependentCareContribution = this.get('companyFsa.dependentCareContribution');
		if (!companyDependentCareContribution) {
			companyDependentCareContribution = 0;
		}

		var maxDependentCareContribution = this.get('companyFsa.maxDependentCareContribution');
		if (fsa.get('dependentCareContribution') > (maxDependentCareContribution - companyDependentCareContribution)) {
			this.set('errorText', 'The joint dependent care contribution cannot exceed $' + maxDependentCareContribution + ' this year.');
			return;
		}
		if (!this.get('changeContributionsOnly')) {
			fsa.set('startDate', fsa.get('computedStartDate'));
		}
		return true;
	},
	totalMedicalCost: function() {
		var amount = this.get('currFsa.employerMedicalContribution') || 0;
		var employeeAmount = this.get('currFsa.medicalCareContribution') || 0;
		var total = parseFloat(amount) + parseFloat(employeeAmount);
		return Math.round(String(total).replace(/,/g,'')).toString();
	}.property('currFsa.medicalCareContribution', 'currFsa.employerMedicalContribution'),
	totalDependentCost: function() {
		var amount = this.get('companyFsa.dependentCareContribution') || 0;
		var employeeAmount = this.get('currFsa.dependentCareContribution') || 0;
		var total = parseFloat(amount) + parseFloat(employeeAmount);
		return Math.round(String(total).replace(/,/g,'')).toLocaleString();
	}.property('currFsa.dependentCareContribution', 'companyFsa.dependentCareContribution'),
	employeeDependentMax: function() {
		var maxDependentCareContribution = this.get('companyFsa.maxDependentCareContribution');
		var amount = this.get('companyFsa.dependentCareContribution');
		if (amount) {
			var remaining = parseFloat(maxDependentCareContribution - amount);
			return parseFloat(remaining.toFixed(2));
		}

		return maxDependentCareContribution;
	}.property('companyFsa.dependentCareContribution')
});

App.EmployeeFsaMedicalOptOutRouteMixin = Ember.Mixin.create({
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('originalFsaMedicalOptOut', controller.get('currFsa.isOptedOutOfMedical'));
	},
});

App.EmployeeFsaMedicalOptOutControllerMixin = Ember.Mixin.create({
	canEmployeeEnrollInHSA: Ember.computed.and('company.showHSA', 'isEligibleForHSA', 'company.hsa.isSetupComplete', 'showHsa'),
	shouldShowMedicalOptOut: function() {
		return this.get('canEmployeeEnrollInHSA') || this.get('originalFsaMedicalOptOut');
	}.property('canEmployeeEnrollInHSA', 'originalFsaMedicalOptOut'),
	canContributeToMedicalFsa: function() {
		var fsaStartDate = zen.americanDateAsDate(this.get('currFsa.startDate'));
		var hsaEndDate = zen.americanDateAsDate(this.get('hsa.effectiveEndDate'));

		var hsaNotEnrolled = !this.get('hsa.isComplete') && hsaEndDate == null;
		var isFsaStartingAfterHsaEnds = this.get('hsa.isCanceled') && fsaStartDate != null && hsaEndDate != null && fsaStartDate >= hsaEndDate;
		var canUseFsa = hsaNotEnrolled || isFsaStartingAfterHsaEnds;
		if (!canUseFsa) {
			this.set('currFsa.isOptedOutOfMedical', true);
			this.set('currFsa.medicalCareContribution', 0);
		}
		return canUseFsa;
	}.property('currFsa.isOptedOutOfMedical', 'currFsa.startDate', 'hsa.status', 'hsa.effectiveEndDate'),
	fsaMedicalOptOut: function() {
		if (this.get('currFsa.isOptedOutOfMedical')) {
			this.set('currFsa.medicalCareContribution', 0);
		}
	}.property('currFsa.isOptedOutOfMedical'),
});

App.EmployeeFsaContributionRoute = App.EmployeeFsaRoute.extend(App.EmployeeFsaMedicalOptOutRouteMixin);
App.EmployeeFsaContributionController = App.EmployeeFsaController.extend(App.EmployeeFsaMedicalOptOutControllerMixin,
																		 App._FsaEmployeeContributionControllerMixin, {
	isFormCompleted: Ember.computed.and('isContributionComplete'),
	nextRoute: 'employee.fsafinish',
	prevRoute: 'employee.fsa.bank',
	continue: function() {
		if (!this.get('isFormCompleted')) {
			var msg = 'Certain fields are missing information. Please complete all fields';
			this.set('errorText', msg);
		} else {
			if (this.get('canEmployeeEnrollInHSA') && !this.get('currFsa.isOptedOutOfMedical')) {
				this.send('showModal', 'employee.fsa.hsawarning', this);
			} else {
				this.save();
			}
		}
	}
});

App.EmployeeFsafinishRoute = App.EmployeeFsaRoute.extend({
	redirect: function(model, transition) {
		var fsa = model['fsa'];
		var alegeusSettings = model['alegeusSettings'];
		if(!fsa.get('isBasicInfoComplete')){
			this.transitionTo('employee.fsa.basicinfo');
		} else if(!fsa.get('isContactInfoComplete')){
			this.transitionTo('employee.fsa.contact');
		} else if(!alegeusSettings.get('isBankInfoComplete')){
			this.transitionTo('employee.fsa.bank');
		}
	},
	setupController: function(controller, model) {
		this._super(controller, model);
	}
});

App.FsaAgreementSignMixin = Ember.Mixin.create({
	checkAndSign: function() {
		if (this.get('agreementName') && this.get('agreementSignature')) {
			var fsa = this.get('currFsa');
			fsa.set('status', 'complete');
			this.saveAndContinue().then(function() {
				this.eventLogger.log("fsa_employee_enrollment_complete");
				var agreementContent = $('.paper')[0].innerHTML;
				var today = new Date();
				var formattedDate = formatDate(today);
				$.ajax({
					async: false,
					type: "POST",
					url: "/createAgreementEmployeeFsa",
					data:
						JSON.stringify({
							agreementContent: agreementContent,
							authName: this.get('agreementName'),
							authDate: formattedDate,
							authSignature: this.get('agreementSignature'),
							enrollmentType: 'Employee FSA Agreement',
							isRenewal: this.get('isRenewal'),
							documentType: 'Enrollment Agreement',
						}),
					contentType: 'application/json',
					success: function(result) {}
				});

				return fsa.reload();
			}.bind(this)).then(function() {
				var o = Ember.Object.create({
					hasPayFrequency: this.get('hasPayFrequency'),
					effectiveDate: fsa.get('startDate'),
					totalPayPeriodContribution: fsa.get('payperiodTotal')
				});
				this.send('showModal', this.get('modal'), o);
			}.bind(this));
		} else {
			this.set('errorText', 'Some fields are missing, all fields are required.');
		}
	}
});

App.EmployeeFsafinishController = App.EmployeeFsaController.extend(App.FsaAgreementSignMixin, {
	agreementName: Ember.computed.alias('currFsa.name'),
	agreementSignature: Ember.computed.alias('currFsa.signature'),
	modal: 'employee.fsanextsteps',
	isRenewal: false,
	save: function() {
		this.checkAndSign();
	}
});

App.EmployeeFsanextstepsController = Ember.ObjectController.extend();

zen._CloseModalWithNextRouteMixin = Ember.Mixin.create({
	close: function(){
		this.send('hideModal');
		this.transitionToRoute(this.get('nextRoute'));
	}
});

App.BankinfoupdatedmodalController = Ember.ObjectController.extend(zen._CloseModalWithNextRouteMixin);

App.EmployeeFsaHsagoodnewsController = Ember.Controller.extend({
	goBack: function() {
		this.send('hideModal');
	},
	continue: function() {
		this.get('model').save();
	}
});
App.EmployeeFsaHsawarningController = Ember.Controller.extend({
	goBack: function() {
		this.get('model').send('hideModal');
	},
	continue: function() {
		this.get('model').send('hideModal');
		this.get('model').save();
	}
});





App.EmployeeFsarenewalgetstartedRoute = Ember.Route.extend(_AuthEmployeeMixin, _MultiModelMixin, {
	model: function() {
		return this.all(dashboard('employee'), {'company': dashboard('company')});
	},
	afterModel: function(model) {
		var promises = [];
		promises = promises.concat([
			thenpath(model, 'fsa.renewal'),
			thenpath(this.otherModels.company, 'fsa.renewal'),
		]);
		return Ember.RSVP.all(promises);
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('companyFsa', controller.get('company.fsa.renewal'));
		controller.set('nextRoute', controller.get('fsa.renewal.bankAccountType') ? 'employee.fsarenewal.bank' : 'employee.fsarenewal.basicinfo');
	},
	redirect: function(model, transition) {
		if(App.switches.isActive('employee_fsa_v2_enrollment')) {
			this.transitionTo('fsaSetup');
		}
	}
});

App.EmployeeFsarenewalRoute = Ember.Route.extend(_AuthEmployeeMixin, {
	model: function () {
		var employeeEligibility = Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function(result) {
			return result;
		});
		return Ember.RSVP.hash({
			'employee': dashboard('employee'),
			'employeeEligibility': employeeEligibility,
			'company': dashboard('company'),
			'fsa': dashboard('employee.fsa'),
			'currFsa': dashboard('employee.fsa.renewal'),
			'companyEnrollment': dashboard('company.fsa'),
			'companyFsa': dashboard('employee.fsa.renewal.companyPlan'),
			'alegeusSettings': dashboard('employee.alegeusSettings'),
			'hsa': dashboard('employee.hsa'),
			'companyHsa': dashboard('employee.company.hsa')
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		controller.set('isRenewal', true);
		var fsaRenewal = model['currFsa'];
		if (fsaRenewal && fsaRenewal.get('status') == 'yet-to-start-renewal') {
			var transaction = App.store.transaction();
			transaction.add(fsaRenewal);
			fsaRenewal.set('status', 'filling-out');
			transaction.commit();
		}
	},
	redirect: function(model, transition) {
		var fsaRenewal = model['currFsa'];
		if (fsaRenewal && fsaRenewal.get('status') == 'complete') {
			this.transitionTo('employee.fsasettings.overview');
		}

		if(App.switches.isActive('employee_fsa_v2_enrollment')) {
			this.transitionTo('fsaSetup');
		}
	},
	renderTemplate: function(controller, model) {
		this.render('employee.fsa', {controller: controller});
	},
});

App.EmployeeFsarenewalController = App.EmployeeFsaController.extend({
	isBankInfoComplete: Ember.computed.oneWay('alegeusSettings.isBankInfoComplete'),
	isBasicInfoComplete: Ember.computed.oneWay('fsa.isBasicInfoComplete'),
	basicRoute: 'employee.fsarenewal.basicinfo',
	contactRoute: 'employee.fsarenewal.contact',
	bankRoute: 'employee.fsarenewal.bank',
	contributionRoute: 'employee.fsarenewal.contribution',
	currFsa: Ember.computed.alias('fsa.renewal'),
});

App.EmployeeFsarenewalBasicinfoRoute = App.EmployeeFsarenewalRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.fsa.basicinfo', {controller: controller});
	},
});

App.EmployeeFsarenewalBasicinfoController = App.EmployeeFsarenewalController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isBasicInfoComplete',
	nextRoute: 'employee.fsarenewal.contact',
	continue: function() {
		// we are reloading 'fsa' because isBasicInfoComplete flag is on the fsa model (not on the fsa.renewal model)
		this.save([this.get('fsa')]);
	}
});

App.EmployeeFsarenewalContactRoute = App.EmployeeFsarenewalRoute.extend({
	renderTemplate: function(controller, model) {
		this.render('employee.fsa.contact', {controller: controller});
	},
});

App.EmployeeFsarenewalContactController = App.EmployeeFsarenewalController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isContactInfoComplete',
	nextRoute: 'employee.fsarenewal.bank',
	prevRoute: 'employee.fsarenewal.basicinfo',
	continue: function() {
		// we are reloading 'fsa' because the isContactInfoComplete flag is on the fsa model (not on the fsa.renewal model)
		this.save([this.get('fsa')]);
	}
});

App.EmployeeFsarenewalBankRoute = App.EmployeeFsarenewalRoute.extend(App.AlegeusEmployeeBankMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.fsa.bank', {controller: controller});
	},
});

App.EmployeeFsarenewalBankController = App.EmployeeFsarenewalController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, App._AlegeusBankSwitchMixin, {
	checkCompleteAfterSave: 'isBankInfoComplete',
	nextRoute: 'employee.fsarenewal.contribution',
	prevRoute: 'employee.fsarenewal.contact',
});

App.EmployeeFsarenewalContributionRoute = App.EmployeeFsarenewalRoute.extend(App.EmployeeFsaMedicalOptOutRouteMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.fsa.contribution', {controller: controller});
	},
});

App.EmployeeFsarenewalContributionController = App.EmployeeFsarenewalController.extend(App.EmployeeFsaMedicalOptOutControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, App._FsaEmployeeContributionControllerMixin, {
	checkCompleteAfterSave: 'isContributionComplete',
	nextRoute: function(){
		// Redirect to page that is not complicated yet
		if (!this.get('isBasicInfoComplete')){
			return 'employee.fsarenewal.basicinfo';
		} else if(!this.get('isContactInfoComplete')){
			return 'employee.fsarenewal.contact';
		} else if(!this.get('isBankInfoComplete')){
			return 'employee.fsarenewal.bank';
		} else {
			return 'employee.fsarenewalfinish';
		}
	}.property('isCompanyInfoComplete', 'isContactInfoComplete', 'isBankInfoComplete'),
	prevRoute: 'employee.fsarenewal.bank',
	continue: function() {
		this.save([this.get('fsa.renewal')]);
	}
});


App.EmployeeFsarenewalfinishRoute = App.EmployeeFsarenewalRoute.extend(App.SignatureFetchOrCreateMixin, {
	renderTemplate: function(controller, model) {
		this.render('employee.fsafinish', {controller: controller});
	},
	baseModelPath: 'currFsa',
	signatureName: 'agreementSignature',
});


App.EmployeeFsarenewalfinishController = App.EmployeeFsaController.extend(App.FsaAgreementSignMixin, {
	agreementSignature: Ember.computed.alias('currFsa.agreementSignature.signature'),
	agreementName: Ember.computed.alias('currFsa.agreementSignature.signatureName'),
	modal: 'employee.fsanextsteps',
	isRenewal: true,
	currFsa: Ember.computed.alias('fsa.renewal'),
	save: function() {
		return this.get('currFsa.agreementSignature').save().then(function () {
			return this.checkAndSign();
		}.bind(this));
	},
});

App.EmployeeFsarenewalnextstepsController = Ember.ObjectController.extend();

_EmployeeUcommuterRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, _AuthEmployeeMixin, _MultiModelMixin, {
	model: function() {
		var employeeEligibility = Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function(result) {
			return result;
		});
		return Ember.RSVP.hash({
			employee: dashboard('employee'),
			employeeEligibility: employeeEligibility,
			company: dashboard('company'),
			unicardCommuter: dashboard('employee.unicardCommuter'),
			alegeusSettings: dashboard('employee.alegeusSettings'),
			companyCommuter: dashboard('company.unicardCommuter'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		controller.set('companyUnicardCommuter', model['companyCommuter']);
	},
});

App.EmployeeUcommuterRoute = _EmployeeUcommuterRoute.extend({
	redirect: function(model, transition) {
		this._super(model, transition);
		if (!model['unicardCommuter'].get('isEligible')) {
			this.transitionTo('index');
			return;
		}
		if (model['unicardCommuter'].get('status') == 'complete') {
			this.transitionTo('employee.ucommutersettings.overview');
			return;
		}
		if(App.switches.isActive('employee_ucommuter_v2_enrollment')) {
			this.transitionTo('employee.ucommuterSetup.introduction.about');
		}
	}
});

App.EmployeeUcommuterIntroRoute = App.EmployeeUcommuterRoute.extend();

App.EmployeeUcommuterController = _TransactionSavingController.extend({
	isBasicInfoComplete: Ember.computed.oneWay('unicardCommuter.isBasicInfoComplete'),
	isContactInfoComplete: Ember.computed.oneWay('unicardCommuter.isContactInfoComplete'),
	isBankInfoComplete: Ember.computed.oneWay('alegeusSettings.isBankInfoComplete'),
	isContributionComplete: function() {
		if (!this.get('unicardCommuter.employeeMassTransitContribution') || !this.get('unicardCommuter.employeeParkingContribution')) {
			return false;
		}
		var isCompanyContributing = this.get('companyUnicardCommuter.isCompanyContributing');
		if (!isCompanyContributing) {
			return true;
		}
		return this.get('unicardCommuter.employerTransitContribution') && this.get('unicardCommuter.employerParkingContribution');
	}.property('unicardCommuter.employerTransitContribution', 'unicardCommuter.employeeMassTransitContribution', 'unicardCommuter.employerParkingContribution', 'unicardCommuter.employeeParkingContribution',
			'companyUnicardCommuter.isCompanyContributing'),
	setBeforeCheck: function() {
		if (this.get('unicardCommuter.status') == null) {
			this.set('unicardCommuter.status', 'filling-out');
		}
	}
});

App.EmployeeUcommuterBasicinfoRoute = App.EmployeeUcommuterRoute.extend();
App.EmployeeUcommuterBasicinfoController = App.EmployeeUcommuterController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isBasicInfoComplete',
	nextRoute: 'employee.ucommuter.contact',
	continue: function() {
		this.save([this.get('unicardCommuter')]);
	}
});

App.EmployeeUcommuterContactRoute = App.EmployeeUcommuterRoute.extend();
App.EmployeeUcommuterContactController = App.EmployeeUcommuterController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, {
	checkCompleteAfterSave: 'isContactInfoComplete',
	prevRoute: 'employee.ucommuter.basicinfo',
	nextRoute: 'employee.ucommuter.bank',
	continue: function() {
		this.save([this.get('unicardCommuter')]);
	}
});

App.EmployeeUcommuterBankRoute = App.EmployeeUcommuterRoute.extend(App.AlegeusEmployeeBankMixin);
App.EmployeeUcommuterBankController = App.EmployeeUcommuterController.extend(App.CheckAndShowErrorOrSaveAndContinueMixin, App._AlegeusBankSwitchMixin, {
	checkCompleteAfterSave: 'isBankInfoComplete',
	prevRoute: 'employee.ucommuter.contact',
	nextRoute: 'employee.ucommuter.contribution',
});

App.EmployeeUcommuterContributionRoute = App.EmployeeUcommuterRoute.extend({
	actions: {
		willTransition: function() {
			var superRes = this._super();
			this.controller.get('unicardCommuter').rollback();
			return superRes;
		}
	}
});
App.EmployeeUcommuterContributionController = App.EmployeeUcommuterController.extend(App.ValidationsControllerMixin, {
	prevRoute: 'employee.ucommuter.bank',
	totalTransitContribution: function() {
		var employerTransitContribution = this.get('unicardCommuter.employerTransitContribution');
		if (!employerTransitContribution) {
			employerTransitContribution = 0;
		}
		else {
			employerTransitContribution = parseFloat(employerTransitContribution);
		}

		var employeeMassTransitContribution = this.get('unicardCommuter.employeeMassTransitContribution');
		if (!employeeMassTransitContribution) {
			employeeMassTransitContribution = 0;
		}
		else {
			employeeMassTransitContribution = parseFloat(employeeMassTransitContribution);
		}

		return employerTransitContribution + employeeMassTransitContribution;
	}.property('unicardCommuter.employerTransitContribution', 'unicardCommuter.employeeMassTransitContribution'),
	totalParkingContribution: function() {
		var employerParkingContribution = this.get('unicardCommuter.employerParkingContribution');
		if (!employerParkingContribution) {
			employerParkingContribution = 0;
		}
		else {
			employerParkingContribution = parseFloat(employerParkingContribution);
		}

		var employeeParkingContribution = this.get('unicardCommuter.employeeParkingContribution');
		if (!employeeParkingContribution) {
			employeeParkingContribution = 0;
		}
		else {
			employeeParkingContribution = parseFloat(employeeParkingContribution);
		}

		return employerParkingContribution + employeeParkingContribution;
	}.property('unicardCommuter.employerParkingContribution', 'unicardCommuter.employeeParkingContribution'),
	remainingEmployerContribution: function() {
		var employerContribution = this.get('unicardCommuter.employerContribution');
		if (!employerContribution) {
			return 0;
		}
		else {
			employerContribution = parseFloat(employerContribution);
			if (employerContribution == 0) {
				return 0;
			}
		}

		var massTransitContribution = this.get('unicardCommuter.employerTransitContribution');
		if (!massTransitContribution) {
			massTransitContribution = 0;
		}
		else {
			massTransitContribution = parseFloat(massTransitContribution);
		}

		var parkingContribution = this.get('unicardCommuter.employerParkingContribution');
		if (!parkingContribution) {
			parkingContribution = 0;
		}
		else {
			parkingContribution = parseFloat(parkingContribution);
		}

		var remainingContribution = employerContribution - massTransitContribution - parkingContribution;

		if (remainingContribution < 0) {
			return 0;
		}

		return remainingContribution;
	}.property('unicardCommuter.employerContribution', 'unicardCommuter.employerTransitContribution', 'unicardCommuter.employerParkingContribution'),
	exceedsPreTaxThreshold: function() {
		var exceedsPreTaxThreshold = false;
		var unicardCommuter = this.get('unicardCommuter');
		if (parseFloat(this.get('totalTransitContribution')) > 255) {
			exceedsPreTaxThreshold = true;
		}
		if (parseFloat(this.get('totalParkingContribution')) > 255) {
			exceedsPreTaxThreshold = true;
		}
		return exceedsPreTaxThreshold;
	}.property('totalTransitContribution', 'totalParkingContribution'),
	continue: function() {
		// If previous sections are not complete, redirect user to complete them
		if(!this.get('isBasicInfoComplete')){
			this.transitionTo('employee.ucommuter.basicinfo');
			return;
		} else if(!this.get('isContactInfoComplete')){
			this.transitionTo('employee.ucommuter.contact');
			return;
		} else if(!this.get('isBankInfoComplete')){
			this.transitionTo('employee.ucommuter.bank');
			return;
		}

		if (this.get('isContributionComplete')) {
			var employerContribution = this.get('unicardCommuter.employerContribution');
			if (!employerContribution) {
				employerContribution = 0;
			} else {
				employerContribution = parseFloat(employerContribution);
			}
			var employerTransitContribution = this.get('unicardCommuter.employerTransitContribution');
			if (!employerTransitContribution) {
				employerTransitContribution = 0;
			} else {
				employerTransitContribution = parseFloat(employerTransitContribution);
			}
			var employerParkingContribution = this.get('unicardCommuter.employerParkingContribution');
			if (!employerParkingContribution) {
				employerParkingContribution = 0;
			} else {
				employerParkingContribution = parseFloat(employerParkingContribution);
			}
			if ((employerTransitContribution + employerParkingContribution) > employerContribution) {
				this.set('errorText', 'The total employer contribution that can be allocated between mass transit and parking is only $' + employerContribution + ".");
				return;
			}

			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return;
			}

			var unicardCommuter = this.get('unicardCommuter');
			unicardCommuter.set('startDate', unicardCommuter.get('computedStartDate'));
			unicardCommuter.set('status', 'complete');
			unicardCommuter.set('massTransitContribution', this.get('totalTransitContribution'));
			unicardCommuter.set('parkingContribution', this.get('totalParkingContribution'));
			this.saveAndContinue().then(function() {
				var o = Ember.Object.create({
					hasPayFrequency: this.get('hasPayFrequency'),
					effectiveDate: unicardCommuter.get('computedStartDate'),
					todayLessThanTenth: new Date().getDate() <= 10,
					totalPayPeriodContribution: unicardCommuter.get('payperiodTotal')
				});
				console.log(o);
				this.send('showModal', 'employee.ucommuternextsteps', o);
			}.bind(this));
		} else {
			this.set('errorText', 'Some fields are missing, all fields are required.');
			return;
		}
	},
});

App.EmployeeUcommuternextstepsController = Ember.ObjectController.extend();

_EmployeeHraRoute = Ember.Route.extend(zen._RollbackOnDeactivateMixin, _AuthEmployeeMixin, {
	model: function() {
		var employeeEligibility = Ember.ajaxGet('/custom_api/getSelfLegacyEmployeeEligibility').then(function(result) {
			return result;
		});
		return Ember.RSVP.hash({
			'employee': dashboard('employee'),
			'employeeEligibility': employeeEligibility,
			'alegeusSettings': dashboard('employee.alegeusSettings'),
			'company': dashboard('company'),
			'hra': dashboard('employee.hra'),
			'currPlan': dashboard('employee.hra.current'),
			'enrollmentPlan': dashboard('employee.hra.next'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		var enrollmentPlan = model.enrollmentPlan;
		if (enrollmentPlan && (Ember.isEmpty(enrollmentPlan.get('status'))||
				enrollmentPlan.get('status') == 'yet-to-start-renewal' || enrollmentPlan.get('status') == 'decline-filling')) {
			enrollmentPlan.set('status', 'filling-out');
			App.store.commit();
		}
	},
	redirect: function(model, transition) {
		var currPlan = model.currPlan;
		var enrollmentPlan = model.enrollmentPlan;
		if(enrollmentPlan && !enrollmentPlan.get('isComplete') &&
				!enrollmentPlan.get('isPastEnrollmentDeadline') && !enrollmentPlan.get('isDecline')) {
			return;
		}
		if((currPlan && currPlan.get('isComplete')) || (enrollmentPlan && enrollmentPlan.get('isComplete'))) {
			this.transitionTo('employee.hrasettings.overview');
		} else {
			this.transitionTo('index');
		}
	},
});
zen._EmployeeHraControllerMixin = Ember.Mixin.create({
	employee: Ember.computed.alias('model.employee'),
	alegeusSettings: Ember.computed.alias('model.alegeusSettings'),
	company: Ember.computed.alias('model.company'),
	hra: Ember.computed.alias('model.hra'),
	isRenewal: Ember.computed.alias('model.currPlan'),
	enrollmentPlan: Ember.computed.alias('model.enrollmentPlan'),
	companyPlan: Ember.computed.alias('enrollmentPlan.companyPlan'),
});

App.EmployeeHraIntroRoute = _EmployeeHraRoute.extend();
App.EmployeeHraIntroController = Ember.Controller.extend(zen._EmployeeHraControllerMixin);

App.EmployeeHraRoute = _EmployeeHraRoute.extend();
App.EmployeeHraController = _TransactionSavingController.extend(zen._EmployeeHraControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, {
	basicRoute: 'employee.hra.basicinfo',
	contactRoute: 'employee.hra.contact',
	bankRoute: 'employee.hra.bank',
	medicareRoute: 'employee.hra.medicare',
	isBasicInfoComplete: Ember.computed.oneWay('hra.isBasicInfoComplete'),
	isContactInfoComplete: Ember.computed.oneWay('hra.isContactInfoComplete'),
	// keeping medical info only in settings object
	isMedicareComplete: Ember.computed.oneWay('hra.isMedicareComplete'),
	isBankInfoComplete: Ember.computed.oneWay('alegeusSettings.isBankInfoComplete'),
});

App.EmployeeHraBasicinfoRoute = App.EmployeeHraRoute.extend();
App.EmployeeHraBasicinfoController = App.EmployeeHraController.extend({
	checkCompleteAfterSave: 'isBasicInfoComplete',
	nextRoute: 'employee.hra.contact',
	continue: function() {
		this.save([this.get('hra')]);
	}
});

App.EmployeeHraContactRoute = App.EmployeeHraRoute.extend();
App.EmployeeHraContactController = App.EmployeeHraController.extend({
	checkCompleteAfterSave: 'isContactInfoComplete',
	nextRoute: 'employee.hra.bank',
	prevRoute: 'employee.hra.basicinfo',
	continue: function() {
		this.save([this.get('hra')]);
	}
});

App.EmployeeHraBankRoute = App.EmployeeHraRoute.extend(App.AlegeusEmployeeBankMixin);
App.EmployeeHraBankController = App.EmployeeHraController.extend(App._AlegeusBankSwitchMixin, {
	checkCompleteAfterSave: 'isBankInfoComplete',
	nextRoute: 'employee.hra.medicare',
	prevRoute: 'employee.hra.contact',
});

App.EmployeeHraMedicareRoute = App.EmployeeHraRoute.extend();
App.EmployeeHraMedicareController = App.EmployeeHraController.extend({
	checkCompleteAfterSave: 'isMedicareComplete',
	prevRoute: 'employee.hra.bank',
	nextRoute: 'employee.hrafinish',
	continue: function() {
		if (this.get('employeeEligibility.isEligibleForHSA')) {
			this.send('showModal', 'employee.hra.warning', this);
		} else {
			this.save([this.get('enrollmentPlan')]);
		}
	}
});

App.EmployeeHraWarningRoute = App.EmployeeHraRoute.extend();
App.EmployeeHraWarningController = App.EmployeeHraController.extend({
	nextRoute: 'employee.hrafinish',
	postSaveCallback: function() {
		this.send('hideModal');
		this.transitionToRoute(this.get('nextRoute'));
	},
	continue: function() {
		this.save([this.get('enrollmentPlan')]);
	},
});

App.EmployeeHrafinishRoute = _EmployeeHraRoute.extend(App.SignatureFetchOrCreateMixin, {
	baseModelPath: 'enrollmentPlan',
	signatureName: 'agreementSignature',
	redirect: function(model, transition) {
		var hra = model.hra;
		var alegeusSettings = model.alegeusSettings;
		if(!hra.get('isBasicInfoComplete')){
			this.transitionTo('employee.hra.basicinfo');
		} else if(!hra.get('isContactInfoComplete')){
			this.transitionTo('employee.hra.contact');
		} else if(!hra.get('isMedicareComplete')) {
			this.transitionTo('employee.hra.medicare');
		} else if(!alegeusSettings.get('isBankInfoComplete')){
			this.transitionTo('employee.hra.bank');
		}
	},
});
App.EmployeeHrafinishController = _TransactionSavingController.extend(zen._EmployeeHraControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, {
	signatureName: Ember.computed.alias('enrollmentPlan.agreementSignature.signatureName'),
	signature: Ember.computed.alias('enrollmentPlan.agreementSignature.signature'),
	save: function() {
		if (this.get('signatureName') && this.get('signature')) {
			var enrollmentPlan = this.get('enrollmentPlan');
			enrollmentPlan.set('status', 'complete');
			// save signature first, if any
			var signatureObj = enrollmentPlan.get('agreementSignature');
			signatureObj.save().then(function() {
				return this.saveAndContinue();
			}.bind(this)).then(function() {
				var agreementContent = $('.paper')[0].innerHTML;
				var today = new Date();
				var formattedDate = formatDate(today);
				$.ajax({
					async: false,
					type: "POST",
					url: "/custom_api/createAgreementEmployeeHra",
					data:
						JSON.stringify({
							agreementContent: agreementContent,
							authName: this.get('signatureName'),
							authDate: formattedDate,
							authSignature: this.get('signature'),
							enrollmentType: 'hra',
							isRenewal: this.get('isRenewal'),
						}),
					contentType: 'application/json',
					success: function(result) {}
				});

				return this.get('enrollmentPlan').reload();
			}.bind(this)).then(function(){
				var startDate = enrollmentPlan.get('startDate');
				var o = Ember.Object.create({
					effectiveDate: enrollmentPlan.get('startDate')
				});
				this.send('showModal', 'employee.hranextsteps', o);
			}.bind(this));
		} else {
			this.set('errorText', 'Some fields are missing, all fields are required.');
		}
	}
});

App.EmployeeHranextstepsController = Ember.ObjectController.extend();

App.EmployeeHrarenewalgetstartedRoute = _EmployeeHraRoute.extend();
App.EmployeeHrarenewalgetstartedController = Ember.Controller.extend(zen._EmployeeHraControllerMixin);

App.EmployeeHradeclineRoute = _EmployeeHraRoute.extend(App.SignatureFetchOrCreateMixin, {
	baseModelPath: 'enrollmentPlan',
	signatureName: 'agreementSignature',
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('errorText', '');
		var enrollmentPlan = model.enrollmentPlan;
		var agreementSignature = enrollmentPlan.get('agreementSignature');
		if (enrollmentPlan && (Ember.isEmpty(enrollmentPlan.get('status'))||
				enrollmentPlan.get('status') == 'yet-to-start-renewal' || enrollmentPlan.get('status') == 'filling-out')) {
			enrollmentPlan.set('status', 'decline-filling');
			App.store.commit().then(function() { enrollmentPlan.set('agreementSignature', agreementSignature); });
		}
	},
});
App.EmployeeHradeclineController = _TransactionSavingController.extend(zen._EmployeeHraControllerMixin, App.CheckAndShowErrorOrSaveAndContinueMixin, {
	signatureName: Ember.computed.alias('enrollmentPlan.agreementSignature.signatureName'),
	signature: Ember.computed.alias('enrollmentPlan.agreementSignature.signature'),
	save: function() {
		if (this.get('signatureName') && this.get('signature')) {
			var enrollmentPlan = this.get('enrollmentPlan');
			enrollmentPlan.set('status', 'decline');
			// save signature first, if any
			var signatureObj = enrollmentPlan.get('agreementSignature');
			// reloading enrollmentPlan object because it is changed server side
			signatureObj.save().then(function() {
				return this.saveAndContinue('index');
			}.bind(this));
			return ;
		}
		this.set('errorText', 'Some fields are missing, all fields are required.');
	}
});









App.EmployeeLtdEnrollRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.ltdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeLtdEnrollController = _TransactionSavingController.extend({
	viewGlossary: function() {
		var ltdPlan = this.get('model');
		this.send("showModal", "employee.life.ltddetails", ltdPlan);
	},
	enroll: function() {
		this.transitionToRoute('employee.ltd.enrollfinish');
	},
	decline: function() {
		this.transitionToRoute('employee.ltd.decline');
	}
});

App.EmployeeLtdEnrollfinishRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.ltdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeLtdEnrollfinishController = _TransactionSavingController.extend({
	save: function() {
		var employeeLtdPlan = this.get('model');
		employeeLtdPlan.set('status', 'pending_approval');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.stdltd.overview');
		}.bind(this));
	},
	back: function() {
		this.transitionToRoute('employee.ltd.enroll');
	}
});

App.EmployeeLtdDeclineRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.ltdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeLtdDeclineController = _TransactionSavingController.extend({
	save: function() {
		var employeeLtdPlan = this.get('model');
		employeeLtdPlan.set('status', 'decline');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.stdltd.overview');
		}.bind(this));
	},
	back: function() {
		this.transitionToRoute('employee.ltd.enroll');
	}
});

App.EmployeeStdEnrollRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.stdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeStdEnrollController = _TransactionSavingController.extend({
	enroll: function() {
		this.transitionToRoute('employee.std.enrollfinish');
	},
	decline: function() {
		this.transitionToRoute('employee.std.decline');
	}
});

App.EmployeeStdEnrollfinishRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.stdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeStdEnrollfinishController = _TransactionSavingController.extend({
	save: function() {
		var employeeStdPlan = this.get('model');
		employeeStdPlan.set('status', 'pending_approval');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.stdltd.overview');
		}.bind(this));
	},
	back: function() {
		this.transitionToRoute('employee.std.enroll');
	}
});

App.EmployeeStdDeclineRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee.stdPlan'), {'company': dashboard('company')});
	}
});
App.EmployeeStdDeclineController = _TransactionSavingController.extend({
	save: function() {
		var employeeStdPlan = this.get('model');
		employeeStdPlan.set('status', 'decline');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('employee.stdltd.overview');
		}.bind(this));
	},
	back: function() {
		this.transitionToRoute('index');
	}
});


App.EmployeeStdltdRoute =  Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee'), {
			'employeeStdPlan': dashboard('employee.stdPlan'),
			'employeeLtdPlan': dashboard('employee.ltdPlan'),

		});
	},
	afterModel: function(model){
		return this.all(model, {
			'employerStdPlan': dashboard('employee.stdPlan').then(function(employeeStdPlan) {
					if(employeeStdPlan!=null && employeeStdPlan.get('employerSTDPlan')) {
						return App.EmployerStdPlan.find(employeeStdPlan.get('employerSTDPlan.id'));
					}
				}),
			'employerLtdPlan': dashboard('employee.ltdPlan').then(function(employeeLtdPlan) {
					if(employeeLtdPlan!=null && employeeLtdPlan.get('employerLTDPlan')) {
						return App.EmployerLtdPlan.find(employeeLtdPlan.get('employerLTDPlan.id'));
					}
				})
		});

	}
});

App.EmployeeStdltdController = Ember.ObjectController.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	productNavElements: function() {
		var listOfNavElements = [
			{
				label: 'Overview',
				routeName: 'employee.stdltd.overview',
			},
		];
		if (this.get('hasAnySpd')) {
			listOfNavElements.push({
				label: 'Summary Plan Description',
				routeName: 'employee.stdltd.spds',
			});
		}
		return listOfNavElements;
	}.property('hasAnySpd'),
		stdSpds: function(){
			if(this.get('employerStdPlan') && this.get('employerStdPlan').get('summaryPlanDescriptionDocument')) {
				return [this.get('employerStdPlan')];
			} else {
				return [];
			}
		}.property('employerStdPlan'),
		ltdSpds: function(){
			if(this.get('employerLtdPlan') && this.get('employerLtdPlan').get('summaryPlanDescriptionDocument')) {
				return [this.get('employerLtdPlan')];
			} else {
				return [];
			}
		}.property('employerLtdPlan'),
		hasStdSpd : Ember.computed.gt('stdSpds.length', 0),
		hasLtdSpd : Ember.computed.gt('ltdSpds.length', 0),
		hasAnySpd : Ember.computed.or('hasStdSpd','hasLtdSpd')
});

App.EmployeeStdltdSpdsRoute = App.EmployeeStdltdRoute.extend();

App.EmployeeStdltdSpdsController = App.EmployeeStdltdController.extend();

App.EmployeeStdltdOverviewRoute =  App.EmployeeStdltdRoute.extend();

App.EmployeeStdltdOverviewController = App.EmployeeStdltdController.extend();

App.EmployeeLifeLtddetailsController = Ember.ObjectController.extend();

function getSpdsPromise(allEhesPromise, lineOfCoverage) {
	return Ember.RSVP.hash({
		enrollments: allEhesPromise,
		ches: dashboard('employee.company.healthEnrollments'),
		carriers: dashboard('employee.company.healthCarriers'),
	}).then(function(res) {
		var enrollments = res.enrollments;
		var enrollment = enrollments.filter(function(enrollment){
			return enrollment.get('coverage_type') == lineOfCoverage && enrollment.get('status') =='complete';
		})[0];
		var ches = res.ches;
		var carriers = res.carriers;
		var promises = [];
		var activeIds = [];

		if(ches==null || carriers==null || enrollment==null){
			return;
		}

		ches.forEach(function(che) {
			if (enrollment.get('companyEnrollment.id') == che.get('id') && che.get('isActive')) {
				activeIds.push(che.get('companyHealthCarrier.id'));
			}
		});

		carriers.forEach(function(carrier) {
			if (activeIds.contains(carrier.get('id'))) {
				if (carrier.get('lineOfCoverage') == lineOfCoverage) {
					promises.pushObject(wrapArrayPromise(carrier.get('companyHealthPlans')));
				}
			}
		});

		return Ember.RSVP.all(promises);
	});
}


/* Employee settings page routes */
App.EmployeePlansettingsRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function() {
		var allEhesPromise = dashboard('employee').then(function(employee) {
			return App.EmployeeHealthEnrollment.find({
				'employee_id': employee.get('id'),
			});
		});

		return this.all(dashboard('employee'), {
			"company": dashboard('company'),
			"employee": dashboard('employee'),
			'employeeCancellationProperties': dashboard('employee.employeeCancellationProperties'),
			'employeeHealthStatusProperties': dashboard('employee.employeeHealthStatusProperties'),
			// [mdv 162]
			'medicalPlan': dashboard('employee.medicalPlan'),
			'dentalPlan': dashboard('employee.dentalPlan'),
			'visionPlan': dashboard('employee.visionPlan'),
			"dependents": dashboard('employee.dependents'),
			'companySettings': dashboard('company.settings'),
			'settings': dashboard('employee.employeeSettings'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'borStats': App.BorStats.find('me'),
			'medicalPromises': getSpdsPromise(allEhesPromise, 'medical'),
			'dentalPromises': getSpdsPromise(allEhesPromise, 'dental'),
			'visionPromises': getSpdsPromise(allEhesPromise, 'vision'),
			'_qualifyingEventsEnrollments': dashboard('employee.qualifyingEvents').then(function(qualifyingEvents) {
				return qualifyingEvents.map(function(qualifyingEvent) {
					return thenpath(qualifyingEvent, 'enrollments');
				});
			}),
			'zAppSubscriptions': App.ZAppInstallSubscription.findAll(),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var routeParts = this.routeName.split('.');
		if (routeParts.get('length') > 1) {
			this.refreshNavControllerFlags(routeParts);
		}
	},
	refreshNavControllerFlags: function (routeParts) {
		// initialize all routes to false, before setting the current to
		// true
		App.EmployeePlanSettingsNavController.set('isoverview', false);
		App.EmployeePlanSettingsNavController.set('isdependents', false);
		App.EmployeePlanSettingsNavController.set('isadddependents', false);
		App.EmployeePlanSettingsNavController.set('isremovedependents', false);
		App.EmployeePlanSettingsNavController.set('ischangeplan', false);
		App.EmployeePlanSettingsNavController.set('isapplyforinsurance', false);
		App.EmployeePlanSettingsNavController.set('iscancel', false);
		App.EmployeePlanSettingsNavController.set('isinsurancecards', false);
		var currentRouteName = routeParts[2];
		if (currentRouteName == 'proof' || currentRouteName == 'dependentdetails' ||
			currentRouteName == 'addfinish' || currentRouteName == 'personalinfo')
		{
			currentRouteName = "adddependents";
		}
		App.EmployeePlanSettingsNavController.set('is' + currentRouteName, true);
		App.EmployeePlanSettingsNavController.updateContent();
	},
	actions: {
		refreshNav: function () {
			var routeParts = this.routeName.split('.');
			this.refreshNavControllerFlags(routeParts);
		},
	}
});


_DelNoneDependentMixin = Ember.Mixin.create({
	_needToLoadEnrollmentPlan: function (enrollment) {
		return enrollment && (
			enrollment.get('isOpenEnrollmentOrSwitchCarrier') ||
			enrollment.get('qualifyingEvent'));
	},
	afterModel: function(model) {
		var promiseArray = [];
		var dependents = [];
		var noneDependents = [];

		var currentEnrollment = this.otherModels.currentEnrollment;
		if (currentEnrollment && this._needToLoadEnrollmentPlan(currentEnrollment) && currentEnrollment.get('medicalPlan')) {
			promiseArray.push(App.EPlan.find({
				id: currentEnrollment.get('medicalPlan.id')
			}).then(function(plans) {
				this.otherModels['currentPlan'] = plans.get('firstObject');
			}.bind(this)));
		}

		var currentDentalEnrollment = this.otherModels.currentDentalEnrollment;
		if (currentDentalEnrollment && this._needToLoadEnrollmentPlan(currentDentalEnrollment) && currentDentalEnrollment.get('dentalPlan')) {
			promiseArray.push(App.EDentalPlan.find({
				id: currentDentalEnrollment.get('dentalPlan.id')
			}).then(function(dentalPlans) {
				this.otherModels['currentDentalPlan'] = dentalPlans.get('firstObject');
			}.bind(this)));
		}

		var currentVisionEnrollment = this.otherModels.currentVisionEnrollment;
		if (currentVisionEnrollment && this._needToLoadEnrollmentPlan(currentVisionEnrollment) && currentVisionEnrollment.get('visionPlan')) {
			promiseArray.push(App.EVisionPlan.find({
				id: currentVisionEnrollment.get('visionPlan.id')
			}).then(function(visionPlans) {
				this.otherModels['currentVisionPlan'] = visionPlans.get('firstObject');
			}.bind(this)));
		}

		this.otherModels.dependents.forEach(function(dependent) {
			if (!dependent.get('firstName') && !dependent.get('lastName')) {
				promiseArray.push(dependent.get('previousCoverage'));
				noneDependents.push(dependent);
			}
			else {
				dependents.push(dependent);
				var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
				linesOfCoverage.forEach(function(lineOfCoverage) {
					if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
						var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
						dhes.forEach(function(dhe) {
							promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
						});
					}
				});
			}
		});
		this.otherModels['dependents'] = dependents;
		return Ember.RSVP.all(promiseArray).then(function() {
			return Ember.RSVP.all(noneDependents.map(function(dep) {
				return dep.destroyRecord();
			}));
		}).catch(function(err) {
			noneDependents.map(function(dep) {
				// There is no way to handle a failed DELETE gracefully in Ember.
				// Manually reset isDeleted, isSaving and savingPromise.
				if (dep.get('isSaving')) {
					dep.set('isDeleted', false);
					dep.set('isSaving', false);
					dep.set('savingPromise', null);
				}
			});
			return Ember.RSVP.resolve();
		}).then(function() {
			if (noneDependents.get('length')) {
				return model.reload();
			}
		});
	},
});


App.EmployeePlansettingsOverviewRoute = App.EmployeePlansettingsRoute.extend(_DelNoneDependentMixin, {
	model: function() {
		return this.all(dashboard('employee'), {
			"company": dashboard('company'),
			"employee": dashboard('employee'),
			"employeeBenefitsOfferingProperties": dashboard('employee.employeeBenefitsOfferingProperties'),
			"employeeCancellationProperties": dashboard('employee.employeeCancellationProperties'),
			'medicalPlan': dashboard('employee.medicalPlan'),
			'dentalPlan': dashboard('employee.dentalPlan'),
			'visionPlan': dashboard('employee.visionPlan'),
			"dependents": dashboard('employee.dependents'),
			'companySettings': dashboard('company.settings'),
			'settings': dashboard('employee.employeeSettings'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'borStats': App.BorStats.find('me'),
		});
	},
	afterModel: function(model) {
		var promiseArray = [];
		promiseArray.push(this._super(model));

		var currentEnrollment = model.get('currentEnrollment');
		var currentDentalEnrollment = model.get('currentDentalEnrollment');
		var currentVisionEnrollment = model.get('currentVisionEnrollment');

		var chosenMedicalPlan = currentEnrollment ? currentEnrollment.get('medicalPlan') : null;
		var chosenDentalPlan = currentDentalEnrollment ? currentDentalEnrollment.get('dentalPlan') : null;
		var chosenVisionPlan = currentVisionEnrollment ? currentVisionEnrollment.get('visionPlan') : null;

		var defaultMedicalPlan, defaultDentalPlan, defaultVisionPlan;
		var defaultMedicalEPlan, defaultDentalEPlan, defaultVisionEPlan;

		if (currentEnrollment && !chosenMedicalPlan && currentEnrollment.get('defaultPlan')) {
			defaultMedicalPlan = App.Plan.find(currentEnrollment.get('defaultPlan'));
			defaultMedicalEPlan = App.EPlan.find({
				id: currentEnrollment.get('defaultPlan'),
			}).then(function(plans) {
				return plans.get('firstObject');
			});
		} else {
			defaultMedicalPlan = defaultMedicalEPlan = null;
		}

		if (currentDentalEnrollment && !chosenDentalPlan && currentDentalEnrollment.get('defaultPlan')) {
			defaultDentalPlan = App.DentalPlan.find(currentDentalEnrollment.get('defaultPlan'));
			defaultDentalEPlan = App.EDentalPlan.find({
				id: currentDentalEnrollment.get('defaultPlan'),
			}).then(function(plans) {
				return plans.get('firstObject');
			});
		} else {
			defaultDentalPlan = defaultDentalEPlan = null;
		}

		if (currentVisionEnrollment && !chosenVisionPlan && currentVisionEnrollment.get('defaultPlan')) {
			defaultVisionPlan = App.VisionPlan.find(currentVisionEnrollment.get('defaultPlan'));
			defaultVisionEPlan = App.EVisionPlan.find({
				id: currentVisionEnrollment.get('defaultPlan')
			}).then(function(plans) {
				return plans.get('firstObject');
			});
		} else {
			defaultVisionPlan = defaultVisionEPlan = null;
		}

		promiseArray.push(this.all(model, {
			'chosenMedicalPlan': chosenMedicalPlan,
			'chosenDentalPlan': chosenDentalPlan,
			'chosenVisionPlan': chosenVisionPlan,
			'defaultMedicalPlan': defaultMedicalPlan,
			'defaultDentalPlan': defaultDentalPlan,
			'defaultVisionPlan': defaultVisionPlan,
			'defaultMedicalEPlan': defaultMedicalEPlan,
			'defaultDentalEPlan': defaultDentalEPlan,
			'defaultVisionEPlan': defaultVisionEPlan
		}));

		return Ember.RSVP.all(promiseArray);
	}
});

App.EmployeePlansettingsDependentsRoute = App.EmployeePlansettingsRoute.extend(_DelNoneDependentMixin, {
	model: function() {
		return this.all(dashboard('employee'), {
			"employee": dashboard('employee'),
			"dependents": dashboard('employee.dependents'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
		});
	},
});

App.EmployeePlansettingsSpdsRoute = App.EmployeePlansettingsRoute.extend(_MultiModelMixin, {
	model: function() {
		var allEhesPromise = dashboard('employee').then(function(employee) {
			return App.EmployeeHealthEnrollment.find({
				'employee_id': employee.get('id'),
			});
		});
		return this.all({
			"employee": dashboard('employee'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'medicalPromises': getSpdsPromise(allEhesPromise, 'medical'),
			'dentalPromises': getSpdsPromise(allEhesPromise, 'dental'),
			'visionPromises': getSpdsPromise(allEhesPromise, 'vision'),
		});
	},
});

_DupDependentMixin = Ember.Mixin.create({
	dupDependent: function() {
		var dependent = this.get('model');
		var duplicateDependentFound = false;

		this.get('dependents').forEach(function(dep) {
			if((dep.get('id') != dependent.get('id')) && !dep.get('isSelf')) {
				if (dep.get('firstName') == this.get('dep_firstName') && dep.get('lastName') == this.get('dep_lastName') && dep.get('type') == this.get('dep_type')) {
					duplicateDependentFound = true;
				}
			}
		}.bind(this));

		if (duplicateDependentFound) {
			this.set("errorText", this.get('dep_firstName') + " " + this.get('dep_lastName') + " has already been added as your dependent.");
			return true;
		}
		return false;
	}
});


App.EmployeeEditdependentRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	afterModel: function(model) {
		return this.all(model, {
			"dependents": dashboard('employee.dependents'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		App.EmployeePlanSettingsNavController.thisIsAddDependents();
		controller.set('dep_firstName', model.get('firstName'));
		controller.set('dep_lastName', model.get('lastName'));
		controller.set('dep_type', model.get('type'));
		controller.set('errorText', "");
	}
});


App.EmployeeEditdependentController = _TransactionSavingController.extend(_DupDependentMixin, {
	actions: {
		save: function() {
			var dependent = this.get('model');
			if (this.dupDependent()) {
				return;
			}
			this.set("errorText", "");

			dependent.set('firstName', this.get('dep_firstName'));
			dependent.set('lastName', this.get('dep_lastName'));
			return this.saveAndContinue('employee.plansettings.dependents').then(
				function() {
					$.ajax({
						url: '/custom_api/updateDependentToBT',
						type: "get",
						data: {
							"dependentId": dependent.get('id')
						},
					});
				}
			);
		}
	}
});


App.EmployeeDeclineInfoRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(App.DeclineInfo.find('me'), {
			'employee': dashboard('employee'),
			'employeeCobra': dashboard('employee.employeeCobra'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'companyHealthCarriers': dashboard('employee.company.healthCarriers')
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		var companyMedicalCarriers = controller.get('companyHealthCarriers');
		var medicalCarrierText = '';
		var multipleMedicalCarriers = false;
		companyMedicalCarriers.forEach(function(carrier) {
			if (carrier.get('lineOfCoverage') == 'medical') {
				if (medicalCarrierText == '') {
					medicalCarrierText += carrier.get('carrierDisplayName');
				}
				else {
					medicalCarrierText += ', ' + carrier.get('carrierDisplayName');
					multipleMedicalCarriers = true;
				}
			}
		});

		if (multipleMedicalCarriers) {
			medicalCarrierText += ' need';
		}
		else {
			medicalCarrierText += ' needs';
		}

		controller.set('medicalCarrierText', medicalCarrierText);
	}
});


App.EmployeeDeclineDentalinfoRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(App.DeclineInfo.find('me'), {
			'employee': dashboard('employee'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'companyHealthCarriers': dashboard('employee.company.healthCarriers')
		});
	},
});


App.EmployeeDeclineVisioninfoRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(App.DeclineInfo.find('me'), {
			'employee': dashboard('employee'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'companyHealthCarriers': dashboard('employee.company.healthCarriers')
		});
	},
});


App.EmployeeDeclineAttestRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		var employeePromise = dashboard('employee');
		var enrollmentsPromise = dashboard('employee.employeeEnrollmentProperties');
		var currentEnrollmentPromises = enrollmentsPromise.then(function(enrollments) {
			var enrollmentPromises = [
				enrollments.get('currentMedicalEnrollment'),
				enrollments.get('currentDentalEnrollment'),
				enrollments.get('currentVisionEnrollment'),
			];

			return Ember.RSVP.all(enrollmentPromises.filter(function(obj) {
				return !!obj;
			})).then(function(enrollments) {
				return Ember.RSVP.resolve(enrollments.filter(function(enrollment) {
					return enrollment && (enrollment.get('status') == 'decline' || (enrollment.get('status') == 'reviewed' && !enrollment.get('defaultPlan')));
				}));
			});
		});

		var selfDependentPromise = employeePromise.then(function(employee) {
			return wrapArrayPromise(employee.get('dependents'));
		}).then(function(dependents) {
			return dependents.filter(function(dependent) {
				return dependent.get('type') == 'self';
			}).get('firstObject');
		});

		var existingWaiversPromise = employeePromise.then(function(employee) {
			return App.EnrollmentWaiver.find({
				'enrollment__employee_id': employee.get('id'),
			});
		}).then(function(enrollmentWaivers) {
			return selfDependentPromise.then(function(selfDependent) {
				return enrollmentWaivers.filter(function(waiver) {
					return waiver.get('dependent.id') == selfDependent.get('id');
				});
			});
		});

		var preloadWaiveReasons = existingWaiversPromise.then(function(enrollmentWaivers) {
			return Ember.RSVP.all(enrollmentWaivers.map(function(waiver) {
				return waiver.get('waivers').then(function(waivers) {
					return waiver.set('waivers', waivers);
				});
			}));
		});

		var companyHealthCarriers = dashboard('company.healthCarriers');
		var carrierCopies = companyHealthCarriers.then(function(companyHealthCarriers) {
			return Ember.RSVP.all(companyHealthCarriers.mapBy('carrier'));
		}).then(function(carriers) {
			return Ember.RSVP.all(carriers.mapBy('copy'));
		});

		return this.all(employeePromise, {
			'dependents': dashboard('employee.dependents'),
			'company': dashboard('company'),
			'declineInfo': App.DeclineInfo.find('me'),
			'enrollments': currentEnrollmentPromises,
			'selfDependent': selfDependentPromise,
			'selfWaivers': existingWaiversPromise,
			'_preloadReasons': preloadWaiveReasons,
			'companyHealthCarriers': companyHealthCarriers,
			'carrierCopies': carrierCopies,
		});
	},
	afterModel: function(model) {
		var promiseArray = [];
		var dependents = this.otherModels.dependents;
		dependents.forEach(function(dependent) {
			var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
			linesOfCoverage.forEach(function(lineOfCoverage) {
				if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
					});
				}
			});
		});

		return Ember.RSVP.all(promiseArray);
	},
});


App.SelfWaiverRouteMixin = Ember.Mixin.create({
	selfWaiverDict: function() {
		var employeePromise = dashboard('employee');
		var selfDependentPromise = employeePromise.then(function(employee) {
			return wrapArrayPromise(employee.get('dependents'));
		}).then(function(dependents) {
			return dependents.filter(function(dependent) {
				return dependent.get('type') == 'self';
			}).get('firstObject');
		});

		var existingWaiversPromise = selfDependentPromise.then(function(dependent) {
			return App.EnrollmentWaiver.find({
				'dependent_id': dependent.get('id'),
			});
		});
		return {
			'employeePromise': employeePromise,
			'selfDependentPromise': selfDependentPromise,
			'existingWaiversPromise': existingWaiversPromise
		};
	}.property()
});

App.EmployeeDeclineDentalattestRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, App.SelfWaiverRouteMixin, {
	model: function(params) {
		var declineDentalPlan = dashboard('company').then(function(company) {
			var dentalPlanId = company.get('selectedDentalPlan');

			return dentalPlanId ? App.EDentalPlan.find(dentalPlanId) : null;
		});

		var enrollmentPromise = dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment');
		var selfWaiverDict = this.get('selfWaiverDict');
		return this.all(selfWaiverDict['employeePromise'], {
			'enrollment': enrollmentPromise,
			'company': dashboard('company'),
			'dependents': dashboard('employee.dependents'),
			'selfDependent': selfWaiverDict['selfDependentPromise'],
			'selfWaivers': selfWaiverDict['existingWaiversPromise'],
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'declineDentalPlan': declineDentalPlan,
		});
	},

	afterModel: function(model) {
		var promiseArray = [];
		var dependents = this.otherModels.dependents;

		dependents.forEach(function(dependent) {
			if(dependent.get('dependentDentalEnrollments.length')) {
				var dhes = dependent.get('dependentDentalEnrollments');
				dhes.forEach(function(dhe) {
					promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
				});
			}
		});
		return Ember.RSVP.all(promiseArray);
	},
});

App.EmployeeDeclineVisionattestRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, App.SelfWaiverRouteMixin, {
	model: function(params) {
		var declineVisionPlan = dashboard('company').then(function(company) {
			var visionPlanId = company.get('selectedVisionPlan');

			return visionPlanId ? App.EVisionPlan.find(visionPlanId) : null;
		});
		var enrollmentPromise = dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment');
		var selfWaiverDict = this.get('selfWaiverDict');
		return this.all(selfWaiverDict['employeePromise'], {
			'enrollment': enrollmentPromise,
			'company': dashboard('company'),
			'dependents': dashboard('employee.dependents'),
			'selfDependent': selfWaiverDict['selfDependentPromise'],
			'selfWaivers': selfWaiverDict['existingWaiversPromise'],
			'shortCircuitOEStats': App.ShortCircuitOpenEnrollmentStats.find('me'),
			'declineVisionPlan': declineVisionPlan,
		});
	},

	afterModel: function(model) {
		var promiseArray = [];
		var dependents = this.otherModels.dependents;

		dependents.forEach(function(dependent) {
			if(dependent.get('dependentVisionEnrollments.length')) {
				var dhes = dependent.get('dependentVisionEnrollments');
				dhes.forEach(function(dhe) {
					promiseArray.push(App.DependentHealthEnrollment.find(dhe.get('id')));
				});
			}
		});
		return Ember.RSVP.all(promiseArray);
	},
});



App.EmployeeConfirmbasicinfoRoute = Ember.Route.extend(_AuthEmployeeMixin, {
	model: function(params) {
		return dashboard('employee');
	}
});


App.EmployeeConfirmbasicinfoController = _TransactionSavingController.extend(App.ValidationsControllerMixin, {
	actions: {
		save: function() {
			if (this.get('anyErrors')) {
				this.set('errorText', this.get('validationErrorText'));
				return new Ember.RSVP.resolve();
			}
			var employee = this.get('model');
			if (!employee.get('first_name') || !employee.get('last_name') ||
				!employee.get('dob') || !employee.get('zip') || !employee.get('sex'))
			{
				this.set('errorText', 'All fields are required, please enter missing fields.');
				return new Ember.RSVP.resolve();
			}

			this.set('errorText', '');
			return this.saveAndContinue('employee.planselector');
		}
	}
});




/**
 * _BundlingMixin
 *
 * Sets up the state so that an EE can select bundled plans if carrier
 * bundling plans have been set-up.
 *
 * What is bundling:
 *   Some carriers offer certain plans only as part of a bundle. For example,
 *   if an EE selects a Kaiser Permanente medical plan, Kaiser sometimes requires
 *   that the EE ALSO selects Kaiser's dental plan.
 *
 * Bundling rule:
 *   If an EE has selected a bundled plan in one line of coverage, he/she MUST
 *   select plans offered by this carrier in other lines IF such plans are available
 *   and the carrier requires bundling along those lines of coverage.
 */
_BundlingMixin = zen._BundlingMixin = Ember.Mixin.create({
	MEDICAL: 'MEDICAL',
	DENTAL: 'DENTAL',
	VISION: 'VISION',
	/**
	 * Fetch all plans offered for each line of coverage
	 * as well as their associated PlanBundle rules.
	 *
	 * Return a promise which will be resolved with a bundling object
	 * that contains all information that will be accessed by other bundling
	 * functions.
	 *
	 * bundles object contains:
	 *   carriers: a dictionary mapping carrier IDs to carrier names
	 *   medicalPlans: array of medical plans offered to this EE
	 *   dentalPlans: array of dental plans offered to this EE
	 *   visionPlans: array of vision plans offered to this EE
	 *   medicalBundles: array of plan bundles that are associated with the carriers of the medical plans
	 *   dentalBundles: array of plan bundles that are associated with the carriers of the dental plans
	 *   visionBundles: array of plan bundles that are associated with the carriers of the vision plans
	 */
	loadBundles: function(medicalPlans, dentalPlans, visionPlans) {
		// Refactor: Can write a generic function to get this (instead of 1 per line of coverage)
		// Refactor: Should try to batch some of these requests.
		var promisesArray = [];

		var self = this;

		var bundles = {
			carriers: {}, // Map carrier IDs to carrier name
			medicalPlans: [],
			dentalPlans: [],
			visionPlans: [],
			medicalBundles: [],
			dentalBundles: [],
			visionBundles: [],
		};

		/* Medical */
		// Unavailable plans should not impact bundling rules
		promisesArray.push(medicalPlans.then(function(mPlans) {
			bundles.medicalPlans = mPlans;

			var secondaryPromisesArray = [];
			// Get plan bundles associated with each plan carrier
			mPlans.forEach(function(mPlan) {
				var carrierId = mPlan.get('stateCarrier.id');
				bundles.carriers[carrierId] = null; // We will later populate with carrier display name

				// Add medical plan bundles to model
				secondaryPromisesArray.push(App.PlanBundle.find({'q_carrier_id': carrierId, 'q_is_active': true}).then(function(mBundles){
					var existingMedicalBundles = bundles.medicalBundles;
					bundles.medicalBundles = existingMedicalBundles ? existingMedicalBundles.concat(mBundles.content) : mBundles.content;
				}));

				// Get carrier names
				secondaryPromisesArray.push(App.Carrier.find(carrierId).then(function(carrier) {
					bundles.carriers[carrier.get('id')] = carrier.get('displayName');
				}));
			});
			return Ember.RSVP.all(secondaryPromisesArray);
		}));

		/* Dental */
		promisesArray.push(dentalPlans.then(function(dPlans) {
			bundles.dentalPlans = dPlans;

			var secondaryPromisesArray = [];

			// Get plan bundles associated with each plan carrier
			dPlans.forEach(function(dPlan) {
				var carrierId = dPlan.get('stateCarrier.id');
				bundles.carriers[carrierId] = null; // We will later populate with carrier display name

				// Add dental plan bundles to model
				secondaryPromisesArray.push(App.PlanBundle.find({'q_carrier_id': carrierId, 'q_is_active': true}).then(function(dBundles){
					var existingDentalBundles = bundles.dentalBundles;
					bundles.dentalBundles = existingDentalBundles ? existingDentalBundles.concat(dBundles.content) : dBundles.content;
				}));

				// Get carrier names
				secondaryPromisesArray.push(App.Carrier.find(carrierId).then(function(carrier) {
					bundles.carriers[carrier.get('id')] = carrier.get('displayName');
				}));
			});
			return Ember.RSVP.all(secondaryPromisesArray);
		}));

		/* Vision */
		promisesArray.push(visionPlans.then(function(vPlans) {
			bundles.visionPlans = vPlans;

			var secondaryPromisesArray = [];

			// Get plan bundles associated with each plan carrier
			vPlans.forEach(function(vPlan) {
				var carrierId = vPlan.get('stateCarrier.id');
				bundles.carriers[carrierId] = null; // We will later populate with carrier display name

				// Add vision plan bundles to model
				secondaryPromisesArray.push(App.PlanBundle.find({'q_carrier_id': carrierId, 'q_is_active': true}).then(function(vBundles){
					var existingVisionBundles = bundles.visionBundles;
					bundles.visionBundles = existingVisionBundles ? existingVisionBundles.concat(vBundles.content) : vBundles.content;
				}));

				// Get carrier names
				secondaryPromisesArray.push(App.Carrier.find(carrierId).then(function(carrier) {
					bundles.carriers[carrier.get('id')] = carrier.get('displayName');
				}));
			});
			return Ember.RSVP.all(secondaryPromisesArray);
		}));

		return Ember.RSVP.all(promisesArray).then(function () {
			return bundles;
		});
	},
	// This function is run in each PlanSelectorRoute's setupController.
	// It sets up each plan selector's controller so that it has required
	// bundling data. I want to minimize as much bundling logic in each individual
	// route's setupController as possible.
	_bundlingSetup: function(controller) {
		// MultiModel Mixin already has a setUpController
		// so naming this differently avoids multiple inheritance issue.
		//
		// Note: MultiModel Mixin has moved all data from otherModels over
		// to the controller by the time this function executes.

		var selectedMedicalPlan = controller.get('currentEnrollment.medicalPlan');
		var selectedDentalPlan = controller.get('currentDentalEnrollment.dentalPlan');
		var selectedVisionPlan = controller.get('currentVisionEnrollment.visionPlan');

		controller.set('hasDeclinedBundledMedical', false);
		controller.set('hasDeclinedBundledDental', false);
		controller.set('hasDeclinedBundledVision', false);
		controller.set('hasSelectedBundledMedical', false);
		controller.set('hasSelectedBundledDental', false);
		controller.set('mustDeclineCoverage', false);

		var bundles = controller.get('bundles');
		var medicalBundles = bundles.medicalBundles;
		var dentalBundles = bundles.dentalBundles;
		var visionBundles = bundles.visionBundles;

		// Create a {carrierId : planBundles} so that we can quickly look
		// at a plan, look at its carrier ID, and then check its bundling rules.
		// Otherwise, {medical, dental, vision}Bundles is an array, and we'd have to iterate through that
		function populateCarrierToBundleMapHelper(bundle, carrierToBundleMap) {
			var carrierId = bundle.get('carrier.id');
			var carrierBundles = carrierToBundleMap[carrierId];
			if (!carrierBundles) {
				carrierToBundleMap[carrierId] = [bundle];
			}
			else {
				carrierToBundleMap[carrierId].push(bundle);
			}
		}

		var carrierToBundleMap = {};
		// Keep track of plan bundle IDs so we don't add duplicates
		var seenBundles = {};

		if (medicalBundles.length) {
			medicalBundles.forEach(function(mBundle){
				if (!seenBundles[mBundle.get('id')]) {
					seenBundles[mBundle.get('id')] = true;
					populateCarrierToBundleMapHelper(mBundle, carrierToBundleMap);
				}
			});
		}

		if (dentalBundles.length) {
			dentalBundles.forEach(function(dBundle){
				if (!seenBundles[dBundle.get('id')]) {
					seenBundles[dBundle.get('id')] = true;
					populateCarrierToBundleMapHelper(dBundle, carrierToBundleMap);
				}
			});
		}

		if (visionBundles.length) {
			visionBundles.forEach(function(vBundle){
				if (!seenBundles[vBundle.get('id')]) {
					seenBundles[vBundle.get('id')] = true;
					populateCarrierToBundleMapHelper(vBundle, carrierToBundleMap);
				}
			});
		}

		bundles.carrierToBundleMap = carrierToBundleMap;

		var currentEnrollment = controller.get('currentEnrollment');
		if (this.get('hasDeclinedBundledMedical').bind(this)(currentEnrollment)) {
			controller.set('hasDeclinedBundledMedical', true);
		}

		var currentDentalEnrollment = controller.get('currentDentalEnrollment');
		var declineStatusObj = this.get('hasDeclinedBundledDental').bind(this)(currentEnrollment, currentDentalEnrollment);
		if (declineStatusObj) {
			controller.set('hasDeclinedBundledDental', declineStatusObj);
		}

		var hasSelectedBundledMedical = this.get('hasSelectedBundledMedical').bind(controller)(selectedMedicalPlan);
		if (hasSelectedBundledMedical) {
			controller.set('hasSelectedBundledMedical', hasSelectedBundledMedical);
		}
		var hasSelectedBundledDental = this.get('hasSelectedBundledDental').bind(controller)(selectedMedicalPlan, selectedDentalPlan);
		if (hasSelectedBundledDental) {
			controller.set('hasSelectedBundledDental', hasSelectedBundledDental);
		}

		bundles['bundledPlans'] = this.get('computeBundledPlans').bind(controller)();
	},
	/**
	 * For each carrier's plan bundle, identify the plans
	 * that are bundled together for each particular plan bundle --
	 * organized by line of coverage.
	 *
	 * Example data structure:
	 * { 4 : [ {'MEDICAL': [medicalPlan1, medicalPlan2],
	 * 			'DENTAL': [dentalPlan1],
	 * 			'VISION: [visionPlan1]' }, {...} ],
	 * 	 5 : [ {'DENTAL': [dentalPlan2],
	 * 	 		'VISION': [visionPlan2]} ]
	 * }
	 */
	computeBundledPlans: function() {
		var bundles = this.get('bundles');
		var carrierToBundleMap = bundles['carrierToBundleMap'];

		var bundlingRelationships = {};
		var bundlingRules = {
			'MEDICAL' : 'isMedicalBundled',
			'DENTAL' : 'isDentalBundled',
			'VISION' : 'isVisionBundled',
		};

		var lineToPlanMap = {
			'MEDICAL': bundles['medicalPlans'],
			'DENTAL': bundles['dentalPlans'],
			'VISION': bundles['visionPlans'],
		};

		for (var carrierId in carrierToBundleMap) {
			var planBundles = carrierToBundleMap[carrierId];
			planBundles.forEach(function(planBundle) {
				var computedPlanBundle = {
					'MEDICAL': [],
					'DENTAL': [],
					'VISION': [],
				};

				var numLinesBundled = 0;
				for (var line in bundlingRules) {
					var bundlingRule = bundlingRules[line];
					var plans = lineToPlanMap[line];

					if (planBundle.get(bundlingRule)) {
						numLinesBundled++;
						computedPlanBundle[line] = plans.filter(function(p) {
							return p.get('stateCarrier.id') == carrierId;
						});
					}
				}
				if (numLinesBundled > 1) {
					if (bundlingRelationships[carrierId]) {
						bundlingRelationships[carrierId].push(computedPlanBundle);
					}
					else {
						bundlingRelationships[carrierId] = [computedPlanBundle];
					}
				}
			});
		}
		return bundlingRelationships;
	},
	/**
	 * Determines if an EE must select a dental plan offered by the carrier of a selected medical plan
	 * because the medical plan bundles Medical and Dental.
	 *
	 * @param  {Plan object}  medicalPlan : the medical plan that a user selected
	 * @return {false or object}
	 *    False: If the selected medical plan does not bundle with any dental plans
	 *    Object: If true, returns the object
	 *            {bundledWith: <string>, allBundledLines: <string>}
	 *            that is used to display text in template
	 */
	hasSelectedBundledMedical: function(medicalPlan) {
		var bundles = this.get('bundles');
		var dentalPlans = bundles['dentalPlans'];
		if (!dentalPlans) {
			return false;
		}

		var obj = {bundledWith: null, allBundledLines: null, carrierName: '', currLine: 'dental'};
		var selectedCarrier = '';
		var isBundledWithMedical = false;

		if (medicalPlan) {
			var carrierToBundleMap = bundles['carrierToBundleMap'];
			var carriers = bundles['carriers'];

			// If medical plan state carrier hasn't loaded yet, search for it
			// in bundles. (Note: Se should just set this in the route setup)
			var carrierIdForMedPlan = medicalPlan.get('stateCarrier.id');
			if (!carrierIdForMedPlan) {
				var medPlans = bundles['medicalPlans'] || [];
				medPlans.forEach(function(mPlan) {
					if (mPlan.get('id') === medicalPlan.get('id')) {
						carrierIdForMedPlan = mPlan.get('stateCarrier.id');
					}
				});
			}

			selectedCarrier = carriers[carrierIdForMedPlan];
			var planBundles = carrierToBundleMap[carrierIdForMedPlan] || [];
			// If this carrier has no plan bundles,
			// we will return false
			planBundles.forEach(function(bundle) {
				dentalPlans.forEach(function(dPlan) {
					if (dPlan.get('stateCarrier.id') === medicalPlan.get('stateCarrier.id') &&
						bundle.get('isMedicalBundled') && bundle.get('isDentalBundled')) {
						isBundledWithMedical = true;
					}
				});
			});
		}

		if (isBundledWithMedical) {
			obj.carrierName = selectedCarrier;
			obj.bundledWith = 'medical';
			obj.allBundledLines = 'medical and dental';
			return obj;
		}
		return false;
	},
	/**
	 * Determines if an EE must select a vision plan offered by the carrier of a selected medical plan and dental plan
	 * because the vision plan bundles Medical and/or Dental with Vision.
	 *
	 * @param  {Plan object}  medicalPlan : the medical plan that a user selected
 	 * @param  {Plan object}  dentalPlan : the dental plan that a user selected
	 * @return {false or object}
	 *    False: If the selected medical/dental plans do not bundle with any vision plans
	 *    Object: If true, returns the object
	 *            {bundledWith: <string>, allBundledLines: <string>}
	 *            that is used to display text in template
	 */
	hasSelectedBundledDental: function(medicalPlan, dentalPlan) {
		var bundles = this.get('bundles');
		var visionPlans = bundles['visionPlans'];
		if (!visionPlans) {
			return false;
		}

		var obj = {hasValue: false, bundledWith: [], allBundledLines: ['dental'], carrierName: '', currLine: 'vision'};
		var selectedCarrier = '';
		var isBundledWithMedical = false;
		var isBundledWithDental = false;

		var carriers = bundles['carriers'];

		if (medicalPlan) {
			var carrierToBundleMap = bundles['carrierToBundleMap'];

			// If medical plan state carrier hasn't loaded yet, search for it
			// in bundles... we should just set this in the route setup.
			var carrierIdForMedPlan = medicalPlan.get('stateCarrier.id');
			if (!carrierIdForMedPlan) {
				var medPlans = bundles['medicalPlans'] || [];
				medPlans.forEach(function(mPlan) {
					if (mPlan.get('id') === medicalPlan.get('id')) {
						carrierIdForMedPlan = mPlan.get('stateCarrier.id');
					}
				});
			}

			var planBundles = carrierToBundleMap[carrierIdForMedPlan];
			if (planBundles) {
				planBundles.forEach(function(bundle) {
					visionPlans.forEach(function(vPlan) {
						if (vPlan.get('stateCarrier.id') === medicalPlan.get('stateCarrier.id') &&
							bundle.get('isMedicalBundled') && bundle.get('isVisionBundled')) {
							isBundledWithMedical = true;
							selectedCarrier = carriers[carrierIdForMedPlan];
						}
					});
				});
			}
		}

		if (dentalPlan) {
			var bundles = this.get('bundles');
			var carrierToBundleMap = bundles['carrierToBundleMap'];

			// If medical plan state carrier hasn't loaded yet, search for it
			// in bundles... we should just set this in the route setup.
			var carrierIdForDentalPlan = dentalPlan.get('stateCarrier.id');
			if (!carrierIdForDentalPlan) {
				var dentalPlans = bundles['dentalPlans'] || [];
				dentalPlans.forEach(function(dPlan) {
					if (dPlan.get('id') === dentalPlan.get('id')) {
						carrierIdForDentalPlan = dPlan.get('stateCarrier.id');
					}
				});
			}

			var planBundles = carrierToBundleMap[carrierIdForDentalPlan];
			if (planBundles) {
				planBundles.forEach(function(bundle) {
					visionPlans.forEach(function(vPlan) {
						if (vPlan.get('stateCarrier.id') === dentalPlan.get('stateCarrier.id') &&
							bundle.get('isDentalBundled') && bundle.get('isVisionBundled')) {
							isBundledWithDental = true;
							// This will overwrite carrier name in the medical case, but
							// since both lines are bundled with vision, they should
							// have the same carrier
							selectedCarrier = carriers[carrierIdForDentalPlan];
						}
					});
				});
			}
		}

		if (isBundledWithMedical) {
			obj.hasValue = true;
			obj.bundledWith.push('medical');
			obj.allBundledLines.push('medical');
		}
		if (isBundledWithDental) {
			obj.hasValue = true;
			obj.bundledWith.push('dental');
			obj.allBundledLines.push('vision'); // refactor all this
		}
		if (obj.hasValue) {
			obj.carrierName = selectedCarrier;
			obj.bundledWith = humanizeList(obj.bundledWith);
			obj.allBundledLines = humanizeList(obj.allBundledLines);
		}
		return obj.hasValue ? obj : false;
	},
	/**
	 * Returns true if some or all dental plans are unavailable to the EE because
	 * he/she has:
	 * 		1) Chosen to decline a medical plan
	 * 		2) One or more of the medical plans bundled Medical and Dental together
	 * 		3) One or more dental plans are offered by carriers of [2]
	 */
	hasDeclinedBundledMedical: function(currentEnrollment) {
		return this.get('hasDeclinedPriorLines').bind(this.controller)('MEDICAL', 'DENTAL', currentEnrollment);
	},
	/**
	 * Returns an object containing text properties if some or all vision plans are unavailable to the EE because
	 * he/she has:
	 * 		1) Chosen to decline a medical and/or Dental plan
	 * 		2) One or more of the vision plans bundled Medical and/or Dental together
	 * 		3) One or more dental plans are offered by carriers of [2]
	 * Thest text properties are used in the template.
	 */
	hasDeclinedBundledDental: function(currentEnrollment, dentalEnrollment) {
		var declinedMV = this.get('hasDeclinedPriorLines').bind(this.controller)('MEDICAL', 'VISION', currentEnrollment);
		var declinedDV = this.get('hasDeclinedPriorLines').bind(this.controller)('DENTAL', 'VISION', dentalEnrollment);

		var bundleStatusObj = {
			declinedLines: [],
			allLines: [],
		};

		if (declinedMV) {
			bundleStatusObj.declinedLines.push('medical');
			bundleStatusObj.allLines.push('medical');
		}
		if (declinedDV) {
			bundleStatusObj.declinedLines.push('dental');
			bundleStatusObj.allLines.push('dental');
		}
		if (bundleStatusObj.declinedLines.length > 0) {
			var temp = bundleStatusObj.declinedLines;
			bundleStatusObj.declinedLines = humanizeList(bundleStatusObj.declinedLines);
			bundleStatusObj.allLines.push('vision');
			bundleStatusObj.allLines = humanizeList(bundleStatusObj.allLines);
			bundleStatusObj.currLine = 'vision';
			return bundleStatusObj;
		}
		else {
			return false;
		}
	},
	/**
	 * Determines if the EE has declined a previous line of coverage that had plans
	 * that operated under bundling rules. If this is the case, certain plans
	 * in the current line of coverage will no longer be available.
	 * @param  {string enum}  priorLine : a previous line to compare plans against
	 * @param  {string enum}  currLine : the current line to determine the availability of
	 * @param  {EHE object}  priorEnrollment : Used to see if the user has declined coverage
	 * @return {Boolean}
	 */
	hasDeclinedPriorLines: function(priorLine, currLine, priorEnrollment) {
		var selectableCurrPlans = {};
		var bundles = this.get('bundles');

		var lineOfCoveragePlanBundleRuleMapping = {
			'MEDICAL' : 'isMedicalBundled',
			'DENTAL' : 'isDentalBundled',
			'VISION' : 'isVisionBundled',
		};

		var lineOfCoveragePlanMapping = {
			'MEDICAL' : bundles['medicalPlans'],
			'DENTAL' : bundles['dentalPlans'],
			'VISION' : bundles['visionPlans'],
		};

		var priorBundleRule = lineOfCoveragePlanBundleRuleMapping[priorLine];
		var currBundleRule = lineOfCoveragePlanBundleRuleMapping[currLine];

		var carrierToBundleMap = bundles['carrierToBundleMap'];
		var currPlans = lineOfCoveragePlanMapping[currLine];

		var didDeclinePrior = null;
		if (priorEnrollment) {
			didDeclinePrior = priorEnrollment.get('status') === 'decline';
		}

		if (didDeclinePrior) {
			// Look at every curr plan. Then look at its carrier. Then get its bundling rule.
			// If prior && curr are bundled && one of the prior plans had this carrier as well,
			// this curr plan is not available.
			var priorPlans = lineOfCoveragePlanMapping[priorLine];

			if (!currPlans || !priorPlans) {
				return false;
			}

			currPlans.forEach(function(cPlan){
				var carrierId = cPlan.get('stateCarrier.id');
				var planBundles = carrierToBundleMap[carrierId] || [];
				var planIsSelectable = true;

				planBundles.forEach(function(planBundle){
					if (planBundle.get(priorBundleRule) && planBundle.get(currBundleRule)){
						// Dict would be better...but # mPlans should be super duper small
						priorPlans.forEach(function(pPlan) {
							if (pPlan.get('stateCarrier.id') === carrierId) {
								planIsSelectable = false;
							}
						});
					}
				});

				if (planIsSelectable) {
					selectableCurrPlans[cPlan.get('id')] = cPlan;
				}
			});
			// Some dental plans are unavailable if medical and dental were bundled.
			return Object.keys(selectableCurrPlans).length < currPlans.get('length');
		}
		else {
			return false;
		}
	},
	/**
	 * Returns a dictionary of all dental plans that can be selected with {planID : plan}
	 * A dental plan can become invalid/disabled if:
	 * 		1) The EE declined medical coverage and one or more of the medical
	 * 		plans bundled Medical and Dental together
	 * 		2) The EE selected a medical plan that bundled Medical and Dental together
	 */
	getSelectableDentalPlans: function(controller, currentEnrollment, currentDentalEnrollment) {
		var mdPlans = this.computeAvailablePlans.call(controller, 'MEDICAL', 'DENTAL', currentEnrollment);
		var selectableDentalPlans = mdPlans;
		return selectableDentalPlans;
	},
	/**
	 * Returns a dictionary of all vision plans that can be selected with {planID : plan}
	 * A vision plan can become invalid/disabled if:
	 * 		1) The EE declined medical coverage and one or more of the medical
	 * 		plans bundled Medical and Vision together
	 * 		2) The EE selected a medical plan that bundled Medical and Vision together
	 * 		3) The EE declined dental coverage and one or more of the dental
	 * 		plans bundled Medical and Vision together
	 * 		4) The EE selected a dental plan that bundled Dental and Vision together
	 */
	getSelectableVisionPlans: function(controller, currentEnrollment, currentDentalEnrollment) {
		var mvPlans = this.computeAvailablePlans.call(controller, 'MEDICAL', 'VISION', currentEnrollment);
		var dvPlans = this.computeAvailablePlans.call(controller, 'DENTAL', 'VISION', currentDentalEnrollment);

		// Intersection of two (get vision plans that are available based on both medical and dental lines)
		var selectableVisionPlans = {};
		for (var key in mvPlans) {
			if (dvPlans[key]) {
				selectableVisionPlans[key] = mvPlans[key];
			}
		}
		for (key in dvPlans) {
			if (mvPlans[key]) {
				selectableVisionPlans[key] = dvPlans[key];
			}
		}

		return selectableVisionPlans;
	},
	/**
	 * Helper function to compute what plans in the current line of coverage are selectable.
	 * The availability of a plan is based on 1) whether the EE had declined a previous line of
	 * coverage, and 2) whether the EE has selected a plan that is part of a bundle.
	 * @param  {string} priorLine
	 *         An ENUM dictating the line of coverage
	 * @param  {string} currLine
	 *         An ENUm dictating what line of plans we should filter based on our selection in the prior line of coverage
	 * @param  {EHE} priorEnrollment
	 * @return {dict}
	 *         Returns a dictionary of selectable plans for the current line of coverage {planID : plan}
	 */
	computeAvailablePlans: function(priorLine, currLine, priorEnrollment) {
		var bundles = this.get('bundles');
		var carrierToBundleMap = bundles['carrierToBundleMap'];

		var lineOfCoveragePlanBundleRuleMapping = {
			'MEDICAL' : 'isMedicalBundled',
			'DENTAL' : 'isDentalBundled',
			'VISION' : 'isVisionBundled',
		};

		var lineOfCoveragePlanMapping = {
			'MEDICAL' : bundles['medicalPlans'],
			'DENTAL' : bundles['dentalPlans'],
			'VISION' : bundles['visionPlans'],
		};

		var priorBundleRule = lineOfCoveragePlanBundleRuleMapping[priorLine];
		var currBundleRule = lineOfCoveragePlanBundleRuleMapping[currLine];
		var priorPlans = lineOfCoveragePlanMapping[priorLine] || [];
		var currPlans = lineOfCoveragePlanMapping[currLine] || [];

		var selectableCurrPlans = {}; // plan id : plan object
		// A plan is not selectable if it has one of these carriers
		// Schema: {carrierId : true} -- Using this as a set. Only keys matter
		var ineligibleCarriers = {};

		// All plans will be available if there is no prior enrollment to check against
		if (priorEnrollment) {
			var didDeclinePrior = priorEnrollment.get('status') === 'decline';
			if (didDeclinePrior) {
				var lineOfCoveragePlanBundleMapping = {
					'MEDICAL' : 'medicalBundles',
					'DENTAL' : 'dentalBundles',
					'VISION' : 'visionBundles',
				};
				var planBundles = bundles[lineOfCoveragePlanBundleMapping[priorLine]];
				// See which prior plans bundled this current line of coverage.
				// Any current plans with those carriers become disabled because
				// the previous line of coverage was declined.
				if (planBundles) {
					planBundles.forEach(function(planBundle) {
						if (planBundle.get(priorBundleRule) && planBundle.get(currBundleRule)) {
							var carrierId = planBundle.get('carrier.id');
							ineligibleCarriers[carrierId] = true;
						}
					});
				} // If there are no plan bundles, all carriers are eligible
			}
			else {
				var lineOfCoverageSelectedPlanMapping = {
					'MEDICAL' : 'medicalPlan',
					'DENTAL' : 'dentalPlan',
					'VISION' : 'visionPlan',
				};
				var priorSelectedPlanIdString = lineOfCoverageSelectedPlanMapping[priorLine] + '.id';
				var selectedPriorPlanId = priorEnrollment.get(priorSelectedPlanIdString);

				// Case 1: Did not select a plan yet (all currPlans should be available)
				// Do nothing
				if (selectedPriorPlanId) {
					// Case 2: Selected a plan:
					// Look at all plans that WEREN'T selected in prior.
					// If that plan bundled, this carrier is ineligible if this carrier
					// exists in the current line && the carrier is NOT the same as that of the selected plan.

					var carrierIdsNotSelected = {};

					// Get selected carrier's ID
					var selectedCarrierId = null;
					priorPlans.forEach(function(pPlan) {
						if (pPlan.get('id') === selectedPriorPlanId) {
							selectedCarrierId = pPlan.get('stateCarrier.id');
						}
					});

					// Note: If there are no plans in the previous line of coverage,
					// all carriers and current plans will be available
					priorPlans.forEach(function(pPlan) {
						if (pPlan.get('id') !== selectedPriorPlanId ) {
							var carrierId = pPlan.get('stateCarrier.id');
							if (carrierId !== selectedCarrierId) {
								carrierIdsNotSelected[carrierId] = true;
							}
						}
					});

					Object.keys(carrierIdsNotSelected).forEach(function(carrierId) {
						var planBundles = carrierToBundleMap[carrierId] || [];

						planBundles.forEach(function(planBundle) {
							if (planBundle.get(priorBundleRule) && planBundle.get(currBundleRule)) {
								var bundledCurrPlanExists = false;

								currPlans.forEach(function(cPlan) {
									// Warning! carrierId is a string at this point :(
									if (cPlan.get('stateCarrier.id') == carrierId) {
										bundledCurrPlanExists = true;
									}
								});

								if (bundledCurrPlanExists) {
									ineligibleCarriers[carrierId] = true;
								}
							}
						});
					});
				}
			}
		}

		// Add and return only plans that do not have
		// invalid carriers
		currPlans.forEach(function(cPlan) {
			var carrierId = cPlan.get('stateCarrier.id');
			if (!ineligibleCarriers[carrierId]) {
				selectableCurrPlans[cPlan.get('id')] = cPlan;
			}
		});
		// If there are no plans for the current line of coverage, no plans are selectable

		return selectableCurrPlans;
	},
});

App.EmployeeOpenenrollmentfinishsuccessController = Ember.ObjectController.extend();

/*
 * Enrollment
 */

/**
 * Base route for Employee Enrollment routes.
 *
 * inherites by EmployeeEnrollmentsummaryRoute and EmployeeEnrollmentRoute
 * - Load employee, company, dependents, EHEs and DHEs.
 * - Load sections and subsections for sectionController.
 * - Setup sectionController
 */
_EmployeeEnrollmentBaseRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		// Subsections has to be loaded after sections to avoid race condition on creating EmployeeCarrierNavList obj.
		var sectionsPromise = App.ESection.find();
		var subsectionsPromise = sectionsPromise.then(function () {
			return App.ESubsection.find();
		});

		return this.all(dashboard('employee'), {
			'employee': dashboard('employee'),
			'dependents': dashboard('employee.dependents'),
			'sections': sectionsPromise,
			'subsections': subsectionsPromise,
			'company': dashboard('company'),
			'currentEnrollment': dashboard('employee.employeeEnrollmentProperties.currentMedicalEnrollment'),
			'currentDentalEnrollment': dashboard('employee.employeeEnrollmentProperties.currentDentalEnrollment'),
			'currentVisionEnrollment': dashboard('employee.employeeEnrollmentProperties.currentVisionEnrollment'),
			'medicalPlan': dashboard('employee.medicalPlan'),
		});
	},
	afterModel: function(model) {
		var promises = [];

		// Load DHEs
		this.otherModels['dependents'].forEach(function(dependent) {
			var linesOfCoverage = ['Medical', 'Dental', 'Vision'];
			linesOfCoverage.forEach(function(lineOfCoverage) {
				if(dependent.get('dependent' + lineOfCoverage + 'Enrollments.length')) {
					var dhes = dependent.get('dependent' + lineOfCoverage + 'Enrollments');
					dhes.forEach(function(dhe) {
						promises.push(App.DependentHealthEnrollment.find(dhe.get('id')));
					});
				}
			});
		});

		return Ember.RSVP.all(promises);
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		// set sectionController
		// set sections, employee, company data to the controller
		var sectionController = controller.get('enrollmentsectionController');
		var sections = controller.get('sections');
		var subsections = controller.get('subsections');
		var medicalPlan = controller.get('medicalPlan');
		var company = controller.get('company');

		controller.set('sectionController', sectionController);
		sectionController.set('content', sections);
		sectionController.set('subsections', subsections);
		sectionController.set('employee', model);
		sectionController.set('medicalPlan', medicalPlan);
		sectionController.set('company', company);
	},
});



/*
 * Base route for Employee Enrollment Section routes.
 *
 * - Load employee and company
 * - Setup sectionController
 * - Update sectionController state flags base on current route
 */
_EmployeeEnrollmentSectionRoute = Ember.Route.extend(_MultiModelMixin, _AuthEmployeeMixin, {
	model: function(params) {
		return this.all(dashboard('employee'), {
			'employee': dashboard('employee'),
			'company': dashboard('company'),
		});
	},
	setupController: function(controller, model) {
		this._super(controller, model);

		var sectionController = controller.get('enrollmentsectionController');
		controller.set('sectionController', sectionController);

		// Splite route name into array and double check length.
		// Length should always be greater than three
		var routeParts = this.routeName.split('.');
		if (routeParts.length > 3) {
			var sections = sectionController.get('content');
			var section = sections.findProperty('path', routeParts[2]);
			var subsections = section.get('subsections');

			// Set isCurrentSection on section obj
			sections.setEach('isCurrentSection', false);
			section.set('isCurrentSection', true);

			// Update currentSection and isCurrentSectionLast
			sectionController.set('currentSection', section);
			var isCurrentSectionLast = sections.indexOf(section) == sections.get('length') - 1;
			sectionController.set('isCurrentSectionLast', isCurrentSectionLast);

			// Subsection route special cases
			var matchingRoute = routeParts[3];
			if (matchingRoute == 'blueshield' ||
				matchingRoute == 'bluecross'  ||
				matchingRoute == 'seechange'  ||
				matchingRoute == 'united'     ||
				matchingRoute == 'healthnet')
			{
				matchingRoute = 'medicaldetails';
			}

			// Update isCurrentSubsection on subsection obj
			var subsection = subsections.findProperty('path', matchingRoute);
			subsections.setEach('isCurrentSubsection', false);
			subsection.set('isCurrentSubsection', true);

			// Update currentSubsection and isCurrentSubsectionLast
			sectionController.set('currentSubsection', subsection);
			var isCurrentSubsectionLast = subsections.indexOf(subsection) == subsections.get('length') - 1;
			sectionController.set('isCurrentSubsectionLast', isCurrentSubsectionLast);
		}

		sectionController.updateContent();
	}
});

_EmployeeEnrollmentDependentsMixIn = Ember.Mixin.create({
	ePlan: null,
	eDentalPlan: null,
	eVisionNull: null,
	dependentsEnrolledInMedical: function() {
		if (!this.get('selfDependent.enrollInMedical')) {
			return 'None';
		}
		var dependents = this.get('dependents');
		var dependentsEnrolled = 'You';
		dependents.forEach(function(dependent) {
			if (!dependent.get('isSelf') && dependent.get('enrollInMedical')) {
				dependentsEnrolled += ' + ' + dependent.get('firstName');
			}
		});
		return dependentsEnrolled;
	}.property('selfDependent.enrollInMedical', 'dependents.@each.isSelf', 'dependents.@each.enrollInMedical', 'dependents.@each.firstName'),
	dependentsEnrolledInDental: function() {
		if (!this.get('selfDependent.enrollInDental')) {
			return 'None';
		}
		var dependents = this.get('dependents');
		var dependentsEnrolled = 'You';
		dependents.forEach(function(dependent) {
			if (!dependent.get('isSelf') && dependent.get('enrollInDental')) {
				dependentsEnrolled += ' + ' + dependent.get('firstName');
			}
		});
		return dependentsEnrolled;
	}.property('selfDependent.enrollInDental', 'dependents.@each.isSelf', 'dependents.@each.enrollInDental', 'dependents.@each.firstName'),
	dependentsEnrolledInVision: function() {
		if (!this.get('selfDependent.enrollInVision')) {
			return 'None';
		}
		var dependents = this.get('dependents');
		var dependentsEnrolled = 'You';
		dependents.forEach(function(dependent) {
			if (!dependent.get('isSelf') && dependent.get('enrollInVision')) {
				dependentsEnrolled += ' + ' + dependent.get('firstName');
			}
		});
		return dependentsEnrolled;
	}.property('selfDependent.enrollInVision', 'dependents.@each.isSelf', 'dependents.@each.enrollInVision', 'dependents.@each.firstName'),
	dependentsInMedical: function() {
		var dependents = this.get('dependents');
		var hasSpouseMedical = false;
		var hasChildMedical = false;
		dependents.forEach(function(item) {
			if (item.get('enrollInMedical') && (item.get('type') == 'Spouse' || item.get('type') == 'Domestic Partner')) {
				hasSpouseMedical = true;
			}
			if (item.get('enrollInMedical') && item.get('type') == 'Child') {
				hasChildMedical = true;
			}
		});

		if (!hasSpouseMedical && !hasChildMedical) {
			return 'you';
		}
		if (hasSpouseMedical && !hasChildMedical) {
			return 'youAndSpouse';
		}
		if (!hasSpouseMedical && hasChildMedical) {
			return 'youAndChild';
		}

		return 'family';
	}.property('dependents.@each.enrollInMedical', 'dependents.@each.type'),
	dependentsInDental: function() {
		var dependents = this.get('dependents');
		var hasSpouseDental = false;
		var hasChildDental = false;
		dependents.forEach(function(item) {
			if (item.get('enrollInDental') && (item.get('type') == 'Spouse' || item.get('type') == 'Domestic Partner')) {
				hasSpouseDental = true;
			}
			if (item.get('enrollInDental') && item.get('type') == 'Child') {
				hasChildDental = true;
			}
		});

		if (!hasSpouseDental && !hasChildDental) {
			return 'you';
		}
		if (hasSpouseDental && !hasChildDental) {
			return 'youAndSpouse';
		}
		if (!hasSpouseDental && hasChildDental) {
			return 'youAndChild';
		}

		return 'family';
	}.property('dependents.@each.enrollInDental', 'dependents.@each.type'),
	dependentsInVision: function() {
		var dependents = this.get('dependents');
		var hasSpouseVision = false;
		var hasChildVision = false;
		dependents.forEach(function(item) {
			if (item.get('enrollInVision') && (item.get('type') == 'Spouse' || item.get('type') == 'Domestic Partner')) {
				hasSpouseVision = true;
			}
			if (item.get('enrollInVision') && item.get('type') == 'Child') {
				hasChildVision = true;
			}
		});

		if (!hasSpouseVision && !hasChildVision) {
			return 'you';
		}
		if (hasSpouseVision && !hasChildVision) {
			return 'youAndSpouse';
		}
		if (!hasSpouseVision && hasChildVision) {
			return 'youAndChild';
		}

		return 'family';
	}.property('dependents.@each.enrollInVision', 'dependents.@each.type'),

	hasCostBeenLoaded: function(cost) {
		if (!cost || isNaN(cost) || parseFloat(cost) === 0.0) {
			return false;
		}
		return true;
	},

	cobraMedicalCost: function() {
		var medicalCost = "";
		var isBorEmployeeAlreadyCovered = this.get('employeeCobra.isBorEmployeeAlreadyCovered');
		if (isBorEmployeeAlreadyCovered) {
			medicalCost = (parseFloat(this.get('employeeCobra.medicalCostWithAdminFee'))).toFixed(2);
			this.set('medicalCostSource', 'cobra');
		}
		else {
			medicalCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('medicalYourCost')) + parseFloat(this.get('medicalYourContribution')))).toFixed(2);
			this.set('medicalCostSource', 'calculator');

			// COBRA-706: allow EE-only enrollment without loaded rates
			if (!this.hasCostBeenLoaded(medicalCost) &&
				this.get('employeeSettings.planType') === 'youPremium' &&
				this.get('dependentsInMedical') === 'you'&& (this.get('dependentsEnrolledInMedical') != 'None')) {
				medicalCost = (parseFloat(this.get('employeeCobra.medicalCostWithAdminFee'))).toFixed(2);
				this.set('medicalCostSource', 'cobra');
			}
		}
		if (!this.hasCostBeenLoaded(medicalCost)) {
			medicalCost = parseFloat('0');
		}
		if (this.get('isActive') && this.get('isEligibleForMedical')) {
			medicalCost = parseFloat('0');
		}
		return medicalCost;
	}.property('isActive', 'isEligibleForMedical', 'medicalYourCost', 'medicalYourContribution', 'employeeCobra.computedAdminFee', 'employeeCobra.isBorEmployeeAlreadyCovered', 'employeeCobra.medicalCostWithAdminFee', 'employeeSettings.planType', 'dependentsInMedical', 'dependentsEnrolledInMedical'),
	cobraDentalCost: function() {
		var dentalCost = "";
		var isBorEmployeeAlreadyCovered = this.get('employeeCobra.isBorEmployeeAlreadyCovered');
		if (isBorEmployeeAlreadyCovered) {
			dentalCost = (parseFloat(this.get('employeeCobra.dentalCostWithAdminFee'))).toFixed(2);
			this.set('dentalCostSource', 'cobra');
		}
		else {
			dentalCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('dentalYourCost')) + parseFloat(this.get('dentalYourContribution')))).toFixed(2);
			this.set('dentalCostSource', 'calculator');

			// COBRA-706: allow EE-only enrollment without loaded rates
			if (!this.hasCostBeenLoaded(dentalCost) &&
				this.get('employeeSettings.dentalPlanType') === 'youPremium' &&
				this.get('dependentsInDental') === 'you' && (this.get('dependentsEnrolledInDental') != 'None')) {
				dentalCost = (parseFloat(this.get('employeeCobra.dentalCostWithAdminFee'))).toFixed(2);
				this.set('dentalCostSource', 'cobra');
			}
		}
		if (!this.hasCostBeenLoaded(dentalCost)) {
			dentalCost = parseFloat('0');
		}
		if (this.get('isActive') && this.get('isEligibleForDental')) {
			dentalCost = parseFloat('0');
		}
		return dentalCost;
	}.property('isActive', 'isEligibleForDental', 'dentalYourCost', 'dentalYourContribution', 'employeeCobra.computedAdminFee', 'employeeCobra.isBorEmployeeAlreadyCovered', 'employeeCobra.dentalCostWithAdminFee', 'employeeSettings.dentalPlanType', 'dependentsInDental', 'dependentsEnrolledInDental'),
	cobraVisionCost: function() {
		var visionCost = "";
		var isBorEmployeeAlreadyCovered = this.get('employeeCobra.isBorEmployeeAlreadyCovered');
		if (isBorEmployeeAlreadyCovered) {
			visionCost = (parseFloat(this.get('employeeCobra.visionCostWithAdminFee'))).toFixed(2);
			this.set('visionCostSource', 'cobra');
		}
		else {
			visionCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('visionYourCost')) + parseFloat(this.get('visionYourContribution')))).toFixed(2);
			this.set('visionCostSource', 'calculator');

			// COBRA-706: allow EE-only enrollment without loaded rates
			if (!this.hasCostBeenLoaded(visionCost) &&
				this.get('employeeSettings.visionPlanType') === 'youPremium' &&
				this.get('dependentsInVision') === 'you' && (this.get('dependentsEnrolledInVision') != 'None')) {
				visionCost = (parseFloat(this.get('employeeCobra.visionCostWithAdminFee'))).toFixed(2);
				this.set('visionCostSource', 'cobra');
			}
		}
		if (!this.hasCostBeenLoaded(visionCost)) {
			visionCost = parseFloat('0');
		}
		if (this.get('isActive') && this.get('isEligibleForVision')) {
			visionCost = parseFloat('0');
		}
		return visionCost;
	}.property('isActive', 'isEligibleForVision', 'visionYourCost', 'visionYourContribution', 'employeeCobra.computedAdminFee', 'employeeCobra.isBorEmployeeAlreadyCovered', 'employeeCobra.visionCostWithAdminFee', 'employeeSettings.visionPlanType', 'dependentsInVision', 'dependentsEnrolledInVision'),
	cobraOETotalCost: function() {
		var medicalCost = parseFloat('0');
		var dentalCost = parseFloat('0');
		var visionCost = parseFloat('0');

		var isBorEmployeeAlreadyCovered = this.get('employeeCobra.isBorEmployeeAlreadyCovered');

		if (isBorEmployeeAlreadyCovered) {
			medicalCost = (parseFloat(this.get('employeeCobra.medicalCostWithAdminFee'))).toFixed(2);
		}
		else {
			medicalCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('medicalYourCost')) + parseFloat(this.get('medicalYourContribution')))).toFixed(2);
		}
		if (isBorEmployeeAlreadyCovered) {
			dentalCost = (parseFloat(this.get('employeeCobra.dentalCostWithAdminFee'))).toFixed(2);
		}
		else {
			dentalCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('dentalYourCost')) + parseFloat(this.get('dentalYourContribution')))).toFixed(2);
		}
		if (isBorEmployeeAlreadyCovered) {
			visionCost = (parseFloat(this.get('employeeCobra.visionCostWithAdminFee'))).toFixed(2);
		}
		else{
			visionCost = (parseFloat(this.get('employeeCobra.computedAdminFee')) * (parseFloat(this.get('visionYourCost')) + parseFloat(this.get('visionYourContribution')))).toFixed(2);
		}
		if (!medicalCost || isNaN(medicalCost)) {
			medicalCost = parseFloat('0');
		}
		if (!dentalCost || isNaN(dentalCost)) {
			dentalCost = parseFloat('0');
		}
		if (!visionCost || isNaN(visionCost)) {
			visionCost = parseFloat('0');
		}

		return (parseFloat(medicalCost) + parseFloat(dentalCost) + parseFloat(visionCost)).toFixed(2);
	}.property('employeeCobra.isBorEmployeeAlreadyCovered', 'employeeCobra.computedAdminFee', 'employeeCobra.medicalCostWithAdminFee', 'employeeCobra.dentalCostWithAdminFee', 'employeeCobra.visionCostWithAdminFee', 'medicalYourCost', 'medicalYourContribution', 'dentalYourCost', 'dentalYourContribution', 'visionYourCost', 'visionYourContribution'),

	medicalPlanCost: function() {
		var medicalPlan = this.get('currentEnrollment.medicalPlan');
		if (!medicalPlan) {
			medicalPlan = this.get('ePlan');
		}

		// When filling-out COBRA during SW, the EE may not have a selected plan
		if (medicalPlan || this.get('employeeCobra.isFillingOut')) {
			var dependents = this.get('dependents');
			var employee = this.get('employee');
			if (!employee) {
				employee = this.get('model');
			}
			var params = 'employee=' + employee.get('id')  + '&decimalDigits=2&dependents=';
			dependents.forEach(function(item) {
				if (item.get('enrollInMedical') && !item.get('isSelf') && item.get('id')) {
					params += item.get('id') + ",";
				}
			});

			var self = this;
			self.set('medicalResponse', 'loading');
			return $.ajax({
				url: '/custom_api/getEmployeePremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('medicalResponse', 'ok');
					if (!self.get('selfDependent.enrollInMedical')) {
						self.set('medicalYourCost', parseFloat('0.00'));
						self.set('medicalYourContribution', parseFloat('0.00'));
					}
					else {
						self.set('medicalYourCost', response.youCost);
						self.set('medicalYourContribution', response.youContribution);
					}
				},
				error: function() {
					self.set('medicalResponse', 'error');
				},
			});
		}

		return null;
	}.observes('dependents.@each.enrollInMedical'),
	dentalPlanCost: function() {
		var dentalPlan = this.get('currentDentalEnrollment.dentalPlan');
		if (!dentalPlan){
			dentalPlan = this.get('eDentalPlan');
		}

		// When filling-out COBRA during SW, the EE may not have a selected plan
		if (dentalPlan || this.get('employeeCobra.isFillingOut')) {
			var dependents = this.get('dependents');
			var employee = this.get('employee');
			if (!employee) {
				employee = this.get('model');
			}
			var params = 'employee=' + employee.get('id') + '&decimalDigits=2&dependents=';
			dependents.forEach(function(item) {
				if (item.get('enrollInDental') && !item.get('isSelf') && item.get('id')) {
					params += item.get('id') + ",";
				}
			});

			var self = this;
			self.set('dentalResponse', 'loading');
			return $.ajax({
				url: '/custom_api/getEmployeeDentalPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('dentalResponse', 'ok');
					if (!self.get('selfDependent.enrollInDental')) {
						self.set('dentalYourCost', parseFloat('0.00'));
						self.set('dentalYourContribution', parseFloat('0.00'));
					}
					else {
						self.set('dentalYourCost', response.youCost);
						self.set('dentalYourContribution', response.youContribution);
					}
				},
				error: function() {
					self.set('dentalResponse', 'error');
				},
			});
		}

		return null;
	}.observes('dependents.@each.enrollInDental'),
	visionPlanCost: function() {
		var visionPlan = this.get('currentVisionEnrollment.visionPlan');
		if (!visionPlan){
			visionPlan = this.get('eVisionPlan');
		}

		// When filling-out COBRA during SW, the EE may not have a selected plan
		if (visionPlan || this.get('employeeCobra.isFillingOut')) {
			var dependents = this.get('dependents');
			var employee = this.get('employee');
			if (!employee) {
				employee = this.get('model');
			}
			var params = 'employee=' + employee.get('id') + '&decimalDigits=2&dependents=';
			dependents.forEach(function(item) {
				if (item.get('enrollInVision') && !item.get('isSelf') && item.get('id')) {
					params += item.get('id') + ",";
				}
			});

			var self = this;
			self.set('visionResponse', 'loading');
			return $.ajax({
				url: '/custom_api/getEmployeeVisionPremiums?' + params,
				type: "get",
				success: function(response) {
					self.set('visionResponse', 'ok');
					if (!self.get('selfDependent.enrollInVision')) {
						self.set('visionYourCost', parseFloat('0.00'));
						self.set('visionYourContribution', parseFloat('0.00'));
					}
					else {
						self.set('visionYourCost', response.youCost);
						self.set('visionYourContribution', response.youContribution);
					}
				},
				error: function() {
					self.set('visionResponse', 'error');
				},
			});
		}

		return null;
	}.observes('dependents.@each.enrollInVision'),
	totalCobraCost: function() {
		if (!this.get('medicalYourCost')) {
			this.medicalPlanCost();
		}
		if (!this.get('dentalYourCost')) {
			this.dentalPlanCost();
		}
		if (!this.get('visionYourCost')) {
			this.visionPlanCost();
		}
		var medicalCost = parseFloat(this.get('cobraMedicalCost'));
		var dentalCost = parseFloat(this.get('cobraDentalCost'));
		var visionCost = parseFloat(this.get('cobraVisionCost'));
		var totalCost = medicalCost + dentalCost + visionCost;
		return Math.round( totalCost * 100) / 100;
	}.property('medicalYourCost','dentalYourCost','visionYourCost','cobraMedicalCost','cobraDentalCost','cobraVisionCost'),
});




App.EmployeeSuccessfinishController = Ember.ObjectController.extend();
App.EmployeeSuccessfinishwithhsaController = Ember.ObjectController.extend();


App.EmployeeQleenrollmentfinishsuccessController = Ember.ObjectController.extend();
App.EmployeeQleenrollmentfinishsuccesswithoutdocumentController = Ember.ObjectController.extend();


App.EmployeePlansettingsCancelRoute = App.EmployeePlansettingsRoute.extend(_MultiModelMixin, {
	model: function() {
		return this.all(dashboard('employee'), {
			'company': dashboard('company'),
			'companySettings': dashboard('company.settings'),
			'companyHealthEnrollments': dashboard('company.healthEnrollments'),
			'employee': dashboard('employee'),
			'employeeCancellationProperties': dashboard('employee.employeeCancellationProperties'),
			'settings': dashboard('employee.employeeSetting'),
			'dependents': dashboard('employee.dependents'),
			'hsa': dashboard('employee.hsa')
		});
	},
	setupController: function (controller, model) {
		this._super(controller, model);
		controller.setProperties({
			cancelMedical: false,
			cancelVision: false,
			cancelDental: false,
			medicalType: null,
			reason: null,
			authName: null,
			authSignature: null,
		});
	}
});
