/* BEGIN VERIFICATION */

App.VerificationVerifycompanyRoute = Ember.Route.extend({
	model: function() {
		return dashboard('company');
	}
});

App.VerificationVerifycompanyController = _AbstractSavingController.extend({
	content: {},
	defaultServiceTypes: ['B'],
	requestedServiceTypes: ['B'],
	preVerificationTransition: 'index',
	preModel: null,
	save: function() {
		this.saveAndContinue().then(function() {
			if (!this.get('isCompanyLegalInfoComplete')) {
				this.set('errorText', 'All fields are required. Please fill in the missing fields.');
				return;
			}

			// create verification request
			var requestedServices = this.get('requestedServiceTypes');
			var existingServices = this.get('existingVerificationRequestTypes');

			if (Ember.isNone(existingServices)) {
				existingServices = [];
			}

			var missingServices = [];
			for (var i = 0; i < requestedServices.length; i++) {
				if (existingServices.indexOf(requestedServices[i]) == -1) {
					missingServices.push(requestedServices[i]);
				}
			}

			var promises = missingServices.map(function(service) {
				return App.CompanyVerification.createRecord({ 'serviceType': service }).save();
			});

			Ember.RSVP.all(promises).then(function() {
				promises = [];
				promises.push(this.get('model').reload());

				var previousModel = this.get('preModel');
				if (!Ember.isNone(previousModel)) {
					promises.push(previousModel.reload());
				}
				return Ember.RSVP.all(promises);
			}.bind(this)).then(function() {
				var next = 'index';
				var previousTransition = this.get('preVerificationTransition');

				if (previousTransition) {
					next = previousTransition;
				}

				this.set('preVerificationTransition', 'index');
				this.set('requestedServiceTypes', this.get('defaultServiceTypes'));
				this.transitionToRoute(next);
			}.bind(this));
		}.bind(this));
	},
	// TODO rhall: move this out of the controller - make it more generic
	isContractorsRoute: function() {
		var route = this.get('preVerificationTransition');
		// show the message if the route is undefined so that we don't miss anyone
		return Ember.isNone(route) || route.indexOf('contractors') > -1;
	}.property('preVerificationTransition')
});

App.VerificationCompanyInfoView = Ember.View.extend({
	templateName: 'verification/companyinfo'
});

/* END VERIFICATION */
