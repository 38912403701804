App.ContractorRoute = Ember.Route.extend({
	model: function() {
		return App.Contractor.find('me');
	},
	afterModel: function(model) {
		return wrapArrayPromise([model.get('contracts'), model.get('ipAgreements')]);
	},
});

App.ContractorIndexRoute = Ember.Route.extend({
	redirect: function(model) {
		var firstConsultantContract = model.get('contracts').findProperty('isUnsigned');
		var firstIpAgreement = model.get('ipAgreements').findProperty('isUnsigned');

		if (firstConsultantContract) {
			this.transitionTo('contractor.signagreement', firstConsultantContract);
		}
		else if (firstIpAgreement) {
			this.transitionTo('contractor.signipagreement', firstIpAgreement);
		}
		else if (!model.get('isBasicInfoComplete')) {
			this.transitionTo('contractor.onboarding.basicinfo');
		}
		else if (!model.get('isBankInfoComplete') && model.get('isCountryUsa')) {
			this.transitionTo('contractor.onboarding.bank');
		}
		else if (!model.get('isExemptionsComplete') && model.get('isCountryUsa')) {
			this.transitionTo('contractor.onboarding.exemptions');
		}
		else if (!model.get('isW9Complete') && model.get('isCountryUsa')) {
			this.transitionTo('contractor.onboarding.w9');
		} else {
			this.transitionTo('employee.personalinfo.basicinfo');
		}
	},
});

App.ContractorSignagreementController = _AbstractSavingController.extend({
	accept: function() {
		this.set('errorText', '');
		if (this.get('consultant_contract.askForAddress') && (!this.get('contractor.address') || !this.get('contractor.city') || !this.get('contractor.country'))) {
			this.set('errorText', 'Please provide your address');
			return;
		}

		if(this.get('contractor.country') == 'United States' && (!this.get('contractor.zip') || !this.get('contractor.state'))) {
			this.set('errorText', 'Please provide your state and zip code');
			return;
		}

		if (this.get('isUnsigned')) {
			this.set('errorText', 'Please sign above to continue');
			return;
		}

		this.saveAndContinue().then(function() {
			this.get('contractor').reload().then(function() {
				this.transitionTo('contractor');
			}.bind(this));
		}.bind(this));

	}
});

App.ContractorSignipagreementController = _AbstractSavingController.extend({
	isDeclaringConflictsGroup: null,
	isDeclaringConflicts: function() {
		return this.get('isDeclaringConflictsGroup') == 'yes';
	}.property('isDeclaringConflictsGroup'),
	isDeclaringCompaniesGroup: null,
	isDeclaringCompanies: function() {
		return this.get('isDeclaringCompaniesGroup') == 'yes';
	}.property('isDeclaringCompaniesGroup'),
	isDeclaringInnovationsGroup: null,
	isDeclaringInnovations: function() {
		return this.get('isDeclaringInnovationsGroup') == 'yes';
	}.property('isDeclaringInnovationsGroup'),
	accept: function() {
		this.set('errorText', '');
		if ((this.get('ip_agreement.hasConflicts') && !this.get('isDeclaringConflictsGroup')) ||
				(this.get('ip_agreement.hasCompanies') && !this.get('isDeclaringCompaniesGroup')) ||
				(this.get('ip_agreement.hasInnovations') && !this.get('isDeclaringInnovationsGroup'))) {
			this.set('errorText', 'Please affirm yes/no to the above questions');
			return;
		}

		if (this.get('ip_agreement.askForAddress') && (!this.get('contractor.address') ||
				!this.get('contractor.city') || !this.get('contractor.country'))) {
			this.set('errorText', 'Please provide your address');
			return;
		}

		if(this.get('contractor.country') == 'United States' && (!this.get('contractor.zip') || !this.get('contractor.state'))) {
			this.set('errorText', 'Please provide your state and zip code');
			return;
		}

		if (this.get('isUnsigned')) {
			this.set('errorText', 'Please sign above to continue');
			return;
		}

		this.saveAndContinue().then(function() {
			this.get('contractor').reload().then(function() {
				this.transitionTo('contractor');
			}.bind(this));
		}.bind(this));

	}
});

_ContractorRoute = Ember.Route.extend({
	model: function() {
		return App.Contractor.find('me');
	},
	setupController: function(controller, model) {
		this._super(controller, model);
		controller.set('isIdentificationEIN', model.get('isBusiness') && model.get('taxId'));
		controller.set('isIdentificationSSN', !model.get('isBusiness') && model.get('taxId'));
		controller.set('newTaxId', '');
	},
});

App.ContractorOnboardingRoute = _ContractorRoute.extend();

App.ContractorOnboardingBasicinfoRoute = _ContractorRoute.extend();

App.ContractorOnboardingBasicinfoController = _AbstractSavingController.extend({

	showLLCClassification: Ember.computed.equal('entityType', 'LC'),
	showSoleProprietorship: Ember.computed.equal('entityType', 'SP'),
	showIdentification: Ember.computed.or('showLLCClassification', 'showSoleProprietorship'),
	save: function() {
		if (!this.get('name') || !this.get('email')) {
			this.set('errorText', 'Name and email are required fields');
			return;
		}
		this.set('errorText', '');


		if(this.get('newTaxId')){
			if(this.get('isBusiness')){
				this.set('identification', 'EIN');
			}
			else{
				this.set('identification', 'SSN');
			}
			this.set('taxId', this.get('newTaxId'));
		}

		//If country is not USA, clear the zip field that might have
		//been set previously - NERD-3852
		if (!this.get('isCountryUsa')) {
			this.set('zip', '');
		}

		this.saveAndContinue().then(function() {
			if (!this.get('isBasicInfoComplete')) {
				this.set("errorText", "Please complete all required fields");
				return;
			}
			this.transitionTo('contractor');
		}.bind(this));
	}
});

App.ContractorOnboardingBankRoute = _ContractorRoute.extend({
	afterModel: function(model) {
		var promises = [
			thenpath(model, 'employee'),
			thenpath(model, 'employee.banks'),
			thenpath(model, 'employee.company.payrollProviderMetadata')
		];
		return Ember.RSVP.all(promises);
	},
});
App.ContractorOnboardingBankController = _AbstractSavingController.extend(_SecondaryEmployeeBankMixin, {
	save: function() {
			var bank = this.get('employee.primaryBank');
			if (bank.get('bankAccountNumber') && bank.get('bankRoutingNumber') && bank.get('bankAccountType') && this.get('authSignatureName') && this.get('authSignature')) {
				this.set('bankAccountNumber', bank.get('bankAccountNumber'));
				this.set('bankRoutingNumber', bank.get('bankRoutingNumber'));
				this.set('bankAccountType', bank.get('bankAccountType'));
			} else {
				this.set("errorText", "Please complete all required fields");
				return;
			}

			this.saveAndContinue().then(function() {

				if (!this.get('isBankInfoComplete')) {
					this.set("errorText", "Please complete all required fields");
					return;
				}
			this.transitionTo('contractor');
			}.bind(this));
	}
});

App.ContractorOnboardingExemptionsRoute = _ContractorRoute.extend({
	setupController: function(controller, model) {
		this._super(controller, model);
		var isYes = model.get('fatcaCodes') || model.get('exemptPayeeCodes');
		if (isYes) {
			controller.set('isFatcaAndWhExempt', 'Y');
		} else {
			controller.set('isFatcaAndWhExempt', 'N');
		}
	},
});

App.ContractorOnboardingExemptionsController = _AbstractSavingController.extend({
	isFatcaAndWhExempt: 'N',
	isExemptionCodesShowing: Ember.computed.equal('isFatcaAndWhExempt', 'Y'),

	actions: {
		save: function() {
			this.set('fatcaAndWhExempt', this.get('isFatcaAndWhExempt'));
			if (this.get('isFatcaAndWhExempt') === 'N') {
				this.set('fatcaCodes', '');
				this.set('exemptPayeeCodes', '');
			}

			this.saveAndContinue().then(function() {
				if (!this.get('isExemptionsComplete')) {
					this.set("errorText", "Please complete all required fields");
					return;
				}

			this.transitionTo('contractor');
			}.bind(this));
		},

	},
});

App.ContractorOnboardingW9Route = _ContractorRoute.extend();
App.ContractorOnboardingW9Controller = _AbstractSavingController.extend({
	save: function() {
		this.saveAndContinue().then(function() {
			if (!this.get('isW9Complete')) {
				this.set("errorText", "Please complete all required fields");
				return;
			}

		this.transitionTo('contractor');
		}.bind(this));
	}
});

App.ContractorPaymentsRoute = Ember.Route.extend({
	model: function() {
		return App.ContractorPayment.find();
	}
});
App.ContractorPaymentsController = Ember.ArrayController.extend();

App.ContractorContractsRoute = Ember.Route.extend({
	model: function() {
		return App.Contractor.find('me');
	},
	afterModel: function(model) {
		return wrapArrayPromise([model.get('contracts'), model.get('ipAgreements')]);
	},
});
App.ContractorContractsController = Ember.ObjectController.extend();

App.ContractorTaxdocsRoute = Ember.Route.extend({
	model: function() {
		return App.Contractor.find('me');
	}
});
App.ContractorTaxdocsController = _AbstractSavingController.extend({
	save: function() {
		this.saveCheckAndContinue('isBankInfoComplete', 'contractor');
	}
});
