App.PayrollReconcileRoute = Ember.Route.extend(_MultiModelMixin, _AuthAdminMixin, {
	model: function() {
		return this.all(App.EmployeeSyncDifference.find(), { 'company': dashboard('company'), 'pdal': App.PayrollDiffActionLog.find() });
	},
	actions: {
		loading: function() {
			return true;
		}
	}
});

App.PayrollReconcileController = Ember.ArrayController.extend({
	allDiffs: function() {
		return this.get('model').filterProperty('ignored', false);
	}.property('length', '@each.ignored'),
	updateZenefits: function() {
		if (this.get('length') === 0) {
			return Ember.RSVP.resolve();
		}
		return Ember.$.ajax({
			type: "POST",
			url: "/overwriteFromPayroll",
			data: JSON.stringify(this.get('model').mapProperty('id').map(function(strId) { return parseInt(strId); })),
			contentType: 'application/json',
			success: function() {
				return this.setPdalStatus().then(function() {
					return wrapArrayPromise(this.get('model')).then(function(diffs) {
						// reload or deleteRecord doesn't work, because the deletions happen on the server side,
						// so the only options are to manually unload the data or do a window reload.
						// (needs to be toArray as unloadRecord modifies the diffs as it's a live array)
						diffs.toArray().invoke('unloadRecord');
					}).then(function() {
						return this.send('showModal', 'payroll.reconcilepopup', { 'isUpdate': true });
					}.bind(this));
				}.bind(this));
			}.bind(this),
			error: function(XMLHttpRequest, textStatus, errorThrown) {
				return this.send('showModal', 'payroll.reconcilepopup', {'isError': true});
			}.bind(this)
		});
	},
	ignoreDiffs: function() {
		if (this.get('length') === 0) {
			return Ember.RSVP.resolve();
		}
		return this.setPdalStatus().then(function() {
			return wrapArrayPromise(this.get('model')).then(function(diffs) {
				return Ember.RSVP.all(diffs.map(function(diff) {
					diff.set('ignored', true);
					return diff.save();
				}));
			}).then(function() {
				return this.send('showModal', 'payroll.reconcilepopup', { 'isUpdate': false });
			}.bind(this));
		}.bind(this));
	},
	setPdalStatus: function() {
		var pdal = this.get('pdal.firstObject');
		if (pdal) {
			pdal.set('status', 'E');
			return pdal.save();
		}
		return Ember.RSVP.resolve();
	}
});

App.PayrollReconcilepopupController = Ember.ObjectController.extend({});
App.PayrollSwitchnotsupportController = Ember.ObjectController.extend();
App.PayrollHelpController = Ember.ObjectController.extend(_PayrollToggleMixin, {
});

App.PayrollSwitchreviewController = _AbstractSavingController.extend({
	switchTermDate: function() {
		return moment(this.get('terminateDate')).format("MMM DD, YYYY");
	}.property('terminateDate'),
	switchTermDateFull: function() {
		return moment(this.get('terminateDate')).format("MMMM DD, YYYY");
	}.property('terminateDate'),
	switchStartDateFull: function() {
		var today = moment();
		if (moment(this.get('terminateDate')) < today) {
			return moment(today).add(1, 'days').format("MMMM DD, YYYY");
		}
		return moment(this.get('terminateDate')).add(1, 'days').format("MMMM DD, YYYY");
	}.property('terminateDate'),
	submit: function() {
		this.set('status', 'SUB');
		this.saveAndContinue().then(function() {
			this.transitionToRoute('payrollIntegrations.manage');
			this.send('hideModal');
		}.bind(this));
	}
});
